import is from 'is_js';

import { uploadRequiredDocument } from 'common/api/creditApplications';
import { uploadPrivateDocument as uploaCreditPrivateDocument } from 'common/api/creditApplications';
import { uploadPrivateDocument as uploadAlliancePrivateDocument } from 'common/api/documentCatalog';
import { updateFinancierInstallersDocumentStatus } from 'common/api/financierAllianceInstaller';
import { ALLIANCE_DOCUMENTS_CATEGORY } from 'common/constants';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_SUCCESS,
    UPLOAD_DOCUMENT_FAILURE,
} from '../actionTypes';
import { privateDocumentsActions } from '../reducer';

import fetchDocuments from './fetchDocuments';
import showModal from './showModal';

export default (values) => (dispatch) => {
    let action = null;
    const newValues = { catalog_document: values.catalog_document };

    if (is.array(values.file)) newValues.file = values.file && values.file[0];

    if (values.id !== null && typeof values.id !== 'undefined') {
        if (values.type === ALLIANCE_DOCUMENTS_CATEGORY)
            action = () =>
                updateFinancierInstallersDocumentStatus(values.id, newValues);
        else action = () => uploadRequiredDocument(values.id, newValues);
    } else {
        if (values.type === ALLIANCE_DOCUMENTS_CATEGORY) {
            action = () => uploadAlliancePrivateDocument(newValues);
            newValues.financier_installers = values.profile_id;
        } else {
            action = () => uploaCreditPrivateDocument(newValues);
            newValues.credit_commercial_offer = values.profile_id;
        }
    }

    dispatch(privateDocumentsActions[UPLOAD_DOCUMENT]());

    action()
        .then((response) => {
            dispatch(
                privateDocumentsActions[UPLOAD_DOCUMENT_SUCCESS](response.data)
            );
            dispatch(showModal(false));
            dispatch(fetchDocuments(values.fetchId, values.type));
        })
        .catch((error) => {
            dispatch(
                privateDocumentsActions[UPLOAD_DOCUMENT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
