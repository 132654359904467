import resetForm from './resetForm';
import resetFormTerms from './resetFormTerms';
import setIsNewRecord from './setIsNewRecord';
import toggleModal from './toggleModal';

export default () => (dispatch) => {
    dispatch(resetForm());
    dispatch(resetFormTerms());
    dispatch(setIsNewRecord(true));
    dispatch(toggleModal(true));
};
