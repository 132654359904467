import React from 'react';

import PropTypes from 'prop-types';
import { Button } from 'sunwise-ui';

const Buttons = ({
    cancelText = 'CANCELAR',
    confirmText = 'ACEPTAR',
    disabledBackButton,
    disabledConfirmButton,
    handleClickCancel,
    handleClickSuccess,
    type,
}) => {
    if (type === 'confirm')
        return (
            <>
                <Button
                    className="__userguiding_cancel_button"
                    color="secondary"
                    disabled={disabledBackButton}
                    onClick={handleClickCancel}
                    type="button"
                    variant="text"
                >
                    {cancelText}
                </Button>{' '}
                <Button
                    className="__userguiding_confirm_button"
                    disabled={disabledConfirmButton}
                    onClick={handleClickSuccess}
                    type="button"
                    variant="text"
                >
                    {confirmText}
                </Button>
            </>
        );

    if (type === 'only_confirm')
        return (
            <Button type="button" onClick={handleClickSuccess}>
                {confirmText}
            </Button>
        );

    return (
        <Button type="button" onClick={handleClickCancel}>
            {confirmText}
        </Button>
    );
};

Buttons.propTypes = {
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    disabledBackButton: PropTypes.bool,
    disabledConfirmButton: PropTypes.bool,
    handleClickCancel: PropTypes.func,
    handleClickSuccess: PropTypes.func,
    type: PropTypes.string,
};

export default Buttons;
