import React from 'react';

import PropTypes from 'prop-types';

const LayoutComponent = ({
    component: Component,
    layout: Layout,
    matchProps,
    rest,
}) => (
    <Layout {...rest}>
        <Component {...matchProps} />
    </Layout>
);

LayoutComponent.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    matchProps: PropTypes.object,
    rest: PropTypes.object,
};

export default LayoutComponent;
