import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Skeleton } from 'sunwise-ui';

import IconPreviewItem from 'common/components/IconPreviewItem';
import { useBreakpoint } from 'common/hooks';

import * as selectors from '../../selectors';

const DefaultTemplatesTab = ({
    categorySelected,
    handleSelectPage,
    isFetchingCustomTemplate,
    pageSelected,
    selectCategory,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const [items, setItems] = useState(6);

    useEffect(() => {
        if (['xs', 'sm'].includes(breakpoint)) setItems(2);
        if (['md'].includes(breakpoint)) setItems(3);
        setItems(6);
    }, [breakpoint]);

    if (isFetchingCustomTemplate)
        return (
            <Grid container mb={2}>
                {Array.from(new Array(items)).map((_, index) => (
                    <Grid item key={`skeleton-${index}`} lg={3} md={6} xs={9}>
                        <Skeleton height={218} variant="rounded" />
                    </Grid>
                ))}
            </Grid>
        );

    if (isNull(categorySelected)) {
        return (
            <Grid container>
                <Grid item xs={18} md={6} lg={3}>
                    <IconPreviewItem
                        icon={<AddIcon fontSize="large" />}
                        isSelected={
                            pageSelected !== null &&
                            pageSelected.id === 'blank-page'
                        }
                        onClick={() =>
                            handleSelectPage({ id: 'blank-page', pages: 1 })
                        }
                        text={t('Blank template')}
                    />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container>
            <Grid item xs={18} md={6} lg={3}>
                <IconPreviewItem
                    icon={<ArrowBackIosNewIcon fontSize="large" />}
                    onClick={() => selectCategory(null)}
                    text={t('Back')}
                />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = createStructuredSelector({
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
});

DefaultTemplatesTab.propTypes = {
    categorySelected: PropTypes.string,
    handleSelectPage: PropTypes.func,
    isFetchingCustomTemplate: PropTypes.bool,
    pageSelected: PropTypes.object,
    selectCategory: PropTypes.func,
};

export default connect(mapStateToProps, null)(DefaultTemplatesTab);
