import React, { useContext, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import { LoadingContext } from 'common/utils/contexts';

import * as actions from './actions';
import ListItems from './components/ListItems';
import { getStatusOptions } from './helpers';
import * as selectors from './selectors';

const Container = ({
    assignProduct,
    downloadFile,
    executives,
    fetchAllFinancierProducts,
    fetchExecutives,
    fetchInstallers,
    financierProducts,
    handlePrepareUnlink,
    installers,
    isDownloading,
    isFetching,
    isFetchingExecutives,
    isFetchingTableSettings,
    pagination,
    tableSettings,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [filterData, setFilterData] = useState({
        executiveName: '',
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        product: 'all',
        searchText: '',
        sortBy: 'desc',
        status: 'all',
    });

    useEffect(() => {
        fetchExecutives();
        fetchAllFinancierProducts();
        fetchInstallers(filterData);
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isDownloading) loadingContext.openLoading(t('Downloading'));
        else loadingContext.closeLoading();
    }, [isDownloading]);

    const handleClickUnlink = (item) => handlePrepareUnlink(filterData, item);

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <TitleIcon title={t('Alliance requests')} />
            </HeaderContainer>

            <ListItems
                assignProduct={assignProduct}
                canModify={true}
                downloadFile={downloadFile}
                executives={executives}
                filterData={filterData}
                filterItems={fetchInstallers}
                financierProducts={financierProducts}
                handleClickUnlink={handleClickUnlink}
                isFetching={isFetching || isFetchingTableSettings}
                isFetchingExecutives={isFetchingExecutives}
                items={installers}
                paginationData={pagination}
                setFilterData={setFilterData}
                statusList={getStatusOptions()}
                tableSettings={tableSettings}
            />
        </MaterialContainer>
    );
};

Container.propTypes = {
    assignProduct: PropTypes.func,
    downloadFile: PropTypes.func,
    executives: PropTypes.array,
    fetchAllFinancierProducts: PropTypes.func,
    fetchExecutives: PropTypes.func,
    fetchInstallers: PropTypes.func,
    financierProducts: PropTypes.array,
    handlePrepareUnlink: PropTypes.func,
    installers: PropTypes.array,
    isDownloading: PropTypes.bool,
    isFetching: PropTypes.bool,
    isFetchingExecutives: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    pagination: PropTypes.object,
    tableSettings: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    executives: selectors.getExecutivesData,
    financierProducts: selectors.getAllFinancierProductsData,
    installers: selectors.getFetchInstallersData,
    isDownloading: selectors.getIsDownloading,
    isFetching: selectors.getIsFetchingInstallers,
    isFetchingExecutives: selectors.getIsFetchingExecutives,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    pagination: selectors.getInstallersPagination,
    tableSettings: customColumnsSelectors.getTableSettingsData,
});

const mapDispatchToProps = (dispatch) => ({
    assignProduct: (ids, callback) =>
        dispatch(actions.prepareAssignProduct(ids, callback)),
    downloadFile: (filterData) => dispatch(actions.downloadFile(filterData)),
    fetchAllFinancierProducts: () =>
        dispatch(actions.fetchAllFinancierProducts()),
    fetchExecutives: () => dispatch(actions.fetchExecutives()),
    fetchInstallers: (filterData) =>
        dispatch(actions.fetchInstallers(filterData)),
    handlePrepareUnlink: (filterData, item) =>
        dispatch(actions.prepareUnlinkAlliance(filterData, item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
