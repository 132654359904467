import { NAME } from './constants';

export const FETCH_EXECUTIVES = `${NAME}/FETCH_EXECUTIVES`;
export const FETCH_EXECUTIVES_FAILURE = `${NAME}/FETCH_EXECUTIVES_FAILURE`;
export const FETCH_EXECUTIVES_SUCCESS = `${NAME}/FETCH_EXECUTIVES_SUCCESS`;

export const FETCH_INSTALLERS = `${NAME}/FETCH_INSTALLERS`;
export const FETCH_INSTALLERS_FAILURE = `${NAME}/FETCH_INSTALLERS_FAILURE`;
export const FETCH_INSTALLERS_SUCCESS = `${NAME}/FETCH_INSTALLERS_SUCCESS`;

export const FETCH_ALL_FINANCIER_PRODUCTS = `${NAME}/FETCH_ALL_FINANCIER_PRODUCTS`;
export const FETCH_ALL_FINANCIER_PRODUCTS_FAILURE = `${NAME}/FETCH_ALL_FINANCIER_PRODUCTS_FAILURE`;
export const FETCH_ALL_FINANCIER_PRODUCTS_SUCCESS = `${NAME}/FETCH_ALL_FINANCIER_PRODUCTS_SUCCESS`;

export const FETCH_FINANCIAL_PRODUCTS = `${NAME}/FETCH_FINANCIAL_PRODUCTS`;
export const FETCH_FINANCIAL_PRODUCTS_FAILURE = `${NAME}/FETCH_FINANCIAL_PRODUCTS_FAILURE`;
export const FETCH_FINANCIAL_PRODUCTS_SUCCESS = `${NAME}/FETCH_FINANCIAL_PRODUCTS_SUCCESS`;

export const INITIALIZE_ASSIGN_PRODUCT_FORM = `${NAME}/INITIALIZE_ASSIGN_PRODUCT_FORM`;

export const RESET_ASSIGN_PRODUCT_FORM = `${NAME}/RESET_ASSIGN_PRODUCT_FORM`;

export const SAVE_BULK_ASSIGN_PRODUCT = `${NAME}/SAVE_BULK_ASSIGN_PRODUCT`;
export const SAVE_BULK_ASSIGN_PRODUCT_FAILURE = `${NAME}/SAVE_BULK_ASSIGN_PRODUCT_FAILURE`;
export const SAVE_BULK_ASSIGN_PRODUCT_SUCCESS = `${NAME}/SAVE_BULK_ASSIGN_PRODUCT_SUCCESS`;

export const SET_IS_DOWNLOADING = `${NAME}/SET_IS_DOWNLOADING`;
export const SET_IS_OPEN_ASSIGN_PRODUCT_MODAL = `${NAME}/SET_IS_OPEN_ASSIGN_PRODUCT_MODAL`;

export const UNLINK_ALLIANCE = `${NAME}/UNLINK_ALLIANCE`;
export const UNLINK_ALLIANCE_FAILURE = `${NAME}/UNLINK_ALLIANCE_FAILURE`;
export const UNLINK_ALLIANCE_SUCCESS = `${NAME}/UNLINK_ALLIANCE_SUCCESS`;
