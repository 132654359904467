import {
    INVALID_PASSWORD_CONFIRMATION,
    INVALID_PASSWORD_CONFIRMATION_LENGTH,
    VALID_PASSWORD_CONFIRMATION,
    VALID_PASSWORD_CONFIRMATION_LENGTH,
} from '../actionTypes';
import { profileSecurityActions } from '../reducer';

export default (event, values) => (dispatch) => {
    const password = values.password;

    const password_confirmation = event.target.value;

    if (password_confirmation.length >= 8) {
        dispatch(profileSecurityActions[VALID_PASSWORD_CONFIRMATION_LENGTH]());
    } else {
        dispatch(
            profileSecurityActions[INVALID_PASSWORD_CONFIRMATION_LENGTH]()
        );
    }

    if (password_confirmation === password) {
        dispatch(profileSecurityActions[VALID_PASSWORD_CONFIRMATION]());
    } else {
        dispatch(profileSecurityActions[INVALID_PASSWORD_CONFIRMATION]());
    }
};
