import i18next from 'i18next';

import { updateSmartDocument } from 'common/api/smartDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

export default (reviewId, setTemplateBackup, updatedData) => (dispatch) => {
    dispatch(interactiveProposalPreviewActions[UPDATE_TEMPLATE]());

    updateSmartDocument(reviewId, {
        content: JSON.stringify(updatedData),
    })
        .then(() => {
            dispatch(
                interactiveProposalPreviewActions[UPDATE_TEMPLATE_SUCCESS]()
            );
            setTemplateBackup(true);

            showToast({
                body: i18next.t('Changes were saved successfully'),
            });
        })
        .catch((error) => {
            dispatch(
                interactiveProposalPreviewActions[UPDATE_TEMPLATE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
