import i18next from 'i18next';
import { getFormValues } from 'redux-form';

import alerts from 'common/modules/alerts';

import { NAME } from '../constants';

import restartPassword from './restartPassword';

export default () => (dispatch, getState) => {
    const state = getState();
    const values = getFormValues(`${NAME}/form`)(state);

    const dataAlert = {
        confirmText: i18next.t('Restore password'),
        messages: [
            i18next.t(
                'Are you really sure about resetting the password for mail {{mail}}?',
                {
                    mail: values.email,
                }
            ),
        ],
        onSuccess: () => dispatch(restartPassword(values)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
