import is from 'is_js';

import { sendMessage } from 'common/api/chat';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import addMessage from '../../commentArea/actions/addMessage';
import {
    SEND_MESSAGE,
    SEND_MESSAGE_FAILURE,
    SEND_MESSAGE_SUCCESS,
} from '../actionTypes';
import { rejectFormActions } from '../reducer';

import initializeForm from './initializeForm';

export default (values, callback) => (dispatch) => {
    const message = values.message.trim();

    if (is.empty(message)) return;

    const newValues = {
        financier: values.user.id,
        message: message,
        message_from: 0,
    };

    dispatch(initializeForm({ message: '', timestamp: new Date() }));

    dispatch(rejectFormActions[SEND_MESSAGE]());

    sendMessage(values.chatId, newValues)
        .then((response) => {
            dispatch(rejectFormActions[SEND_MESSAGE_SUCCESS](response.data));

            dispatch(initializeForm({ message: '', timestamp: new Date() }));

            const message = {
                created_at: response.data.created_at,
                financier: values.user,
                id: response.data.id,
                message: response.data.message,
                message_from: response.data.message_from,
                name: values.user.name,
                updated_at: response.data.updated_at,
            };

            dispatch(addMessage(message));

            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                rejectFormActions[SEND_MESSAGE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
