import { NAME } from './constants';

export const DELETE_SMART_DOCUMENT = `${NAME}/DELETE_SMART_DOCUMENT`;
export const DELETE_SMART_DOCUMENT_FAILURE = `${NAME}/DELETE_SMART_DOCUMENT_FAILURE`;
export const DELETE_SMART_DOCUMENT_SUCCESS = `${NAME}/DELETE_SMART_DOCUMENT_SUCCESS`;

export const DUPLICATE_SMART_DOCUMENT = `${NAME}/DUPLICATE_SMART_DOCUMENT`;
export const DUPLICATE_SMART_DOCUMENT_FAILURE = `${NAME}/DUPLICATE_SMART_DOCUMENT_FAILURE`;
export const DUPLICATE_SMART_DOCUMENT_SUCCESS = `${NAME}/DUPLICATE_SMART_DOCUMENT_SUCCESS`;

export const FETCH_CUSTOM_TEMPLATE = `${NAME}/FETCH_CUSTOM_TEMPLATE`;
export const FETCH_CUSTOM_TEMPLATE_FAILURE = `${NAME}/FETCH_CUSTOM_TEMPLATE_FAILURE`;
export const FETCH_CUSTOM_TEMPLATE_SUCCESS = `${NAME}/FETCH_CUSTOM_TEMPLATE_SUCCESS`;

export const FETCH_SMART_DOCUMENTS = `${NAME}/FETCH_SMART_DOCUMENTS`;
export const FETCH_SMART_DOCUMENTS_FAILURE = `${NAME}/FETCH_SMART_DOCUMENTS_FAILURE`;
export const FETCH_SMART_DOCUMENTS_SUCCESS = `${NAME}/FETCH_SMART_DOCUMENTS_SUCCESS`;

export const FETCH_TEMPLATES = `${NAME}/FETCH_TEMPLATES`;
export const FETCH_TEMPLATES_FAILURE = `${NAME}/FETCH_TEMPLATES_FAILURE`;
export const FETCH_TEMPLATES_SUCCESS = `${NAME}/FETCH_TEMPLATES_SUCCESS`;

export const INITIALIZE_SMART_DOCUMENT_FORM = `${NAME}/INITIALIZE_SMART_DOCUMENT_FORM`;

export const RENAME_SMART_DOCUMENT = `${NAME}/RENAME_SMART_DOCUMENT`;
export const RENAME_SMART_DOCUMENT_FAILURE = `${NAME}/RENAME_SMART_DOCUMENT_FAILURE`;
export const RENAME_SMART_DOCUMENT_SUCCESS = `${NAME}/RENAME_SMART_DOCUMENT_SUCCESS`;

export const RESET = `${NAME}/RESET`;
export const RESET_DOCUMENT_FORM = `${NAME}/RESET_DOCUMENT_FORM`;

export const SAVE_SMART_DOCUMENT = `${NAME}/SAVE_SMART_DOCUMENT`;
export const SAVE_SMART_DOCUMENT_FAILURE = `${NAME}/SAVE_SMART_DOCUMENT_FAILURE`;
export const SAVE_SMART_DOCUMENT_SUCCESS = `${NAME}/SAVE_SMART_DOCUMENT_SUCCESS`;

export const SET_IS_OPEN_SMART_DOCUMENT_MODAL = `${NAME}/SET_IS_OPEN_SMART_DOCUMENT_MODAL`;
export const SET_SMART_DOCUMENT_FORM_MODE = `${NAME}/SET_SMART_DOCUMENT_FORM_MODE`;
export const SET_SELECTED_DOCUMENT = `${NAME}/SET_SELECTED_DOCUMENT`;
