import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Link, Typography } from 'sunwise-ui';

const CreditNameFormatter = ({ row }) => (
    <Box display="flex" flexDirection="column" gap={1}>
        <Link
            color="secondary"
            component={RouterLink}
            to={`/credits/file/${row.id}`}
        >
            <Typography fontWeight="bold" variant="body2">
                {get(row, 'client.name', '')}{' '}
                {get(row, 'client.first_lastname', '')}{' '}
                {get(row, 'client.second_lastname', '')}
            </Typography>
        </Link>

        <Box alignItems="flex-start" display="flex" gap={1}>
            <Avatar
                src={get(row, 'installer.image', null)}
                sx={{ height: 20, width: 20 }}
            />

            <Typography variant="caption">
                {`${get(row, 'installer.name', '')} / ${get(
                    row,
                    'client.agent_name',
                    ''
                )}`}
            </Typography>
        </Box>
    </Box>
);

CreditNameFormatter.propTypes = { row: PropTypes.object };

export default CreditNameFormatter;
