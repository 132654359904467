import { filterNotifications } from 'common/api/notifications';

import {
    FETCH_NAV_NOTIFICATIONS,
    FETCH_NAV_NOTIFICATIONS_FAILURE,
    FETCH_NAV_NOTIFICATIONS_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(notificationsActions[FETCH_NAV_NOTIFICATIONS]());

    filterNotifications({ page: 1, perPage: 10 })
        .then((response) =>
            dispatch(
                notificationsActions[FETCH_NAV_NOTIFICATIONS_SUCCESS](
                    response.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                notificationsActions[FETCH_NAV_NOTIFICATIONS_FAILURE](error)
            )
        );
};
