import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteInsuranceConfig from './deleteCoverageConfig';

export default (filterData, item, setSelectedCoverage) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        haveTextCaptcha: true,
        messages: [i18next.t('Are you really sure to delete the item?')],
        onSuccess: () =>
            dispatch(
                deleteInsuranceConfig(filterData, item, setSelectedCoverage)
            ),
        textToValidate: i18next.t('Delete').toUpperCase(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
