import { fetchListCatalogs } from 'common/api/documentCatalog';
import { PRODUCT_DOCUMENTS_CATEGORY, CONTRACT_TYPE } from 'common/constants';

import {
    FETCH_CONTRACT_DOCUMENTS,
    FETCH_CONTRACT_DOCUMENTS_FAILURE,
    FETCH_CONTRACT_DOCUMENTS_SUCCESS,
} from '../../actionTypes';
import { creditFileActions } from '../../reducer';

export default () => (dispatch) => {
    dispatch(creditFileActions[FETCH_CONTRACT_DOCUMENTS]());

    fetchListCatalogs()
        .then((response) => {
            const dataFiltered = response.data.data.filter(
                (item) =>
                    item.category === PRODUCT_DOCUMENTS_CATEGORY &&
                    item.type === CONTRACT_TYPE
            );
            dispatch(
                creditFileActions[FETCH_CONTRACT_DOCUMENTS_SUCCESS](
                    dataFiltered
                )
            );
        })
        .catch((error) =>
            dispatch(
                creditFileActions[FETCH_CONTRACT_DOCUMENTS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
