import React from 'react';

import PropTypes from 'prop-types';
import {
    FormControl,
    FormHelperText,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
} from 'sunwise-ui';

const RadioButtonField = ({
    disabled,
    input: { onChange, value: selectedValue },
    items,
    label,
    meta: { error, touched },
    row,
}) => (
    <FormControl disabled={disabled} sx={{ mb: 2 }}>
        {label && <FormLabel>{label}</FormLabel>}

        <RadioGroup
            onChange={(e) => onChange(e.target.value)}
            row={row}
            value={selectedValue}
        >
            {items.map((item, index) => (
                <FormControlLabel
                    control={<Radio />}
                    disabled={disabled}
                    key={`radio-field-${index}`}
                    label={item.label}
                    value={item.value}
                />
            ))}
        </RadioGroup>

        {Boolean(error) && touched && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
);

RadioButtonField.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object,
    items: PropTypes.array,
    label: PropTypes.string,
    meta: PropTypes.object,
    row: PropTypes.bool,
};

export default RadioButtonField;
