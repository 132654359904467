import { isEmpty, isUndefined } from 'lodash';

import {
    DEFAULT_NO_PERMISSIONS,
    PERMISSION_LEVELS,
} from 'common/constants/permissions';

import {
    getIsGuest,
    getIsValidLogin,
    getPermissionsDictionary,
    getUserPermits,
    logoutActions,
} from './session';

export const isValidAccess = (isPublic, requiredPermissions) => {
    if (!isPublic) {
        const isGuest = getIsGuest();
        const isValidLogin = getIsValidLogin();
        if (!isValidLogin) {
            logoutActions();
            return false;
        }
        if (isGuest) return false;
        if (requiredPermissions)
            return validateAccessWithPermissions(requiredPermissions);
    }
    return true;
};

export const validateAccessWithPermissions = (requiredPermissions) => {
    const userPermits = getUserPermits();
    if (isEmpty(userPermits)) logoutActions();
    if (isEmpty(requiredPermissions)) return true;
    if (!isEmpty(userPermits) && requiredPermissions)
        return (
            userPermits.filter((value) => requiredPermissions.includes(value))
                .length > 0
        );
    return false;
};

export const validateByPermissionCode = (permissionCode) => {
    const permissionsDictionary = getPermissionsDictionary();
    if (Array.isArray(permissionCode)) {
        const permissionDictionary = permissionCode.reduce((acc, current) => {
            const permission = permissionsDictionary[current];
            if (!isUndefined(permission)) {
                return {
                    ...acc,
                    [current]: getCanActionsDictionary(
                        permissionsDictionary,
                        current
                    ),
                };
            } else {
                return {
                    ...acc,
                    [current]: DEFAULT_NO_PERMISSIONS,
                };
            }
        }, {});
        return {
            getPermissionsByCode: (code) => permissionDictionary[code],
        };
    }
    return getCanActionsDictionary(permissionsDictionary, permissionCode);
};

export const getCanActionsDictionary = (
    permissionsDictionary,
    permissionCode
) => {
    const permission = permissionsDictionary[permissionCode];
    if (!isUndefined(permission)) {
        const { is_enabled: isEnabled, permission_level: permissionLevel } =
            permission;
        switch (permissionLevel) {
            case PERMISSION_LEVELS.VIEW:
                return {
                    canDelete: false,
                    canModify: false,
                    canView: isEnabled,
                };
            case PERMISSION_LEVELS.MODIFY:
                return {
                    canDelete: false,
                    canModify: true,
                    canView: isEnabled,
                };
            case PERMISSION_LEVELS.ALL:
                return {
                    canDelete: true,
                    canModify: true,
                    canView: isEnabled,
                };
            default:
                return DEFAULT_NO_PERMISSIONS;
        }
    }
    return DEFAULT_NO_PERMISSIONS;
};
