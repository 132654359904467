import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

const sortByName = (items) => {
    const newItems = [...items];
    newItems.sort((a, b) => {
        const nameA = a.label.toUpperCase();
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });
    return newItems;
};

export const getModel = (state) => state[NAME];

export const getFunnelColumns = createSelector(
    getModel,
    (model) => model.funnelColumns
);

export const getIsFetchingFunnelColumns = createSelector(
    getFunnelColumns,
    (funnelColumns) => funnelColumns.isFetching
);

export const getFunnelColumnsData = createSelector(
    getFunnelColumns,
    (funnelColumns) => funnelColumns.data
);

export const getFunnelColumnsErrors = createSelector(
    getFunnelColumns,
    (funnelColumns) => funnelColumns.errors
);

export const getUpdatingStatusProject = createSelector(
    getModel,
    (model) => model.updatingStatusProject
);

export const getUpdatingStatusProjectId = createSelector(
    getUpdatingStatusProject,
    (model) => (model ? model.projectId : model)
);

export const getRefreshingIsNeeded = createSelector(
    getModel,
    (model) => model.refreshingIsNeeded
);

export const getNextPages = createSelector(
    getModel,
    (model) => model.nextPages
);

export const getNextPagesIds = createSelector(
    getNextPages,
    (model) => model.ids
);

export const getIsFetchingNextPages = createSelector(
    getNextPages,
    (model) => model.isFetching
);

export const getFilterItemSelected = createSelector(
    getModel,
    (model) => model.filterItemSelected
);

export const getExecutivesModel = createSelector(
    getModel,
    (model) => model.executives
);

export const getExecutivesData = createSelector(
    getExecutivesModel,
    (model) => model.data || []
);

export const getExecutivesIsFetching = createSelector(
    getExecutivesModel,
    (model) => model.isFetching
);

export const getExecutivesToSelect = createSelector(
    getExecutivesData,
    (model) => {
        const items = model.map((item) => {
            const name = item.name;
            return { label: name, value: name };
        });

        const sortedItems = sortByName(items);

        sortedItems.unshift({
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: null,
        });

        return sortedItems;
    }
);

export const getExecutivesErrors = createSelector(
    getExecutivesModel,
    (model) => model.errors
);

export const getCompaniesModel = createSelector(
    getModel,
    (model) => model.companies
);

export const getCompaniesData = createSelector(
    getCompaniesModel,
    (model) => model.data || []
);

export const getCompaniesIsFetching = createSelector(
    getCompaniesModel,
    (model) => model.isFetching
);

export const getCompaniesErrors = createSelector(
    getCompaniesModel,
    (model) => model.errors
);

export const getCompaniesToSelect = createSelector(
    getCompaniesData,
    (model) => {
        const items = model.map((item) => {
            const name = item.name;
            return { label: name, value: name };
        });

        const sortedItems = sortByName(items);

        sortedItems.unshift({
            label: i18next.t('All text', { context: 'male', count: 2 }),
            value: null,
        });

        return sortedItems;
    }
);
