import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Grid } from 'sunwise-ui';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';

import ProductCardRedux from './ProductCardRedux';

const ProductForm = ({
    allianceId,
    callback,
    handleSubmit,
    products,
    save,
}) => {
    const { t } = useTranslation();

    const handleOnClickSave = (values) =>
        save({ ...values, allianceId: allianceId }, callback);

    return (
        <form>
            <Grid container>
                {products.map((product) => (
                    <Grid item lg={6} key={`product-${product.id}`}>
                        <Field
                            component={ProductCardRedux}
                            name="financiers_products"
                            product={product}
                        />
                    </Grid>
                ))}
            </Grid>

            <Divider sx={{ mx: -2 }} />

            <Box sx={{ textAlign: 'right', mt: 2 }}>
                <Button onClick={handleSubmit(handleOnClickSave)}>
                    {t('Update')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.initialValuesProductForm,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, callback) =>
        dispatch(actions.saveSelectedProducts(values, callback)),
});

ProductForm.propTypes = {
    allianceId: PropTypes.string,
    callback: PropTypes.func,
    handleSubmit: PropTypes.func,
    products: PropTypes.array,
    save: PropTypes.func,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/card-form`,
})(ProductForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
