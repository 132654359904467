import { initializeApp } from 'firebase/app';
import {
    getMessaging,
    getToken,
    isSupported,
    onMessage,
} from 'firebase/messaging';

import { createDevice } from 'common/api/devices';

export const getIsNotificationIsSupported = () =>
    !!(
        window.Notification ||
        window.webkitNotifications ||
        navigator.mozNotification
    );

const firebaseConfig = {
    apiKey: 'AIzaSyA9Ed1_lagUcGaoCtwAc769tmXwsHCTkTw',
    appId: '1:396140834646:web:30e4d769235cbd8c2f30f7',
    authDomain: 'backend-notification-sw.firebaseapp.com',
    measurementId: 'G-7XEGQ1NJZC',
    messagingSenderId: '396140834646',
    projectId: 'backend-notification-sw',
    storageBucket: 'backend-notification-sw.appspot.com',
};

const app = initializeApp(firebaseConfig);

let messaging;

try {
    messaging =
        isSupported() && getIsNotificationIsSupported()
            ? getMessaging(app)
            : null;
} catch (e) {
    messaging = null;
}

export const handleGetToken = (setToken) => {
    if (!getIsNotificationIsSupported() || !messaging) return;
    getToken(messaging, {
        vapidKey:
            'BCOPd1KaG-w9Cgg-v2WADAzUTlyZyycNZIa8YN9FmjkaekH5eCLlIewV49MiloNrUXxXMu-uzf0MB_qxu8jr5UE',
    })
        .then((currentToken) => {
            if (currentToken) {
                createDevice({ registration_id: currentToken, type: 'web' });
                setToken(currentToken);
                return currentToken;
            }
        })
        .catch();
};

export const requestPermission = (setToken) => {
    if (!getIsNotificationIsSupported() || !messaging) return;
    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') handleGetToken(setToken);
    });
};

export const getFirebaseToken = (setToken, setShowGrantPermission) => {
    if (!getIsNotificationIsSupported() || !messaging) {
        setShowGrantPermission(false);
        return;
    }
    if (Notification.permission === 'granted') handleGetToken(setToken);
    if (Notification.permission !== 'denied') setShowGrantPermission(true);
};

export const onMessageListener = () => {
    return new Promise((resolve) => {
        if (!getIsNotificationIsSupported() || !messaging) return;
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });
};
