import { deleteFinancierInstallersDocuments } from 'common/api/financierAllianceInstaller';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_FAILURE,
    DELETE_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

import fetchRequiredDocumentsList from './documents/fetchRequiredDocumentsList';

export default (document, installerId) => (dispatch) => {
    dispatch(installerProfileActions[DELETE_DOCUMENT]());

    deleteFinancierInstallersDocuments(document?.id)
        .then(() => {
            dispatch(installerProfileActions[DELETE_DOCUMENT_SUCCESS]());
            dispatch(fetchRequiredDocumentsList(installerId));
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch(
                installerProfileActions[DELETE_DOCUMENT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
