import { exportConsumtionsCSV } from 'common/api/proposals';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SET_IS_DOWNLOADING } from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(creditFileActions[SET_IS_DOWNLOADING](true));

    exportConsumtionsCSV(id)
        .then((response) => {
            dispatch(creditFileActions[SET_IS_DOWNLOADING](false));
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Consumos.csv`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            dispatch(creditFileActions[SET_IS_DOWNLOADING](false));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
