import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import { LANGUAGE_DICTIONARY } from '../../constants';

const CustomTemplateColumns = () => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const nameColumn = [
        {
            align: 'left',
            field: 'title',
            flex: 1,
            headerName: t('Title'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Box display="flex" flexDirection="column">
                        <Typography variant="caption">{row.title}</Typography>
                        <Typography variant="caption">
                            {t('{{count}} Sheet', { count: row.pages })}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            field: 'language',
            flex: 1,
            headerName: t('Language'),
            renderCell: (params) => {
                const { row } = params;
                if (LANGUAGE_DICTIONARY[row?.language])
                    return t(LANGUAGE_DICTIONARY[row?.language]?.label);
                return null;
            },
        },
        {
            align: 'left',
            field: 'created_at',
            flex: 1,
            headerName: t('Creation date'),
            renderCell: (params) => {
                const { row } = params;
                return row?.created_at;
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    return columns;
};

CustomTemplateColumns.propTypes = {
    languagesDictionary: PropTypes.object,
};

export default CustomTemplateColumns;
