import { createSlice } from '@reduxjs/toolkit';

import {
    INVALID_PASSWORD,
    INVALID_PASSWORD_CONFIRMATION,
    INVALID_PASSWORD_CONFIRMATION_LENGTH,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    VALID_PASSWORD,
    VALID_PASSWORD_CONFIRMATION,
    VALID_PASSWORD_CONFIRMATION_LENGTH,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetch: { data: [], error: null, isFetching: false },
    initialValues: {
        confirm_password: null,
        id: null,
        new_password: null,
        old_password: null,
        profile_image: null,
    },
    save: { data: {}, errors: null, isSaving: false },
    validPassword: false,
    validPasswordConfirmation: false,
    validPasswordConfirmationLength: false,
};

const profileSecuritySlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INVALID_PASSWORD]: (state) => {
            state.validPassword = false;
        },
        [INVALID_PASSWORD_CONFIRMATION]: (state) => {
            state.validPasswordConfirmation = false;
        },
        [VALID_PASSWORD_CONFIRMATION_LENGTH]: (state) => {
            state.validPasswordConfirmationLength = true;
        },
        [INVALID_PASSWORD_CONFIRMATION_LENGTH]: (state) => {
            state.validPasswordConfirmationLength = false;
        },
        [SAVE]: (state) => {
            state.save.isSaving = true;
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state) => {
            state.save.isSaving = false;
        },
        [VALID_PASSWORD]: (state) => {
            state.validPassword = true;
        },
        [VALID_PASSWORD_CONFIRMATION]: (state) => {
            state.validPasswordConfirmation = true;
        },
        [VALID_PASSWORD_CONFIRMATION_LENGTH]: (state) => {
            state.validPasswordConfirmationLength = true;
        },
    },
});

export const profileSecurityActions = profileSecuritySlice.actions;

export default profileSecuritySlice.reducer;
