import { filterCredits } from 'common/api/creditApplications';

import {
    FETCH_CREDITS,
    FETCH_CREDITS_FAILURE,
    FETCH_CREDITS_SUCCESS,
} from '../actionTypes';
import { creditsActions } from '../reducer';

export default (filterData) => (dispatch) => {
    const {
        allianceId,
        financialAdvisor,
        orderBy,
        page = 1,
        pageSize,
        searchText,
        sortBy,
        status,
    } = filterData;

    dispatch(creditsActions[FETCH_CREDITS]());

    filterCredits({
        allianceId: allianceId === 'all' ? '' : allianceId,
        financialAdvisor: financialAdvisor === 'all' ? '' : financialAdvisor,
        orderBy,
        page: page + 1,
        perPage: pageSize,
        searchText,
        sortBy,
        status: status.join(','),
    })
        .then((response) =>
            dispatch(creditsActions[FETCH_CREDITS_SUCCESS](response.data))
        )
        .catch((error) =>
            dispatch(
                creditsActions[FETCH_CREDITS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
