import { saveAssignedUser } from 'common/api/creditApplications';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SAVE_ASSIGNED_USER,
    SAVE_ASSIGNED_USER_FAILURE,
    SAVE_ASSIGNED_USER_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

import resetAssignUserForm from './resetAssignUserForm';

export default (values, callback) => (dispatch) => {
    const newValues = { financial_advisor: values.user };

    dispatch(creditFileActions[SAVE_ASSIGNED_USER]());

    saveAssignedUser(values?.creditId, newValues)
        .then(() => {
            dispatch(creditFileActions[SAVE_ASSIGNED_USER_SUCCESS]());
            dispatch(resetAssignUserForm());
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                creditFileActions[SAVE_ASSIGNED_USER_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
