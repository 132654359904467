import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

/** FETCH ELECTRIC BILLS */
export const getElectricBillsModel = createSelector(
    getModel,
    (model) => model.fetchElectricBills
);

export const getIsFetchingElectricBills = createSelector(
    getElectricBillsModel,
    (value) => value.isFetching
);

export const getElectricBillsData = createSelector(
    getElectricBillsModel,
    (value) => value.data
);

export const getElectricBillsErrors = createSelector(
    getElectricBillsModel,
    (value) => value.errors
);

// FETCH FINANCING TEMPLATE
export const getFinancingTemplate = createSelector(
    getModel,
    (model) => model.financingTemplate
);

export const getIsFetchingFinancingTemplate = createSelector(
    getFinancingTemplate,
    (value) => value.isFetching
);

/** FETCH INTERACTIVE PROPOSAL */
export const getInteractiveProposal = createSelector(
    getModel,
    (model) => model.interactiveProposal
);

export const getIsFetchingInteractiveProposal = createSelector(
    getInteractiveProposal,
    (value) => value.isFetching
);

export const getInteractiveProposalData = createSelector(
    getInteractiveProposal,
    (value) => value.data
);

export const getInteractiveProposalError = createSelector(
    getInteractiveProposal,
    (value) => value.error
);

export const getHasInteractiveProposalError = createSelector(
    getInteractiveProposal,
    (value) => value.hasError
);

/** FETCH INTERACTIVE PROPOSAL CONTENT */
export const getInteractiveProposalContent = createSelector(
    getModel,
    (model) => model.interactiveProposalContent
);

export const getIsFetchingInteractiveProposalContent = createSelector(
    getInteractiveProposalContent,
    (value) => value.isFetching
);

export const getInteractiveProposalContentData = createSelector(
    getInteractiveProposalContent,
    (value) => value.data
);

export const getInteractiveProposalContentErrors = createSelector(
    getInteractiveProposalContent,
    (value) => value.errors
);

/** FETCH SMART DOCUMENT CONTENT */
export const getSmartDocumentContent = createSelector(
    getModel,
    (model) => model.smartDocumentContent
);

export const getIsFetchingSmartDocumentContent = createSelector(
    getSmartDocumentContent,
    (value) => value.isFetching
);

export const getSmartDocumentContentData = createSelector(
    getSmartDocumentContent,
    (value) => value.data
);

export const getSmartDocumentContentErrors = createSelector(
    getSmartDocumentContent,
    (value) => value.errors
);

/** DOWNLOAD DATASHEET */
export const getDownloadDatasheet = createSelector(
    getModel,
    (model) => model.downloadDatasheet
);

export const getIsDownloadingDatasheet = createSelector(
    getDownloadDatasheet,
    (value) => value.isDownloading
);

// DOWNLOAD PROPOSAL CSV
export const getDownloadProposalCsv = createSelector(
    getModel,
    (model) => model.downloadProposalCsv
);

export const getIsDownloadingProposalCsv = createSelector(
    getDownloadProposalCsv,
    (value) => value.isDownloading
);

// UPDATE CUSTOM TEMPLATE
export const getUpdateCustomTemplate = createSelector(
    getModel,
    (model) => model.updateCustomTemplate
);

export const getIsUpdatingCustomTemplate = createSelector(
    getUpdateCustomTemplate,
    (value) => value.isUpdating
);
