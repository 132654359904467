import { updateNotification } from 'common/api/notifications';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_FAILURE,
    UPDATE_NOTIFICATION_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';

import fetchNavNotifications from './fetchNavNotifications';
import fetchNotifications from './fetchNotifications';

export default (filterData, notification) => (dispatch) => {
    if (notification && !notification.viewed) {
        dispatch(notificationsActions[UPDATE_NOTIFICATION]());

        const data = { viewed: !notification.viewed };

        updateNotification(notification.id, data)
            .then((response) => {
                dispatch(
                    notificationsActions[UPDATE_NOTIFICATION_SUCCESS](
                        response.data
                    )
                );
                dispatch(fetchNavNotifications());
                dispatch(fetchNotifications(filterData));
            })
            .catch((error) => {
                dispatch(
                    notificationsActions[UPDATE_NOTIFICATION_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    }
};
