import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from 'sunwise-ui';

import {
    arraytoDictionary,
    removeDuplicateObjects,
} from 'common/utils/helpers';

const AutocompleteField = ({ disabled, label, onChange, options, value }) => {
    const [currentValue, setCurrentValue] = useState(null);
    const dictionary = arraytoDictionary(options, 'value');

    const isValidValue = (value) => !isEmpty(value);

    useEffect(() => {
        if (isValidValue(value)) {
            setCurrentValue(dictionary[value]);
        }
    }, [value]);

    return (
        <Autocomplete
            autoHighlight
            disabled={disabled}
            isOptionEqualToValue={(option, selected) =>
                !isEmpty(value) && option?.value === selected.value
            }
            onChange={(_, newValue) => {
                if (newValue) onChange(newValue?.value);
            }}
            onInputChange={(event, newInputValue, reason) => {
                if (reason !== 'reset') setCurrentValue(newInputValue);
                if (reason === 'clear') {
                    setCurrentValue(null);
                    onChange('');
                }
            }}
            options={removeDuplicateObjects(options)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                    }}
                    variant="standard"
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={`item-${Math.random()}`}>
                    {option.label}
                </li>
            )}
            value={currentValue}
        />
    );
};

AutocompleteField.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.string,
};

export default AutocompleteField;
