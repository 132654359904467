import isEmpty from 'lodash/isEmpty';

import { sendMessage } from 'common/api/chat';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SEND_MESSAGE,
    SEND_MESSAGE_FAILURE,
    SEND_MESSAGE_SUCCESS,
} from '../actionTypes';
import { FINANCIER_USER_TYPE } from '../constants';
import { commentAreaActions } from '../reducer';

import addMessage from './addMessage';
import initializeForm from './initializeForm';

export default (values, callback) => (dispatch) => {
    const message = values.message.trim();

    if (isEmpty(message)) return false;

    const newValues = {
        message: message,
        message_from: values.message_from,
    };

    if (values.message_from === FINANCIER_USER_TYPE) {
        newValues.financier = values.user.id;
    } else {
        newValues.installer = values.user.id;
    }

    dispatch(initializeForm({ message: '', timestamp: new Date() }));

    dispatch(commentAreaActions[SEND_MESSAGE]());

    sendMessage(values.chatId, newValues)
        .then((response) => {
            dispatch(commentAreaActions[SEND_MESSAGE_SUCCESS](response.data));

            dispatch(initializeForm({ message: '', timestamp: new Date() }));

            const message = {
                id: response.data.id,
                name: response.data.name,
                message: response.data.message,
                message_from: response.data.message_from,
                created_at: response.data.created_at,
                updated_at: response.data.updated_at,
                username: response.data.username,
                logo: response.data.logo,
            };

            if (values.message_from === FINANCIER_USER_TYPE) {
                message.financier = values.user;
            } else {
                message.installer = values.user;
            }

            dispatch(addMessage(message));

            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                commentAreaActions[SEND_MESSAGE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
