import React from 'react';

import { useTranslation } from 'react-i18next';
import { Container } from 'sunwise-ui';

import HeaderContainer from 'common/components/HeaderContainer';
import ResumeData from 'common/components/ResumeData';
import TitleIcon from 'common/components/TitleIcon';

import ProfileConfiguration from '../profileConfiguration';
import ProfileSecurity from '../profileSecurity';

import DataForm from './components/DataForm';

const MainContainer = () => {
    const { t } = useTranslation();

    return (
        <Container maxWidth={false}>
            <HeaderContainer>
                <TitleIcon title={t('My profile')} />
            </HeaderContainer>

            <ResumeData isOpen={true} title={t('General information')}>
                <DataForm />
            </ResumeData>

            <ResumeData title={t('Security')}>
                <ProfileSecurity.Container />
            </ResumeData>

            <ResumeData title={t('Setting')}>
                <ProfileConfiguration.Container />
            </ResumeData>
        </Container>
    );
};

MainContainer.propTypes = {};

export default MainContainer;
