import API from '..';
const ENTITY = 'financiers/installers';

export const getFinancierInstaller = (id) => API.get(`/api/v1/${ENTITY}/${id}`);

export const getFinancierInstallers = () =>
    API.get(`/api/v1/${ENTITY}/catalog/`);

export const filterFinancierInstallers = ({
    executiveName,
    orderBy = '',
    page = 1,
    perPage,
    product,
    searchText = '',
    sortBy = '',
    status,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            ...(executiveName ? { executive_name: executiveName } : {}),
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
            ...(status ? { status } : {}),
            ...(product ? { product } : {}),
            page: page,
            per_page: perPage,
        },
    });

export const exportFile = ({ executiveName, searchText = '', status } = {}) =>
    API.get(`/api/v1/${ENTITY}/export/`, {
        params: {
            ...(executiveName ? { executive_name: executiveName } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(status ? { status } : {}),
        },
    });

export const getFinancierInstallersPaginated = ({
    orderBy = '',
    page = 1,
    perPage,
    sortBy = '',
    status = 0,
}) =>
    API.get(`/api/v1/${ENTITY}/paginate/${status}`, {
        params: {
            order_by: orderBy,
            page,
            per_page: perPage,
            sort: sortBy,
        },
    });

export const approveFinancierInstallers = (id) =>
    API.put(`/api/v1/${ENTITY}/${id}/approve`);

export const closeFinancierInstallers = (id) =>
    API.put(`/api/v1/${ENTITY}/${id}/close`);

export const getFinancierInstallersBySearch = ({
    orderBy = '',
    page = 1,
    perPage,
    sortBy = '',
    status = 1,
    searchStr = '',
}) =>
    API.get(
        `/api/v1/${ENTITY}/search${status ? '/' + status : ''}${
            searchStr ? '/' + searchStr : ''
        }`,
        {
            params: {
                order_by: orderBy,
                page,
                per_page: perPage,
                sort: sortBy,
            },
        }
    );

export const downloadFinancierInstallerDocuments = (id, type) =>
    API.get(`/api/v1/${ENTITY}/${id}/download-documents/?type=${type}`, {
        responseType: 'blob',
    });

export const changeStatusAlliance = (allianceId, data) =>
    API.put(`/api/v1/${ENTITY}/${allianceId}`, { ...data });

export const fetchCompanyUsers = (id) =>
    API.get(`/api/v1/${ENTITY}/${id}/users`);
