import { getRequiredDocuments } from 'common/api/creditApplications';

import {
    FETCH_REQUIRED_DOCUMENTS_LIST,
    FETCH_REQUIRED_DOCUMENTS_LIST_FAILURE,
    FETCH_REQUIRED_DOCUMENTS_LIST_SUCCESS,
} from '../../actionTypes';
import { creditFileActions } from '../../reducer';

export default (id) => (dispatch) => {
    dispatch(creditFileActions[FETCH_REQUIRED_DOCUMENTS_LIST]());

    getRequiredDocuments(id)
        .then((response) => {
            dispatch(
                creditFileActions[FETCH_REQUIRED_DOCUMENTS_LIST_SUCCESS](
                    response.data.data
                )
            );
        })
        .catch((error) =>
            dispatch(
                creditFileActions[FETCH_REQUIRED_DOCUMENTS_LIST_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
