import { approveFinancierInstallers } from 'common/api/financierInstallers';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    APPROVE_ALLIANCE,
    APPROVE_ALLIANCE_FAILURE,
    APPROVE_ALLIANCE_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (id, callback) => (dispatch) => {
    dispatch(installerProfileActions[APPROVE_ALLIANCE]());

    approveFinancierInstallers(id)
        .then(() => {
            dispatch(installerProfileActions[APPROVE_ALLIANCE_SUCCESS]());
            if (callback) callback();
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch(
                installerProfileActions[APPROVE_ALLIANCE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
