import { NAME } from './constants';

export const DOWNLOAD_DATASHEET = `${NAME}/DOWNLOAD_DATASHEET`;
export const DOWNLOAD_DATASHEET_FAILURE = `${NAME}/DOWNLOAD_DATASHEET_FAILURE`;
export const DOWNLOAD_DATASHEET_SUCCESS = `${NAME}/DOWNLOAD_DATASHEET_SUCCESS`;

export const DOWNLOAD_PROPOSAL_CSV = `${NAME}/DOWNLOAD_PROPOSAL_CSV`;
export const DOWNLOAD_PROPOSAL_CSV_FAILURE = `${NAME}/DOWNLOAD_PROPOSAL_CSV_FAILURE`;
export const DOWNLOAD_PROPOSAL_CSV_SUCCESS = `${NAME}/DOWNLOAD_PROPOSAL_CSV_SUCCESS`;

export const FETCH_ELECTRIC_BILLS = `${NAME}/FETCH_ELECTRIC_BILLS`;
export const FETCH_ELECTRIC_BILLS_FAILURE = `${NAME}/FETCH_ELECTRIC_BILLS_FAILURE`;
export const FETCH_ELECTRIC_BILLS_SUCCESS = `${NAME}/FETCH_ELECTRIC_BILLS_SUCCESS`;

export const FETCH_FINANCING_TEMPLATE = `${NAME}/FETCH_FINANCING_TEMPLATE`;
export const FETCH_FINANCING_TEMPLATE_FAILURE = `${NAME}/FETCH_FINANCING_TEMPLATE_FAILURE`;
export const FETCH_FINANCING_TEMPLATE_SUCCESS = `${NAME}/FETCH_FINANCING_TEMPLATE_SUCCESS`;

export const FETCH_INTERACTIVE_PROPOSAL = `${NAME}/FETCH_INTERACTIVE_PROPOSAL`;
export const FETCH_INTERACTIVE_PROPOSAL_FAILURE = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_FAILURE`;
export const FETCH_INTERACTIVE_PROPOSAL_SUCCESS = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_SUCCESS`;

export const FETCH_INTERACTIVE_PROPOSAL_CONTENT = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_CONTENT`;
export const FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE`;
export const FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS = `${NAME}/FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS`;

export const FETCH_SMART_DOCUMENT_CONTENT = `${NAME}/FETCH_SMART_DOCUMENT_CONTENT`;
export const FETCH_SMART_DOCUMENT_CONTENT_FAILURE = `${NAME}/FETCH_SMART_DOCUMENT_CONTENT_FAILURE`;
export const FETCH_SMART_DOCUMENT_CONTENT_SUCCESS = `${NAME}/FETCH_SMART_DOCUMENT_CONTENT_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const UPDATE_TEMPLATE = `${NAME}/UPDATE_TEMPLATE`;
export const UPDATE_TEMPLATE_FAILURE = `${NAME}/UPDATE_TEMPLATE_FAILURE`;
export const UPDATE_TEMPLATE_SUCCESS = `${NAME}/UPDATE_TEMPLATE_SUCCESS`;
