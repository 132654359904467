import React, { useState } from 'react';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BusinessIcon from '@mui/icons-material/Business';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
import TableChartIcon from '@mui/icons-material/TableChart';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    Typography,
} from 'sunwise-ui';

import CheckAccess from 'common/components/CheckAccess';
import { PERMISSION_LIST } from 'common/constants/permissions';
import { useBreakpoint } from 'common/hooks';

import { ReactComponent as IcoAlliance } from 'resources/icoAlliance.svg';
import { ReactComponent as IcoContacts } from 'resources/icoContacts.svg';
import { ReactComponent as IcoFinancial } from 'resources/icoFinancial.svg';
import { ReactComponent as IcoTemplates } from 'resources/icoTemplates.svg';

import Logo from './Logo';
import ProfileButton from './ProfileButton';
import ProfileMenu from './ProfileMenu';
import ProfileMenuItems from './ProfileMenuItems';
import SecondaryDrawer from './SecondaryDrawer';
import StyledListItemButton from './StyledListItemButton';
import StyledListItemText from './StyledListItemText';
import TooltipHandler from './TooltipHandler';

const Name = styled(Typography)(() => ({
    color: '#EEF4FA',
    display: 'block',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.25px',
    lineHeight: '20px',
    textAlign: 'left',
}));

const DrawerContent = ({
    container,
    drawerSize,
    handleDrawerToggle,
    match,
    mobileOpen,
    multiBranchMode,
    profileValues,
    redirect,
    selectedTheme,
    setTheme,
    subscription,
    toggleDrawerSize,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const [anchorProfileMenu, setAnchorProfileMenu] = useState(null);

    const handleProfileMenuOpen = (event) => {
        if (mobileOpen && ['xs', 'sm'].includes(breakpoint)) {
            handleProfileDrawerToggle();
            return;
        }
        setAnchorProfileMenu(event.currentTarget);
    };
    const [mobileOpenProfileDrawer, setMobileOpenProfileDrawer] =
        useState(false);
    const handleProfileDrawerToggle = () =>
        setMobileOpenProfileDrawer(!mobileOpenProfileDrawer);

    return (
        <React.Fragment>
            <Box
                display="flex"
                sx={{
                    alignItems: 'center',
                    justifyContent: { md: 'center', xs: 'space-between' },
                    px: { md: 0, xs: 2 },
                }}
            >
                <Box sx={{ padding: '8px 4px' }}>
                    <Logo />
                </Box>

                <IconButton
                    onClick={() => {
                        if (mobileOpen) handleDrawerToggle();
                    }}
                    sx={{ display: { md: 'none' } }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>

            <Divider sx={{ display: { md: 'none', sx: 'block' } }} />

            <Box
                component="span"
                className="toggleDrawer"
                sx={{
                    color: '#4a4a5d',
                    cursor: 'pointer',
                    display: { md: 'block', xs: 'none' },
                    textAlign: 'right',
                    visibility: 'hidden',
                }}
                onClick={toggleDrawerSize}
            >
                {drawerSize === 'normal' ? (
                    <ArrowLeftIcon />
                ) : (
                    <ArrowRightIcon />
                )}
            </Box>

            <List
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    pb: 0,
                }}
            >
                <CheckAccess
                    requiredPermissions={[
                        PERMISSION_LIST.ALLIANCE_PRODUCT_APPLICATIONS_PERMISSION,
                    ]}
                >
                    <ListItem disablePadding>
                        <TooltipHandler
                            drawerSize={drawerSize}
                            title={t('Request', { count: 2 })}
                        >
                            <StyledListItemButton
                                onClick={() => {
                                    redirect('/credits');
                                    if (mobileOpen) handleDrawerToggle();
                                }}
                                selected={
                                    /^\/credits/i.test(match.path) ||
                                    /^\/credits\/file/i.test(match.path) ||
                                    /^\/credits\/preview/i.test(match.path)
                                }
                                size={drawerSize}
                            >
                                <IcoContacts />
                                <StyledListItemText
                                    primary={t('Request', { count: 2 })}
                                />
                            </StyledListItemButton>
                        </TooltipHandler>
                    </ListItem>
                </CheckAccess>

                <CheckAccess
                    requiredPermissions={[
                        PERMISSION_LIST.ALLIANCE_PRODUCT_APPLICATIONS_PERMISSION,
                    ]}
                >
                    <ListItem disablePadding>
                        <TooltipHandler drawerSize={drawerSize} title="Funnel">
                            <StyledListItemButton
                                onClick={() => {
                                    handleDrawerToggle();
                                    redirect('/funnel');
                                    if (mobileOpen) handleDrawerToggle();
                                }}
                                selected={/^\/funnel/i.test(match.path)}
                                size={drawerSize}
                            >
                                <TableChartIcon />
                                <StyledListItemText primary="Funnel" />
                            </StyledListItemButton>
                        </TooltipHandler>
                    </ListItem>
                </CheckAccess>

                <CheckAccess
                    requiredPermissions={[
                        PERMISSION_LIST.ALLIANCE_REQUESTS_PERMISSION,
                    ]}
                >
                    <ListItem disablePadding>
                        <TooltipHandler
                            drawerSize={drawerSize}
                            title={t('Alliance', { count: 2 })}
                        >
                            <StyledListItemButton
                                onClick={() => {
                                    redirect('/installers');
                                    if (mobileOpen) handleDrawerToggle();
                                }}
                                selected={
                                    /^\/installers/i.test(match.path) ||
                                    /^\/installer-profile/i.test(match.path)
                                }
                                size={drawerSize}
                            >
                                <IcoAlliance />
                                <StyledListItemText
                                    primary={t('Alliance', { count: 2 })}
                                />
                            </StyledListItemButton>
                        </TooltipHandler>
                    </ListItem>
                </CheckAccess>

                <CheckAccess
                    requiredPermissions={[
                        PERMISSION_LIST.ALLIANCE_PRODUCTS_PERMISSION,
                    ]}
                >
                    <ListItem disablePadding>
                        <TooltipHandler
                            drawerSize={drawerSize}
                            title={t('Product', { count: 2 })}
                        >
                            <StyledListItemButton
                                onClick={() => {
                                    redirect('/financial');
                                    if (mobileOpen) handleDrawerToggle();
                                }}
                                selected={/^\/financial/i.test(match.path)}
                                size={drawerSize}
                            >
                                <IcoFinancial />
                                <StyledListItemText
                                    primary={t('Product', { count: 2 })}
                                />
                            </StyledListItemButton>
                        </TooltipHandler>
                    </ListItem>
                </CheckAccess>

                <ListItem disablePadding>
                    <TooltipHandler
                        drawerSize={drawerSize}
                        title={t('Template', { count: 2 })}
                    >
                        <StyledListItemButton
                            onClick={() => {
                                redirect('/templates');
                                if (mobileOpen) handleDrawerToggle();
                            }}
                            selected={
                                /^\/templates/i.test(match.path) ||
                                /^\/edit-template/i.test(match.path)
                            }
                            size={drawerSize}
                        >
                            <IcoTemplates />
                            <StyledListItemText
                                primary={t('Template', { count: 2 })}
                            />
                        </StyledListItemButton>
                    </TooltipHandler>
                </ListItem>

                <CheckAccess
                    requiredPermissions={[
                        PERMISSION_LIST.INSURANCE_DOCUMENTS_PERMISSION,
                        PERMISSION_LIST.ALLIANCE_DOCUMENTS_PERMISSION,
                        PERMISSION_LIST.APPLICATION_DOCUMENTS_PERMISSION,
                    ]}
                >
                    <ListItem disablePadding>
                        <TooltipHandler
                            drawerSize={drawerSize}
                            title={t('Configuration')}
                        >
                            <StyledListItemButton
                                onClick={() => {
                                    redirect('/configuration');
                                    if (mobileOpen) handleDrawerToggle();
                                }}
                                selected={/^\/configuration/i.test(match.path)}
                                size={drawerSize}
                            >
                                <SpeedIcon />
                                <StyledListItemText
                                    primary={t('Configuration')}
                                />
                            </StyledListItemButton>
                        </TooltipHandler>
                    </ListItem>
                </CheckAccess>

                <CheckAccess
                    requiredPermissions={[
                        PERMISSION_LIST.GENERAL_INFORMATION_PERMISSION,
                        PERMISSION_LIST.ROLES_PERMISSION,
                        PERMISSION_LIST.USERS_PERMISSION,
                    ]}
                >
                    <ListItem disablePadding>
                        <TooltipHandler
                            drawerSize={drawerSize}
                            title={t('Company')}
                        >
                            <StyledListItemButton
                                onClick={() => {
                                    redirect('/company');
                                    if (mobileOpen) handleDrawerToggle();
                                }}
                                selected={/^\/company/i.test(match.path)}
                                size={drawerSize}
                            >
                                <BusinessIcon />
                                <StyledListItemText primary={t('Company')} />
                            </StyledListItemButton>
                        </TooltipHandler>
                    </ListItem>
                </CheckAccess>

                <ListItem disablePadding sx={{ marginTop: 'auto' }}>
                    <Box sx={{ width: '100%' }}>
                        <Divider
                            sx={{
                                marginTop: 'auto',
                                display: { sx: 'block', md: 'none' },
                            }}
                        />

                        <StyledListItemButton
                            selected={match.path === '/profile'}
                            size={drawerSize}
                            sx={{
                                p: '8px 4px',
                                alignItems: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            onClick={handleProfileMenuOpen}
                        >
                            <ProfileButton profileValues={profileValues} />

                            <Name
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {profileValues?.first_name}{' '}
                                {profileValues?.last_name}
                            </Name>

                            <IconButton
                                sx={{
                                    display: { xs: 'flex', md: 'none' },
                                    ml: 'auto',
                                }}
                            >
                                <SettingsIcon />
                            </IconButton>
                        </StyledListItemButton>
                    </Box>
                </ListItem>
            </List>

            <SecondaryDrawer
                {...{
                    container,
                    onClose: handleProfileDrawerToggle,
                    match,
                    mobileOpen: mobileOpenProfileDrawer,
                    redirect,
                }}
            >
                <ProfileMenuItems
                    {...{
                        handleCloseProfileMenu: () => {
                            handleProfileDrawerToggle();
                            handleDrawerToggle();
                        },
                        match,
                        profileValues,
                        redirect,
                        selectedTheme,
                        setTheme,
                        subscription,
                    }}
                />
            </SecondaryDrawer>

            <ProfileMenu
                anchorProfileMenu={anchorProfileMenu}
                handleDrawerToggle={handleDrawerToggle}
                match={match}
                mobileOpen={mobileOpen}
                multiBranchMode={multiBranchMode}
                profileValues={profileValues}
                redirect={redirect}
                selectedTheme={selectedTheme}
                setAnchorProfileMenu={setAnchorProfileMenu}
                setTheme={setTheme}
                subscription={subscription}
            />
        </React.Fragment>
    );
};

DrawerContent.propTypes = {
    container: PropTypes.func,
    drawerSize: PropTypes.string,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    multiBranchMode: PropTypes.bool,
    profileValues: PropTypes.object,
    redirect: PropTypes.func,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
    subscription: PropTypes.object,
    toggleDrawerSize: PropTypes.func,
};

export default DrawerContent;
