import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const DUPLICATE_TEMPLATE = `${NAME}/DUPLICATE_TEMPLATE`;
export const DUPLICATE_TEMPLATE_FAILURE = `${NAME}/DUPLICATE_TEMPLATE_FAILURE`;
export const DUPLICATE_TEMPLATE_SUCCESS = `${NAME}/DUPLICATE_TEMPLATE_SUCCESS`;

export const FETCH_TEMPLATES = `${NAME}/FETCH_TEMPLATES`;
export const FETCH_TEMPLATES_FAILURE = `${NAME}/FETCH_TEMPLATES_FAILURE`;
export const FETCH_TEMPLATES_SUCCESS = `${NAME}/FETCH_TEMPLATES_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SET_IS_OPEN_MODAL = `${NAME}/SET_IS_OPEN_MODAL`;
