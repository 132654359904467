import i18next from 'i18next';

export const NAME = 'installers';
export const COLUMN_KEYS = {
    business_name: i18next.t('Business name'),
    owner: i18next.t('Legal representative'),
    user_tracking: i18next.t('Assigned executive'),
    products: i18next.t('Product', { count: 2 }),
    phone: i18next.t('Phone'),
    city: i18next.t('City'),
    status: i18next.t('Status'),
    created_at: i18next.t('Creation date'),
};
export const REQUIRED_COLUMNS_KEYS = ['business_name', 'created_at'];
