import * as React from 'react';

import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepConnector, {
    stepConnectorClasses,
} from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Tooltip } from 'sunwise-ui';

import {
    APPROVED_STATUS,
    CLOSED_STATUS,
    CONDITIONED_APPROVED_STATUS,
    INCOMPLETE_STATUS,
    PREAPPROVED_STATUS,
    PROCESS_STATUS,
    REQUESTED_STATUS,
} from 'common/constants';
import { getStatusText } from 'common/utils/helpers';

import { ReactComponent as ApprovedStatus } from 'resources/approvedStatus.svg';
import { ReactComponent as CreditGrantedStatus } from 'resources/creditGrantedStatus.svg';
import { ReactComponent as IncompleteStatus } from 'resources/incompleteStatus.svg';
import { ReactComponent as InprocessStatus } from 'resources/inprocessStatus.svg';
import { ReactComponent as PrefeasibilityStatus } from 'resources/prefeasibilityStatus.svg';
import { ReactComponent as RequestingStatus } from 'resources/requestingStatus.svg';

import StepperTimelineIcon from './StepperTimelineIcon';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 15,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#ff9a00',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#ff9a00',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 4,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#f7f7f7',
        borderRadius: 1,
    },
}));

const getSteps = (isAlliance, status) => {
    if (isAlliance) {
        return [
            REQUESTED_STATUS,
            INCOMPLETE_STATUS,
            PROCESS_STATUS,
            APPROVED_STATUS,
            CLOSED_STATUS,
        ];
    }
    return [
        REQUESTED_STATUS,
        INCOMPLETE_STATUS,
        PROCESS_STATUS,
        PREAPPROVED_STATUS,
        status === CONDITIONED_APPROVED_STATUS
            ? CONDITIONED_APPROVED_STATUS
            : APPROVED_STATUS,
        CLOSED_STATUS,
    ];
};

const getStepIcons = (isAlliance) => {
    if (isAlliance) {
        return {
            1: <RequestingStatus />,
            2: <IncompleteStatus />,
            3: <InprocessStatus />,
            4: <PrefeasibilityStatus />,
            5: <ApprovedStatus />,
        };
    }
    return {
        1: <RequestingStatus />,
        2: <IncompleteStatus />,
        3: <InprocessStatus />,
        4: <PrefeasibilityStatus />,
        5: <ApprovedStatus />,
        6: <CreditGrantedStatus />,
    };
};

const StepperTimeline = ({
    canModify,
    isAlliance = false,
    onClick,
    status,
}) => {
    const activeStep = getSteps(isAlliance, status).indexOf(status);

    const icons = getStepIcons(isAlliance);

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
            >
                {getSteps(isAlliance, status).map((stepStatus, index) => (
                    <Tooltip
                        key={stepStatus}
                        title={
                            <Box alignItems="center" display="flex" gap={1}>
                                {icons[String(index + 1)]}{' '}
                                {getStatusText(stepStatus, isAlliance)}
                            </Box>
                        }
                    >
                        <Step>
                            <StepButton
                                onClick={() => onClick({ status: stepStatus })}
                                disabled={!canModify}
                            >
                                <StepLabel
                                    StepIconComponent={(props) => (
                                        <StepperTimelineIcon
                                            icons={icons}
                                            isAlliance={isAlliance}
                                            disabled={!canModify}
                                            {...props}
                                        />
                                    )}
                                />
                            </StepButton>
                        </Step>
                    </Tooltip>
                ))}
            </Stepper>
        </Box>
    );
};

StepperTimeline.propTypes = {
    canModify: PropTypes.bool,
    isAlliance: PropTypes.bool,
    onClick: PropTypes.func,
    status: PropTypes.string,
};

export default StepperTimeline;
