import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/template-form`)(state),
    (values) => values || {}
);

export const getBulkUpdateItemsModel = createSelector(
    getModel,
    (model) => model.bulkUpdateItems
);

export const getIsFetchingBulk = createSelector(
    getBulkUpdateItemsModel,
    (model) => model.isFetching
);

/* Fetch templates */
export const getTemplatesModel = createSelector(
    getModel,
    (model) => model.fetchTemplates
);

export const getTemplatesData = createSelector(
    getTemplatesModel,
    (model) => model.data.data || []
);

export const getTemplatesErrors = createSelector(
    getTemplatesModel,
    (model) => model.errors
);

export const getIsFetchingTemplates = createSelector(
    getTemplatesModel,
    (model) => model.isFetching
);

export const getTemplatesPagination = createSelector(
    getTemplatesModel,
    (model) => model.data.pagination || {}
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);
