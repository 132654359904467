import React from 'react';

import PropTypes from 'prop-types';
import { Breadcrumbs, Link, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

const getMaxItems = ({ breakpoint, locations } = {}) => {
    if (breakpoint === 'xs') return 2;
    if (breakpoint === 'sm') return 3;
    return locations.length + 1;
};

const CollapsedBreadcrumbs = ({ current, locations }) => {
    const breakpoint = useBreakpoint();
    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            maxItems={getMaxItems({ breakpoint, locations })}
        >
            {locations.map((location, index) => {
                return (
                    <Link
                        color="inherit"
                        href={location.link ? location.link : '#'}
                        key={`breadcrumb-${index}`}
                        onClick={location.link ? () => {} : location.action}
                        sx={{
                            fontSize: '14px',
                            '&:hover': { color: 'primary.main' },
                        }}
                        underline="hover"
                    >
                        {location.label}
                    </Link>
                );
            })}
            <Typography sx={{ color: 'primary.main', fontSize: '14px' }}>
                {current}
            </Typography>
        </Breadcrumbs>
    );
};

CollapsedBreadcrumbs.propTypes = {
    current: PropTypes.string,
    locations: PropTypes.array,
};

export default CollapsedBreadcrumbs;
