import React from 'react';

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Switch } from 'sunwise-ui';

import { logoutActions } from 'common/utils/session';

import * as profileActions from '../../../modules/profile/actions';

import StyledMenuItem from './StyledMenuItem';

const ProfileMenuItems = ({
    handleCloseProfileMenu,
    match,
    profileValues,
    redirect,
    saveConfig,
    selectedTheme,
    setTheme,
}) => {
    const { t } = useTranslation();
    const handleOnSwitchTheme = (e) => {
        const theme = e.target.checked ? 'dark' : 'light';
        setTheme(theme);
        saveConfig({ mode: theme });
    };
    const handleOnClickLogout = () => {
        handleCloseProfileMenu();
        logoutActions();
    };
    const handleOnClickProfile = () => {
        handleCloseProfileMenu();
        redirect('/profile');
    };

    return (
        <React.Fragment>
            <StyledMenuItem
                onClick={handleOnClickProfile}
                selected={match.path === '/profile'}
            >
                <PersonIcon />
                {profileValues?.first_name} {profileValues?.last_name}
            </StyledMenuItem>

            <StyledMenuItem>
                <DarkModeIcon />
                {t('Dark mode')}
                <Switch
                    checked={selectedTheme === 'dark'}
                    onChange={handleOnSwitchTheme}
                />
            </StyledMenuItem>

            <StyledMenuItem onClick={handleOnClickLogout}>
                <LogoutIcon />
                {t('Log out')}
            </StyledMenuItem>
        </React.Fragment>
    );
};

ProfileMenuItems.propTypes = {
    handleCloseProfileMenu: PropTypes.func,
    match: PropTypes.object,
    profileValues: PropTypes.object,
    redirect: PropTypes.func,
    saveConfig: PropTypes.func,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    saveConfig: (config) => dispatch(profileActions.saveConfig(config)),
});

export default compose(connect(null, mapDispatchToProps))(ProfileMenuItems);
