import React, { useEffect } from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { PropTypes } from 'prop-types';
import { useGeolocated } from 'react-geolocated';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Grid } from 'sunwise-ui';

import { ControlledMap, RoundedIcon, ShowErrors } from 'common/components';
import { ReduxFieldInput } from 'common/components/form/redux';
import {
    ItemGeocodeResult,
    ListGeocodeResult,
    WrapperGeocodeResult,
} from 'common/components/maps';
import {
    DEFAULT_LAT,
    DEFAULT_LNG,
    DEFAULT_MAP_PREVIEW_ZOOM,
} from 'common/constants';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const LocationForm = ({
    canUpdate,
    changeInput,
    creditId,
    errors,
    fetchGeocodePoint,
    formValues,
    geocodePointData,
    geocodeResult,
    handleClose,
    handleGeocode,
    handleOnSave,
    handleSubmit,
    initialValues,
    isFetchingGeocodePoint,
    isSaving,
    setEmptyGeocodeResult,
    setShowGeocodeResults,
    showGeocodeResults,
}) => {
    const { t } = useTranslation();

    const { coords, getPosition, isGeolocationEnabled } = useGeolocated({
        positionOptions: { enableHighAccuracy: false },
        userDecisionTimeout: 5000,
        suppressLocationOnMount: true,
    });

    const position = get(formValues, 'position', {});

    useEffect(() => {
        if (coords) updateLocation(coords);
    }, [coords]);

    useEffect(() => {
        if (!isNil(geocodePointData))
            changeInput('description', geocodePointData);
    }, [geocodePointData]);

    useEffect(() => {
        if (isEmpty(position)) return;
        const { latitude, longitude } = position;
        changeInput('latitude', latitude);
        changeInput('longitude', longitude);
    }, [position]);

    useEffect(() => {
        if (
            formValues.latitude !== initialValues.latitude ||
            formValues.longitude !== initialValues.longitude
        )
            fetchGeocodePoint(formValues.latitude, formValues.longitude);
    }, [formValues.latitude, formValues.longitude]);

    const disabledButton = isEqual(initialValues, formValues);

    const isDisabled = !canUpdate ? true : false;

    const updateLocation = (position) => {
        if (isEmpty(position)) return;
        const { latitude, longitude } = position;
        changeInput('position', { latitude, longitude });
        changeInput('latitude', latitude);
        changeInput('longitude', longitude);
    };

    const handleClickSave = (values) =>
        handleOnSave({ ...values, creditId }, () => handleClose());

    return (
        <Box component="form">
            <Grid container>
                <Grid item xs>
                    {geocodeResult &&
                        geocodeResult.length > 0 &&
                        showGeocodeResults && (
                            <WrapperGeocodeResult>
                                <ListGeocodeResult
                                    style={{
                                        transform:
                                            'translate3d(0px, 38px, 0px)',
                                    }}
                                >
                                    {geocodeResult.map((result, i) => (
                                        <ItemGeocodeResult
                                            key={i}
                                            onClick={() => {
                                                changeInput(
                                                    'description',
                                                    result.formatted_address
                                                );

                                                const { lat, lng } =
                                                    result.geometry.location;
                                                updateLocation({
                                                    latitude: lat,
                                                    longitude: lng,
                                                });
                                            }}
                                        >
                                            {result.formatted_address}
                                        </ItemGeocodeResult>
                                    ))}
                                </ListGeocodeResult>
                            </WrapperGeocodeResult>
                        )}

                    <Field
                        component={ReduxFieldInput}
                        disabled={isDisabled || isFetchingGeocodePoint}
                        name="description"
                        onChange={({ target }) => {
                            if (target.value.length >= 6)
                                handleGeocode(target.value);
                            else setEmptyGeocodeResult();
                            changeInput('description', target.value);
                        }}
                        onBlur={() => {
                            setTimeout(() => {
                                setShowGeocodeResults(false);
                            }, 150);
                        }}
                        onFocus={() => setShowGeocodeResults(true)}
                        placeholder={t('Location')}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs>
                    <Field
                        component={ReduxFieldInput}
                        disabled={isDisabled}
                        fullWidth
                        name="latitude"
                        placeholder={t('Latitude')}
                    />
                </Grid>

                <Grid item xs>
                    <Field
                        component={ReduxFieldInput}
                        disabled={isDisabled}
                        fullWidth
                        name="longitude"
                        placeholder={t('Longitude')}
                    />
                </Grid>

                <Grid item>
                    <RoundedIcon
                        bgColor="#1F3C53"
                        borderColor="#1F3C53"
                        customSize="36"
                        disabled={!isGeolocationEnabled || isSaving}
                        icon="fas fa-location-arrow"
                        iconColor="#fff"
                        onClick={() => {
                            if (!isNil(coords)) {
                                const { latitude, longitude } = coords;
                                updateLocation({ latitude, longitude });
                            } else getPosition();
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs>
                    <ControlledMap
                        center={{
                            lat: formValues.latitude || DEFAULT_LAT,
                            lng: formValues.longitude || DEFAULT_LNG,
                        }}
                        disableDefaultUI={isDisabled}
                        heightMap="206px"
                        onChange={(position) =>
                            changeInput('position', position)
                        }
                        readOnly={isDisabled}
                        title={t('Location')}
                        value={formValues.location}
                        zoom={DEFAULT_MAP_PREVIEW_ZOOM}
                    />
                </Grid>
            </Grid>

            <Grid container mt={2}>
                <Grid
                    item
                    xs
                    sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        justifyContent: { md: 'right', xs: 'center' },
                    }}
                >
                    <Button
                        color="secondary"
                        onClick={handleClose}
                        sx={{
                            mr: { md: 2, xs: 0 },
                            order: { md: 1, xs: 2 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        color="secondary"
                        disabled={disabledButton}
                        onClick={handleSubmit(handleClickSave)}
                        sx={{
                            mb: { md: 0, xs: 2 },
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        variant="outlined"
                    >
                        {isSaving ? t('Saving') : t('Save')}
                    </Button>
                </Grid>
            </Grid>

            <ShowErrors errors={errors} />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getSaveLocationErrors,
    formValues: selectors.getLocationValues,
    geocodePointData: selectors.getGeocodePointData,
    geocodeResult: selectors.getFetchGeocodeData,
    initialValues: selectors.getLocationInitialValues,
    isFetchingGeocodePoint: selectors.getIsFetchingGeocodePoint,
    isSaving: selectors.getIsSavingLocation,
    issDeletingLocation: selectors.getIsDeletingLocation,
    showGeocodeResults: selectors.showGeocodeResults,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/location-form`, field, value)),
    fetchGeocodePoint: (lat, lng) =>
        dispatch(actions.fetchGeocodePoint(lat, lng)),
    handleGeocode: (str) => dispatch(actions.fetchGeocodeResult(str)),
    handleOnSave: (values, callback) =>
        dispatch(actions.saveLocation(values, callback)),
    setEmptyGeocodeResult: () => dispatch(actions.setEmptyGeocodeResult()),
    setShowGeocodeResults: (value) =>
        dispatch(actions.setShowGeocodeResults(value)),
});

LocationForm.propTypes = {
    canUpdate: PropTypes.bool,
    changeInput: PropTypes.func,
    creditId: PropTypes.string,
    errors: PropTypes.object,
    fetchGeocodePoint: PropTypes.func,
    formValues: PropTypes.object,
    geocodePointData: PropTypes.object,
    geocodeResult: PropTypes.array,
    handleClose: PropTypes.func,
    handleGeocode: PropTypes.func,
    handleOnSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingGeocodePoint: PropTypes.bool,
    isSaving: PropTypes.bool,
    setEmptyGeocodeResult: PropTypes.func,
    setShowGeocodeResults: PropTypes.func,
    showGeocodeResults: PropTypes.bool,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/location-form`,
    validate,
})(LocationForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
