import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteDocument from './deleteDocument';

export default (
        handleClickDocumentItem,
        handleClickProposalItem,
        proposalDocumentDefault,
        proposalId,
        smartDocumentId
    ) =>
    (dispatch) => {
        const dataAlert = {
            confirmText: i18next.t('Delete'),
            haveTextCaptcha: true,
            messages: [
                i18next.t(
                    'Once you delete this document you will not be able to recover it. Are you sure you want to continue?'
                ),
            ],
            onSuccess: () =>
                dispatch(
                    deleteDocument(
                        handleClickDocumentItem,
                        handleClickProposalItem,
                        proposalDocumentDefault,
                        proposalId,
                        smartDocumentId
                    )
                ),
            textToValidate: i18next.t('Delete').toUpperCase(),
            title: i18next.t('Confirmation'),
            type: alerts.ALERT_TYPE_CONFIRM,
            variant: 'warning',
        };

        dispatch(alerts.actions.show(dataAlert));
    };
