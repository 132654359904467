import { get, isEmpty } from 'lodash';

import { getRoleLevels } from 'common/api/roles';

import {
    FETCH_ROLE_LEVELS,
    FETCH_ROLE_LEVELS_FAILURE,
    FETCH_ROLE_LEVELS_SUCCESS,
} from '../actionTypes';
import { ROLE_LEVELS } from '../constants';
import { companyRolesActions } from '../reducer';

import fetchRoleLevelDetails from './fetchRoleLevelDetails';
import initializeForm from './initializeForm';

export default (isNewRecord) => (dispatch) => {
    dispatch(companyRolesActions[FETCH_ROLE_LEVELS]());

    getRoleLevels()
        .then((response) => {
            const data = get(response, 'data.data', {});
            dispatch(companyRolesActions[FETCH_ROLE_LEVELS_SUCCESS](data));
            if (isNewRecord) {
                const levels = data.filter(
                    ({ level }) => level !== ROLE_LEVELS.OWNER
                );
                const levelId = get(levels[0], 'id', null);
                dispatch(
                    initializeForm({
                        financier_group_type: !isEmpty(data) ? levelId : null,
                        alliances_visualization_level: 0,
                    })
                );
                if (levelId) dispatch(fetchRoleLevelDetails(levelId));
            }
        })
        .catch((error) =>
            dispatch(
                companyRolesActions[FETCH_ROLE_LEVELS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
