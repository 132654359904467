import { getFinancierExecutives } from 'common/api/companies';

import {
    FETCH_EXECUTIVES,
    FETCH_EXECUTIVES_FAILURE,
    FETCH_EXECUTIVES_SUCCESS,
} from '../actionTypes';
import { installersActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(installersActions[FETCH_EXECUTIVES]());

    getFinancierExecutives()
        .then((response) =>
            dispatch(
                installersActions[FETCH_EXECUTIVES_SUCCESS](response.data.data)
            )
        )
        .catch((error) =>
            dispatch(
                installersActions[FETCH_EXECUTIVES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
