import React, { useContext, useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Container as MaterialContainer, UiDialog as Dialog } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissions';
import withPermissions from 'common/hocs/withPermissions';
import * as customColumnsSelectors from 'common/modules/customColumns/selectors';
import { LoadingContext } from 'common/utils/contexts';

import companyUsers from '../companyUsers';

import * as actions from './actions';
import ChangeStatusForm from './components/ChangeStatusForm';
import ListItems from './components/ListItems';
import SelectUserForm from './components/ReassignAdvisorForm';
import { creditsFormatted, getStatusOptions } from './helpers';
import * as selectors from './selectors';

const Container = ({
    alliances,
    canModify,
    credits,
    downloadFile,
    fetchAlliances,
    fetchUsers,
    filterCredits,
    handleClickChangeStatus,
    handleClickReassignAdvisor,
    handleCloseModalChangeStatus,
    handleCloseModalReassignAdvisor,
    handleSaveRow,
    isDownloading,
    isFetching,
    isFetchingAlliances,
    isFetchingTableSettings,
    isOpenModalChangeStatus,
    isOpenModalReassingAdvisor,
    pagination,
    tableSettings,
    users,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [filterData, setFilterData] = useState({
        allianceId: '',
        financialAdvisor: '',
        orderBy: null,
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: null,
        status: [],
    });

    useEffect(() => {
        fetchUsers();
        fetchAlliances();
        filterCredits(filterData);
    }, []);

    useEffect(() => {
        if (isEmpty(loadingContext)) return;
        if (isDownloading) loadingContext.openLoading(t('Downloading'));
        else loadingContext.closeLoading();
    }, [isDownloading]);

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <TitleIcon title={t('Request', { count: 2 })} />
            </HeaderContainer>

            <ListItems
                alliances={alliances}
                canModify={canModify}
                downloadFile={downloadFile}
                filterData={filterData}
                filterItems={filterCredits}
                handleClickChangeStatus={handleClickChangeStatus}
                handleClickReassignAdvisor={handleClickReassignAdvisor}
                handleSaveRow={handleSaveRow}
                isFetching={isFetching || isFetchingTableSettings}
                isFetchingAlliances={isFetchingAlliances}
                isOpenModalChangeStatus={isOpenModalChangeStatus}
                isOpenModalReassingAdvisor={isOpenModalReassingAdvisor}
                items={creditsFormatted(credits)}
                paginationData={pagination}
                setFilterData={setFilterData}
                statusList={getStatusOptions()}
                tableSettings={tableSettings}
                users={users}
            />
            <Dialog
                onClose={() => handleCloseModalReassignAdvisor()}
                open={isOpenModalReassingAdvisor}
                size="sm"
                title={t('Change executive')}
            >
                <SelectUserForm filterData={filterData} users={users} />
            </Dialog>
            <Dialog
                onClose={() => handleCloseModalChangeStatus()}
                open={isOpenModalChangeStatus}
                size="sm"
                title={t('Change status')}
            >
                <ChangeStatusForm
                    filterData={filterData}
                    statusList={getStatusOptions()}
                />
            </Dialog>
        </MaterialContainer>
    );
};

Container.propTypes = {
    alliances: PropTypes.array,
    canModify: PropTypes.bool,
    credits: PropTypes.array,
    downloadFile: PropTypes.func,
    fetchAlliances: PropTypes.func,
    fetchUsers: PropTypes.func,
    filterCredits: PropTypes.func,
    handleClickChangeStatus: PropTypes.func,
    handleClickReassignAdvisor: PropTypes.func,
    handleCloseModalChangeStatus: PropTypes.func,
    handleCloseModalReassignAdvisor: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isDownloading: PropTypes.bool,
    isFetching: PropTypes.bool,
    isFetchingAlliances: PropTypes.bool,
    isFetchingTableSettings: PropTypes.bool,
    isOpenModalChangeStatus: PropTypes.bool,
    isOpenModalReassingAdvisor: PropTypes.bool,
    pagination: PropTypes.object,
    tableSettings: PropTypes.array,
    users: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    alliances: selectors.getDataAlliances,
    credits: selectors.getCreditsData,
    isDownloading: selectors.getIsDownloading,
    isFetching: selectors.getCreditsIsFetching,
    isFetchingAlliances: selectors.getIsFetchingAlliances,
    isFetchingTableSettings: customColumnsSelectors.getIsFetchingTableSettings,
    isOpenModalChangeStatus: selectors.getIsOpenModalChangeStatus,
    isOpenModalReassingAdvisor: selectors.getIsopenModalReassignAdvisor,
    pagination: selectors.getCreditsPagination,
    tableSettings: customColumnsSelectors.getTableSettingsData,
    users: companyUsers.selectors.getFetchItemsListData,
});

const mapDispatchToProps = (dispatch) => ({
    downloadFile: (filterData) => dispatch(actions.downloadFile(filterData)),
    fetchAlliances: () => dispatch(actions.fetchAllianceList()),
    fetchUsers: () => dispatch(companyUsers.actions.fetchItems()),
    filterCredits: (filterData) => dispatch(actions.filterCredits(filterData)),
    handleClickChangeStatus: (ids, callback) =>
        dispatch(actions.prepareChangeStatus(ids, callback)),
    handleCloseModalChangeStatus: () =>
        dispatch(actions.closeModalChangeStatus()),
    handleClickReassignAdvisor: (ids, callback) =>
        dispatch(actions.prepareReassignAdvisor(ids, callback)),
    handleCloseModalReassignAdvisor: () =>
        dispatch(actions.closeModalReassignAdvisor()),
    handleSaveRow: (filterData, item) =>
        dispatch(actions.updateProduct(filterData, item)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.ALLIANCE_PRODUCT_APPLICATIONS_PERMISSION)
)(withRouter(Container));
