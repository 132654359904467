import React from 'react';

import SendIcon from '@mui/icons-material/Send';
import SyncIcon from '@mui/icons-material/Sync';
import { styled } from '@mui/material/styles';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Typography } from 'sunwise-ui';

import {
    APPROVED_STATUS,
    PROCESS_STATUS,
    REJECTED_STATUS,
    REQUESTED_STATUS,
} from 'common/constants';
import {
    getFileExtensionByPath,
    isCompressedFileSupported,
    isImageFileSupported,
} from 'common/utils/helpers';

import { ReactComponent as TextDocument } from 'resources/textDocumentIcon.svg';

import FileUploader from './components/FileUploader';
import FileUploaderButton from './components/FileUploaderButton';
import * as selectors from './selectors';

const StyledForm = styled('form')`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const FilePreview = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    img {
        max-height: 90%;
        max-width: 100%;
    }
`;

const Actions = styled('div')`
    bottom: 50px;
    display: flex;
    gap: 16px;
    justify-content: center;
    position: absolute;
    text-align: center;
    width: 100%;
`;

const ReplaceContainer = styled('div')`
    z-index: 1;
`;

const FormContainer = ({
    handleSend,
    initialFile,
    initialValues,
    onClickChangeStatus,
    onClickReject,
    status,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, setValue, watch } = useForm({
        defaultValues: initialValues,
    });

    const fileValue = watch('file');

    const handleSave = (values) =>
        handleSend(values.file && values.file[0], () => removeFile());

    const removeFile = () => setValue('file', null);

    const renderPreview = (
        tempUrl,
        isImage,
        isCompressedFile,
        isFileLoaded
    ) => {
        if (isImage) return <img alt="" src={tempUrl} />;
        if (isCompressedFile || isFileLoaded) return <TextDocument />;
        return (
            <embed
                height="100%"
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${tempUrl}`}
                width="100%"
            />
        );
    };

    const renderStatusActionButtons = (status) => {
        if (status === REQUESTED_STATUS || status === PROCESS_STATUS)
            return (
                <Box display="flex" gap={1}>
                    <Button
                        onClick={() => onClickChangeStatus(APPROVED_STATUS)}
                        variant="outlined"
                        sx={{ backgroundColor: '#4EC752' }}
                    >
                        <span>{t('Approve')}</span>
                    </Button>

                    <Button
                        onClick={onClickReject}
                        sx={{ backgroundColor: '#D54242' }}
                    >
                        <span>{t('Reject')}</span>
                    </Button>
                </Box>
            );

        if (status === REJECTED_STATUS)
            return (
                <Box display="flex" gap={1}>
                    <Button
                        onClick={() => onClickChangeStatus(PROCESS_STATUS)}
                        variant="outlined"
                    >
                        <span>{PROCESS_STATUS}</span>
                    </Button>

                    <Button
                        onClick={() => onClickChangeStatus(APPROVED_STATUS)}
                        sx={{ backgroundColor: '#4EC752' }}
                    >
                        <span>{t('Approve')}</span>
                    </Button>
                </Box>
            );

        return (
            <Box display="flex" gap={1}>
                <Button
                    onClick={() => onClickChangeStatus(PROCESS_STATUS)}
                    variant="outlined"
                >
                    <span>{PROCESS_STATUS}</span>
                </Button>

                <Button
                    onClick={onClickReject}
                    sx={{ backgroundColor: '#D54242' }}
                >
                    <span>{t('Reject')}</span>
                </Button>
            </Box>
        );
    };

    const finalUrl = initialFile?.includes('https')
        ? initialFile
        : `${process.env.REACT_APP_API_URL}${initialFile}`;

    const renderContent = () => {
        if (!isArray(fileValue) && initialFile === null)
            return (
                <FileUploader
                    control={control}
                    name="file"
                    setValue={setValue}
                />
            );

        if (isArray(fileValue))
            return (
                <>
                    <FilePreview>
                        {renderPreview(
                            watch('file.0.preview'),
                            isImageFileSupported(watch('file.0.path')),
                            isCompressedFileSupported(watch('file.0.path')),
                            true
                        )}

                        <Typography variant="body2" fontWeight="bold" p={2}>
                            Archivo{' '}
                            {getFileExtensionByPath(
                                watch('file.0.path')
                            ).toUpperCase()}
                        </Typography>
                    </FilePreview>

                    <Actions>
                        <Button
                            onClick={handleSubmit(handleSave)}
                            startIcon={<SendIcon />}
                        >
                            {t('Send')}
                        </Button>

                        <Button
                            color="secondary"
                            onClick={removeFile}
                            startIcon={<SyncIcon />}
                        >
                            {t('Change')}
                        </Button>
                    </Actions>
                </>
            );

        return (
            <FilePreview>
                <ReplaceContainer>
                    <Typography fontWeight="bold" variant="body2">
                        {t('Current status')}: {status}
                    </Typography>

                    <Box display="flex" gap={1}>
                        <FileUploaderButton
                            control={control}
                            name="file"
                            setValue={setValue}
                        />

                        {renderStatusActionButtons(status)}
                    </Box>
                </ReplaceContainer>

                {renderPreview(
                    finalUrl,
                    isImageFileSupported(finalUrl),
                    isCompressedFileSupported(finalUrl)
                )}

                <Typography variant="body2" fontWeight="bold" p={2}>
                    {t('File')} {getFileExtensionByPath(finalUrl).toUpperCase()}
                </Typography>
            </FilePreview>
        );
    };

    return <StyledForm>{renderContent()}</StyledForm>;
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

FormContainer.propTypes = {
    handleSend: PropTypes.func,
    initialFile: PropTypes.string,
    initialValues: PropTypes.object,
    onClickChangeStatus: PropTypes.func,
    onClickReject: PropTypes.func,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default connect(mapStateToProps)(FormContainer);
