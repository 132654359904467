import API from '..';
const ENTITY = 'installers';

export const getInstallers = () => API.get(`/api/v1/${ENTITY}/`);
export const getInstaller = (id) => API.get(`/api/v1/${ENTITY}/${id}`);
export const getProposalsTotals = (
    initialDate,
    finalDate,
    installerId = null
) =>
    API.get(
        `/api/v1/${ENTITY}/graph/${installerId}?initial_date=${initialDate}&final_date=${finalDate}`
    );
export const updateInstaller = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });

export const updateInstallerUsers = (id) =>
    API.put(`/api/v1/${ENTITY}/companies-users/${id}`);
