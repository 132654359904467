import initializeReassignAdvisorForm from './initializeReassignAdvisorForm';
import openModalReassignAdvisor from './openModalReassignAdvisor';

export default (ids, callback) => (dispatch) => {
    const values = {
        callback,
        credits: ids,
    };
    dispatch(initializeReassignAdvisorForm(values));
    dispatch(openModalReassignAdvisor(true));
};
