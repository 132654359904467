import { createItem, updateItem } from 'common/api/users';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { companyUsersActions } from '../reducer';

import filterItems from './filterItems';
import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

export default (filterData, values) => (dispatch) => {
    const newValues = {
        email: values.email,
        financier: values.financier,
        financier_group: values.financier_group,
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
        second_surname: values.second_surname,
    };

    if (!values.id) {
        newValues.password = values.password;
        newValues.confirm_password = values.confirm_password;
    }

    const save = values.id
        ? () => updateItem(newValues, values.id)
        : () => createItem(newValues);

    dispatch(companyUsersActions[SAVE_ITEM]());

    save()
        .then(() => {
            dispatch(companyUsersActions[SAVE_ITEM_SUCCESS]());
            dispatch(toggleModalProduct(false));
            dispatch(resetForm());
            dispatch(filterItems(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch(
                companyUsersActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
