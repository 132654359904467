import React, { useState, useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Button, Container as MaterialContainer, Grid } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissions';
import withPermissions from 'common/hocs/withPermissions';
import { getFinancierType } from 'common/utils/session';

import * as brightSelectors from '../financialBright/selectors';
import * as broveSelectors from '../financialBrove/selectors';
import * as ceideSelectors from '../financialCeide/selectors';
import * as ciBancoSelectors from '../financialCiBanco/selectors';
import * as galtSelectors from '../financialGalt/selectors';
import * as redGirasolSelectors from '../financialRedGirasol/selectors';
import * as serfimexSelectors from '../financialSerfimex/selectors';

import * as actions from './actions';
import {
    FinancialBright,
    FinancialBrove,
    FinancialCeide,
    FinancialCiBanco,
    FinancialGalt,
    FinancialRedGirasol,
    FinancialSerfimex,
} from './components';
import ListItems from './components/ListItems';
import * as selectors from './selectors';

const Container = ({
    canDelete,
    canModify,
    fetchProducts,
    handleClickBulkItems,
    handleClickDuplicate,
    handleSaveRow,
    isFetching,
    isFetchingBulk,
    modalBrightIsOpen,
    modalBroveIsOpen,
    modalCeideIsOpen,
    modalCiBancoIsOpen,
    modalGaltIsOpen,
    modalRedGirasolIsOpen,
    modalSerfimexIsOpen,
    paginationData,
    prepareCreate,
    prepareUpdate,
    products,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        sortBy: 'desc',
        status: 'active',
    });

    useEffect(() => {
        fetchProducts(filterData);
    }, []);

    const financierType = getFinancierType();

    const handleClickPreview = (product) =>
        prepareUpdate(financierType, product, true);

    const handleClickUpdate = (product) =>
        prepareUpdate(financierType, product, false);

    return (
        <React.Fragment>
            <MaterialContainer maxWidth={false}>
                <HeaderContainer>
                    <TitleIcon title={t('Alliance Products')} />
                </HeaderContainer>

                <Grid container mb={2}>
                    <Grid
                        hidden={!canModify}
                        item
                        sx={{ textAlign: 'right' }}
                        xs={18}
                    >
                        <Button
                            endIcon={<AddIcon />}
                            onClick={() => prepareCreate()}
                            sx={{ width: { md: 'auto', xs: '100%' } }}
                            variant="outlined"
                        >
                            {t('Add Product')}
                        </Button>
                    </Grid>
                </Grid>

                <ListItems
                    canDelete={canDelete}
                    canModify={canModify}
                    filterData={filterData}
                    filterItems={fetchProducts}
                    handleClickBulkItems={handleClickBulkItems}
                    handleClickDuplicate={handleClickDuplicate}
                    handleClickPreview={handleClickPreview}
                    handleClickUpdate={handleClickUpdate}
                    handleSaveRow={handleSaveRow}
                    isFetching={isFetching || isFetchingBulk}
                    items={products}
                    paginationData={paginationData}
                    setFilterData={setFilterData}
                />
            </MaterialContainer>

            {modalCiBancoIsOpen && (
                <FinancialCiBanco
                    callback={() => fetchProducts(filterData)}
                    canDelete={canDelete}
                    filterData={filterData}
                    financierType={financierType}
                    handleClickBulkItems={handleClickBulkItems}
                    padding="16px 48px"
                />
            )}

            {modalBrightIsOpen && (
                <FinancialBright
                    callback={() => fetchProducts(filterData)}
                    canDelete={canDelete}
                    filterData={filterData}
                    financierType={financierType}
                    handleClickBulkItems={handleClickBulkItems}
                    padding="16px 48px"
                />
            )}

            {modalRedGirasolIsOpen && (
                <FinancialRedGirasol
                    callback={() => fetchProducts(filterData)}
                    financierType={financierType}
                    padding="16px 48px"
                />
            )}

            {modalBroveIsOpen && (
                <FinancialBrove
                    callback={() => fetchProducts(filterData)}
                    financierType={financierType}
                    padding="16px 48px"
                />
            )}

            {modalCeideIsOpen && (
                <FinancialCeide
                    callback={() => fetchProducts(filterData)}
                    financierType={financierType}
                    padding="16px 48px"
                />
            )}

            {modalGaltIsOpen && (
                <FinancialGalt
                    callback={() => fetchProducts(filterData)}
                    financierType={financierType}
                    padding="16px 48px"
                />
            )}

            {modalSerfimexIsOpen && (
                <FinancialSerfimex
                    callback={() => fetchProducts(filterData)}
                    canDelete={canDelete}
                    filterData={filterData}
                    financierType={financierType}
                    handleClickBulkItems={handleClickBulkItems}
                    padding="16px 48px"
                />
            )}
        </React.Fragment>
    );
};

Container.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    fetchProducts: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingBulk: PropTypes.bool,
    modalBrightIsOpen: PropTypes.bool,
    modalBroveIsOpen: PropTypes.bool,
    modalCeideIsOpen: PropTypes.bool,
    modalCiBancoIsOpen: PropTypes.bool,
    modalGaltIsOpen: PropTypes.bool,
    modalRedGirasolIsOpen: PropTypes.bool,
    modalSerfimexIsOpen: PropTypes.bool,
    paginationData: PropTypes.object,
    prepareCreate: PropTypes.func,
    prepareUpdate: PropTypes.func,
    products: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getProductsIsFetching,
    isFetchingBulk: selectors.getIsFetchingBulk,
    modalBrightIsOpen: brightSelectors.getProductModalIsOpen,
    modalBroveIsOpen: broveSelectors.getProductModalIsOpen,
    modalCeideIsOpen: ceideSelectors.getProductModalIsOpen,
    modalCiBancoIsOpen: ciBancoSelectors.getProductModalIsOpen,
    modalGaltIsOpen: galtSelectors.getProductModalIsOpen,
    modalRedGirasolIsOpen: redGirasolSelectors.getProductModalIsOpen,
    modalSerfimexIsOpen: serfimexSelectors.getProductModalIsOpen,
    paginationData: selectors.getProductsPagination,
    products: selectors.getProductsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProducts: (filterData) => dispatch(actions.fetchProducts(filterData)),
    handleClickBulkItems: (ids, value, filterData, callback) =>
        dispatch(actions.prepareBulkItems(ids, value, filterData, callback)),
    handleClickDuplicate: (product) =>
        dispatch(actions.duplicateProduct(product)),
    handleSaveRow: (filterData, item) =>
        dispatch(actions.updateProduct(filterData, item)),
    prepareCreate: (type) => dispatch(actions.prepareCreate(type)),
    prepareUpdate: (type, product, disabled) =>
        dispatch(actions.prepareUpdate(type, product, disabled)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.ALLIANCE_PRODUCTS_PERMISSION)
)(Container);
