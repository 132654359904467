import is from 'is_js';
import get from 'lodash/get';

import { updateItem } from 'common/api/users';
import { isJsonString, showReponseErrorsAsAlert } from 'common/utils/helpers';
import { initSessionActions } from 'common/utils/session';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { profileActions } from '../reducer';

import setCurrentValues from './setCurrentValues';

export default (values) => (dispatch) => {
    let newValues = {
        first_name: values.first_name,
        last_name: values.last_name,
        phone: values.phone,
    };

    if (is.array(values.profile_image))
        newValues.profile_image =
            values.profile_image && values.profile_image[0];

    dispatch(profileActions[SAVE]());

    updateItem(newValues, values.id)
        .then((response) => {
            dispatch(profileActions[SAVE_SUCCESS]());
            const json = localStorage.getItem('session');
            const session = isJsonString(json)
                ? JSON.parse(localStorage.getItem('session'))
                : null;

            if (session && get(response, 'data.profile_image', '')) {
                session.first_name = response.data.first_name;
                session.last_name = response.data.last_name;
                session.phone = response.data.phone;
                session.profile_image =
                    get(response, 'data.profile_image', '') +
                    '?' +
                    new Date().getTime();

                localStorage.setItem('session', JSON.stringify(session));
            }

            dispatch(
                setCurrentValues({
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    phone: response.data.phone,
                    profile_image:
                        response.data.profile_image +
                        '?' +
                        new Date().getTime(),
                })
            );

            initSessionActions();
            showToast();
        })
        .catch((error) => {
            dispatch(
                profileActions[SAVE_FAILURE](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
