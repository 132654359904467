import React from 'react';

import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Box } from 'sunwise-ui';

import SortableItem from './SortableItem';

const SortableList = ({
    canModify,
    companiesCatalogs,
    id,
    onDragEnd,
    prepareDelete,
    prepareUpdate,
    type,
}) => (
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={id}>
            {(provided) => (
                <Box ref={provided.innerRef} {...provided.droppableProps}>
                    {companiesCatalogs.map((companyCatalog, index) => (
                        <SortableItem
                            canModify={canModify}
                            companyCatalog={companyCatalog}
                            id={`item-${companyCatalog.id}`}
                            index={index}
                            key={`item-${companyCatalog.id}`}
                            prepareDelete={prepareDelete}
                            prepareUpdate={prepareUpdate}
                            type={type}
                        />
                    ))}

                    {provided.placeholder}
                </Box>
            )}
        </Droppable>
    </DragDropContext>
);

SortableList.propTypes = {
    canModify: PropTypes.bool,
    companiesCatalogs: PropTypes.array,
    id: PropTypes.string,
    onDragEnd: PropTypes.func,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export default SortableList;
