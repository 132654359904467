import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Grid, Typography } from 'sunwise-ui';

import {
    ReduxFieldInput,
    ReduxFieldSelect,
} from 'common/components/form/redux';
import ShowErrors from 'common/components/ShowErrors';
import { arraytoDictionary } from 'common/utils/helpers';

import * as actions from '../actions';
import { NAME } from '../constants';
import {
    getAllianceViewLevelOptions,
    getIsDisabledFields,
    getRoleLevelOptions,
} from '../helpers';
import * as selectors from '../selectors';
import validate from '../validate';

import PermissionList from './PermissionList';

const RoleForm = ({
    changeInput,
    errors,
    fetchRoleLevelDetails,
    fetchRoleLevels,
    financierGroupType,
    formValues,
    handleClickCloseModal,
    handleClickSave,
    handleSubmit,
    isFetchingRoleGroups,
    isFetchingRoleLevels,
    isNewRecord,
    isSaving,
    roleLevels,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchRoleLevels(isNewRecord);
    }, []);

    const roleLevelDictionary = arraytoDictionary(roleLevels, 'id');

    const isDisabled = getIsDisabledFields({
        financierGroupType,
        isFetchingRoleLevels,
        isNewRecord,
        roleLevel: roleLevelDictionary[formValues?.financier_group_type] || {},
    });

    return (
        <form onSubmit={handleSubmit(handleClickSave)}>
            <Grid container>
                <Grid item xs={5}>
                    <Box
                        sx={{
                            backgroundColor: '#EEF4FA',
                            borderBottomLeftRadius: '18px',
                            borderTopLeftRadius: '18px',
                            display: 'flex',
                            flexDirection: 'column',
                            mb: 2,
                            minHeight: '100%',
                            p: 2,
                        }}
                    >
                        <Typography fontWeight="bold" mb={1} variant="body2">
                            {t('Role')}
                        </Typography>

                        <Field
                            component={ReduxFieldInput}
                            disabled={isDisabled}
                            label={`${t('Role name')} *`}
                            name="name"
                            variant="standard"
                        />

                        <Field
                            component={ReduxFieldSelect}
                            disabled={isDisabled}
                            label={`${t('Role level')} *`}
                            name="financier_group_type"
                            onChange={(_, value) => {
                                fetchRoleLevelDetails(value);
                            }}
                            options={getRoleLevelOptions(roleLevels)}
                            sxBox={{ my: 2 }}
                            variant="standard"
                        />

                        <Field
                            component={ReduxFieldSelect}
                            disabled={isDisabled}
                            label={`${t('Display level')} *`}
                            name="alliances_visualization_level"
                            options={getAllianceViewLevelOptions()}
                            sxBox={{ mb: 2 }}
                            variant="standard"
                        />
                    </Box>
                </Grid>

                <Grid item xs={13}>
                    <PermissionList
                        changeInput={changeInput}
                        formValues={formValues}
                        isDisabled={isDisabled}
                        isFetching={isFetchingRoleGroups}
                        roleLevel={
                            roleLevelDictionary[formValues.financier_group_type]
                        }
                    />
                </Grid>
            </Grid>

            <ShowErrors errors={errors} />

            <Divider sx={{ my: 2 }} />

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                <Button
                    color="secondary"
                    onClick={handleClickCloseModal}
                    variant="text"
                >
                    {t('Cancel')}
                </Button>

                <Button disabled={isDisabled || isSaving} type="submit">
                    {isNewRecord ? t('Add') : t('Save')}
                </Button>
            </Box>
        </form>
    );
};

RoleForm.propTypes = {
    changeInput: PropTypes.func,
    errors: PropTypes.array,
    fetchRoleLevelDetails: PropTypes.func,
    fetchRoleLevels: PropTypes.func,
    financierGroupType: PropTypes.object,
    formValues: PropTypes.object,
    handleClickCloseModal: PropTypes.func,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    isFetchingRoleGroups: PropTypes.bool,
    isFetchingRoleLevels: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    roleLevels: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isFetchingRoleGroups: selectors.getIsFetchingRoleLevelDetails,
    isFetchingRoleLevels: selectors.getIsFetchingRoleLevels,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
    roleLevels: selectors.getRoleLevelsData,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/role-form`, field, value)),
    fetchRoleLevelDetails: (levelId) =>
        dispatch(actions.fetchRoleLevelDetails(levelId)),
    fetchRoleLevels: (isNewRecord) =>
        dispatch(actions.fetchRoleLevels(isNewRecord)),
    handleClickSave: (values, company) =>
        dispatch(actions.save(values, company)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/role-form`,
    validate,
})(RoleForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
