import i18next from 'i18next';
import { types } from 'sunwise-template-core';

import { createSmartDocument } from 'common/api/smartDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { getDefaultName } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_SMART_DOCUMENT,
    SAVE_SMART_DOCUMENT_FAILURE,
    SAVE_SMART_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { templateDocumentsActions } from '../reducer';
import * as selectors from '../selectors';

import fetchDocuments from './fetchDocuments';
import openDocumentModal from './openDocumentModal';

export default (
        handleClickDocumentItem,
        initTemplate,
        proposalId,
        resetTemplate,
        values
    ) =>
    (dispatch, getState) => {
        const state = getState();
        const documentTemplates = selectors.getTemplatesData(state);
        const smartDocuments = selectors.getSmartDocumentsData(state);

        const { commercial_offer_id, template } = values;

        const templateName = documentTemplates.filter(
            (item) => item.id === values.template
        )?.[0]?.title;

        const name = getDefaultName({
            arrayItems: smartDocuments,
            preffix: i18next.t('Document'),
            projectName: templateName,
        });

        const newValues = {
            commercial_offer_id,
            template,
            name,
        };

        dispatch(templateDocumentsActions[SAVE_SMART_DOCUMENT]());

        createSmartDocument(newValues)
            .then((response) => {
                dispatch(
                    templateDocumentsActions[SAVE_SMART_DOCUMENT_SUCCESS](
                        response.data
                    )
                );
                const { content, id, name } = response.data;
                dispatch(fetchDocuments(proposalId));
                handleClickDocumentItem({
                    hasContent: true,
                    id,
                    name,
                    document_reference_id: id,
                    type: types.SMART_DOCUMENTS_TYPE,
                });
                resetTemplate();
                initTemplate(JSON.parse(content));
                dispatch(openDocumentModal(false));
                showToast({
                    body: i18next.t('It was created successfully'),
                });
            })
            .catch((error) => {
                dispatch(
                    templateDocumentsActions[SAVE_SMART_DOCUMENT_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
