import Geocode from 'react-geocode';

import {
    FETCH_GEOCODE,
    FETCH_GEOCODE_FAILURE,
    FETCH_GEOCODE_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (str) => (dispatch) => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
    Geocode.setLanguage('es-MX');
    Geocode.setRegion('mx');

    dispatch(creditFileActions[FETCH_GEOCODE]());

    return Geocode.fromAddress(str).then(
        (response) =>
            dispatch(
                creditFileActions[FETCH_GEOCODE_SUCCESS](response.results)
            ),
        (error) =>
            dispatch(
                creditFileActions[FETCH_GEOCODE_FAILURE](
                    error?.response?.data?.errors
                )
            )
    );
};
