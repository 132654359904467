import { reset } from 'redux-form';

import { NAME } from '../constants';

import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

export default () => (dispatch) => {
    dispatch(reset(`${NAME}/form`));
    dispatch(resetForm());
    dispatch(toggleModalProduct(true));
};
