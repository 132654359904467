import reduxWebsocket from '@giantmachines/redux-websocket';
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { PREFIX } from 'common/sockets/constants';

import createRootReducer from './modules/main/reducer';

export const history = createBrowserHistory();

export default function configureStoreApp(preloadedState) {
    const store = configureStore({
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ serializableCheck: false })
                .concat(routerMiddleware(history))
                .concat(
                    reduxWebsocket({
                        prefix: PREFIX,
                        reconnectOnClose: true,
                    })
                ),
        preloadedState,
        reducer: createRootReducer(history),
    });

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./modules/main/reducer', () =>
            store.replaceReducer(createRootReducer(history))
        );
    }

    return store;
}
