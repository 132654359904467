import React from 'react';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Grid, IconButton, Link, Tooltip } from 'sunwise-ui';

import {
    formatDate,
    parseDate,
    differenceInDaysDate,
} from 'common/utils/dates';

const getCardStyles = ({ days, status }) => {
    const common = { borderLeft: '6px solid', borderRadius: '6px' };
    if (status === 'Cerrado' || status === 'Cancelado')
        return { ...common, borderColor: '#ccc' };
    if (days < 10) return { ...common, borderColor: '#00ff00' };
    if (days >= 10 && days < 15) return { ...common, borderColor: '#ffa500' };
    if (days >= 15) return { ...common, borderColor: '#ff0000' };
};

const getPreviewId = (permalink) => {
    if (!permalink) return null;
    const splited = permalink.split('/');
    return splited?.[splited.length - 2] || null;
};

const DateComponent = styled(Box)`
    cursor: pointer;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    text-transform: capitalize;
`;

const StyledCard = styled(Card)`
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 8px;
    padding: 16px;
    width: 100%;
`;

const getDate = (requested_at) => {
    const date = !requested_at ? '01/01/1970 00:00:00' : requested_at;
    return parseDate(date, 'dd/MM/yyyy HH:mm:ss');
};

const ItemCard = ({ project }) => {
    const { t } = useTranslation();
    const {
        id: creditId,
        contact,
        permalink,
        project_name,
        rate_name,
        requested_at,
        status,
        system_size,
        proposal_name,
    } = project;

    const paseDateRequestedAt = getDate(requested_at);
    const date = formatDate(paseDateRequestedAt, 'dd/MM/yyyy');

    const today = new Date();
    const days = differenceInDaysDate(today, paseDateRequestedAt);

    const cardStyles = getCardStyles({ days, status });

    const previewId = getPreviewId(permalink);

    return (
        <StyledCard sx={cardStyles}>
            <Grid container sx={{ mb: 1 }}>
                <Grid item xs>
                    <Link
                        color="inherit"
                        href={`/credits/${creditId}/preview/${previewId}`}
                        underline="none"
                        variant="body2"
                    >
                        <strong>{proposal_name}</strong>
                    </Link>

                    <Box>
                        <i>
                            {t('Project')}: {project_name}
                        </i>
                    </Box>

                    <Box>{contact.agent_name}</Box>

                    <Box>
                        {t('Rate')}: {rate_name}
                    </Box>

                    <Box>
                        {t('System size')}: {system_size} kW
                    </Box>
                </Grid>

                <Grid item sx={{ ml: 'auto' }}>
                    <IconButton href={`/credits/file/${creditId}`}>
                        <ArrowCircleRightIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container sx={{ alignItems: 'center' }}>
                <Grid item xs>
                    <Box sx={{ display: 'flex', gap: 1, my: 2 }}>
                        <Tooltip
                            title={`${t('Elapsed days')}: ${
                                requested_at ? days : '---'
                            }`}
                        >
                            <DateComponent
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    gap: 1,
                                    textAlign: 'right',
                                }}
                            >
                                <CalendarTodayIcon />

                                {requested_at ? date : '---'}
                            </DateComponent>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>
        </StyledCard>
    );
};

ItemCard.propTypes = { project: PropTypes.object };

export default ItemCard;
