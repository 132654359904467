import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_FAILURE,
    FETCH_DOCUMENTS_SUCCESS,
    FETCH_DOCUMENTS_TYPE,
    FETCH_DOCUMENTS_TYPE_FAILURE,
    FETCH_DOCUMENTS_TYPE_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SHOW_MODAL,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    documentsType: { data: [], errors: [], isLoading: false },
    fetchDocuments: { data: [], error: null, isLoading: false },
    initialValues: {
        catalog_document: null,
        file: null,
        id: null,
        installer: null,
        profile_id: null,
        type: null,
    },
    isVisibleModal: false,
    uploadFile: { errors: null, isLoading: false },
};

const privateDocumentsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_DOCUMENTS]: (state) => {
            state.fetchDocuments.isFetching = true;
        },
        [FETCH_DOCUMENTS_FAILURE]: (state, action) => {
            state.fetchDocuments = {
                ...state.fetchDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOCUMENTS_SUCCESS]: (state, action) => {
            state.fetchDocuments = {
                ...state.fetchDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOCUMENTS_TYPE]: (state) => {
            state.documentsType.isFetching = true;
        },
        [FETCH_DOCUMENTS_TYPE_FAILURE]: (state, action) => {
            state.documentsType = {
                ...state.documentsType,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOCUMENTS_TYPE_SUCCESS]: (state, action) => {
            state.documentsType = {
                ...state.documentsType,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SHOW_MODAL]: (state, action) => {
            state.isVisibleModal = action.payload;
        },
        [UPLOAD_DOCUMENT]: (state) => {
            state.fileUploaded.isUploading = true;
        },
        [UPLOAD_DOCUMENT_FAILURE]: (state) => {
            state.fileUploaded.isUploading = false;
        },
        [UPLOAD_DOCUMENT_SUCCESS]: (state) => {
            state.fileUploaded.isUploading = false;
        },
    },
});

export const privateDocumentsActions = privateDocumentsSlice.actions;

export default privateDocumentsSlice.reducer;
