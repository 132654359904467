import { exportFile } from 'common/api/financierInstallers';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import { SET_IS_DOWNLOADING } from '../actionTypes';
import { installersActions } from '../reducer';

export default (filterData) => (dispatch) => {
    dispatch(installersActions[SET_IS_DOWNLOADING](true));

    const { executiveName, searchText, status } = filterData;

    exportFile({
        executiveName,
        searchText,
        status: status === 'all' ? '' : status,
    })
        .then((response) => {
            dispatch(installersActions[SET_IS_DOWNLOADING](false));
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Instaladores.csv`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((error) => {
            dispatch(installersActions[SET_IS_DOWNLOADING](false));
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
