import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    Card,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import { HeaderContainer, TitleIcon, Pagination } from 'common/components';

import * as actions from './actions';
import NotificationsList from './components/NotificationsList';
import { getFilterOptions, getFilterTypeOptions } from './helpers';
import * as selectors from './selectors';

const Main = ({
    deleteNotification,
    fetchNotifications,
    isDeletingNotification,
    isFetching,
    isUpdatingNotifications,
    markAllAsRead,
    notifications,
    pagination,
    redirect,
    updateNotification,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        page: 0,
        perPage: 25,
        type: 'all',
        viewed: false,
    });

    useEffect(() => {
        fetchNotifications(filterData);
    }, [filterData]);

    const handleOnPageChange = (page) =>
        setFilterData({ ...filterData, page: page });

    const handleClickFilterBy = (item) =>
        setFilterData({
            ...filterData,
            page: 0,
            viewed: item.value,
        });

    const handleClickFilterByType = (item) =>
        setFilterData({
            ...filterData,
            page: 0,
            type: item.value,
        });

    const isLoading =
        isDeletingNotification || isFetching || isUpdatingNotifications;

    return (
        <Container maxWidth={false}>
            <HeaderContainer>
                <Grid container alignItems="end" justifyContent="flex-end">
                    <Grid
                        item
                        lg={
                            filterData.viewed || notifications?.length === 0
                                ? 12
                                : 8
                        }
                        xs={18}
                    >
                        <TitleIcon title={t('Your notifications')} />
                    </Grid>

                    <Grid item xs={18} md={9} lg={3}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Filter by')}</InputLabel>

                            <Select
                                label={t('Filter by')}
                                onChange={(e) => handleClickFilterBy(e.target)}
                                value={filterData?.viewed}
                                variant="standard"
                            >
                                {getFilterOptions().map(
                                    ({ label, value, disabled = false }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`filter-select-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={18} md={9} lg={3}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Type')}</InputLabel>
                            <Select
                                label={t('Type')}
                                onChange={(e) =>
                                    handleClickFilterByType(e.target)
                                }
                                value={filterData?.type}
                                variant="standard"
                            >
                                {getFilterTypeOptions().map(
                                    ({ label, value, disabled = false }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`filter-select-type-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        hidden={notifications?.length === 0}
                        item
                        lg={4}
                        md={9}
                        textAlign="right"
                        xs={18}
                    >
                        <Button
                            color="secondary"
                            onClick={() => markAllAsRead()}
                            sx={{ width: { xs: '100%', md: 'auto' } }}
                            variant="outlined"
                            visible={!filterData.viewed}
                        >
                            {t('Mark all as read')}
                        </Button>
                    </Grid>
                </Grid>
            </HeaderContainer>

            <Card>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <Card.Body>
                        <NotificationsList
                            deleteNotification={deleteNotification}
                            filterData={filterData}
                            notifications={notifications}
                            redirect={redirect}
                            updateNotification={updateNotification}
                        />
                        {notifications?.length > 0 && (
                            <Pagination
                                handleOnPageChange={handleOnPageChange}
                                pagination={pagination}
                            />
                        )}
                    </Card.Body>
                )}
            </Card>
        </Container>
    );
};

const mapStateToProps = createStructuredSelector({
    isDeletingNotification: selectors.getIsDeletingNotification,
    isFetching: selectors.getIsFetchingNotifications,
    isUpdatingNotifications: selectors.getIsUpdatingNotifications,
    notifications: selectors.getNotificationsData,
    pagination: selectors.getNotificationsPagination,
});

const mapDispatchToProps = (dispatch) => ({
    deleteNotification: (filterData, id) =>
        dispatch(actions.deleteNotification(filterData, id)),
    fetchNotifications: (filterData) =>
        dispatch(actions.fetchNotifications(filterData)),
    markAllAsRead: () => dispatch(actions.markAllAsRead()),
    redirect: (link) => dispatch(push(link)),
    updateNotification: (filterData, notification) =>
        dispatch(actions.updateNotification(filterData, notification)),
});

Main.propTypes = {
    deleteNotification: PropTypes.func,
    fetchNotifications: PropTypes.func,
    isDeletingNotification: PropTypes.bool,
    isFetching: PropTypes.bool,
    isUpdatingNotifications: PropTypes.bool,
    markAllAsRead: PropTypes.func,
    notifications: PropTypes.array,
    pagination: PropTypes.object,
    redirect: PropTypes.func,
    updateNotification: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
