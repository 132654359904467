import { styled } from '@mui/material/styles';
import { ListItemButton } from 'sunwise-ui';

const StyledListItemButton = styled(ListItemButton)(
    ({ theme, size, variant }) => ({
        alignItems: 'center',
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        display: 'flex',
        flexDirection: variant === 'horizontal' ? 'row' : 'column',
        gap: '4px',
        justifyContent: 'center',
        padding: variant === 'horizontal' ? '16px' : '16px 4px',
        '& svg': {
            color: '#848bab',
            height: '24px',
            width: '24px',
        },
        '&:hover': {
            '& svg': {
                '& path, & .shape': { fill: '#ffffff' },
                transform: 'scale(1.14)',
            },
        },
        '&.Mui-selected, &.Mui-selected:hover': {
            '& svg path, & svg .shape': { fill: '#ffffff' },
            background: 'transparent',
            borderRight: `4px solid ${
                theme.palette.mode === 'dark'
                    ? '#fff'
                    : theme.palette.primary.main
            }`,
        },
        [theme.breakpoints.down('md')]: {
            '& svg': { height: '20px', width: '20px' },
            flexDirection: 'row',
            justifyContent: 'left',
            paddingLeft: '16px',
            paddingRight: '16px',
        },
        ...(size === 'small' && {
            [theme.breakpoints.up('md')]: {
                '& .MuiListItemText-root': { display: 'none' },
                '& svg': {
                    color: '#848bab',
                    height: '20px',
                    width: '20px',
                },
            },
        }),
    })
);

export default StyledListItemButton;
