import { getTemplatesCatalog } from 'common/api/templates';

import {
    FETCH_TEMPLATE_CATALOGS,
    FETCH_TEMPLATE_CATALOGS_FAILURE,
    FETCH_TEMPLATE_CATALOGS_SUCCESS,
} from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(editTemplateViewActions[FETCH_TEMPLATE_CATALOGS]());

    return getTemplatesCatalog()
        .then((response) => {
            dispatch(
                editTemplateViewActions[FETCH_TEMPLATE_CATALOGS_SUCCESS](
                    response.data
                )
            );
        })
        .catch((error) =>
            dispatch(
                editTemplateViewActions[FETCH_TEMPLATE_CATALOGS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
