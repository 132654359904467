import { getPrivateDocumentsList as getCreditPrivateDocumentsList } from 'common/api/creditApplications';
import { getPrivateDocumentsList as getAlliancePrivateDocumentsList } from 'common/api/documentCatalog';
import { ALLIANCE_DOCUMENTS_CATEGORY } from 'common/constants';

import {
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_SUCCESS,
    FETCH_DOCUMENTS_FAILURE,
} from '../actionTypes';
import { privateDocumentsActions } from '../reducer';

export default (itemId, type) => (dispatch) => {
    const action =
        type === ALLIANCE_DOCUMENTS_CATEGORY
            ? getAlliancePrivateDocumentsList
            : getCreditPrivateDocumentsList;

    dispatch(privateDocumentsActions[FETCH_DOCUMENTS]());

    action(itemId)
        .then((response) =>
            dispatch(
                privateDocumentsActions[FETCH_DOCUMENTS_SUCCESS](
                    Array.isArray(response.data)
                        ? response.data
                        : response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                privateDocumentsActions[FETCH_DOCUMENTS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
