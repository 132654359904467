import { reset } from 'redux-form';

import { create, update } from 'common/api/financierProduct';
import { handleCheckboxField } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_PRODUCT,
    SAVE_PRODUCT_FAILURE,
    SAVE_PRODUCT_SUCCESS,
} from '../actionTypes';
import { NAME } from '../constants';
import { financialCeideActions } from '../reducer';

import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

export default (values, callback) => (dispatch) => {
    let blind_objects = [];

    if (
        values.administratives_expenses &&
        values.administratives_expenses.isChecked
    )
        blind_objects.push('administratives_expenses');

    if (values.retention && values.retention.isChecked)
        blind_objects.push('retention');

    if (values.amount_values && values.amount_values.isChecked)
        blind_objects.push('amount_values');

    if (values.power_values && values.power_values.isChecked)
        blind_objects.push('power_values');

    const newValues = {
        accesories_list_must_have: '',
        accesories_list_optional: handleCheckboxField(
            values.accesories_list_optional
        ),
        amount_values:
            values.amount_values.min + ',' + values.amount_values.max,
        catalog_documents: handleCheckboxField(values.catalog_documents),
        description: values.description,
        inverters_list: handleCheckboxField(values.inverters_list),
        name: values.name,
        panels_list: handleCheckboxField(values.panels_list),
        parameters: JSON.stringify({
            blind_objects: blind_objects,
            administratives_expenses: values.administratives_expenses.value,
            is_administrative_expenses_percentage:
                values.administratives_expenses.unit === 0 ? true : false,
            retention: values.retention.value,
            is_retention_percentage: values.retention.unit === 0 ? true : false,
        }),
        power_values: values.power_values.min + ',' + values.power_values.max,
        rate_list: handleCheckboxField(values.rate_list),
        type: values.type,
        type_algorithm: values.type_algorithm,
        types_business_company: values.types_business_company,
    };

    if (values.id) newValues.id = values.id;

    const save = values.id
        ? () => update(newValues, values.id)
        : () => create(newValues);

    dispatch(financialCeideActions[SAVE_PRODUCT]());

    save()
        .then(() => {
            dispatch(financialCeideActions[SAVE_PRODUCT_SUCCESS]());
            dispatch(reset(`${NAME}/form`));
            dispatch(resetForm());
            dispatch(toggleModalProduct(false));
            showToast();
            if (callback) callback();
        })
        .catch((error) =>
            dispatch(
                financialCeideActions[SAVE_PRODUCT_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
