import i18next from 'i18next';

import { deleteInsuranceConfig } from 'common/api/products';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_COVERAGES,
    DELETE_COVERAGES_FAILURE,
    DELETE_COVERAGES_SUCCESS,
} from '../actionTypes';
import { configurationCoveragesActions } from '../reducer';

import fetchCoverages from './fetchCoverages';

export default (filterData, values, setSelectedCoverage) => (dispatch) => {
    dispatch(configurationCoveragesActions[DELETE_COVERAGES]());
    deleteInsuranceConfig(values.id)
        .then(() => {
            dispatch(configurationCoveragesActions[DELETE_COVERAGES_SUCCESS]());
            dispatch(
                fetchCoverages(filterData, (coverages) => {
                    const filtered = coverages.filter(
                        (item) => item.id === values.insurance_product
                    );
                    setSelectedCoverage(filtered[0]);
                })
            );
            dispatch(alerts.actions.close());
            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                configurationCoveragesActions[DELETE_COVERAGES_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
