import { getProductsList } from 'common/api/products';

import {
    FETCH_PRODUCTS_LIST,
    FETCH_PRODUCTS_LIST_FAILURE,
    FETCH_PRODUCTS_LIST_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

import initializeProductsForm from './initializeProductsForm';

export default (financierId, allianceId) => (dispatch) => {
    dispatch(installerProfileActions[FETCH_PRODUCTS_LIST]());

    getProductsList(financierId, allianceId)
        .then((response) => {
            dispatch(
                installerProfileActions[FETCH_PRODUCTS_LIST_SUCCESS](
                    response.data.data
                )
            );
            const financiers_products = {};
            if (response.data.data)
                response.data.data.forEach(
                    (item) => (financiers_products[item.id] = item.added)
                );
            const newValues = { financiers_products: financiers_products };
            dispatch(initializeProductsForm(newValues));
        })
        .catch((error) =>
            dispatch(
                installerProfileActions[FETCH_PRODUCTS_LIST_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
