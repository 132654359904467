import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import LabelError from './LabelError';

const Container = styled('div')`
    background-color: #e5e5e5;
    padding: 15px;
`;

const Title = styled('div')`
    font-size: 15px;
    font-weight: 400px;
`;

const ShowErrors = ({ errors }) => {
    const { t } = useTranslation();

    if (!errors || errors.length === 0) return null;

    return (
        <Container>
            <Title>{t('Please fix the following errors')}: </Title>

            {errors.map((error, index) => (
                <LabelError key={index} type="error">
                    * {error}
                </LabelError>
            ))}
        </Container>
    );
};

ShowErrors.propTypes = { errors: PropTypes.array };

export default ShowErrors;
