import { NAME } from './constants';

export const FETCH_COMPANY = `${NAME}/FETCH_COMPANY`;
export const FETCH_COMPANY_FAILURE = `${NAME}/FETCH_COMPANY_FAILURE`;
export const FETCH_COMPANY_SUCCESS = `${NAME}/FETCH_COMPANY_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_COMPANY = `${NAME}/SAVE_COMPANY`;
export const SAVE_COMPANY_FAILURE = `${NAME}/SAVE_COMPANY_FAILURE`;
export const SAVE_COMPANY_SUCCESS = `${NAME}/SAVE_COMPANY_SUCCESS`;
