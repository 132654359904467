import React, { useEffect } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Table } from 'sunwise-ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

const Container = styled('div')`
    display: block;
    ${({ isFetching }) => isFetching && 'overflow-x: auto;'}
    width: 100%;
`;

const StyledTh = styled('th')`
    background-color: #000;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    padding: 6px 12px;
`;

const StyledTd = styled('td')`
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    padding: 8px 12px;
`;

const TotalTr = styled('tr')`
    border-top: 1px solid #000;
`;

const LoadingText = styled('span')`
    color: #777;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
    padding: 10px 12px;
    text-align: center;
    width: 100%;
`;

const FinancialTable = ({
    commercialOfferId,
    downloadFinancialData,
    fetchFinancialData,
    financialFormattedData,
    isFetching,
}) => {
    const { t } = useTranslation();
    useEffect(() => {
        fetchFinancialData(commercialOfferId);
    }, []);

    return (
        <Container isFetching={isFetching}>
            {!isFetching && financialFormattedData.length > 0 && (
                <Box sx={{ textAlign: 'right', mb: 2 }}>
                    <Button
                        color="secondary"
                        onClick={() => downloadFinancialData(commercialOfferId)}
                        variant="text"
                    >
                        {t('Download')} CSV
                    </Button>
                </Box>
            )}
            <Table>
                <thead>
                    <tr>
                        <StyledTh>{t('Payment No')}</StyledTh>
                        <StyledTh>{t('Payment to capital')} $/€</StyledTh>
                        <StyledTh>{t('Interest payment')} $/€</StyledTh>
                        <StyledTh>{t('Monthly payment')} $/€</StyledTh>
                        <StyledTh>
                            {t('Monthly payment')} {t('Tax')} $/€
                        </StyledTh>
                        <StyledTh>{t('Full payment')} $/€</StyledTh>
                        <StyledTh>{t('Balance')}</StyledTh>
                    </tr>
                </thead>
                <tbody>
                    {financialFormattedData.map((financialItem) => {
                        if (financialItem.period === 'total') {
                            return (
                                <TotalTr key={financialItem.period}>
                                    <StyledTd>{t('Total')}</StyledTd>
                                    <StyledTd>
                                        {financialItem['capital_payment']}
                                    </StyledTd>
                                    <StyledTd>
                                        {financialItem['total_rate']}
                                    </StyledTd>
                                    <StyledTd></StyledTd>
                                    <StyledTd>
                                        {financialItem['total_tax_payment']}
                                    </StyledTd>
                                    <StyledTd>
                                        {financialItem['total_payment']}
                                    </StyledTd>
                                    <StyledTd></StyledTd>
                                </TotalTr>
                            );
                        }
                        return (
                            <tr key={financialItem.period}>
                                <StyledTd>{financialItem.period}</StyledTd>
                                <StyledTd>
                                    {financialItem['capital-payment']}
                                </StyledTd>
                                <StyledTd>{financialItem.rate}</StyledTd>
                                <StyledTd>
                                    {financialItem['month-payment']}
                                </StyledTd>
                                <StyledTd>
                                    {financialItem['tax-payment']}
                                </StyledTd>
                                <StyledTd>
                                    {financialItem['total-payment']}
                                </StyledTd>
                                <StyledTd>{financialItem.capital}</StyledTd>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            {isFetching && <LoadingText>{t('Loading')}</LoadingText>}
        </Container>
    );
};

FinancialTable.propTypes = {
    commercialOfferId: PropTypes.string,
    downloadFinancialData: PropTypes.func,
    fetchFinancialData: PropTypes.func,
    financialFormattedData: PropTypes.array,
    isFetching: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    financialFormattedData: selectors.getFinancialFormattedData,
    isFetching: selectors.getFinancialIsFetching,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFinancialData: (commercialOfferId) =>
        dispatch(actions.fetchFinancialData(commercialOfferId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(FinancialTable));
