import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleIcon from '@mui/icons-material/Circle';
import PropTypes from 'prop-types';

const ProposalStatusIcon = ({ hasApproved }) => {
    if (hasApproved)
        return <CheckCircleIcon fontSize="medium" sx={{ color: '#3caf5f' }} />;
    return <CircleIcon fontSize="medium" sx={{ color: '#3caf5f' }} />;
};

ProposalStatusIcon.propTypes = {
    hasApproved: PropTypes.bool,
};

export default ProposalStatusIcon;
