import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Tab, TabPanel, Tabs } from 'sunwise-ui';

import { ReduxFieldCheck } from 'common/components/form/redux';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import Inverters from './Inverters';
import Panels from './Panels';

const Page2 = ({ changeInput, disabled, formValues, initialValues }) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('panels');
    const handleChange = (_, newValue) => setSelectedTab(newValue);

    return (
        <React.Fragment>
            <Box>
                <Tabs
                    onChange={handleChange}
                    scrollButtons="auto"
                    value={selectedTab}
                    variant="scrollable"
                >
                    <Tab
                        id="tab-panels"
                        key="tab-panels"
                        label={t('Panels')}
                        value="panels"
                    />
                    <Tab
                        id="tab-inverters"
                        key="tab-inverters"
                        label={t('Inverters')}
                        value="inverters"
                    />
                    <Tab
                        id="tab-storage"
                        key="tab-storage"
                        label={t('Storage')}
                        value="storage"
                    />
                </Tabs>
            </Box>

            <TabPanel key="tab-panels" selectedTab={selectedTab} value="panels">
                <Panels
                    changeInput={changeInput}
                    disabled={disabled}
                    formValues={formValues}
                    initialValues={initialValues}
                />
            </TabPanel>

            <TabPanel
                key="tab-inverters"
                selectedTab={selectedTab}
                value="inverters"
            >
                <Inverters
                    changeInput={changeInput}
                    disabled={disabled}
                    formValues={formValues}
                    initialValues={initialValues}
                />
            </TabPanel>

            <TabPanel
                key="tab-storage"
                selectedTab={selectedTab}
                value="storage"
            >
                <Field
                    component={ReduxFieldCheck}
                    label="Incluye equipos de almacenamiento"
                    name="storage_equipment_required"
                />
            </TabPanel>
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    pagination: selectors.getPanelsPagination,
    panels: selectors.getPanelsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPanels: (filter, page) => dispatch(actions.fetchPanels(filter, page)),
});

Page2.propTypes = {
    changeInput: PropTypes.func,
    disabled: PropTypes.bool,
    formValues: PropTypes.object,
    initialValues: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page2);
