import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Button, Card, Grid, IconButton } from 'sunwise-ui';

import { ReduxFieldInput } from 'common/components/form/redux';

import * as actions from './actions';
import { NAME } from './constants';
import * as selectors from './selectors';

const RejectForm = styled('form')`
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    .form-control {
        border-radius: 20px;
    }
`;

const CardContainer = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    max-width: 320px;
`;

const Title = styled('div')`
    color: #fa6968;
    font-size: 14px;
    font-weight: 600;
`;

const Container = ({
    callback,
    chatId,
    documentId,
    documentType,
    handleOnReject,
    handleSubmit,
    isFetching,
    onChangeFileStatus,
    selectedFile,
    sendMessage,
    setShowModal,
    user,
}) => {
    const { t } = useTranslation();

    const handleOnClickSave = (values) =>
        handleOnReject(selectedFile, () => {
            sendMessage(
                {
                    ...values,
                    chatId,
                    documentId,
                    documentType,
                    user,
                },
                () => {
                    setShowModal(false);
                    onChangeFileStatus();

                    if (callback) callback();
                }
            );
        });

    return (
        <RejectForm onSubmit={handleSubmit(handleOnClickSave)}>
            <CardContainer>
                <Card>
                    <Card.Header>
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <Title>{t('Reason for rejection')}</Title>
                            </Grid>

                            <Grid item>
                                <IconButton onClick={() => setShowModal(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Card.Header>

                    <Card.Body>
                        <Field
                            component={ReduxFieldInput}
                            name="message"
                            label={t('Add comment')}
                        />
                    </Card.Body>
                </Card>
            </CardContainer>

            <Button
                startIcon={<SendIcon />}
                disabled={isFetching}
                onClick={handleSubmit(handleOnClickSave)}
            >
                {t('Send reasons for rejection')}
            </Button>
        </RejectForm>
    );
};

Container.propTypes = {
    callback: PropTypes.func,
    chatId: PropTypes.string,
    documentId: PropTypes.string,
    documentType: PropTypes.string,
    handleOnReject: PropTypes.func,
    handleSubmit: PropTypes.func,
    isFetching: PropTypes.bool,
    onChangeFileStatus: PropTypes.func,
    selectedFile: PropTypes.object,
    sendMessage: PropTypes.func,
    setShowModal: PropTypes.func,
    user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isFetching: selectors.getSendIsFetching,
});

const mapDispatchToProps = (dispatch) => ({
    sendMessage: (values, callback) =>
        dispatch(actions.sendMessage(values, callback)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/reject-form`,
})(Container);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
