import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DropdownCardMenu from 'common/components/DropdownCardMenu';

const MenuOptions = ({
    document,
    handleClickDocumentItem,
    handleClickEditButton,
    handleClickProposalItem,
    isDisabled,
    prepareDocumentDelete,
    prepareDocumentDuplicate,
    prepareDocumentRename,
    proposalDocumentDefault,
    proposalId,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();

    return (
        <DropdownCardMenu
            disabled={isDisabled}
            items={[
                {
                    handleClick: () => handleClickEditButton(),
                    icon: <EditIcon fontSize="small" />,
                    label: t('Edit'),
                },
                {
                    handleClick: () =>
                        prepareDocumentRename(document.name, document.id),
                    icon: (
                        <DriveFileRenameOutlineOutlinedIcon fontSize="small" />
                    ),
                    label: t('Rename'),
                },
                {
                    handleClick: () =>
                        prepareDocumentDuplicate(document.name, document.id),
                    icon: <FileCopyIcon fontSize="small" />,
                    label: t('Duplicate'),
                },
                {
                    handleClick: () =>
                        prepareDocumentDelete(
                            handleClickDocumentItem,
                            handleClickProposalItem,
                            proposalDocumentDefault,
                            proposalId,
                            document.id
                        ),
                    icon: <DeleteIcon fontSize="small" />,
                    label: t('Delete'),
                },
            ]}
            sxIconButton={{
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            }}
            visible={document.id === selectedDocumentId}
        />
    );
};

MenuOptions.propTypes = {
    document: PropTypes.object,
    handleClickDocumentItem: PropTypes.func,
    handleClickEditButton: PropTypes.func,
    handleClickProposalItem: PropTypes.func,
    isDisabled: PropTypes.bool,
    prepareDocumentDelete: PropTypes.func,
    prepareDocumentDuplicate: PropTypes.func,
    prepareDocumentRename: PropTypes.func,
    proposalDocumentDefault: PropTypes.object,
    proposalId: PropTypes.string,
    selectedDocumentId: PropTypes.string,
};

export default MenuOptions;
