import API from '..';
const ENTITY = 'proposals';

export const exportConsumtionsCSV = (id) =>
    API.get(`/api/v1/${ENTITY}/csv/consumption/${id}/`);
export const exportProposalCSV = (id) =>
    API.get(`/api/v1/proposals/csv/download/${id}`);
export const getConsumptionPdfs = (id) =>
    API.get(`/api/v1/${ENTITY}/pdfs/consumption/${id}/`);
export const getProposalBackups = (proposalId) =>
    API.get(`/api/v1/${ENTITY}/repositories/${proposalId}/`);
export const updateCommercialOffer = (values, id) =>
    API.put(`/api/v1/${ENTITY}/commercial-offer/${id}`, { ...values });
