import { createAction, createSlice } from '@reduxjs/toolkit';
import { get, isEmpty } from 'lodash';

import {
    BROKEN_SOCKET,
    CLOSED_SOCKET,
    ERROR_SOCKET,
    MESSAGE_SOCKET,
    OPEN_SOCKET,
    RECONNECTED,
} from 'common/sockets/constants';
import { handleFileURL } from 'common/utils/helpers';

import {
    ADD_MESSAGE,
    FETCH_MESSAGES,
    FETCH_MESSAGES_FAILURE,
    FETCH_MESSAGES_SUCCESS,
    INIT_CHAT,
    INIT_CHAT_FAILURE,
    INIT_CHAT_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SEND_MESSAGE,
    SEND_MESSAGE_FAILURE,
    SEND_MESSAGE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initChat: { data: {}, errors: null, isFetching: false },
    initialValues: {
        chatId: null,
        is_comment: 0,
        message: '',
        message_from: null,
        note_message: '',
        user: null,
    },
    messages: { data: { messages: [] }, errors: null, isFetching: false },
    send: { data: {}, errors: null, isFetching: false },
    socketConnected: false,
    socketError: null,
};

const brokenSocket = createAction(BROKEN_SOCKET),
    closedSocket = createAction(CLOSED_SOCKET),
    errorSocket = createAction(ERROR_SOCKET),
    messageSocket = createAction(MESSAGE_SOCKET),
    openSocket = createAction(OPEN_SOCKET),
    reconnected = createAction(RECONNECTED);

const chatSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ADD_MESSAGE]: (state, action) => {
            state.messages.data.messages.push(action.payload);
        },
        [FETCH_MESSAGES]: (state) => {
            state.messages.isFetching = true;
        },
        [FETCH_MESSAGES_FAILURE]: (state, action) => {
            state.messages = {
                ...state.messages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_MESSAGES_SUCCESS]: (state, action) => {
            state.messages = {
                ...state.messages,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INIT_CHAT]: (state) => {
            state.initChat.isFetching = true;
        },
        [INIT_CHAT_FAILURE]: (state, action) => {
            state.initChat = {
                ...state.initChat,
                errors: action.payload,
                isFetching: false,
            };
        },
        [INIT_CHAT_SUCCESS]: (state, action) => {
            state.initChat = {
                ...state.initChat,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SEND_MESSAGE]: (state) => {
            state.send.isFetching = true;
        },
        [SEND_MESSAGE_FAILURE]: (state, action) => {
            state.send = {
                ...state.send,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SEND_MESSAGE_SUCCESS]: (state, action) => {
            state.send = {
                ...state.send,
                data: action.payload,
                isFetching: false,
            };
        },
    },
    extraReducers: {
        [brokenSocket]: (state) => {
            state.socketError =
                'Error al enviar datos. Reinicie su sesión o intente más tarde';
        },
        [errorSocket]: (state) => {
            state.socketError = 'Error de servidor. Intente más tarde';
        },
        [reconnected]: (state) => {
            state.socketError = null;
        },
        [closedSocket]: (state) => {
            state.socketConnected = false;
        },
        [openSocket]: (state) => {
            state.socketConnected = true;
        },
        [messageSocket]: (state, action) => {
            const { message: content } = JSON.parse(action.payload.message);
            const { data, type, errors } = content;
            if (
                type !== '1' ||
                data.model !== 'chats/credit/' ||
                !isEmpty(errors)
            )
                return state;
            const {
                id,
                name,
                sent_from,
                logo,
                username,
                message,
                message_from,
                is_comment: isComment,
                created_at,
                updated_at,
            } = get(data, 'response', {});

            const newMessage = {
                created_at,
                id,
                is_comment: isComment,
                logo: handleFileURL(logo, process.env.REACT_APP_S3_MEDIA_PATH),
                message_from,
                message,
                name,
                sent_from,
                updated_at,
                username,
            };

            if (message_from !== state.initialValues?.user?.id) {
                state.messages.data.messages.push(newMessage);
                return;
            }

            if (isComment) {
                state.initialValues = {
                    ...state.initialValues,
                    message: data?.other,
                    timestamp: new Date(),
                };
            } else {
                state.initialValues = {
                    ...state.initialValues,
                    note_message: data?.other,
                    timestamp: new Date(),
                };
            }
            state.send = {
                ...state.send,
                data: data?.response,
                isFetching: false,
            };
            state.messages.data.messages.push(newMessage);
        },
    },
});

export const chatActions = chatSlice.actions;

export default chatSlice.reducer;
