import { getInteractiveProposal } from 'common/api/sunpal/interactiveProposal';

import {
    FETCH_INTERACTIVE_PROPOSAL,
    FETCH_INTERACTIVE_PROPOSAL_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            interactiveProposalPreviewActions[FETCH_INTERACTIVE_PROPOSAL]()
        );

        getInteractiveProposal(id)
            .then((response) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_INTERACTIVE_PROPOSAL_SUCCESS
                    ](response.data)
                )
            )
            .catch((error) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_INTERACTIVE_PROPOSAL_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
