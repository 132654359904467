import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Card, Divider, List, ListItem } from 'sunwise-ui';

import PlaceholderCardDocuments from 'common/components/placeholder/PlaceholderCardDocuments';
import TextVariant from 'common/components/TextVariant';

import DocumentItem from './DocumentItem';

const DocumentUploadCard = ({
    canModify,
    documents,
    fetchId,
    handleClickAdd,
    handleClickUpdate,
    installer,
    isFetchingDocuments,
    profileId,
    type,
}) => {
    const { t } = useTranslation();

    return (
        <PlaceholderCardDocuments ready={isFetchingDocuments}>
            <Card>
                <Card.Header>
                    <TextVariant text={t('Private documents')} variant="bold" />
                </Card.Header>

                <List>
                    {documents &&
                        documents.map((document, index) => (
                            <ListItem key={index}>
                                <DocumentItem
                                    handleClickUpdate={() =>
                                        handleClickUpdate(
                                            document,
                                            type,
                                            profileId,
                                            installer,
                                            fetchId
                                        )
                                    }
                                    document={document}
                                />
                            </ListItem>
                        ))}
                </List>

                <Divider />

                <Card.Body hidden={!canModify} sx={{ textAlign: 'right' }}>
                    <Button
                        color="secondary"
                        onClick={() =>
                            handleClickAdd(type, profileId, installer, fetchId)
                        }
                        startIcon={<AddIcon />}
                        variant="text"
                    >
                        {t('Add documents')}
                    </Button>
                </Card.Body>
            </Card>
        </PlaceholderCardDocuments>
    );
};

DocumentUploadCard.propTypes = {
    canModify: PropTypes.bool,
    documents: PropTypes.array,
    fetchId: PropTypes.string,
    handleClickAdd: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    installer: PropTypes.string,
    isFetchingDocuments: PropTypes.bool,
    profileId: PropTypes.string,
    type: PropTypes.string,
};

export default DocumentUploadCard;
