import React from 'react';

import LinkOffIcon from '@mui/icons-material/LinkOff';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/DropdownCardMenu';

const ActionsFormatter = ({
    canModify,
    handleClickAssignProduct,
    handleClickUnlink,
    row,
}) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '4px',
                justifyContent: 'end',
                width: '100%',
            }}
        >
            <DropdownCardMenu
                items={[
                    {
                        handleClick: () => handleClickUnlink(row),
                        icon: <LinkOffIcon fontSize="small" />,
                        label: t('Unlink'),
                    },
                    {
                        handleClick: () => handleClickAssignProduct([row?.id]),
                        icon: <PlaylistAddIcon fontSize="small" />,
                        label: t('Assign product', { count: 2 }),
                    },
                ]}
                visible={canModify}
            />
        </Box>
    );
};

ActionsFormatter.propTypes = {
    canModify: PropTypes.bool,
    handleClickAssignProduct: PropTypes.func,
    handleClickUnlink: PropTypes.func,
    row: PropTypes.object,
};

export default ActionsFormatter;
