import React from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { TextField } from 'sunwise-ui';

import Arrow from 'common/components/Arrow';
import LabelError from 'common/components/LabelError';
import useSelectToggleMenuOptions from 'common/hooks/form/useSelectToggleMenuOptions';

const StyledContainerArrow = styled('div')``;

const StyledSelect = styled('div')`
    align-items: center;
    border-left: 1px solid #eff1fb;
    cursor: pointer;
    display: flex;
    height: 80%;
    justify-content: space-between;
    min-width: 120px;
    padding-left: 10px;
`;

const StyledSelectField = styled('div')`
    margin: 15px 0px;
    position: relative;
    user-select: none;
    width: 100%;

    & .error {
        margin-left: 25px;
    }
`;

const Title = styled('div')`
    color: #848bab;
    font-size: 12px;
`;

const List = styled('ul')`
    background-color: #f6f8fa;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top: none;
    border: 1px solid rgb(223, 223, 223);
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    list-style: none;
    margin-top: 0px;
    min-width: 120px;
    overflow-scrolling: touch;
    overflow-y: hide;
    padding: 0px 0;
    position: absolute;
    right: 0;
    text-overflow: hide;
    z-index: 10;
`;

const Item = styled('li')`
    border-bottom: 1px solid #fff;
    color: #848bab;
    cursor: default;
    font-size: 12px;
    overflow-y: hide;
    padding: 10px 0px 10px 30px;
    text-overflow: ellipsis;

    &:hover {
        background-color: #ff9a00;
        color: #fff;
    }
`;

const SelectInputField = (props) => {
    const { isOpen, toggle } = useSelectToggleMenuOptions();

    const { inputFieldName, selectFieldName, placeholder, options } = props;
    const inputField = get(props, inputFieldName);
    const selectField = get(props, selectFieldName);
    const inputMaxLenght = get(props, 'inputFieldMaxLenght', 124);

    const showOptions = (options) => {
        if (options.length === 0) return null;
        return (
            <StyledSelect onClick={toggle}>
                <Title>{selectField.input.value.label || '--'} </Title>

                <StyledContainerArrow>
                    <Arrow direction={`${isOpen ? 'up' : 'down'}`} />
                </StyledContainerArrow>
            </StyledSelect>
        );
    };

    return (
        <StyledSelectField>
            <TextField
                {...inputField.input}
                fullWidth
                maxLength={inputMaxLenght}
                label={placeholder}
            />

            {showOptions(options)}

            {isOpen && (
                <List>
                    {options.map((option, index) => (
                        <Item
                            key={`select-option-${index}-${selectField.input.value}`}
                            onClick={() =>
                                selectField.input.onChange(option, true)
                            }
                        >
                            {option.label}
                        </Item>
                    ))}
                </List>
            )}

            {inputField.meta.error && inputField.meta.touched && (
                <LabelError type="error">{inputField.meta.error}</LabelError>
            )}

            {selectField.meta.error && selectField.meta.touched && (
                <LabelError type="error">{selectField.meta.error}</LabelError>
            )}
        </StyledSelectField>
    );
};

SelectInputField.propTypes = {
    inputFieldName: PropTypes.string,
    inputFieldMaxLenght: PropTypes.number,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    selectFieldName: PropTypes.string,
};

export default SelectInputField;
