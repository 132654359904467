import React from 'react';

import i18next from 'i18next';
import { toast } from 'react-toastify';

import ToastNotification from 'common/components/ToastNotification';

export default (props) => {
    const defaults = {
        autoClose: 1500,
        body: i18next.t('Saved successfully'),
        closeButton: true,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: true,
        newestOnTop: true,
        title: '',
        type: 'success',
    };

    const values = { ...defaults, ...props };

    const {
        autoClose,
        content = null,
        body,
        closeButton,
        closeOnClick,
        draggable,
        hideProgressBar,
        newestOnTop,
        title,
        type,
    } = values;

    return toast(
        <ToastNotification
            content={content}
            body={body}
            title={title}
            type={type}
        />,
        {
            autoClose: autoClose,
            closeButton,
            closeOnClick,
            draggable,
            hideProgressBar: hideProgressBar,
            newestOnTop,
        }
    );
};
