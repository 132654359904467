import { styled } from '@mui/material/styles';

export default styled('div')`
    .nav-tabs {
        border-bottom: 0;
        padding-left: 0;
        position: relative;

        .nav-link {
            background-color: #eef4fa;
            border-radius: 8px 8px 0 0;
            color: #b3b7cb;
            font-size: 14px;
            height: 38px;
            line-height: 38px;
            margin-top: 26px;
            padding-bottom: 1px;
            padding: 0;
            text-align: center;
            width: 196px;

            &:hover {
                border-color: #e9ecef #e9ecef transparent;
            }
        }

        .nav-link.active {
            background-color: #ffffff;
            border-bottom-color: #ffffff;
            border-color: #eff1fb;
            color: #202253;
            font-size: 14px;
            font-weight: 500;
            height: 64px;
            letter-spacing: 0.2px;
            line-height: 64px;
            margin-top: 0;
            position: relative;

            &::after {
                background-color: #ff9a00;
                bottom: 0;
                content: ' ';
                height: 2px;
                left: 50%;
                position: absolute;
                transform: translate(-50%, 0);
                width: 30px;
            }
        }
    }

    .tab-content {
        background-color: #fff;
        border: solid 1px #eff1fb;
        margin-top: -1px;
        z-index: 1;
    }
`;
