import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { styled } from '@mui/material/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Button,
    IconButton,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
} from 'sunwise-ui';

const StyledListItemText = styled(ListItemText)`
    padding-top: 6px;
    & .MuiListItemText-primary {
        font-size: 13px;
        font-weight: 600;
    }

    &.disabled {
        color: #848bab;
    }
`;

const StyledListItemTextSecondary = styled(ListItemText)`
    & .MuiListItemText-secondary {
        font-size: 13px;
    }
`;

const StyledListItem = styled(ListItem)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        color:
            theme.palette.mode === 'dark'
                ? '#fff'
                : theme.palette.secondary.main,
    },
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '8px',
}));

const buildLink = (notification) => {
    const parameters = get(notification, 'data.parameters', []);
    let path = '/' + get(notification, 'data.path', '#');
    path = path.replace(/credit-file/g, 'credits/file');
    const isLocalRedirect = !/^http/i.test(path);

    const extraQuery = [];

    if (isLocalRedirect) {
        parameters.forEach((item) => {
            path = path.replace(`{${item.key}}`, item.value);
            if (item.key === 'document_id')
                extraQuery.push(`documentId=${item.value}`);
        });
    }

    if (extraQuery.length > 0) {
        path = path + '?' + extraQuery.join('&');
    }

    let response = { to: path };

    if (!isLocalRedirect) response.target = '_blank';

    return response;
};

const NotificationItem = ({
    handleClickDelete,
    handleClickMarkAsReaded,
    notification,
}) => {
    const { t } = useTranslation();
    const { body, created_at, id, title, viewed } = notification;
    const handleOnClickMarkAsReaded = () =>
        handleClickMarkAsReaded({ id, viewed });
    const handleOnClickDelete = () => handleClickDelete(id);

    return (
        <StyledListItem selected={!viewed} divider>
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <ListItemIcon
                    className={viewed ? 'disabled' : ''}
                    sx={{ justifyContent: 'center', margin: 0, width: '32px' }}
                >
                    <NotificationsIcon />
                </ListItemIcon>

                <Box>
                    <Box>
                        <Link
                            component={RouterLink}
                            {...buildLink(notification)}
                        >
                            <StyledListItemText
                                className={viewed ? 'disabled' : ''}
                                primary={title}
                            />
                        </Link>
                        <StyledListItemTextSecondary secondary={body} />

                        <StyledListItemTextSecondary secondary={created_at} />
                    </Box>

                    <Button
                        fontWeight="normal"
                        onClick={handleOnClickMarkAsReaded}
                        size="small"
                        variant="text"
                        visible={!viewed}
                    >
                        {t('Mark as read')}
                    </Button>
                </Box>

                <IconButton
                    sx={{ cursor: 'pointer', margin: '0 8px 0 auto' }}
                    onClick={handleOnClickDelete}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>
        </StyledListItem>
    );
};

NotificationItem.propTypes = {
    handleClickDelete: PropTypes.func,
    handleClickMarkAsReaded: PropTypes.func,
    handleClickNotification: PropTypes.func,
    notification: PropTypes.object,
    redirect: PropTypes.func,
    setIsOpenDrawer: PropTypes.func,
};

export default NotificationItem;
