import i18next from 'i18next';
import is from 'is_js';

export default (values = {}) => {
    const errors = {};

    if (is.empty(values.name)) errors.name = i18next.t('Name is required');

    if (!values.financier_group_type)
        errors.financier_group_type = i18next.t('Role level is required');

    return errors;
};
