import { getProjectsSalesStage } from 'common/api/dashboards';

import {
    FETCH_PROJECTS_SALES_STAGE,
    FETCH_PROJECTS_SALES_STAGE_FAILURE,
    FETCH_PROJECTS_SALES_STAGE_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(installerProfileActions[FETCH_PROJECTS_SALES_STAGE]());

    getProjectsSalesStage()
        .then((response) =>
            dispatch(
                installerProfileActions[FETCH_PROJECTS_SALES_STAGE_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                installerProfileActions[FETCH_PROJECTS_SALES_STAGE_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
