import i18next from 'i18next';

import { changeStatusAlliance } from 'common/api/financierInstallers';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    CHANGE_STATUS_ALLIANCE,
    CHANGE_STATUS_ALLIANCE_FAILURE,
    CHANGE_STATUS_ALLIANCE_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (item, callback, loadingContext) => (dispatch) => {
    const newValues = { status: item.status };

    loadingContext.openLoading(i18next.t('Saving'));
    dispatch(installerProfileActions[CHANGE_STATUS_ALLIANCE]());

    changeStatusAlliance(item.allianceId, newValues)
        .then(() => {
            dispatch(installerProfileActions[CHANGE_STATUS_ALLIANCE_SUCCESS]());
            loadingContext.closeLoading();
            if (callback) callback();
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            loadingContext.closeLoading();
            dispatch(
                installerProfileActions[CHANGE_STATUS_ALLIANCE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
