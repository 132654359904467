import React from 'react';

import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { CostFormatter } from 'common/components';
import { useBreakpoint } from 'common/hooks';
import { parseDate, formatDate } from 'common/utils/dates';
import { getStatusText } from 'common/utils/helpers';

import CreditNameFormatter from './CreditNameFormatter';
import ProductActionsFormatter from './ProductActionsFormatter';
import UserTrackingFormatter from './UserTrackingFormatter';

const Columns = ({
    canModify,
    hiddenColumns,
    orders,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const clientColumn = [
        {
            align: 'left',
            editable: canModify,
            field: 'client_name',
            headerName: isEmpty(rowModesModel) ? t('Customer') : t('Name'),
            hide: hiddenColumns.includes('client_name'),
            minWidth: 360,
            order: orders?.client_name,
            renderCell: (params) => {
                const { row } = params;
                return <CreditNameFormatter row={row} />;
            },
            sortable: false,
        },
        {
            align: 'left',
            editable: canModify,
            field: 'client_first_lastname',
            flex: 1,
            headerName: t('Last name'),
            hide: isEmpty(rowModesModel),
        },
        {
            align: 'left',
            editable: canModify,
            field: 'client_second_lastname',
            flex: 1,
            headerName: t('Second last name'),
            hide: isEmpty(rowModesModel),
        },
    ];

    let columns = [
        ...clientColumn,
        {
            field: 'business_name',
            headerName: t('Business name'),
            hide: hiddenColumns.includes('business_name'),
            order: orders?.business_name,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.business_name || '- - -'}{' '}
                    </Typography>
                );
            },
            minWidth: 180,
        },
        {
            field: 'amount',
            headerName: t('Amount'),
            hide: !isEmpty(rowModesModel) || hiddenColumns.includes('amount'),
            order: orders?.amount,
            renderCell: (params) => {
                const { row } = params;
                return CostFormatter(row.amount, row);
            },
            minWidth: 180,
        },
        {
            field: 'type_business',
            headerName: t('Type business'),
            hide:
                !isEmpty(rowModesModel) ||
                hiddenColumns.includes('type_business'),
            order: orders?.type_business,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.type_business || '- - -'}{' '}
                    </Typography>
                );
            },
            minWidth: 180,
        },
        {
            editable: canModify,
            field: 'project_name',
            headerName: t('Project'),
            hide: hiddenColumns.includes('project_name'),
            order: orders?.project_name,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.project_name || '- - -'}{' '}
                    </Typography>
                );
            },
            minWidth: 200,
        },
        {
            field: 'financial_advisor',
            headerName: t('Assigned executive'),
            hide:
                !isEmpty(rowModesModel) ||
                hiddenColumns.includes('financial_advisor'),
            order: orders?.financial_advisor,
            renderCell: (params) => {
                const { row } = params;
                return <UserTrackingFormatter row={row} />;
            },
            minWidth: 180,
        },
        {
            field: 'finacier_product_name',
            headerName: t('Financial product'),
            hide: hiddenColumns.includes('finacier_product_name'),
            order: orders?.finacier_product_name,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.finacier_product_name || '- - -'}{' '}
                    </Typography>
                );
            },
            minWidth: 200,
        },
        {
            field: 'rate',
            headerName: t('Rate', { context: 'financial' }),
            hide: !isEmpty(rowModesModel) || hiddenColumns.includes('rate'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">{`${row.rate}%`}</Typography>
                );
            },
            minWidth: 80,
        },
        {
            field: 'term',
            headerName: t('Term'),
            hide: !isEmpty(rowModesModel) || hiddenColumns.includes('term'),
            order: orders?.term,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">{`${row.term}M`}</Typography>
                );
            },
            minWidth: 80,
        },
        {
            field: 'status',
            headerName: t('Status'),
            hide: !isEmpty(rowModesModel) || hiddenColumns.includes('status'),
            order: orders?.status,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {getStatusText(row.status, false)}
                    </Typography>
                );
            },
            minWidth: 180,
        },
        {
            field: 'created_at',
            headerName: t('Creation date'),
            hide:
                !isEmpty(rowModesModel) || hiddenColumns.includes('created_at'),
            order: orders?.created_at,
            renderCell: (params) => {
                const { row } = params;
                return row.created_at ? (
                    <Typography variant="caption">
                        {formatDate(
                            parseDate(row.created_at, 'dd/MM/yyyy HH:mm:ss'),
                            'dd/MM/yyyy'
                        )}
                    </Typography>
                ) : (
                    '- - -'
                );
            },
            minWidth: 180,
        },
        {
            field: 'requested_at',
            headerName: t('Requested at'),
            hide:
                !isEmpty(rowModesModel) ||
                hiddenColumns.includes('requested_at'),
            order: orders?.requested_at,
            renderCell: (params) => {
                const { row } = params;
                return row.requested_at ? (
                    <Typography variant="caption">
                        {formatDate(
                            parseDate(row.requested_at, 'dd/MM/yyyy HH:mm:ss'),
                            'dd/MM/yyyy'
                        )}
                    </Typography>
                ) : (
                    '- - -'
                );
            },
            minWidth: 180,
        },
        {
            field: 'updated_at',
            headerName: t('Updated at'),
            hide:
                !isEmpty(rowModesModel) || hiddenColumns.includes('updated_at'),
            order: orders?.updated_at,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {formatDate(
                            parseDate(row.updated_at, 'dd/MM/yyyy HH:mm:ss'),
                            'dd/MM/yyyy'
                        )}
                    </Typography>
                );
            },
            minWidth: 180,
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = clientColumn;
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: 'actions',
            flex: 1,
            headerName: '',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ProductActionsFormatter
                        row={row}
                        rowModesModel={rowModesModel}
                        selectedRowId={selectedRowId}
                        setRowModesModel={setRowModesModel}
                        setSelectedRowId={setSelectedRowId}
                    />
                );
            },
            sortable: false,
        },
    ];

    return orderBy(columns, ['order'], ['asc']);
};

Columns.propTypes = {
    filterData: PropTypes.object,
    handleClickDelete: PropTypes.func,
    hiddenColumns: PropTypes.array,
    loadingContext: PropTypes.object,
    orders: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.func,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default Columns;
