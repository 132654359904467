import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_PRODUCT,
    FETCH_PRODUCT_FAILURE,
    FETCH_PRODUCT_SUCCESS,
    INITIALIZE_FORM,
    OPEN_MODAL_PRODUCT,
    RESET_FORM,
    SAVE_PRODUCT,
    SAVE_PRODUCT_FAILURE,
    SAVE_PRODUCT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: {
        accesories_list_optional: [],
        amount_values: { isChecked: false, max: 0, min: 0 },
        catalog_documents: [],
        description: '',
        inverters_list: '',
        name: '',
        panels_list: '',
        parameters: { tax: 0, terms: [] },
        parameters_commission_credit: {
            isChecked: false,
            opening_commission_vat: 0,
            unit: 0,
            value: 0,
        },
        parameters_hitch: { isChecked: false, unit: 0, value: 0 },
        parameters_rate: { isChecked: false, value: 0 },
        power_values: { isChecked: false, max: 0, min: 0 },
        rate_list: {},
        storage_equipment_required: false,
        template: '',
        type: 'public',
        type_algorithm: 'static_payment',
        types_business_company: 'physical_person',
    },
    product: { data: {}, errors: [], isFetching: true },
    productModalIsOpen: false,
    save: { errors: [], isSaving: true },
};

const financialCiBancoSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_PRODUCT]: (state) => {
            state.product.isFetching = true;
        },
        [FETCH_PRODUCT_FAILURE]: (state, action) => {
            state.product = {
                ...state.product,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PRODUCT_SUCCESS]: (state, action) => {
            state.product = {
                ...state.product,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = {
                ...state.initialValues,
                ...action.payload,
            };
        },
        [OPEN_MODAL_PRODUCT]: (state, action) => {
            state.productModalIsOpen = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.save.errors = [];
        },
        [SAVE_PRODUCT]: (state) => {
            state.save.isSaving = true;
        },
        [SAVE_PRODUCT_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_PRODUCT_SUCCESS]: (state) => {
            state.save.isSaving = false;
        },
    },
});

export const financialCiBancoActions = financialCiBancoSlice.actions;

export default financialCiBancoSlice.reducer;
