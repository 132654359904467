import React from 'react';

import TabsSystem from 'common/components/TabsSystem';
import { PERMISSION_LIST } from 'common/constants/permissions';

import companyGeneral from '../companyGeneral';
import companyRoles from '../companyRoles';
import companyUsers from '../companyUsers';

const tabs = [
    {
        content: <companyGeneral.Container />,
        id: 'general',
        label: 'General',
        requiredPermissions: [PERMISSION_LIST.GENERAL_INFORMATION_PERMISSION],
    },

    {
        content: <companyRoles.Container />,
        id: 'roles',
        label: 'Roles',
        requiredPermissions: [PERMISSION_LIST.ROLES_PERMISSION],
    },

    {
        content: <companyUsers.Container />,
        id: 'users',
        label: 'Usuarios',
        requiredPermissions: [PERMISSION_LIST.USERS_PERMISSION],
    },
];

const Container = () => (
    <TabsSystem baseUrl="company" tabs={tabs} title="Empresa" />
);

export default Container;
