import { createSlice } from '@reduxjs/toolkit';

import {
    GET_LISA_FILE,
    GET_LISA_FILE_FAILURE,
    GET_LISA_FILE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = { fetchFile: { error: null, loading: false } };

const lisaFilesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [GET_LISA_FILE]: (state) => {
            state.fetchFile.loading = true;
            state.fetchFile.error = null;
        },
        [GET_LISA_FILE_FAILURE]: (state, action) => {
            state.fetchFile.error = action.payload;
            state.fetchFile.loading = false;
        },
        [GET_LISA_FILE_SUCCESS]: (state) => {
            state.fetchFile.loading = false;
        },
    },
});

export const lisaFilesActions = lisaFilesSlice.actions;

export default lisaFilesSlice.reducer;
