import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getBulkUpdateItemsModel = createSelector(
    getModel,
    (model) => model.bulkUpdateItems
);

export const getIsFetchingBulk = createSelector(
    getBulkUpdateItemsModel,
    (model) => model.isFetching
);

/* Fetch productos */
export const getProductsModel = createSelector(
    getModel,
    (model) => model.products
);

export const getProductsData = createSelector(
    getProductsModel,
    (model) => model.data.data || []
);

export const getProductsErrors = createSelector(
    getProductsModel,
    (model) => model.errors
);

export const getProductsIsFetching = createSelector(
    getProductsModel,
    (model) => model.isFetching
);

export const getProductsPagination = createSelector(
    getProductsModel,
    (model) => model.data.pagination || {}
);

/* Accesories */
export const getAccessoriesModel = createSelector(
    getModel,
    (model) => model.accessories
);

export const getAccessoriesData = createSelector(
    getAccessoriesModel,
    (model) => model.data.data
);

export const getAccessoriesErrors = createSelector(
    getAccessoriesModel,
    (model) => model.errors
);

export const getAccessoriesIsFetching = createSelector(
    getAccessoriesModel,
    (model) => model.isFetching
);

export const getAccessoriesPagination = createSelector(
    getAccessoriesModel,
    (model) => model.data.pagination
);

/* Documents */
export const getDocumentsModel = createSelector(
    getModel,
    (model) => model.documents
);

export const getDocumentsData = createSelector(
    getDocumentsModel,
    (model) => model.data
);

export const getDocumentsErrors = createSelector(
    getDocumentsModel,
    (model) => model.errors
);

export const getDocumentsIsFetching = createSelector(
    getDocumentsModel,
    (model) => model.isFetching
);

/* Inverter Brands */
export const getInverterBrands = createSelector(
    getModel,
    (model) => model.inverterBrands
);

export const getInverterBrandsData = createSelector(
    getInverterBrands,
    (model) => model.data
);

export const getIsFetchingInverterBrands = createSelector(
    getInverterBrands,
    (model) => model.isFetching
);

export const getInverterBrandsErrors = createSelector(
    getInverterBrands,
    (model) => model.errors
);

/* Inverters */
export const getInvertersModel = createSelector(
    getModel,
    (model) => model.inverters
);

export const getInvertersData = createSelector(
    getInvertersModel,
    (model) => model.data.data
);

export const getInvertersErrors = createSelector(
    getInvertersModel,
    (model) => model.errors
);

export const getInvertersIsFetching = createSelector(
    getInvertersModel,
    (model) => model.isFetching
);

export const getInvertersPagination = createSelector(
    getInvertersModel,
    (model) => model.data.pagination
);

/* Panel Brands */
export const getPanelBrands = createSelector(
    getModel,
    (model) => model.panelBrands
);

export const getPanelBrandsData = createSelector(
    getPanelBrands,
    (model) => model.data
);

export const getIsFetchingPanelBrands = createSelector(
    getPanelBrands,
    (model) => model.isFetching
);

export const getPanelBrandsErrors = createSelector(
    getPanelBrands,
    (model) => model.errors
);

/* Panels */
export const getPanelsModel = createSelector(getModel, (model) => model.panels);

export const getPanelsData = createSelector(
    getPanelsModel,
    (model) => model.data.data
);

export const getPanelsErrors = createSelector(
    getPanelsModel,
    (model) => model.errors
);

export const getPanelsIsFetching = createSelector(
    getPanelsModel,
    (model) => model.isFetching
);

export const getPanelsPagination = createSelector(
    getPanelsModel,
    (model) => model.data.pagination
);

/* Templates */
export const getfetchTemplateCatalogs = createSelector(
    getModel,
    (model) => model.fetchTemplateCatalogs
);

export const getIsFetchingTemplateCatalogs = createSelector(
    getfetchTemplateCatalogs,
    (fetchTemplateCatalogs) => fetchTemplateCatalogs.isFetching
);

export const getfetchTemplateCatalogsData = createSelector(
    getfetchTemplateCatalogs,
    (fetchTemplateCatalogs) => fetchTemplateCatalogs.data || []
);

export const getfetchTemplateCatalogsErrors = createSelector(
    getfetchTemplateCatalogs,
    (fetchTemplateCatalogs) => fetchTemplateCatalogs.errors || []
);
