import { NAME } from './constants';

export const FETCH_PRODUCT = `${NAME}/FETCH_PRODUCT`;
export const FETCH_PRODUCT_FAILURE = `${NAME}/FETCH_PRODUCT_FAILURE`;
export const FETCH_PRODUCT_SUCCESS = `${NAME}/FETCH_PRODUCT_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const OPEN_MODAL_PRODUCT = `${NAME}/OPEN_MODAL_PRODUCT`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SAVE_PRODUCT = `${NAME}/SAVE_PRODUCT`;
export const SAVE_PRODUCT_FAILURE = `${NAME}/SAVE_PRODUCT_FAILURE`;
export const SAVE_PRODUCT_SUCCESS = `${NAME}/SAVE_PRODUCT_SUCCESS`;
