import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Alert, Box, Button, Divider, Link } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import {
    ReactHookFormDragnDropFile,
    ReactHookFormInput,
    ReactHookFormSwitch,
} from 'common/components/form/rhf';
import {
    FILE_ALLOWED_EXTENSIONS,
    FILE_FOR_DOCUMENT_TYPES,
    PRODUCT_DOCUMENTS_CATEGORY,
} from 'common/constants';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const CompanyCatalogForm = ({
    errors,
    initialValues,
    isNewRecord,
    isSaving,
    save,
    type,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setValue } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const onClickSave = (values) => save(values, type);

    return (
        <form onSubmit={handleSubmit(onClickSave)}>
            <ReactHookFormInput
                control={control}
                disabled={isSaving}
                label={`${t('Name')} *`}
                name="name"
            />

            <ReactHookFormInput
                control={control}
                disabled={isSaving}
                label={`${t('Description')}`}
                name="description"
            />

            {FILE_FOR_DOCUMENT_TYPES.includes(type?.type) && (
                <ReactHookFormDragnDropFile
                    accept={FILE_ALLOWED_EXTENSIONS.PDF}
                    control={control}
                    disabled={isSaving}
                    formats="pdf"
                    name="file"
                    setValue={setValue}
                />
            )}

            {initialValues.file && (
                <Box py={1}>
                    <Link
                        color="secondary"
                        href={initialValues.file}
                        target="_blank"
                    >
                        {t('See attached files')}
                    </Link>
                </Box>
            )}

            <ReactHookFormSwitch
                control={control}
                disabled={isSaving}
                fullWidth
                label={
                    type?.category === PRODUCT_DOCUMENTS_CATEGORY
                        ? t('Required to pre-validate request')
                        : t('Mandatory document')
                }
                name="required"
            />

            {!isNewRecord && (
                <Alert severity="info">
                    {t('Changing the values ​​will only affect new requests')}
                </Alert>
            )}

            <ShowErrors errors={errors} />

            <Divider sx={{ mb: 2, mx: -2 }} />

            <Box sx={{ textAlign: 'right' }}>
                <Button disabled={isSaving} type="submit">
                    {t('Save')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSaveItem,
    initialValues: selectors.getInitialValues,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
    type: selectors.getType,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, type) => dispatch(actions.save(values, type)),
});

CompanyCatalogForm.propTypes = {
    errors: PropTypes.array,
    initialValues: PropTypes.object,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    save: PropTypes.func,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCatalogForm);
