import { styled } from '@mui/material/styles';

export default styled('span')`
    border: solid #2f4dff;
    border-width: ${({ direction }) =>
        direction === 'right'
            ? '1px 1px 0px 0px'
            : direction === 'left'
            ? '0px 0px 1px 1px'
            : direction === 'up'
            ? '1px 0px 0px 1px'
            : '0 1px 1px 0'};
    cursor: pointer;
    display: inline-block;
    margin-left: 20px;
    padding: 3px;
    transform: rotate(45deg);
`;
