import React, { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import showToast from 'common/utils/showToast';

import * as actions from '../actions';
import * as selectors from '../selectors';

import Column from './Column';
import ColumnsWrapper from './ColumnsWrapper';
import PlaceholderColumn from './PlaceholderColumn';

const Container = ({
    fetchFunnelProjects,
    fetchNextPage,
    filterQuery,
    funnelColumns,
    isLoadingProjects,
    isOnline,
    nextPagesIds,
    refreshingIsNeeded,
    selectProject,
    updatingStatusProjectId,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (!refreshingIsNeeded) return;
        showToast({
            body: t('Changes have been detected. You need to refresh the page'),
            type: 'warning',
            autoClose: false,
        });
    }, [refreshingIsNeeded]);

    useEffect(() => {
        fetchFunnelProjects(filterQuery);
    }, [filterQuery]);

    const sortedColumns = sortBy(
        Object.keys(funnelColumns).map((key) => funnelColumns[key]),
        (o) => o.index
    );

    return (
        <ColumnsWrapper>
            <PlaceholderColumn
                isEmpty={isEmpty(sortedColumns)}
                ready={!isLoadingProjects}
            >
                {sortedColumns.map((sortedColumn) => {
                    const isLoadingCurrentNextPage =
                        nextPagesIds[sortedColumn.id];

                    return (
                        <Column
                            fetchNextPage={(
                                status,
                                nextPage,
                                page,
                                setPage,
                                callback
                            ) =>
                                fetchNextPage(
                                    status,
                                    nextPage,
                                    filterQuery,
                                    page,
                                    setPage,
                                    callback
                                )
                            }
                            isLoadingCurrentNextPage={isLoadingCurrentNextPage}
                            nextProject={sortedColumn.hasNextPage}
                            id={sortedColumn.id}
                            key={sortedColumn.id}
                            isOnline={isOnline}
                            refreshingIsNeeded={refreshingIsNeeded}
                            projects={sortedColumn.projects}
                            selectProject={selectProject}
                            title={sortedColumn.name}
                            updatingStatusProjectId={updatingStatusProjectId}
                        />
                    );
                })}
            </PlaceholderColumn>
        </ColumnsWrapper>
    );
};

const mapStateToProps = createStructuredSelector({
    funnelColumns: selectors.getFunnelColumnsData,
    refreshingIsNeeded: selectors.getRefreshingIsNeeded,
    updatingStatusProjectId: selectors.getUpdatingStatusProjectId,
    isLoadingProjects: selectors.getIsFetchingFunnelColumns,
    nextPagesIds: selectors.getNextPagesIds,
});

const mapDispatchToProps = (dispatch) => ({
    fetchFunnelProjects: (filterQuery) =>
        dispatch(actions.fetchFunnelProjects(filterQuery)),
    fetchNextPage: (
        status,
        nextProject,
        filterQuery,
        page,
        setPage,
        callback
    ) =>
        dispatch(
            actions.fetchNextPage(
                status,
                nextProject,
                filterQuery,
                page,
                setPage,
                callback
            )
        ),
});

Container.propTypes = {
    fetchFunnelProjects: PropTypes.func,
    fetchNextPage: PropTypes.func,
    filterQuery: PropTypes.object,
    funnelColumns: PropTypes.object,
    isLoadingProjects: PropTypes.bool,
    isOnline: PropTypes.bool,
    nextPagesIds: PropTypes.object,
    refreshingIsNeeded: PropTypes.bool,
    selectProject: PropTypes.func,
    updatingStatusProjectId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
