import { createSlice } from '@reduxjs/toolkit';

import {
    INITIALIZE_FORM,
    RESET_FORM,
    SEND_MESSAGE,
    SEND_MESSAGE_FAILURE,
    SEND_MESSAGE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: { chatId: null, message: '', message_from: 0, user: null },
    send: { data: {}, errors: null, isFetching: false },
};

const rejectFormSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SEND_MESSAGE]: (state) => {
            state.send.isFetching = true;
        },
        [SEND_MESSAGE_FAILURE]: (state, action) => {
            state.send = {
                ...state.send,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SEND_MESSAGE_SUCCESS]: (state, action) => {
            state.send = {
                ...state.send,
                data: action.payload,
                isFetching: false,
            };
        },
    },
});

export const rejectFormActions = rejectFormSlice.actions;

export default rejectFormSlice.reducer;
