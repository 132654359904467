import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Box, Typography } from 'sunwise-ui';

import SortableItemContent from './SortableItemContent';

const StyledLi = styled('li')`
    padding: 15px 15px 0 15px;

    &:focus {
        outline: transparent !important;
    }
`;

const SortableItem = ({
    canUpdate,
    handleClickDelete,
    handleClickDuplicate,
    handleClickSelect,
    index,
    item,
    refDictionary,
    scrollDisabled,
    selectedPageId,
}) => {
    return (
        <Draggable
            draggableId={item.id || 'tempPage'}
            index={index}
            isDragDisabled={!canUpdate}
        >
            {(provided, snapshot) => (
                <StyledLi
                    className={`${item.type === 'group' && 'blocked'}`}
                    ref={provided.innerRef}
                    tabIndex={0}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div
                        ref={(ref) => {
                            refDictionary[item.id] = ref;
                        }}
                    >
                        {canUpdate && (
                            <Box display="flex" alignItems="center">
                                <UnfoldMoreIcon fontSize="small" />

                                <Typography
                                    fontWeight="bold"
                                    sx={{ color: '#ffffff' }}
                                    variant="caption"
                                >
                                    {item.page}
                                </Typography>

                                <Box
                                    alignItems="center"
                                    display="flex"
                                    gap="5px"
                                    sx={{ ml: 'auto' }}
                                >
                                    {item.type !== 'group' &&
                                        !snapshot.isDragging && (
                                            <FileCopyIcon
                                                fontSize="small"
                                                onClick={() =>
                                                    handleClickDuplicate(item)
                                                }
                                                sx={{ color: '#ffffff' }}
                                            />
                                        )}
                                    {!snapshot.isDragging && (
                                        <DeleteIcon
                                            fontSize="small"
                                            onClick={() =>
                                                handleClickDelete(
                                                    item.id,
                                                    item.type === 'page'
                                                )
                                            }
                                            sx={{ color: '#ffffff' }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        )}

                        <SortableItemContent
                            handleClickSelect={handleClickSelect}
                            index={index}
                            isDragging={snapshot}
                            item={item}
                            scrollDisabled={scrollDisabled}
                            selectedPageId={selectedPageId}
                        />
                    </div>
                </StyledLi>
            )}
        </Draggable>
    );
};

SortableItem.propTypes = {
    canUpdate: PropTypes.bool,
    handleClickDelete: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickSelect: PropTypes.func,
    index: PropTypes.number,
    item: PropTypes.object,
    refDictionary: PropTypes.object,
    scrollDisabled: PropTypes.bool,
    selectedPageId: PropTypes.string,
};

export default SortableItem;
