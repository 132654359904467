import React, { useState } from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { IconButton, AppBar, Box, Toolbar } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import Logo from './Logo';
import NotificationButton from './NotificationButton';
import NotificationsDrawer from './NotificationsDrawer';

const TopBar = ({ handleDrawerToggle, notificationsQuantity, redirect }) => {
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);

    const theme = useTheme();
    const breakpoint = useBreakpoint();
    const handleToggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        )
            return;
        setIsOpenDrawer(open);
    };
    const isDarkMode = theme.palette.mode === 'dark';
    const sxIcon = {
        color: isDarkMode ? '#61666b' : '#1f3c53',
    };

    return (
        <React.Fragment>
            <Box sx={{ zIndex: 3 }}>
                <AppBar
                    position={
                        ['xs', 'sm'].includes(breakpoint) ? 'fixed' : 'static'
                    }
                    sx={{
                        background: isDarkMode ? '#1B1C1F' : '#fff',
                        boxShadow: isDarkMode ? 'none' : null,
                        color: isDarkMode ? '#b8bfc5' : '#1f3c53',
                        height: '52px',
                        justifyContent: 'center',
                    }}
                >
                    <Toolbar>
                        <IconButton
                            onClick={handleDrawerToggle}
                            sx={{ display: { md: 'none' } }}
                        >
                            <Logo />
                        </IconButton>

                        <Box sx={{ flexGrow: 1 }} />

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <NotificationButton
                                handleToggleDrawer={handleToggleDrawer}
                                quantity={notificationsQuantity}
                                sxIcon={sxIcon}
                            />
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>

            <NotificationsDrawer
                handleToggleDrawer={handleToggleDrawer}
                isOpenDrawer={isOpenDrawer}
                redirect={redirect}
                setIsOpenDrawer={setIsOpenDrawer}
            />
        </React.Fragment>
    );
};

TopBar.propTypes = {
    handleDrawerToggle: PropTypes.func,
    notificationsQuantity: PropTypes.number,
    redirect: PropTypes.func,
};

export default TopBar;
