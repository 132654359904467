import { getNewItems } from 'common/api/funnelProposals';

import {
    FETCH_NEXT_PAGE,
    FETCH_NEXT_PAGE_FAILURE,
    FETCH_NEXT_PAGE_SUCCESS,
} from '../actionTypes';
import { PER_PAGE } from '../constants';
import { actions } from '../reducer';

export default (status, nextProject, filterQuery, page, setPage) =>
    (dispatch) => {
        dispatch(actions[FETCH_NEXT_PAGE]({ id: status }));

        const newPage = page + 1;

        getNewItems({
            filterQuery,
            nextProject,
            page: newPage,
            perPage: PER_PAGE,
            status,
        })
            .then((response) => {
                const projects = response.data.data;
                if (projects.length > 0) {
                    dispatch(
                        actions[FETCH_NEXT_PAGE_SUCCESS]({
                            id: status,
                            projects: projects,
                        })
                    );
                    setPage(newPage);
                } else {
                    dispatch(actions[FETCH_NEXT_PAGE_FAILURE]({ id: status }));
                }
            })
            .catch((error) => {
                dispatch(
                    actions[FETCH_NEXT_PAGE_FAILURE]({ error, id: status })
                );
            });
    };
