import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { bulkTemplateStatus } from 'common/api/templates';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { templatesActions } from '../reducer';

import fetchTemplates from './fetchTemplates';

export default (ids, value, filterData, callback) => (dispatch) => {
    const newValues = {
        status: value,
        templates: ids,
    };

    dispatch(templatesActions[BULK_UPDATE_ITEMS]());

    bulkTemplateStatus(newValues)
        .then(() => {
            dispatch(templatesActions[BULK_UPDATE_ITEMS_SUCCESS]());
            if (!isEmpty(filterData)) dispatch(fetchTemplates(filterData));
            if (value === 'deleted')
                showToast({ body: i18next.t('It was successfully removed') });
            if (value === 'archived')
                showToast({ body: i18next.t('Archived successfully') });
            if (value === 'active')
                showToast({
                    body: i18next.t('Unarchived successfully'),
                });
            dispatch(alerts.actions.close());
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                templatesActions[BULK_UPDATE_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
