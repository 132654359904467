import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

// DELETE SMART DOCUMENT
export const getDeleteSmartDocument = createSelector(
    getModel,
    (model) => model.deleteDocument
);

export const getIsDeletingSmartDocument = createSelector(
    getDeleteSmartDocument,
    (value) => value.isDeleting
);

// DUPLICATE SMART DOCUMENT
export const getDuplicateSmartDocument = createSelector(
    getModel,
    (model) => model.duplicateDocument
);

export const getIsDuplicatingSmartDocument = createSelector(
    getDuplicateSmartDocument,
    (value) => value.isDuplicating
);

// IS OPEN SMART DOCUMENT MODAL
export const getIsOpenSmartDocumentModal = createSelector(
    getModel,
    (model) => model.isOpenSmartDocumentModal
);

// SMART DOCUMENT FORM MODE
export const getSmartDocumentFormMode = createSelector(
    getModel,
    (model) => model.smartDocumentFormMode
);

// DOCUMENT TEMPLATES
export const getTemplates = createSelector(
    getModel,
    (model) => model.templates
);

export const getTemplatesData = createSelector(
    getTemplates,
    (value) => value.data
);

export const getIsFetchingTemplates = createSelector(
    getTemplates,
    (value) => value.isFetching
);

// SMART DOCUMENTS
export const getSmartDocuments = createSelector(
    getModel,
    (model) => model.smartDocuments
);

export const getSmartDocumentsData = createSelector(
    getSmartDocuments,
    (value) => value.data
);

export const getIsFetchingSmartDocuments = createSelector(
    getSmartDocuments,
    (value) => value.isFetching
);

// FETCH CUSTOM TEMPLATE
export const getFetchCustomTemplate = createSelector(
    getModel,
    (model) => model.fetchCustomTemplate
);

export const getIsFetchingCustomTemplate = createSelector(
    getFetchCustomTemplate,
    (value) => value.isFetching
);

// RENAME SMART DOCUMENT
export const getRenameSmartDocument = createSelector(
    getModel,
    (model) => model.renameDocument
);

export const getIsRenamingSmartDocument = createSelector(
    getRenameSmartDocument,
    (value) => value.isRenaming
);

// SAVE SMART DOCUMENT
export const getSaveSmartDocument = createSelector(
    getModel,
    (model) => model.saveSmartDocument
);

export const getIsSavingSmartDocument = createSelector(
    getSaveSmartDocument,
    (value) => value.isSaving
);

// SMART DOCUMENT INITIAL VALUES
export const getSmartDocumentInitialValues = createSelector(
    getModel,
    (model) => model.smartDocumentInitialValues
);
