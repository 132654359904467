import i18next from 'i18next';

import alerts from 'common/modules/alerts';
import { getStatusText } from 'common/utils/helpers';

import changeStatusCredit from './changeStatusCredit';

export default (item, callback, loadingContext) => (dispatch) => {
    const dataAlert = {
        messages: [
            i18next.t(
                'Are you sure you want to change the status to "{{status}}"?',
                { status: getStatusText(item.status, false) }
            ),
        ],
        onSuccess: () =>
            dispatch(changeStatusCredit(item, callback, loadingContext)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'error',
    };

    dispatch(alerts.actions.show(dataAlert));
};
