import { getSmartDocuments } from 'common/api/smartDocuments';

import {
    FETCH_SMART_DOCUMENTS,
    FETCH_SMART_DOCUMENTS_FAILURE,
    FETCH_SMART_DOCUMENTS_SUCCESS,
} from '../actionTypes';
import { templateDocumentsActions } from '../reducer';

export default (proposalId) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(templateDocumentsActions[FETCH_SMART_DOCUMENTS]());

        getSmartDocuments(proposalId)
            .then((response) =>
                dispatch(
                    templateDocumentsActions[FETCH_SMART_DOCUMENTS_SUCCESS](
                        response.data.data
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    templateDocumentsActions[FETCH_SMART_DOCUMENTS_FAILURE](
                        error
                    )
                )
            )
            .finally(resolve);
    });
