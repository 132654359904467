import React from 'react';

import PropTypes from 'prop-types';

import DrawerContent from './DrawerContent';
import StyledDrawer from './StyledDrawer';

const SideBar = ({
    drawerSize,
    handleDrawerToggle,
    match,
    mobileOpen,
    multiBranchMode,
    profileValues,
    redirect,
    selectedTheme,
    setTheme,
    subscription,
    toggleDrawerSize,
}) => {
    const container =
        window !== undefined ? () => window.document.body : undefined;
    const drawerContent = (
        <DrawerContent
            {...{
                container,
                drawerSize,
                handleDrawerToggle,
                match,
                mobileOpen,
                multiBranchMode,
                profileValues,
                redirect,
                selectedTheme,
                setTheme,
                subscription,
                toggleDrawerSize,
            }}
        />
    );
    return (
        <React.Fragment>
            <StyledDrawer
                drawersize={drawerSize}
                variant="permanent"
                sx={{ display: { xs: 'none', md: 'block' } }}
            >
                {drawerContent}
            </StyledDrawer>

            <StyledDrawer
                container={container}
                drawersize={drawerSize}
                ModalProps={{ keepMounted: true }}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                variant="temporary"
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: '100%',
                    },
                }}
            >
                {drawerContent}
            </StyledDrawer>
        </React.Fragment>
    );
};

SideBar.propTypes = {
    drawerSize: PropTypes.string,
    handleDrawerToggle: PropTypes.func,
    match: PropTypes.object,
    mobileOpen: PropTypes.bool,
    multiBranchMode: PropTypes.bool,
    profileValues: PropTypes.object,
    redirect: PropTypes.func,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
    subscription: PropTypes.object,
    toggleDrawerSize: PropTypes.func,
};

export default SideBar;
