import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Card,
    Divider,
    Grid,
    List,
    ListItem,
    Typography,
} from 'sunwise-ui';

import { KeyValue, Modal } from 'common/components';
import { PlaceholderCardContactsDashboard } from 'common/components/placeholder';
import { numberFormat } from 'common/utils/helpers';

import { ContactForm } from '../components';

const ContactFileCard = ({
    canModify,
    creditId,
    client,
    handleClickUpdate,
    handleClickCloseModalContact,
    isOpenModalContact,
    isLoadingContact,
    systemSize,
    systemGeneration,
}) => {
    const { t } = useTranslation();
    const {
        agent_name = t('No assigned advisor'),
        business_name,
        emails = [],
        first_lastname = '',
        name = '',
        origin = t('Without origin'),
        second_lastname = '',
        telephones = [],
    } = client;

    return (
        <PlaceholderCardContactsDashboard ready={isLoadingContact}>
            <Box>
                <Card>
                    <Card.Header>
                        <Typography fontWeight="bold" variant="body2">
                            {t('Client')}
                        </Typography>
                    </Card.Header>

                    <List>
                        <ListItem>
                            <Typography variant="body2">
                                {name} {first_lastname} {second_lastname}
                            </Typography>
                        </ListItem>

                        <ListItem>
                            <Box alignItems="center" display="flex" gap={1}>
                                <Typography fontWeight="bold" variant="body2">
                                    {t('Business name')}:
                                </Typography>
                                <Typography variant="body2">
                                    {business_name || '- - -'}
                                </Typography>
                            </Box>
                        </ListItem>

                        <ListItem>
                            <Grid container>
                                {isArray(telephones) &&
                                telephones?.length > 0 ? (
                                    telephones?.map(({ telephone }, index) => (
                                        <Grid
                                            alignItems="center"
                                            display="flex"
                                            gap={1}
                                            item
                                            key={index}
                                            xs
                                        >
                                            <PhoneIcon fontSize="small" />
                                            <Typography variant="body2">
                                                {telephone}
                                            </Typography>
                                        </Grid>
                                    ))
                                ) : (
                                    <Grid item xs>
                                        <Typography variant="caption">
                                            {t('No phone registered')}
                                        </Typography>
                                    </Grid>
                                )}

                                {isArray(emails) && emails?.length > 0 ? (
                                    <Grid
                                        alignItems="center"
                                        display="flex"
                                        gap={1}
                                        item
                                        xs
                                    >
                                        <EmailIcon fontSize="small" />
                                        <Typography variant="body2">
                                            {emails[0].email}
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <Grid item xs>
                                        <Typography variant="caption">
                                            {t('No registered email')}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </ListItem>

                        <ListItem>
                            <Grid container>
                                <Grid
                                    alignItems="center"
                                    display="flex"
                                    gap={1}
                                    item
                                    md={12}
                                >
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {t('Assigned advisor')}:
                                    </Typography>
                                    <Typography variant="body2">
                                        {agent_name || '- - -'}
                                    </Typography>
                                </Grid>
                                <Grid
                                    alignItems="center"
                                    display="flex"
                                    gap={1}
                                    item
                                    md={12}
                                >
                                    <Typography
                                        fontWeight="bold"
                                        variant="body2"
                                    >
                                        {t('Origin')}:
                                    </Typography>
                                    <Typography variant="body2">
                                        {origin || '- - -'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ListItem>

                        <ListItem>
                            <Grid container sx={{ mb: 2 }}>
                                <Grid item xs>
                                    <KeyValue
                                        append="kW"
                                        title={t('System size')}
                                        value={numberFormat(systemSize, {
                                            style: 'decimal',
                                        })}
                                    />
                                </Grid>

                                <Grid item xs>
                                    <KeyValue
                                        append="kWH"
                                        title={t('Annual generation')}
                                        value={numberFormat(systemGeneration, {
                                            style: 'decimal',
                                        })}
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>
                    </List>

                    <Divider />

                    <Card.Body hidden={!canModify} sx={{ textAlign: 'center' }}>
                        <Button
                            color="secondary"
                            onClick={() => handleClickUpdate(client)}
                            variant="text"
                        >
                            {t('Edit')}
                        </Button>
                    </Card.Body>
                </Card>
            </Box>

            <Modal
                onHide={handleClickCloseModalContact}
                show={isOpenModalContact}
                size="lg"
                title={t('Edit')}
            >
                <ContactForm
                    creditId={creditId}
                    contactId={client.id}
                    handleClickCloseForm={handleClickCloseModalContact}
                />
            </Modal>
        </PlaceholderCardContactsDashboard>
    );
};

ContactFileCard.propTypes = {
    canModify: PropTypes.bool,
    creditId: PropTypes.string,
    client: PropTypes.object,
    handleClickUpdate: PropTypes.func,
    handleClickCloseModalContact: PropTypes.func,
    isOpenModalContact: PropTypes.bool,
    isLoadingContact: PropTypes.bool,
    systemSize: PropTypes.number,
    systemGeneration: PropTypes.number,
};

export default ContactFileCard;
