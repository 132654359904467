import { exportFile } from 'common/api/creditApplications';

import { SET_IS_DOWNLOADING } from '../actionTypes';
import { creditsActions } from '../reducer';

export default (filterData) => (dispatch) => {
    const { allianceId, financialAdvisor, searchText, status } = filterData;

    dispatch(creditsActions[SET_IS_DOWNLOADING](true));

    exportFile({
        allianceId,
        financialAdvisor,
        searchText,
        status: status === 'all' ? '' : status,
    })
        .then((response) => {
            dispatch(creditsActions[SET_IS_DOWNLOADING](false));
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Solicitudes.csv`);
            document.body.appendChild(link);
            link.click();
            dispatch(creditsActions[SET_IS_DOWNLOADING](false));
        })
        .catch(() => dispatch(creditsActions[SET_IS_DOWNLOADING](false)));
};
