import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_SMART_DOCUMENT,
    DELETE_SMART_DOCUMENT_FAILURE,
    DELETE_SMART_DOCUMENT_SUCCESS,
    DUPLICATE_SMART_DOCUMENT,
    DUPLICATE_SMART_DOCUMENT_FAILURE,
    DUPLICATE_SMART_DOCUMENT_SUCCESS,
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    FETCH_SMART_DOCUMENTS,
    FETCH_SMART_DOCUMENTS_FAILURE,
    FETCH_SMART_DOCUMENTS_SUCCESS,
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
    INITIALIZE_SMART_DOCUMENT_FORM,
    RENAME_SMART_DOCUMENT,
    RENAME_SMART_DOCUMENT_FAILURE,
    RENAME_SMART_DOCUMENT_SUCCESS,
    RESET,
    RESET_DOCUMENT_FORM,
    SAVE_SMART_DOCUMENT,
    SAVE_SMART_DOCUMENT_FAILURE,
    SAVE_SMART_DOCUMENT_SUCCESS,
    SET_IS_OPEN_SMART_DOCUMENT_MODAL,
    SET_SMART_DOCUMENT_FORM_MODE,
} from './actionTypes';
import { NAME, SMART_DOCUMENT_FORM_CREATE_MODE } from './constants';

const INITIAL_STATE = {
    deleteDocument: { errors: [], isDeleting: false },
    duplicateDocument: { errors: [], isDuplicating: false },
    fetchCustomTemplate: { data: null, errors: [], isFetching: false },
    isOpenSmartDocumentModal: false,
    renameDocument: { errors: [], isRenaming: false },
    saveSmartDocument: { errors: [], isSaving: false },
    smartDocumentFormMode: SMART_DOCUMENT_FORM_CREATE_MODE,
    smartDocumentInitialValues: {
        commercial_offer_id: '',
        id: null,
        name: '',
        template: '',
    },
    smartDocuments: { data: [], errors: [], isFetching: false },
    templates: { data: [], errors: [], isFetching: false },
};

const templateDocumentsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_SMART_DOCUMENT]: (state) => {
            state.deleteDocument = {
                ...state.deleteDocument,
                errors: [],
                isDeleting: true,
            };
        },
        [DELETE_SMART_DOCUMENT_FAILURE]: (state, action) => {
            state.deleteDocument = {
                ...state.deleteDocument,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_SMART_DOCUMENT_SUCCESS]: (state) => {
            state.deleteDocument.isDeleting = false;
        },
        [DUPLICATE_SMART_DOCUMENT]: (state) => {
            state.duplicateDocument = {
                ...state.duplicateDocument,
                errors: [],
                isDuplicating: true,
            };
        },
        [DUPLICATE_SMART_DOCUMENT_FAILURE]: (state, action) => {
            state.duplicateDocument = {
                ...state.duplicateDocument,
                errors: action.payload,
                isDuplicating: false,
            };
        },
        [DUPLICATE_SMART_DOCUMENT_SUCCESS]: (state) => {
            state.duplicateDocument.isDuplicating = false;
        },
        [FETCH_CUSTOM_TEMPLATE]: (state) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: null,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_FAILURE]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CUSTOM_TEMPLATE_SUCCESS]: (state, action) => {
            state.fetchCustomTemplate = {
                ...state.fetchCustomTemplate,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SMART_DOCUMENTS]: (state) => {
            state.smartDocuments = {
                ...state.smartDocuments,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_SMART_DOCUMENTS_FAILURE]: (state, action) => {
            state.smartDocuments = {
                ...state.smartDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SMART_DOCUMENTS_SUCCESS]: (state, action) => {
            state.smartDocuments = {
                ...state.smartDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATES]: (state) => {
            state.templates = {
                ...state.templates,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_TEMPLATES_FAILURE]: (state, action) => {
            state.templates = {
                ...state.templates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATES_SUCCESS]: (state, action) => {
            state.templates = {
                ...state.templates,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_SMART_DOCUMENT_FORM]: (state, action) => {
            state.smartDocumentInitialValues = {
                ...state.smartDocumentInitialValues,
                ...action.payload,
            };
        },
        [SET_IS_OPEN_SMART_DOCUMENT_MODAL]: (state, action) => {
            state.isOpenSmartDocumentModal = action.payload;
        },
        [RENAME_SMART_DOCUMENT]: (state) => {
            state.renameDocument = {
                ...state.renameDocument,
                errors: [],
                isRenaming: true,
            };
        },
        [RENAME_SMART_DOCUMENT_FAILURE]: (state, action) => {
            state.renameDocument = {
                ...state.renameDocument,
                errors: action.payload,
                isRenaming: false,
            };
        },
        [RENAME_SMART_DOCUMENT_SUCCESS]: (state) => {
            state.renameDocument.isRenaming = false;
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_DOCUMENT_FORM]: (state) => {
            state.smartDocumentInitialValues =
                INITIAL_STATE.smartDocumentInitialValues;
        },
        [SAVE_SMART_DOCUMENT]: (state) => {
            state.saveSmartDocument = {
                ...state.saveSmartDocument,
                errors: [],
                isSaving: true,
            };
        },
        [SAVE_SMART_DOCUMENT_FAILURE]: (state, action) => {
            state.saveSmartDocument = {
                ...state.saveSmartDocument,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SMART_DOCUMENT_SUCCESS]: (state) => {
            state.saveSmartDocument.isSaving = false;
        },
        [SET_SMART_DOCUMENT_FORM_MODE]: (state, action) => {
            state.smartDocumentFormMode = action.payload;
        },
    },
});

export const templateDocumentsActions = templateDocumentsSlice.actions;

export default templateDocumentsSlice.reducer;
