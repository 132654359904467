import { fetchListCatalogs } from 'common/api/documentCatalog';

import {
    FETCH_COMPANIES_CATALOGS,
    FETCH_COMPANIES_CATALOGS_FAILURE,
    FETCH_COMPANIES_CATALOGS_SUCCESS,
} from '../actionTypes';
import { configurationDocumentsActions } from '../reducer';

import setType from './setType';

export default (values) => (dispatch) => {
    dispatch(setType(values));
    dispatch(configurationDocumentsActions[FETCH_COMPANIES_CATALOGS]());

    fetchListCatalogs()
        .then((response) => {
            const dataFiltered = response.data.data.filter(
                (item) =>
                    item?.category === values?.category &&
                    item?.type === values?.type
            );

            dispatch(
                configurationDocumentsActions[FETCH_COMPANIES_CATALOGS_SUCCESS](
                    dataFiltered
                )
            );
        })
        .catch((error) =>
            dispatch(
                configurationDocumentsActions[FETCH_COMPANIES_CATALOGS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
