import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

export default (values = {}) => {
    const errors = {};
    if (isEmpty(values.name)) errors.name = i18next.t('Name cannot be empty');
    if (
        values.type_algorithm === 'static_api_key' &&
        values.type_proposal_template === 'template' &&
        isEmpty(values.template)
    )
        errors.template = i18next.t(
            'Template cannot be empty if it is the document to be used'
        );
    if (values.type_algorithm === 'static_api_key' && isEmpty(values.api_key))
        errors.api_key = i18next.t('API Key cannot be empty');
    if (
        values.type_algorithm === 'static_api_key' &&
        isEmpty(values.secret_key)
    )
        errors.secret_key = i18next.t('API Key cannot be empty');

    if (
        values.parameters_hitch &&
        values.parameters_hitch.unit === 0 &&
        (values.parameters_hitch.value < 0 ||
            values.parameters_hitch.value > 100)
    )
        errors.parameters_hitch = {
            value: i18next.t('The value must be between {{min}} and {{max}}', {
                max: 100,
                min: 0,
            }),
        };
    return errors;
};
