import { filterNotifications } from 'common/api/notifications';

import {
    FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATIONS_FAILURE,
    FETCH_NOTIFICATIONS_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';

export default (filterData = {}) =>
    (dispatch) => {
        dispatch(notificationsActions[FETCH_NOTIFICATIONS]());

        const {
            page = 0,
            perPage = 25,
            type = null,
            viewed = null,
        } = filterData;

        filterNotifications({
            page: page + 1,
            perPage,
            type: type !== 'all' ? type : null,
            viewed: viewed !== 'all' ? viewed : null,
        })
            .then((response) =>
                dispatch(
                    notificationsActions[FETCH_NOTIFICATIONS_SUCCESS](
                        response.data
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    notificationsActions[FETCH_NOTIFICATIONS_FAILURE](error)
                )
            );
    };
