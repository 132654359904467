import { object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { LANGUAGE_REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        language: string().required(LANGUAGE_REQUIRED_TEXT).nullable(),
    });
};
