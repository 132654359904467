import { NAME } from './constants';

export const APPROVE_ALLIANCE = `${NAME}/APPROVE_ALLIANCE`;
export const APPROVE_ALLIANCE_FAILURE = `${NAME}/APPROVE_ALLIANCE_FAILURE`;
export const APPROVE_ALLIANCE_SUCCESS = `${NAME}/APPROVE_ALLIANCE_SUCCESS`;

export const CHANGE_PRODUCT_STATUS = `${NAME}/CHANGE_PRODUCT_STATUS`;
export const CHANGE_PRODUCT_STATUS_FAILURE = `${NAME}/CHANGE_PRODUCT_STATUS_FAILURE`;
export const CHANGE_PRODUCT_STATUS_SUCCESS = `${NAME}/CHANGE_PRODUCT_STATUS_SUCCESS`;

export const CHANGE_STATUS_ALLIANCE = `${NAME}/CHANGE_STATUS_ALLIANCE`;
export const CHANGE_STATUS_ALLIANCE_FAILURE = `${NAME}/CHANGE_STATUS_ALLIANCE_FAILURE`;
export const CHANGE_STATUS_ALLIANCE_SUCCESS = `${NAME}/CHANGE_STATUS_ALLIANCE_SUCCESS`;

export const CHANGE_STATUS_FILE = `${NAME}/CHANGE_STATUS_FILE`;
export const CHANGE_STATUS_FILE_FAILURE = `${NAME}/CHANGE_STATUS_FILE_FAILURE`;
export const CHANGE_STATUS_FILE_SUCCESS = `${NAME}/CHANGE_STATUS_FILE_SUCCESS`;

export const CLOSE_ALLIANCE = `${NAME}/CLOSE_ALLIANCE`;
export const CLOSE_ALLIANCE_FAILURE = `${NAME}/CLOSE_ALLIANCE_FAILURE`;
export const CLOSE_ALLIANCE_SUCCESS = `${NAME}/CLOSE_ALLIANCE_SUCCESS`;

export const DELETE_DOCUMENT = `${NAME}/DELETE_DOCUMENT`;
export const DELETE_DOCUMENT_FAILURE = `${NAME}/DELETE_DOCUMENT_FAILURE`;
export const DELETE_DOCUMENT_SUCCESS = `${NAME}/DELETE_DOCUMENT_SUCCESS`;

export const FETCH_ASSIGNED_USER = `${NAME}/FETCH_ASSIGNED_USER`;
export const FETCH_ASSIGNED_USER_FAILURE = `${NAME}/FETCH_ASSIGNED_USER_FAILURE`;
export const FETCH_ASSIGNED_USER_SUCCESS = `${NAME}/FETCH_ASSIGNED_USER_SUCCESS`;

export const FETCH_COMPANY_USERS = `${NAME}/FETCH_COMPANY_USERS`;
export const FETCH_CONPANY_USERS_FAILURE = `${NAME}/FETCH_CONPANY_USERS_FAILURE`;
export const FETCH_COMPANY_USERS_SUCCESS = `${NAME}/FETCH_COMPANY_USERS_SUCCESS`;

export const FETCH_CONTRACT_DOCUMENTS = `${NAME}/FETCH_CONTRACT_DOCUMENTS`;
export const FETCH_CONTRACT_DOCUMENTS_FAILURE = `${NAME}/FETCH_CONTRACT_DOCUMENTS_FAILURE`;
export const FETCH_CONTRACT_DOCUMENTS_SUCCESS = `${NAME}/FETCH_CONTRACT_DOCUMENTS_SUCCESS`;

export const FETCH_DOCUMENTS = `${NAME}/FETCH_DOCUMENTS`;
export const FETCH_DOCUMENTS_FAILURE = `${NAME}/FETCH_DOCUMENTS_FAILURE`;
export const FETCH_DOCUMENTS_SUCCESS = `${NAME}/FETCH_DOCUMENTS_SUCCESS`;

export const FETCH_REQUEST_FORM_DOCUMENTS = `${NAME}/FETCH_REQUEST_FORM_DOCUMENTS`;
export const FETCH_REQUEST_FORM_DOCUMENTS_FAILURE = `${NAME}/FETCH_REQUEST_FORM_DOCUMENTS_FAILURE`;
export const FETCH_REQUEST_FORM_DOCUMENTS_SUCCESS = `${NAME}/FETCH_REQUEST_FORM_DOCUMENTS_SUCCESS`;

export const FETCH_FINANCIER_INSTALLER = `${NAME}/FETCH_FINANCIER_INSTALLER`;
export const FETCH_FINANCIER_INSTALLER_FAILURE = `${NAME}/FETCH_FINANCIER_INSTALLER_FAILURE`;
export const FETCH_FINANCIER_INSTALLER_SUCCESS = `${NAME}/FETCH_FINANCIER_INSTALLER_SUCCESS`;

export const FETCH_PROJECTS_SALES_STAGE = `${NAME}/FETCH_PROJECTS_SALES_STAGE`;
export const FETCH_PROJECTS_SALES_STAGE_FAILURE = `${NAME}/FETCH_PROJECTS_SALES_STAGE_FAILURE`;
export const FETCH_PROJECTS_SALES_STAGE_SUCCESS = `${NAME}/FETCH_PROJECTS_SALES_STAGE_SUCCESS`;

export const FETCH_INSTALLER = `${NAME}/FETCH_INSTALLER`;
export const FETCH_INSTALLER_FAILURE = `${NAME}/FETCH_INSTALLER_FAILURE`;
export const FETCH_INSTALLER_SUCCESS = `${NAME}/FETCH_INSTALLER_SUCCESS`;

export const FETCH_PRODUCTS_LIST = `${NAME}/FETCH_PRODUCTS_LIST`;
export const FETCH_PRODUCTS_LIST_FAILURE = `${NAME}/FETCH_PRODUCTS_LIST_FAILURE`;
export const FETCH_PRODUCTS_LIST_SUCCESS = `${NAME}/FETCH_PRODUCTS_LIST_SUCCESS`;

export const FETCH_PROPOSALS_TOTALS = `${NAME}/FETCH_PROPOSALS_TOTALS`;
export const FETCH_PROPOSALS_TOTALS_FAILURE = `${NAME}/FETCH_PROPOSALS_TOTALS_FAILURE`;
export const FETCH_PROPOSALS_TOTALS_SUCCESS = `${NAME}/FETCH_PROPOSALS_TOTALS_SUCCESS`;

export const FETCH_REQUIRED_DOCUMENTS_LIST = `${NAME}/FETCH_REQUIRED_DOCUMENTS_LIST`;
export const FETCH_REQUIRED_DOCUMENTS_LIST_FAILURE = `${NAME}/FETCH_REQUIRED_DOCUMENTS_LIST_FAILURE`;
export const FETCH_REQUIRED_DOCUMENTS_LIST_SUCCESS = `${NAME}/FETCH_REQUIRED_DOCUMENTS_LIST_SUCCESS`;

export const FETCH_SELECTED_PRODUCTS = `${NAME}/FETCH_SELECTED_PRODUCTS`;
export const FETCH_SELECTED_PRODUCTS_FAILURE = `${NAME}/FETCH_SELECTED_PRODUCTS_FAILURE`;
export const FETCH_SELECTED_PRODUCTS_SUCCESS = `${NAME}/FETCH_SELECTED_PRODUCTS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const INITIALIZE_FORM_INSTALLER_DATA = `${NAME}/INITIALIZE_FORM_INSTALLER_DATA`;

export const INITIALIZE_PRODUCTS_FORM = `${NAME}/INITIALIZE_PRODUCTS_FORM`;

export const REJECT_ALLIANCE = `${NAME}/REJECT_ALLIANCE`;
export const REJECT_ALLIANCE_FAILURE = `${NAME}/REJECT_ALLIANCE_FAILURE`;
export const REJECT_ALLIANCE_SUCCESS = `${NAME}/REJECT_ALLIANCE_SUCCESS`;

export const REJECT_FILE = `${NAME}/REJECT_FILE`;
export const REJECT_FILE_FAILURE = `${NAME}/REJECT_FILE_FAILURE`;
export const REJECT_FILE_SUCCESS = `${NAME}/REJECT_FILE_SUCCESS`;

export const RESET = `${NAME}/RESET`;
export const RESET_ASSIGN_USER_FORM = `${NAME}/RESET_ASSIGN_USER_FORM`;
export const RESET_PRODUCTS_FORM = `${NAME}/RESET_PRODUCTS_FORM`;
export const RESET_UPLOAD_FORM = `${NAME}/RESET_UPLOAD_FORM`;

export const SAVE_ASSIGNED_USER = `${NAME}/SAVE_ASSIGNED_USER`;
export const SAVE_ASSIGNED_USER_FAILURE = `${NAME}/SAVE_ASSIGNED_USER_FAILURE`;
export const SAVE_ASSIGNED_USER_SUCCESS = `${NAME}/SAVE_ASSIGNED_USER_SUCCESS`;

export const SAVE_DOCUMENT = `${NAME}/SAVE_DOCUMENT`;
export const SAVE_DOCUMENT_FAILURE = `${NAME}/SAVE_DOCUMENT_FAILURE`;
export const SAVE_DOCUMENT_SUCCESS = `${NAME}/SAVE_DOCUMENT_SUCCESS`;

export const SAVE_SELECTED_PRODUCTS = `${NAME}/SAVE_SELECTED_PRODUCTS`;
export const SAVE_SELECTED_PRODUCTS_FAILURE = `${NAME}/SAVE_SELECTED_PRODUCTS_FAILURE`;
export const SAVE_SELECTED_PRODUCTS_SUCCESS = `${NAME}/SAVE_SELECTED_PRODUCTS_SUCCESS`;

export const SET_IS_DOWNLOADING = `${NAME}/SET_IS_DOWNLOADING`;

export const UPDATE_INSTALLER_DATA = `${NAME}/UPDATE_INSTALLER_DATA`;
export const UPDATE_INSTALLER_DATA_FAILURE = `${NAME}/UPDATE_INSTALLER_DATA_FAILURE`;
export const UPDATE_INSTALLER_DATA_SUCCESS = `${NAME}/UPDATE_INSTALLER_DATA_SUCCESS`;

export const UPDATE_INSTALLER_USERS = `${NAME}/UPDATE_INSTALLER_USERS`;
export const UPDATE_INSTALLER_USERS_FAILURE = `${NAME}/UPDATE_INSTALLER_USERS_FAILURE`;
export const UPDATE_INSTALLER_USERS_SUCCESS = `${NAME}/UPDATE_INSTALLER_USERS_SUCCESS`;

export const UPLOAD_DOCUMENT = `${NAME}/UPLOAD_DOCUMENT`;
export const UPLOAD_DOCUMENT_FAILURE = `${NAME}/UPLOAD_DOCUMENT_FAILURE`;
export const UPLOAD_DOCUMENT_SUCCESS = `${NAME}/UPLOAD_DOCUMENT_SUCCESS`;
