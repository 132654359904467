import i18next from 'i18next';

import { duplicateProduct } from 'common/api/financierProduct';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DUPLICATE_PRODUCT,
    DUPLICATE_PRODUCT_FAILURE,
    DUPLICATE_PRODUCT_SUCCESS,
} from '../actionTypes';
import { financialActions } from '../reducer';

import fetchProducts from './fetchProducts';

export default (product) => (dispatch) => {
    dispatch(financialActions[DUPLICATE_PRODUCT]());

    duplicateProduct(product.id)
        .then(() => {
            dispatch(financialActions[DUPLICATE_PRODUCT_SUCCESS]());
            dispatch(fetchProducts());
            showToast({ body: i18next.t('Successfully duplicated') });
        })
        .catch((error) => {
            dispatch(
                financialActions[DUPLICATE_PRODUCT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
