import React, { useEffect, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from 'sunwise-ui';

import { arraytoDictionary } from 'common/utils/helpers';

const ReduxFieldAutocomplete = (props) => {
    const {
        disabled,
        id,
        input: { value, onChange },
        label,
        meta: { touched, error },
        options,
        required,
        variant,
    } = props;
    const dic = arraytoDictionary(options, 'value');
    const [currentValue, setCurrentValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const isValidValue = (value) => !isEmpty(value);

    useEffect(() => {
        if (isValidValue(value)) {
            setCurrentValue(value);
            setInputValue(dic[value]?.label);
        }
    }, [value]);

    return (
        <Autocomplete
            disabled={disabled}
            getOptionDisabled={(option) =>
                option?.disabled || option.value === ''
            }
            getOptionLabel={(option) => option?.label || ''}
            id={id}
            inputValue={inputValue}
            isOptionEqualToValue={(option, value) =>
                isValidValue(value) && option.value === value
            }
            onChange={(_, newValue) => {
                setCurrentValue(newValue?.value || null);
                if (onChange) onChange(newValue?.value);
            }}
            onInputChange={(event, newInputValue, reason) => {
                if (reason !== 'reset') setInputValue(newInputValue);
            }}
            options={options}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={Boolean(error) && touched}
                    helperText={error?.message}
                    label={label}
                    variant={variant}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={`${option.value}-${option.label}`}>
                    {option.label}
                </li>
            )}
            required={required}
            value={currentValue}
        />
    );
};

ReduxFieldAutocomplete.propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.array,
    required: PropTypes.bool,
    variant: PropTypes.string,
};

export default ReduxFieldAutocomplete;
