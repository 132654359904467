import { fetchListCatalogs } from 'common/api/documentCatalog';
import { PRODUCT_DOCUMENTS_CATEGORY } from 'common/constants';

import {
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_FAILURE,
    FETCH_DOCUMENTS_SUCCESS,
} from '../actionTypes';
import { financialActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(financialActions[FETCH_DOCUMENTS]());

    fetchListCatalogs()
        .then((response) => {
            const dataFiltered = response.data.data.filter(
                (item) => item.category === PRODUCT_DOCUMENTS_CATEGORY
            );
            dispatch(financialActions[FETCH_DOCUMENTS_SUCCESS](dataFiltered));
        })
        .catch((error) =>
            dispatch(
                financialActions[FETCH_DOCUMENTS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
