import React, { useRef, useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Box, Card, Typography } from 'sunwise-ui';

import { scroll } from 'common/utils/mixins';

import ItemCard from './ItemCard';

const Wrapper = styled(Box)`
    flex: 0 0 auto;
    overflow: hidden;
    width: 316px;
    padding-left: 12px;
`;

const ColumnContainer = styled(Box)`
    height: calc(100% - 42px);
    overflow: hidden;
    scrollbar-gutter: stable;
    ${scroll.custom()}
    .scroll-loader {
        color: #b6b9ba;
        font-weight: 600;
        font-size: 14px;
    }
    &:hover {
        overflow-y: auto;
        overflow-y: overlay;
    }
    & > div {
        display: flex;
        flex-direction: column;
        min-height: 100%;
    }
`;

const ItemsList = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: calc(100% - 15px);
    padding: 0 12px 0 0;
    width: 100%;
`;

const Title = styled(Typography)`
    cursor: default;
    display: block;
    font-size: 22px;
    font-weight: bold;
    height: 27px;
    line-height: 27px;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Column = ({
    fetchNextPage,
    id,
    isLoadingCurrentNextPage,
    isOnline,
    nextProject,
    projects,
    refreshingIsNeeded,
    selectProject,
    title,
    updatingStatusProjectId,
}) => {
    const { t } = useTranslation();
    const columnDivRef = useRef();
    const [page, setPage] = useState(1);

    const loadMoreProjects = () => {
        fetchNextPage(id, nextProject, page, setPage);
        if (columnDivRef.current) {
            columnDivRef.current.scrollTop =
                columnDivRef.current.scrollTop - 60;
        }
    };

    const hasNextPage = nextProject && !isLoadingCurrentNextPage;

    return (
        <Wrapper>
            <Title title={title}>{title}</Title>
            <ColumnContainer ref={columnDivRef}>
                <InfiniteScroll
                    key={`infinite-${id}`}
                    pageStart={1}
                    loadMore={loadMoreProjects}
                    hasMore={hasNextPage}
                    loader={
                        <div className="scroll-loader" key={0}>
                            {t('Loading').concat('...')}
                        </div>
                    }
                    useWindow={false}
                    threshold={50}
                    getScrollParent={() => columnDivRef.current}
                >
                    <ItemsList>
                        {!projects.length && (
                            <Card
                                sx={{
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                <Card.Body>{t('No items')}</Card.Body>
                            </Card>
                        )}
                        {projects.map((item, index) => (
                            <ItemCard
                                id={item.id}
                                index={index}
                                key={item.id}
                                project={item}
                                selectProject={selectProject}
                                disabled={
                                    refreshingIsNeeded ||
                                    !isOnline ||
                                    item.id === updatingStatusProjectId
                                }
                            />
                        ))}
                    </ItemsList>
                </InfiniteScroll>
            </ColumnContainer>
        </Wrapper>
    );
};
Column.propTypes = {
    fetchNextPage: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isLoadingCurrentNextPage: PropTypes.bool,
    isOnline: PropTypes.bool,
    nextProject: PropTypes.bool,
    projects: PropTypes.array,
    refreshingIsNeeded: PropTypes.bool,
    selectProject: PropTypes.func,
    title: PropTypes.string,
    updatingStatusProjectId: PropTypes.string,
};

export default Column;
