import React from 'react';

import PropTypes from 'prop-types';

import { validateByPermissionCode } from 'common/utils/permissions';

const PermissionsComponent = (props) => {
    const { Children, injectedProp, permissionCode } = props;
    return (
        <Children
            {...injectedProp}
            {...validateByPermissionCode(permissionCode)}
        />
    );
};

PermissionsComponent.propTypes = {
    Children: PropTypes.node,
    injectedProp: PropTypes.object,
    permissionCode: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
};

const PermissionsHOC = (permissionCode) => (Children) => (props) =>
    PermissionsComponent({
        injectedProp: props,
        permissionCode,
        Children,
    });

export default PermissionsHOC;
