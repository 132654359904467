import { enUS, es, ptBR } from 'date-fns/locale';
import i18n from 'i18next';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import {
    SUPPORTED_LANGUAGES,
    DEFAULT_LANGUAGE,
    DATE_FORMATS,
} from 'common/constants/multiregion';

import { getSessionLocalStorage } from './session';

export const initProfileConfigurationActions = (updateConfiguration) => {
    const settings = getUserSettings();
    if (!isEmpty(settings)) {
        if (i18n.isInitialized) i18n.changeLanguage(settings?.language?.key);
        if (
            settings.auto_timezone &&
            settings.timezone.key !== getIanaTimezoneKey()
        )
            updateConfiguration({
                language: settings?.language?.key,
                timezone: getIanaTimezoneKey(),
                auto_timezone: settings.auto_timezone,
            });
    }
};

export const getUserSettings = () => {
    const session = getSessionLocalStorage();
    return get(session, 'settings', {});
};

export const getDateFormatByLocale = () =>
    DATE_FORMATS[getIsoCodeCurrentLocale()];

export const getIanaTimezoneKey = () =>
    Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getIsoCodeDetectedLanguage = () =>
    Intl.DateTimeFormat()
        .resolvedOptions()
        .locale.toLowerCase()
        .split('-')[0] || 'en';

export const getRuntimeLanguage = () =>
    SUPPORTED_LANGUAGES[getIsoCodeDetectedLanguage()] || DEFAULT_LANGUAGE;

export const getIsoCodeCurrentLocale = () => {
    const language = getRuntimeLanguage();
    return language.split('-')[0];
};

export const getLocaleEquivalenceForDateFns = () => {
    const locales = { en: enUS, es: es, pt: ptBR };
    return locales[getIsoCodeCurrentLocale()];
};
