import API from '..';
const ENTITY = 'notifications';

export const createNotificacion = (data) =>
    API.post(`/api/v1/${ENTITY}/`, { ...data });
export const deleteNotification = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const filterNotifications = ({
    page = 1,
    perPage,
    type = null,
    viewed = false,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/listing/`, {
        params: {
            page,
            per_page: perPage,
            type,
            viewed,
        },
    });
export const getNotificacion = (id) => API.get(`/api/v1/${ENTITY}/${id}`);
export const getNotificacions = (page = 1, perPage = 20) =>
    API.get(`/api/v1/${ENTITY}/?page=${page}&per_page=${perPage}`);
export const markAllAsRead = () => API.delete(`/api/v1/${ENTITY}/`);
export const updateNotification = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
