import i18next from 'i18next';

import { deleteItem } from 'common/api/documentCatalog';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_COMPANIES_CATALOGS,
    DELETE_COMPANIES_CATALOGS_FAILURE,
    DELETE_COMPANIES_CATALOGS_SUCCESS,
} from '../actionTypes';
import { configurationDocumentsActions } from '../reducer';

import fetchCatalogs from './fetchCatalogs';

export default (id, type) => (dispatch) => {
    dispatch(configurationDocumentsActions[DELETE_COMPANIES_CATALOGS]());

    deleteItem(id)
        .then(() => {
            dispatch(
                configurationDocumentsActions[
                    DELETE_COMPANIES_CATALOGS_SUCCESS
                ]()
            );
            dispatch(alerts.actions.close());
            dispatch(fetchCatalogs(type));

            showToast({ body: i18next.t('It was successfully removed') });
        })
        .catch((error) => {
            dispatch(
                configurationDocumentsActions[
                    DELETE_COMPANIES_CATALOGS_FAILURE
                ](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
