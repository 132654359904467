import i18next from 'i18next';

import { exportProposalCSV } from 'common/api/proposals';
import showToast from 'common/utils/showToast';

import {
    DOWNLOAD_PROPOSAL_CSV,
    DOWNLOAD_PROPOSAL_CSV_FAILURE,
    DOWNLOAD_PROPOSAL_CSV_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

import downloadFile from './downloadFile';

export default (id, name = i18next.t('File'), loadingContext) =>
    (dispatch) => {
        dispatch(interactiveProposalPreviewActions[DOWNLOAD_PROPOSAL_CSV]());
        loadingContext.openLoading(i18next.t('Downloading'));
        exportProposalCSV(id)
            .then((response) => {
                dispatch(
                    interactiveProposalPreviewActions[
                        DOWNLOAD_PROPOSAL_CSV_SUCCESS
                    ](response.data)
                );
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                loadingContext.closeLoading();
                dispatch(downloadFile(name, url, 'csv'));
                showToast({
                    body: i18next.t('It has been downloaded successfully'),
                });
            })
            .catch((error) => {
                loadingContext.closeLoading();
                dispatch(
                    interactiveProposalPreviewActions[
                        DOWNLOAD_PROPOSAL_CSV_FAILURE
                    ](error)
                );
            });
    };
