import React from 'react';

import ReorderIcon from '@mui/icons-material/Reorder';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import {
    Box,
    Checkbox,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from 'sunwise-ui';

const SorteableItem = ({ columnKeys, handleOnSelectItem, index, item }) => (
    <Draggable draggableId={item.name} id={item.name} index={index}>
        {(provided) => (
            <Box
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                <ListItem
                    disablePadding
                    secondaryAction={
                        <Checkbox
                            checked={item.is_active}
                            disabled={item.required}
                            onChange={(e) =>
                                handleOnSelectItem(item.name, e.target.checked)
                            }
                        />
                    }
                >
                    <ListItemButton>
                        <ListItemIcon>
                            <ReorderIcon />
                        </ListItemIcon>
                        <ListItemText primary={columnKeys[item.name]} />
                    </ListItemButton>
                </ListItem>
                <Divider />
            </Box>
        )}
    </Draggable>
);

SorteableItem.propTypes = {
    columnKeys: PropTypes.object,
    handleOnSelectItem: PropTypes.func,
    index: PropTypes.number,
    item: PropTypes.object,
};

export default SorteableItem;
