import is from 'is_js';

import { save, update } from 'common/api/documentCatalog';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { configurationDocumentsActions } from '../reducer';

import fetchCatalogs from './fetchCatalogs';
import resetForm from './resetForm';
import toggleModal from './toggleModal';

export default (values, type) => (dispatch) => {
    const newValues = {
        category: type?.category,
        description: values.description,
        id: values.id,
        name: values.name,
        required: values.required,
        type: type?.type,
    };

    const action = values.id ? update : save;

    if (is.array(values.file)) newValues.file = values.file && values.file[0];

    dispatch(configurationDocumentsActions[SAVE_ITEM]());

    action(newValues)
        .then((response) => {
            dispatch(
                configurationDocumentsActions[SAVE_ITEM_SUCCESS](response.data)
            );

            dispatch(fetchCatalogs(type));
            dispatch(toggleModal(false));
            dispatch(resetForm());

            showToast();
        })
        .catch((error) => {
            dispatch(
                configurationDocumentsActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
