import API from '..';
const ENTITY = 'financiers/financier-alliance-installer';

export const deleteItem = () => {};
export const fetchListCatalogs = () =>
    API.get(`/api/v1/${ENTITY}/catalog/documents/`);
export const save = (data) =>
    API.post(`/api/v1/${ENTITY}/catalog/documents/`, { ...data });
export const update = (data) =>
    API.put(`/api/v1/${ENTITY}/catalog/documents/${data.id}`, { ...data });
export const updateOrderItems = () => {};
export const updateRequiredDocuments = (installerId, data) =>
    API.put(`/api/v1/${ENTITY}/alliance/documents/${installerId}`, { ...data });

export const getPrivateDocumentsList = (installerId) =>
    API.get(`/api/v1/${ENTITY}/documents/private/${installerId}`);
export const uploadPrivateDocument = (data) =>
    API.post(`/api/v1/${ENTITY}/documents/private/`, { ...data });
