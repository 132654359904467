import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import SortableItem from './SortableItem';

const StyledList = styled('ul')`
    list-style-type: none;
    margin: 0 0;
    padding: 0 0;
`;

const SortableList = ({
    canUpdate = true,
    handleClickDelete,
    handleClickDuplicate,
    handleClickSelect,
    id,
    items,
    onDragEnd,
    refDictionary,
    scrollDisabled,
    selectedPageId,
}) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={id}>
                {(provided) => (
                    <StyledList
                        className="sortable"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {items.map((item, index) => (
                            <SortableItem
                                canUpdate={canUpdate}
                                handleClickDelete={handleClickDelete}
                                handleClickDuplicate={handleClickDuplicate}
                                handleClickSelect={handleClickSelect}
                                index={index}
                                item={item}
                                key={`sortable-item-${item.id}`}
                                refDictionary={refDictionary}
                                scrollDisabled={scrollDisabled}
                                selectedPageId={selectedPageId}
                            />
                        ))}

                        {provided.placeholder}
                    </StyledList>
                )}
            </Droppable>
        </DragDropContext>
    );
};

SortableList.propTypes = {
    canUpdate: PropTypes.bool,
    handleClickDelete: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickSelect: PropTypes.func,
    id: PropTypes.string,
    items: PropTypes.array,
    onDragEnd: PropTypes.func,
    refDictionary: PropTypes.object,
    scrollDisabled: PropTypes.bool,
    selectedPageId: PropTypes.string,
};

export default SortableList;
