import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteDocumentFile from './deleteDocumentFile';

export default (document) => (dispatch) => {
    const { name } = document;
    const dataAlert = {
        messages: [
            i18next.t('Are you sure you want to delete document {{name}}?', {
                name,
            }),
        ],
        onSuccess: () => dispatch(deleteDocumentFile(document)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
