import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { GridRowModes } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from 'sunwise-ui';

const ProductActionsFormatter = ({
    row,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const isInEditMode = rowModesModel[row?.id]?.mode === GridRowModes.Edit;

    const handleClickSave = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
        setSelectedRowId(null);
    };

    const handleClickCancel = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        setSelectedRowId(null);
    };

    if (!isInEditMode) return null;

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '4px',
                justifyContent: 'end',
                width: '100%',
            }}
        >
            <Tooltip title="Guardar cambios">
                <IconButton onClick={handleClickSave(selectedRowId)}>
                    <SaveIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title="Cancelar">
                <IconButton onClick={handleClickCancel(selectedRowId)}>
                    <CancelIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

ProductActionsFormatter.propTypes = {
    row: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.string,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ProductActionsFormatter;
