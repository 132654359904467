import i18next from 'i18next';
import { types } from 'sunwise-template-core';

import { deleteSmartDocument } from 'common/api/smartDocuments';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_SMART_DOCUMENT,
    DELETE_SMART_DOCUMENT_FAILURE,
    DELETE_SMART_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { templateDocumentsActions } from '../reducer';
import * as selectors from '../selectors';

import fetchDocuments from './fetchDocuments';

export default (
        handleClickDocumentItem,
        handleClickProposalItem,
        proposalDocumentDefault,
        proposalId,
        smartDocumentId
    ) =>
    (dispatch, getState) => {
        const state = getState();
        const smartDocuments = selectors.getSmartDocumentsData(state);
        const filteredSmartDocuments = smartDocuments.filter(
            (item) => item.id !== smartDocumentId
        );
        dispatch(templateDocumentsActions[DELETE_SMART_DOCUMENT]());

        deleteSmartDocument(smartDocumentId)
            .then(() => {
                dispatch(
                    templateDocumentsActions[DELETE_SMART_DOCUMENT_SUCCESS](
                        smartDocumentId
                    )
                );
                dispatch(fetchDocuments(proposalId));
                if (filteredSmartDocuments.length > 0)
                    handleClickDocumentItem({
                        ...filteredSmartDocuments[0],
                        document_reference_id: filteredSmartDocuments[0].id,
                        type: types.SMART_DOCUMENTS_TYPE,
                    });
                else {
                    handleClickProposalItem(proposalDocumentDefault);
                }
                dispatch(alerts.actions.close());
                showToast({ body: i18next.t('It was successfully removed') });
            })
            .catch((error) => {
                dispatch(
                    templateDocumentsActions[DELETE_SMART_DOCUMENT_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
