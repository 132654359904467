import React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { PageThumbnail } from 'sunwise-template-core';

const BORDER_WIDTH = 4;
const THUMBNAIL_HEIGHT = 101;
const THUMBNAIL_WIDTH = 71;

const Container = styled('div')`
    border-bottom: 1px solid #012b43;
    display: flex;
    padding: 5px;
    position: relative;
`;

const PageWrapper = styled('div')`
    align-items: center;
    background-color: #fff;
    border-radius: 2px;
    border: ${BORDER_WIDTH}px solid transparent;
    cursor: pointer;
    display: flex;
    height: ${({ orientation }) =>
        orientation === 'portrait' ? THUMBNAIL_HEIGHT : THUMBNAIL_WIDTH}px;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    width: ${({ orientation }) =>
        orientation === 'portrait' ? THUMBNAIL_WIDTH : THUMBNAIL_HEIGHT}px;
    z-index: 1;

    &.selected {
        border: ${BORDER_WIDTH}px solid #008dff;
    }
`;

const Dots = styled('span')`
    font-size: 50px;
    pointer-events: none;
`;

const StyledPageThumbnail = styled(PageThumbnail)`
    && {
        height: ${({ page }) =>
            (page.orientation === 'portrait'
                ? THUMBNAIL_HEIGHT
                : THUMBNAIL_WIDTH) -
            BORDER_WIDTH * 2}px;
        min-height: initial;
        min-width: initial;
        width: ${({ page }) =>
            (page.orientation === 'portrait'
                ? THUMBNAIL_WIDTH
                : THUMBNAIL_HEIGHT) -
            BORDER_WIDTH * 2}px;

        & > div {
            transform: scale(
                ${({ page }) =>
                    page.orientation === 'portrait'
                        ? (THUMBNAIL_WIDTH - BORDER_WIDTH * 2) / 778.5
                        : (THUMBNAIL_HEIGHT - BORDER_WIDTH * 2) / 1123.26},
                ${({ page }) =>
                    page.orientation === 'portrait'
                        ? (THUMBNAIL_HEIGHT - BORDER_WIDTH * 2) / 1123.26
                        : (THUMBNAIL_WIDTH - BORDER_WIDTH * 2) / 778.5}
            );
        }
    }
`;

const ItemPreviewPage = ({ handleClickSelect, page, selected }) => (
    <Container orientation={page.orientation}>
        <PageWrapper
            className={selected && 'selected'}
            onClick={handleClickSelect}
            orientation={page.orientation}
        >
            {selected && <Dots>&#183;&#183;&#183;</Dots>}
            {!selected && <StyledPageThumbnail page={page} />}
            {page.blocked === 1 && <LockIcon />}
        </PageWrapper>
    </Container>
);

ItemPreviewPage.propTypes = {
    handleClickSelect: PropTypes.func,
    page: PropTypes.object,
    selected: PropTypes.bool,
};

export default ItemPreviewPage;
