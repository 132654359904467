import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import {
    InputSearch,
    BulkEditionMenu,
    StatusFilterSelect,
} from 'common/components';

const BulkOperationsCard = ({
    canDelete,
    filterData,
    handleClickBulkItems,
    handleOnChangeSearch,
    handleOnSelectStatus,
    prefix,
    selectedBulkOption,
    selectionModel,
    setSelectedBulkOption,
    setSelectionModel,
}) => {
    const { t } = useTranslation();
    const isArchivedList = filterData?.status === 'archived';
    const callback = () => {
        setSelectedBulkOption('');
        setSelectionModel([]);
    };

    return (
        <Card>
            <Card.Body>
                <Grid container alignItems="end" justifyContent="flex-end">
                    <Grid
                        item
                        xs={18}
                        md={8}
                        lg={isEmpty(selectionModel) ? 15 : 11}
                    >
                        <InputSearch
                            onChange={handleOnChangeSearch}
                            placeholder={t('Search')}
                            value={filterData?.searchText}
                        />
                    </Grid>

                    <Grid item xs={18} md={4} lg={3}>
                        <StatusFilterSelect
                            filterBy={filterData?.status}
                            handleSelected={handleOnSelectStatus}
                            variant="standard"
                        />
                    </Grid>

                    <Grid
                        hidden={isEmpty(selectionModel) || !canDelete}
                        item
                        lg={4}
                        md={6}
                        xs={18}
                    >
                        <BulkEditionMenu
                            items={[
                                {
                                    handleClick: () =>
                                        handleClickBulkItems(
                                            selectionModel,
                                            'archived',
                                            filterData,
                                            callback
                                        ),
                                    label: t('Archive'),
                                    visible: !isArchivedList,
                                },
                                {
                                    handleClick: () =>
                                        handleClickBulkItems(
                                            selectionModel,
                                            'active',
                                            filterData,
                                            callback
                                        ),
                                    label: t('Unarchive'),
                                    visible: isArchivedList,
                                },
                                {
                                    handleClick: () =>
                                        handleClickBulkItems(
                                            selectionModel,
                                            'deleted',
                                            filterData,
                                            callback
                                        ),
                                    label: t('Delete'),
                                    visible: isArchivedList,
                                },
                            ]}
                            prefix={prefix}
                            selectedBulkOption={selectedBulkOption}
                            setSelectionModel={setSelectionModel}
                        />
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    canDelete: PropTypes.bool,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    handleOnSelectStatus: PropTypes.func,
    prefix: PropTypes.string,
    selectedBulkOption: PropTypes.string,
    selectionModel: PropTypes.array,
    setSelectedBulkOption: PropTypes.func,
    setSelectionModel: PropTypes.func,
};

export default BulkOperationsCard;
