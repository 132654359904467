import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, DataGrid } from 'sunwise-ui';

import CustomPagination from 'common/components/CustomPagination';

const DEBOUNCE_TIME = 1500;

const ProductListItems = ({
    alliancesFilterData,
    bulkAssignProducts,
    filterData,
    filterItems,
    initialValues,
    isFetching,
    items,
    paginationData,
    setFilterData,
}) => {
    const { t } = useTranslation();
    const { handleSubmit, reset } = useForm({
        defaultValues: initialValues,
    });
    const [rowCount, setRowCount] = useState(0);
    const [selectionModel, setSelectionModel] = useState([]);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => reset(initialValues), [initialValues]);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    useEffect(() => {
        if (!isEmpty(items)) {
            const selected = items
                .filter(({ added }) => added)
                .map((item) => item.id);
            setSelectionModel(selected);
        }
    }, [items]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    const handleClickAssignProducts = (values) =>
        bulkAssignProducts(alliancesFilterData, {
            ...values,
            products: selectionModel,
        });

    return (
        <Box component="form">
            <DataGrid
                autoHeight
                checkboxSelection={true}
                columns={[
                    {
                        align: 'left',
                        field: 'name',
                        flex: 1,
                        headerName: t('Product'),
                        sortable: false,
                    },
                ]}
                components={{
                    LoadingOverlay: LinearProgress,
                    Pagination: CustomPagination,
                }}
                componentsProps={{
                    pagination: {
                        rowsPerPageOptions: [25, 50, 75, 100],
                    },
                }}
                disableColumnMenu
                disableSelectionOnClick
                editMode="row"
                experimentalFeatures={{ newEditingApi: true }}
                getRowHeight={() => 'auto'}
                initialState={{
                    pagination: {
                        pageSize: filterData?.pageSize,
                    },
                }}
                loading={isFetching}
                onPageChange={handleOnPageChange}
                onPageSizeChange={(newPage) =>
                    setFilterData({ ...filterData, pageSize: newPage })
                }
                onSortModelChange={handleOnSortModelChange}
                onSelectionModelChange={(newModel) =>
                    setSelectionModel(newModel)
                }
                page={paginationData?.current_page - 1 || 0}
                pageSize={filterData.pageSize}
                pagination
                paginationMode="server"
                rowCount={rowCount}
                rows={items}
                selectionModel={selectionModel}
                sortingMode="server"
            />

            <Box mt={2} textAlign="right">
                <Button
                    disabled={isEmpty(selectionModel)}
                    color="secondary"
                    onClick={handleSubmit(handleClickAssignProducts)}
                >
                    {t('Save')}
                </Button>
            </Box>
        </Box>
    );
};

ProductListItems.propTypes = {
    alliancesFilterData: PropTypes.object,
    bulkAssignProducts: PropTypes.func,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default ProductListItems;
