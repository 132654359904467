import { getItems } from 'common/api/users';

import {
    FETCH_ITEMS_LIST,
    FETCH_ITEMS_LIST_FAILURE,
    FETCH_ITEMS_LIST_SUCCESS,
} from '../actionTypes';
import { companyUsersActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(companyUsersActions[FETCH_ITEMS_LIST]());

    getItems()
        .then((response) => {
            dispatch(
                companyUsersActions[FETCH_ITEMS_LIST_SUCCESS](
                    response.data.data
                )
            );
        })
        .catch((error) =>
            dispatch(
                companyUsersActions[FETCH_ITEMS_LIST_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
