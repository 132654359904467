import { updateInstallerUsers } from 'common/api/installers';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPDATE_INSTALLER_USERS,
    UPDATE_INSTALLER_USERS_FAILURE,
    UPDATE_INSTALLER_USERS_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (installerId, callback) => (dispatch) => {
    dispatch(installerProfileActions[UPDATE_INSTALLER_USERS]());

    updateInstallerUsers(installerId)
        .then(() => {
            dispatch(installerProfileActions[UPDATE_INSTALLER_USERS_SUCCESS]());
            showToast({ body: 'Se actualizó correctamente.' });
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                installerProfileActions[UPDATE_INSTALLER_USERS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
