import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import {
    AutocompleteField,
    AutocompleteMultipleField,
    BulkEditionMenu,
    InputSearch,
    ManageCsvDropdown,
} from 'common/components';

import {
    getSizeLgStatus,
    getAlliancesOptions,
    getUsersOptions2,
} from '../helpers';

const BulkOperationsCard = ({
    alliances,
    canModify,
    downloadFile,
    filterData,
    handleClickAlliance,
    handleClickChangeStatus,
    handleClickReassignAdvisor,
    handleClickStatus,
    handleClickUser,
    handleOnChangeSearch,
    isFetchingAlliances,
    prefix,
    selectedBulkOption,
    selectionModel,
    setSelectedBulkOption,
    setSelectionModel,
    statusList,
    users,
}) => {
    const { t } = useTranslation();
    const callback = () => {
        setSelectedBulkOption('');
        setSelectionModel([]);
    };

    return (
        <Card>
            <Card.Body>
                <Grid container alignItems="end" justifyContent="flex-end">
                    <Grid item xs={16} md={17} lg={17}>
                        <InputSearch
                            onChange={handleOnChangeSearch}
                            placeholder={t(
                                'Search by client, project, financial product'
                            )}
                            value={filterData?.searchText}
                        />
                    </Grid>

                    <Grid item xs={2} md={1} lg={1} sx={{ textAlign: 'right' }}>
                        <ManageCsvDropdown
                            handleDownload={() => downloadFile(filterData)}
                        />
                    </Grid>

                    <Grid item xs={18} md={9} lg={3}>
                        <AutocompleteField
                            label={
                                isEmpty(filterData?.financialAdvisor)
                                    ? t('All executives')
                                    : t('Executive')
                            }
                            onChange={handleClickUser}
                            options={getUsersOptions2(users)}
                            value={filterData?.financialAdvisor}
                        />
                    </Grid>

                    <Grid item xs={18} md={9} lg={3}>
                        <AutocompleteField
                            disabled={isFetchingAlliances}
                            label={
                                isEmpty(filterData?.allianceId)
                                    ? t('All the companies')
                                    : t('Company')
                            }
                            onChange={handleClickAlliance}
                            options={getAlliancesOptions(alliances)}
                            value={filterData?.allianceId}
                        />
                    </Grid>

                    <Grid item xs={18} lg={getSizeLgStatus(selectionModel)}>
                        <AutocompleteMultipleField
                            label={
                                filterData?.status.length === 0
                                    ? t('All statuses')
                                    : t('Status')
                            }
                            onChange={handleClickStatus}
                            options={statusList}
                            value={filterData?.status}
                        />
                    </Grid>

                    <Grid
                        hidden={isEmpty(selectionModel) || !canModify}
                        item
                        lg={4}
                        sx={{ order: 6 }}
                        xs={18}
                    >
                        <BulkEditionMenu
                            items={[
                                {
                                    label: t('Reassign executive'),
                                    handleClick: () =>
                                        handleClickReassignAdvisor(
                                            selectionModel,
                                            callback
                                        ),
                                    visible: true,
                                },
                                {
                                    label: t('Change status'),
                                    handleClick: () =>
                                        handleClickChangeStatus(
                                            selectionModel,
                                            callback
                                        ),
                                    visible: true,
                                },
                            ]}
                            prefix={prefix}
                            selectedBulkOption={selectedBulkOption}
                            setSelectionModel={setSelectionModel}
                        />
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    alliances: PropTypes.any,
    canModify: PropTypes.bool,
    downloadFile: PropTypes.func,
    filterData: PropTypes.object,
    handleClickAlliance: PropTypes.func,
    handleClickChangeStatus: PropTypes.func,
    handleClickReassignAdvisor: PropTypes.func,
    handleClickStatus: PropTypes.func,
    handleClickUser: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    isFetchingAlliances: PropTypes.bool,
    prefix: PropTypes.string,
    selectedBulkOption: PropTypes.string,
    selectionModel: PropTypes.array,
    setSelectedBulkOption: PropTypes.func,
    setSelectionModel: PropTypes.func,
    statusList: PropTypes.array,
    users: PropTypes.array,
};

export default BulkOperationsCard;
