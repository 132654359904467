import React, { useMemo, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Box, ToggleButton, ToggleButtonGroup } from 'sunwise-ui';

import { parseDate, formatDate } from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';

const Charts = ({ items }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [chartId, setChart] = useState('chartEnergy');

    const categories = useMemo(
        () =>
            items.map((item) =>
                formatDate(parseDate(item.date_finish, 'yyyy-MM-dd'), 'MMM-yy')
            ),
        [items]
    );
    const dataKwh = useMemo(
        () => items.map((item) => item.consumtion || item.consumption),
        [items]
    );

    const series = [{ name: 'kWh', data: dataKwh }];

    const yaxis = {
        decimalsInFloat: 0,
        labels: {
            formatter: (val) =>
                numberFormat(val, { style: 'decimal', unit: 'kWh' }),
        },
    };

    const chartConfigEnergy = {
        options: {
            chart: { background: '#ffffff00', toolbar: { show: false } },
            colors: ['#0073d0', '#f2ae47'],
            dataLabels: { enabled: false },
            legend: { show: false },
            tooltip: { y: { formatter: (val) => val.toFixed(0) } },
            xaxis: { categories },
            yaxis: yaxis,
        },
        series: series,
    };

    const priceConsumitionGroups = items.reduce((acc, current) => {
        const parsedObject = JSON.parse(current.info_price_consumtion);
        let tempObject = { ...acc };
        Object.keys(parsedObject).forEach((key) => {
            if (!tempObject[key]) tempObject[key] = [];
            tempObject[key] = [...tempObject[key], parsedObject[key]];
        });
        return tempObject;
    }, {});

    let seriesInfoPriceConsumtion = Object.keys(priceConsumitionGroups).map(
        (key) => ({ data: priceConsumitionGroups[key], name: key })
    );

    const chartConfigEconomic = {
        options: {
            chart: {
                background: '#ffffff00',
                stacked: true,
                toolbar: { show: false },
            },
            colors: [
                '#02E675',
                '#FF9A00',
                '#2F4DFF',
                '#002438',
                '#785F9D',
                '#FA6968',
                '#0073d0',
                '#600980',
                '#803909',
            ],
            dataLabels: { enabled: false },
            legend: { show: true },
            tooltip: {
                x: {
                    formatter: (value, { dataPointIndex, w }) =>
                        value +
                        ' - <strong> Total:' +
                        numberFormat(
                            w.globals.stackedSeriesTotals[dataPointIndex],
                            {
                                style: 'decimal',
                            }
                        ) +
                        '</strong>',
                },
                y: {
                    formatter: (val) => numberFormat(val, { style: 'decimal' }),
                },
            },
            xaxis: { categories },
            yaxis: {
                decimalsInFloat: 0,
                labels: {
                    formatter: (val) =>
                        numberFormat(val, { style: 'currency' }),
                },
            },
        },
        series: seriesInfoPriceConsumtion,
    };

    return (
        <Box>
            <React.Fragment>
                <ToggleButtonGroup
                    color="primary"
                    exclusive
                    fullWidth
                    onChange={(e) => setChart(e.target.value)}
                    size="small"
                    sx={{ mb: 2 }}
                    value={chartId}
                >
                    <ToggleButton value="chartEnergy">
                        {t('Energy')}
                    </ToggleButton>
                    <ToggleButton value="chartEconomic">
                        {t('Economic')}
                    </ToggleButton>
                </ToggleButtonGroup>

                {chartId === 'chartEnergy' && (
                    <Chart
                        height="250"
                        options={{
                            ...chartConfigEnergy.options,
                            theme: { mode: theme.palette.mode },
                        }}
                        series={chartConfigEnergy.series}
                        type="bar"
                    />
                )}

                {chartId === 'chartEconomic' && (
                    <Chart
                        height="250"
                        options={{
                            ...chartConfigEconomic.options,
                            theme: { mode: theme.palette.mode },
                        }}
                        series={chartConfigEconomic.series}
                        type="bar"
                    />
                )}
            </React.Fragment>
        </Box>
    );
};

Charts.propTypes = { items: PropTypes.array };

export default Charts;
