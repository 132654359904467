import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from 'sunwise-ui';

const ReactHookFormSelect2 = ({
    blurOnSelect,
    control,
    disabled,
    isClearable,
    isLoading,
    label,
    name,
    onChange,
    options,
    required,
    variant,
}) => {
    const optionsWithGroup = [];
    options.forEach((group) => {
        group.options.forEach((option) =>
            optionsWithGroup.push({ ...option, group: group.label })
        );
    });
    const sortedOptions = optionsWithGroup.sort(
        (a, b) => -b.group?.localeCompare(a.group)
    );
    const onChangeAction = (_, data, field, onChange) => {
        field.onChange(data?.value ?? '');
        if (onChange) onChange(data?.value ?? '');
    };
    return (
        <Controller
            control={control}
            name={name}
            render={({ field, fieldState: { error } }) => (
                <Autocomplete
                    blurOnSelect={blurOnSelect}
                    disableClearable={!isClearable}
                    disabled={disabled}
                    getOptionDisabled={(option) =>
                        option?.disabled || option.value === ''
                    }
                    getOptionLabel={(option) => option?.label || ''}
                    groupBy={(option) => option.group}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value?.value
                    }
                    loading={isLoading}
                    name={field.name}
                    onChange={(event, data) =>
                        onChangeAction(event, data, field, onChange)
                    }
                    options={sortedOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={Boolean(error)}
                            helperText={error?.message}
                            label={label}
                            inputRef={field.ref}
                            variant={variant}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={`${option.value}-${option.label}`}>
                            {option.label}
                        </li>
                    )}
                    required={required}
                    value={
                        sortedOptions.find(
                            (opt) => opt.value === field.value
                        ) ?? null
                    }
                />
            )}
        />
    );
};

ReactHookFormSelect2.defaultProps = {
    blurOnSelect: true,
    isClearable: true,
    isLoading: false,
};

ReactHookFormSelect2.propTypes = {
    blurOnSelect: PropTypes.bool,
    control: PropTypes.object,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    ignore: PropTypes.array,
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    required: PropTypes.bool,
    variant: PropTypes.string,
};

export default ReactHookFormSelect2;
