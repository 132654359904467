import React from 'react';

import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { List } from 'sunwise-ui';

import SortableItem from './SortableItem';

const SortableList = ({
    columnKeys,
    columns,
    handleOnSelectItem,
    onDragEnd,
}) => (
    <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={`columns-items`}>
            {(provided) => (
                <List ref={provided.innerRef} {...provided.droppableProps}>
                    {columns.map((item, index) => (
                        <SortableItem
                            columnKeys={columnKeys}
                            handleOnSelectItem={handleOnSelectItem}
                            index={index}
                            item={item}
                            key={`item-${item.name}`}
                        />
                    ))}

                    {provided.placeholder}
                </List>
            )}
        </Droppable>
    </DragDropContext>
);

SortableList.propTypes = {
    columnKeys: PropTypes.object,
    columns: PropTypes.array,
    handleOnSelectItem: PropTypes.func,
    onDragEnd: PropTypes.func,
};

export default SortableList;
