import { ContentTypeJSON } from 'common/utils/api';

import API from '..';

const ENTITY = 'credit-applications';

export const bulkReassignAdvisors = (data) =>
    API.put(
        `/api/v1/${ENTITY}/credits/bulk-reassign-advisors/`,
        { ...data },
        ContentTypeJSON
    );

export const bulkCreditStatus = (data) =>
    API.put(
        `/api/v1/${ENTITY}/credits/bulk-change-status/`,
        { ...data },
        ContentTypeJSON
    );

export const updateRowEdition = (id, data) =>
    API.put(`/api/v1/${ENTITY}/credits/row-edition/${id}/`, { ...data });

export const getCreditApplications = () => API.get(`/api/v1/${ENTITY}/`);
export const getCreditApplication = (id) => API.get(`/api/v1/${ENTITY}/${id}`);
export const getApprovedCreditApplications = () =>
    API.get(`/api/v1/${ENTITY}/filter/Aprobado`);
export const updateCreditApplication = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
export const exportFile = ({
    allianceId,
    financialAdvisor,
    searchText = '',
    status,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/credits/export/`, {
        params: {
            ...(allianceId ? { allianceId } : {}),
            ...(financialAdvisor
                ? { financial_advisor: financialAdvisor }
                : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(status ? { status } : {}),
        },
    });
export const exportFinancialData = (proposalId) =>
    API.get(`/api/v1/${ENTITY}/credits/export-financial-data/`, {
        params: {
            commercial_offer: proposalId,
        },
    });

export const filterCredits = ({
    allianceId,
    financialAdvisor,
    orderBy = '',
    page = 1,
    perPage,
    searchText = '',
    sortBy = '',
    status,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/credits/`, {
        params: {
            ...(allianceId ? { allianceId } : {}),
            ...(financialAdvisor
                ? { financial_advisor: financialAdvisor }
                : {}),
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
            ...(status ? { status } : {}),
            page: page,
            per_page: perPage,
        },
    });

export const getFinancialData = (commercialOfferId) =>
    API.get(
        `/api/v1/${ENTITY}/commercial-offer/${commercialOfferId}/financial-data/`
    );
export const deleteRequiredDocuments = (documentId) =>
    API.delete(
        `/api/v1/${ENTITY}/credit-commercial-offer-product/documents/${documentId}`
    );
export const getRequiredDocuments = (creditAppId) =>
    API.get(
        `/api/v1/${ENTITY}/credit-commercial-offer-product/documents/${creditAppId}`
    );
export const saveRequiredDocument = (creditAppId, data) =>
    API.put(
        `/api/v1/${ENTITY}/credit-commercial-offer-product/documents/${creditAppId}`,
        {
            ...data,
        }
    );
export const saveAssignedUser = (creditAppId, data) =>
    API.patch(
        `/api/v1/${ENTITY}/financial-advisor/${creditAppId}/`,
        { ...data },
        ContentTypeJSON
    );
export const uploadRequiredDocument = (documentId, data) =>
    API.put(
        `/api/v1/${ENTITY}/credit-commercial-offer-product/document/${documentId}`,
        {
            ...data,
        }
    );
export const approveCreditApplicaton = (creditAppId) =>
    API.put(`/api/v1/${ENTITY}/${creditAppId}/approve`);
export const cancelCreditApplicaton = (creditAppId, data) =>
    API.put(`/api/v1/${ENTITY}/${creditAppId}`, { ...data });
export const preApproveCreditApplicaton = (creditAppId) =>
    API.put(`/api/v1/${ENTITY}/${creditAppId}/pre-approve`);
export const closeCreditApplicaton = (creditAppId) =>
    API.get(`/api/v1/${ENTITY}/${creditAppId}/close`);

export const changeStatusFile = (fileId, data) =>
    API.post(
        `/api/v1/${ENTITY}/credit-commercial-offer-product/document/${fileId}`,
        { ...data }
    );

export const getPrivateDocumentsList = (installerId) =>
    API.get(
        `/api/v1/${ENTITY}/credit-commercial-offer-product/documents/private/${installerId}`
    );
export const uploadPrivateDocument = (data) =>
    API.post(
        `/api/v1/${ENTITY}/credit-commercial-offer-product/documents/private/`,
        { ...data }
    );

export const downloadCreditApplicationDocuments = (id, type) =>
    API.get(`/api/v1/${ENTITY}/${id}/download-documents/?type=${type}`, {
        responseType: 'blob',
    });

export const addLocation = (data) =>
    API.post(`/api/v1/${ENTITY}/locations/`, { ...data });
export const deleteLocation = (id) =>
    API.delete(`/api/v1/${ENTITY}/locations/${id}/`);
export const updateLocation = (data, id) =>
    API.patch(
        `/api/v1/${ENTITY}/locations/${id}/`,
        { ...data },
        ContentTypeJSON
    );
