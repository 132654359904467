import { changeStatusFile } from 'common/api/financierAllianceInstaller';
import { APPROVED_STATUS } from 'common/constants';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    CHANGE_STATUS_FILE,
    CHANGE_STATUS_FILE_FAILURE,
    CHANGE_STATUS_FILE_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (file, status = APPROVED_STATUS, callback) =>
    (dispatch) => {
        const newValues = { status };

        dispatch(installerProfileActions[CHANGE_STATUS_FILE]());

        changeStatusFile(file.id, newValues)
            .then(() => {
                dispatch(installerProfileActions[CHANGE_STATUS_FILE_SUCCESS]());
                if (callback) callback();
                dispatch(alerts.actions.close());
            })
            .catch((error) => {
                dispatch(
                    installerProfileActions[CHANGE_STATUS_FILE_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
