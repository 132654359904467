import i18next from 'i18next';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getContactId = createSelector(
    getModel,
    (model) => model.contactId
);

export const getUploadFile = createSelector(
    getModel,
    (model) => model.uploadFile
);

export const getIsUploading = createSelector(
    getUploadFile,
    (uploadFile) => uploadFile.isLoading
);

export const getErrors = createSelector(
    getUploadFile,
    (uploadFile) => uploadFile.errors
);

export const getIsVisibleModal = createSelector(
    getModel,
    (model) => model.isVisibleModal
);

export const getFetchDocuments = createSelector(
    getModel,
    (model) => model.fetchDocuments
);

export const getIsLoadingFetchDocuments = createSelector(
    getFetchDocuments,
    (fetchDocuments) => fetchDocuments.isLoading
);

export const getDataDocuments = createSelector(
    getFetchDocuments,
    (fetchDocuments) => fetchDocuments.data
);

export const getElementToDelete = createSelector(
    getModel,
    (model) => model.elementToDelete
);

export const getIsOpenConfirmModal = createSelector(
    getModel,
    (model) => model.isOpenConfirmModal
);

export const getDataConfirmModal = createSelector(
    getModel,
    (model) => model.dataConfirmModal || {}
);

export const getDocumentsType = createSelector(
    getModel,
    (model) => model.documentsType
);

export const getDocumentsTypeToSelect = createSelector(
    getDocumentsType,
    (documentsType) => getContactDocumentTypeToDropdown(documentsType)
);

const getContactDocumentTypeToDropdown = (documentsType) => {
    let list = documentsType.data
        ? documentsType.data.map((documentType) => ({
              label: documentType.name,
              value: documentType.id,
          }))
        : [];

    list.unshift({
        label: i18next.t('Select a document type'),
        value: '',
    });

    return list;
};
