import { getPanels } from 'common/api/productsSunwise';

import {
    FETCH_PANELS,
    FETCH_PANELS_FAILURE,
    FETCH_PANELS_SUCCESS,
} from '../actionTypes';
import { financialActions } from '../reducer';

export default (filterData) => (dispatch) => {
    dispatch(financialActions[FETCH_PANELS]());

    const { brandName, financierProductId, page, pageSize, searchText } =
        filterData;

    getPanels({
        brandName,
        financierProductId,
        page: page + 1,
        pageSize,
        searchStr: searchText,
    })
        .then((response) =>
            dispatch(financialActions[FETCH_PANELS_SUCCESS](response.data))
        )
        .catch((error) =>
            dispatch(
                financialActions[FETCH_PANELS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
