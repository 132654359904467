import { createSlice } from '@reduxjs/toolkit';

import {
    APPROVE_ALLIANCE,
    APPROVE_ALLIANCE_FAILURE,
    APPROVE_ALLIANCE_SUCCESS,
    CHANGE_PRODUCT_STATUS,
    CHANGE_PRODUCT_STATUS_FAILURE,
    CHANGE_PRODUCT_STATUS_SUCCESS,
    CHANGE_STATUS_ALLIANCE,
    CHANGE_STATUS_ALLIANCE_FAILURE,
    CHANGE_STATUS_ALLIANCE_SUCCESS,
    CHANGE_STATUS_FILE,
    CHANGE_STATUS_FILE_FAILURE,
    CHANGE_STATUS_FILE_SUCCESS,
    CLOSE_ALLIANCE,
    CLOSE_ALLIANCE_FAILURE,
    CLOSE_ALLIANCE_SUCCESS,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_FAILURE,
    DELETE_DOCUMENT_SUCCESS,
    FETCH_ASSIGNED_USER,
    FETCH_ASSIGNED_USER_FAILURE,
    FETCH_ASSIGNED_USER_SUCCESS,
    FETCH_COMPANY_USERS,
    FETCH_COMPANY_USERS_SUCCESS,
    FETCH_CONPANY_USERS_FAILURE,
    FETCH_CONTRACT_DOCUMENTS,
    FETCH_CONTRACT_DOCUMENTS_FAILURE,
    FETCH_CONTRACT_DOCUMENTS_SUCCESS,
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_FAILURE,
    FETCH_DOCUMENTS_SUCCESS,
    FETCH_FINANCIER_INSTALLER,
    FETCH_FINANCIER_INSTALLER_FAILURE,
    FETCH_FINANCIER_INSTALLER_SUCCESS,
    FETCH_INSTALLER,
    FETCH_INSTALLER_FAILURE,
    FETCH_INSTALLER_SUCCESS,
    FETCH_PRODUCTS_LIST,
    FETCH_PRODUCTS_LIST_FAILURE,
    FETCH_PRODUCTS_LIST_SUCCESS,
    FETCH_PROJECTS_SALES_STAGE,
    FETCH_PROJECTS_SALES_STAGE_FAILURE,
    FETCH_PROJECTS_SALES_STAGE_SUCCESS,
    FETCH_PROPOSALS_TOTALS,
    FETCH_PROPOSALS_TOTALS_FAILURE,
    FETCH_PROPOSALS_TOTALS_SUCCESS,
    FETCH_REQUEST_FORM_DOCUMENTS,
    FETCH_REQUEST_FORM_DOCUMENTS_FAILURE,
    FETCH_REQUEST_FORM_DOCUMENTS_SUCCESS,
    FETCH_REQUIRED_DOCUMENTS_LIST,
    FETCH_REQUIRED_DOCUMENTS_LIST_FAILURE,
    FETCH_REQUIRED_DOCUMENTS_LIST_SUCCESS,
    FETCH_SELECTED_PRODUCTS,
    FETCH_SELECTED_PRODUCTS_FAILURE,
    FETCH_SELECTED_PRODUCTS_SUCCESS,
    INITIALIZE_FORM,
    INITIALIZE_FORM_INSTALLER_DATA,
    INITIALIZE_PRODUCTS_FORM,
    REJECT_ALLIANCE,
    REJECT_ALLIANCE_FAILURE,
    REJECT_ALLIANCE_SUCCESS,
    REJECT_FILE,
    REJECT_FILE_FAILURE,
    REJECT_FILE_SUCCESS,
    RESET,
    RESET_ASSIGN_USER_FORM,
    RESET_PRODUCTS_FORM,
    RESET_UPLOAD_FORM,
    SAVE_ASSIGNED_USER,
    SAVE_ASSIGNED_USER_FAILURE,
    SAVE_ASSIGNED_USER_SUCCESS,
    SAVE_DOCUMENT,
    SAVE_DOCUMENT_FAILURE,
    SAVE_DOCUMENT_SUCCESS,
    SAVE_SELECTED_PRODUCTS,
    SAVE_SELECTED_PRODUCTS_FAILURE,
    SAVE_SELECTED_PRODUCTS_SUCCESS,
    SET_IS_DOWNLOADING,
    UPDATE_INSTALLER_DATA,
    UPDATE_INSTALLER_DATA_FAILURE,
    UPDATE_INSTALLER_DATA_SUCCESS,
    UPDATE_INSTALLER_USERS,
    UPDATE_INSTALLER_USERS_FAILURE,
    UPDATE_INSTALLER_USERS_SUCCESS,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    approveAlliance: { errors: [], isFetching: false },
    assignedUser: { errors: [], isFetching: false },
    changeProductStatus: { errors: [], isFetching: false },
    changeStatusAlliance: { errors: [], isFetching: false },
    changeStatusFile: { errors: [], isFetching: false },
    closeAlliance: { errors: [], isFetching: false },
    companyUsers: { data: [], errors: [], isFetching: true },
    contractDocuments: { data: [], errors: [], isFetching: true },
    documents: { data: [], errors: [], isFetching: true },
    fetchInstaller: { data: {}, errors: [], isFetching: true },
    fileUploaded: { data: {}, errors: [], isUploading: false },
    financierInstaller: { data: {}, errors: [], isFetching: true },
    initialValues: { agent: null, finalDate: null, initialDate: null },
    initialValuesAssignUserForm: { user: null },
    initialValuesInstallerDataForm: { id: null },
    initialValuesProductForm: { financiers_products: {} },
    initialValuesUploadForm: { file: null, name: '' },
    deleteDocument: { errors: [], isDeleting: false },
    isDownloading: false,
    productsList: { data: [], errors: [], isLoading: true },
    projectsSalesStage: { data: [], errors: [], isLoading: false },
    proposalsTotals: {
        data: {
            categories: [],
            cotizadas: {
                propuestas_dinero: 0,
                propuestas_kw: 0,
                total_propuestas: 0,
            },
            currency: '',
            emitidas: {
                propuestas_dinero: 0,
                propuestas_kw: 0,
                total_propuestas: 0,
            },
            fecha_fin: null,
            fecha_inicio: null,
            series: {},
            solicitadas: {
                propuestas_dinero: 0,
                propuestas_kw: 0,
                total_propuestas: 0,
            },
        },
        errors: [],
        isEmpty: false,
        isLoading: false,
    },
    rejectAlliance: { data: {}, errors: [], isFetching: false },
    rejectFile: { errors: [], isFetching: false },
    requestFormDocuments: { data: [], errors: [], isFetching: true },
    requiredDocumentsList: { data: [], errors: [], isFetching: true },
    saveAssignedUser: { errors: [], isFetching: false },
    saveContract: { errors: [], isFetching: false },
    saveDocument: { errors: [], isSaving: false },
    saveSelectedProducts: { errors: [], isFetching: false },
    selectedProducts: { data: [], errors: [], isFetching: true },
    updateInstaller: { errors: [], isSaving: false },
    updateInstallerUsers: { errors: [], isSaving: false },
};

const installerProfileSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [APPROVE_ALLIANCE]: (state) => {
            state.approveAlliance.isFetching = true;
        },
        [APPROVE_ALLIANCE_FAILURE]: (state, action) => {
            state.approveAlliance = {
                ...state.approveAlliance,
                errors: action.payload,
                isFetching: false,
            };
        },
        [APPROVE_ALLIANCE_SUCCESS]: (state) => {
            state.approveAlliance.isFetching = false;
        },
        [CHANGE_PRODUCT_STATUS]: (state) => {
            state.changeProductStatus.isFetching = true;
        },
        [CHANGE_PRODUCT_STATUS_FAILURE]: (state, action) => {
            state.changeProductStatus = {
                ...state.changeProductStatus,
                errors: action.payload,
                isFetching: false,
            };
        },
        [CHANGE_PRODUCT_STATUS_SUCCESS]: (state) => {
            state.changeProductStatus.isFetching = false;
        },
        [CHANGE_STATUS_ALLIANCE]: (state) => {
            state.changeStatusAlliance.isFetching = true;
        },
        [CHANGE_STATUS_ALLIANCE_FAILURE]: (state, action) => {
            state.changeStatusAlliance = {
                ...state.changeStatusAlliance,
                errors: action.payload,
                isFetching: false,
            };
        },
        [CHANGE_STATUS_ALLIANCE_SUCCESS]: (state) => {
            state.changeStatusAlliance.isFetching = false;
        },
        [CHANGE_STATUS_FILE]: (state) => {
            state.changeStatusFile.isFetching = true;
        },
        [CHANGE_STATUS_FILE_FAILURE]: (state, action) => {
            state.changeStatusFile = {
                ...state.changeStatusFile,
                errors: action.payload,
                isFetching: false,
            };
        },
        [CHANGE_STATUS_FILE_SUCCESS]: (state) => {
            state.changeStatusFile.isFetching = false;
        },
        [CLOSE_ALLIANCE]: (state) => {
            state.closeAlliance.isFetching = true;
        },
        [CLOSE_ALLIANCE_FAILURE]: (state, action) => {
            state.closeAlliance = {
                ...state.closeAlliance,
                errors: action.payload,
                isFetching: false,
            };
        },
        [CLOSE_ALLIANCE_SUCCESS]: (state) => {
            state.closeAlliance.isFetching = false;
        },
        [DELETE_DOCUMENT]: (state) => {
            state.deleteDocument.isDeleting = true;
        },
        [DELETE_DOCUMENT_FAILURE]: (state, action) => {
            state.deleteDocument = {
                ...state.deleteDocument,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_DOCUMENT_SUCCESS]: (state) => {
            state.deleteDocument.isDeleting = false;
        },
        [FETCH_ASSIGNED_USER]: (state) => {
            state.assignedUser.isFetching = true;
        },
        [FETCH_ASSIGNED_USER_FAILURE]: (state, action) => {
            state.assignedUser = {
                ...state.assignedUser,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ASSIGNED_USER_SUCCESS]: (state, action) => {
            state.assignedUser = {
                ...state.assignedUser,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPANY_USERS]: (state) => {
            state.companyUsers.isFetching = true;
        },
        [FETCH_CONPANY_USERS_FAILURE]: (state, action) => {
            state.companyUsers = {
                ...state.companyUsers,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPANY_USERS_SUCCESS]: (state, action) => {
            state.companyUsers = {
                ...state.companyUsers,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONTRACT_DOCUMENTS]: (state) => {
            state.contractDocuments.isFetching = true;
        },
        [FETCH_CONTRACT_DOCUMENTS_FAILURE]: (state, action) => {
            state.contractDocuments = {
                ...state.contractDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONTRACT_DOCUMENTS_SUCCESS]: (state, action) => {
            state.contractDocuments = {
                ...state.contractDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOCUMENTS]: (state) => {
            state.documents.isFetching = true;
        },
        [FETCH_DOCUMENTS_FAILURE]: (state, action) => {
            state.documents = {
                ...state.documents,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOCUMENTS_SUCCESS]: (state, action) => {
            state.documents = {
                ...state.documents,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIER_INSTALLER]: (state) => {
            state.financierInstaller.isFetching = true;
        },
        [FETCH_FINANCIER_INSTALLER_FAILURE]: (state, action) => {
            state.financierInstaller = {
                ...state.financierInstaller,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIER_INSTALLER_SUCCESS]: (state, action) => {
            state.financierInstaller = {
                ...state.financierInstaller,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INSTALLER]: (state) => {
            state.fetchInstaller.isFetching = true;
        },
        [FETCH_INSTALLER_FAILURE]: (state, action) => {
            state.fetchInstaller = {
                ...state.fetchInstaller,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INSTALLER_SUCCESS]: (state, action) => {
            state.fetchInstaller = {
                ...state.fetchInstaller,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PRODUCTS_LIST]: (state) => {
            state.productsList.isFetching = true;
        },
        [FETCH_PRODUCTS_LIST_FAILURE]: (state, action) => {
            state.productsList = {
                ...state.productsList,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PRODUCTS_LIST_SUCCESS]: (state, action) => {
            state.productsList = {
                ...state.productsList,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROJECTS_SALES_STAGE]: (state) => {
            state.projectsSalesStage.isLoading = true;
        },
        [FETCH_PROJECTS_SALES_STAGE_FAILURE]: (state, action) => {
            state.projectsSalesStage = {
                ...state.projectsSalesStage,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_PROJECTS_SALES_STAGE_SUCCESS]: (state, action) => {
            state.projectsSalesStage = {
                ...state.projectsSalesStage,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_PROPOSALS_TOTALS]: (state) => {
            state.proposalsTotals.isLoading = true;
        },
        [FETCH_PROPOSALS_TOTALS_FAILURE]: (state, action) => {
            state.proposalsTotals = {
                ...state.proposalsTotals,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_PROPOSALS_TOTALS_SUCCESS]: (state, action) => {
            state.proposalsTotals = {
                ...state.proposalsTotals,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_REQUEST_FORM_DOCUMENTS]: (state) => {
            state.requestFormDocuments.isFetching = true;
        },
        [FETCH_REQUEST_FORM_DOCUMENTS_FAILURE]: (state, action) => {
            state.requestFormDocuments = {
                ...state.requestFormDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUEST_FORM_DOCUMENTS_SUCCESS]: (state, action) => {
            state.requestFormDocuments = {
                ...state.requestFormDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUIRED_DOCUMENTS_LIST]: (state) => {
            state.requiredDocumentsList.isFetching = true;
        },
        [FETCH_REQUIRED_DOCUMENTS_LIST_FAILURE]: (state, action) => {
            state.requiredDocumentsList = {
                ...state.requiredDocumentsList,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUIRED_DOCUMENTS_LIST_SUCCESS]: (state, action) => {
            state.requiredDocumentsList = {
                ...state.requiredDocumentsList,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SELECTED_PRODUCTS]: (state) => {
            state.selectedProducts.isFetching = true;
        },
        [FETCH_SELECTED_PRODUCTS_FAILURE]: (state, action) => {
            state.selectedProducts = {
                ...state.selectedProducts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SELECTED_PRODUCTS_SUCCESS]: (state, action) => {
            state.selectedProducts = {
                ...state.selectedProducts,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = {
                ...state.initialValues,
                ...action.payload,
            };
        },
        [INITIALIZE_FORM_INSTALLER_DATA]: (state, action) => {
            state.initialValuesInstallerDataForm = {
                ...state.initialValuesInstallerDataForm,
                ...action.payload,
            };
        },
        [INITIALIZE_PRODUCTS_FORM]: (state, action) => {
            state.initialValuesProductForm = {
                ...state.initialValuesProductForm,
                ...action.payload,
            };
        },
        [REJECT_ALLIANCE]: (state) => {
            state.rejectAlliance = { data: null, errors: [], isFetching: true };
        },
        [REJECT_ALLIANCE_FAILURE]: (state, action) => {
            state.rejectAlliance = {
                errors: action.payload,
                isFetching: false,
            };
        },
        [REJECT_ALLIANCE_SUCCESS]: (state, action) => {
            state.rejectAlliance = {
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [REJECT_FILE]: (state) => {
            state.rejectFile.isFetching = true;
        },
        [REJECT_FILE_FAILURE]: (state, action) => {
            state.rejectFile = {
                ...state.rejectFile,
                errors: action.payload,
                isFetching: false,
            };
        },
        [REJECT_FILE_SUCCESS]: (state) => {
            state.rejectFile.isFetching = false;
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_ASSIGN_USER_FORM]: (state) => {
            state.initialValuesAssignUserForm =
                INITIAL_STATE.initialValuesAssignUserForm;
            state.saveAssignedUser.errors = [];
        },
        [RESET_PRODUCTS_FORM]: (state) => {
            state.initialValuesProductForm =
                INITIAL_STATE.initialValuesProductForm;
            state.saveSelectedProducts.errors = [];
        },
        [RESET_UPLOAD_FORM]: (state) => {
            state.initialValuesUploadForm =
                INITIAL_STATE.initialValuesUploadForm;
            state.fileUploaded = INITIAL_STATE.fileUploaded;
        },
        [SAVE_ASSIGNED_USER]: (state) => {
            state.saveAssignedUser.isFetching = true;
        },
        [SAVE_ASSIGNED_USER_FAILURE]: (state, action) => {
            state.saveAssignedUser = {
                ...state.saveAssignedUser,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SAVE_ASSIGNED_USER_SUCCESS]: (state) => {
            state.saveAssignedUser.isFetching = false;
        },
        [SAVE_DOCUMENT]: (state) => {
            state.saveDocument.isSaving = true;
        },
        [SAVE_DOCUMENT_FAILURE]: (state, action) => {
            state.saveDocument = {
                ...state.saveDocument,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_DOCUMENT_SUCCESS]: (state) => {
            state.saveDocument.isSaving = false;
        },
        [SAVE_SELECTED_PRODUCTS]: (state) => {
            state.saveSelectedProducts.isFetching = true;
        },
        [SAVE_SELECTED_PRODUCTS_FAILURE]: (state, action) => {
            state.saveSelectedProducts = {
                ...state.saveSelectedProducts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SAVE_SELECTED_PRODUCTS_SUCCESS]: (state) => {
            state.saveSelectedProducts.isFetching = false;
        },
        [SET_IS_DOWNLOADING]: (state, action) => {
            state.isDownloading = action.payload;
        },
        [UPDATE_INSTALLER_DATA]: (state) => {
            state.updateInstaller.isSaving = true;
        },
        [UPDATE_INSTALLER_DATA_FAILURE]: (state, action) => {
            state.updateInstaller = {
                ...state.updateInstaller,
                errors: action.payload,
                isSaving: false,
            };
        },
        [UPDATE_INSTALLER_DATA_SUCCESS]: (state) => {
            state.updateInstaller.isSaving = false;
        },
        [UPDATE_INSTALLER_USERS]: (state) => {
            state.updateInstallerUsers.isSaving = true;
        },
        [UPDATE_INSTALLER_USERS_FAILURE]: (state, action) => {
            state.updateInstallerUsers = {
                ...state.updateInstallerUsers,
                errors: action.payload,
                isSaving: false,
            };
        },
        [UPDATE_INSTALLER_USERS_SUCCESS]: (state) => {
            state.updateInstallerUsers.isSaving = false;
        },
        [UPLOAD_DOCUMENT]: (state) => {
            state.fileUploaded.isUploading = true;
        },
        [UPLOAD_DOCUMENT_FAILURE]: (state, action) => {
            state.fileUploaded = {
                ...state.fileUploaded,
                errors: action.payload,
                isUploading: false,
            };
        },
        [UPLOAD_DOCUMENT_SUCCESS]: (state, action) => {
            const { data, index } = action.payload;
            const requiredDocuments = state.requiredDocumentsList.data;
            const document = requiredDocuments[index];
            state.requiredDocumentsList.data[index] = {
                ...document,
                file: data.file,
                status: data.status,
            };
            state.fileUploaded = {
                ...state.fileUploaded,
                data: data,
                isUploading: false,
            };
        },
    },
});

export const installerProfileActions = installerProfileSlice.actions;

export default installerProfileSlice.reducer;
