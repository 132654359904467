import React from 'react';

import BoltIcon from '@mui/icons-material/Bolt';
import { useTheme } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemButton, ListItemText } from 'sunwise-ui';

import { ELECTRIC_BILL_TYPE } from '../constants';

import SideMenuCard from './SideMenuCard';

const ElectricBillCard = ({
    electricBills,
    handleClickElectricBillItem,
    isDisabled,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (isEmpty(electricBills)) return null;
    return (
        <SideMenuCard
            icon={<BoltIcon sx={{ color: 'primary.main' }} />}
            title={t('Electricity bill')}
        >
            <List disablePadding>
                {electricBills.map((item) => (
                    <ListItem
                        disabled={isDisabled || item.id === selectedDocumentId}
                        disablePadding
                        key={`electric-bill-${item.label}`}
                        selected={item.id.toString() === selectedDocumentId}
                        sx={{
                            borderRadius: '8px',
                            mb: '5px',
                            '&.Mui-selected': {
                                backgroundColor:
                                    theme.palette.mode === 'dark'
                                        ? '#000'
                                        : 'rgba(31, 60, 83, 0.13)',
                            },
                        }}
                    >
                        <ListItemButton
                            disabled={
                                isDisabled || item.id === selectedDocumentId
                            }
                            onClick={() =>
                                handleClickElectricBillItem({
                                    archive: item.url,
                                    fileName: item.url,
                                    id: item.id.toString(),
                                    name: item.label,
                                    type: ELECTRIC_BILL_TYPE,
                                    urlType: item.urlType,
                                })
                            }
                            sx={{ borderRadius: '8px' }}
                        >
                            <ListItemText
                                primary={item.label}
                                sx={{
                                    '.MuiListItemText-primary': {
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        textTransform: 'capitalize',
                                    },
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </SideMenuCard>
    );
};

ElectricBillCard.propTypes = {
    electricBills: PropTypes.array,
    handleClickElectricBillItem: PropTypes.func,
    isDisabled: PropTypes.bool,
    selectedDocumentId: PropTypes.string,
};

export default ElectricBillCard;
