import API from '..';
const ENTITY = 'notifications/devices';

/** devices */
export const createDevice = (data) =>
    API.post(`/api/v1/${ENTITY}/`, { ...data });
export const deleteDevice = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const deleteDevices = (type) =>
    API.delete(`/api/v1/${ENTITY}/delete/?type=${type}`);
export const getDevice = (id) => API.get(`/api/v1/${ENTITY}/${id}`);
export const getDevices = () => API.get(`/api/v1/${ENTITY}/`);
export const updateDevice = (id, data) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
