import { styled } from '@mui/material/styles';

export default styled('div')`
    margin-bottom: 15px;

    .nav-tabs {
        border-bottom: 0;

        .nav-link {
            color: #848bab;
            font-size: 14px;
            font-size: 14px;
            line-height: 18px;
            line-height: 18px;
            margin-right: 30px;
            padding: 5px 12px;
            position: relative;

            &:after {
                background-color: #eff1fb;
                content: '';
                height: 16px;
                position: absolute;
                right: -15px;
                width: 1px;
            }

            &:last-child:after {
                display: none;
            }
        }

        .nav-link.active {
            background-color: #ffffff;
            border: 1px solid #eff1fb;
            color: #ff9a00;

            &:before {
                background-color: #ff9a00;
                bottom: -10px;
                content: '';
                height: 2px;
                left: 50%;
                margin-left: -15px;
                position: absolute;
                width: 30px;
            }
        }
    }

    .tab-content {
        padding-top: 1em;
    }

    ${({ variant }) =>
        variant === 'product' &&
        `
        .nav-tabs {
            border-bottom: 0;
            flex-wrap: nowrap;
            justify-content: space-between;
            overflow-x: auto;
            overflow: hidden;

            .nav-link {
                align-items: center;
                background: #eef4fa;
                display: flex;
                flex-direction: column;
                font-size: 13px;
                font-weight: 600;
                justify-content: center;
                line-height: 16px;
                margin-right: 0;
                margin-right: 2px;
                padding: 12px;
                position: relative;
                text-align: center;
                width: 100%;

                &:after {
                    display: none;
                }

                &:last-child{
                    margin-right: 0;
                }
            }

            .nav-link.active {
                background-color: #ffffff;
                border-bottom: 0;
                color: #202253;
                margin-top: -5px;

                &:before {
                    display: none;
                }
            }
        }

        @media (min-width: 768px) {
            .nav-tabs .nav-link{
                flex-direction: row;
            }
        }

        .tab-content {
            background: #fff;
            border: 1px solid #ecedf0;
            border-top: 0;
            padding-top: 1em;
        }

        .card-header-tabs{
            margin: 0 0;
        }
    `}
`;
