import React, { useEffect, useState } from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Tab,
    TabPanel,
    Tabs,
    TextField,
    Typography,
    UiDialog as Dialog,
} from 'sunwise-ui';

import CustomTemplatesTab from './CustomTemplatesTab';
import DefaultTemplatesTab from './DefaultTemplatesTab';

const NewPageModal = ({
    handleClickClose,
    isOpen,
    onAddPage,
    onClonePage,
    onEnter,
}) => {
    const { t } = useTranslation();
    const [currentKey, setCurrentKey] = useState('default-pages-templates-tab');
    const [categorySelected, selectCategory] = useState(null);
    const [pageSelected, selectPage] = useState(null);
    const [fromPage, setFromPage] = useState(1);
    const [toPage, setToPage] = useState(1);
    const isDefaultTab = currentKey === 'custom-pages-templates-tab';

    useEffect(() => {
        if (isDefaultTab && !isNull(categorySelected)) selectCategory(null);
        if (!isNull(pageSelected)) selectPage(null);
    }, [currentKey]);

    const handleFromPageValue = (value) =>
        setFromPage(value === '' ? '' : parseInt(value));

    const handleToPageValue = (value) =>
        setToPage(value === '' ? '' : parseInt(value));

    const handleSelectPage = (page) => {
        setFromPage(1);
        setToPage(page.pages);
        selectPage(page);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (pageSelected !== null && pageSelected.id === 'blank-page') {
            onAddPage(toPage);
        } else {
            const temp = [];
            for (let i = fromPage; i <= toPage; ++i) temp.push(i);
            onClonePage(pageSelected.id, temp.join());
        }
        handleClickClose();
        selectPage(null);
    };

    const handleChange = (_, newValue) => setCurrentKey(newValue);

    return (
        <Dialog
            onClose={() => {
                handleClickClose();
                selectPage(null);
            }}
            onEnter={onEnter}
            onExited={() => {
                setCurrentKey('default-pages-templates-tab');
                selectCategory(null);
                selectPage(null);
                setFromPage(1);
                setToPage(1);
            }}
            open={isOpen}
            title={t('New page')}
        >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentKey} onChange={handleChange}>
                    <Tab
                        id="tab-default-pages-templates-tab"
                        key="tab-default-pages-templates-tab"
                        label={t('Default templates')}
                        value="default-pages-templates-tab"
                    />

                    <Tab
                        id="tab-custom-pages-templates-tab"
                        key="tab-custom-pages-templates-tab"
                        label={t('My templates')}
                        value="custom-pages-templates-tab"
                    />
                </Tabs>
            </Box>

            <TabPanel
                key="tab-default-pages-templates-tab"
                selectedTab={currentKey}
                value="default-pages-templates-tab"
            >
                <DefaultTemplatesTab
                    categorySelected={categorySelected}
                    handleSelectPage={handleSelectPage}
                    pageSelected={pageSelected}
                    selectCategory={selectCategory}
                />
            </TabPanel>

            <TabPanel
                key="tab-custom-pages-templates-tab"
                selectedTab={currentKey}
                value="custom-pages-templates-tab"
            >
                <CustomTemplatesTab handleSelectPage={handleSelectPage} />
            </TabPanel>

            <Box display="flex" alignItems="center">
                {pageSelected && (
                    <Box
                        alignItems="center"
                        display="flex"
                        gap={1}
                        sx={{ '.MuiFormControl-root': { mb: 0 } }}
                    >
                        {pageSelected.id === 'blank-page' ? (
                            <TextField
                                label={t('Number of pages')}
                                onBlur={(event) => {
                                    if (
                                        event.target.value === '' ||
                                        parseInt(event.target.value) < 1
                                    )
                                        handleToPageValue(1);
                                }}
                                onChange={(e) =>
                                    handleToPageValue(e?.target?.value)
                                }
                                sx={{ width: '160px', textAlign: 'center' }}
                                value={toPage}
                            />
                        ) : (
                            <>
                                <Typography variant="caption">
                                    {t('From page')}
                                </Typography>

                                <TextField
                                    disabled={pageSelected.id === 'blank-page'}
                                    max={toPage}
                                    onBlur={(event) => {
                                        if (
                                            event.target.value === '' ||
                                            parseInt(event.target.value) < 1
                                        )
                                            handleFromPageValue(1);
                                    }}
                                    onChange={(e) =>
                                        handleFromPageValue(e?.target?.value)
                                    }
                                    sx={{ width: '60px', textAlign: 'center' }}
                                    value={fromPage}
                                />

                                <Typography variant="caption">
                                    {t('To page')}
                                </Typography>

                                <TextField
                                    disabled={pageSelected.id === 'blank-page'}
                                    max={pageSelected.pages}
                                    onBlur={(event) => {
                                        if (event.target.value === '')
                                            handleToPageValue(
                                                pageSelected.pages
                                            );
                                        if (
                                            parseInt(event.target.value) <
                                            parseInt(fromPage)
                                        )
                                            handleToPageValue(fromPage);
                                    }}
                                    onChange={(e) =>
                                        handleToPageValue(e?.target?.value)
                                    }
                                    sx={{ textAlign: 'center', width: '60px' }}
                                    value={toPage}
                                />
                            </>
                        )}
                    </Box>
                )}

                <Box sx={{ ml: 'auto' }}>
                    <Button
                        disabled={pageSelected === null}
                        onClick={handleSubmit}
                        type="button"
                    >
                        {t('Add')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

NewPageModal.propTypes = {
    handleClickClose: PropTypes.func,
    isOpen: PropTypes.bool,
    onAddPage: PropTypes.func,
    onClonePage: PropTypes.func,
    onEnter: PropTypes.func,
};

export default NewPageModal;
