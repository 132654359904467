import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

const ReactHookFormSelect = ({
    control,
    disabled,
    fullWidth,
    id,
    label,
    name,
    onChange,
    options,
    sxFormControl,
    variant,
}) => (
    <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
            <FormControl
                error={Boolean(error)}
                fullWidth={fullWidth}
                size="small"
                {...sxFormControl}
                variant={variant}
            >
                <InputLabel
                    {...(field?.value && { shrink: Boolean(field.value) })}
                >
                    {label}
                </InputLabel>

                <Select
                    disabled={disabled}
                    displayEmpty={label === ''}
                    id={id}
                    label={label}
                    {...field}
                    onChange={(e) => {
                        field.onChange(e);
                        if (onChange) onChange(e);
                    }}
                    value={field.value !== null ? field.value : ''}
                >
                    {options.map(
                        ({ label, value, disabled: optionDisabled }) => (
                            <MenuItem
                                key={`select-${value}-${label}`}
                                value={value}
                                disabled={optionDisabled}
                            >
                                {label}
                            </MenuItem>
                        )
                    )}
                </Select>

                {Boolean(error) && (
                    <FormHelperText>{error?.message}</FormHelperText>
                )}
            </FormControl>
        )}
    />
);

ReactHookFormSelect.defaultProps = {
    fullWidth: true,
    ignore: [],
    label: '',
    sxFormControl: {},
};

ReactHookFormSelect.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    id: PropTypes.string,
    ignore: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    required: PropTypes.bool,
    sxFormControl: PropTypes.object,
    variant: PropTypes.string,
};

export default ReactHookFormSelect;
