import { getFinancialProducts, getProductsList } from 'common/api/products';
import { getFinancierId } from 'common/utils/session';

import {
    FETCH_FINANCIAL_PRODUCTS,
    FETCH_FINANCIAL_PRODUCTS_FAILURE,
    FETCH_FINANCIAL_PRODUCTS_SUCCESS,
} from '../actionTypes';
import { installersActions } from '../reducer';

export default (allianceId = null) =>
    (dispatch) => {
        dispatch(installersActions[FETCH_FINANCIAL_PRODUCTS]());
        const action = allianceId
            ? getProductsList(getFinancierId(), allianceId)
            : getFinancialProducts();

        action
            .then((response) =>
                dispatch(
                    installersActions[FETCH_FINANCIAL_PRODUCTS_SUCCESS](
                        response.data
                    )
                )
            )
            .catch((error) =>
                dispatch(
                    installersActions[FETCH_FINANCIAL_PRODUCTS_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
