import React from 'react';

import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

const InstallerOwnerFormatter = ({ row }) => (
    <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="caption">
            {row?.installer?.legal_representative || '- - -'}
        </Typography>

        <Typography variant="caption">{row?.installer?.email}</Typography>
    </Box>
);

InstallerOwnerFormatter.propTypes = {
    row: PropTypes.object,
};

export default InstallerOwnerFormatter;
