import React from 'react';

import Notifications from '@mui/icons-material/Notifications';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Badge, IconButton } from 'sunwise-ui';

const NotificationButton = ({ handleToggleDrawer, quantity, sxIcon }) => {
    const theme = useTheme();
    return (
        <IconButton onClick={handleToggleDrawer(true)} size="small">
            <Badge
                badgeContent={quantity}
                sx={{
                    '& .MuiBadge-badge': {
                        backgroundColor: theme.palette.primary.light,
                        color:
                            theme.palette.mode === 'dark'
                                ? theme.palette.primary.contrastText
                                : '#fff',
                    },
                }}
            >
                <Notifications sx={sxIcon} />
            </Badge>
        </IconButton>
    );
};

NotificationButton.propTypes = {
    handleToggleDrawer: PropTypes.func,
    quantity: PropTypes.number,
    sxIcon: PropTypes.object,
};

export default NotificationButton;
