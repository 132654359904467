import { getSmartDocument } from 'common/api/smartDocuments';

import {
    FETCH_CUSTOM_TEMPLATE,
    FETCH_CUSTOM_TEMPLATE_SUCCESS,
    FETCH_CUSTOM_TEMPLATE_FAILURE,
} from '../actionTypes';
import { templateDocumentsActions } from '../reducer';

export default (id, initTemplate, resetTemplate) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(templateDocumentsActions[FETCH_CUSTOM_TEMPLATE]());

        return getSmartDocument(id)
            .then((response) => {
                dispatch(
                    templateDocumentsActions[FETCH_CUSTOM_TEMPLATE_SUCCESS](
                        response.data
                    )
                );
                const { content } = response.data;
                resetTemplate();
                initTemplate(JSON.parse(content));
            })
            .catch((error) =>
                dispatch(
                    templateDocumentsActions[FETCH_CUSTOM_TEMPLATE_FAILURE](
                        error
                    )
                )
            )
            .finally(resolve);
    });
