import { createSlice } from '@reduxjs/toolkit';

import {
    BRANCH_OFFICES_DICTIONARY_BACKUP,
    FETCH_COMPANIES,
    FETCH_COMPANIES_FAILURE,
    FETCH_COMPANIES_SUCCESS,
    FETCH_EXECUTIVES,
    FETCH_EXECUTIVES_FAILURE,
    FETCH_EXECUTIVES_SUCCESS,
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
    FETCH_NEXT_PAGE,
    FETCH_NEXT_PAGE_FAILURE,
    FETCH_NEXT_PAGE_SUCCESS,
    RESET_VALUES,
    SELECT_FILTER_ITEM,
    UPDATE_COLUMNS,
    UPDATE_COLUMNS_FAILURE,
    UPDATE_COLUMNS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    branchOfficesDictionaryBackup: {},
    companies: { data: [], error: null, isFetching: false },
    executives: { data: [], errors: [], isFetching: false },
    filterItemSelected: { type: '', value: '' },
    filterQuery: '',
    funnelColumns: { data: {}, errors: [], isFetching: false },
    nextPages: { errors: [], ids: {}, isFetching: false },
    refreshingIsNeeded: false,
    socketConnected: false,
    socketError: null,
    updatingStatusProject: null,
};

const slice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BRANCH_OFFICES_DICTIONARY_BACKUP]: (state, action) => {
            state.branchOfficesDictionaryBackup = action.payload;
        },
        [FETCH_ITEMS]: (state) => {
            state.funnelColumns = {
                ...state.funnelColumns,
                errors: [],
                isFetching: true,
            };
        },
        [UPDATE_COLUMNS]: (state, action) => {
            const { funnelColumnsOrdered, projectId } = action.payload;

            state.funnelColumns = {
                ...state.funnelColumns,
                data: funnelColumnsOrdered,
                isFetching: false,
            };
            state.updatingStatusProject = {
                funnelColumns: { ...state.funnelColumns.data },
                projectId,
            };
        },
        [UPDATE_COLUMNS_FAILURE]: (state, action) => {
            let restoredFunnelColumns = {
                ...state.updatingStatusProject.funnelColumns,
            };

            state.funnelColumns = {
                ...state.funnelColumns,
                data: restoredFunnelColumns,
                errors: action.payload.errors,
                isFetching: false,
            };
            state.updatingStatusProject = null;
        },
        [UPDATE_COLUMNS_SUCCESS]: (state) => {
            state.updatingStatusProject = null;
        },
        [FETCH_ITEMS_FAILURE]: (state, action) => {
            state.funnelColumns = {
                ...state.funnelColumns,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_SUCCESS]: (state, action) => {
            state.funnelColumns = {
                ...state.funnelColumns,
                data: action.payload.funnelProjects,
                isFetching: false,
            };
            state.filterQuery = action.payload.filterQuery;
        },
        [FETCH_NEXT_PAGE]: (state, action) => {
            state.nextPages = {
                ...state.nextPages,
                errors: [],
                ids: { ...state.nextPages.ids, [action.payload.id]: true },
                isFetching: true,
            };
        },
        [FETCH_NEXT_PAGE_FAILURE]: (state, action) => {
            let newFunnelColumns2 = { ...state.funnelColumns.data };
            newFunnelColumns2[action.payload.id].nextProject = null;

            state.nextPages = {
                ...state.nextPages,
                errors: action.payload.error,
                ids: { ...state.nextPages.ids, [action.payload.id]: false },
                isFetching: false,
            };
            state.funnelColumns = {
                ...state.funnelColumns,
                data: newFunnelColumns2,
            };
        },
        [FETCH_NEXT_PAGE_SUCCESS]: (state, action) => {
            let newFunnelColumns = { ...state.funnelColumns.data };
            const { nextProject, id, projects } = action.payload;
            newFunnelColumns[id].projects = [
                ...newFunnelColumns[id].projects,
                ...projects,
            ];
            newFunnelColumns[id].nextProject = nextProject;

            state.nextPages = {
                ...state.nextPages,
                ids: { ...state.nextPages.ids, [id]: false },
                isFetching: false,
            };
            state.funnelColumns = {
                ...state.funnelColumns,
                data: newFunnelColumns,
            };
        },
        [FETCH_COMPANIES]: (state) => {
            state.companies = {
                ...state.companies,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_COMPANIES_FAILURE]: (state, action) => {
            state.companies = {
                ...state.companies,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPANIES_SUCCESS]: (state, action) => {
            state.companies = {
                ...state.companies,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [FETCH_EXECUTIVES]: (state) => {
            state.executives.isLoading = true;
        },
        [FETCH_EXECUTIVES_FAILURE]: (state, action) => {
            state.executives = {
                ...state.executives,
                error: action.payload,
                isLoading: false,
            };
        },
        [FETCH_EXECUTIVES_SUCCESS]: (state, action) => {
            state.executives = {
                ...state.executives,
                data: action.payload,
                isLoading: false,
            };
        },
        [RESET_VALUES]: () => INITIAL_STATE,
        [SELECT_FILTER_ITEM]: (state, action) => {
            state.filterItemSelected = action.payload;
        },
    },
});

export const actions = slice.actions;

export default slice.reducer;
