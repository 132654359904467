import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Card,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from 'sunwise-ui';

import { DropdownCardMenu, EmptyMessage } from 'common/components';

const CoverageConfigPreview = ({
    canModify,
    filterData,
    handleToggleCoverageConfigModal,
    prepareDelete,
    prepareUpdate,
    selectedCoverage,
    setSelectedCoverage,
}) => {
    const { t } = useTranslation();

    if (isEmpty(selectedCoverage))
        return (
            <Card sx={{ minHeight: 400 }}>
                <Card.Body>
                    <EmptyMessage
                        text={t('Select an operating cost to configure')}
                    />
                </Card.Body>
            </Card>
        );

    const renderContent = () => {
        if (isEmpty(selectedCoverage?.insurance_product_params))
            return <EmptyMessage text={t('There are no items to display')} />;
        return (
            <List>
                {selectedCoverage.insurance_product_params.map((item) => (
                    <ListItem
                        disablePadding
                        key={`coverage-config-${item.id}`}
                        secondaryAction={
                            <DropdownCardMenu
                                items={[
                                    {
                                        handleClick: () => prepareUpdate(item),
                                        icon: <EditIcon fontSize="small" />,
                                        label: t('Edit'),
                                    },
                                    {
                                        handleClick: () =>
                                            prepareDelete(
                                                filterData,
                                                item,
                                                setSelectedCoverage
                                            ),
                                        icon: <DeleteIcon fontSize="small" />,
                                        label: t('Delete'),
                                    },
                                ]}
                                sxIconButton={{ ml: 'auto' }}
                                visible={canModify}
                            />
                        }
                    >
                        <ListItemButton sx={{ py: 2 }}>
                            <ListItemText primary={item.description} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        );
    };

    return (
        <Card sx={{ minHeight: 400 }}>
            <Card.Header>
                <Box hidden={!canModify} sx={{ textAlign: 'right' }}>
                    <Button
                        color="secondary"
                        endIcon={<AddIcon fontSize="small" />}
                        onClick={() => handleToggleCoverageConfigModal(true)}
                        variant="outlined"
                    >
                        {t('Add')}
                    </Button>
                </Box>
            </Card.Header>
            <Card.Body>{renderContent()}</Card.Body>
        </Card>
    );
};

CoverageConfigPreview.propTypes = {
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleToggleCoverageConfigModal: PropTypes.func,
    prepareDelete: PropTypes.func,
    prepareUpdate: PropTypes.func,
    selectedCoverage: PropTypes.object,
    setSelectedCoverage: PropTypes.func,
};

export default CoverageConfigPreview;
