import { INVALID_PASSWORD, VALID_PASSWORD } from '../actionTypes';
import { profileSecurityActions } from '../reducer';

export default (event) => (dispatch) => {
    const password = event.target.value;

    if (password.length >= 8) {
        dispatch(profileSecurityActions[VALID_PASSWORD]());
    } else {
        dispatch(profileSecurityActions[INVALID_PASSWORD]());
    }
};
