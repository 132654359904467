import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { types } from 'sunwise-template-core';

import FinancingDocumentsCard from '../../templateDocuments/Container';

import DataSheetsCard from './DataSheetsCard';
import DownloadCard from './DownloadCard';
import EditionButtons from './EditionButtons';
import ElectricBillCard from './ElectricBillCard';
import FinancialCard from './FinancialCard';
import ProposalCard from './ProposalCard';
import SmartDocumentsCard from './SmartDocumentsCard';

const SupportMenuContent = ({
    closeDrawer,
    datasheets,
    downloadFormat,
    editionDisabled,
    electricBills,
    handleClickDatasheetItem,
    handleClickDocumentItem,
    handleClickDownload,
    handleClickEditButton,
    handleClickElectricBillItem,
    handleClickProposalItem,
    handleUpdateTemplate,
    initTemplate,
    interactiveProposalData,
    isDownloading,
    isFetching,
    isUpdatingCustomTemplate,
    proposalId,
    resetTemplate,
    restorePages,
    selectedDocument,
    setDownloadFormat,
    setEditionDisabled,
    setSelectedDocument,
    smartDocuments,
}) => {
    const isDisabled =
        !editionDisabled ||
        isDownloading ||
        isFetching ||
        isUpdatingCustomTemplate;

    const proposalDocumentDefault = {
        id: proposalId,
        name: get(interactiveProposalData, 'proposal_name', ''),
        type: types.SMART_DOCUMENTS_TYPE,
    };

    return (
        <>
            <EditionButtons
                canUpdatePreview={true}
                editionDisabled={editionDisabled}
                handleClickCancel={() => {
                    restorePages();
                    setEditionDisabled(true);
                    closeDrawer();
                }}
                handleClickSave={() => {
                    handleUpdateTemplate();
                    closeDrawer();
                }}
                isUpdatingCustomTemplate={isUpdatingCustomTemplate}
                setEditionDisabled={setEditionDisabled}
            />

            <DownloadCard
                downloadFormat={downloadFormat}
                handleClickDownload={handleClickDownload}
                selectedDocument={selectedDocument}
                setDownloadFormat={setDownloadFormat}
            />

            <ProposalCard
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                proposalId={proposalId}
                proposalName={get(interactiveProposalData, 'proposal_name', '')}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <ElectricBillCard
                electricBills={electricBills}
                handleClickElectricBillItem={handleClickElectricBillItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <FinancialCard
                financing={get(interactiveProposalData, 'financing', {})}
                handleClickDatasheetItem={handleClickDatasheetItem}
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />

            <FinancingDocumentsCard
                closeDrawer={closeDrawer}
                handleClickEditButton={handleClickEditButton}
                handleClickProposalItem={handleClickProposalItem}
                initTemplate={initTemplate}
                isDisabled={isDisabled}
                isUpdatingCustomTemplate={isUpdatingCustomTemplate}
                proposalDocumentDefault={proposalDocumentDefault}
                proposalId={proposalId}
                resetTemplate={resetTemplate}
                selectedDocument={selectedDocument}
                setEditionDisabled={setEditionDisabled}
                setSelectedDocument={setSelectedDocument}
            />

            <SmartDocumentsCard
                editionDisabled={editionDisabled}
                handleClickDocumentItem={handleClickDocumentItem}
                isDisabled={isDisabled}
                isUpdatingCustomTemplate={isUpdatingCustomTemplate}
                selectedDocumentId={get(selectedDocument, 'id', null)}
                setEditionDisabled={setEditionDisabled}
                smartDocuments={smartDocuments}
            />

            <DataSheetsCard
                datasheets={datasheets}
                handleClickDatasheetItem={handleClickDatasheetItem}
                isDisabled={isDisabled}
                selectedDocumentId={get(selectedDocument, 'id', null)}
            />
        </>
    );
};

SupportMenuContent.propTypes = {
    closeDrawer: PropTypes.func,
    datasheets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    downloadFormat: PropTypes.number,
    editionDisabled: PropTypes.bool,
    electricBills: PropTypes.array,
    handleClickDatasheetItem: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    handleClickDownload: PropTypes.func,
    handleClickEditButton: PropTypes.func,
    handleClickElectricBillItem: PropTypes.func,
    handleClickProposalItem: PropTypes.func,
    handleUpdateTemplate: PropTypes.func,
    initTemplate: PropTypes.func,
    interactiveProposalData: PropTypes.object,
    isDownloading: PropTypes.bool,
    isFetching: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    proposalId: PropTypes.string,
    resetTemplate: PropTypes.func,
    restorePages: PropTypes.func,
    selectedDocument: PropTypes.object,
    setDownloadFormat: PropTypes.func,
    setEditionDisabled: PropTypes.func,
    setSelectedDocument: PropTypes.func,
    smartDocuments: PropTypes.array,
};

export default SupportMenuContent;
