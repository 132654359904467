import { getFinancierInstallers } from 'common/api/financierInstallers';

import {
    FETCH_ALLIANCES,
    FETCH_ALLIANCES_FAILURE,
    FETCH_ALLIANCES_SUCCESS,
} from '../actionTypes';
import { creditsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(creditsActions[FETCH_ALLIANCES]());

    getFinancierInstallers()
        .then((response) =>
            dispatch(
                creditsActions[FETCH_ALLIANCES_SUCCESS](response.data.data)
            )
        )
        .catch((error) =>
            dispatch(
                creditsActions[FETCH_ALLIANCES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
