import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Button, Grid, Card } from 'sunwise-ui';

import { TitleIcon, Modal, StatusFilterSelect } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissions';
import withPermissions from 'common/hocs/withPermissions';

import * as actions from './actions';
import {
    CardList,
    CoverageConfigForm,
    CoverageConfigPreview,
    CoverageForm,
} from './components';
import * as selectors from './selectors';

const DEBOUNCE_TIME = 1000;

const Container = ({
    canModify,
    coverages,
    fetchCoverages,
    handleClickCoverageFormModal,
    handleToggleCoverageConfigModal,
    initialValuesCoverageConfigForm,
    isFetchingCoverages,
    isOpenCoverageConfigModal,
    isOpenModal,
    prepareArchiveCoverage,
    prepareDeleteCoverageConfig,
    prepareUpdateCoverageConfig,
}) => {
    const { t } = useTranslation();
    const [selectedCoverage, setSelectedCoverage] = useState(null);
    const [filterData, setFilterData] = useState({ status: 'active' });

    const [currentFilterData, setCurrentFilterData] = useState({});

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            setCurrentFilterData(filterData);
            handleOnFilter(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => fetchCoverages(filter), DEBOUNCE_TIME)
    ).current;

    const hanldeOnClickCoverageConfigPrepareUpdate = (item) =>
        prepareUpdateCoverageConfig(item);

    const handleOnSelectStatus = (value) => {
        const filter = { ...filterData, page: 0, status: value };
        setFilterData(filter);
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid item md={9}>
                    <Card sx={{ minHeight: 400 }}>
                        <Card.Header>
                            <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item md={9} lg={6} xs={18}>
                                    <TitleIcon
                                        fontWeight="bold"
                                        title={t('Operating costs')}
                                        variant="subtitle"
                                    />
                                </Grid>
                                <Grid item md={9} lg={4} xs={18}>
                                    <StatusFilterSelect
                                        filterBy={filterData?.status}
                                        handleSelected={handleOnSelectStatus}
                                        variant="standard"
                                    />
                                </Grid>
                                <Grid
                                    hidden={!canModify}
                                    item
                                    textAlign="right"
                                    xs
                                >
                                    <Button
                                        endIcon={<AddIcon fontSize="small" />}
                                        onClick={() =>
                                            handleClickCoverageFormModal(true)
                                        }
                                    >
                                        {t('Add')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card.Header>

                        <CardList
                            canModify={canModify}
                            coverages={coverages}
                            filterData={filterData}
                            isFetching={isFetchingCoverages}
                            prepareArchiveCoverage={prepareArchiveCoverage}
                            selectedCoverage={selectedCoverage}
                            setSelectedCoverage={setSelectedCoverage}
                        />
                    </Card>
                </Grid>

                <Grid item md={9}>
                    <CoverageConfigPreview
                        canModify={canModify}
                        filterData={filterData}
                        handleToggleCoverageConfigModal={
                            handleToggleCoverageConfigModal
                        }
                        prepareDelete={prepareDeleteCoverageConfig}
                        prepareUpdate={hanldeOnClickCoverageConfigPrepareUpdate}
                        selectedCoverage={selectedCoverage}
                        setSelectedCoverage={setSelectedCoverage}
                    />
                </Grid>
            </Grid>

            <Modal
                keyboard={false}
                onHide={() => handleClickCoverageFormModal(false)}
                show={isOpenModal}
                size="sm"
                title={t('New operating cost')}
            >
                <CoverageForm filterData={filterData} />
            </Modal>

            <Modal
                keyboard={false}
                onHide={() => handleToggleCoverageConfigModal(false)}
                show={isOpenCoverageConfigModal}
                size="sm"
                title={`${
                    initialValuesCoverageConfigForm.id === null
                        ? t('Create')
                        : t('Update')
                } ${t('Configuration')}`}
            >
                <CoverageConfigForm
                    filterData={filterData}
                    selectedCoverage={selectedCoverage}
                    setSelectedCoverage={setSelectedCoverage}
                />
            </Modal>
        </React.Fragment>
    );
};

Container.propTypes = {
    canModify: PropTypes.bool,
    coverages: PropTypes.array,
    fetchCoverages: PropTypes.func,
    handleClickCoverageFormModal: PropTypes.func,
    handleToggleCoverageConfigModal: PropTypes.func,
    initialValuesCoverageConfigForm: PropTypes.object,
    isFetchingCoverages: PropTypes.bool,
    isOpenCoverageConfigModal: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    prepareArchiveCoverage: PropTypes.func,
    prepareDeleteCoverageConfig: PropTypes.func,
    prepareUpdateCoverageConfig: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    coverages: selectors.getCoveragesData,
    isOpenCoverageConfigModal: selectors.getIsOpenCoverageConfigModal,
    isOpenModal: selectors.getIsOpenCoverageFormModal,
    initialValuesCoverageConfigForm:
        selectors.getInitialValuesCoverageConfigForm,
    isFetchingCoverages: selectors.getCoveragesIsFetching,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCoverages: (filterData) =>
        dispatch(actions.fetchCoverages(filterData)),
    handleClickCoverageFormModal: (value) =>
        dispatch(actions.toggleCoverageModal(value)),
    handleToggleCoverageConfigModal: (value) =>
        dispatch(actions.toggleCoverageConfigModal(value)),
    prepareArchiveCoverage: (filterData, item, value, callback) =>
        dispatch(
            actions.prepareArchiveCoverage(filterData, item, value, callback)
        ),
    prepareDeleteCoverageConfig: (filterData, item, setSelectedCoverage) =>
        dispatch(
            actions.prepareDeleteCoverageConfig(
                filterData,
                item,
                setSelectedCoverage
            )
        ),
    prepareUpdateCoverageConfig: (item) =>
        dispatch(actions.prepareUpdateCoverageConfig(item)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.INSURANCE_DOCUMENTS_PERMISSION)
)(Container);
