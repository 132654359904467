import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Divider, Grid, Typography } from 'sunwise-ui';

import {
    ReduxFieldCheck,
    ReduxFieldInput,
    ReduxToggleButtonGroupField,
} from 'common/components/form/redux';
import TextVariant from 'common/components/TextVariant';
import {
    DEFAULT_ALGORITHM_TYPES,
    DEFAULT_PRODUCT_TYPES,
    DEFAULT_RATES,
    DEFAULT_TYPES_BUSINESS,
} from 'common/constants';

import CheckboxButtonField from '../../financial/components/productForm/CheckboxButtonField';
import RadioButtonField from '../../financial/components/productForm/RadioButtonField';

const FieldContainer = styled('div')`
    margin-bottom: 45px;
`;

const TextInputContainer = styled(FieldContainer)`
    .form-label {
        color: #202253;
        font-size: 15px;
        margin-bottom: 5px;
    }
    .form-control {
        border-radius: 3px;
        border: 1px solid #eff1fb;
        box-shadow: 0 2px 12px 0 rgba(129, 158, 200, 0.06);
        font-size: 13px;
        height: 50px;
        padding: 17px 0 17px 18px;
    }
`;

const TextAreaContainer = styled(TextInputContainer)`
    .form-control {
        height: 121px;
    }
`;

const Parameters = ({ changeInput, disabled, formValues }) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <TextInputContainer>
                <Field
                    component={ReduxFieldInput}
                    disabled={disabled}
                    label={`${t('Product name')} *`}
                    name="name"
                    placeholder={t('Product name')}
                />
            </TextInputContainer>

            <TextAreaContainer>
                <Field
                    as="textarea"
                    component={ReduxFieldInput}
                    defaultBehevior={true}
                    disabled={disabled}
                    label={t('General information about the product')}
                    name="description"
                    placeholder=""
                />
            </TextAreaContainer>

            <FieldContainer>
                <TextVariant text={t('Product type')} variant="bold" />
                <Divider />
                <Field
                    component={RadioButtonField}
                    disabled={disabled}
                    items={DEFAULT_PRODUCT_TYPES}
                    name="type"
                    prefixId="type"
                />
            </FieldContainer>

            <FieldContainer>
                <TextVariant text={t('Type of tax regime')} variant="bold" />
                <Divider />
                <Field
                    component={RadioButtonField}
                    disabled={disabled}
                    items={DEFAULT_TYPES_BUSINESS}
                    name="types_business_company"
                    prefixId="fiscal2"
                />
            </FieldContainer>

            <FieldContainer>
                <Typography fontWeight="bold" variant="body2">
                    {t('Algorithm for running')}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Field
                    component={RadioButtonField}
                    disabled={disabled}
                    items={DEFAULT_ALGORITHM_TYPES}
                    name="type_algorithm"
                    prefixId="type_algorithm"
                />
            </FieldContainer>

            <FieldContainer>
                <TextVariant text={t('Select the rates')} variant="bold" />
                <Divider />
                <Field
                    component={CheckboxButtonField}
                    disabled={disabled}
                    items={DEFAULT_RATES}
                    name="rate_list"
                    prefixId="rates"
                />
            </FieldContainer>

            <TextInputContainer>
                <TextVariant text={t('Parameters')} variant="bold" />
                <Divider />
                <div>
                    <label>{t('Administration commission')}</label>
                </div>

                <Grid container>
                    <Grid item xs={2} lg={1}>
                        <Field
                            component={ReduxFieldCheck}
                            disabled={true}
                            label=""
                            name="administratives_expenses.isChecked"
                            onChange={(e) => {
                                if (!e.target.checked)
                                    changeInput(
                                        'administratives_expenses.value',
                                        0
                                    );
                            }}
                        />
                    </Grid>

                    <Grid item xs>
                        <Field
                            append={
                                formValues.administratives_expenses &&
                                formValues.administratives_expenses.unit === 0
                                    ? '%'
                                    : 'MXN'
                            }
                            component={ReduxFieldInput}
                            disabled={
                                !formValues['administratives_expenses'] ||
                                !formValues['administratives_expenses']
                                    .isChecked ||
                                disabled
                            }
                            label=""
                            name="administratives_expenses.value"
                            prepend={
                                formValues.administratives_expenses &&
                                formValues.administratives_expenses.unit === 1
                                    ? '$'
                                    : null
                            }
                            type="number"
                        />
                    </Grid>

                    <Grid item xs={9} lg={4}>
                        <Field
                            component={ReduxToggleButtonGroupField}
                            disabled={true}
                            items={[
                                { label: t('Percentage'), value: 0 },
                                { label: t('Amount'), value: 1 },
                            ]}
                            name="administratives_expenses.unit"
                            onChange={() => {
                                changeInput(
                                    'administratives_expenses.value',
                                    formValues.administratives_expenses.unit ===
                                        0
                                        ? 27.7
                                        : 0
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <div>
                    <label>{t('Minimum account balance')}</label>
                </div>

                <Grid container>
                    <Grid item xs={2} lg={1}>
                        <Field
                            component={ReduxFieldCheck}
                            disabled={true}
                            label=""
                            name="retention.isChecked"
                            onChange={(e) => {
                                if (!e.target.checked)
                                    changeInput('retention.value', 0);
                            }}
                        />
                    </Grid>

                    <Grid item xs>
                        <Field
                            append={
                                formValues.retention &&
                                formValues.retention.unit === 0
                                    ? '%'
                                    : 'MXN'
                            }
                            component={ReduxFieldInput}
                            disabled={
                                !formValues['retention'] ||
                                !formValues['retention'].isChecked ||
                                disabled
                            }
                            label=""
                            name="retention.value"
                            prepend={
                                formValues.retention &&
                                formValues.retention.unit === 1
                                    ? '$'
                                    : null
                            }
                            type="number"
                        />
                    </Grid>

                    <Grid item xs={9} lg={4}>
                        <Field
                            component={ReduxToggleButtonGroupField}
                            disabled={true}
                            items={[
                                { label: t('Percentage'), value: 0 },
                                { label: t('Amount'), value: 1 },
                            ]}
                            name="retention.unit"
                            onChange={() => {
                                changeInput(
                                    'retention.value',
                                    formValues.retention.unit === 0 ? 5 : 0
                                );
                            }}
                        />
                    </Grid>
                </Grid>
            </TextInputContainer>

            <TextInputContainer>
                <TextVariant text={t('Amount limit')} variant="bold" />
                <Divider />
                <Grid container>
                    <Grid item xs={2} lg={1}>
                        <Field
                            component={ReduxFieldCheck}
                            disabled={disabled}
                            label=""
                            name="amount_values.isChecked"
                            onChange={(e) => {
                                if (!e.target.checked) {
                                    changeInput('amount_values.min', 0);
                                    changeInput('amount_values.max', '');
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs>
                        <Field
                            append="MXN"
                            component={ReduxFieldInput}
                            disabled={
                                !formValues['amount_values'] ||
                                !formValues['amount_values'].isChecked ||
                                disabled
                            }
                            label={t('Minimum amount')}
                            name="amount_values.min"
                            prepend="$"
                            type="number"
                        />
                    </Grid>

                    <Grid item xs>
                        <Field
                            append="MXN"
                            component={ReduxFieldInput}
                            disabled={
                                !formValues['amount_values'] ||
                                !formValues['amount_values'].isChecked ||
                                disabled
                            }
                            label={t('Maximum amount')}
                            name="amount_values.max"
                            prepend="$"
                            type="number"
                        />
                    </Grid>
                </Grid>
            </TextInputContainer>

            <TextInputContainer>
                <TextVariant text={t('Power limit')} variant="bold" />
                <Divider />
                <Grid container>
                    <Grid item xs={2} lg={1}>
                        <Field
                            component={ReduxFieldCheck}
                            disabled={disabled}
                            label=""
                            name="power_values.isChecked"
                            onChange={(e) => {
                                if (!e.target.checked) {
                                    changeInput('power_values.min', 0);
                                    changeInput('power_values.max', '');
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs>
                        <Field
                            append="kW"
                            component={ReduxFieldInput}
                            disabled={
                                !formValues['power_values'] ||
                                !formValues['power_values'].isChecked ||
                                disabled
                            }
                            label={t('Minimum power')}
                            name="power_values.min"
                            type="number"
                        />
                    </Grid>

                    <Grid item xs>
                        <Field
                            append="kW"
                            component={ReduxFieldInput}
                            disabled={
                                !formValues['power_values'] ||
                                !formValues['power_values'].isChecked ||
                                disabled
                            }
                            label={t('Maximum power')}
                            name="power_values.max"
                            type="number"
                        />
                    </Grid>
                </Grid>
            </TextInputContainer>
        </React.Fragment>
    );
};

Parameters.propTypes = {
    changeInput: PropTypes.func,
    disabled: PropTypes.bool,
    formValues: PropTypes.object,
};

export default Parameters;
