import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Avatar, IconButton } from 'sunwise-ui';

import { stringAvatar } from 'common/utils/helpers';

import { AVATAR_SIZE } from './constants';

const ProfileButton = ({ profileValues }) => {
    const profileName = `${get(profileValues, 'first_name', '')} ${get(
        profileValues,
        'last_name',
        ''
    )}`;
    return (
        <IconButton size="small">
            {get(profileValues, 'profile_image', '') ? (
                <Avatar
                    alt={profileName}
                    src={get(profileValues, 'profile_image', '')}
                    sx={{
                        height: AVATAR_SIZE,
                        width: AVATAR_SIZE,
                    }}
                />
            ) : (
                <Avatar
                    {...stringAvatar(profileName)}
                    sx={{
                        fontSize: '16px',
                        height: AVATAR_SIZE,
                        width: AVATAR_SIZE,
                    }}
                />
            )}
        </IconButton>
    );
};

ProfileButton.propTypes = { profileValues: PropTypes.object };

export default ProfileButton;
