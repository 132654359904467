import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_COVERAGES,
    DELETE_COVERAGES_FAILURE,
    DELETE_COVERAGES_SUCCESS,
    FETCH_COVERAGES,
    FETCH_COVERAGES_FAILURE,
    FETCH_COVERAGES_SUCCESS,
    INITIALIZE_FORM_COVERAGE_CONFIG,
    OPEN_COVERAGE_CONFING_FORM_MODAL,
    OPEN_COVERAGE_FORM_MODAL,
    RESET_COVERAGE_CONFIG_FORM,
    RESET_COVERAGE_FORM,
    SAVE_COVERAGE_CONFIG,
    SAVE_COVERAGE_CONFIG_FAILURE,
    SAVE_COVERAGE_CONFIG_SUCCESS,
    SAVE_COVERAGE,
    SAVE_COVERAGE_FAILURE,
    SAVE_COVERAGE_SUCCESS,
    SET_IS_NEW_COVERAGE_CONFIG_RECORD,
    SET_IS_NEW_COVERAGE_RECORD,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    deleteItem: {
        errors: [],
        isDeleting: false,
    },
    initialValuesCoverageConfigForm: {
        annual_increase: 0,
        amount: 0,
        description: '',
        id: null,
        insurance_product: null,
        tax_included: false,
        tax_type: 'percentaje_tax',
        tax_value: 0,
        type_cost: 0,
        value_type: '',
    },
    initialValuesCoverageForm: {
        description: '',
        id: null,
        name: '',
    },
    coverages: {
        data: [],
        errors: [],
        isFetching: false,
    },
    isNewCoverageConfigRecord: true,
    isNewCoverageRecord: true,
    isOpenCoverageConfigModal: false,
    isOpenCoverageFormModal: false,
    saveCoverage: {
        errors: [],
        isSaving: false,
    },
    saveCoverageConfig: {
        errors: [],
        isSaving: false,
    },
};

const configurationCoveragesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_COVERAGES]: (state) => {
            state.deleteItem.isDeleting = true;
        },
        [DELETE_COVERAGES_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                isDeleting: false,
                errors: action.payload,
            };
        },
        [DELETE_COVERAGES_SUCCESS]: (state) => {
            state.deleteItem.isDeleting = false;
        },
        [FETCH_COVERAGES]: (state) => {
            state.coverages.isFetching = true;
        },
        [FETCH_COVERAGES_FAILURE]: (state, action) => {
            state.coverages = {
                ...state.coverages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COVERAGES_SUCCESS]: (state, action) => {
            state.coverages = {
                ...state.coverages,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM_COVERAGE_CONFIG]: (state, action) => {
            state.initialValuesCoverageConfigForm = {
                ...state.initialValuesCoverageConfigForm,
                ...action.payload,
            };
        },
        [OPEN_COVERAGE_CONFING_FORM_MODAL]: (state, action) => {
            state.isOpenCoverageConfigModal = action.payload;
        },
        [OPEN_COVERAGE_FORM_MODAL]: (state, action) => {
            state.isOpenCoverageFormModal = action.payload;
        },
        [RESET_COVERAGE_CONFIG_FORM]: (state) => {
            state.initialValuesCoverageConfigForm =
                INITIAL_STATE.initialValuesCoverageConfigForm;
            state.saveCoverageConfig = INITIAL_STATE.saveCoverageConfig;
        },
        [RESET_COVERAGE_FORM]: (state) => {
            state.initialValuesCoverageForm =
                INITIAL_STATE.initialValuesCoverageForm;
            state.saveCoverage = INITIAL_STATE.saveCoverage;
        },
        [SAVE_COVERAGE_CONFIG]: (state) => {
            state.saveCoverageConfig.isSaving = true;
        },
        [SAVE_COVERAGE_CONFIG_FAILURE]: (state, action) => {
            state.saveCoverageConfig = {
                ...state.saveCoverageConfig,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_COVERAGE_CONFIG_SUCCESS]: (state) => {
            state.saveCoverageConfig.isSaving = false;
        },
        [SAVE_COVERAGE]: (state) => {
            state.saveCoverage.isSaving = true;
        },
        [SAVE_COVERAGE_FAILURE]: (state, action) => {
            state.saveCoverage = {
                ...state.saveCoverage,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_COVERAGE_SUCCESS]: (state) => {
            state.saveCoverage.isSaving = false;
        },
        [SET_IS_NEW_COVERAGE_CONFIG_RECORD]: (state, action) => {
            state.isNewCoverageConfigRecord = action.payload;
        },
        [SET_IS_NEW_COVERAGE_RECORD]: (state, action) => {
            state.isNewCoverageRecord = action.payload;
        },
    },
});

export const configurationCoveragesActions =
    configurationCoveragesSlice.actions;

export default configurationCoveragesSlice.reducer;
