import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValuesCoverageForm = createSelector(
    getModel,
    (model) => model.initialValuesCoverageForm
);

export const getIsOpenCoverageConfigModal = createSelector(
    getModel,
    (model) => model.isOpenCoverageConfigModal
);

export const getIsOpenCoverageFormModal = createSelector(
    getModel,
    (model) => model.isOpenCoverageFormModal
);

/* coverages */
export const getCoveragesModel = createSelector(
    getModel,
    (model) => model.coverages
);

export const getCoveragesData = createSelector(
    getCoveragesModel,
    (model) => model.data
);

export const getCoveragesErrors = createSelector(
    getCoveragesModel,
    (model) => model.errors
);

export const getCoveragesIsFetching = createSelector(
    getCoveragesModel,
    (model) => model.isFetching
);

/* coverage form */
export const getSaveCoverage = createSelector(
    getModel,
    (model) => model.saveCoverage
);

export const getErrorsSaveCoverage = createSelector(
    getSaveCoverage,
    (model) => model.errors
);

export const getIsSavingCoverage = createSelector(
    getSaveCoverage,
    (model) => model.isSaving
);

export const getIsNewCoverageRecord = createSelector(
    getModel,
    (model) => model.isNewCoverageRecord
);

/* coverage config */
export const getInitialValuesCoverageConfigForm = createSelector(
    getModel,
    (model) => model.initialValuesCoverageConfigForm
);

export const getSaveCoverageConfig = createSelector(
    getModel,
    (model) => model.saveCoverageConfig
);

export const getErrorsSaveCoverageConfig = createSelector(
    getSaveCoverageConfig,
    (model) => model.errors
);

export const getIsSavingCoverageConfig = createSelector(
    getSaveCoverageConfig,
    (model) => model.isSaving
);

export const getIsNewCoverageRecordConfig = createSelector(
    getModel,
    (model) => model.isNewCoverageRecord
);

export const getIsNewCoverageConfigRecord = createSelector(
    getModel,
    (model) => model.isNewCoverageConfigRecord
);
