import API from '..';
import { ContentTypeJSON } from 'common/utils/api';
const ENTITY = 'smart-documents';

export const createSmartDocument = (data) =>
    API.post(`/api/v1/proposals/${ENTITY}/`, { ...data });
export const deleteSmartDocument = (id) =>
    API.delete(`/api/v1/proposals/${ENTITY}/${id}/`);
export const duplicateSmartDocument = (data) =>
    API.post(`/api/v1/proposals/duplicate/${ENTITY}/`, { ...data });
export const getSmartDocument = (id) => API.get(`/api/v1/proposals/${ENTITY}/${id}/`);
export const getSmartDocuments = (proposalId) => API.get(`/api/v1/proposals/offer-${ENTITY}/${proposalId}`);
export const updateSmartDocument = (id, data) =>
    API.patch(`/api/v1/proposals/${ENTITY}/${id}/`, { ...data }, ContentTypeJSON);