import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const StyledFigure = styled('figure')`
    align-items: center;
    border-radius: 100%;
    display: flex;
    height: ${({ sizeFigure }) => sizeFigure && `${sizeFigure}px`};
    overflow: hidden;
    width: ${({ sizeFigure }) => sizeFigure && `${sizeFigure}px`};

    img {
        width: 100%;
    }
`;

const Figure = ({ className, src, sizeFigure = 66 }) => (
    <StyledFigure className={className} sizeFigure={sizeFigure}>
        <img alt="" src={src} />
    </StyledFigure>
);

Figure.propTypes = {
    className: PropTypes.string,
    sizeFigure: PropTypes.number,
    src: PropTypes.string,
};

export default Figure;
