import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from './actions';
import DataForm from './components/DataForm';
import * as selectors from './selectors';

const Container = ({ handleCloseModal, isOpenModal }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            onClose={() => handleCloseModal()}
            open={isOpenModal}
            size="sm"
            title={t('Restore password')}
        >
            <DataForm />
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    isOpenModal: selectors.getIsOpenModal,
});

const mapDispatchToProps = (dispatch) => ({
    handleCloseModal: () => dispatch(actions.openModal(false)),
});

Container.propTypes = {
    handleCloseModal: PropTypes.func,
    isOpenModal: PropTypes.bool,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Container));
