import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_COMPANY,
    FETCH_COMPANY_FAILURE,
    FETCH_COMPANY_SUCCESS,
    INITIALIZE_FORM,
    RESET,
    RESET_FORM,
    SAVE_COMPANY,
    SAVE_COMPANY_FAILURE,
    SAVE_COMPANY_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    fetchCompany: { data: {}, errors: [], isFetching: false },
    initialValues: { description: '', id: null, image: null, name: '' },
    saveCompany: { errors: [], isSaving: false },
};

const companyGeneralSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_COMPANY]: (state) => {
            state.fetchCompany.isFetching = true;
        },
        [FETCH_COMPANY_FAILURE]: (state, action) => {
            state.fetchCompany = {
                ...state.fetchCompany,
                isFetching: false,
                errors: action.payload,
            };
        },
        [FETCH_COMPANY_SUCCESS]: (state) => {
            state.fetchCompany.isFetching = false;
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveCompany.errors = [];
        },
        [SAVE_COMPANY]: (state) => {
            state.saveCompany.isSaving = true;
        },
        [SAVE_COMPANY_FAILURE]: (state, action) => {
            state.saveCompany = {
                ...state.saveCompany,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_COMPANY_SUCCESS]: (state) => {
            state.saveCompany.isSaving = false;
        },
    },
});

export const companyGeneralActions = companyGeneralSlice.actions;

export default companyGeneralSlice.reducer;
