import React from 'react';

import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, Grid, Link, Typography } from 'sunwise-ui';

import DateTooltip from './DateTooltip';

const ProposalItem = ({ createdAt, creditId, proposalId, proposalName }) => (
    <Card>
        <Card.Body>
            <Grid container>
                <Grid alignItems="center" display="flex" gap={1} item xs>
                    <Link
                        color="secondary"
                        component={RouterLink}
                        to={`/credits/${creditId}/preview/${proposalId}`}
                    >
                        <Typography fontWeight="bold" variant="body2">
                            {proposalName}
                        </Typography>
                    </Link>
                </Grid>
            </Grid>

            {createdAt && (
                <Box my={1} sx={{ width: 70 }}>
                    <DateTooltip createdAt={createdAt} />
                </Box>
            )}
        </Card.Body>
    </Card>
);

ProposalItem.propTypes = {
    createdAt: PropTypes.string,
    creditId: PropTypes.number,
    proposalId: PropTypes.number,
    proposalName: PropTypes.string,
};

export default ProposalItem;
