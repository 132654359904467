import i18next from 'i18next';
import is from 'is_js';
import { change } from 'redux-form';

import { changePasswordProfile } from 'common/api/users';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { logoutActions } from 'common/utils/session';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { NAME } from '../constants';
import { profileSecurityActions } from '../reducer';

export default (values) => (dispatch) => {
    const newValues = {
        confirm_new_password: values.confirm_password,
        new_password: values.new_password,
        old_password: values.old_password,
    };

    dispatch(profileSecurityActions[SAVE]());

    changePasswordProfile(newValues)
        .then(() => {
            dispatch(profileSecurityActions[SAVE_SUCCESS]());
            dispatch(change(`${NAME}/form`, 'old_password', null));
            dispatch(change(`${NAME}/form`, 'new_password', null));
            dispatch(change(`${NAME}/form`, 'confirm_password', null));
            showToast();

            const messages = [
                i18next.t(
                    'The password has been updated. For security reasons, the current session will be terminated'
                ),
            ];

            if (!is.empty(messages)) {
                const dataAlert = {
                    messages: messages,
                    onSuccess: () => {
                        dispatch(alerts.actions.close());
                        logoutActions();
                    },
                    textConfirm: i18next.t('Continue'),
                    title: i18next.t('Information'),
                    type: alerts.ALERT_TYPE_ONLY_CONFIRM,
                    variant: 'success',
                };

                dispatch(alerts.actions.show(dataAlert));
            }
        })
        .catch((error) => {
            dispatch(
                profileSecurityActions[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
