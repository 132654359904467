import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const CardFolderFooterWrapper = styled('div')`
    color: #848bab;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    line-height: 14px;
`;

const CardFolderFooter = ({ children }) => (
    <CardFolderFooterWrapper>{children}</CardFolderFooterWrapper>
);

CardFolderFooter.propTypes = {
    children: PropTypes.node,
};

export default CardFolderFooter;
