import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

/* Guardar productos */
export const getModelSaveProduct = createSelector(
    getModel,
    (model) => model.save
);

export const getSaveData = createSelector(
    getModelSaveProduct,
    (model) => model.data
);

export const getSaveProductErrors = createSelector(
    getModelSaveProduct,
    (model) => model.errors
);

export const getSaveProductIsFetching = createSelector(
    getModelSaveProduct,
    (model) => model.isSaving
);

/* Modal product status */
export const getProductModalIsOpen = createSelector(
    getModel,
    (model) => model.productModalIsOpen
);
