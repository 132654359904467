import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import {
    Map,
    Marker,
    InfoWindow,
    GoogleApiWrapper as MapContainer,
} from 'google-maps-react';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';

import { mapStyles } from 'common/utils/mapStyles';

const StyledMap = styled('div')`
    align-items: center;
    background-color: #c5c5c5;
    bottom: 0;
    display: flex;
    height: ${({ heightMap }) => `${heightMap}px`};
    justify-content: center;
    left: 0;
    position: relative;
    right: 0;
    top: 0;
`;

const _MapContainer = ({
    center,
    google,
    heightMap = 280,
    markers,
    onMapClicked,
    readOnly,
    showInformation = false,
    zoom = 20,
}) => {
    const draggable = !isUndefined(readOnly) ? !readOnly : false;
    const _center = center ? center : { lat: 20.9664281, lng: -89.6239336 };

    const [activeMarker, setActiveMarker] = useState(null);
    const [selectedKey, setSelectedKey] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState({ name: '' });

    const [showingInfoWindow, setShowingInfoWindow] = useState(false);

    const onMouseoverMarker = (props, markerr, event, key) => {
        if (key !== selectedKey) {
            setActiveMarker(markerr);
            setSelectedPlace(props);
            setShowingInfoWindow(true);
            setSelectedKey(key);
        }
    };

    return (
        <StyledMap heightMap={heightMap}>
            <Map
                center={_center}
                google={google}
                initialCenter={_center}
                mapType="satellite"
                onClick={onMapClicked}
                streetViewControl={false}
                styles={mapStyles}
                zoom={zoom ? zoom : 14}
            >
                {markers &&
                    markers.map((marker, key) => (
                        <Marker
                            contact_name={marker.contact_name}
                            draggable={draggable}
                            key={key}
                            icon={{
                                url: marker.icon
                                    ? marker.icon
                                    : 'https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png',
                                scaledSize: new google.maps.Size(32, 32),
                            }}
                            title={marker.title}
                            name={marker.name}
                            onClick={marker.onClick}
                            onDragend={marker.onDragend}
                            onMouseover={(props, markerr, event) =>
                                onMouseoverMarker(props, markerr, event, key)
                            }
                            position={{
                                lat: marker.lat,
                                lng: marker.lng,
                            }}
                        />
                    ))}

                {showInformation ? (
                    <InfoWindow
                        marker={activeMarker}
                        visible={showingInfoWindow}
                    >
                        <React.Fragment>
                            <h6>{selectedPlace.name}</h6>
                            <p>{selectedPlace.contact_name}</p>
                        </React.Fragment>
                    </InfoWindow>
                ) : null}
            </Map>
        </StyledMap>
    );
};

_MapContainer.propTypes = {
    center: PropTypes.object,
    google: PropTypes.object,
    heightMap: PropTypes.number,
    markers: PropTypes.array,
    onMapClicked: PropTypes.func,
    readOnly: PropTypes.bool,
    showInformation: PropTypes.bool,
    zoom: PropTypes.number,
};

export default MapContainer({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
})(_MapContainer);
