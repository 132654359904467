import { reset } from 'redux-form';

import { create, update } from 'common/api/financierProduct';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { handleCheckboxField } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_PRODUCT,
    SAVE_PRODUCT_FAILURE,
    SAVE_PRODUCT_SUCCESS,
} from '../actionTypes';
import { NAME } from '../constants';
import { financialCiBancoActions } from '../reducer';

import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

export default (values, callback) => (dispatch) => {
    let blind_objects = [];

    blind_objects.push('rate');

    if (
        values.parameters_commission_credit &&
        values.parameters_commission_credit.isChecked
    )
        blind_objects.push('commission_credit');

    if (values.parameters_hitch && values.parameters_hitch.isChecked)
        blind_objects.push('hitch');

    if (values.amount_values && values.amount_values.isChecked)
        blind_objects.push('amount_values');

    if (values.power_values && values.power_values.isChecked)
        blind_objects.push('power_values');

    const newValues = {
        accesories_list_must_have: '',
        accesories_list_optional: handleCheckboxField(
            values.accesories_list_optional
        ),
        amount_values:
            values.amount_values.min + ',' + values.amount_values.max,
        catalog_documents: handleCheckboxField(values.catalog_documents),
        description: values.description,
        inverters_list: values?.inverters_list?.toString(),
        name: values.name,
        panels_list: values?.panels_list?.toString(),
        parameters: JSON.stringify({
            blind_objects: blind_objects,
            commission_credit: values.parameters_commission_credit.value,
            hitch: values.parameters_hitch.value,
            is_commission_percentage:
                values.parameters_commission_credit.unit === 0 ? true : false,
            is_percentage: values.parameters_hitch.unit === 0 ? true : false,
            opening_commission_vat:
                values.parameters_commission_credit.opening_commission_vat,
            rate: values.parameters_rate.value,
            terms: handleCheckboxField(values.parameters.terms),
            tax: values.parameters.tax,
        }),
        power_values: values.power_values.min + ',' + values.power_values.max,
        rate_list: handleCheckboxField(values.rate_list),
        storage_equipment_required: values.storage_equipment_required,
        template: values.template,
        type: values.type,
        type_algorithm: values.type_algorithm,
        types_business_company: values.types_business_company,
    };

    if (values.id) newValues.id = values.id;

    const save = values.id
        ? () => update(newValues, values.id)
        : () => create(newValues);

    dispatch(financialCiBancoActions[SAVE_PRODUCT]());

    save()
        .then(() => {
            dispatch(financialCiBancoActions[SAVE_PRODUCT_SUCCESS]());
            dispatch(reset(`${NAME}/form`));
            dispatch(resetForm());
            dispatch(toggleModalProduct(false));
            showToast();
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                financialCiBancoActions[SAVE_PRODUCT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
