import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider } from 'sunwise-ui';

import { ReduxFieldInput } from 'common/components/form/redux';
import ShowErrors from 'common/components/ShowErrors';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';

const InstallerForm = ({
    errors,
    handleClickCloseForm,
    handleSubmit,
    installerId,
    isSaving,
    onSuccess,
    save,
}) => {
    const { t } = useTranslation();
    const disabled = false;
    const handleOnClickSubmit = (values) =>
        save({ ...values, installerId: installerId }, onSuccess);
    return (
        <form onSubmit={handleSubmit(handleOnClickSubmit)}>
            <Field
                component={ReduxFieldInput}
                disabled={disabled}
                label={t('Legal representative')}
                name="legal_representative"
                placeholder={t('Legal representative')}
            />

            <Field
                component={ReduxFieldInput}
                disabled={disabled}
                label={t('Tax ID')}
                name="rfc"
                placeholder={t('Tax ID')}
            />

            <Field
                component={ReduxFieldInput}
                disabled={disabled}
                label={t('Phone')}
                name="phone"
                placeholder={t('Phone')}
            />

            <Field
                component={ReduxFieldInput}
                disabled={disabled}
                label={t('Email')}
                name="email"
                placeholder={t('Email')}
            />

            <Field
                component={ReduxFieldInput}
                disabled={disabled}
                label={t('City')}
                name="city"
                placeholder={t('City')}
            />

            <ShowErrors errors={errors} />

            <Divider sx={{ mb: 2, mx: -2 }} />

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                <Button
                    color="secondary"
                    onClick={handleClickCloseForm}
                    variant="text"
                >
                    {t('Cancel')}
                </Button>

                {!disabled && (
                    <Button disabled={isSaving} type="submit">
                        {t('Save')}
                    </Button>
                )}
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getValuesInstallerDataForm,
    initialValues: selectors.getInitialValuesInstallerDataForm,
    isSaving: selectors.getUpdateInstallerIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    save: (values, onSuccess) =>
        dispatch(actions.updateInstallerData(values, onSuccess)),
});

InstallerForm.propTypes = {
    errors: PropTypes.object,
    handleClickCloseForm: PropTypes.func,
    handleSubmit: PropTypes.func,
    installerId: PropTypes.string,
    isSaving: PropTypes.bool,
    onSuccess: PropTypes.func,
    save: PropTypes.func,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form-update-installer`,
})(InstallerForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
