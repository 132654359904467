import is from 'is_js';
import {
    get,
    isArray,
    isEmpty,
    isEqual,
    isObject,
    isString,
    isUndefined,
    uniqBy,
    uniqWith,
} from 'lodash';

import {
    APPROVED_STATUS,
    CANCELLED_STATUS,
    CLOSED_STATUS,
    CONDITIONED_APPROVED_STATUS,
    ENABLED_STATUS,
    GRANTED_STATUS,
    INCOMPLETE_STATUS,
    PRE_APPROVED_STATUS,
    PREAPPROVED_STATUS,
    PREVIABILITY_STATUS,
    PROCESS_STATUS,
    REQUESTED_STATUS,
} from 'common/constants';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

const dataImageRegex =
    /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const parseExponentialNumber = (value) => {
    if (!value) return 0;
    if (!(value.toString().indexOf('e') !== -1)) return value;
    const parts = value.toString().split('e-');
    if (parts[1]) {
        if (parts[1] > 100) return 0;
        return value.toFixed(parts[1]);
    }
    return value;
};

export const getPlaceholderImg = (size = 100, text = 'Sin imagen') =>
    `https://placehold.jp/${size}x${size}.png?text=${text}`;

export const addDefaultSrc = (e, size) =>
    (e.target.src = getPlaceholderImg(size));

export const arraytoDictionary = (array, key) => {
    if (!Array.isArray(array)) return {};
    return array.reduce(
        (acc, current) => ({ ...acc, [current[key]]: current }),
        {}
    );
};

export const calculateFinalCost = (cost, margin) => {
    const value = parseFloat(cost) / (1 - parseFloat(margin) / 100) || 0;
    return value.toFixed(2);
};

export const calculateMargin = (finalCost, cost) => {
    const value =
        (100 * (parseFloat(finalCost) - parseFloat(cost))) /
            parseFloat(finalCost) || 0;
    return value.toFixed(2);
};

export const calculateRangesPriceFrom = (lastTo) => parseInt(lastTo) + 1;

export const calculateRangesPriceTo = (from) => parseInt(from);

export const calculateRangesPriceToByNextRange = (nextRange) =>
    parseInt(nextRange.price_from) - 1;

export const cleanJSON = (str) => str.replace(/(\r\n|\n|\r|\t)/gm, ' ');

export const getCurrencyLabel = (currency) =>
    parseInt(currency) === 1 ? 'USD' : 'MXN';

export const getDefaultName = ({ arrayItems, preffix, projectName }) => {
    let name = '';
    const baseName = `${preffix} - ${projectName}`;
    const items = arrayItems.filter((item) => item.name.includes(baseName));
    for (let i = 0; i <= items.length; i++) {
        const test = arrayItems.filter((item) =>
            i === 0
                ? item.name === baseName
                : item.name === `${baseName} (${i})`
        );
        if (test.length === 0) {
            name = i === 0 ? baseName : `${baseName} (${i})`;
            break;
        }
    }
    return name;
};

export const getFileExtensionByPath = (url) => {
    if (!url) return '';
    if (url.indexOf('.', url.lastIndexOf('/') + 1) === -1) return '';
    return url.split(/[#?]/)[0].split('.').pop().trim();
};
const stringToColor = (string) => {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
};

export const getHiddenColumns = (tableSettings) => {
    return tableSettings
        .filter((item) => !item.is_active)
        .map(({ name }) => name);
};

export const getOrderedColumns = (tableSettings) => {
    return tableSettings.reduce((acc, col) => {
        return {
            ...acc,
            [col.name]: col.order,
        };
    }, {});
};

export const getIsLocalRedirect = (path) => !/^http/i.test(path);

export const getNumberFromString = (string) => {
    const number = string.match(/\d+/g);
    if (number) {
        return parseInt(number);
    }
    return 0;
};

export const getStatusKey = (statusName) => {
    switch (statusName) {
        case REQUESTED_STATUS:
            return 0;
        case PROCESS_STATUS:
            return 1;
        case APPROVED_STATUS:
            return 3;
        case CLOSED_STATUS:
            return 4;
        case CANCELLED_STATUS:
            return 6;
        case PREAPPROVED_STATUS:
            return 7;
        case INCOMPLETE_STATUS:
            return 8;
        case CONDITIONED_APPROVED_STATUS:
            return 9;
        default:
            return statusName;
    }
};

export const getStatusText = (status, isAlliance) => {
    if (isAlliance) {
        switch (status) {
            case APPROVED_STATUS:
                return PRE_APPROVED_STATUS;
            case CLOSED_STATUS:
                return ENABLED_STATUS;
            default:
                return status;
        }
    } else {
        switch (status) {
            case CLOSED_STATUS:
                return GRANTED_STATUS;
            case PREAPPROVED_STATUS:
                return PREVIABILITY_STATUS;
            default:
                return status;
        }
    }
};

export const getStatusProposalLabel = (hasApproved) => {
    if (hasApproved) return 'Aprobada';
    return 'Generada';
};

export const handleFileURL = (url, preffix) => {
    const pattern = /^((https):\/\/)/;
    return pattern.test(url) ? url : `${preffix}${url}`;
};

export const handleTermsOnCSVFile = (data, defaultTerms) => {
    if (!data) return;
    const csvData = data.data;
    const selectedTerms = {};
    let termsOnDocument = [];
    for (let i = 1; i < csvData[0].length; i++) {
        if (csvData[0][i] !== '') {
            const term = defaultTerms.find(
                (item) =>
                    parseInt(item.value) == getNumberFromString(csvData[0][i])
            );
            if (term) termsOnDocument.push(term);
        }
    }
    const nonDuplidatedAndSortData = mergeTermsData(
        defaultTerms,
        termsOnDocument
    );
    termsOnDocument.forEach((item) => (selectedTerms[item.value] = true));
    return {
        termsList: nonDuplidatedAndSortData,
        selectedTerms: selectedTerms,
    };
};

export const isCompressedFileSupported = (pathName) =>
    ['zip', 'rar'].includes(getFileExtensionByPath(pathName));

export const isFile = (value) =>
    is.array(value) &&
    is.not.empty(value) &&
    Object.prototype.toString.call(value[0]) === '[object File]';

export const isImageBase64 = (string) => string.match(dataImageRegex);

export const isImageFileSupported = (pathName) =>
    ['png', 'jpeg', 'jpg'].includes(getFileExtensionByPath(pathName));

export const isImageUrl = (string) => string.match(urlRegex);

export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const itemsToSelectWithoutDuplicates = (
    id,
    formValues,
    itemsToSelect,
    groupName = 'items'
) => {
    const selected = formValues[groupName].map((item) => item.item);
    return itemsToSelect.filter(
        (item) =>
            selected.indexOf(item.value) === -1 ||
            item.value === id ||
            item.value === 'undefined' ||
            item.value === ''
    );
};

export const mergeTermsData = (defaultTerms = [], termsOnDocument = []) =>
    uniqBy([...defaultTerms, ...termsOnDocument], 'value').sort(
        (a, b) => parseInt(a.value) - parseInt(b.value)
    );

export const nextItemsToSelect = (itemsSelected = [], itemsToSelect) =>
    itemsToSelect.filter(
        (itemToSelect) =>
            itemToSelect.value !== '' &&
            itemsSelected
                .map((itemSelected) => itemSelected.item)
                .indexOf(itemToSelect.value) < 0
    );

export const numberFormat = (value, options) => {
    const newValue = parseExponentialNumber(value);
    const {
        currency = 'MXN',
        decimals = 2,
        locale = 'es-MX',
        unit = '',
        style,
    } = options;
    let formatted = newValue;
    let unitText = '';

    switch (style) {
        case 'currency':
            formatted = new Intl.NumberFormat(locale, {
                style,
                currency,
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            }).format(newValue);
            break;
        case 'decimal':
            formatted = new Intl.NumberFormat(locale, {
                style,
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals,
            }).format(newValue);
            unitText = unit;
            break;
        default:
            formatted = newValue;
            break;
    }

    if (isEmpty(unitText)) return formatted;
    return `${formatted} ${unitText}`;
};

export const pluralizeText = (number, text, addText = 's') =>
    number !== 1 ? `${text}${addText}` : text;

export const removeDuplicateObjects = (array) => {
    return uniqWith(array, isEqual);
};

export const showReponseErrorsAsAlert = (dispatch, response) => {
    let errors = [];
    if (isUndefined(response)) return false;
    const responseErrors = get(response, 'data.errors', null);
    if (responseErrors) {
        if (isArray(responseErrors))
            errors = responseErrors.map((error) => error);
        else if (isString(responseErrors)) errors = [responseErrors];
        else if (isObject(responseErrors)) {
            Object.keys(responseErrors).forEach((key) => {
                if (isArray(responseErrors[key])) {
                    errors = [...errors, `${key} ${responseErrors[key][0]}`];
                }
                if (isString(responseErrors[key])) {
                    errors = [...errors, responseErrors[key]];
                }
            });
        }
    }
    if (!isEmpty(errors)) {
        const dataAlert = {
            confirmText: 'Aceptar',
            messages: errors,
            title: 'Errores',
            type: alerts.ALERT_TYPE_ALERT,
        };
        dispatch(alerts.actions.show(dataAlert));
    } else {
        showToast({
            type: 'error',
            body: 'Ocurrio un error durante el proceso',
        });
    }
};

export const stringAvatar = (name) => {
    if (name.split(' ').length === 1)
        return {
            sx: { bgcolor: stringToColor(name) },
            children: `${name.split(' ')[0][0]}`,
        };
    if (name.split(' ').length > 1)
        return {
            sx: { bgcolor: stringToColor(name) },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    return {
        sx: { bgcolor: '#000000' },
        children: `--`,
    };
};

export const truncateString = (text, maxLength) => {
    return text.length > maxLength
        ? text.substring(0, maxLength) + '...'
        : text;
};

export const fullNameBuild = ({ firstName, lastName, secondSurname }) => {
    let fullName = '';
    if (firstName) fullName += firstName;
    if (lastName) {
        if (fullName.length > 0) fullName += ' ';
        fullName += lastName;
    }
    if (secondSurname) {
        if (fullName.length > 0) fullName += ' ';
        fullName += secondSurname;
    }
    return fullName;
};

export const handleCheckboxField = (values) => {
    if (!values) return '';

    const object_keys = Object.keys(values);
    let temp = [];

    object_keys.forEach((item) => {
        if (values[item] === true) temp.push(item);
    });

    return temp.toString();
};

export const hasValue = (object, name) =>
    Object.prototype.hasOwnProperty.call(object, name) &&
    !isEmpty(object[name]);
