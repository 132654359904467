import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import approveCredit from './approveCredit';

export default (id, callback) => (dispatch) => {
    if (!id) return;

    const dataAlert = {
        messages: [
            i18next.t(
                'Once the request is approved, the changes can no longer be reverted'
            ),
        ],
        onSuccess: () => dispatch(approveCredit(id, callback)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
