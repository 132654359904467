import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Grid } from 'sunwise-ui';

import { ReduxFieldFile, ReduxFieldSelect } from 'common/components/form/redux';
import ShowErrors from 'common/components/ShowErrors';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const ContainerForm = styled('div')`
    .container-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
        margin-top: 50px;
    }

    .container-buttons {
        margin-top: 50px;
    }
`;

const Form = ({
    documentsTypeToSelect,
    errors,
    formValues,
    handleClickCancel,
    handleSubmit,
    isUploading,
    save,
}) => {
    const { t } = useTranslation();

    return (
        <ContainerForm>
            <form>
                <Grid container>
                    <Grid item xs>
                        <Field
                            component={ReduxFieldSelect}
                            disabled={
                                formValues.id !== null &&
                                typeof formValues.id !== 'undefined'
                            }
                            name="catalog_document"
                            options={documentsTypeToSelect}
                            placeholder={t('Document type')}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs>
                        <Field
                            component={ReduxFieldFile}
                            label={t('Attach file')}
                            name="file"
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs>
                        <ShowErrors errors={errors} />
                    </Grid>
                </Grid>

                <Divider sx={{ mb: 2, mx: -2 }} />

                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                    <Button
                        color="secondary"
                        onClick={handleClickCancel}
                        type="button"
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>

                    <Button
                        disabled={isUploading}
                        onClick={handleSubmit(save)}
                        type="submit"
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </form>
        </ContainerForm>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrors,
    formValues: (state) => getFormValues(`${NAME}/upload`)(state) || {},
    initialValues: selectors.getInitialValues,
    isUploading: selectors.getIsUploading,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values) => dispatch(actions.uploadFile(values)),
});

Form.propTypes = {
    documentsTypeToSelect: PropTypes.array,
    errors: PropTypes.object,
    formValues: PropTypes.object,
    handleClickCancel: PropTypes.func,
    handleSubmit: PropTypes.func,
    isUploading: PropTypes.bool,
    save: PropTypes.func,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/upload`,
    validate,
})(Form);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
