import isEmpty from 'lodash/isEmpty';

import fetchInstallers from './fetchInstallers';
import openAssignProductModal from './openAssignProductModal';
import resetAssignProductForm from './resetAssignProductForm';

export default (filterData = {}) =>
    (dispatch) => {
        dispatch(resetAssignProductForm());
        if (!isEmpty(filterData)) dispatch(fetchInstallers(filterData));
        dispatch(openAssignProductModal(false));
    };
