export default {
    control: {
        backgroundColor: '#fdf6d9',
        padding: '8.5px 14px',
    },
    input: {
        padding: '8.5px 14px',
        margin: 0,
        borderRadius: '8px',
        boxShadow: 'none',
        outline: 'none',
        border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
            height: '100px',
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
        },
        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    },
};
