import i18next from 'i18next';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import {
    ALLIANCE_DOCUMENTS_CATEGORY,
    CONTRACT_TYPE,
    DOCUMENTS_TYPE,
    REQUEST_FORM_TYPE,
} from 'common/constants';
import { numberFormat } from 'common/utils/helpers';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getInitialValuesInstallerDataForm = createSelector(
    getModel,
    (model) => model.initialValuesInstallerDataForm
);

export const getValuesInstallerDataForm = createSelector(
    (state) => getFormValues(`${NAME}/form-update-installer`)(state),
    (values) => values || {}
);

export const initialValuesProductForm = createSelector(
    getModel,
    (model) => model.initialValuesProductForm
);

export const getInitialValuesAssignUserForm = createSelector(
    getModel,
    (model) => model.initialValuesAssignUserForm
);

export const getInitialValuesUploadForm = createSelector(
    getModel,
    (model) => model.initialValuesUploadForm
);

export const initialValuesRejectForm = createSelector(
    getModel,
    (model) => model.initialValuesRejectForm
);

export const initialValuesUploadForm = createSelector(
    getModel,
    (model) => model.initialValuesUploadForm
);

export const getProjectsSalesStage = createSelector(
    getModel,
    (model) => model.projectsSalesStage
);

export const getProjectsSalesStageData = createSelector(
    getProjectsSalesStage,
    (model) => {
        let projectsKw = [`${i18next.t('Total powers')} (kW)`];
        let projectsNumber = [i18next.t('Number of projects')];
        let projectsTotal = [i18next.t('Total value')];

        const columns = model.data.map((data) => {
            projectsNumber.push(
                numberFormat(data.projects_number, {
                    style: 'decimal',
                })
            );
            projectsTotal.push(
                numberFormat(data.projects_total, {
                    style: 'currency',
                }) +
                    ' ' +
                    data.currency
            );
            projectsKw.push(
                numberFormat(data.projects_kw, {
                    style: 'decimal',
                    unit: 'kW',
                })
            );

            return { color: data.color, title: data.status };
        });

        const rows = { projectsNumber, projectsTotal, projectsKw };

        return { columns, rows };
    }
);

export const getTotalProjectsIssued = createSelector(
    getProjectsSalesStage,
    (model) => {
        const total = model.data.reduce(
            (valorAnterior, valorActual) =>
                valorAnterior + valorActual.projects_number,
            0
        );
        return numberFormat(total, { style: 'currency' });
    }
);

export const getTotalMoneyProjects = createSelector(
    getProjectsSalesStage,
    (model) => {
        const total = model.data.reduce(
            (valorAnterior, valorActual) =>
                valorAnterior + valorActual.projects_total,
            0
        );
        return numberFormat(total, { style: 'currency' });
    }
);

export const getTotalKwProjects = createSelector(
    getProjectsSalesStage,
    (model) => {
        const total = model.data.reduce(
            (valorAnterior, valorActual) =>
                valorAnterior + valorActual.projects_kw,
            0
        );
        return numberFormat(total, { style: 'currency' });
    }
);

export const getProjectsSalesStageIsLoading = createSelector(
    getProjectsSalesStage,
    (model) => model.isLoading
);

export const getProjectsSalesStageErrors = createSelector(
    getProjectsSalesStage,
    (model) => model.error
);

export const getProposalsTotals = createSelector(
    getModel,
    (model) => model.proposalsTotals
);

export const getProposalsTotalsData = createSelector(
    getProposalsTotals,
    (model) => model.data
);

export const getProposalsTotalsIsLoading = createSelector(
    getProposalsTotals,
    (model) => model.isLoading
);

export const getProposalsTotalsErrors = createSelector(
    getProposalsTotals,
    (model) => model.error
);

export const getFetchInstaller = createSelector(
    getModel,
    (model) => model.fetchInstaller
);

export const getDataFetchInstaller = createSelector(
    getFetchInstaller,
    (fetchInstaller) => fetchInstaller.data || {}
);

/* required documents list*/
export const getRequiredDocumentsListModel = createSelector(
    getModel,
    (model) => model.requiredDocumentsList
);

export const getRequiredDocumentsListData = createSelector(
    getRequiredDocumentsListModel,
    (model) => model.data
);

export const getRequiredDocumentsListErrors = createSelector(
    getRequiredDocumentsListModel,
    (model) => model.errors
);

export const getRequiredDocumentsListIsFetching = createSelector(
    getRequiredDocumentsListModel,
    (model) => model.isFetching
);

/* Assigned user */
export const getAssignedUserModel = createSelector(
    getModel,
    (model) => model.assignedUser
);

export const getAssignedUserData = createSelector(
    getAssignedUserModel,
    (model) => model.data
);

export const getAssignedUserErrors = createSelector(
    getAssignedUserModel,
    (model) => model.errors
);

export const getAssignedUserIsFetching = createSelector(
    getAssignedUserModel,
    (model) => model.isFetching
);

/* Contract documents */
export const getContractDocumentsModel = createSelector(
    getModel,
    (model) => model.contractDocuments
);

export const getContractDocumentsList = createSelector(
    getRequiredDocumentsListData,
    (model) =>
        model.filter(
            (item) =>
                item.category === ALLIANCE_DOCUMENTS_CATEGORY &&
                item.type === CONTRACT_TYPE
        )
);

export const getContractDocumentsData = createSelector(
    getContractDocumentsModel,
    getContractDocumentsList,
    (model, contractDocuments) => {
        const contractDocumentsFilter = contractDocuments.filter(
            (item) =>
                item.financier_alliance_installer_catalog_document !== null
        );

        const contractDocumentsIds = contractDocumentsFilter.map(
            (item) => item.financier_alliance_installer_catalog_document.id
        );

        const files = model.data.filter(
            (item) => !contractDocumentsIds.includes(item.id)
        );

        return files;
    }
);

export const getContractDocumentsErrors = createSelector(
    getContractDocumentsModel,
    (model) => model.errors
);

export const getContractDocumentsIsFetching = createSelector(
    getContractDocumentsModel,
    (model) => model.isFetching
);

/* Request form documents */
export const getRequestFormDocumentsModel = createSelector(
    getModel,
    (model) => model.requestFormDocuments
);

export const getRequestFormDocumentsList = createSelector(
    getRequiredDocumentsListData,
    (model) =>
        model.filter(
            (item) =>
                item.category === ALLIANCE_DOCUMENTS_CATEGORY &&
                item.type === REQUEST_FORM_TYPE
        )
);

export const getRequestFormDocumentsData = createSelector(
    getRequestFormDocumentsModel,
    getRequestFormDocumentsList,
    (model, requestFormDocuments) => {
        const requestFormDocumentsFilter = requestFormDocuments.filter(
            (item) =>
                item.financier_alliance_installer_catalog_document !== null
        );

        const requestFormDocumentsIds = requestFormDocumentsFilter.map(
            (item) => item.financier_alliance_installer_catalog_document.id
        );

        const files = model.data.filter(
            (item) => !requestFormDocumentsIds.includes(item.id)
        );

        return files;
    }
);

export const getRequestFormDocumentsErrors = createSelector(
    getRequestFormDocumentsModel,
    (model) => model.errors
);

export const getRequestFormDocumentsIsFetching = createSelector(
    getRequestFormDocumentsModel,
    (model) => model.isFetching
);

/* Documents */
export const getDocumentsModel = createSelector(
    getModel,
    (model) => model.documents
);

export const getDocumentsList = createSelector(
    getRequiredDocumentsListData,
    (model) =>
        model.filter(
            (item) =>
                item.category === ALLIANCE_DOCUMENTS_CATEGORY &&
                item.type === DOCUMENTS_TYPE
        )
);

export const getDocumentsData = createSelector(
    getDocumentsModel,
    getDocumentsList,
    (model, documents) => {
        const documentsFilter = documents.filter(
            (item) =>
                item.financier_alliance_installer_catalog_document !== null
        );

        const documentsIds = documentsFilter.map(
            (item) => item.financier_alliance_installer_catalog_document.id
        );

        const files = model.data.filter(
            (item) => !documentsIds.includes(item.id)
        );

        return files;
    }
);

export const getDocumentsErrors = createSelector(
    getDocumentsModel,
    (model) => model.errors
);

export const getDocumentsIsFetching = createSelector(
    getDocumentsModel,
    (model) => model.isFetching
);

/* Financier-Installer */
export const getFinancierInstallerModel = createSelector(
    getModel,
    (model) => model.financierInstaller
);

export const getFinancierInstallerData = createSelector(
    getFinancierInstallerModel,
    (model) => model.data
);

export const getFinancierInstallerErrors = createSelector(
    getFinancierInstallerModel,
    (model) => model.errors
);

export const getFinancierInstallerIsFetching = createSelector(
    getFinancierInstallerModel,
    (model) => model.isFetching
);

/* Save Documents */
export const getSaveDocument = createSelector(
    getModel,
    (model) => model.saveDocument
);

export const getSaveDocumentIsSaving = createSelector(
    getSaveDocument,
    (model) => model.isSaving
);

/* Products list */
export const getProductsListModel = createSelector(
    getModel,
    (model) => model.productsList
);

export const getProductsListData = createSelector(
    getProductsListModel,
    (model) => model.data
);

export const getProductsListErrors = createSelector(
    getProductsListModel,
    (model) => model.errors
);

export const getProductsListIsFetching = createSelector(
    getProductsListModel,
    (model) => model.isFetching
);

/* Selected products */
export const getSelectedProductsModel = createSelector(
    getModel,
    (model) => model.selectedProducts
);

export const getSelectedProductsData = createSelector(
    getSelectedProductsModel,
    (model) => model.data
);

export const getSelectedProductsErrors = createSelector(
    getSelectedProductsModel,
    (model) => model.errors
);

export const getSelectedProductsIsFetching = createSelector(
    getSelectedProductsModel,
    (model) => model.isFetching
);

/* Save selected products */
export const getSaveSelectedProductsModel = createSelector(
    getModel,
    (model) => model.saveSelectedProducts
);

export const getSaveSelectedProductsData = createSelector(
    getSaveSelectedProductsModel,
    (model) => model.data
);

export const getSaveSelectedProductsErrors = createSelector(
    getSaveSelectedProductsModel,
    (model) => model.errors
);

export const getSaveSelectedProductsIsFetching = createSelector(
    getSaveSelectedProductsModel,
    (model) => model.isFetching
);

/*Upload file*/
export const getUploadingFile = createSelector(
    getModel,
    (model) => model.fileUploaded
);

export const isFileUploading = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.isUploading
);

export const getUploadedFileData = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.data || []
);

export const getUploadedFileErrors = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.errors
);

export const getContacts = createSelector(getModel, (model) =>
    model.fetchInstaller &&
    model.fetchInstaller.data &&
    model.fetchInstaller.data.company_users
        ? model.fetchInstaller.data.company_users
        : []
);

export const getUpdateInstaller = createSelector(
    getModel,
    (model) => model.updateInstaller
);

export const getUpdateInstallerData = createSelector(
    getUpdateInstaller,
    (model) => model.data
);

export const getUpdateInstallerErrors = createSelector(
    getUpdateInstaller,
    (model) => model.errors
);

export const getUpdateInstallerIsSaving = createSelector(
    getUpdateInstaller,
    (model) => model.isSaving
);

export const getCompanyUsersModel = createSelector(
    getModel,
    (model) => model.companyUsers
);

export const getCompanyUsersData = createSelector(
    getCompanyUsersModel,
    (model) => model.data
);

export const getCompanyUsersErrors = createSelector(
    getCompanyUsersModel,
    (model) => model.errors
);

export const getCompanyUsersIsFetching = createSelector(
    getCompanyUsersModel,
    (model) => model.isFetching
);
export const getDeleteDocument = createSelector(
    getModel,
    (model) => model.deleteDocument
);

export const getIsDeletingDocument = createSelector(
    getDeleteDocument,
    (model) => model.isDeleting
);

export const getDeleteDocumentErrors = createSelector(
    getDeleteDocument,
    (model) => model.errors
);
