import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CustomPagination, DataGrid } from 'sunwise-ui';

import * as selectors from '../../selectors';

import CustomTemplateColumns from './CustomTemplateColumns';

const CustomTemplatesTab = ({
    clonePages,
    customTemplate,
    handleSelectPage,
    isFetchingCustomTemplate,
}) => {
    const filteredItems = clonePages.filter(
        (item) => item.pages >= 1 && item?.language === customTemplate?.language
    );

    return (
        <DataGrid
            autoHeight
            columns={CustomTemplateColumns()}
            components={{
                LoadingOverlay: LinearProgress,
                Pagination: CustomPagination,
            }}
            disableColumnMenu
            getRowHeight={() => 'auto'}
            initialState={{
                pagination: {
                    pageSize: 20,
                },
            }}
            loading={isFetchingCustomTemplate}
            pagination
            rows={filteredItems}
            onSelectionModelChange={(newSelectionModel) => {
                const selectedIDs = new Set(newSelectionModel);
                const selectedRowData = filteredItems.filter((row) =>
                    selectedIDs.has(row.id.toString())
                );

                if (selectedRowData[0]) {
                    handleSelectPage({
                        id: selectedRowData[0]?.id,
                        pages: selectedRowData[0]?.pages,
                    });
                }
            }}
        />
    );
};

const mapStateToProps = createStructuredSelector({
    clonePages: selectors.getfetchTemplateCatalogsData,
    customTemplate: selectors.getDatafetchTemplate,
    isFetchingCustomTemplate: selectors.getIsFetchingCustomTemplate,
});

CustomTemplatesTab.propTypes = {
    clonePages: PropTypes.array,
    customTemplate: PropTypes.object,
    handleSelectPage: PropTypes.func,
    isFetchingCustomTemplate: PropTypes.bool,
};

export default connect(mapStateToProps, null)(CustomTemplatesTab);
