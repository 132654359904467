import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import { Box, Button, Card, Divider } from 'sunwise-ui';

import CheckListItem from 'common/components/form/CheckListItem';

const SelectDocuemntsForm = ({
    callback,
    documents,
    handleSubmit,
    isFetching,
    moduleName,
    onSave,
}) => {
    const { t } = useTranslation();
    const handleOnClickSave = (values) => onSave(values, callback);
    if (isFetching) return <React.Fragment>{t('Loading')}</React.Fragment>;

    if (documents.length === 0)
        return (
            <React.Fragment>
                {t('There are no more documents to select')}
            </React.Fragment>
        );

    return (
        <form form={`${moduleName}/documents-card-form`}>
            <Card>
                <Field
                    component={CheckListItem}
                    items={documents}
                    name="catalog_documents"
                    prefixId="catalog_documents"
                />
            </Card>

            <Divider sx={{ mb: 2, mx: -2 }} />

            <Box sx={{ textAlign: 'right' }}>
                <Button onClick={handleSubmit(handleOnClickSave)}>
                    {t('Save')}
                </Button>
            </Box>
        </form>
    );
};

SelectDocuemntsForm.propTypes = {
    callback: PropTypes.func,
    documents: PropTypes.array,
    handleSubmit: PropTypes.func,
    isFetching: PropTypes.bool,
    moduleName: PropTypes.string,
    onSave: PropTypes.func,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `documents/documents-card-form`,
})(SelectDocuemntsForm);

export default FormContainer;
