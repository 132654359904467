import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import {
    CONTRACT_TYPE,
    DOCUMENTS_TYPE,
    INSURANCE_DOCUMENTS_TYPE,
    PRODUCT_DOCUMENTS_CATEGORY,
    REQUEST_FORM_TYPE,
} from 'common/constants';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getLocationInitialValues = createSelector(
    getModel,
    (model) => model.locationInitialValues
);

export const getLocationValues = createSelector(
    (state) => getFormValues(`${NAME}/location-form`)(state),
    (values) => values || {}
);

/* Producto por ID */

export const getCreditApplicationModel = createSelector(
    getModel,
    (model) => model.creditApplication
);

export const getCreditApplicationData = createSelector(
    getCreditApplicationModel,
    (model) => model.data
);

export const getCreditApplicationErrors = createSelector(
    getCreditApplicationModel,
    (model) => model.errors
);

export const getCreditApplicationIsFetching = createSelector(
    getCreditApplicationModel,
    (model) => model.isFetching
);

/* Financial Data */

export const getFinancialModel = createSelector(
    getModel,
    (model) => model.financial
);
export const getFinancialFormattedData = createSelector(
    getFinancialModel,
    (financial) => {
        const financialDataDictionary = financial.data.reduce(
            (acc, current) => {
                let tempObject = { ...acc };
                if (current.period === null) {
                    if (!tempObject.total) tempObject.total = {};
                    tempObject.total[
                        current.key
                    ] = `${current.formatted_value}`;
                } else {
                    if (!tempObject[current.period])
                        tempObject[current.period] = {};
                    tempObject[current.period][
                        current.key
                    ] = `${current.formatted_value} ${current.unit}`;
                }

                return tempObject;
            },
            {}
        );
        return Object.keys(financialDataDictionary).map((key) => ({
            ...financialDataDictionary[key],
            period: key,
        }));
    }
);

export const getFinancialErrors = createSelector(
    getFinancialModel,
    (financial) => financial.errors
);

export const getFinancialIsFetching = createSelector(
    getFinancialModel,
    (financial) => financial.isFetching
);

/* Required documents list*/

export const getRequiredDocumentsListModel = createSelector(
    getModel,
    (model) => model.requiredDocumentsList
);

export const getRequiredDocumentsListData = createSelector(
    getRequiredDocumentsListModel,
    (model) => model.data
);

export const getRequiredDocumentsListErrors = createSelector(
    getRequiredDocumentsListModel,
    (model) => model.errors
);

export const getRequiredDocumentsListIsFetching = createSelector(
    getRequiredDocumentsListModel,
    (model) => model.isFetching
);

export const getConsumptionPdfsModel = createSelector(
    getModel,
    (model) => model.fetchConsumptionPdfs
);

export const getConsumptionPdfsData = createSelector(
    getConsumptionPdfsModel,
    (model) => model.data
);

export const getIsFetchingConsumptionPdfs = createSelector(
    getConsumptionPdfsModel,
    (model) => model.isFetching
);

/* Contract documents */
export const getContractDocumentsModel = createSelector(
    getModel,
    (model) => model.contractDocuments
);

export const getContractDocumentsList = createSelector(
    getRequiredDocumentsListData,
    (model) =>
        model.filter(
            (item) =>
                item.category === PRODUCT_DOCUMENTS_CATEGORY &&
                item.type === CONTRACT_TYPE
        )
);

export const getContractDocumentsData = createSelector(
    getContractDocumentsModel,
    getContractDocumentsList,
    (model, contractDocuments) => {
        const contractDocumentsFilter = contractDocuments.filter(
            (item) => item.catalog_document !== null
        );

        const contractDocumentsIds = contractDocumentsFilter.map(
            (item) => item.catalog_document
        );

        const files = model.data.filter(
            (item) => !contractDocumentsIds.includes(item.id)
        );

        return files;
    }
);

export const getContractDocumentsErrors = createSelector(
    getContractDocumentsModel,
    (model) => model.errors
);

export const getContractDocumentsIsFetching = createSelector(
    getContractDocumentsModel,
    (model) => model.isFetching
);

/* Request form documents */
export const getRequestFormDocumentsModel = createSelector(
    getModel,
    (model) => model.requestFormDocuments
);

export const getRequestFormDocumentsList = createSelector(
    getRequiredDocumentsListData,
    (model) =>
        model.filter(
            (item) =>
                item.category === PRODUCT_DOCUMENTS_CATEGORY &&
                item.type === REQUEST_FORM_TYPE
        )
);

export const getRequestFormDocumentsData = createSelector(
    getRequestFormDocumentsModel,
    getRequestFormDocumentsList,
    (model, requestFormDocuments) => {
        const requestFormDocumentsFilter = requestFormDocuments.filter(
            (item) => item.catalog_document !== null
        );

        const requestFormDocumentsIds = requestFormDocumentsFilter.map(
            (item) => item.catalog_document
        );

        const files = model.data.filter(
            (item) => !requestFormDocumentsIds.includes(item.id)
        );

        return files;
    }
);

export const getRequestFormDocumentsErrors = createSelector(
    getRequestFormDocumentsModel,
    (model) => model.errors
);

export const getRequestFormDocumentsIsFetching = createSelector(
    getRequestFormDocumentsModel,
    (model) => model.isFetching
);

/* Insurance form documents */
export const getInsuranceDocumentsModel = createSelector(
    getModel,
    (model) => model.insuranceDocuments
);

export const getInsuranceDocumentsList = createSelector(
    getRequiredDocumentsListData,
    (model) =>
        model.filter(
            (item) =>
                item.category === PRODUCT_DOCUMENTS_CATEGORY &&
                item.type === INSURANCE_DOCUMENTS_TYPE
        )
);

export const getInsuranceDocumentsData = createSelector(
    getInsuranceDocumentsModel,
    getInsuranceDocumentsList,
    (model, insuranceDocuments) => {
        const insuranceDocumentsFilter = insuranceDocuments.filter(
            (item) => item.catalog_document !== null
        );

        const insuranceDocumentsIds = insuranceDocumentsFilter.map(
            (item) => item.catalog_document
        );

        const files = model.data.filter(
            (item) => !insuranceDocumentsIds.includes(item.id)
        );

        return files;
    }
);

export const getInsuranceDocumentsErrors = createSelector(
    getInsuranceDocumentsModel,
    (model) => model.errors
);

export const getInsuranceDocumentsIsFetching = createSelector(
    getInsuranceDocumentsModel,
    (model) => model.isFetching
);

/* Documents */
export const getDocumentsModel = createSelector(
    getModel,
    (model) => model.documents
);

export const getDocumentsList = createSelector(
    getRequiredDocumentsListData,
    (model) =>
        model.filter(
            (item) =>
                item.category === PRODUCT_DOCUMENTS_CATEGORY &&
                item.type === DOCUMENTS_TYPE
        )
);

export const getDocumentsData = createSelector(
    getDocumentsModel,
    getDocumentsList,
    (model, documents) => {
        const documentsFilter = documents.filter(
            (item) => item.catalog_document !== null
        );

        const documentsIds = documentsFilter.map(
            (item) => item.catalog_document
        );

        const files = model.data.filter(
            (item) => !documentsIds.includes(item.id)
        );

        return files;
    }
);

export const getDocumentsErrors = createSelector(
    getDocumentsModel,
    (model) => model.errors
);

export const getDocumentsIsFetching = createSelector(
    getDocumentsModel,
    (model) => model.isFetching
);

/* Save Documents */
export const getSaveDocument = createSelector(
    getModel,
    (model) => model.saveDocument
);

export const getSaveDocumentIsSaving = createSelector(
    getSaveDocument,
    (model) => model.isSaving
);

/*Upload file*/
export const getUploadingFile = createSelector(
    getModel,
    (model) => model.fileUploaded
);

export const isFileUploading = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.isUploading
);

export const getUploadedFileData = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.data || []
);

export const getUploadedFileErrors = createSelector(
    getUploadingFile,
    (fileUploaded) => fileUploaded.errors
);

// Edit contact data

export const getIsOpenModalContact = createSelector(
    getModel,
    (model) => model.isOpenModalContact
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getInitialValuesFormEditCredit = createSelector(
    getModel,
    (model) => model.initialValuesFormEditCredit
);

export const getInitialValuesAssignUserForm = createSelector(
    getModel,
    (model) => model.initialValuesAssignUserForm
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving
);

// Credit bureau data
export const getCreditBureauModel = createSelector(
    getModel,
    (model) => model.creditBureauData
);

export const getCreditBureauData = createSelector(
    getCreditBureauModel,
    (model) => model.data
);

export const getCreditBureauErrors = createSelector(
    getCreditBureauModel,
    (model) => model.errors
);

export const getCreditBureauIsLoading = createSelector(
    getCreditBureauModel,
    (model) => model.isLoading
);

/* Geocode */
export const getFetchGeocode = createSelector(
    getModel,
    (model) => model.fetchGeocode
);

export const getFetchGeocodeData = createSelector(
    getFetchGeocode,
    (data) => data.data || []
);

export const showGeocodeResults = createSelector(
    getModel,
    (model) => model.showGeocodeResults
);

export const getGeocodePointModel = createSelector(
    getModel,
    (model) => model.fetchGeocodePoint
);

export const getIsFetchingGeocodePoint = createSelector(
    getGeocodePointModel,
    (model) => model.isFetching
);

export const getGeocodePointData = createSelector(
    getGeocodePointModel,
    (model) => model.data
);

export const getGeocodePointErrors = createSelector(
    getGeocodePointModel,
    (model) => model.errors
);

export const getSaveLocation = createSelector(
    getModel,
    (model) => model.saveLocation
);

export const getIsSavingLocation = createSelector(
    getSaveLocation,
    (save) => save.isSaving
);

export const getSaveLocationErrors = createSelector(
    getSaveLocation,
    (save) => save.errors
);

export const getDeleteLocation = createSelector(
    getModel,
    (model) => model.deleteLocation
);

export const getIsDeletingLocation = createSelector(
    getSaveLocation,
    (save) => save.isDeleting
);

export const getIsDownloading = createSelector(
    getModel,
    (model) => model.isDownloading
);

export const getDeleteDocument = createSelector(
    getModel,
    (model) => model.deleteDocument
);

export const getIsDeletingDocument = createSelector(
    getDeleteDocument,
    (model) => model.isDeleting
);

export const getDeleteDocumentErrors = createSelector(
    getDeleteDocument,
    (model) => model.errors
);

// PROPOSAL BACKUPS
export const getProposalBackupsModel = createSelector(
    getModel,
    (model) => model.proposalBackups
);

export const getProposalBackupsData = createSelector(
    getProposalBackupsModel,
    (model) => model.data
);

export const getIsFetchingProposalBackups = createSelector(
    getProposalBackupsModel,
    (model) => model.isFetching
);

export const getProposalBackupsErrors = createSelector(
    getProposalBackupsModel,
    (model) => model.errors
);
