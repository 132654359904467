import i18next from 'i18next';

export const NAME = 'credits';
export const COLUMN_KEYS = {
    client_name: i18next.t('Client name'),
    business_name: i18next.t('Business name'),
    amount: i18next.t('Amount'),
    type_business: i18next.t('Type business'),
    project_name: i18next.t('Project'),
    financial_advisor: i18next.t('Assigned executive'),
    finacier_product_name: i18next.t('Financial product'),
    rate: i18next.t('Rate', { context: 'financial' }),
    term: i18next.t('Term'),
    status: i18next.t('Status'),
    requested_at: i18next.t('Creation date'),
    updated_at: i18next.t('Updated at'),
    created_at: i18next.t('Created at'),
};
export const REQUIRED_COLUMNS_KEYS = ['client_name', 'updated_at'];
