import i18next from 'i18next';

import { updateSmartDocument } from 'common/api/smartDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    RENAME_SMART_DOCUMENT,
    RENAME_SMART_DOCUMENT_FAILURE,
    RENAME_SMART_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { templateDocumentsActions } from '../reducer';

import fetchDocuments from './fetchDocuments';
import openDocumentModal from './openDocumentModal';
export default (proposalId, values) => (dispatch) => {
    dispatch(templateDocumentsActions[RENAME_SMART_DOCUMENT]());

    updateSmartDocument(values.id, {
        name: values.name,
    })
        .then(() => {
            dispatch(
                templateDocumentsActions[RENAME_SMART_DOCUMENT_SUCCESS](values)
            );
            dispatch(fetchDocuments(proposalId));
            dispatch(openDocumentModal(false));
            showToast({ body: i18next.t('Changes were saved successfully') });
        })
        .catch((error) => {
            dispatch(
                templateDocumentsActions[RENAME_SMART_DOCUMENT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
