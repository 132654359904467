import { changeProductStatus } from 'common/api/products';

import {
    CHANGE_PRODUCT_STATUS,
    CHANGE_PRODUCT_STATUS_FAILURE,
    CHANGE_PRODUCT_STATUS_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (values, callback) => (dispatch) => {
    const newValues = { alliance: values.allianceId };

    dispatch(installerProfileActions[CHANGE_PRODUCT_STATUS]());

    changeProductStatus(values.productId, newValues)
        .then(() => {
            dispatch(installerProfileActions[CHANGE_PRODUCT_STATUS_SUCCESS]());
            if (callback) callback();
        })
        .catch((error) =>
            dispatch(
                installerProfileActions[CHANGE_PRODUCT_STATUS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
