import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import * as actions from './actions';
import { DocumentUploadCard, Modal } from './components';
import * as selectors from './selectors';

const Container = ({
    canModify,
    dataDocuments,
    documentsType,
    documentsTypeToSelect,
    fetchDocumentsType,
    fetchId,
    getDocuments,
    handleClickCloseModal,
    handleClickCreate,
    installer,
    isFetchingDocuments,
    isVisibleModal,
    prepareUpdateForm,
    profileId,
    type,
}) => {
    useEffect(() => {
        if (installer && installer.id) {
            getDocuments(fetchId, type);
            fetchDocumentsType(installer.id, type);
        }
    }, [installer, fetchId]);

    return (
        <React.Fragment>
            <DocumentUploadCard
                canModify={canModify}
                documents={dataDocuments}
                handleClickAdd={handleClickCreate}
                handleClickUpdate={prepareUpdateForm}
                isFetchingDocuments={isFetchingDocuments}
                type={type}
                profileId={profileId}
                installer={installer}
                fetchId={fetchId}
            />

            <Modal
                documentsType={documentsType}
                documentsTypeToSelect={documentsTypeToSelect}
                fetchDocumentsType={fetchDocumentsType}
                handleClickClose={handleClickCloseModal}
                show={isVisibleModal}
            />
        </React.Fragment>
    );
};

Container.propTypes = {
    canModify: PropTypes.bool,
    dataDocuments: PropTypes.array,
    documentsType: PropTypes.object,
    documentsTypeToSelect: PropTypes.array,
    fetchDocumentsType: PropTypes.func,
    fetchId: PropTypes.string,
    getDocuments: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickCreate: PropTypes.func,
    installer: PropTypes.object,
    isFetchingDocuments: PropTypes.bool,
    isVisibleModal: PropTypes.bool,
    prepareUpdateForm: PropTypes.func,
    profileId: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const mapStateToProps = createStructuredSelector({
    dataConfirmModal: selectors.getDataConfirmModal,
    dataDocuments: selectors.getDataDocuments,
    documentsType: selectors.getDocumentsType,
    documentsTypeToSelect: selectors.getDocumentsTypeToSelect,
    isFetchingDocuments: selectors.getIsLoadingFetchDocuments,
    isUploading: selectors.getIsUploading,
    isVisibleModal: selectors.getIsVisibleModal,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDocumentsType: (itemId, type) =>
        dispatch(actions.fetchDocumentsType(itemId, type)),
    getDocuments: (itemId, type) =>
        dispatch(actions.fetchDocuments(itemId, type)),
    handleClickCloseModal: () => dispatch(actions.closeModal()),
    handleClickCreate: (type, profileId, installer, fetchId) =>
        dispatch(
            actions.prepareCreateForm(type, profileId, installer, fetchId)
        ),
    prepareUpdateForm: (document, type, profileId, installer, fetchId) =>
        dispatch(
            actions.prepareUpdateForm(
                document,
                type,
                profileId,
                installer,
                fetchId
            )
        ),
    save: (values) => dispatch(actions.uploadFile(values)),
    showModal: (value) => dispatch(actions.showModal(value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Container));
