import { createSlice } from '@reduxjs/toolkit';

import {
    DOWNLOAD_DATASHEET,
    DOWNLOAD_DATASHEET_FAILURE,
    DOWNLOAD_DATASHEET_SUCCESS,
    DOWNLOAD_PROPOSAL_CSV,
    DOWNLOAD_PROPOSAL_CSV_FAILURE,
    DOWNLOAD_PROPOSAL_CSV_SUCCESS,
    FETCH_ELECTRIC_BILLS,
    FETCH_ELECTRIC_BILLS_FAILURE,
    FETCH_ELECTRIC_BILLS_SUCCESS,
    FETCH_FINANCING_TEMPLATE,
    FETCH_FINANCING_TEMPLATE_FAILURE,
    FETCH_FINANCING_TEMPLATE_SUCCESS,
    FETCH_INTERACTIVE_PROPOSAL,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS,
    FETCH_INTERACTIVE_PROPOSAL_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_SUCCESS,
    FETCH_SMART_DOCUMENT_CONTENT,
    FETCH_SMART_DOCUMENT_CONTENT_FAILURE,
    FETCH_SMART_DOCUMENT_CONTENT_SUCCESS,
    RESET,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_FAILURE,
    UPDATE_TEMPLATE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    downloadDatasheet: { errors: [], isDownloading: false },
    downloadProposalCsv: { errors: [], isDownloading: false },
    fetchElectricBills: { data: [], errors: [], isFetching: false },
    financingTemplate: { data: null, errors: [], isFetching: false },
    interactiveProposal: {
        data: {},
        errors: [],
        hasError: false,
        isFetching: false,
    },
    interactiveProposalContent: { data: {}, errors: [], isFetching: false },
    smartDocumentContent: { data: {}, errors: [], isFetching: false },
    updateCustomTemplate: { errors: [], isUpdating: false },
};

const interactiveProposalPreviewSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DOWNLOAD_DATASHEET]: (state) => {
            state.downloadDatasheet.isDownloading = true;
        },
        [DOWNLOAD_DATASHEET_FAILURE]: (state, action) => {
            state.downloadDatasheet = {
                ...state.downloadDatasheet,
                errors: action.payload,
                isDownloading: false,
            };
        },
        [DOWNLOAD_DATASHEET_SUCCESS]: (state) => {
            state.downloadDatasheet.isDownloading = false;
        },
        [DOWNLOAD_PROPOSAL_CSV]: (state) => {
            state.downloadProposalCsv.isDownloading = true;
        },
        [DOWNLOAD_PROPOSAL_CSV_FAILURE]: (state, action) => {
            state.downloadProposalCsv = {
                ...state.downloadDatasheet,
                errors: action.payload,
                isDownloading: false,
            };
        },
        [DOWNLOAD_PROPOSAL_CSV_SUCCESS]: (state) => {
            state.downloadProposalCsv.isDownloading = false;
        },
        [FETCH_ELECTRIC_BILLS]: (state) => {
            state.fetchElectricBills.isFetching = true;
        },
        [FETCH_ELECTRIC_BILLS_FAILURE]: (state, action) => {
            state.fetchElectricBills = {
                ...state.fetchElectricBills,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ELECTRIC_BILLS_SUCCESS]: (state, action) => {
            state.fetchElectricBills = {
                ...state.fetchElectricBills,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCING_TEMPLATE]: (state) => {
            state.financingTemplate = {
                ...state.financingTemplate,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_FINANCING_TEMPLATE_FAILURE]: (state, action) => {
            state.financingTemplate = {
                ...state.financingTemplate,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCING_TEMPLATE_SUCCESS]: (state, action) => {
            state.financingTemplate = {
                ...state.financingTemplate,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INTERACTIVE_PROPOSAL]: (state) => {
            state.interactiveProposal.isFetching = true;
        },
        [FETCH_INTERACTIVE_PROPOSAL_FAILURE]: (state, action) => {
            state.interactiveProposal = {
                ...state.interactiveProposal,
                errors: action.payload,
                hasError: true,
                isFetching: false,
            };
        },
        [FETCH_INTERACTIVE_PROPOSAL_SUCCESS]: (state, action) => {
            state.interactiveProposal = {
                ...state.interactiveProposal,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INTERACTIVE_PROPOSAL_CONTENT]: (state) => {
            state.interactiveProposalContent.isFetching = true;
        },
        [FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE]: (state, action) => {
            state.interactiveProposalContent = {
                ...state.interactiveProposalContent,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS]: (state, action) => {
            state.interactiveProposalContent = {
                ...state.interactiveProposalContent,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SMART_DOCUMENT_CONTENT]: (state) => {
            state.smartDocumentContent.isFetching = true;
        },
        [FETCH_SMART_DOCUMENT_CONTENT_FAILURE]: (state, action) => {
            state.smartDocumentContent = {
                ...state.smartDocumentContent,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SMART_DOCUMENT_CONTENT_SUCCESS]: (state, action) => {
            state.smartDocumentContent = {
                ...state.smartDocumentContent,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [UPDATE_TEMPLATE]: (state) => {
            state.updateCustomTemplate.isUpdating = true;
        },
        [UPDATE_TEMPLATE_FAILURE]: (state, action) => {
            state.updateCustomTemplate = {
                ...state.updateCustomTemplate,
                errors: action.payload,
                isUpdating: false,
            };
        },
        [UPDATE_TEMPLATE_SUCCESS]: (state) => {
            state.updateCustomTemplate.isUpdating = false;
        },
    },
});

export const interactiveProposalPreviewActions =
    interactiveProposalPreviewSlice.actions;

export default interactiveProposalPreviewSlice.reducer;
