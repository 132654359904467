import get from 'lodash/get';

import initializeFormEditCredit from './initializeFormEditCredit';

export default (creditApplication) => (dispatch) => {
    const commercialOffer = get(creditApplication, 'commercial_offer', {});

    const values = {
        amount: get(
            commercialOffer,
            'commercial_offer_parameters[0].amount',
            0
        ),
        creditApplicationId: creditApplication.id,
        hitch: get(commercialOffer, 'commercial_offer_parameters[0].hitch', 0),
        id: get(commercialOffer, 'commercial_offer_id', null),
        rate: get(commercialOffer, 'commercial_offer_parameters[0].rate', 0),
        term: get(commercialOffer, 'commercial_offer_parameters[0].term', 0),
    };

    dispatch(initializeFormEditCredit(values));
};
