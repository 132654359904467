import { createRole, updateRole } from 'common/api/roles';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import fetchUserSettings from '../../profile/actions/fetchData';
import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { companyRolesActions } from '../reducer';

import fetchItems from './fetchItems';
import openModal from './openModal';
import resetForm from './resetForm';

export default (values) => (dispatch) => {
    const newGroups = values?.groups.map((item) => ({
        id: item.id,
        permission_level: item.permission_level,
    }));
    const newValues = {
        alliances_visualization_level: values.alliances_visualization_level,
        financier_group_type: values.financier_group_type,
        groups: newGroups,
        name: values.name,
    };

    dispatch(companyRolesActions[SAVE_ITEM]());

    const save = values.id
        ? () => updateRole(newValues, values.id)
        : () => createRole(newValues);

    save()
        .then(() => {
            dispatch(companyRolesActions[SAVE_ITEM_SUCCESS]());
            dispatch(fetchUserSettings());
            dispatch(fetchItems());
            dispatch(openModal(false));
            dispatch(resetForm());
            showToast();
        })
        .catch((error) => {
            dispatch(
                companyRolesActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
