import { saveInsurance, updateInsurance } from 'common/api/products';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_COVERAGE,
    SAVE_COVERAGE_FAILURE,
    SAVE_COVERAGE_SUCCESS,
} from '../actionTypes';
import { configurationCoveragesActions } from '../reducer';

import fetchCoverages from './fetchCoverages';
import resetCoverageForm from './resetCoverageForm';
import toggleCoverageModal from './toggleCoverageModal';

export default (filterData, values) => (dispatch) => {
    let newValues = {
        description: values.description,
        name: values.name,
    };

    if (values.id) newValues.id = values.id;

    const action = values.id ? updateInsurance : saveInsurance;

    dispatch(configurationCoveragesActions[SAVE_COVERAGE]());

    action(newValues)
        .then((response) => {
            dispatch(
                configurationCoveragesActions[SAVE_COVERAGE_SUCCESS](
                    response.data
                )
            );
            dispatch(fetchCoverages(filterData));
            dispatch(toggleCoverageModal(false));
            dispatch(resetCoverageForm());
            showToast();
        })
        .catch((error) => {
            dispatch(
                configurationCoveragesActions[SAVE_COVERAGE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
