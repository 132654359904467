import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import preApproveCredit from './preApproveCredit';

export default (id, callback) => (dispatch) => {
    if (!id) return;

    const dataAlert = {
        messages: [
            i18next.t(
                'By pre-validating the request you will no longer be able to revert the changes'
            ),
        ],
        onSuccess: () => dispatch(preApproveCredit(id, callback)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
