import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { Button, Tooltip, Typography } from 'sunwise-ui';

import { DOCUMENT_STATUS } from 'common/constants';

const getSxRequestButton = (color = null) =>
    color
        ? {
              border: `1px solid ${color}`,
              color: color,
              whiteSpace: 'nowrap',
              width: 'calc(100% - 8px)',
              '&:hover': {
                  backgroundColor: 'transparent',
                  borderColor: color,
              },
              '&.MuiButton-root': { textTransform: 'inherit' },
          }
        : {
              whiteSpace: 'nowrap',
              width: 'calc(100% - 8px)',
              '&:hover': {
                  backgroundColor: 'transparent',
              },
              '&.MuiButton-root': { textTransform: 'inherit' },
          };

const DocumentStatus = ({ disabled = false, onClick, status }) => {
    const renderText = (status) => {
        if (status == DOCUMENT_STATUS.NO_DOCUMENT_STATUS.value)
            return (
                <Tooltip title={DOCUMENT_STATUS.NO_DOCUMENT_STATUS.value}>
                    <Typography
                        component="span"
                        noWrap
                        sx={{
                            fontWeight: 700,
                            fontSize: '0.875rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '6.5rem',
                        }}
                    >
                        {DOCUMENT_STATUS.NO_DOCUMENT_STATUS.value}
                    </Typography>
                </Tooltip>
            );
        return (
            <Tooltip title={status}>
                <Typography
                    component="span"
                    noWrap
                    sx={{
                        fontWeight: 700,
                        fontSize: '0.875rem',
                        width: '5rem',
                    }}
                >
                    {status}
                </Typography>
            </Tooltip>
        );
    };

    switch (status) {
        case DOCUMENT_STATUS.APPROVED_STATUS.value:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    startIcon={<CheckIcon />}
                    sx={{
                        ...getSxRequestButton(
                            DOCUMENT_STATUS.APPROVED_STATUS.color
                        ),
                    }}
                    variant="outlined"
                >
                    {renderText(DOCUMENT_STATUS.APPROVED_STATUS.value)}
                </Button>
            );
        case DOCUMENT_STATUS.REJECTED_STATUS.value:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    startIcon={<CloseIcon />}
                    sx={{
                        ...getSxRequestButton(
                            DOCUMENT_STATUS.REJECTED_STATUS.color
                        ),
                    }}
                    variant="outlined"
                >
                    {renderText(DOCUMENT_STATUS.REJECTED_STATUS.value)}
                </Button>
            );
        case DOCUMENT_STATUS.REQUESTED_STATUS.value:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    sx={{
                        ...getSxRequestButton(
                            DOCUMENT_STATUS.REQUESTED_STATUS.color
                        ),
                    }}
                    variant="outlined"
                >
                    {renderText(DOCUMENT_STATUS.REQUESTED_STATUS.value)}
                </Button>
            );
        case DOCUMENT_STATUS.DOWNLOAD_STATUS.value:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    sx={{ ...getSxRequestButton() }}
                    variant="outlined"
                >
                    {renderText(DOCUMENT_STATUS.DOWNLOAD.value)}
                </Button>
            );
        case DOCUMENT_STATUS.INPROCESS_STATUS.value:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    sx={{
                        ...getSxRequestButton(
                            DOCUMENT_STATUS.INPROCESS_STATUS.color
                        ),
                    }}
                    variant="outlined"
                >
                    {renderText(DOCUMENT_STATUS.INPROCESS_STATUS.value)}
                </Button>
            );
        default:
            return (
                <Button
                    color="secondary"
                    disabled={disabled}
                    onClick={onClick}
                    sx={{ ...getSxRequestButton() }}
                    variant="outlined"
                >
                    {renderText(DOCUMENT_STATUS.NO_DOCUMENT_STATUS.value)}
                </Button>
            );
    }
};

DocumentStatus.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DocumentStatus;
