import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import {
    SMART_DOCUMENT_FORM_DUPLICATE_MODE,
    SMART_DOCUMENT_FORM_RENAME_MODE,
} from './constants';

export const getDocumentTemplatesToSelect = (documents) => [
    {
        disabled: true,
        label: i18next.t('Select a template'),
        value: '',
    },
    ...documents.map((item) => ({
        label: item.title,
        value: item.id,
    })),
];

export const getIsUpdatingContextMessage = (
    editionDisabled,
    isUpdatingCustomTemplate,
    loadingContext,
    setEditionDisabled
) => {
    if (isEmpty(loadingContext)) return;
    if (isUpdatingCustomTemplate) loadingContext.openLoading('Guardando...');
    else {
        if (!editionDisabled) setEditionDisabled(true);
        loadingContext.closeLoading();
    }
};

export const getSmartDocumentModalTitle = (smartDocumentFormMode) => {
    switch (smartDocumentFormMode) {
        case SMART_DOCUMENT_FORM_DUPLICATE_MODE:
            return i18next.t('Duplicate document');
        case SMART_DOCUMENT_FORM_RENAME_MODE:
            return i18next.t('Rename document');
        default:
            return i18next.t('Create document');
    }
};
