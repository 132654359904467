import React, { useState } from 'react';

import { formatDate, subDate } from 'common/utils/dates';

import ColumnsContainer from './components/ColumnsContainer';
import Filters from './components/Filters';

const Container = () => {
    const initialDate = formatDate(
        subDate(new Date(), { years: 1 }),
        'dd/MM/yyyy'
    );
    const finalDate = formatDate(new Date(), 'dd/MM/yyyy');
    const [filterQuery, setFilterQuery] = useState({
        company: '',
        executive: '',
        final_date: finalDate,
        initial_date: initialDate,
        search: '',
    });

    return (
        <>
            <Filters
                filterQuery={filterQuery}
                setFilterQuery={setFilterQuery}
            />

            <ColumnsContainer filterQuery={filterQuery} />
        </>
    );
};

export default Container;
