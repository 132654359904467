import React from 'react';

import PropTypes from 'prop-types';
import { ToggleButtonGroup, ToggleButton } from 'sunwise-ui';

const ReduxToggleButtonGroupField = ({
    disabled,
    input: { name, onChange, value: selectedValue },
    items,
    sx = { my: 1 },
}) => (
    <ToggleButtonGroup
        color="primary"
        disabled={disabled}
        exclusive
        onChange={(e) => onChange(parseInt(e.target.value))}
        size="small"
        sx={sx}
        value={selectedValue}
    >
        {items.map((item, index) => (
            <ToggleButton
                disabled={disabled}
                key={`${name}-toggle-button-${index}`}
                value={item.value}
            >
                {item.label}
            </ToggleButton>
        ))}
    </ToggleButtonGroup>
);

ReduxToggleButtonGroupField.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object,
    items: PropTypes.array,
    sx: PropTypes.object,
};

export default ReduxToggleButtonGroupField;
