import API from '..';
const ENTITY = 'users';

export const createItem = (data) => API.post(`/api/v1/${ENTITY}/`, { ...data });
export const getItems = () => API.get(`/api/v1/${ENTITY}/list/`);
export const filterItems = (str) => API.get(`/api/v1/${ENTITY}/filter/${str}`);
export const filterUsers = ({
    orderBy = 'created_at',
    page = 1,
    perPage = 25,
    searchText = '',
    sortBy = null,
} = {}) =>
    API.get(`/api/v1/${ENTITY}/listing/`, {
        params: {
            order_by: orderBy,
            page: page,
            per_page: perPage,
            ...(searchText ? { search: searchText } : {}),
            sort: sortBy,
        },
    });
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
export const deleteItem = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const activateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/enable/${id}`, { ...data });
export const changePassword = (data) =>
    API.post(`/api/v1/${ENTITY}/change-password/`, { ...data });
export const changePasswordProfile = (data) =>
    API.post(`/api/v1/${ENTITY}/change-password-profile/`, { ...data });
export const changePasswordUser = (data, id) =>
    API.put(`/api/v1/${ENTITY}/change-password-user/${id}/`, { ...data });
export const restartPassword = (id) =>
    API.put(`/api/v1/${ENTITY}/restart-password/${id}`);
export const getFinancierGroup = () =>
    API.get(`/api/v1/${ENTITY}/financier-group/`);
export const updateConfigUser = (data) =>
    API.post(`/api/v1/${ENTITY}/settings/`, { ...data });
