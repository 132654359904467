import i18next from 'i18next';

export const getBrandOptions = (brands) => {
    const options = brands.map((item) => ({
        label: item.name,
        value: item.name,
    }));

    return [
        {
            disabled: true,
            label: i18next.t('Select a brand'),
            value: '',
        },
        ...options,
    ];
};
