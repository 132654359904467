import { updateItem } from 'common/api/clients';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

import fetchCreditApplication from './fetchCreditApplication';
import resetForm from './resetForm';
import toggleModalContact from './toggleModalContact';

export default (values) => (dispatch) => {
    const newValues = {
        business_name: values.business_name,
        emails: JSON.stringify(values.emails.map((email) => ({ ...email }))),
        first_lastname: values.first_lastname,
        name: values.name,
        second_lastname: values.second_lastname,
        telephones: JSON.stringify(
            values.telephones.map((phone) => ({ ...phone }))
        ),
    };

    dispatch(creditFileActions[SAVE_ITEM]());

    const save = () => updateItem(newValues, values.id);

    save()
        .then(() => {
            dispatch(creditFileActions[SAVE_ITEM_SUCCESS]());
            dispatch(fetchCreditApplication(values.creditId));
            dispatch(toggleModalContact(false));
            dispatch(resetForm());

            showToast();
        })
        .catch((error) => {
            dispatch(
                creditFileActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
