import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import isotypeSunpay from 'resources/isotypeSunpay.svg';

const Wrapper = styled('div')`
    align-items: center;
    background: #002438ee;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: ${({ visible }) => (visible ? '1' : '0')};
    position: fixed;
    top: 0;
    transition: ${({ visible }) =>
        visible
            ? 'opacity 0.25s linear, visibility 0s linear'
            : 'opacity 0.25s linear, visibility 0s linear 0.25s'};
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    width: 100%;
    z-index: 9999;
`;

const Container = styled('div')`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 300px;
`;

const LogoImage1 = styled('img')`
    height: 100%;
    position: absolute;
    width: 100%;
`;

const ImageContainer = styled('div')`
    display: flex;
    height: 64px;
    margin-bottom: 10px;
    position: relative;
    width: 64px;
`;

const MessageText = styled('span')`
    color: #fff;
    font-size: 16px;
    letter-spacing: 0.01rem;
    line-height: 18px;
    min-height: 18px;
    text-align: center;
    width: 100%;
`;

const ProcessView = ({ myForwardedRef }) => {
    const { t } = useTranslation();
    const [loadingData, setLoadingData] = useState({
        show: false,
        text: t('Loading'),
    });

    useImperativeHandle(myForwardedRef, () => ({
        openLoading(text = i18next.t('Loading')) {
            document.body.style.overflowY = 'hidden';
            setLoadingData({ text, show: true });
        },
        closeLoading() {
            document.body.style.overflowY = 'auto';
            setLoadingData({ text: '', show: false });
        },
    }));

    return (
        <Wrapper visible={loadingData.show}>
            <Container>
                <ImageContainer>
                    <LogoImage1 src={isotypeSunpay} />
                </ImageContainer>
                <MessageText>{loadingData.text}</MessageText>
            </Container>
        </Wrapper>
    );
};

ProcessView.propTypes = { myForwardedRef: PropTypes.object };

const ProcessViewForwared = forwardRef((props, ref) => (
    <ProcessView {...props} myForwardedRef={ref} />
));

ProcessViewForwared.displayName = 'ProcessViewForwared';

export default ProcessViewForwared;
