import React from 'react';

import PropTypes from 'prop-types';
import { TextField, InputAdornment } from 'sunwise-ui';

const ReduxFieldInput = ({
    as,
    append,
    defaultBehevior = false,
    disabled,
    input: { value, onChange, onBlur, onFocus },
    meta: { touched, error },
    placeholder,
    label,
    readOnly,
    type,
    maxLength,
    min,
    onlyNumbers = false,
    prepend,
    sx,
    variant,
}) => {
    const onChangeAction = (event, defaultBehevior, onlyNumbers) => {
        const text = event.target.value;

        if (onlyNumbers) {
            event.target.value = text.replace(/[A-Za-z]/gi, '');
            onChange(event);

            return;
        }

        if (
            !(
                text.length > 1 &&
                text.charAt(0) === '0' &&
                text.charAt(1) === '0' &&
                !defaultBehevior
            )
        ) {
            if (type === 'number' && parseFloat(text) >= 1) {
                event.target.value = text.replace(/^0+/, '');
            }

            onChange(event);
        }
    };

    const onBlurAction = (event, blurValue) => {
        if (type === 'number' && event.target.value === '') {
            event.preventDefault();
            blurValue = min || 0;
            event.target.value = min || 0;
        }

        onBlur(event, blurValue);
    };

    const onFocusAction = (event, focusValue) => {
        if (type === 'number' && event.target.value === '') {
            event.preventDefault();
            focusValue = min || 0;
            event.target.value = min || 0;
        }

        onFocus(event, focusValue);
    };

    return (
        <TextField
            disabled={disabled}
            error={Boolean(error) && touched}
            fullWidth
            helperText={touched && error}
            inputProps={{ maxLength: maxLength }}
            InputProps={{
                endAdornment: append && (
                    <InputAdornment position="end">{append}</InputAdornment>
                ),
                readOnly: readOnly,
                startAdornment: prepend && (
                    <InputAdornment position="start">{prepend}</InputAdornment>
                ),
            }}
            label={label}
            multiline={as === 'textarea'}
            onBlur={onBlurAction}
            onChange={(e) =>
                onChangeAction(e, defaultBehevior, onlyNumbers, onChange)
            }
            onFocus={onFocusAction}
            placeholder={placeholder}
            sx={sx}
            type={type}
            value={value || ''}
            variant={variant}
        />
    );
};

ReduxFieldInput.propTypes = {
    as: PropTypes.string,
    append: PropTypes.string,
    defaultBehevior: PropTypes.bool,
    disabled: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    min: PropTypes.number,
    onlyNumbers: PropTypes.bool,
    prepend: PropTypes.string,
    sx: PropTypes.object,
    variant: PropTypes.string,
};

export default ReduxFieldInput;
