import React, { useEffect, useState } from 'react';

import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Container, Grid, Tab, Tabs, TabPanel } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { validateAccessWithPermissions } from 'common/utils/permissions';

const getTabsWithRights = (tabs) =>
    tabs.filter(
        (tab) =>
            !tab.hidden &&
            (!tab.requiredPermissions ||
                validateAccessWithPermissions(tab.requiredPermissions))
    );

const TabsSystem = ({
    baseUrl,
    checkRedirect,
    match,
    redirect,
    tabs = [],
    title,
}) => {
    const tabsWithRights = getTabsWithRights(tabs);
    const [selectedTab, setSelectedTab] = useState(
        tabsWithRights.length > 0 ? tabsWithRights[0].id : ''
    );

    useEffect(() => {
        if (match.params.id) setSelectedTab(match.params.id);
    }, [match.params.id]);

    if (match.params.id && match.params.id !== selectedTab) return null;

    const handleChange = (_, newValue) => {
        setSelectedTab(newValue);
        redirect(`/${baseUrl}/${newValue}`);
    };

    checkRedirect(match.params.id, tabsWithRights);

    return (
        <Container maxWidth={false}>
            {title && (
                <HeaderContainer>
                    <Grid item xs>
                        <TitleIcon title={title} />
                    </Grid>
                </HeaderContainer>
            )}

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onChange={handleChange}
                    scrollButtons="auto"
                    value={selectedTab}
                    variant="scrollable"
                >
                    {tabsWithRights.map((tab) => (
                        <Tab
                            id={`tab-${tab.id}`}
                            key={`tab-${tab.id}`}
                            label={tab.label}
                            value={tab.id}
                        />
                    ))}
                </Tabs>
            </Box>

            {tabsWithRights.map((tab) => (
                <TabPanel
                    key={`tab-panel-${tab.id}`}
                    selectedTab={selectedTab}
                    value={tab.id}
                >
                    {tab.content}
                </TabPanel>
            ))}
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => ({
    redirect: (link) => dispatch(push(link)),
    checkRedirect: (id, tabs = []) => {
        if (tabs.length === 0) {
            dispatch(push('/'));
            return;
        }

        const links = tabs.map((tab) => tab.id);

        if (
            typeof id !== 'undefined' &&
            id !== '' &&
            links.indexOf(id) === -1
        ) {
            dispatch(push('/'));
            return;
        }
    },
});

TabsSystem.propTypes = {
    baseUrl: PropTypes.string,
    checkRedirect: PropTypes.func,
    match: PropTypes.object,
    redirect: PropTypes.func,
    tabs: PropTypes.array,
    title: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(withRouter(TabsSystem));
