import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Tabs, Tab, TabPanel } from 'sunwise-ui';

import { Modal, ShowErrors } from 'common/components';

import * as configurationInsurancesActions from '../configurationInsurances/actions';
import * as configurationInsurancesSelectors from '../configurationInsurances/selectors';
import * as financialActions from '../financial/actions';
import Documents from '../financial/components/productForm/Documents';
import Footer from '../financial/components/productForm/Footer';
import Products from '../financial/components/productForm/Products';
import * as financialSelectors from '../financial/selectors';

import * as actions from './actions';
import Parameters from './components/Parameters';
import { NAME } from './constants';
import * as selectors from './selectors';
import validate from './validate';

const ProductForm = ({
    callback,
    canDelete,
    changeInput,
    coverages,
    errors,
    fetchCoverages,
    fetchTemplates,
    filterData,
    financierType,
    formValues,
    handleClickBulkItems,
    handleSubmit,
    initialValues,
    isFetchingCoverages,
    isFetchingTemplates,
    modalIsOpen,
    save,
    templates,
    toggleModalProduct,
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('parameters');
    const isNewRecord = formValues.id ? false : true;

    const handleOnClickComplete = (values) => save(values, callback);
    const handleChange = (_, newValue) => setSelectedTab(newValue);
    const onExited = () => setSelectedTab('parameters');
    const onHide = () => toggleModalProduct(false);
    const title = `${isNewRecord ? t('Add') : t('Edit')} ${t('Product')}`;

    return (
        <form>
            <Modal
                footerComponent={() => (
                    <Footer
                        canDelete={canDelete}
                        changeInput={changeInput}
                        filterData={filterData}
                        formValues={formValues}
                        handleClickBulkItems={handleClickBulkItems}
                        isNewRecord={isNewRecord}
                        onClick={handleSubmit(handleOnClickComplete)}
                    />
                )}
                onExited={onExited}
                onHide={onHide}
                show={modalIsOpen}
                size="lg"
                title={title}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        onChange={handleChange}
                        scrollButtons="auto"
                        value={selectedTab}
                        variant="scrollable"
                    >
                        <Tab
                            id="tab-parameters"
                            key="tab-parameters"
                            label={t('Parameters')}
                            value="parameters"
                        />
                        <Tab
                            id="tab-products"
                            key="tab-products"
                            label={t('Product', { count: 2 })}
                            value="products"
                        />
                        <Tab
                            id="tab-documents"
                            key="tab-documents"
                            label={t('Document', { count: 2 })}
                            value="documents"
                        />
                    </Tabs>
                </Box>

                <TabPanel
                    key="tab-parameters"
                    selectedTab={selectedTab}
                    value="parameters"
                >
                    <Parameters
                        changeInput={changeInput}
                        coverages={coverages}
                        disabled={
                            formValues.disabled ||
                            isFetchingCoverages ||
                            isFetchingTemplates
                        }
                        fetchCoverages={fetchCoverages}
                        fetchTemplates={fetchTemplates}
                        formValues={formValues}
                        isNewRecord={isNewRecord}
                        templates={templates}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-products"
                    selectedTab={selectedTab}
                    value="products"
                >
                    <Products
                        changeInput={changeInput}
                        disabled={formValues.disabled}
                        formValues={formValues}
                        initialValues={initialValues}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-documents"
                    selectedTab={selectedTab}
                    value="documents"
                >
                    <Documents
                        disabled={formValues.disabled}
                        financierType={financierType}
                    />
                </TabPanel>

                {errors && errors.length ? (
                    <div>
                        <ShowErrors errors={errors} />
                    </div>
                ) : null}
            </Modal>
        </form>
    );
};

ProductForm.propTypes = {
    callback: PropTypes.func,
    canDelete: PropTypes.bool,
    changeInput: PropTypes.func,
    coverages: PropTypes.array,
    errors: PropTypes.array,
    fetchCoverages: PropTypes.func,
    fetchTemplates: PropTypes.func,
    filterData: PropTypes.object,
    financierType: PropTypes.number,
    formValues: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingCoverages: PropTypes.bool,
    isFetchingTemplates: PropTypes.bool,
    modalIsOpen: PropTypes.bool,
    save: PropTypes.func,
    templates: PropTypes.array,
    toggleModalProduct: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    coverages: configurationInsurancesSelectors.getCoveragesData,
    errors: selectors.getSaveProductErrors,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isFetchingCoverages:
        configurationInsurancesSelectors.getCoveragesIsFetching,
    isFetchingTemplates: financialSelectors.getIsFetchingTemplateCatalogs,
    modalIsOpen: selectors.getProductModalIsOpen,
    templates: financialSelectors.getfetchTemplateCatalogsData,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    fetchCoverages: () =>
        dispatch(configurationInsurancesActions.fetchCoverages()),
    fetchTemplates: () => dispatch(financialActions.fetchTemplates()),
    save: (values, callback) => dispatch(actions.save(values, callback)),
    toggleModalProduct: (status) =>
        dispatch(actions.toggleModalProduct(status)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(ProductForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
