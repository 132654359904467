import React from 'react';

import { useTranslation } from 'react-i18next';

import TabsSystem from 'common/components/TabsSystem';

import funnelFinancing from '../funnelFinancing';

const getTabs = (t) => [
    {
        content: <funnelFinancing.Container />,
        id: 'financing',
        label: t('Financing', { count: 2 }),
        requiredPermissions: [],
    },
];

const Funnel = () => {
    const { t } = useTranslation();
    return <TabsSystem baseUrl="funnel" tabs={getTabs(t)} />;
};

export default Funnel;
