import React, { forwardRef, useImperativeHandle, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import {
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
} from 'sunwise-ui';

const DropdownCardMenu = ({
    customAdditionalItem = null,
    disabled,
    items,
    myForwardedRef,
    sxIconButton,
    visible,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useImperativeHandle(myForwardedRef, () => ({
        closeMenu() {
            handleClose();
        },
    }));

    if (!visible) return null;

    return (
        <>
            <IconButton
                disabled={disabled}
                onClick={handleClick}
                sx={sxIconButton}
            >
                <MoreVertIcon />
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                {items.map(
                    (
                        {
                            disabled,
                            handleClick: menuItemClick,
                            icon,
                            iconPosition = 'left',
                            label,
                            link,
                            linkOptions,
                            visible = true,
                        },
                        index
                    ) => {
                        if (!visible) return null;
                        return (
                            <MenuItem
                                key={index}
                                disabled={disabled}
                                onClick={() => {
                                    if (menuItemClick) {
                                        menuItemClick();
                                        handleClose();
                                    }
                                }}
                                href={link}
                                {...linkOptions}
                            >
                                {icon && iconPosition === 'left' && (
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '24px !important',
                                            mr: 1,
                                        }}
                                    >
                                        {icon}
                                    </ListItemIcon>
                                )}

                                <Typography variant="body2" color="#848bab">
                                    {label}
                                </Typography>

                                {icon && iconPosition === 'right' && (
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '24px !important',
                                            ml: 'auto',
                                        }}
                                    >
                                        {icon}
                                    </ListItemIcon>
                                )}
                            </MenuItem>
                        );
                    }
                )}
                {customAdditionalItem}
            </Menu>
        </>
    );
};

DropdownCardMenu.defaultProps = {
    customAdditionalItem: null,
    disabled: false,
    visible: true,
};

DropdownCardMenu.propTypes = {
    customAdditionalItem: PropTypes.object,
    disabled: PropTypes.bool,
    items: PropTypes.array,
    myForwardedRef: PropTypes.object,
    sxIconButton: PropTypes.object,
    visible: PropTypes.bool,
};

const DropdownCardMenuForwared = forwardRef((props, ref) => (
    <DropdownCardMenu {...props} myForwardedRef={ref} />
));

DropdownCardMenuForwared.displayName = 'DropdownCardMenuForwared';

export default DropdownCardMenuForwared;
