import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from 'sunwise-ui';

const Footer = ({
    canDelete,
    changeInput,
    filterData,
    formValues,
    handleClickBulkItems,
    isNewRecord = false,
    onClick,
}) => {
    const { t } = useTranslation();
    const isArchived = formValues?.status_flag === 'archived';

    return (
        <Box mb={2} sx={{ width: '100%' }}>
            <Grid container mt={1}>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', xs: 'column' },
                        gap: 2,
                        justifyContent: { md: 'right', xs: 'center' },
                    }}
                    xs
                >
                    {!isNewRecord && (
                        <>
                            <Button
                                color="secondary"
                                fullWidth={false}
                                onClick={() =>
                                    handleClickBulkItems(
                                        [formValues?.id],
                                        'archived',
                                        filterData,
                                        () =>
                                            changeInput(
                                                'status_flag',
                                                'archived'
                                            )
                                    )
                                }
                                sx={{
                                    mr: { md: 2, xs: 0 },
                                    order: { md: 1, xs: 2 },
                                    width: { md: 'auto', xs: '100%' },
                                }}
                                type="button"
                                variant="text"
                                visible={!isArchived && canDelete}
                            >
                                {t('Archive')}
                            </Button>

                            <Button
                                color="secondary"
                                fullWidth={false}
                                onClick={() =>
                                    handleClickBulkItems(
                                        [formValues?.id],
                                        'active',
                                        filterData,
                                        () =>
                                            changeInput('status_flag', 'active')
                                    )
                                }
                                sx={{
                                    mr: { md: 2, xs: 0 },
                                    order: { md: 1, xs: 2 },
                                    width: { md: 'auto', xs: '100%' },
                                }}
                                type="button"
                                variant="text"
                                visible={isArchived && canDelete}
                            >
                                {t('Restore')}
                            </Button>

                            <Button
                                color="error"
                                fullWidth={false}
                                onClick={() =>
                                    handleClickBulkItems(
                                        [formValues?.id],
                                        'deleted',
                                        filterData,
                                        () => {}
                                    )
                                }
                                sx={{
                                    mr: { md: 2, xs: 0 },
                                    order: { md: 1, xs: 2 },
                                    width: { md: 'auto', xs: '100%' },
                                }}
                                type="button"
                                variant="text"
                                visible={isArchived && canDelete}
                            >
                                {t('Delete')}
                            </Button>
                        </>
                    )}

                    <Button
                        fullWidth={false}
                        onClick={onClick}
                        sx={{
                            mb: { md: 0, xs: 2 },
                            order: { md: 2, xs: 1 },
                            width: { md: 'auto', xs: '100%' },
                        }}
                        type="button"
                        variant="outlined"
                        visible={!isArchived}
                    >
                        {isNewRecord ? t('Create') : t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

Footer.propTypes = {
    canDelete: PropTypes.bool,
    changeInput: PropTypes.func,
    filterData: PropTypes.object,
    formValues: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    isNewRecord: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Footer;
