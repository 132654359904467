import { closeFinancierInstallers } from 'common/api/financierInstallers';
import alerts from 'common/modules/alerts';

import {
    CLOSE_ALLIANCE,
    CLOSE_ALLIANCE_FAILURE,
    CLOSE_ALLIANCE_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (id, callback) => (dispatch) => {
    dispatch(installerProfileActions[CLOSE_ALLIANCE]());

    closeFinancierInstallers(id)
        .then(() => {
            dispatch(installerProfileActions[CLOSE_ALLIANCE_SUCCESS]());
            if (callback) callback();
            dispatch(alerts.actions.close());
        })
        .catch((error) =>
            dispatch(
                installerProfileActions[CLOSE_ALLIANCE_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
