import React from 'react';

import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
} from 'sunwise-ui';

const ReactHookFormRadioButtonGroup = ({
    control,
    disabled,
    items,
    label,
    name,
    onChangedValue,
    row,
}) => (
    <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl error={Boolean(error)}>
                {label && <FormLabel>{label}</FormLabel>}

                <RadioGroup
                    onChange={(e) => {
                        onChange(e.target.value);
                        if (onChangedValue) onChangedValue(e);
                    }}
                    row={row}
                    value={value}
                >
                    {items.map((item, index) => (
                        <FormControlLabel
                            control={
                                <Radio disabled={item.disabled || disabled} />
                            }
                            key={`radio-field-${index}`}
                            label={item.label}
                            value={item.value}
                        />
                    ))}
                </RadioGroup>

                {Boolean(error) && (
                    <FormHelperText>{error?.message}</FormHelperText>
                )}
            </FormControl>
        )}
    />
);

ReactHookFormRadioButtonGroup.propTypes = {
    control: PropTypes.object,
    disabled: PropTypes.bool,
    items: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string,
    onChangedValue: PropTypes.func,
    row: PropTypes.bool,
};

export default ReactHookFormRadioButtonGroup;
