import React, { useEffect, useState } from 'react';

import { push } from 'connected-react-router';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Box } from 'sunwise-ui';

import alerts from 'common/modules/alerts';

import * as companyGeneralActions from '../../../modules/companyGeneral/actions';
import * as notificationsActions from '../../../modules/notifications/actions';
import * as notificationsSelectors from '../../../modules/notifications/selectors';
import * as profileActions from '../../../modules/profile/actions';
import * as profileSelectors from '../../../modules/profile/selectors';

import Footer from './Footer';
import SideBar from './SideBar';
import TopBar from './TopBar';

const DefaultLayout = ({
    children,
    fetchDataCompany,
    fetchNavNotifications,
    fetchUserSettings,
    match,
    notificationsQuantity,
    profileValues,
    redirect,
    saveConfig,
    selectedTheme,
    setTheme,
    subscription,
}) => {
    const [drawerSize, setDrawerSize] = useState('normal');
    const [mobileOpen, setMobileOpen] = useState(false);

    useEffect(() => {
        fetchUserSettings();
        fetchNavNotifications();
        fetchDataCompany();
        const selectedDrawerSize = localStorage.getItem('selectedDrawerSize');
        if (selectedDrawerSize) setDrawerSize(selectedDrawerSize);
    }, []);

    const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

    const toggleDrawerSize = () => {
        const newDrawerSize = drawerSize === 'normal' ? 'small' : 'normal';
        setDrawerSize(newDrawerSize);
        saveConfig({ expanded_sidebar: drawerSize === 'small' });
        localStorage.setItem('selectedDrawerSize', newDrawerSize);
    };

    return (
        <Box sx={{ flexGrow: 0, height: '100%' }}>
            <SideBar
                drawerSize={drawerSize}
                handleDrawerToggle={handleDrawerToggle}
                match={match}
                mobileOpen={mobileOpen}
                profileValues={profileValues}
                redirect={redirect}
                selectedTheme={selectedTheme}
                setTheme={setTheme}
                subscription={subscription}
                toggleDrawerSize={toggleDrawerSize}
            />

            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    ml: { md: '86px' },
                    pt: { xs: '52px', md: 0 },
                    width: { md: 'calc(100% - 86px)' },
                    ...(drawerSize === 'small' && {
                        ml: { md: '50px' },
                        width: { md: 'calc(100% - 50px)' },
                    }),
                }}
            >
                <TopBar
                    availableProjects={get(
                        subscription,
                        'counters.available_projects',
                        0
                    )}
                    handleDrawerToggle={handleDrawerToggle}
                    notificationsQuantity={notificationsQuantity}
                    redirect={redirect}
                    subscription={subscription}
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                    }}
                >
                    {children}
                </Box>

                <Footer />
            </Box>

            <alerts.Container />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    notificationsQuantity: notificationsSelectors.getNavNotificationsNoReaded,
    profileValues: profileSelectors.getCurrentValues,
    selectedTheme: profileSelectors.getTheme,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDataCompany: () => dispatch(companyGeneralActions.fetchData()),
    fetchNavNotifications: () =>
        dispatch(notificationsActions.fetchNavNotifications()),
    fetchUserSettings: () => dispatch(profileActions.fetchData()),
    redirect: (link) => dispatch(push(link)),
    saveConfig: (config) => dispatch(profileActions.saveConfig(config)),
    setTheme: (value) => dispatch(profileActions.setTheme(value)),
});

DefaultLayout.propTypes = {
    children: PropTypes.object,
    fetchDataCompany: PropTypes.func,
    fetchNavNotifications: PropTypes.func,
    fetchUserSettings: PropTypes.func,
    match: PropTypes.object,
    notificationsQuantity: PropTypes.number,
    profileValues: PropTypes.object,
    redirect: PropTypes.func,
    saveConfig: PropTypes.func,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
    subscription: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DefaultLayout));
