import i18next from 'i18next';

import { changePasswordUser } from 'common/api/users';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { RESET_FORM, SAVE, SAVE_FAILURE, SAVE_SUCCESS } from '../actionTypes';
import { resetPasswordUserActions } from '../reducer';

import openModal from './openModal';

export default (values) => (dispatch) => {
    const newValues = {
        confirm_password: values.confirm_password,
        password: values.new_password,
    };

    dispatch(resetPasswordUserActions[SAVE]());

    changePasswordUser(newValues, values.id)
        .then(() => {
            dispatch(resetPasswordUserActions[SAVE_SUCCESS]());
            dispatch(resetPasswordUserActions[RESET_FORM]());
            dispatch(openModal(false));
            showToast({
                body: i18next.t('Password has been updated successfully'),
            });
        })
        .catch((error) => {
            dispatch(
                resetPasswordUserActions[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
