import { reset } from 'redux-form';

import { create, update } from 'common/api/financierProduct';
import { handleCheckboxField } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_PRODUCT,
    SAVE_PRODUCT_FAILURE,
    SAVE_PRODUCT_SUCCESS,
} from '../actionTypes';
import { NAME } from '../constants';
import { financialBepensaActions } from '../reducer';

import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

export default (values, callback) => (dispatch) => {
    const newValues = {
        accesories_list_must_have: '',
        accesories_list_optional: handleCheckboxField(
            values.accesories_list_optional
        ),
        catalog_documents: handleCheckboxField(values.catalog_documents),
        description: values.description,
        inverters_list: handleCheckboxField(values.inverters_list),
        name: values.name,
        panels_list: handleCheckboxField(values.panels_list),
        type: values.type,
        type_algorithm: values.type_algorithm,
        types_business_company: values.types_business_company,
    };

    if (values.id) newValues.id = values.id;

    const save = values.id
        ? () => update(newValues, values.id)
        : () => create(newValues);

    dispatch(financialBepensaActions[SAVE_PRODUCT]());

    save()
        .then(() => {
            dispatch(financialBepensaActions[SAVE_PRODUCT_SUCCESS]());
            dispatch(reset(`${NAME}/form`));
            dispatch(resetForm());
            dispatch(toggleModalProduct(false));
            showToast();
            if (callback) callback();
        })
        .catch((error) =>
            dispatch(
                financialBepensaActions[SAVE_PRODUCT_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
