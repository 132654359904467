import React from 'react';

import PropTypes from 'prop-types';
import { Grid, Switch } from 'sunwise-ui';

import { StyledH2 } from 'common/components';

const ProductStatus = ({
    allianceId,
    callback,
    canModify,
    handleChangeProductStatus,
    product,
}) => (
    <Grid alignItems="center" container>
        <Grid item xs>
            <StyledH2>{product.name}</StyledH2>
        </Grid>

        <Grid item hidden={!canModify}>
            <Switch
                checked={product.approved}
                onChange={() =>
                    handleChangeProductStatus(
                        { allianceId: allianceId, productId: product.id },
                        callback
                    )
                }
            />
        </Grid>
    </Grid>
);

ProductStatus.propTypes = {
    allianceId: PropTypes.number,
    callback: PropTypes.func,
    canModify: PropTypes.bool,
    handleChangeProductStatus: PropTypes.func,
    product: PropTypes.object,
};

export default ProductStatus;
