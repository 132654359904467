import { NAME } from './constants';

export const DELETE_COMPANIES_CATALOGS = `${NAME}/DELETE_COMPANIES_CATALOGS`;
export const DELETE_COMPANIES_CATALOGS_FAILURE = `${NAME}/DELETE_COMPANIES_CATALOGS_FAILURE`;
export const DELETE_COMPANIES_CATALOGS_SUCCESS = `${NAME}/DELETE_COMPANIES_CATALOGS_SUCCESS`;

export const FETCH_COMPANIES_CATALOGS = `${NAME}/FETCH_COMPANIES_CATALOGS`;
export const FETCH_COMPANIES_CATALOGS_FAILURE = `${NAME}/FETCH_COMPANIES_CATALOGS_FAILURE`;
export const FETCH_COMPANIES_CATALOGS_SUCCESS = `${NAME}/FETCH_COMPANIES_CATALOGS_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const INITIALIZE_FORM_TERMS = `${NAME}/INITIALIZE_FORM_TERMS`;

export const OPEN_MODAL = `${NAME}/OPEN_MODAL`;

export const RESET = `${NAME}/RESET`;
export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_FORM_TERMS = `${NAME}/RESET_FORM_TERMS`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;

export const SET_TYPE = `${NAME}/SET_TYPE`;
