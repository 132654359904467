import {
    FILE_COMPLEX_TYPE,
    FILE_SIMPLE_TYPE,
    INFONAVIT_TYPE,
    INSURANCE_TYPE,
} from 'common/constants';
import { getIsCiBanco, getIsSerfimex } from 'common/utils/session';

import prepareCreateBright from '../../financialBright/actions/prepareCreate';
import prepareCreateBrove from '../../financialBrove/actions/prepareCreate';
import prepareCreateCeide from '../../financialCeide/actions/prepareCreate';
import prepareCreateCiBanco from '../../financialCiBanco/actions/prepareCreate';
import prepareCreateGalt from '../../financialGalt/actions/prepareCreate';
import prepareCreateRedGirasol from '../../financialRedGirasol/actions/prepareCreate';
import prepareCreateSerfimex from '../../financialSerfimex/actions/prepareCreate';

export default (type) => (dispatch) => {
    const isCibanco = getIsCiBanco();
    const isSerfimex = getIsSerfimex();
    switch (type) {
        case FILE_COMPLEX_TYPE:
            dispatch(prepareCreateRedGirasol());
            break;
        case FILE_SIMPLE_TYPE:
            dispatch(prepareCreateGalt());
            break;
        case INFONAVIT_TYPE:
            dispatch(prepareCreateCeide());
            break;
        case INSURANCE_TYPE:
            dispatch(prepareCreateBrove());
            break;
        default: {
            if (isCibanco) dispatch(prepareCreateCiBanco());
            else if (isSerfimex) dispatch(prepareCreateSerfimex());
            else dispatch(prepareCreateBright());
            break;
        }
    }
};
