import { getOfferCustomTemplate } from 'common/api/templates';

import {
    FETCH_TEMPLATE,
    FETCH_TEMPLATE_SUCCESS,
    FETCH_TEMPLATE_FAILURE,
} from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

export default (id) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch(editTemplateViewActions[FETCH_TEMPLATE]());

        return getOfferCustomTemplate(id)
            .then((response) => {
                dispatch(
                    editTemplateViewActions[FETCH_TEMPLATE_SUCCESS](
                        response.data
                    )
                );
                resolve(response.data);
            })
            .catch((error) => {
                dispatch(
                    editTemplateViewActions[FETCH_TEMPLATE_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                reject();
            });
    });
