import React from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { styled } from '@mui/material/styles';
import is from 'is_js';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton } from 'sunwise-ui';

const ContainerInfo = styled('div')`
    display: flex;
    flex-direction: row;
`;

const ContainerLabel = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    line-height: 12px;
`;

const Label = styled('label')`
    font-size: 14px;
    font-weight: 600;
`;

const getFileName = (document) => {
    let title = '';

    if (!is.undefined(document.title)) title = document.title;
    else if (!is.undefined(document.name)) title = document.name;

    return title;
};

const DocumentItem = ({ document, handleClickUpdate }) => {
    const { t } = useTranslation();

    return (
        <Grid container>
            <Grid item xs>
                <ContainerInfo>
                    <ContainerLabel>
                        <Label sx={{ mb: 1 }}>{getFileName(document)}</Label>

                        <Label>
                            <small>
                                {t('Loaded')} {document.created_at}
                            </small>
                        </Label>
                    </ContainerLabel>
                </ContainerInfo>
            </Grid>

            <Grid item sx={{ display: 'flex', gap: 2 }}>
                <IconButton
                    download={true}
                    href={document.file}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <DownloadIcon />
                </IconButton>

                <IconButton onClick={handleClickUpdate}>
                    <UploadIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

DocumentItem.propTypes = {
    document: PropTypes.object,
    handleClickUpdate: PropTypes.func,
};

export default DocumentItem;
