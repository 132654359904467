import { updateInsurance } from 'common/api/products';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_COVERAGE,
    SAVE_COVERAGE_FAILURE,
    SAVE_COVERAGE_SUCCESS,
} from '../actionTypes';
import { configurationCoveragesActions } from '../reducer';

import fetchCoverages from './fetchCoverages';

export default (filterData, item, value, callback) => (dispatch) => {
    let newValues = {
        id: item.id,
        status_flag: value,
    };

    dispatch(configurationCoveragesActions[SAVE_COVERAGE]());

    updateInsurance(newValues)
        .then((response) => {
            dispatch(
                configurationCoveragesActions[SAVE_COVERAGE_SUCCESS](
                    response.data
                )
            );
            dispatch(fetchCoverages(filterData));
            if (callback) callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                configurationCoveragesActions[SAVE_COVERAGE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
