import { NAME } from './constants';

export const DELETE_NOTIFICATION = `${NAME}/DELETE_NOTIFICATION`;
export const DELETE_NOTIFICATION_FAILURE = `${NAME}/DELETE_NOTIFICATION_FAILURE`;
export const DELETE_NOTIFICATION_SUCCESS = `${NAME}/DELETE_NOTIFICATION_SUCCESS`;

export const FETCH_NOTIFICATIONS = `${NAME}/FETCH_NOTIFICATIONS`;
export const FETCH_NOTIFICATIONS_FAILURE = `${NAME}/FETCH_NOTIFICATIONS_FAILURE`;
export const FETCH_NOTIFICATIONS_SUCCESS = `${NAME}/FETCH_NOTIFICATIONS_SUCCESS`;

export const FETCH_NAV_NOTIFICATIONS = `${NAME}/FETCH_NAV_NOTIFICATIONS`;
export const FETCH_NAV_NOTIFICATIONS_FAILURE = `${NAME}/FETCH_NAV_NOTIFICATIONS_FAILURE`;
export const FETCH_NAV_NOTIFICATIONS_SUCCESS = `${NAME}/FETCH_NAV_NOTIFICATIONS_SUCCESS`;

export const MARK_ALL_NOTIFICATIONS_AS_READ = `${NAME}/MARK_ALL_NOTIFICATIONS_AS_READ`;
export const MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE = `${NAME}/MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE`;
export const MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS = `${NAME}/MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS`;

export const UPDATE_NOTIFICATION = `${NAME}/UPDATE_NOTIFICATION`;
export const UPDATE_NOTIFICATION_FAILURE = `${NAME}/UPDATE_NOTIFICATION_FAILURE`;
export const UPDATE_NOTIFICATION_SUCCESS = `${NAME}/UPDATE_NOTIFICATION_SUCCESS`;
