import React, { useContext, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    editionLevels,
    WidgetSideMenu,
    withTemplateCore,
} from 'sunwise-template-core';
import { Box, Typography } from 'sunwise-ui';

import CollapsedBreadcrumbs from 'common/components/CollapsedBreadcrumbs';
import { LoadingContext } from 'common/utils/contexts';
import { getToken } from 'common/utils/session';

import * as actions from './actions';
import LeftSideMenu from './components/LeftSideMenu';
import PageContainer from './components/PageContainer';
import PageModal from './components/PageModal';
import { handleClonePage } from './helpers';
import * as selectors from './selectors';

const TemplateEditionContainer = styled(Box)(
    ({ theme }) => `
    background:  ${theme.palette.mode === 'dark' ? null : 'rgb(248, 252, 255)'};
    display: block;
    flex: 1;
    position: relative;
`
);

const TemplateWrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    width: 100%;
`;

const InfoContainer = styled(Box)`
    align-items: center;
    display: flex;
    margin: 0 8px;
    max-width: 800px;
    width: 100%;
`;

const TextContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    width: calc(100% - 100px);
`;

const TitleText = styled(Typography)`
    font-size: 24px;
    font-weight: 100;
    line-height: 26px;
    margin-bottom: 2px;
    min-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`;

const DataText = styled(Typography)`
    font-size: 11px;
    line-height: 12px;
    min-height: 12px;
`;

const InfoWrapper = styled(Box)(
    ({ theme }) => `
        background-color: ${theme.palette.mode === 'dark' ? '#1B1C1F' : '#fff'};
        display: flex;
        height: 60px;
        justify-content: center;
        position: relative;
        width: 100%;
`
);

const Container = ({
    addBlankPageInTemplate,
    addNewPages,
    addTemplatePages,
    deletePagesInTemplate,
    duplicatePagesInTemplate,
    fetchTemplateCatalogs,
    handleClickBack,
    initialize,
    initTemplate,
    isEmptyPages,
    isInitialize,
    isInitializing,
    isUpdatingCustomTemplate,
    match,
    orderPagesInTemplate,
    reset,
    selectedPageId,
    selectPage,
    templateCreatedAt,
    templateTitle,
}) => {
    const { t } = useTranslation();
    const loadingContext = useContext(LoadingContext);
    const [newPageModalIsOpen, showNewPageModal] = useState(false);
    const templateId = match.params.uid;

    useEffect(() => {
        initialize(templateId, initTemplate);
        return () => {
            reset();
        };
    }, [templateId]);

    useEffect(() => {
        if (isEmpty(loadingContext)) return null;
        if (isUpdatingCustomTemplate) loadingContext.openLoading(t('Saving'));
        else loadingContext.closeLoading();
    }, [isUpdatingCustomTemplate]);

    if (isInitialize || isInitializing) return null;

    return (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                height: 'calc(100vh - 105px)',
                overflow: 'hidden',
            }}
        >
            <LeftSideMenu
                canUpdate
                deletePagesInTemplate={deletePagesInTemplate}
                duplicatePagesInTemplate={duplicatePagesInTemplate}
                orderPagesInTemplate={orderPagesInTemplate}
                selectPage={selectPage}
                selectedPageId={selectedPageId}
                showNewPageModal={() => showNewPageModal(true)}
            />

            <TemplateEditionContainer>
                <TemplateWrapper>
                    <InfoWrapper>
                        <InfoContainer>
                            <TextContainer>
                                <TitleText title={templateTitle}>
                                    {templateTitle}
                                </TitleText>

                                <DataText pl={1}>
                                    <strong>{t('Created at')}:</strong>{' '}
                                    {templateCreatedAt}
                                </DataText>

                                <Box pl={2}>
                                    <CollapsedBreadcrumbs
                                        current={templateTitle}
                                        locations={[
                                            {
                                                label: t('Template', {
                                                    count: 2,
                                                }),
                                                action: () => handleClickBack(),
                                            },
                                        ]}
                                    />
                                </Box>
                            </TextContainer>
                        </InfoContainer>
                    </InfoWrapper>

                    <PageContainer
                        isEmptyPages={isEmptyPages}
                        isInitializing={isInitializing}
                        onAddPage={addBlankPageInTemplate}
                        selectedPageId={selectedPageId}
                    />
                </TemplateWrapper>
            </TemplateEditionContainer>

            <WidgetSideMenu
                canUpdatePanelsSowing={true}
                canUpdateProjectLocation={true}
            />

            <PageModal
                handleClickClose={() => showNewPageModal(false)}
                isOpen={newPageModalIsOpen}
                onAddPage={addBlankPageInTemplate}
                onClonePage={handleClonePage(
                    templateId,
                    addTemplatePages,
                    addNewPages
                )}
                onEnter={() => fetchTemplateCatalogs()}
            />
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    isInitialize: selectors.getIsInitialize,
    isUpdatingCustomTemplate: selectors.getIsUpdatingCustomTemplate,
});

const mapDispatchToProps = (dispatch) => ({
    addTemplatePages: (customTemplateId, cloneTemplateId, pages, addNewPages) =>
        dispatch(
            actions.addTemplatePages(
                customTemplateId,
                cloneTemplateId,
                pages,
                addNewPages
            )
        ),
    fetchTemplateCatalogs: () => dispatch(actions.fetchTemplateCatalogs()),
    handleClickBack: () => dispatch(actions.handleClickBack()),
    initialize: (id, initTemplate) =>
        dispatch(actions.initialize(id, initTemplate)),
    reset: () => dispatch(actions.reset()),
    updatePage: (templateData) => dispatch(actions.updatePage(templateData)),
    updateTemplate: (templateId, templateData) =>
        dispatch(actions.updateTemplate(templateId, templateData)),
});

Container.propTypes = {
    addBlankPageInTemplate: PropTypes.func,
    addNewPages: PropTypes.func,
    addTemplatePages: PropTypes.func,
    deletePagesInTemplate: PropTypes.func,
    duplicatePagesInTemplate: PropTypes.func,
    fetchTemplateCatalogs: PropTypes.func,
    handleClickBack: PropTypes.func,
    initialize: PropTypes.func,
    initTemplate: PropTypes.func,
    isEmptyPages: PropTypes.bool,
    isInitialize: PropTypes.bool,
    isInitializing: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    match: PropTypes.object,
    orderPagesInTemplate: PropTypes.func,
    reset: PropTypes.func,
    selectedPageId: PropTypes.string,
    selectPage: PropTypes.func,
    templateCreatedAt: PropTypes.string,
    templateTitle: PropTypes.string,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withTemplateCore((props) => ({
        application: 'sunpay',
        baseUrl: process.env.REACT_APP_API_URL,
        editionMode: editionLevels.FULL_EDITION_MODE,
        froalaLicenseKey: process.env.REACT_APP_FROALA_LICENSE,
        googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        language: 'es-mx',
        onChangeInPage: props.updatePage,
        token: getToken(),
        updateTemplatePromise: props.updateTemplate,
    }))
)(Container);
