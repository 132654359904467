import { styled } from '@mui/material/styles';
import { MenuItem } from 'sunwise-ui';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    color: '#fff',
    display: 'flex',
    gap: '8px',
    padding: '16px',
    '& svg': {
        color: '#848bab',
        height: '24px',
        width: '24px',
    },
    '&:hover': {
        '& svg': {
            '& path, & .shape': { fill: '#ffffff' },
            transform: 'scale(1.14)',
        },
    },
    '&.Mui-selected, &.Mui-selected:hover': {
        '& svg path, & svg .shape': { fill: '#ffffff' },
        background: 'transparent',
        borderRight: `4px solid ${
            theme.palette.mode === 'dark' ? '#fff' : theme.palette.primary.main
        }`,
    },
}));

export default StyledMenuItem;
