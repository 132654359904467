import { updateRowEdition } from 'common/api/creditApplications';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { creditsActions } from '../reducer';

import filterCredits from './filterCredits';

export default (filterData, values) => (dispatch) => {
    if (!values.id) return;

    const {
        client_first_lastname,
        client_name,
        project_name,
        client_second_lastname,
    } = values;

    const newValues = {
        first_lastname: client_first_lastname,
        name: client_name,
        project_name: project_name,
        second_lastname: client_second_lastname,
    };

    dispatch(creditsActions[SAVE_ITEM]());

    updateRowEdition(values.id, newValues)
        .then(() => {
            dispatch(creditsActions[SAVE_ITEM_SUCCESS]());
            dispatch(filterCredits(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch(
                creditsActions[SAVE_ITEM_FAILURE](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
