import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider, Grid } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import {
    ReactHookFormCheck,
    ReactHookFormInput,
    ReactHookFormRadioButtonGroup,
    ReactHookFormSelect,
} from 'common/components/form/rhf';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import { TAX_TYPES } from '../constants';
import * as selectors from '../selectors';
import validate from '../validateConfig';

const CoverageConfigForm = ({
    errors,
    filterData,
    initialValues,
    isSaving,
    save,
    selectedCoverage,
    setSelectedCoverage,
}) => {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, watch } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleOnClickSave = (values) =>
        save(
            filterData,
            { ...values, insurance_product: selectedCoverage.id },
            setSelectedCoverage
        );

    const formValues = watch();

    return (
        <form>
            <ReactHookFormInput
                control={control}
                disabled={isSaving}
                label={t('Description')}
                name="description"
            />

            <Grid alignItems="center" container>
                <Grid
                    item
                    sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}
                    xs
                >
                    <ReactHookFormInput
                        append={formValues?.value_type === 0 ? '%' : 'MXN'}
                        control={control}
                        label={t('Amount')}
                        name="amount"
                        onlyNumbers={true}
                        prepend={
                            [1, 2].includes(formValues?.value_type) ? '$' : ''
                        }
                        type="text"
                    />
                </Grid>

                <Grid item md={8} xs={18}>
                    <ReactHookFormSelect
                        control={control}
                        label={t('Unit')}
                        name="value_type"
                        options={[
                            {
                                disabled: true,
                                label: t('Select'),
                                value: '',
                            },
                            {
                                label: `$/kW ${t('Yearly')}`,
                                value: 2,
                            },
                            { label: `$/${t('Fixed')}`, value: 1 },
                            {
                                label: `%/${t('Amount to finance')}`,
                                value: 0,
                            },
                        ]}
                    />
                </Grid>
            </Grid>

            <ReactHookFormInput
                append="%"
                control={control}
                name="annual_increase"
                label={t('Annual increase')}
                onlyNumbers={true}
                type="text"
            />

            <Grid alignItems="center" container>
                <Grid
                    item
                    sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}
                    xs
                >
                    <Box sx={{ '& .MuiCheckbox-root': { p: 0 } }}>
                        <ReactHookFormCheck
                            control={control}
                            name="tax_included"
                        />
                    </Box>

                    <ReactHookFormInput
                        append={
                            formValues?.tax_type === TAX_TYPES.PERCENTAGE
                                ? '%'
                                : 'MXN'
                        }
                        control={control}
                        disabled={!formValues?.tax_included}
                        label={t('Tax')}
                        name="tax_value"
                        onlyNumbers={true}
                        prepend={
                            formValues?.tax_type === TAX_TYPES.AMOUNT ? '$' : ''
                        }
                        type="text"
                    />
                </Grid>

                <Grid item md={8} xs={18}>
                    <ReactHookFormRadioButtonGroup
                        control={control}
                        disabled={!formValues?.tax_included}
                        items={[
                            {
                                label: t('Percentage'),
                                value: TAX_TYPES.PERCENTAGE,
                            },
                            { label: t('Amount'), value: TAX_TYPES.AMOUNT },
                        ]}
                        name="tax_type"
                        row
                    />
                </Grid>
            </Grid>

            <Box hidden>
                <ReactHookFormSelect
                    control={control}
                    disabled
                    label={t('Method of payment')}
                    name="type_cost"
                    options={[
                        {
                            disabled: true,
                            label: t('Select'),
                            value: '',
                        },
                        { label: t('Cash'), value: 0 },
                        { label: t('Funded'), value: 1 },
                    ]}
                />
            </Box>

            <ShowErrors errors={errors} />

            <Divider sx={{ mx: -2 }} />

            <Box sx={{ textAlign: 'right', pt: 2 }}>
                <Button
                    disabled={isSaving}
                    onClick={handleSubmit(handleOnClickSave)}
                >
                    {isSaving ? t('Saving') : t('Save')}
                </Button>
            </Box>
        </form>
    );
};

CoverageConfigForm.propTypes = {
    errors: PropTypes.array,
    filterData: PropTypes.object,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    save: PropTypes.func,
    selectedCoverage: PropTypes.object,
    setSelectedCoverage: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSaveCoverageConfig,
    initialValues: selectors.getInitialValuesCoverageConfigForm,
    isSaving: selectors.getIsSavingCoverageConfig,
});

const mapDispatchToProps = (dispatch) => ({
    save: (filterData, values, setSelectedCoverage) =>
        dispatch(
            actions.saveCoverageConfig(filterData, values, setSelectedCoverage)
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoverageConfigForm);
