import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Card, Grid, Typography } from 'sunwise-ui';

import EmptyMessage from 'common/components/EmptyMessage';
import CheckListItem from 'common/components/form/CheckListItem';

const CatalogDocuments = ({ disabled, documents }) => {
    const { t } = useTranslation();

    if (documents.length === 0)
        return (
            <EmptyMessage>
                <Typography>{t('There are no items to display')}</Typography>
            </EmptyMessage>
        );

    return (
        <Grid item xs>
            <Card>
                <Field
                    component={CheckListItem}
                    disabled={disabled}
                    items={documents}
                    name="catalog_documents"
                    prefixId="catalog_documents"
                />
            </Card>
        </Grid>
    );
};

CatalogDocuments.propTypes = {
    disabled: PropTypes.bool,
    documents: PropTypes.array,
};

export default CatalogDocuments;
