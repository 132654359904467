import initializeForm from './initializeForm';
import showModal from './showModal';

export default (type, profileId, installer, fetchId) => (dispatch) => {
    dispatch(showModal(true));
    dispatch(
        initializeForm({
            fetchId: fetchId,
            installer: installer,
            profile_id: profileId,
            type: type,
        })
    );
};
