import { getConsumptionPdfs } from 'common/api/proposals';

import {
    FETCH_CONSUMPTION_PDFS,
    FETCH_CONSUMPTION_PDFS_FAILURE,
    FETCH_CONSUMPTION_PDFS_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(creditFileActions[FETCH_CONSUMPTION_PDFS]());

    getConsumptionPdfs(id)
        .then((response) =>
            dispatch(
                creditFileActions[FETCH_CONSUMPTION_PDFS_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                creditFileActions[FETCH_CONSUMPTION_PDFS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
