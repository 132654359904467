import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Grid, Typography } from 'sunwise-ui';

import { ReduxFieldSelect } from 'common/components/form/redux';

import {
    getFilteredGroupsIds,
    getGroupIem,
    getPermissionLevelOptions,
} from '../helpers';

const ReduxFieldArray = ({
    category,
    disabled,
    fields,
    formValues,
    label,
    placeholder,
    roleLevel,
}) => {
    const groups = get(formValues, 'groups', []);
    const groupsIds = getFilteredGroupsIds(category, groups);

    return fields.map((item, index) => {
        const group = getGroupIem(formValues, index);
        const newIndex = groupsIds.indexOf(group.id);

        return group.category === category ? (
            <Grid
                alignItems="center"
                container
                key={`field-array-${newIndex}-${Math.random()}`}
                spacing={0}
                sx={{
                    backgroundColor:
                        newIndex % 2 === 0 ? '#eef4fa' : 'transparent',
                    borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                }}
            >
                <Grid item xs={14} py={1}>
                    <Typography variant="caption">{group?.name}</Typography>
                </Grid>

                <Grid item xs={4} py={1}>
                    <Field
                        component={ReduxFieldSelect}
                        disabled={disabled}
                        label={label}
                        name={`${item}.permission_level`}
                        options={getPermissionLevelOptions(roleLevel, group.id)}
                        placeholder={placeholder}
                        sx={{ fontSize: '14px' }}
                        sxBox={{ '& .MuiFormControl-root': { mb: 0 } }}
                        variant="standard"
                    />
                </Grid>
            </Grid>
        ) : null;
    });
};

ReduxFieldArray.propTypes = {
    category: PropTypes.string,
    disabled: PropTypes.bool,
    fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    formValues: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    roleLevel: PropTypes.object,
};

export default ReduxFieldArray;
