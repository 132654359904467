import React from 'react';

import PropTypes from 'prop-types';
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    List,
    ListItem,
} from 'sunwise-ui';

const CheckListItem = ({
    disabled,
    input: { onChange, value: selectedValue },
    items,
    meta: { error, touched },
}) => {
    const handleOnChange = (e) => {
        const value = Object.assign({}, selectedValue, {
            [e.target.value]: e.target.checked,
        });
        if (onChange) onChange(value);
    };

    return (
        <List>
            {items.map((item) => (
                <ListItem key={`item-${item.id}`}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedValue[item.id] == true}
                                    disabled={disabled}
                                    onChange={handleOnChange}
                                    value={item.id}
                                />
                            }
                            label={item.name}
                        />
                    </FormGroup>
                </ListItem>
            ))}

            {error && touched && { error }}
        </List>
    );
};

CheckListItem.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object,
    items: PropTypes.array,
    meta: PropTypes.object,
};

export default CheckListItem;
