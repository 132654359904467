import { getFinancialData } from 'common/api/creditApplications';

import {
    FETCH_FINANCIAL_DATA,
    FETCH_FINANCIAL_DATA_FAILURE,
    FETCH_FINANCIAL_DATA_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (commercialOfferId) => (dispatch) => {
    dispatch(creditFileActions[FETCH_FINANCIAL_DATA]());

    getFinancialData(commercialOfferId)
        .then((response) =>
            dispatch(
                creditFileActions[FETCH_FINANCIAL_DATA_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                creditFileActions[FETCH_FINANCIAL_DATA_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
