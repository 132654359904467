import React, { useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid } from 'sunwise-ui';

import { InfoToolTip } from 'common/components';
import {
    ReactHookFormInput,
    ReactHookFormInputDatePicker2,
    ReactHookFormSelect,
} from 'common/components/form/rhf';

import * as actions from '../actions';
import * as selectors from '../selectors';

import InfoList from './InfoList';

const MIN_DATE = new Date(2005, 0, 1);
const DEBOUNCE_TIME = 500;

const Filters = ({
    companies,
    executives,
    fetchCompanies,
    fetchExecutives,
    filterQuery,
    setFilterQuery,
}) => {
    const { t } = useTranslation();
    const [timer, setTimer] = useState(null);
    const initialValues = { ...filterQuery };

    const { control, reset } = useForm({ defaultValues: initialValues });

    useEffect(() => reset(initialValues), []);

    const handleChange = (value) => {
        clearTimeout(timer);
        setTimer(setTimeout(() => triggerChange(value), DEBOUNCE_TIME));
    };

    const triggerChange = (value) => {
        setFilterQuery((prev) => ({ ...prev, search: value }));
    };

    const handleOnChangeValue = (value, name) => {
        setFilterQuery((prev) => ({ ...prev, [name]: value }));
    };

    useEffect(() => {
        fetchExecutives();
        fetchCompanies();
    }, []);

    return (
        <form>
            <Grid container>
                <Grid item xs={18} md={6}>
                    <ReactHookFormInput
                        control={control}
                        label={' '}
                        name="search"
                        onChange={(e) => handleChange(e.target.value)}
                        placeholder={t('Search')}
                        prepend={<SearchIcon />}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md>
                    <ReactHookFormSelect
                        control={control}
                        name="executive"
                        label={t('Executive')}
                        onChange={(e) =>
                            handleOnChangeValue(e.target.value, 'executive')
                        }
                        options={executives}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md>
                    <ReactHookFormSelect
                        control={control}
                        name="company"
                        label={t('Company')}
                        onChange={(e) =>
                            handleOnChangeValue(e.target.value, 'company')
                        }
                        options={companies}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md>
                    <ReactHookFormInputDatePicker2
                        control={control}
                        disableFuture
                        label={t('Initial date')}
                        minDate={MIN_DATE}
                        name="initial_date"
                        onChange={(e) => handleOnChangeValue(e, 'initial_date')}
                        variant="standard"
                    />
                </Grid>

                <Grid item xs={18} md>
                    <ReactHookFormInputDatePicker2
                        control={control}
                        disableFuture
                        label={t('Final date')}
                        minDate={MIN_DATE}
                        name="final_date"
                        onChange={(e) => handleOnChangeValue(e, 'final_date')}
                        variant="standard"
                    />
                </Grid>

                <Grid item sx={{ display: 'flex' }}>
                    <InfoToolTip variant="no-wrapper">
                        <InfoList />
                    </InfoToolTip>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    companies: selectors.getCompaniesToSelect,
    executives: selectors.getExecutivesToSelect,
    filterItemSelected: selectors.getFilterItemSelected,
});

const mapDispatchToProps = (dispatch) => ({
    fetchExecutives: () => dispatch(actions.fetchExecutives()),
    fetchCompanies: () => dispatch(actions.fetchCompanies()),
});

Filters.propTypes = {
    companies: PropTypes.array,
    executives: PropTypes.array,
    fetchCompanies: PropTypes.func,
    fetchExecutives: PropTypes.func,
    filterQuery: PropTypes.object,
    setFilterQuery: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
