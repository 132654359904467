import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const Wrapper = styled('span')`
    display: inline-block;
`;

const StyledRoundedIcon = styled('div')`
    align-items: center;
    background-color: ${({ bgColor, noBackground }) =>
        noBackground ? `none` : bgColor ? bgColor : `#F8FCFF`};
    border-radius: 30px;
    border: ${({ noBorder, borderColor }) =>
        noBorder
            ? `none`
            : `1px solid ${borderColor ? `${borderColor}` : `#F1F7FD`}`};
    display: flex;
    height: 30px;
    justify-content: center;
    text-align: center;
    width: 30px;
    border-radius: 100%;

    ${({ size }) =>
        size === 'xs' &&
        `
        height: 20px;
        width: 20px;
    `}

    ${({ size }) =>
        size === 'md' &&
        `
        height: 36px;
        width: 36px;
    `}

    ${({ size }) =>
        size === 'lg' &&
        `
        height: 50px;
        width: 50px;
    `}

    ${({ size, customSize }) =>
        size === 'custom' &&
        `
        height: ${customSize}px;
        width: ${customSize}px;
    `}

    ${({ clickable, onClick }) => (clickable || onClick) && `cursor: pointer;`}
`;

const StyledIcon = styled('span')`
    color: ${({ iconColor }) => (iconColor ? iconColor : '#848BAB')};
    display: inline-flex;
    font-size: ${({ size, customIconSize }) =>
        size === 'custom' && customIconSize
            ? `${customIconSize}px`
            : size === 'xs'
            ? `10px`
            : size === 'md'
            ? `15px`
            : size === 'lg'
            ? `25px`
            : `11px`};
`;

const RoundedIcon = ({
    bgColor,
    borderColor,
    className,
    clickable,
    customIconSize,
    customSize,
    icon,
    iconColor,
    iconImg,
    isIconLetter,
    noBackground,
    noBorder,
    onClick,
    size,
}) => (
    <Wrapper>
        <StyledRoundedIcon
            bgColor={bgColor}
            borderColor={borderColor}
            className={className}
            clickable={clickable}
            customSize={customSize}
            noBackground={noBackground}
            noBorder={noBorder}
            onClick={onClick}
            size={size}
        >
            <StyledIcon
                className={isIconLetter || icon}
                customIconSize={customIconSize}
                iconColor={iconColor}
                size={size}
            >
                {iconImg ? (
                    <img alt="" src={iconImg} width="14" />
                ) : (
                    isIconLetter && icon
                )}
            </StyledIcon>
        </StyledRoundedIcon>
    </Wrapper>
);

RoundedIcon.propTypes = {
    bgColor: PropTypes.string,
    borderColor: PropTypes.string,
    className: PropTypes.string,
    clickable: PropTypes.bool,
    customIconSize: PropTypes.number,
    customSize: PropTypes.number,
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconImg: PropTypes.string,
    isIconLetter: PropTypes.bool,
    noBackground: PropTypes.bool,
    noBorder: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.oneOf(['xs', 'md', 'lg', 'custom']),
};

export default RoundedIcon;
