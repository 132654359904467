import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider } from 'sunwise-ui';

import { ReduxFieldInput } from 'common/components/form/redux';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validateUpdateCreditForm';

const FormEditCredit = ({
    handleClickCloseForm,
    handleSubmit,
    isSaving,
    updateCredit,
}) => {
    const { t } = useTranslation();

    const handleOnClickSubmit = (values) =>
        updateCredit({ ...values }, () => {
            handleClickCloseForm();
        });

    return (
        <form onSubmit={handleSubmit(handleOnClickSubmit)}>
            <Field
                component={ReduxFieldInput}
                label={t('Hitch')}
                name="hitch"
                onlyNumbers
                placeholder={t('Enter the down payment amount')}
                prepend="$"
            />

            <Field
                append={t('Months', { count: 2 }).toLowerCase()}
                component={ReduxFieldInput}
                label={t('Term')}
                name="term"
                onlyNumbers
                placeholder={t('Enter the term')}
            />

            <Divider sx={{ mb: 2, mx: -2 }} />

            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                <Button
                    color="secondary"
                    onClick={handleClickCloseForm}
                    variant="text"
                >
                    {t('Cancel')}
                </Button>
                <Button disabled={isSaving} type="submit">
                    {t('Save')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesFormEditCredit,
    isSaving: selectors.getIsSavingItem,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    updateCredit: (values, callback) =>
        dispatch(actions.updateCredit(values, callback)),
});

FormEditCredit.propTypes = {
    handleClickCloseForm: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSaving: PropTypes.bool,
    updateCredit: PropTypes.func,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/edit-credit-form`,
    validate,
})(FormEditCredit);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
