import API from '..';
const ENTITY = '/api/v1/funnel-proposals';

export const getItems = ({ perPage = 7, filterQuery }) =>
    API.get(`${ENTITY}/`, {
        params: { per_page: perPage, ...filterQuery },
    });

export const getNewItems = ({ status, perPage = 7, filterQuery, page }) =>
    API.get(`${ENTITY}/items/`, {
        params: {
            page,
            per_page: perPage,
            status,
            ...filterQuery,
        },
    });
