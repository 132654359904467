import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_FAILURE,
    DELETE_NOTIFICATION_SUCCESS,
    FETCH_NAV_NOTIFICATIONS,
    FETCH_NAV_NOTIFICATIONS_FAILURE,
    FETCH_NAV_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAILURE,
    MARK_ALL_NOTIFICATIONS_AS_READ,
    MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE,
    MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    deleteNotification: {
        isDeleting: false,
        errors: [],
    },
    fetchNavNotifications: {
        isFetching: false,
        data: {
            data: [],
            pagination: {},
        },
        errors: [],
    },
    fetchNotifications: {
        isFetching: false,
        data: {
            data: [],
            pagination: {},
        },
        errors: [],
    },
    markAllAsRead: {
        isUpdating: false,
        errors: [],
    },
    updateNotification: {
        isUpdating: false,
        errors: [],
    },
};

const notificationsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_NOTIFICATION]: (state) => {
            state.deleteNotification.isDeleting = true;
        },
        [DELETE_NOTIFICATION_FAILURE]: (state, action) => {
            state.deleteNotification = {
                ...state.deleteNotification,
                error: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_NOTIFICATION_SUCCESS]: (state) => {
            state.deleteNotification.isDeleting = false;
        },
        [FETCH_NAV_NOTIFICATIONS]: (state) => {
            state.fetchNavNotifications.isFetching = true;
        },
        [FETCH_NAV_NOTIFICATIONS_FAILURE]: (state, action) => {
            state.fetchNavNotifications = {
                ...state.fetchNavNotifications,
                data: {},
                error: action.payload,
                isFetching: false,
            };
        },
        [FETCH_NAV_NOTIFICATIONS_SUCCESS]: (state, action) => {
            state.fetchNavNotifications = {
                ...state.fetchNavNotifications,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [FETCH_NOTIFICATIONS]: (state) => {
            state.fetchNotifications.isFetching = true;
        },
        [FETCH_NOTIFICATIONS_FAILURE]: (state, action) => {
            state.fetchNotifications = {
                ...state.fetchNotifications,
                data: {},
                error: action.payload,
                isFetching: false,
            };
        },
        [FETCH_NOTIFICATIONS_SUCCESS]: (state, action) => {
            state.fetchNotifications = {
                ...state.fetchNotifications,
                data: action.payload,
                errors: [],
                isFetching: false,
            };
        },
        [MARK_ALL_NOTIFICATIONS_AS_READ]: (state) => {
            state.markAllAsRead.isUpdating = true;
        },
        [MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE]: (state, action) => {
            state.markAllAsRead = {
                ...state.markAllAsRead,
                error: action.payload,
                isUpdating: false,
            };
        },
        [MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS]: (state) => {
            state.markAllAsRead.isUpdating = false;
        },
        [UPDATE_NOTIFICATION]: (state) => {
            state.updateNotification.isUpdating = true;
        },
        [UPDATE_NOTIFICATION_FAILURE]: (state, action) => {
            state.updateNotification = {
                ...state.updateNotification,
                error: action.payload,
                isUpdating: false,
            };
        },
        [UPDATE_NOTIFICATION_SUCCESS]: (state) => {
            state.updateNotification.isUpdating = false;
        },
    },
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice.reducer;
