import { getInverterBrands } from 'common/api/productsSunwise';

import {
    FETCH_INVERTER_BRANDS,
    FETCH_INVERTER_BRANDS_FAILURE,
    FETCH_INVERTER_BRANDS_SUCCESS,
} from '../actionTypes';
import { financialActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(financialActions[FETCH_INVERTER_BRANDS]());

    getInverterBrands()
        .then((response) =>
            dispatch(
                financialActions[FETCH_INVERTER_BRANDS_SUCCESS](response.data)
            )
        )
        .catch((error) =>
            dispatch(
                financialActions[FETCH_INVERTER_BRANDS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
