import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider } from 'sunwise-ui';

import { ReduxFieldSelect } from 'common/components/form/redux';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';

const ChangeStatusForm = ({ filterData, handleSubmit, save, statusList }) => {
    const { t } = useTranslation();
    const handleOnClickSave = (values) => save(filterData, values);

    const options = [
        { disabled: true, label: t('Select a status'), value: '' },
        ...statusList,
    ];

    return (
        <form>
            <Field
                component={ReduxFieldSelect}
                label=""
                name="status"
                options={options}
            />

            <Divider sx={{ mb: 2, mx: -2 }} />

            <Box sx={{ textAlign: 'right' }}>
                <Button onClick={handleSubmit(handleOnClickSave)}>
                    {t('Save')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialChangeStatusValues,
});

const mapDispatchToProps = (dispatch) => ({
    save: (filterData, values) =>
        dispatch(actions.bulkChangeStatus(filterData, values)),
});

ChangeStatusForm.propTypes = {
    filterData: PropTypes.object,
    handleSubmit: PropTypes.func,
    save: PropTypes.func,
    statusList: PropTypes.array,
};

const ChangeStatusFormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/change-status-form`,
})(ChangeStatusForm);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangeStatusFormContainer);
