import { styled } from '@mui/material/styles';

export const FlexRow = styled('div')`
    align-items: ${({ alignItems = 'normal' }) => alignItems};
    display: ${({ isVisible = true }) => (isVisible ? 'flex' : 'none')};
    flex-direction: row;
    flex-wrap: ${({ wrap = 'nowrap' }) => wrap};
    justify-content: ${({ justifyContent = 'normal' }) => justifyContent};
`;

export const FlexColumn = styled('div')`
    align-items: ${({ alignItems = 'normal' }) => alignItems};
    display: ${({ isVisible = true }) => (isVisible ? 'flex' : 'none')};
    flex-direction: column;
    flex-wrap: ${({ wrap = 'nowrap' }) => wrap};
    justify-content: ${({ justifyContent = 'normal' }) => justifyContent};
`;

export const Spacer = styled('div')`
    flex-basis: 0;
    flex-grow: 1;
`;
