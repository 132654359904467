import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Tabs, Tab, TabPanel } from 'sunwise-ui';

import { Modal, ShowErrors } from 'common/components';

import Documents from '../financial/components/productForm/Documents';
import Footer from '../financial/components/productForm/Footer';
import Products from '../financial/components/productForm/Products';

import * as actions from './actions';
import Parameters from './components/Parameters';
import { NAME } from './constants';
import * as selectors from './selectors';
import validate from './validate';

const ProductForm = ({
    callback,
    changeInput,
    errors,
    formValues,
    handleSubmit,
    modalIsOpen,
    save,
    toggleModalProduct,
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('parameters');
    const isNewRecord = formValues.id ? false : true;

    const handleOnClickComplete = (values) => save(values, callback);
    const handleChange = (_, newValue) => setSelectedTab(newValue);
    const onExited = () => setSelectedTab('parameters');
    const onHide = () => toggleModalProduct(false);
    const title = `${isNewRecord ? t('Add') : t('Edit')} ${t('Product')}`;

    return (
        <form>
            <Modal
                footerComponent={() => (
                    <Footer
                        isNewRecord={isNewRecord}
                        onClick={handleSubmit(handleOnClickComplete)}
                    />
                )}
                onExited={onExited}
                onHide={onHide}
                show={modalIsOpen}
                size="lg"
                title={title}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        onChange={handleChange}
                        scrollButtons="auto"
                        value={selectedTab}
                        variant="scrollable"
                    >
                        <Tab
                            id="tab-parameters"
                            key="tab-parameters"
                            label={t('Parameters')}
                            value="parameters"
                        />
                        <Tab
                            id="tab-products"
                            key="tab-products"
                            label={t('Product', { count: 2 })}
                            value="products"
                        />
                        <Tab
                            id="tab-documents"
                            key="tab-documents"
                            label={t('Document', { count: 2 })}
                            value="documents"
                        />
                    </Tabs>
                </Box>

                <TabPanel
                    key="tab-parameters"
                    selectedTab={selectedTab}
                    value="parameters"
                >
                    <Parameters
                        changeInput={changeInput}
                        disabled={formValues.disabled}
                        formValues={formValues}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-products"
                    selectedTab={selectedTab}
                    value="products"
                >
                    <Products
                        disabled={formValues.disabled}
                        formValues={formValues}
                    />
                </TabPanel>

                <TabPanel
                    key="tab-documents"
                    selectedTab={selectedTab}
                    value="documents"
                >
                    <Documents disabled={formValues.disabled} />
                </TabPanel>

                {errors && errors.length ? (
                    <div>
                        <ShowErrors errors={errors} />
                    </div>
                ) : null}
            </Modal>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getSaveProductErrors,
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    modalIsOpen: selectors.getProductModalIsOpen,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    save: (values, callback) => dispatch(actions.save(values, callback)),
    toggleModalProduct: (status) =>
        dispatch(actions.toggleModalProduct(status)),
});

ProductForm.propTypes = {
    callback: PropTypes.func,
    changeInput: PropTypes.func,
    errors: PropTypes.array,
    formValues: PropTypes.object,
    handleSubmit: PropTypes.func,
    modalIsOpen: PropTypes.bool,
    save: PropTypes.func,
    toggleModalProduct: PropTypes.func,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(ProductForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
