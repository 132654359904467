import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { numberFormat } from 'common/utils/helpers';

export default (values = {}) => {
    const errors = {};
    if (isEmpty(values.hitch))
        errors.hitch = i18next.t('The field cannot be empty');
    if (values.hitch < 0 || values.hitch > values.amount)
        errors.hitch = i18next.t(
            'The value must be between {{min}} and {{max}}',
            {
                max: numberFormat(values.amount, { style: 'currency' }),
                min: 0,
            }
        );
    if (isEmpty(values.term))
        errors.term = i18next.t('The field cannot be empty');
    if (values.term < 0)
        errors.term = i18next.t('The value must be greater than {{value}}', {
            value: 0,
        });
    return errors;
};
