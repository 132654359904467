import initializeChangeStatusForm from './initializeChangeStatusForm';
import openModalChangeStatus from './openModalChangeStatus';

export default (ids, callback) => (dispatch) => {
    const values = {
        callback,
        credits: ids,
    };
    dispatch(initializeChangeStatusForm(values));
    dispatch(openModalChangeStatus(true));
};
