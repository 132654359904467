import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Card,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from 'sunwise-ui';

import {
    AutocompleteField,
    BulkEditionMenu,
    InputSearch,
    ManageCsvDropdown,
} from 'common/components';

import { getExecutivesOptions } from '../helpers';

const BulkOperationsCard = ({
    canModify,
    downloadFile,
    executives,
    filterData,
    financierProducts,
    handleClickAssignProduct,
    handleOnChangeFilter,
    handleOnChangeSearch,
    isFetchingExecutives,
    prefix,
    selectionModel,
    setSelectionModel,
    statusList,
}) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Body>
                <Grid alignItems="end" container>
                    <Grid item xs={16} lg={8} sx={{ order: 1 }}>
                        <InputSearch
                            onChange={handleOnChangeSearch}
                            placeholder={t('Search')}
                            value={filterData?.searchText}
                        />
                    </Grid>

                    <Grid item md={3} sx={{ order: { lg: 2, xs: 3 } }} xs={18}>
                        <AutocompleteField
                            disabled={isFetchingExecutives}
                            label={
                                isEmpty(filterData?.executiveName)
                                    ? t('All executives')
                                    : t('Executive')
                            }
                            onChange={(value) =>
                                handleOnChangeFilter(value, 'executiveName')
                            }
                            options={getExecutivesOptions(executives)}
                            value={filterData?.executiveName}
                        />
                    </Grid>

                    <Grid item md={3} sx={{ order: { lg: 3, xs: 4 } }} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Financial product')}</InputLabel>
                            <Select
                                label={t('Financial product')}
                                onChange={(e) =>
                                    handleOnChangeFilter(
                                        e.target.value,
                                        'product'
                                    )
                                }
                                value={filterData?.product || 'all'}
                                variant="standard"
                            >
                                <MenuItem
                                    key="financier-product-all"
                                    value="all"
                                >
                                    {t('All text', {
                                        context: 'male',
                                        count: 2,
                                    })}
                                </MenuItem>
                                {financierProducts.map(({ id, name }) => (
                                    <MenuItem
                                        key={`financier-product-${id}-${name}`}
                                        value={id}
                                    >
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item md={3} sx={{ order: { lg: 3, xs: 4 } }} xs={18}>
                        <FormControl fullWidth size="small">
                            <InputLabel>{t('Status')}</InputLabel>
                            <Select
                                label={t('Status')}
                                onChange={(e) =>
                                    handleOnChangeFilter(
                                        e.target.value,
                                        'status'
                                    )
                                }
                                value={filterData?.status || 'all'}
                                variant="standard"
                            >
                                {statusList.map(
                                    ({ label, value, disabled = false }) => (
                                        <MenuItem
                                            disabled={disabled}
                                            key={`agent-select-${value}-${label}`}
                                            value={value}
                                        >
                                            {label}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid
                        item
                        lg={1}
                        sx={{ order: { md: 5, xs: 2 }, textAlign: 'right' }}
                        xs={2}
                    >
                        <ManageCsvDropdown
                            handleDownload={() => downloadFile(filterData)}
                        />
                    </Grid>
                </Grid>

                <Box
                    hidden={isEmpty(selectionModel) || !canModify}
                    sx={{ mt: 2 }}
                >
                    <BulkEditionMenu
                        items={[
                            {
                                label: t('Assign product'),
                                handleClick: () =>
                                    handleClickAssignProduct(
                                        selectionModel,
                                        () => setSelectionModel([])
                                    ),
                                visible: true,
                            },
                        ]}
                        prefix={prefix}
                        setSelectionModel={setSelectionModel}
                    />
                </Box>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    canModify: PropTypes.bool,
    downloadFile: PropTypes.func,
    executives: PropTypes.array,
    filterData: PropTypes.object,
    financierProducts: PropTypes.array,
    handleClickAssignProduct: PropTypes.func,
    handleOnChangeFilter: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    isFetchingExecutives: PropTypes.bool,
    prefix: PropTypes.string,
    selectionModel: PropTypes.array,
    setSelectionModel: PropTypes.func,
    statusList: PropTypes.array,
};

export default BulkOperationsCard;
