import { updateRequiredDocuments } from 'common/api/documentCatalog';
import {
    handleCheckboxField,
    showReponseErrorsAsAlert,
} from 'common/utils/helpers';

import {
    SAVE_DOCUMENT,
    SAVE_DOCUMENT_FAILURE,
    SAVE_DOCUMENT_SUCCESS,
} from '../../actionTypes';
import { installerProfileActions } from '../../reducer';

export default (values, callback) => (dispatch) => {
    const newValues = {
        documents: handleCheckboxField(values.catalog_documents),
    };

    dispatch(installerProfileActions[SAVE_DOCUMENT]());

    updateRequiredDocuments(values.installerId, newValues)
        .then(() => {
            dispatch(installerProfileActions[SAVE_DOCUMENT_SUCCESS]());
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                installerProfileActions[SAVE_DOCUMENT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
