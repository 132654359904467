export const ACADEMY_LANGUAGE_CODE_EQUIVALENCE = {
    en: 'en',
    es: 'es',
    pt: 'pt-BR',
};

export const DATE_FORMATS = {
    en: 'MM/dd/yyyy',
    es: 'dd/MM/yyyy',
    pt: 'dd/MM/yyyy',
};

export const DEFAULT_LANGUAGE = 'en-us';

export const SUPPORTED_LANGUAGES = {
    en: 'en-us',
    es: 'es-mx',
    pt: 'pt-br',
};
