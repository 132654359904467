import { fetchListCatalogs } from 'common/api/documentCatalog';
import { PRIVATE_DOCUMENTS_TYPE } from 'common/constants';

import {
    FETCH_DOCUMENTS_TYPE_FAILURE,
    FETCH_DOCUMENTS_TYPE,
    FETCH_DOCUMENTS_TYPE_SUCCESS,
} from '../actionTypes';
import { privateDocumentsActions } from '../reducer';

export default (itemId, type) => (dispatch) => {
    dispatch(privateDocumentsActions[FETCH_DOCUMENTS_TYPE]());

    fetchListCatalogs(itemId)
        .then((response) => {
            const dataFiltered = response.data.data.filter(
                (item) =>
                    item.category === type &&
                    item.type === PRIVATE_DOCUMENTS_TYPE
            );

            dispatch(
                privateDocumentsActions[FETCH_DOCUMENTS_TYPE_SUCCESS](
                    dataFiltered
                )
            );
        })
        .catch((error) =>
            dispatch(
                privateDocumentsActions[FETCH_DOCUMENTS_TYPE_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
