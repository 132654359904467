import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    DUPLICATE_TEMPLATE,
    DUPLICATE_TEMPLATE_FAILURE,
    DUPLICATE_TEMPLATE_SUCCESS,
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_IS_OPEN_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    bulkUpdateItems: { errors: [], isFetching: false },
    duplicateTemplate: { errors: [], isFetching: true },
    fetchTemplates: {
        data: { data: [], paginaton: {} },
        error: null,
        isFetching: false,
    },
    initialValues: { language: 'es-mx', name: '' },
    isOpenModal: false,
    saveItem: { errors: [], isSaving: false },
};

const templatesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems.isFetching = true;
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                isFetching: false,
                errors: action.payload,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state) => {
            state.bulkUpdateItems.isFetching = false;
        },
        [DUPLICATE_TEMPLATE]: (state) => {
            state.duplicateTemplate.isFetching = true;
        },
        [DUPLICATE_TEMPLATE_FAILURE]: (state, action) => {
            state.duplicateTemplate = {
                ...state.duplicateTemplate,
                isFetching: false,
                errors: action.payload,
            };
        },
        [DUPLICATE_TEMPLATE_SUCCESS]: (state) => {
            state.duplicateTemplate.isFetching = false;
        },
        [FETCH_TEMPLATES]: (state) => {
            state.fetchTemplates.isFetching = true;
        },
        [FETCH_TEMPLATES_FAILURE]: (state, action) => {
            state.fetchTemplates = {
                ...state.fetchTemplates,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATES_SUCCESS]: (state, action) => {
            state.fetchTemplates = {
                ...state.fetchTemplates,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveItem.errors = [];
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem.isSaving = true;
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state) => {
            state.saveItem.isSaving = false;
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const templatesActions = templatesSlice.actions;

export default templatesSlice.reducer;
