import React from 'react';

import PersonIcon from '@mui/icons-material/Person';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Grid, Typography } from 'sunwise-ui';

import { RoundedIcon } from 'common/components';

const ContactPreview = ({ contact }) => (
    <Grid container alignItems="center">
        <Grid item>
            <RoundedIcon
                bgColor="#FFF"
                borderColor="#D3D7EB"
                icon={<PersonIcon />}
                iconColor="#FF9A00"
                isIconLetter
                size="md"
            />
        </Grid>
        <Grid item xs>
            <Grid container>
                <Grid item lg={4}>
                    <Typography fontWeight="bold" variant="body2">
                        {contact.first_name} {contact.last_name}
                    </Typography>
                </Grid>

                <Grid item lg={3}>
                    {get(contact, 'company_group.name', '')}
                </Grid>

                <Grid item lg={4}>
                    {get(contact, 'phone', '')}
                </Grid>

                <Grid item lg={7}>
                    {get(contact, 'email', '')}
                </Grid>
            </Grid>
        </Grid>
    </Grid>
);

ContactPreview.propTypes = { contact: PropTypes.object };

export default ContactPreview;
