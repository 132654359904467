import initializeForm from './initializeForm';
import showModal from './showModal';

export default (document, type, profileId, installer, fetchId) =>
    (dispatch) => {
        const values = {
            catalog_document:
                document.financier_alliance_installer_catalog_document
                    ? document.financier_alliance_installer_catalog_document.id
                    : document.catalog_document
                    ? document.catalog_document
                    : null,
            fetchId: fetchId,
            id: document && document.id ? document.id : null,
            installer: installer,
            profile_id: profileId,
            type: type,
        };

        dispatch(initializeForm(values));
        dispatch(showModal(true));
    };
