import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getInitialValuesTermsForm = createSelector(
    getModel,
    (model) => model.initialValuesTermsForm
);

export const getCompaniesCatalogs = createSelector(
    getModel,
    (model) => model.companiesCatalogs
);

export const getDataCompaniesCatalogs = createSelector(
    getCompaniesCatalogs,
    (model) => model.data
);

export const getIsFetchingCompaniesCatalogs = createSelector(
    getCompaniesCatalogs,
    (model) => model.isFetching
);

export const getIsOpenModal = createSelector(
    getModel,
    (model) => model.isOpenModal
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors
);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving
);

export const getType = createSelector(getModel, (model) => model.type);

export const getCloseInEscape = createSelector(
    getModel,
    (model) => model.closeInEscape
);
