import React from 'react';

import PropTypes from 'prop-types';
import { Divider, Grid } from 'sunwise-ui';

const HeaderContainer = ({ children }) => (
    <React.Fragment>
        <Grid container sx={{ my: 1 }}>
            <Grid item xs>
                {children}
            </Grid>
        </Grid>

        <Divider sx={{ mb: 2 }} />
    </React.Fragment>
);

HeaderContainer.propTypes = {
    children: PropTypes.node,
};

export default HeaderContainer;
