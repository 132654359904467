import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Grid, IconButton, Typography } from 'sunwise-ui';

import { Modal } from 'common/components';
import { INFONAVIT_TYPE } from 'common/constants';
import useToggle from 'common/hooks/useToggle';
import { getIsSerfimex } from 'common/utils/session';

import FinancialTable from './FinancialTable';
import FormEditCredit from './FormEditCredit';
import ProductFileRequest from './ProductFileRequest';

const CreditRequestSummaryCard = ({
    canModify,
    commercialOfferId,
    creditItem,
    downloadFinancialData,
    financierProduct,
    parameters,
    prepareCreditUpdateForm,
    primaTotal,
    showEditButton = false,
    subTotal,
    total,
}) => {
    const { t } = useTranslation();
    const { value: modalDisplayed, toggle: handleClickToggle } =
        useToggle(false);

    const { value: modalEditCredit, toggle: handleOnClickEditCredit } =
        useToggle(false);

    const { value: modalInfonavitIsOpen, toggle: handleClickModalInfonavit } =
        useToggle(false);

    const handleOnShowModalEditCredit = () => handleOnClickEditCredit(true);
    const handleOnHideModalEditCredit = () => handleOnClickEditCredit(false);
    const handleOnHideModal = () => handleClickToggle(false);
    const handleOnShowModal = () => handleClickToggle(true);

    const handleOnShowModalInfonavit = () => handleClickModalInfonavit(true);
    const handleOnHideModalInfonavit = () => handleClickModalInfonavit(false);

    const creditType = get(creditItem, 'credit_type', null);
    const infonavitData = JSON.parse(
        get(creditItem, 'infonavit_data', '{}') || '{}'
    );
    const isInfonavitType = parseInt(creditType) === INFONAVIT_TYPE;
    const isSerfimex = getIsSerfimex();

    return (
        <Box>
            <Card>
                <Card.Header>
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography fontWeight="bold" variant="body2">
                                {t('Request')}
                            </Typography>
                            <Typography variant="body2">
                                {financierProduct?.name}
                            </Typography>
                        </Grid>

                        {showEditButton && !isInfonavitType && !isSerfimex && (
                            <Grid hidden={!canModify} item>
                                <IconButton
                                    color="secondary"
                                    onClick={handleOnShowModalEditCredit}
                                    variant="text"
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Card.Header>

                <ProductFileRequest
                    handleOnShowModal={handleOnShowModal}
                    handleOnShowModalInfonavit={handleOnShowModalInfonavit}
                    infonavitData={infonavitData}
                    parameters={parameters}
                    primaTotal={primaTotal}
                    subTotal={subTotal}
                    total={total}
                    type={parseInt(creditType)}
                />

                <Modal
                    onHide={handleOnHideModalEditCredit}
                    onEnter={prepareCreditUpdateForm}
                    show={modalEditCredit}
                    size="md"
                    title={t('Edit')}
                >
                    <FormEditCredit
                        handleClickCloseForm={handleOnHideModalEditCredit}
                    />
                </Modal>

                <Modal
                    onHide={handleOnHideModal}
                    show={modalDisplayed}
                    size="lg"
                    title={t('Run')}
                >
                    <FinancialTable
                        commercialOfferId={commercialOfferId}
                        downloadFinancialData={downloadFinancialData}
                    />
                </Modal>

                <Modal
                    onHide={handleOnHideModalInfonavit}
                    show={modalInfonavitIsOpen}
                    size="lg"
                    title={t('Request')}
                >
                    {infonavitData && infonavitData.extra && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: infonavitData.extra.table,
                            }}
                        ></div>
                    )}
                </Modal>
            </Card>
        </Box>
    );
};

CreditRequestSummaryCard.propTypes = {
    canModify: PropTypes.bool,
    commercialOfferId: PropTypes.string,
    creditItem: PropTypes.object,
    downloadFinancialData: PropTypes.func,
    financierProduct: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    parameters: PropTypes.object,
    prepareCreditUpdateForm: PropTypes.func,
    primaTotal: PropTypes.number,
    showEditButton: PropTypes.bool,
    subTotal: PropTypes.number,
    total: PropTypes.number,
};

export default CreditRequestSummaryCard;
