import { styled } from '@mui/material/styles';
import { Grid } from 'sunwise-ui';

import { custom1 } from 'common/utils/customScrolls';

export default styled(Grid)`
    box-shadow: 0 2px 12px 0 rgba(129, 158, 200, 0.06);
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 16px;

    ${custom1}
`;
