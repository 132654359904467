import get from 'lodash/get';

import { getCreditApplication } from 'common/api/creditApplications';

import {
    FETCH_CREDIT_APPLICATION,
    FETCH_CREDIT_APPLICATION_FAILURE,
    FETCH_CREDIT_APPLICATION_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

import initializeAssignUserForm from './initializeAssignUserForm';

export default (id) => (dispatch) => {
    if (!id) return;

    dispatch(creditFileActions[FETCH_CREDIT_APPLICATION]());

    getCreditApplication(id)
        .then((response) => {
            dispatch(
                creditFileActions[FETCH_CREDIT_APPLICATION_SUCCESS](
                    response.data
                )
            );
            dispatch(
                initializeAssignUserForm({
                    user: get(response.data, 'financial_advisor.id', null),
                })
            );
        })
        .catch((error) =>
            dispatch(
                creditFileActions[FETCH_CREDIT_APPLICATION_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
