import { NAME } from './constants';

export const BULK_UPDATE_ITEMS = `${NAME}/BULK_UPDATE_ITEMS`;
export const BULK_UPDATE_ITEMS_FAILURE = `${NAME}/BULK_UPDATE_ITEMS_FAILURE`;
export const BULK_UPDATE_ITEMS_SUCCESS = `${NAME}/BULK_UPDATE_ITEMS_SUCCESS`;

export const FETCH_ACCESSORIES = `${NAME}/FETCH_ACCESSORIES`;
export const FETCH_ACCESSORIES_FAILURE = `${NAME}/FETCH_ACCESSORIES_FAILURE`;
export const FETCH_ACCESSORIES_SUCCESS = `${NAME}/FETCH_ACCESSORIES_SUCCESS`;

export const FETCH_DOCUMENTS = `${NAME}/FETCH_DOCUMENTS`;
export const FETCH_DOCUMENTS_FAILURE = `${NAME}/FETCH_DOCUMENTS_FAILURE`;
export const FETCH_DOCUMENTS_SUCCESS = `${NAME}/FETCH_DOCUMENTS_SUCCESS`;

export const FETCH_INVERTER_BRANDS = `${NAME}/FETCH_INVERTER_BRANDS`;
export const FETCH_INVERTER_BRANDS_FAILURE = `${NAME}/FETCH_INVERTER_BRANDS_FAILURE`;
export const FETCH_INVERTER_BRANDS_SUCCESS = `${NAME}/FETCH_INVERTER_BRANDS_SUCCESS`;

export const FETCH_INVERTERS = `${NAME}/FETCH_INVERTERS`;
export const FETCH_INVERTERS_FAILURE = `${NAME}/FETCH_INVERTERS_FAILURE`;
export const FETCH_INVERTERS_SUCCESS = `${NAME}/FETCH_INVERTERS_SUCCESS`;

export const FETCH_PANEL_BRANDS = `${NAME}/FETCH_PANEL_BRANDS`;
export const FETCH_PANEL_BRANDS_FAILURE = `${NAME}/FETCH_PANEL_BRANDS_FAILURE`;
export const FETCH_PANEL_BRANDS_SUCCESS = `${NAME}/FETCH_PANEL_BRANDS_SUCCESS`;

export const FETCH_PANELS = `${NAME}/FETCH_PANELS`;
export const FETCH_PANELS_FAILURE = `${NAME}/FETCH_PANELS_FAILURE`;
export const FETCH_PANELS_SUCCESS = `${NAME}/FETCH_PANELS_SUCCESS`;

export const FETCH_PRODUCTS = `${NAME}/FETCH_PRODUCTS`;
export const FETCH_PRODUCTS_FAILURE = `${NAME}/FETCH_PRODUCTS_FAILURE`;
export const FETCH_PRODUCTS_SUCCESS = `${NAME}/FETCH_PRODUCTS_SUCCESS`;

export const FETCH_TEMPLATE_CATALOGS = `${NAME}/FETCH_TEMPLATE_CATALOGS`;
export const FETCH_TEMPLATE_CATALOGS_FAILURE = `${NAME}/FETCH_TEMPLATE_CATALOGS_FAILURE`;
export const FETCH_TEMPLATE_CATALOGS_SUCCESS = `${NAME}/FETCH_TEMPLATE_CATALOGS_SUCCESS`;

export const DUPLICATE_PRODUCT = `${NAME}/DUPLICATE_PRODUCT`;
export const DUPLICATE_PRODUCT_FAILURE = `${NAME}/DUPLICATE_PRODUCT_FAILURE`;
export const DUPLICATE_PRODUCT_SUCCESS = `${NAME}/DUPLICATE_PRODUCT_SUCCESS`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;
