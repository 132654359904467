import React, { forwardRef, useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import es from 'date-fns/locale/es';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TextField } from 'sunwise-ui';

import LabelError from 'common/components/LabelError';
import { parseDate, formatDate } from 'common/utils/dates';

const StyledInputField = styled('div')`
    .react-datepicker-wrapper,
    .react-datepicker-wrapper .react-datepicker__input-container {
        width: 100%;
    }
`;

const CustomInput = ({
    disabled,
    error,
    fullWidth,
    label,
    onChange,
    onClick,
    value,
}) => (
    <TextField
        {...{ disabled, onClick, onChange, value }}
        autoComplete="off"
        error={Boolean(error)}
        fullWidth={fullWidth}
        helperText={error?.message}
        label={label}
    />
);

CustomInput.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.object,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.string,
};

const CustomInputRefered = forwardRef((props, ref) => (
    <CustomInput {...props} myForwardedRef={ref} />
));

CustomInputRefered.displayName = 'CustomInputRefered';

const InputDatePicker = ({
    append,
    disabled,
    fullWidth,
    input,
    label,
    maxDate,
    meta: { error, touched },
    minDate,
    name,
    onChange,
    placeholder,
    prepend,
    variant,
}) => {
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        if (input.value) {
            setSelectedDate(parseDate(input.value, 'dd/MM/yyyy'));
        }
    }, [input]);

    const handleChange = (date) => {
        if (date) {
            setSelectedDate(date);
            input.onChange(formatDate(date, 'dd/MM/yyyy'));
        } else input.onChange(formatDate(selectedDate, 'dd/MM/yyyy'));
    };

    return (
        <StyledInputField>
            <DatePicker
                customInput={
                    <CustomInputRefered
                        append={append}
                        disabled={disabled}
                        fullWidth={fullWidth}
                        label={label}
                        name={name}
                        prepend={prepend}
                        variant={variant}
                    />
                }
                dateFormat="dd/MM/yyyy"
                dateFormatCalendar="MMMM"
                locale={es}
                maxDate={maxDate}
                minDate={minDate}
                onChange={onChange ? onChange : handleChange}
                placeholderText={placeholder}
                readOnly={disabled}
                scrollableYearDropdown
                selected={selectedDate}
                showYearDropdown
            />

            {error && touched && <LabelError type="error">{error}</LabelError>}
        </StyledInputField>
    );
};

InputDatePicker.propTypes = {
    append: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date),
    meta: PropTypes.object,
    minDate: PropTypes.instanceOf(Date),
    name: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    prepend: PropTypes.string,
    variant: PropTypes.string,
};

export default InputDatePicker;
