import i18next from 'i18next';

import { deleteLocation } from 'common/api/creditApplications';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DELETE_LOCATION,
    DELETE_LOCATION_FAILURE,
    DELETE_LOCATION_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

import fetchCreditApplication from './fetchCreditApplication';
import resetLocationForm from './resetLocationForm';

export default (id, creditId) => (dispatch) => {
    dispatch(creditFileActions[DELETE_LOCATION]());

    deleteLocation(id)
        .then(() => {
            dispatch(creditFileActions[DELETE_LOCATION_SUCCESS]());
            dispatch(fetchCreditApplication(creditId));
            dispatch(resetLocationForm());
            showToast({
                body: i18next.t('It was successfully removed'),
            });
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch(
                creditFileActions[DELETE_LOCATION_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
