import React from 'react';

import PropTypes from 'prop-types';

import { INFONAVIT_TYPE, INSURANCE_TYPE } from 'common/constants';

import InfonavitRequestDetail from './InfonavitRequestDetail';
import InsuranceRequestDetail from './InsuranceRequestDetail';
import RequestDetail from './RequestDetail';

const ProductFileRequest = ({
    handleOnShowModal,
    handleOnShowModalInfonavit,
    infonavitData,
    parameters,
    primaTotal,
    subTotal,
    total,
    type,
}) => {
    switch (type) {
        case INFONAVIT_TYPE:
            return (
                <InfonavitRequestDetail
                    amount={parameters?.amount}
                    handleOnShowModalInfonavit={handleOnShowModalInfonavit}
                    infonavitData={infonavitData}
                />
            );
        case INSURANCE_TYPE:
            return (
                <InsuranceRequestDetail
                    handleOnShowModal={handleOnShowModal}
                    primaTotal={primaTotal}
                    subTotal={subTotal}
                    term={parameters?.term}
                    total={total}
                />
            );
        default:
            return (
                <RequestDetail
                    handleOnShowModal={handleOnShowModal}
                    parameters={parameters}
                />
            );
    }
};

ProductFileRequest.propTypes = {
    amount: PropTypes.number,
    handleOnShowModal: PropTypes.func,
    handleOnShowModalInfonavit: PropTypes.func,
    infonavitData: PropTypes.object,
    parameters: PropTypes.object,
    primaTotal: PropTypes.number,
    subTotal: PropTypes.number,
    total: PropTypes.number,
    type: PropTypes.number,
};

export default ProductFileRequest;
