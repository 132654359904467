import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'redux-form';
import { Box, Chip } from 'sunwise-ui';

import { getScrollClasses } from '../helpers';

import ReduxFieldArray from './ReduxFieldArray';

const PermissionList = ({
    changeInput,
    formValues,
    isDisabled,
    isFetching,
    roleLevel,
}) => {
    const { t } = useTranslation();

    if (isFetching)
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );

    const categories = get(formValues, 'categories', []);

    return (
        <Box display="flex" flexDirection="column" gap={2} p={2}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: 2,
                    overflowX: 'auto',
                    py: 2,
                    width: '100%',
                    ...getScrollClasses(),
                }}
            >
                {categories.map((item, index) => (
                    <Chip
                        className={
                            item.id === formValues?.category ? 'selected' : ''
                        }
                        key={`chip-${index}-${Math.random()}`}
                        label={item?.name?.toLowerCase()}
                        onClick={() => changeInput('category', item?.id)}
                        sx={{
                            '&.MuiChip-outlined': {
                                borderColor: '#20445A',
                                color: '#20445A',
                                textTransform: 'capitalize',
                                fontWeight: 600,
                                '&.selected, &:focus, &:hover': {
                                    background: 'rgb(169,181,192)',
                                    color: '#1F3C53',
                                },
                            },
                        }}
                        variant="outlined"
                    />
                ))}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    maxHeight: '400px',
                    minHeight: '400px',
                    overflowY: 'auto',
                    pr: 1,
                    width: '100%',
                    ...getScrollClasses(),
                }}
            >
                <FieldArray
                    category={formValues?.category}
                    component={ReduxFieldArray}
                    disabled={isDisabled}
                    formValues={formValues}
                    name="groups"
                    placeholder={t('Level')}
                    roleLevel={roleLevel}
                />
            </Box>
        </Box>
    );
};

PermissionList.propTypes = {
    changeInput: PropTypes.func,
    formValues: PropTypes.object,
    isDisabled: PropTypes.bool,
    isFetching: PropTypes.bool,
    roleLevel: PropTypes.object,
};

export default PermissionList;
