import { SET_SMART_DOCUMENT_FORM_MODE } from '../actionTypes';
import { SMART_DOCUMENT_FORM_RENAME_MODE } from '../constants';
import { templateDocumentsActions } from '../reducer';

import initializeSmartDocumentForm from './initializeDocumentForm';
import openDocumentModal from './openDocumentModal';

export default (name, id) => (dispatch) => {
    dispatch(
        templateDocumentsActions[SET_SMART_DOCUMENT_FORM_MODE](
            SMART_DOCUMENT_FORM_RENAME_MODE
        )
    );
    dispatch(initializeSmartDocumentForm({ id, name }));

    return dispatch(openDocumentModal(true));
};
