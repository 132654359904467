import React from 'react';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { GridRowModes } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Tooltip } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/DropdownCardMenu';

const ActionsFormatter = ({
    canDelete,
    canModify,
    filterData,
    handleClickBulkItems,
    handleClickDuplicate,
    handleClickUpdate,
    row,
    rowModesModel,
    selectedRowId,
    setRowModesModel,
    setSelectedRowId,
}) => {
    const { t } = useTranslation();
    const isArchived = row?.status_flag === 'archived';
    const isInEditMode = rowModesModel[row?.id]?.mode === GridRowModes.Edit;

    const handleClickSave = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View },
        });
        setSelectedRowId(null);
    };

    const handleClickCancel = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        setSelectedRowId(null);
    };

    const renderOptions = () => {
        if (isInEditMode) {
            return (
                <>
                    <Tooltip title="Guardar cambios">
                        <IconButton onClick={handleClickSave(selectedRowId)}>
                            <SaveIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Cancelar">
                        <IconButton onClick={handleClickCancel(selectedRowId)}>
                            <CancelIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            );
        }

        return (
            <DropdownCardMenu
                items={[
                    {
                        handleClick: () => handleClickUpdate(row),
                        icon: <EditIcon fontSize="small" />,
                        label: t('Edit'),
                        visible: canModify,
                    },
                    {
                        handleClick: () => handleClickDuplicate(row),
                        icon: <ContentCopyIcon fontSize="small" />,
                        label: t('Duplicate'),
                        visible: canModify,
                    },
                    {
                        handleClick: () =>
                            handleClickBulkItems(
                                [row.id],
                                'archived',
                                filterData,
                                () => {}
                            ),
                        icon: <ArchiveOutlinedIcon fontSize="small" />,
                        label: t('Archive'),
                        visible: !isArchived && canDelete,
                    },
                    {
                        handleClick: () =>
                            handleClickBulkItems(
                                [row.id],
                                'active',
                                filterData,
                                () => {}
                            ),
                        icon: <UnarchiveOutlinedIcon fontSize="small" />,
                        label: t('Unarchive'),
                        visible: isArchived && canDelete,
                    },
                    {
                        handleClick: () =>
                            handleClickBulkItems(
                                [row.id],
                                'deleted',
                                filterData,
                                () => {}
                            ),
                        icon: <DeleteIcon fontSize="small" />,
                        label: t('Delete'),
                        visible: isArchived && canDelete,
                    },
                ]}
                visible={canModify || canDelete}
            />
        );
    };

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
            }}
        >
            {renderOptions()}
        </Box>
    );
};

ActionsFormatter.propTypes = {
    canDelete: PropTypes.bool,
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    handleClickBulkItems: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    row: PropTypes.object,
    rowModesModel: PropTypes.object,
    selectedRowId: PropTypes.string,
    setRowModesModel: PropTypes.func,
    setSelectedRowId: PropTypes.func,
};

export default ActionsFormatter;
