import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Fields } from 'redux-form';
import { Box, Button } from 'sunwise-ui';

import SelectInputField from 'common/components/form/SelectInputField';

const PhoneFieldList = ({ fields, placeholder, options }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {fields.map((phones, index) => (
                <Box alignItems="center" display="flex" gap={2} key={index}>
                    <Fields
                        component={SelectInputField}
                        inputFieldMaxLenght={32}
                        inputFieldName={`${phones}.telephone`}
                        names={[`${phones}.telephone`, `${phones}.phone_type`]}
                        options={options}
                        placeholder={`${placeholder} #${index + 1}`}
                        selectFieldName={`${phones}.phone_type`}
                    />

                    {index > 0 && (
                        <Button
                            color="secondary"
                            onClick={() => fields.remove(index)}
                            variant="text"
                        >
                            {t('Delete')}
                        </Button>
                    )}
                </Box>
            ))}

            <Box sx={{ textAlign: 'right' }}>
                <Button
                    color="secondary"
                    onClick={() => fields.push({})}
                    variant="text"
                >
                    {t('Add phone')}
                </Button>
            </Box>
        </React.Fragment>
    );
};

PhoneFieldList.propTypes = {
    fields: PropTypes.object,
    options: PropTypes.array,
    placeholder: PropTypes.string,
};

export default PhoneFieldList;
