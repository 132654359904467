import React, { useEffect, useState } from 'react';

import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { UiDialog as Dialog } from 'sunwise-ui';

import * as actions from '../actions';
import * as selectors from '../selectors';

import ProductListItems from './ProductListItems';

const ProductsModal = ({
    allianceId,
    alliancesFilterData,
    bulkAssignProducts,
    fetchFinancialProducts,
    financialProducts,
    financialProductsPagination,
    handleCloseAssignProductModal,
    initialValues,
    isFetchingFinancialProducts,
    isOpenAssignProductModal,
}) => {
    const { t } = useTranslation();
    const [filterData, setFilterData] = useState({
        orderBy: 'created_at',
        page: 0,
        pageSize: 25,
        sortBy: 'desc',
    });

    useEffect(() => {
        if (isOpenAssignProductModal)
            if (!isNull(allianceId)) fetchFinancialProducts(allianceId);
            else fetchFinancialProducts();
    }, [isOpenAssignProductModal]);

    return (
        <Dialog
            onClose={() => handleCloseAssignProductModal()}
            open={isOpenAssignProductModal}
            size="sm"
            title={t('Assign product')}
        >
            <ProductListItems
                alliancesFilterData={alliancesFilterData}
                bulkAssignProducts={bulkAssignProducts}
                filterData={filterData}
                filterItems={fetchFinancialProducts}
                initialValues={initialValues}
                isFetching={isFetchingFinancialProducts}
                items={financialProducts}
                paginationData={financialProductsPagination}
                setFilterData={setFilterData}
            />
        </Dialog>
    );
};

ProductsModal.propTypes = {
    allianceId: PropTypes.string,
    alliancesFilterData: PropTypes.object,
    bulkAssignProducts: PropTypes.func,
    fetchFinancialProducts: PropTypes.func,
    financialProducts: PropTypes.array,
    financialProductsPagination: PropTypes.object,
    handleCloseAssignProductModal: PropTypes.func,
    initialValues: PropTypes.object,
    isFetchingFinancialProducts: PropTypes.bool,
    isOpenAssignProductModal: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
    financialProducts: selectors.getFinancialProductsData,
    financialProductsPagination: selectors.getFinancialProductsPagination,
    initialValues: selectors.getInitialAssignProductValues,
    isFetchingFinancialProducts: selectors.getIsFetchingFinancialProducts,
    isOpenAssignProductModal: selectors.getIsOpenAssignProductModal,
});

const mapDispatchToProps = (dispatch) => ({
    bulkAssignProducts: (filterData, values) =>
        dispatch(actions.bulkAssignProducts(filterData, values)),
    fetchFinancialProducts: (allianceId) =>
        dispatch(actions.fetchFinancialProducts(allianceId)),
    handleCloseAssignProductModal: () =>
        dispatch(actions.closeAssingProductModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsModal);
