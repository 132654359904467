import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from 'sunwise-ui';

import yupResolver from 'common/utils/yupResolver';

import validate from '../validate';

import Input from './Input';
import PasswordInput from './PasswordInput';

const Loginform = ({ errors, onSubmit, isLoadingSession }) => {
    const { t } = useTranslation();
    const [typeFieldPassword, setTypeFieldPassword] = useState('password');
    const showPassword = typeFieldPassword === 'text';

    const { control, handleSubmit, watch } = useForm({
        defaultValues: { email: '', password: '' },
        resolver: yupResolver(validate),
    });

    const handleClickShowPassoword = () => {
        if (showPassword) setTypeFieldPassword('password');
        else setTypeFieldPassword('text');
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input
                control={control}
                label={t('Email')}
                name="email"
                type="email"
            />

            <PasswordInput
                control={control}
                formValues={watch()}
                handleClickShowPassoword={handleClickShowPassoword}
                label={t('Password')}
                name="password"
                showPassword={showPassword}
                type={typeFieldPassword}
            />

            {errors &&
                errors.map((error, index) => (
                    <Alert key={`error-login-${index}`} severity="warning">
                        <small>{`*${error}`}</small>
                    </Alert>
                ))}

            <Button disabled={isLoadingSession} fullWidth type="submit">
                {isLoadingSession ? t('Loading') : t('Log in')}
            </Button>
        </form>
    );
};

Loginform.propTypes = {
    errors: PropTypes.array,
    onSubmit: PropTypes.func,
    isLoadingSession: PropTypes.bool,
};

export default Loginform;
