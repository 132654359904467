import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Box } from 'sunwise-ui';

import { sliderSettings } from './constants';
import SlideItem from './SlideItem';

const StyledContiner = styled(Box)`
    margin: 16px 0;
    width: 100%;

    .slick-slider {
        padding: 0;
        width: 100%;
    }

    .slick-list {
        overflow: hidden;
    }

    .slick-dotted .slick-slider {
        margin-bottom: 30px;
    }

    .slick-dots {
        bottom: -10px;
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 100%;

        li {
            cursor: pointer;
            display: inline-block;
            height: 10px;
            margin: 0 5px;
            padding: 0;
            position: relative;
            transition: width 0.3s ease-in-out;
            width: 10px;

            button {
                background: transparent;
                border: 0;
                color: transparent;
                cursor: pointer;
                display: block;
                font-size: 0;
                height: 10px;
                line-height: 0;
                outline: none;
                padding: 5px;
                width: 10px;

                &:hover,
                &:focus {
                    outline: none;

                    &:before {
                        opacity: 1;
                    }
                }

                &:before {
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    color: black;
                    content: '•';
                    font-family: 'slick';
                    font-size: 6px;
                    height: 10px;
                    left: 0;
                    line-height: 20px;
                    opacity: 0.25;
                    position: absolute;
                    text-align: center;
                    top: 0;
                    width: 10px;
                }
            }
        }

        li.slick-active button:before {
            color: black;
            opacity: 0.75;
        }
    }

    @keyframes dot-loading {
        from {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }

    .ft-slick__dots--custom {
        background-color: #e5e7e9;
        border-radius: 4px;
        height: 8px;
        position: relative;
        width: 14px;
    }

    .slick-dots {
        li {
            margin: 0 2px;
            transition: width 0.3s ease-in-out;
            width: 14px;
        }

        .slick-active {
            transition: width 0.3s ease-in-out;
            width: 56px;

            .ft-slick__dots--custom {
                top: 0;
                width: 56px;

                .loading {
                    animation: dot-loading 3s ease-in;
                    background-image: linear-gradient(270deg, #ff9a00, #e7a804);
                    border-radius: 4px;
                    display: inline-block;
                    height: 8px;
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }
`;

const SlidesContainer = ({
    alignItems = 'left',
    slides,
    textAlign = 'justify',
}) => {
    if (!slides || !slides.length > 0) return null;
    return (
        <StyledContiner>
            <Slider {...sliderSettings}>
                {slides &&
                    slides.length > 0 &&
                    slides.map((slide, index) => (
                        <SlideItem
                            alignItems={alignItems}
                            content={slide.content}
                            key={index}
                            textAlign={textAlign}
                        />
                    ))}
            </Slider>
        </StyledContiner>
    );
};

SlidesContainer.propTypes = {
    alignItems: PropTypes.string,
    slides: PropTypes.array,
    textAlign: PropTypes.string,
};

export default SlidesContainer;
