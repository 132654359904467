import {
    SMART_DOCUMENT_FORM_DUPLICATE_MODE,
    SMART_DOCUMENT_FORM_RENAME_MODE,
} from '../constants';

import createDocument from './createDocument';
import duplicateDocument from './duplicateDocument';
import renameDocument from './renameDocument';

export default (
        handleClickDocumentItem,
        initTemplate,
        proposalId,
        resetTemplate,
        smartDocumentFormMode,
        values
    ) =>
    (dispatch) => {
        switch (smartDocumentFormMode) {
            case SMART_DOCUMENT_FORM_DUPLICATE_MODE:
                dispatch(
                    duplicateDocument(
                        handleClickDocumentItem,
                        initTemplate,
                        proposalId,
                        resetTemplate,
                        values
                    )
                );
                break;
            case SMART_DOCUMENT_FORM_RENAME_MODE:
                dispatch(renameDocument(proposalId, values));
                break;
            default:
                dispatch(
                    createDocument(
                        handleClickDocumentItem,
                        initTemplate,
                        proposalId,
                        resetTemplate,
                        values
                    )
                );
                break;
        }
    };
