import { NAME } from './constants';

export const APPROVE_CREDIT = `${NAME}/APPROVE_CREDIT`;
export const APPROVE_CREDIT_FAILURE = `${NAME}/APPROVE_CREDIT_FAILURE`;
export const APPROVE_CREDIT_SUCCESS = `${NAME}/APPROVE_CREDIT_SUCCESS`;

export const CANCEL_CREDIT = `${NAME}/CANCEL_CREDIT`;
export const CANCEL_CREDIT_FAILURE = `${NAME}/CANCEL_CREDIT_FAILURE`;
export const CANCEL_CREDIT_SUCCESS = `${NAME}/CANCEL_CREDIT_SUCCESS`;

export const CHANGE_STATUS_CREDIT = `${NAME}/CHANGE_STATUS_CREDIT`;
export const CHANGE_STATUS_CREDIT_FAILURE = `${NAME}/CHANGE_STATUS_CREDIT_FAILURE`;
export const CHANGE_STATUS_CREDIT_SUCCESS = `${NAME}/CHANGE_STATUS_CREDIT_SUCCESS`;

export const CHANGE_STATUS_FILE = `${NAME}/CHANGE_STATUS_FILE`;
export const CHANGE_STATUS_FILE_FAILURE = `${NAME}/CHANGE_STATUS_FILE_FAILURE`;
export const CHANGE_STATUS_FILE_SUCCESS = `${NAME}/CHANGE_STATUS_FILE_SUCCESS`;

export const CLOSE_CREDIT = `${NAME}/CLOSE_CREDIT`;
export const CLOSE_CREDIT_FAILURE = `${NAME}/CLOSE_CREDIT_FAILURE`;
export const CLOSE_CREDIT_SUCCESS = `${NAME}/CLOSE_CREDIT_SUCCESS`;

export const DELETE_DOCUMENT = `${NAME}/DELETE_DOCUMENT`;
export const DELETE_DOCUMENT_FAILURE = `${NAME}/DELETE_DOCUMENT_FAILURE`;
export const DELETE_DOCUMENT_SUCCESS = `${NAME}/DELETE_DOCUMENT_SUCCESS`;

export const DELETE_LOCATION = `${NAME}/DELETE_LOCATION`;
export const DELETE_LOCATION_FAILURE = `${NAME}/DELETE_LOCATION_FAILURE`;
export const DELETE_LOCATION_SUCCESS = `${NAME}/DELETE_LOCATION_SUCCESS`;

export const FETCH_CONSUMPTION_PDFS = `${NAME}/FETCH_CONSUMPTION_PDFS`;
export const FETCH_CONSUMPTION_PDFS_FAILURE = `${NAME}/FETCH_CONSUMPTION_PDFS_FAILURE`;
export const FETCH_CONSUMPTION_PDFS_SUCCESS = `${NAME}/FETCH_CONSUMPTION_PDFS_SUCCESS`;

export const FETCH_CONTACT = `${NAME}/FETCH_CONTACT`;
export const FETCH_CONTACT_FAILURE = `${NAME}/FETCH_CONTACT_FAILURE`;
export const FETCH_CONTACT_SUCCESS = `${NAME}/FETCH_CONTACT_SUCCESS`;

export const FETCH_CONTRACT_DOCUMENTS = `${NAME}/FETCH_CONTRACT_DOCUMENTS`;
export const FETCH_CONTRACT_DOCUMENTS_FAILURE = `${NAME}/FETCH_CONTRACT_DOCUMENTS_FAILURE`;
export const FETCH_CONTRACT_DOCUMENTS_SUCCESS = `${NAME}/FETCH_CONTRACT_DOCUMENTS_SUCCESS`;

export const FETCH_CREDIT_APPLICATION = `${NAME}/FETCH_CREDIT_APPLICATION`;
export const FETCH_CREDIT_APPLICATION_FAILURE = `${NAME}/FETCH_CREDIT_APPLICATION_FAILURE`;
export const FETCH_CREDIT_APPLICATION_SUCCESS = `${NAME}/FETCH_CREDIT_APPLICATION_SUCCESS`;

export const FETCH_CREDIT_BUREAU = `${NAME}/FETCH_CREDIT_BUREAU`;
export const FETCH_CREDIT_BUREAU_FAILURE = `${NAME}/FETCH_CREDIT_BUREAU_SUCCESS`;
export const FETCH_CREDIT_BUREAU_SUCCESS = `${NAME}/FETCH_CREDIT_BUREAU_FAILURE`;

export const FETCH_DOCUMENTS = `${NAME}/FETCH_DOCUMENTS`;
export const FETCH_DOCUMENTS_FAILURE = `${NAME}/FETCH_DOCUMENTS_FAILURE`;
export const FETCH_DOCUMENTS_SUCCESS = `${NAME}/FETCH_DOCUMENTS_SUCCESS`;

export const FETCH_FINANCIAL_DATA = `${NAME}/FETCH_FINANCIAL_DATA`;
export const FETCH_FINANCIAL_DATA_FAILURE = `${NAME}/FETCH_FINANCIAL_DATA_FAILURE`;
export const FETCH_FINANCIAL_DATA_SUCCESS = `${NAME}/FETCH_FINANCIAL_DATA_SUCCESS`;

export const FETCH_GEOCODE = `${NAME}/FETCH_GEOCODE`;
export const FETCH_GEOCODE_FAILURE = `${NAME}/FETCH_GEOCODE_FAILURE`;
export const FETCH_GEOCODE_SUCCESS = `${NAME}/FETCH_GEOCODE_SUCCESS`;

export const FETCH_GEOCODE_POINT = `${NAME}/FETCH_GEOCODE_POINT`;
export const FETCH_GEOCODE_POINT_FAILURE = `${NAME}/FETCH_GEOCODE_POINT_FAILURE`;
export const FETCH_GEOCODE_POINT_SUCCESS = `${NAME}/FETCH_GEOCODE_POINT_SUCCESS`;

export const FETCH_INSURANCE_DOCUMENTS = `${NAME}/FETCH_INSURANCE_DOCUMENTS`;
export const FETCH_INSURANCE_DOCUMENTS_FAILURE = `${NAME}/FETCH_INSURANCE_DOCUMENTS_FAILURE`;
export const FETCH_INSURANCE_DOCUMENTS_SUCCESS = `${NAME}/FETCH_INSURANCE_DOCUMENTS_SUCCESS`;

export const FETCH_REQUEST_FORM_DOCUMENTS = `${NAME}/FETCH_REQUEST_FORM_DOCUMENTS`;
export const FETCH_REQUEST_FORM_DOCUMENTS_FAILURE = `${NAME}/FETCH_REQUEST_FORM_DOCUMENTS_FAILURE`;
export const FETCH_REQUEST_FORM_DOCUMENTS_SUCCESS = `${NAME}/FETCH_REQUEST_FORM_DOCUMENTS_SUCCESS`;

export const FETCH_REQUIRED_DOCUMENTS_LIST = `${NAME}/FETCH_REQUIRED_DOCUMENTS_LIST`;
export const FETCH_REQUIRED_DOCUMENTS_LIST_FAILURE = `${NAME}/FETCH_REQUIRED_DOCUMENTS_LIST_FAILURE`;
export const FETCH_REQUIRED_DOCUMENTS_LIST_SUCCESS = `${NAME}/FETCH_REQUIRED_DOCUMENTS_LIST_SUCCESS`;

export const FETCH_PROPOSAL_BACKUPS = `${NAME}/FETCH_PROPOSAL_BACKUPS`;
export const FETCH_PROPOSAL_BACKUPS_FAILURE = `${NAME}/FETCH_PROPOSAL_BACKUPS_FAILURE`;
export const FETCH_PROPOSAL_BACKUPS_SUCCESS = `${NAME}/FETCH_PROPOSAL_BACKUPS_SUCCESS`;

export const INITIALIZE_ASSIGN_USER_FORM = `${NAME}/INITIALIZE_ASSIGN_USER_FORM`;
export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;
export const INITIALIZE_FORM_EDIT_CREDIT = `${NAME}/INITIALIZE_FORM_EDIT_CREDIT`;
export const INITIALIZE_LOCATION_FORM = `${NAME}/INITIALIZE_LOCATION_FORM`;

export const OPEN_MODAL_CONTACT = `${NAME}/OPEN_MODAL_CONTACT`;

export const PRE_APPROVE_CREDIT = `${NAME}/PRE_APPROVE_CREDIT`;
export const PRE_APPROVE_CREDIT_FAILURE = `${NAME}/PRE_APPROVE_CREDIT_FAILURE`;
export const PRE_APPROVE_CREDIT_SUCCESS = `${NAME}/PRE_APPROVE_CREDIT_SUCCESS`;

export const REJECT_FILE = `${NAME}/REJECT_FILE`;
export const REJECT_FILE_FAILURE = `${NAME}/REJECT_FILE_FAILURE`;
export const REJECT_FILE_SUCCESS = `${NAME}/REJECT_FILE_SUCCESS`;

export const RESET_UPLOAD_FORM = `${NAME}/RESET_UPLOAD_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;
export const RESET_ASSIGN_USER_FORM = `${NAME}/RESET_ASSIGN_USER_FORM`;
export const RESET_LOCATION_FORM = `${NAME}/RESET_LOCATION_FORM`;

export const SAVE_ASSIGNED_USER = `${NAME}/SAVE_ASSIGNED_USER`;
export const SAVE_ASSIGNED_USER_FAILURE = `${NAME}/SAVE_ASSIGNED_USER_FAILURE`;
export const SAVE_ASSIGNED_USER_SUCCESS = `${NAME}/SAVE_ASSIGNED_USER_SUCCESS`;

export const SAVE_DOCUMENT = `${NAME}/SAVE_DOCUMENT`;
export const SAVE_DOCUMENT_FAILURE = `${NAME}/SAVE_DOCUMENT_FAILURE`;
export const SAVE_DOCUMENT_SUCCESS = `${NAME}/SAVE_DOCUMENT_SUCCESS`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SAVE_LOCATION = `${NAME}/SAVE_LOCATION`;
export const SAVE_LOCATION_FAILURE = `${NAME}/SAVE_LOCATION_FAILURE`;
export const SAVE_LOCATION_SUCCESS = `${NAME}/SAVE_LOCATION_SUCCESS`;

export const SET_IS_DOWNLOADING = `${NAME}/SET_IS_DOWNLOADING`;
export const SET_SHOW_GEOCODE_RESULTS = `${NAME}/SET_SHOW_GEOCODE_RESULTS`;

export const UPDATE_CREDIT = `${NAME}/UPDATE_CREDIT`;
export const UPDATE_CREDIT_FAILURE = `${NAME}/UPDATE_CREDIT_FAILURE`;
export const UPDATE_CREDIT_SUCCESS = `${NAME}/UPDATE_CREDIT_SUCCESS`;

export const UPLOAD_DOCUMENT = `${NAME}/UPLOAD_DOCUMENT`;
export const UPLOAD_DOCUMENT_FAILURE = `${NAME}/UPLOAD_DOCUMENT_FAILURE`;
export const UPLOAD_DOCUMENT_SUCCESS = `${NAME}/UPLOAD_DOCUMENT_SUCCESS`;
