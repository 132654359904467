import React from 'react';

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Pagination } from 'sunwise-ui';

const CustomPagination = ({ handleOnPageChange, pagination }) => {
    if (isEmpty(pagination)) return null;

    const currentPage = pagination.current_page;
    const perPage = pagination.per_page;
    const totalRows = pagination.total_rows;
    const totalPages = Math.ceil(totalRows / perPage);

    return (
        <Pagination
            count={totalPages}
            onChange={(_, value) => handleOnPageChange(value)}
            page={currentPage}
        />
    );
};

CustomPagination.propTypes = {
    handleOnPageChange: PropTypes.func,
    pagination: PropTypes.object,
};

export default CustomPagination;
