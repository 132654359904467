import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid, List, ListItem } from 'sunwise-ui';

import { KeyValue } from 'common/components';
import { numberFormat } from 'common/utils/helpers';

const InfonavitRequestDetail = ({
    amount,
    handleOnShowModalInfonavit,
    infonavitData,
}) => {
    const { t } = useTranslation();

    if (isEmpty(infonavitData)) return null;

    const {
        total_amount = 0,
        retention_value = 0,
        available_balance = 0,
        nss,
        birthday,
        administratives_expenses_percentage_value = 0,
    } = infonavitData;

    return (
        <List>
            <ListItem>
                <Grid container>
                    <Grid item xs>
                        <KeyValue
                            append="mxn"
                            title={t('Total of the proposal')}
                            value={numberFormat(amount, { style: 'currency' })}
                        />
                        <Grid container>
                            <Grid item xs>
                                <KeyValue title="NSS" value={nss} />
                            </Grid>
                            <Grid item xs>
                                <KeyValue
                                    title={t('Date of birth')}
                                    value={birthday}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <KeyValue
                            append="mxn"
                            title={`${t('Amount')} INFONAVIT`}
                            value={numberFormat(total_amount, {
                                style: 'currency',
                            })}
                        />
                        <Grid container>
                            <Grid item xs>
                                <KeyValue
                                    append="mxn"
                                    Retention
                                    title={`${t('Retention')} 5%`}
                                    value={numberFormat(retention_value, {
                                        style: 'currency',
                                    })}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                <KeyValue
                                    append="mxn"
                                    title={t('Administrative expenses')}
                                    value={numberFormat(
                                        administratives_expenses_percentage_value,
                                        { style: 'currency' }
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItem>

            <ListItem>
                <Grid container>
                    <Grid item xs>
                        <KeyValue
                            append="mxn"
                            title={t('Available balance')}
                            value={numberFormat(available_balance, {
                                style: 'currency',
                            })}
                        />
                    </Grid>
                </Grid>
            </ListItem>

            <ListItem>
                <Button
                    color="secondary"
                    onClick={handleOnShowModalInfonavit}
                    variant="text"
                >
                    {t('Verify data')}
                </Button>
            </ListItem>
        </List>
    );
};

InfonavitRequestDetail.propTypes = {
    amount: PropTypes.number,
    handleOnShowModalInfonavit: PropTypes.func,
    infonavitData: PropTypes.object,
};

export default InfonavitRequestDetail;
