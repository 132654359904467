import React from 'react';

import PropTypes from 'prop-types';
import { Card, Divider, Grid, Skeleton } from 'sunwise-ui';

const renderCols = (numCols) => {
    const cols = [];

    for (let i = 0; i < numCols; i++) {
        cols.push(
            <Grid item xs key={`placeholder-col-card-contact-${i}`}>
                <Skeleton variant="rounded" />
            </Grid>
        );
    }

    return cols;
};

const Placeholder = (
    <Card>
        <Card.Body>
            <Grid container>
                <Grid item xs>
                    <Grid container>{renderCols(5)}</Grid>

                    <Divider sx={{ my: 1 }} />

                    <Grid container>{renderCols(5)}</Grid>
                </Grid>
            </Grid>

            <Divider sx={{ my: 1 }} />

            <Grid container>{renderCols(1)}</Grid>
        </Card.Body>
    </Card>
);

const PlaceholderCardContactsDashboard = ({ children, ready }) => (
    <React.Fragment>{ready ? Placeholder : children}</React.Fragment>
);

PlaceholderCardContactsDashboard.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderCardContactsDashboard;
