import React from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';
import { FlexRow } from 'common/utils/layout';

const StyledTable = styled('table')`
    width: 100%;
    thead {
        background-color: #202253;
        th {
            color: #fff;
            font-size: 12px;
            padding: 10px 6px;
            text-align: center;
            &:first-child {
                border-radius: 14px 0 0 14px;
            }
            &:last-child {
                border-radius: 0 14px 14px 0;
            }
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid #ccc;
            &:last-child {
                border-bottom: 0 solid #ccc;
            }
        }
        td {
            font-weight: 600;
            padding: 10px 6px;
            text-align: center;
            vertical-align: middle;
            &.proposal-label {
                font-size: 11px;
                width: 124px;
            }
        }
    }
`;

const SignumLabel = styled('span')`
    color: #ff9800;
    font-size: 11px;
    font-weight: 600;
    line-height: 13px;
    min-height: 13px;
`;

const ValueLabel = styled('span')`
    color: #d3d7eb;
    font-size: 28px;
    font-weight: 600;
    line-height: 30px;
`;

const CardTotalProposalTable = ({ proposalsTotals }) => {
    const { t } = useTranslation();
    return (
        <Card>
            <Card.Body>
                <StyledTable>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t('Total proposals')}</th>
                            <th>{t('Total power of the proposals')}</th>
                            <th>{t('Value of proposals')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td className="proposal-label">
                                {t('Proposals issued without your products')}
                            </td>

                            <td>
                                <ValueLabel>
                                    {numberFormat(
                                        get(
                                            proposalsTotals,
                                            'emitidas.total_propuestas',
                                            0
                                        ),
                                        { style: 'decimal' }
                                    )}
                                </ValueLabel>
                            </td>

                            <td>
                                <FlexRow
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <ValueLabel>
                                        {numberFormat(
                                            get(
                                                proposalsTotals,
                                                'emitidas.propuestas_kw',
                                                0
                                            ),
                                            { style: 'decimal' }
                                        )}
                                    </ValueLabel>

                                    <SignumLabel>kWp</SignumLabel>
                                </FlexRow>
                            </td>

                            <td>
                                <FlexRow
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <SignumLabel>$</SignumLabel>

                                    <ValueLabel>
                                        {numberFormat(
                                            get(
                                                proposalsTotals,
                                                'emitidas.propuestas_dinero',
                                                0
                                            ),
                                            { style: 'currency' }
                                        )}
                                    </ValueLabel>
                                </FlexRow>
                            </td>
                        </tr>

                        <tr>
                            <td className="proposal-label">
                                {t('Proposals issued with your products')}
                            </td>

                            <td>
                                <ValueLabel>
                                    {numberFormat(
                                        get(
                                            proposalsTotals,
                                            'cotizadas.total_propuestas',
                                            0
                                        ),
                                        { style: 'decimal' }
                                    )}
                                </ValueLabel>
                            </td>

                            <td>
                                <FlexRow
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <ValueLabel>
                                        {numberFormat(
                                            get(
                                                proposalsTotals,
                                                'cotizadas.propuestas_kw',
                                                0
                                            ),
                                            { style: 'decimal' }
                                        )}
                                    </ValueLabel>

                                    <SignumLabel>kWp</SignumLabel>
                                </FlexRow>
                            </td>

                            <td>
                                <FlexRow
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <SignumLabel>$</SignumLabel>

                                    <ValueLabel>
                                        {numberFormat(
                                            get(
                                                proposalsTotals,
                                                'cotizadas.propuestas_dinero',
                                                0
                                            ),
                                            {
                                                style: 'currency',
                                            }
                                        )}
                                    </ValueLabel>
                                </FlexRow>
                            </td>
                        </tr>

                        <tr>
                            <td className="proposal-label">
                                {t('Proposals issued and requested')}
                            </td>

                            <td>
                                <ValueLabel>
                                    {numberFormat(
                                        get(
                                            proposalsTotals,
                                            'solicitadas.total_propuestas',
                                            0
                                        ),
                                        { style: 'decimal' }
                                    )}
                                </ValueLabel>
                            </td>

                            <td>
                                <FlexRow
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <ValueLabel>
                                        {numberFormat(
                                            get(
                                                proposalsTotals,
                                                'solicitadas.propuestas_kw',
                                                0
                                            ),
                                            { style: 'decimal' }
                                        )}
                                    </ValueLabel>

                                    <SignumLabel>kWp</SignumLabel>
                                </FlexRow>
                            </td>
                            <td>
                                <FlexRow
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <SignumLabel>$</SignumLabel>

                                    <ValueLabel>
                                        {numberFormat(
                                            get(
                                                proposalsTotals,
                                                'solicitadas.propuestas_dinero',
                                                0
                                            ),
                                            { style: 'currency' }
                                        )}
                                    </ValueLabel>
                                </FlexRow>
                            </td>
                        </tr>
                    </tbody>
                </StyledTable>
            </Card.Body>
        </Card>
    );
};

CardTotalProposalTable.propTypes = { proposalsTotals: PropTypes.object };

export default CardTotalProposalTable;
