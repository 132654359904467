import { getItems } from 'common/api/executives';

import {
    FETCH_EXECUTIVES,
    FETCH_EXECUTIVES_FAILURE,
    FETCH_EXECUTIVES_SUCCESS,
} from '../actionTypes';
import { actions } from '../reducer';

export default () => (dispatch) => {
    dispatch(actions[FETCH_EXECUTIVES]());

    getItems()
        .then((response) => {
            const data = response.data.data;

            dispatch(actions[FETCH_EXECUTIVES_SUCCESS](data));
        })
        .catch((error) => {
            dispatch(actions[FETCH_EXECUTIVES_FAILURE](error));
        });
};
