import React, { useEffect, useRef } from 'react';

import { styled } from '@mui/material/styles';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import { custom1 } from 'common/utils/customScrolls';

import { SIDE_WIDTH } from '../../constants';

import SortableList from './SortableList';

const AsideColWrapper = styled(Box)`
    box-shadow: 0 2px 12px 0 rgba(129, 158, 200, 0.06);
    font-size: 12px;
    height: calc(100% - 46px);
    line-height: 18px;
    min-width: ${SIDE_WIDTH}px;
    overflow-y: scroll;
    width: ${SIDE_WIDTH}px;

    ${custom1}

    @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
    }
`;

const AsideCol = ({
    canUpdate,
    onDeletePage,
    onDuplicatePage,
    pages,
    selectedPageId,
    selectPage,
    updateOrder,
}) => {
    const inputEl = useRef(null);

    let refDictionary = {};
    let scrollDisabled = false;

    useEffect(() => {
        if (
            !scrollDisabled &&
            inputEl.current &&
            selectedPageId &&
            refDictionary[selectedPageId]
        ) {
            inputEl.current.scrollTop =
                refDictionary[selectedPageId].offsetTop - 98;
        }

        if (scrollDisabled) scrollDisabled = false;
    }, [selectedPageId]);

    const onDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) return;

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;

        updateOrder(source.index, destination.index);
    };
    return (
        <AsideColWrapper ref={inputEl}>
            {!isEmpty(pages) && (
                <SortableList
                    canUpdate={canUpdate}
                    handleClickDelete={onDeletePage}
                    handleClickDuplicate={onDuplicatePage}
                    handleClickSelect={selectPage}
                    id="page-list"
                    items={pages}
                    onDragEnd={onDragEnd}
                    refDictionary={refDictionary}
                    scrollDisabled={scrollDisabled}
                    selectedPageId={selectedPageId}
                />
            )}
        </AsideColWrapper>
    );
};

AsideCol.propTypes = {
    canUpdate: PropTypes.bool,
    onDeletePage: PropTypes.func,
    onDuplicatePage: PropTypes.func,
    pages: PropTypes.array,
    selectedPageId: PropTypes.string,
    selectPage: PropTypes.func,
    updateOrder: PropTypes.func,
};

export default AsideCol;
