import { updateItem } from 'common/api/companies';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_COMPANY,
    SAVE_COMPANY_SUCCESS,
    SAVE_COMPANY_FAILURE,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import fetchData from './fetchData';

export default (values) => (dispatch) => {
    const newValues = {
        description: values.description,
        id: values.id,
        name: values.name,
    };

    if (Array.isArray(values.image))
        newValues.image = values.image && values.image[0];

    dispatch(companyGeneralActions[SAVE_COMPANY]());

    updateItem(newValues, values.id)
        .then(() => {
            dispatch(companyGeneralActions[SAVE_COMPANY_SUCCESS]());
            dispatch(fetchData());
            showToast();
        })
        .catch((error) => {
            dispatch(
                companyGeneralActions[SAVE_COMPANY_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
