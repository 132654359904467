import { createSlice } from '@reduxjs/toolkit';

import {
    ADD_TEMPLATE_PAGES,
    ADD_TEMPLATE_PAGES_FAILURE,
    ADD_TEMPLATE_PAGES_SUCCESS,
    FETCH_TEMPLATE,
    FETCH_TEMPLATE_FAILURE,
    FETCH_TEMPLATE_SUCCESS,
    FETCH_TEMPLATE_CATALOGS,
    FETCH_TEMPLATE_CATALOGS_FAILURE,
    FETCH_TEMPLATE_CATALOGS_SUCCESS,
    RESET,
    SET_INITIALIZE,
    UPDATE_TEMPLATE,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    addTemplatePages: { errors: [], isFetching: true },
    fetchTemplate: { data: null, errors: [], isFetching: true },
    fetchTemplateCatalogs: { data: [], errors: [], isFetching: true },
    isInitialize: true,
    updateTemplate: { errors: [], isUpdating: false },
};

const editTemplateViewSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ADD_TEMPLATE_PAGES]: (state) => {
            state.addTemplatePages.isFetching = true;
        },
        [ADD_TEMPLATE_PAGES_FAILURE]: (state, action) => {
            state.addTemplatePages = {
                ...state.addTemplatePages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [ADD_TEMPLATE_PAGES_SUCCESS]: (state) => {
            state.addTemplatePages.isFetching = false;
        },
        [FETCH_TEMPLATE]: (state) => {
            state.fetchTemplate.isFetching = true;
        },
        [FETCH_TEMPLATE_FAILURE]: (state, action) => {
            state.fetchTemplate = {
                ...state.fetchTemplate,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_SUCCESS]: (state, action) => {
            state.fetchTemplate = {
                ...state.fetchTemplate,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_CATALOGS]: (state) => {
            state.fetchTemplateCatalogs.isFetching = true;
        },
        [FETCH_TEMPLATE_CATALOGS_FAILURE]: (state, action) => {
            state.fetchTemplateCatalogs = {
                ...state.fetchTemplateCatalogs,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_CATALOGS_SUCCESS]: (state, action) => {
            state.fetchTemplateCatalogs = {
                ...state.fetchTemplateCatalogs,
                data: action.payload,
                isFetching: false,
            };
        },
        [RESET]: () => INITIAL_STATE,
        [SET_INITIALIZE]: (state, action) => {
            state.isInitialize = action.payload;
        },
        [UPDATE_TEMPLATE]: (state) => {
            state.updateTemplate.isUpdating = true;
        },
        [UPDATE_TEMPLATE_FAILURE]: (state, action) => {
            state.updateTemplate = {
                ...state.updateTemplate,
                errors: action.payload,
                isUpdating: false,
            };
        },
        [UPDATE_TEMPLATE_SUCCESS]: (state) => {
            state.updateTemplate.isUpdating = false;
        },
    },
});

export const editTemplateViewActions = editTemplateViewSlice.actions;

export default editTemplateViewSlice.reducer;
