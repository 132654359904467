import React, { useRef, useState, useEffect, Suspense } from 'react';

import { CssBaseline } from '@mui/material';
import {
    createTheme,
    StyledEngineProvider,
    ThemeProvider,
} from '@mui/material/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    breakpoints,
    componentsOverride,
    customShadows,
    palette,
    shadows,
    shape,
    typography,
} from 'sunwise-ui';

import { NotificationsListener } from 'common/components';
import LoadingProcessView from 'common/modules/loadingView/ProcessView';
import { LoadingProvider } from 'common/utils/contexts';
import 'react-toastify/dist/ReactToastify.css';

import ToastAskPermission from './common/components/ToastAskPermission';
import main from './modules/main';
import * as profileActions from './modules/profile/actions';
import * as profileSelectors from './modules/profile/selectors';
import {
    components as themeDarkComponents,
    palette as themeDarkPalette,
} from './theme-dark';

const App = ({ history, selectedTheme = 'light', setTheme }) => {
    const loadingProcessRef = useRef(null);
    const [providerValue, setProviderValue] = useState({});

    const themeOptions = {
        breakpoints,
        customShadows,
        palette: selectedTheme === 'light' ? palette : themeDarkPalette,
        shadows,
        shape,
        typography,
    };
    const theme = createTheme(themeOptions);
    theme.components = componentsOverride(theme);
    if (selectedTheme === 'dark')
        theme.components = { ...themeDarkComponents(theme).components };

    useEffect(() => {
        if (loadingProcessRef.current !== null) {
            const { openLoading, closeLoading } = loadingProcessRef.current;
            setProviderValue({ openLoading, closeLoading });
        }
    }, [loadingProcessRef]);

    useEffect(() => {
        const storageTheme = localStorage.getItem('theme') || 'light';
        setTheme(storageTheme);
    }, []);

    return (
        <Suspense fallback={null}>
            <LoadingProcessView ref={loadingProcessRef} />
            <Router history={history}>
                <LoadingProvider value={providerValue}>
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <NotificationsListener />
                            <ToastAskPermission />
                            <main.Container />
                        </ThemeProvider>
                    </StyledEngineProvider>
                </LoadingProvider>
                <ToastContainer />
            </Router>
        </Suspense>
    );
};

App.propTypes = {
    history: PropTypes.object,
    selectedTheme: PropTypes.string,
    setTheme: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    selectedTheme: profileSelectors.getTheme,
});

const mapDispatchToProps = (dispatch) => ({
    setTheme: (value) => dispatch(profileActions.setTheme(value)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
)(App);
