import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_ALL_FINANCIER_PRODUCTS,
    FETCH_ALL_FINANCIER_PRODUCTS_FAILURE,
    FETCH_ALL_FINANCIER_PRODUCTS_SUCCESS,
    FETCH_EXECUTIVES,
    FETCH_EXECUTIVES_FAILURE,
    FETCH_EXECUTIVES_SUCCESS,
    FETCH_FINANCIAL_PRODUCTS,
    FETCH_FINANCIAL_PRODUCTS_FAILURE,
    FETCH_FINANCIAL_PRODUCTS_SUCCESS,
    FETCH_INSTALLERS,
    FETCH_INSTALLERS_FAILURE,
    FETCH_INSTALLERS_SUCCESS,
    INITIALIZE_ASSIGN_PRODUCT_FORM,
    RESET_ASSIGN_PRODUCT_FORM,
    SAVE_BULK_ASSIGN_PRODUCT,
    SAVE_BULK_ASSIGN_PRODUCT_FAILURE,
    SAVE_BULK_ASSIGN_PRODUCT_SUCCESS,
    SET_IS_DOWNLOADING,
    SET_IS_OPEN_ASSIGN_PRODUCT_MODAL,
    UNLINK_ALLIANCE,
    UNLINK_ALLIANCE_FAILURE,
    UNLINK_ALLIANCE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    allFinancierProducts: { data: [], error: null, isFetching: true },
    executives: { data: [], errors: [], isFetching: true },
    financialProducts: {
        data: { data: [], pagination: {} },
        error: null,
        isFetching: true,
    },
    initialAssignProductValues: { alliances: [], products: [] },
    installers: {
        data: { data: [], pagination: {} },
        error: null,
        isFetching: true,
    },
    isDownloading: false,
    isOpenAssignProductModal: false,
    saveAssignProduct: { data: {}, errors: [], isSaving: false },
    unlinkAlliance: { errors: [], isFetching: true },
};

const installersSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_ALL_FINANCIER_PRODUCTS]: (state) => {
            state.allFinancierProducts.isFetching = true;
        },
        [FETCH_ALL_FINANCIER_PRODUCTS_FAILURE]: (state, action) => {
            state.allFinancierProducts = {
                ...state.allFinancierProducts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ALL_FINANCIER_PRODUCTS_SUCCESS]: (state, action) => {
            state.allFinancierProducts = {
                ...state.allFinancierProducts,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_EXECUTIVES]: (state) => {
            state.executives.isFetching = true;
        },
        [FETCH_EXECUTIVES_FAILURE]: (state, action) => {
            state.executives = {
                ...state.executives,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_EXECUTIVES_SUCCESS]: (state, action) => {
            state.executives = {
                ...state.executives,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INSTALLERS]: (state) => {
            state.installers.isFetching = true;
        },
        [FETCH_INSTALLERS_FAILURE]: (state, action) => {
            state.installers = {
                ...state.installers,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INSTALLERS_SUCCESS]: (state, action) => {
            state.installers = {
                ...state.installers,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIAL_PRODUCTS]: (state) => {
            state.financialProducts.isFetching = true;
        },
        [FETCH_FINANCIAL_PRODUCTS_FAILURE]: (state, action) => {
            state.financialProducts = {
                ...state.financialProducts,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIAL_PRODUCTS_SUCCESS]: (state, action) => {
            state.financialProducts = {
                ...state.financialProducts,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_ASSIGN_PRODUCT_FORM]: (state, action) => {
            state.initialAssignProductValues = {
                ...state.initialAssignProductValues,
                ...action.payload,
            };
        },
        [RESET_ASSIGN_PRODUCT_FORM]: (state) => {
            state.initialAssignProductValues =
                INITIAL_STATE.initialAssignProductValues;
            state.financialProducts = INITIAL_STATE.financialProducts;
            state.saveAssignProduct.errors = [];
        },
        [SAVE_BULK_ASSIGN_PRODUCT]: (state) => {
            state.saveAssignProduct.isSaving = true;
        },
        [SAVE_BULK_ASSIGN_PRODUCT_FAILURE]: (state, action) => {
            state.saveAssignProduct = {
                ...state.saveAssignProduct,
                isSaving: false,
                errors: action.payload,
            };
        },
        [SAVE_BULK_ASSIGN_PRODUCT_SUCCESS]: (state) => {
            state.saveAssignProduct.isSaving = false;
        },
        [SET_IS_DOWNLOADING]: (state, action) => {
            state.isDownloading = action.payload;
        },
        [SET_IS_OPEN_ASSIGN_PRODUCT_MODAL]: (state, action) => {
            state.isOpenAssignProductModal = action.payload;
        },
        [UNLINK_ALLIANCE]: (state) => {
            state.unlinkAlliance.isFetching = true;
        },
        [UNLINK_ALLIANCE_FAILURE]: (state) => {
            state.unlinkAlliance.isFetching = false;
        },
        [UNLINK_ALLIANCE_SUCCESS]: (state, action) => {
            state.unlinkAlliance = {
                ...state.unlinkAlliance,
                data: action.payload,
                isFetching: false,
            };
        },
    },
});

export const installersActions = installersSlice.actions;

export default installersSlice.reducer;
