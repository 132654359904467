import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import ReorderIcon from '@mui/icons-material/Reorder';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import {
    Box,
    Divider,
    IconButton,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from 'sunwise-ui';

import { TERMS_AND_CONDITIONS_TYPE } from 'common/constants';

const SortableItem = ({
    canModify,
    companyCatalog,
    index,
    prepareUpdate,
    type,
}) => {
    const theme = useTheme();

    return (
        <Draggable
            isDragDisabled={type?.type === TERMS_AND_CONDITIONS_TYPE}
            draggableId={companyCatalog.id}
            index={index}
        >
            {(provided) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {type?.type === TERMS_AND_CONDITIONS_TYPE ? (
                        <Box
                            py={2}
                            sx={{
                                background:
                                    type?.type === TERMS_AND_CONDITIONS_TYPE &&
                                    theme.palette.mode === 'dark'
                                        ? '#fff'
                                        : theme.palette.primary,
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: companyCatalog.content,
                                }}
                            />
                        </Box>
                    ) : (
                        <Box>
                            <ListItemButton>
                                <ListItemIcon>
                                    <ReorderIcon fontSize="small" />
                                </ListItemIcon>

                                <ListItemText primary={companyCatalog.name} />

                                {canModify && (
                                    <IconButton
                                        onClick={() =>
                                            prepareUpdate(companyCatalog, type)
                                        }
                                        sx={{ ml: 'auto' }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </ListItemButton>

                            <Divider />
                        </Box>
                    )}
                </Box>
            )}
        </Draggable>
    );
};

SortableItem.propTypes = {
    canModify: PropTypes.bool,
    companyCatalog: PropTypes.object,
    index: PropTypes.number,
    prepareUpdate: PropTypes.func,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export default SortableItem;
