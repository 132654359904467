import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_ALLIANCES,
    FETCH_ALLIANCES_FAILURE,
    FETCH_ALLIANCES_SUCCESS,
    FETCH_APPROVED_CREDITS,
    FETCH_APPROVED_CREDITS_FAILURE,
    FETCH_APPROVED_CREDITS_SUCCESS,
    FETCH_CREDIT_APPLICATIONS,
    FETCH_CREDIT_APPLICATIONS_FAILURE,
    FETCH_CREDIT_APPLICATIONS_SUCCESS,
    FETCH_CREDITS,
    FETCH_CREDITS_FAILURE,
    FETCH_CREDITS_SUCCESS,
    INITIALIZE_CHANGE_STATUS_FORM,
    INITIALIZE_REASSIGN_ADVISOR_FORM,
    OPEN_MODAL_CHANGE_STATUS,
    OPEN_MODAL_REASSIGN_ADVISOR,
    RESET_CHANGE_STATUS_FORM,
    RESET_REASSIGN_ADVISOR_FORM,
    SAVE_BULK_REASSING_ADVISOR,
    SAVE_BULK_REASSING_ADVISOR_FAILURE,
    SAVE_BULK_REASSING_ADVISOR_SUCCESS,
    SAVE_BULK_CHANGE_STATUS,
    SAVE_BULK_CHANGE_STATUS_FAILURE,
    SAVE_BULK_CHANGE_STATUS_SUCCESS,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_IS_DOWNLOADING,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    alliances: {
        data: [],
        errors: [],
        isFetching: true,
    },
    approved_credits: {
        data: [],
        errors: [],
        isFetching: true,
    },
    credit_applications: {
        data: [],
        errors: [],
        isFetching: true,
    },
    credits: {
        data: {
            data: [],
            pagination: {},
        },
        errors: [],
        isFetching: true,
    },
    initialChangeStatusValues: {
        credits: [],
        status: null,
    },
    initialReassignAdvisorValues: {
        credits: [],
        financial_advisor: null,
    },
    isDownloading: false,
    isOpenModalChangeStatus: false,
    isOpenModalReassingAdvisor: false,
    saveReassignAdvisor: {
        data: {},
        errors: [],
        isSaving: false,
    },
    saveBulkStatus: {
        data: {},
        errors: [],
        isSaving: false,
    },
    saveItem: {
        data: {},
        errors: [],
        isSaving: false,
    },
};

const creditsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_ALLIANCES]: (state) => {
            state.alliances.isFetching = true;
        },
        [FETCH_ALLIANCES_FAILURE]: (state, action) => {
            state.alliances = {
                ...state.alliances,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ALLIANCES_SUCCESS]: (state, action) => {
            state.alliances = {
                ...state.alliances,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_APPROVED_CREDITS]: (state) => {
            state.approved_credits.isFetching = true;
        },
        [FETCH_APPROVED_CREDITS_FAILURE]: (state, action) => {
            state.approved_credits = {
                ...state.approved_credits,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_APPROVED_CREDITS_SUCCESS]: (state, action) => {
            state.approved_credits = {
                ...state.approved_credits,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CREDIT_APPLICATIONS]: (state) => {
            state.credit_applications.isFetching = true;
        },
        [FETCH_CREDIT_APPLICATIONS_FAILURE]: (state, action) => {
            state.credit_applications = {
                ...state.credit_applications,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CREDIT_APPLICATIONS_SUCCESS]: (state, action) => {
            state.credit_applications = {
                ...state.credit_applications,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CREDITS]: (state) => {
            state.credits.isFetching = true;
        },
        [FETCH_CREDITS_FAILURE]: (state, action) => {
            state.credits = {
                ...state.credits,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CREDITS_SUCCESS]: (state, action) => {
            state.credits = {
                ...state.credits,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_CHANGE_STATUS_FORM]: (state, action) => {
            state.initialChangeStatusValues = {
                ...state.initialChangeStatusValues,
                ...action.payload,
            };
        },
        [INITIALIZE_REASSIGN_ADVISOR_FORM]: (state, action) => {
            state.initialReassignAdvisorValues = {
                ...state.initialReassignAdvisorValues,
                ...action.payload,
            };
        },
        [OPEN_MODAL_CHANGE_STATUS]: (state, action) => {
            state.isOpenModalChangeStatus = action.payload;
        },
        [OPEN_MODAL_REASSIGN_ADVISOR]: (state, action) => {
            state.isOpenModalReassingAdvisor = action.payload;
        },
        [RESET_CHANGE_STATUS_FORM]: (state) => {
            state.initialChangeStatusValues =
                INITIAL_STATE.initialChangeStatusValues;
            state.saveBulkStatus.errors = [];
        },
        [RESET_REASSIGN_ADVISOR_FORM]: (state) => {
            state.initialReassignAdvisorValues =
                INITIAL_STATE.initialReassignAdvisorValues;
            state.saveReassignAdvisor.errors = [];
        },
        [SAVE_BULK_REASSING_ADVISOR]: (state) => {
            state.saveReassignAdvisor.isSaving = true;
        },
        [SAVE_BULK_REASSING_ADVISOR_FAILURE]: (state, action) => {
            state.saveReassignAdvisor = {
                ...state.saveReassignAdvisor,
                isSaving: false,
                errors: action.payload,
            };
        },
        [SAVE_BULK_REASSING_ADVISOR_SUCCESS]: (state) => {
            state.saveReassignAdvisor.isSaving = false;
        },
        [SAVE_BULK_CHANGE_STATUS]: (state) => {
            state.saveBulkStatus.isSaving = true;
        },
        [SAVE_BULK_CHANGE_STATUS_FAILURE]: (state, action) => {
            state.saveBulkStatus = {
                ...state.saveBulkStatus,
                isSaving: false,
                errors: action.payload,
            };
        },
        [SAVE_BULK_CHANGE_STATUS_SUCCESS]: (state) => {
            state.saveBulkStatus.isSaving = false;
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem.isSaving = true;
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state) => {
            state.saveItem.isSaving = false;
        },
        [SET_IS_DOWNLOADING]: (state, action) => {
            state.isDownloading = action.payload;
        },
    },
});

export const creditsActions = creditsSlice.actions;

export default creditsSlice.reducer;
