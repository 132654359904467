import { me } from 'common/api/auth';
import { updatePermissionGroups } from 'common/utils/session';

import { FETCH, FETCH_SUCCESS, FETCH_FAILURE } from '../actionTypes';
import { profileActions } from '../reducer';

import initializeForm from './initializeForm';

export default () => (dispatch) => {
    dispatch(profileActions[FETCH]());

    me()
        .then((response) => {
            dispatch(profileActions[FETCH_SUCCESS](response.data));
            const { groups } = response.data;
            dispatch(initializeForm(response.data));
            updatePermissionGroups(groups);
        })
        .catch((error) =>
            dispatch(
                profileActions[FETCH_FAILURE](error?.response?.data?.errors)
            )
        );
};
