import i18next from 'i18next';

import { SET_SMART_DOCUMENT_FORM_MODE } from '../actionTypes';
import { SMART_DOCUMENT_FORM_DUPLICATE_MODE } from '../constants';
import { templateDocumentsActions } from '../reducer';

import initializeSmartDocumentForm from './initializeDocumentForm';
import openDocumentModal from './openDocumentModal';

export default (name, id) => (dispatch) => {
    dispatch(
        templateDocumentsActions[SET_SMART_DOCUMENT_FORM_MODE](
            SMART_DOCUMENT_FORM_DUPLICATE_MODE
        )
    );
    dispatch(
        initializeSmartDocumentForm({
            id,
            name: i18next.t('Copy of {{name}}', { name }),
        })
    );

    return dispatch(openDocumentModal(true));
};
