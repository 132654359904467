import fetchElectricBills from './fetchElectricBills';
import fetchInteractiveProposal from './fetchInteractiveProposal';
import fetchInteractiveProposalContent from './fetchInteractiveProposalContent';

export default (offerId, initTemplate) => (dispatch) => {
    Promise.all([
        dispatch(fetchElectricBills(offerId)),
        dispatch(fetchInteractiveProposal(offerId)),
        dispatch(fetchInteractiveProposalContent(offerId, initTemplate)),
    ]).catch(() => {});
};
