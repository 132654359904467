import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider } from 'sunwise-ui';

import {
    ReduxFieldInput,
    ReduxFieldSelect,
} from 'common/components/form/redux';
import ShowErrors from 'common/components/ShowErrors';

import { ROLE_LEVELS } from '../../companyRoles/constants';
import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const UserForm = ({
    companyId,
    errors,
    filterData,
    financierGroupType,
    formValues,
    groups,
    handleClickCloseForm,
    handleClickResetPassword,
    handleSubmit,
    isNewRecord,
    isSaving,
    onChangePassword,
    onChangePasswordConfirmation,
    save,
}) => {
    const { t } = useTranslation();
    const groupsOptions = groups
        .filter(
            ({ financier_group_type }) =>
                financier_group_type?.level !== ROLE_LEVELS.OWNER
        )
        .map((group) => ({
            label: group.name,
            value: group.id,
        }));

    groupsOptions.unshift({ value: '', label: t('Select a role') });

    const handleOnClickSubmit = (values) =>
        save(filterData, { ...values, financier: companyId });

    return (
        <form onSubmit={handleSubmit(handleOnClickSubmit)}>
            <Field
                component={ReduxFieldSelect}
                label={`${t('Role')} *`}
                name="financier_group"
                options={groupsOptions}
            />

            <Field
                component={ReduxFieldInput}
                label={`${t('Name')} *`}
                name="first_name"
                placeholder={t('Enter name')}
            />

            <Field
                component={ReduxFieldInput}
                label={`${t('Last name')} *`}
                name="last_name"
                placeholder={t('Enter last name')}
            />

            <Field
                component={ReduxFieldInput}
                label={`${t('Second last name')}`}
                name="second_surname"
                placeholder={t('Enter last name')}
            />

            <Field
                component={ReduxFieldInput}
                disabled={!isNewRecord}
                label={`${t('Email')} *`}
                name="email"
                placeholder={t('Enter email')}
            />

            <Field
                component={ReduxFieldInput}
                label={t('Phone')}
                name="phone"
                placeholder={t('Enter phone')}
            />

            {isNewRecord && (
                <React.Fragment>
                    <Field
                        component={ReduxFieldInput}
                        label={`${t('Password')} *`}
                        name="password"
                        onChange={(e) => onChangePassword(e)}
                        placeholder={t('Enter password')}
                        type="password"
                    />

                    <Field
                        component={ReduxFieldInput}
                        label={`${t('Confirm password')} *`}
                        name="confirm_password"
                        onChange={(e) =>
                            onChangePasswordConfirmation(e, formValues.password)
                        }
                        onFocus={(e) =>
                            onChangePasswordConfirmation(e, formValues.password)
                        }
                        placeholder={t('Enter password again')}
                        type="password"
                    />
                </React.Fragment>
            )}

            <ShowErrors errors={errors} />

            <Divider sx={{ mb: 2, mx: -2 }} />

            <Box display="flex">
                <Button
                    onClick={() => handleClickResetPassword(formValues.id)}
                    variant="text"
                    visible={!isNewRecord && financierGroupType?.level === 0}
                >
                    {t('Restore password')}
                </Button>
                <Box display="flex" sx={{ ml: 'auto', gap: 1 }}>
                    <Button
                        color="secondary"
                        onClick={handleClickCloseForm}
                        variant="text"
                    >
                        {t('Cancel')}
                    </Button>
                    <Button disabled={isSaving} type="submit">
                        {t('Save')}
                    </Button>
                </Box>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    save: (filterData, values) => dispatch(actions.save(filterData, values)),
});

UserForm.propTypes = {
    companyId: PropTypes.string,
    errors: PropTypes.object,
    filterData: PropTypes.object,
    financierGroupType: PropTypes.object,
    formValues: PropTypes.object,
    groups: PropTypes.array,
    handleClickCloseForm: PropTypes.func,
    handleClickResetPassword: PropTypes.func,
    handleSubmit: PropTypes.func,
    isNewRecord: PropTypes.bool,
    isSaving: PropTypes.bool,
    onChangePassword: PropTypes.func,
    onChangePasswordConfirmation: PropTypes.func,
    save: PropTypes.func,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(UserForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
