import { filterProducts } from 'common/api/financierProduct';

import {
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_FAILURE,
    FETCH_PRODUCTS_SUCCESS,
} from '../actionTypes';
import { financialActions } from '../reducer';

export default ({
        orderBy,
        page = 0,
        pageSize,
        searchText,
        sortBy,
        status,
    } = {}) =>
    (dispatch) => {
        dispatch(financialActions[FETCH_PRODUCTS]());

        filterProducts({
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
            status,
        })
            .then((response) =>
                dispatch(
                    financialActions[FETCH_PRODUCTS_SUCCESS](response.data)
                )
            )
            .catch((error) =>
                dispatch(
                    financialActions[FETCH_PRODUCTS_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
