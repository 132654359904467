import { SET_INITIALIZE } from '../actionTypes';
import { editTemplateViewActions } from '../reducer';

import fetchTemplate from './fetchTemplate';

export default (id, initTemplate) => async (dispatch) => {
    dispatch(editTemplateViewActions[SET_INITIALIZE](true));
    const templateData = await dispatch(fetchTemplate(id));
    if (templateData) {
        initTemplate(templateData);
        dispatch(editTemplateViewActions[SET_INITIALIZE](false));
    } else dispatch(editTemplateViewActions[SET_INITIALIZE](false));
};
