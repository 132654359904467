import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider } from 'sunwise-ui';

import { ReduxFieldAutocomplete } from 'common/components/form/redux';

import * as actions from '../actions';
import { NAME } from '../constants';
import { getUsersOptions } from '../helpers';
import * as selectors from '../selectors';

const ReassignAdvisorForm = ({ filterData, handleSubmit, save, users }) => {
    const { t } = useTranslation();
    const handleOnClickSave = (values) => save(filterData, values);

    return (
        <form>
            <Field
                component={ReduxFieldAutocomplete}
                name="financial_advisor"
                options={getUsersOptions(users)}
            />

            <Divider sx={{ mb: 2, mx: -2 }} />

            <Box sx={{ textAlign: 'right' }}>
                <Button onClick={handleSubmit(handleOnClickSave)}>
                    {t('Save')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialReassignAdvisorValues,
});

const mapDispatchToProps = (dispatch) => ({
    save: (filterData, values) =>
        dispatch(actions.bulkReassignAdvisor(filterData, values)),
});

ReassignAdvisorForm.propTypes = {
    filterData: PropTypes.object,
    handleSubmit: PropTypes.func,
    save: PropTypes.func,
    users: PropTypes.array,
};

const ReassignAdvisorFormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/reassing-advisor-form`,
})(ReassignAdvisorForm);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReassignAdvisorFormContainer);
