import { styled } from '@mui/material/styles';
import { Menu } from 'sunwise-ui';

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiList-root': {
        'li, span': { fontSize: '14px' },
        padding: 0,
    },
    '& .MuiPaper-root': {
        background:
            theme.palette.mode === 'dark'
                ? '#1B1C1F'
                : theme.palette.secondary.main,
        color: '#fff',
    },
}));

export default StyledMenu;
