import { updateCommercialOffer } from 'common/api/proposals';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPDATE_CREDIT,
    UPDATE_CREDIT_FAILURE,
    UPDATE_CREDIT_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

import fetchCreditApplication from './fetchCreditApplication';

export default (values, callback) => (dispatch) => {
    const newValues = {
        hitch: values.hitch,
        hitch_percentage: (values.hitch / values.amount) * 100,
        term: values.term,
    };

    dispatch(creditFileActions[UPDATE_CREDIT]());

    updateCommercialOffer(newValues, values.id)
        .then(() => {
            dispatch(creditFileActions[UPDATE_CREDIT_SUCCESS]());
            dispatch(fetchCreditApplication(values.creditApplicationId));
            if (callback) callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                creditFileActions[UPDATE_CREDIT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
