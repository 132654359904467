import { SET_SMART_DOCUMENT_FORM_MODE } from '../actionTypes';
import { SMART_DOCUMENT_FORM_CREATE_MODE } from '../constants';
import { templateDocumentsActions } from '../reducer';

import initializeSmartDocumentForm from './initializeDocumentForm';
import openDocumentModal from './openDocumentModal';

export default (proposalId) => (dispatch) => {
    dispatch(
        templateDocumentsActions[SET_SMART_DOCUMENT_FORM_MODE](
            SMART_DOCUMENT_FORM_CREATE_MODE
        )
    );
    dispatch(
        initializeSmartDocumentForm({
            commercial_offer_id: proposalId,
        })
    );
    dispatch(openDocumentModal(true));
};
