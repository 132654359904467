import React from 'react';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, ListItemButton, ListItemText } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/DropdownCardMenu';
import EmptyMessage from 'common/components/EmptyMessage';

const CardList = ({
    canModify,
    coverages,
    filterData,
    isFetching,
    prepareArchiveCoverage,
    selectedCoverage,
    setSelectedCoverage,
}) => {
    const { t } = useTranslation();

    if (isFetching)
        return (
            <Box sx={{ width: '100%' }}>
                <LinearProgress />
            </Box>
        );

    if (!isFetching && isEmpty(coverages))
        return <EmptyMessage text={t('There are no items to display')} />;

    return (
        <List>
            {coverages.map((item) => {
                const isArchived = item?.status_flag === 'archived';

                return (
                    <ListItem
                        disablePadding
                        key={`coverage-step-${item.id}`}
                        secondaryAction={
                            <DropdownCardMenu
                                items={[
                                    {
                                        handleClick: () =>
                                            prepareArchiveCoverage(
                                                filterData,
                                                item,
                                                'archived',
                                                () => setSelectedCoverage({})
                                            ),
                                        icon: (
                                            <ArchiveOutlinedIcon fontSize="small" />
                                        ),
                                        label: t('Archive'),
                                        visible: !isArchived,
                                    },
                                    {
                                        handleClick: () =>
                                            prepareArchiveCoverage(
                                                filterData,
                                                item,
                                                'active',
                                                () => setSelectedCoverage({})
                                            ),
                                        icon: (
                                            <UnarchiveOutlinedIcon fontSize="small" />
                                        ),
                                        label: t('Unarchive'),
                                        visible: isArchived,
                                    },
                                ]}
                                sxIconButton={{ ml: 'auto' }}
                                visible={canModify}
                            />
                        }
                        selected={
                            selectedCoverage && selectedCoverage.id === item.id
                        }
                    >
                        <ListItemButton
                            onClick={() => setSelectedCoverage(item)}
                            sx={{ py: 2 }}
                        >
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
};

CardList.propTypes = {
    canModify: PropTypes.bool,
    coverages: PropTypes.array,
    filterData: PropTypes.object,
    isFetching: PropTypes.bool,
    prepareArchiveCoverage: PropTypes.func,
    selectedCoverage: PropTypes.object,
    setSelectedCoverage: PropTypes.func,
};

export default CardList;
