import i18next from 'i18next';
import get from 'lodash/get';

export const getFilterOptions = () => [
    {
        label: i18next.t('All notifications'),
        value: 'all',
    },
    {
        label: i18next.t('Read'),
        value: true,
    },
    {
        label: i18next.t('Not read'),
        value: false,
    },
];

export const getFilterTypeOptions = () => [
    {
        label: i18next.t('All text', { context: 'female', count: 2 }),
        value: 'all',
    },
    {
        label: i18next.t('Alliance chat'),
        value: 'chat_ally',
    },
    {
        label: i18next.t('Document chat'),
        value: 'chat_document',
    },
    {
        label: i18next.t('Request chat'),
        value: 'chat_request',
    },
    {
        label: i18next.t('Mentions'),
        value: 'mentions',
    },
];

export const handleClickNotificationBuild = ({
    handleClickMarkAsReaded,
    notification,
    redirect,
    setIsOpenDrawer,
}) => {
    const { id, viewed } = notification;
    if (setIsOpenDrawer) setIsOpenDrawer(false);
    handleClickMarkAsReaded({ id, viewed });
    const parameters = get(notification, 'data.parameters', []);
    let path = '/' + get(notification, 'data.path', '#');
    path = path.replace(/credit-file/g, 'credits/file');
    const isLocalRedirect = !/^http/i.test(path);

    const extraQuery = [];

    if (isLocalRedirect) {
        parameters.forEach((item) => {
            path = path.replace(`{${item.key}}`, item.value);
            if (item.key === 'document_id')
                extraQuery.push(`documentId=${item.value}`);
        });
    }

    if (isLocalRedirect && path) {
        if (extraQuery.length > 0) {
            path = path + '?' + extraQuery.join('&');
        }
        redirect(path);
        return;
    }

    !isLocalRedirect && window.open(path, '_blank');
};
