import { getSelectedProducts } from 'common/api/products';

import {
    FETCH_SELECTED_PRODUCTS,
    FETCH_SELECTED_PRODUCTS_FAILURE,
    FETCH_SELECTED_PRODUCTS_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (allianceId) => (dispatch) => {
    dispatch(installerProfileActions[FETCH_SELECTED_PRODUCTS]());

    getSelectedProducts(allianceId)
        .then((response) =>
            dispatch(
                installerProfileActions[FETCH_SELECTED_PRODUCTS_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                installerProfileActions[FETCH_SELECTED_PRODUCTS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
