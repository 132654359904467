import i18next from 'i18next';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

import { updateConfigUser } from 'common/api/users';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { getUserSettings, getRuntimeLanguage } from 'common/utils/multiregion';
import { getSessionLocalStorage, updateSession } from 'common/utils/session';
import showToast from 'common/utils/showToast';

import { SAVE, SAVE_SUCCESS, SAVE_FAILURE } from '../actionTypes';
import { profileConfigurationActions } from '../reducer';

import initializeMultiregionForm from './initializeMultiregionForm';

export default (values, onSuccessCallback) => (dispatch) => {
    const newValues = {
        auto_timezone: values.auto_timezone,
        language: values.language,
        timezone: values.timezone,
    };

    dispatch(profileConfigurationActions[SAVE]());

    const message = i18next.t(
        'An error occurred during the update. Please try later'
    );

    updateConfigUser(newValues)
        .then((response) => {
            if (!response.data || !response.data.settings) {
                dispatch(profileConfigurationActions[SAVE_FAILURE]([message]));
                return;
            }

            dispatch(
                profileConfigurationActions[SAVE_SUCCESS](
                    response.data.settings
                )
            );

            dispatch(initializeMultiregionForm(newValues));

            const session = getSessionLocalStorage();
            if (session) {
                const settings = getUserSettings();
                const languageStored = get(
                    settings,
                    'language.key',
                    getRuntimeLanguage()
                );
                const isLanguageChanged =
                    languageStored !== response.data.settings.language.key;

                const isAccountWithoutMultiregionSettings = isNil(settings);

                updateSession({
                    settings: response.data.settings,
                });

                if (onSuccessCallback)
                    onSuccessCallback(
                        isAccountWithoutMultiregionSettings
                            ? isAccountWithoutMultiregionSettings
                            : isLanguageChanged,
                        response.data.settings.language.key
                    );
            }

            showToast();
        })
        .catch((error) => {
            dispatch(
                profileConfigurationActions[SAVE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
