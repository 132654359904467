import get from 'lodash/get';

import { getRoleDetails } from 'common/api/roles';

import {
    FETCH_ROLE_DETAILS,
    FETCH_ROLE_DETAILS_FAILURE,
    FETCH_ROLE_DETAILS_SUCCESS,
} from '../actionTypes';
import { getPermissionGroups, getRoleCategories } from '../helpers';
import { companyRolesActions } from '../reducer';

import initializeForm from './initializeForm';
import openModal from './openModal';
import resetForm from './resetForm';
import setIsNewRecord from './setIsNewRecord';

export default (roleId) => (dispatch) => {
    dispatch(companyRolesActions[FETCH_ROLE_DETAILS]());

    getRoleDetails(roleId)
        .then((response) => {
            const data = get(response, 'data.data', {});
            dispatch(companyRolesActions[FETCH_ROLE_DETAILS_SUCCESS](data));
            const categoriesSettings = get(data, 'category_settings', []);
            const {
                id,
                name,
                alliances_visualization_level,
                financier_group_type,
            } = data;
            dispatch(resetForm());
            dispatch(setIsNewRecord(false));
            dispatch(
                initializeForm({
                    alliances_visualization_level,
                    categories: getRoleCategories(categoriesSettings),
                    category: categoriesSettings[0]?.id,
                    financier_group_type: financier_group_type.id,
                    groups: getPermissionGroups(categoriesSettings),
                    id,
                    name,
                })
            );
            dispatch(openModal(true));
        })
        .catch((error) =>
            dispatch(
                companyRolesActions[FETCH_ROLE_DETAILS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
