import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useTheme } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import {
    editionLevels,
    PageToolbar,
    types,
    withTemplateCore,
} from 'sunwise-template-core';
import { Grid } from 'sunwise-ui';

import SwipeableDrawer from 'common/components/SwipeableDrawer';
import * as lisaFileActions from 'common/modules/lisaFiles/actions';
import { LoadingContext } from 'common/utils/contexts';
import { getToken } from 'common/utils/session';

import * as templateDocuments from '../templateDocuments/selectors';

import * as actions from './actions';
import {
    HeaderPreview,
    NotFound,
    PageWrapper,
    PreviewContent,
    StyledSideBarColumn,
    SupportMenuContent,
} from './components';
import { PDF_MEDIUM_QUALITY } from './constants';
import {
    getIsLoadingContextMessage,
    handleClickDocumentItemBuild,
    handleClickDownloadBuild,
    handleClickElectricBillBuild,
    handleCloseDrawer,
    handleScrollTopSideBar,
} from './helpers';
import * as selectors from './selectors';

const Container = ({
    downloadDatasheet,
    downloadElectricBill,
    downloadFile,
    downloadProposalCsv,
    electricBills,
    fetchElectricBill,
    fetchFinancingTemplate,
    fetchSmartDocumentContent,
    getTemplateUpdatedData,
    hasInteractiveProposalError,
    initialFetching,
    initTemplate,
    interactiveProposalContentData,
    interactiveProposalData,
    isDownloadingDatasheet,
    isDownloadingProposalCsv,
    isFetchingCustomTemplate,
    isFetchingElectricBills,
    isFetchingFinancingTemplate,
    isFetchingInteractiveProposal,
    isFetchingInteractiveProposalContent,
    isFetchingSmartDocumentContent,
    isUpdatingCustomTemplate,
    match,
    renderPdfFile,
    reset,
    resetTemplate,
    restorePages,
    setTemplateBackup,
    updateTemplate,
}) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const [editionDisabled, setEditionDisabled] = useState(true);
    const [selectedDocument, setSelectedDocument] = useState({});
    const [downloadFormat, setDownloadFormat] = useState(
        parseInt(localStorage.getItem('downloadFormat')) || 2
    );
    const loadingContext = useContext(LoadingContext);
    const offerId = match.params.uid;
    const creditId = match.params.creditId;
    const sideBarRef = useRef(null);
    const swipeableDrawerRef = useRef(null);

    useEffect(() => () => reset(), []);

    useEffect(() => {
        initialFetching(offerId, initTemplate);
    }, [initTemplate, offerId]);

    useEffect(() => {
        getIsLoadingContextMessage({
            isFetchingCustomTemplate,
            isFetchingElectricBills,
            isFetchingFinancingTemplate,
            isFetchingInteractiveProposal,
            isFetchingInteractiveProposalContent,
            isFetchingSmartDocumentContent,
            loadingContext,
        });
    }, [
        isFetchingCustomTemplate,
        isFetchingElectricBills,
        isFetchingFinancingTemplate,
        isFetchingInteractiveProposal,
        isFetchingInteractiveProposalContent,
        isFetchingSmartDocumentContent,
    ]);

    const handleUpdateTemplate = useCallback(() => {
        const { id } = selectedDocument;
        const updatedData = getTemplateUpdatedData();
        updateTemplate(id, setTemplateBackup, updatedData);
    }, [selectedDocument, getTemplateUpdatedData, setTemplateBackup]);

    const datasheets = get(interactiveProposalData, 'data_sheets', []);
    const hasApproved = get(interactiveProposalData, 'has_approbed', false);
    const smartDocuments = get(interactiveProposalData, 'smart_documments', []);
    const templateContent = get(
        interactiveProposalContentData,
        'content_str',
        '{}'
    );

    useEffect(() => {
        if (!isEmpty(interactiveProposalData))
            setSelectedDocument({
                id: offerId,
                name: get(interactiveProposalData, 'proposal_name', ''),
                type: types.ONE_PROPOSAL_TYPE,
            });
    }, [interactiveProposalData]);

    if (hasInteractiveProposalError)
        return <NotFound isLoading={isFetchingInteractiveProposal} />;

    const handleClickDatasheetItem = (datasheet) => {
        resetTemplate();
        setSelectedDocument(datasheet);
        setDownloadFormat(PDF_MEDIUM_QUALITY);
        handleCloseDrawer(swipeableDrawerRef);
    };

    const handleClickDownload = () =>
        handleClickDownloadBuild(
            downloadDatasheet,
            downloadElectricBill,
            downloadFile,
            downloadProposalCsv,
            downloadFormat,
            loadingContext,
            renderPdfFile,
            selectedDocument
        );

    const handleClickDocumentItem = (document) =>
        handleClickDocumentItemBuild(
            document,
            fetchSmartDocumentContent,
            fetchFinancingTemplate,
            initTemplate,
            resetTemplate,
            selectedDocument,
            setDownloadFormat,
            setSelectedDocument,
            swipeableDrawerRef,
            templateContent
        );

    const handleClickElectricBillItem = (document) =>
        handleClickElectricBillBuild(
            document,
            fetchElectricBill,
            loadingContext,
            setDownloadFormat,
            setSelectedDocument,
            swipeableDrawerRef
        );

    const handleClickEditButton = () => {
        setEditionDisabled(false);
        setTemplateBackup();
        handleScrollTopSideBar(sideBarRef);
        handleCloseDrawer(swipeableDrawerRef);
    };

    return (
        <>
            <Grid container spacing={0}>
                <Grid
                    item
                    lg={14}
                    md={12}
                    sx={{
                        height: editionDisabled
                            ? {
                                  md: 'calc(100vh - 64px)',
                                  xs: 'calc(100vh - 53px)',
                              }
                            : {
                                  md: 'calc(100vh - 113px)',
                                  xs: 'calc(100vh - 102px)',
                              },
                    }}
                    xs={18}
                >
                    <HeaderPreview
                        creditId={creditId}
                        hasApproved={hasApproved}
                    />

                    <PageToolbar
                        editionLevel={editionLevels.PARTIAL_EDITION_MODE}
                        reOrderDisabled
                        sxToolbar={{
                            pl: '76px',
                            ...(editionDisabled ? { minHeight: 0 } : {}),
                        }}
                        visible={!editionDisabled}
                    />

                    <PageWrapper
                        sx={{
                            height: {
                                md: 'calc(100% - 94px)',
                                xs: 'calc(100% - 130px)',
                            },
                        }}
                    >
                        <PreviewContent
                            editionDisabled={editionDisabled}
                            isFetchingCustomTemplate={isFetchingCustomTemplate}
                            isFetchingFinancingTemplate={
                                isFetchingFinancingTemplate
                            }
                            isFetchingInteractiveProposalContent={
                                isFetchingInteractiveProposalContent
                            }
                            isFetchingSmartDocumentContent={
                                isFetchingSmartDocumentContent
                            }
                            selectedDocument={selectedDocument}
                        />
                    </PageWrapper>
                </Grid>

                <StyledSideBarColumn
                    item
                    lg={4}
                    md={6}
                    ref={sideBarRef}
                    sx={{
                        backgroundColor: isDarkMode ? '#000' : '#fff',
                        borderLeft: isDarkMode
                            ? '1px solid rgba(145, 158, 171, 0.24)'
                            : '0',
                        display: { xs: 'none', md: 'block' },
                        height: editionDisabled
                            ? {
                                  md: 'calc(100vh - 64px)',
                                  xs: 'calc(100vh - 53px)',
                              }
                            : {
                                  md: 'calc(100vh - 113px)',
                                  xs: 'calc(100vh - 102px)',
                              },
                    }}
                >
                    <SupportMenuContent
                        closeDrawer={() =>
                            handleCloseDrawer(swipeableDrawerRef)
                        }
                        datasheets={datasheets}
                        downloadFormat={downloadFormat}
                        editionDisabled={editionDisabled}
                        electricBills={electricBills}
                        handleClickDatasheetItem={handleClickDatasheetItem}
                        handleClickDocumentItem={handleClickDocumentItem}
                        handleClickDownload={handleClickDownload}
                        handleClickEditButton={handleClickEditButton}
                        handleClickElectricBillItem={
                            handleClickElectricBillItem
                        }
                        handleUpdateTemplate={handleUpdateTemplate}
                        initTemplate={initTemplate}
                        interactiveProposalData={interactiveProposalData}
                        isDownloading={
                            isDownloadingDatasheet || isDownloadingProposalCsv
                        }
                        isFetching={
                            isFetchingCustomTemplate ||
                            isFetchingElectricBills ||
                            isFetchingFinancingTemplate ||
                            isFetchingInteractiveProposal ||
                            isFetchingInteractiveProposalContent ||
                            isFetchingSmartDocumentContent
                        }
                        isUpdatingCustomTemplate={isUpdatingCustomTemplate}
                        proposalId={offerId}
                        resetTemplate={resetTemplate}
                        restorePages={restorePages}
                        selectedDocument={selectedDocument}
                        setDownloadFormat={setDownloadFormat}
                        setEditionDisabled={setEditionDisabled}
                        setSelectedDocument={setSelectedDocument}
                        smartDocuments={smartDocuments}
                    />
                </StyledSideBarColumn>
            </Grid>

            <SwipeableDrawer
                ref={swipeableDrawerRef}
                sxWrapper={{ height: 'calc(100vh - 100px)' }}
            >
                <SupportMenuContent
                    closeDrawer={() => handleCloseDrawer(swipeableDrawerRef)}
                    datasheets={datasheets}
                    downloadFormat={downloadFormat}
                    editionDisabled={editionDisabled}
                    electricBills={electricBills}
                    handleClickDatasheetItem={handleClickDatasheetItem}
                    handleClickDocumentItem={handleClickDocumentItem}
                    handleClickDownload={handleClickDownload}
                    handleClickEditButton={handleClickEditButton}
                    handleClickElectricBillItem={handleClickElectricBillItem}
                    handleUpdateTemplate={handleUpdateTemplate}
                    initTemplate={initTemplate}
                    interactiveProposalData={interactiveProposalData}
                    isDownloading={
                        isDownloadingDatasheet || isDownloadingProposalCsv
                    }
                    isFetching={
                        isFetchingCustomTemplate ||
                        isFetchingElectricBills ||
                        isFetchingFinancingTemplate ||
                        isFetchingInteractiveProposal ||
                        isFetchingInteractiveProposalContent ||
                        isFetchingSmartDocumentContent
                    }
                    isUpdatingCustomTemplate={isUpdatingCustomTemplate}
                    proposalId={offerId}
                    resetTemplate={resetTemplate}
                    restorePages={restorePages}
                    selectedDocument={selectedDocument}
                    setDownloadFormat={setDownloadFormat}
                    setEditionDisabled={setEditionDisabled}
                    setSelectedDocument={setSelectedDocument}
                    smartDocuments={smartDocuments}
                />
            </SwipeableDrawer>
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    electricBills: selectors.getElectricBillsData,
    hasInteractiveProposalError: selectors.getHasInteractiveProposalError,
    interactiveProposalContentData: selectors.getInteractiveProposalContentData,
    interactiveProposalData: selectors.getInteractiveProposalData,
    isDownloadingDatasheet: selectors.getIsDownloadingDatasheet,
    isDownloadingProposalCsv: selectors.getIsDownloadingProposalCsv,
    isFetchingCustomTemplate: templateDocuments.getIsFetchingCustomTemplate,
    isFetchingFinancingTemplate: selectors.getIsFetchingFinancingTemplate,
    isFetchingElectricBills: selectors.getIsFetchingElectricBills,
    isFetchingInteractiveProposal: selectors.getIsFetchingInteractiveProposal,
    isFetchingInteractiveProposalContent:
        selectors.getIsFetchingInteractiveProposalContent,
    isFetchingSmartDocumentContent: selectors.getIsFetchingSmartDocumentContent,
    isUpdatingCustomTemplate: selectors.getIsUpdatingCustomTemplate,
});

const mapDispatchToProps = (dispatch) => ({
    downloadDatasheet: (datasheetName, openLoading, closeLoading) =>
        dispatch(
            actions.downloadDatasheet(datasheetName, openLoading, closeLoading)
        ),
    downloadElectricBill: (archive, fileName, loadingContext, name, urlType) =>
        dispatch(
            actions.downloadElectricBill(
                archive,
                fileName,
                loadingContext,
                name,
                urlType
            )
        ),
    downloadFile: (name, url, type) =>
        dispatch(actions.downloadFile(name, url, type)),
    downloadProposalCsv: (id, name, loadingContext) =>
        dispatch(actions.downloadProposalCsv(id, name, loadingContext)),
    fetchElectricBill: (file_name, onSuccess) =>
        dispatch(lisaFileActions.getFile(file_name, onSuccess)),
    fetchFinancingTemplate: (id, initTemplate) =>
        dispatch(actions.fetchFinancingTemplateContent(id, initTemplate)),
    fetchSmartDocumentContent: (id, initTemplate) =>
        dispatch(actions.fetchSmartDocumentContent(id, initTemplate)),
    initialFetching: (offerId, initTemplate) =>
        dispatch(actions.initialFetching(offerId, initTemplate)),
    renderPdfFile: (
        templateFinishedId,
        templateName,
        openLoading,
        closeLoading,
        templateType
    ) =>
        dispatch(
            actions.renderPdfFile(
                templateFinishedId,
                templateName,
                openLoading,
                closeLoading,
                templateType
            )
        ),
    reset: () => dispatch(actions.reset()),
    updateTemplate: (reviewId, setTemplateBackup, updatedData) =>
        dispatch(
            actions.updateTemplate(reviewId, setTemplateBackup, updatedData)
        ),
});

Container.propTypes = {
    downloadDatasheet: PropTypes.func,
    downloadElectricBill: PropTypes.func,
    downloadFile: PropTypes.func,
    downloadProposalCsv: PropTypes.func,
    electricBills: PropTypes.array,
    fetchElectricBill: PropTypes.func,
    fetchFinancingTemplate: PropTypes.func,
    fetchSmartDocumentContent: PropTypes.func,
    getTemplateUpdatedData: PropTypes.func,
    hasInteractiveProposalError: PropTypes.bool,
    initialFetching: PropTypes.func,
    initTemplate: PropTypes.func,
    interactiveProposalContentData: PropTypes.object,
    interactiveProposalData: PropTypes.object,
    isDownloadingDatasheet: PropTypes.bool,
    isDownloadingProposalCsv: PropTypes.bool,
    isFetchingCustomTemplate: PropTypes.bool,
    isFetchingElectricBills: PropTypes.bool,
    isFetchingFinancingTemplate: PropTypes.bool,
    isFetchingInteractiveProposal: PropTypes.bool,
    isFetchingInteractiveProposalContent: PropTypes.bool,
    isFetchingSmartDocumentContent: PropTypes.bool,
    isUpdatingCustomTemplate: PropTypes.bool,
    match: PropTypes.object,
    renderPdfFile: PropTypes.func,
    reset: PropTypes.func,
    resetTemplate: PropTypes.func,
    restorePages: PropTypes.func,
    setTemplateBackup: PropTypes.func,
    updateTemplate: PropTypes.func,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withTemplateCore(() => ({
        application: 'sunpay',
        baseUrl: process.env.REACT_APP_API_URL,
        editionMode: editionLevels.NO_EDITION_MODE,
        froalaLicenseKey: process.env.REACT_APP_FROALA_LICENSE,
        googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        language: 'es-mx',
        token: getToken(),
    }))
)(Container);
