import { bulkCreditStatus } from 'common/api/creditApplications';
import { getStatusKey, showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_BULK_CHANGE_STATUS,
    SAVE_BULK_CHANGE_STATUS_FAILURE,
    SAVE_BULK_CHANGE_STATUS_SUCCESS,
} from '../actionTypes';
import { creditsActions } from '../reducer';

import closeModalChangeStatus from './closeModalChangeStatus';

export default (filterData, values) => (dispatch) => {
    const newValues = {
        credits: values.credits,
        status: getStatusKey(values.status),
    };

    dispatch(creditsActions[SAVE_BULK_CHANGE_STATUS]());

    bulkCreditStatus(newValues)
        .then(() => {
            dispatch(creditsActions[SAVE_BULK_CHANGE_STATUS_SUCCESS]());
            dispatch(closeModalChangeStatus(filterData));
            if (values.callback) values.callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                creditsActions[SAVE_BULK_CHANGE_STATUS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
