import { getProposalsTotals } from 'common/api/installers';

import {
    FETCH_PROPOSALS_TOTALS,
    FETCH_PROPOSALS_TOTALS_FAILURE,
    FETCH_PROPOSALS_TOTALS_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

import initializeForm from './initializeForm';

export default (values, installerId) => (dispatch) => {
    const initialDate = values.initial_date;
    const finalDate = values.final_date;
    dispatch(initializeForm(values));

    dispatch(installerProfileActions[FETCH_PROPOSALS_TOTALS]());

    getProposalsTotals(initialDate, finalDate, installerId)
        .then((response) =>
            dispatch(
                installerProfileActions[FETCH_PROPOSALS_TOTALS_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                installerProfileActions[FETCH_PROPOSALS_TOTALS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
