import { createSlice } from '@reduxjs/toolkit';

import {
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_IS_OPEN_MODAL,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: { confirm_password: null, id: null, new_password: null },
    isOpenModal: false,
    save: { data: {}, errors: [], isSaving: false },
};

const resetPasswordUserSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.save.errors = [];
        },
        [SAVE]: (state) => {
            state.save.isSaving = true;
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state) => {
            state.save.isSaving = false;
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
    },
});

export const resetPasswordUserActions = resetPasswordUserSlice.actions;

export default resetPasswordUserSlice.reducer;
