import { createSlice } from '@reduxjs/toolkit';

import {
    APPROVE_CREDIT,
    APPROVE_CREDIT_FAILURE,
    APPROVE_CREDIT_SUCCESS,
    CANCEL_CREDIT,
    CANCEL_CREDIT_FAILURE,
    CANCEL_CREDIT_SUCCESS,
    CHANGE_STATUS_CREDIT,
    CHANGE_STATUS_CREDIT_FAILURE,
    CHANGE_STATUS_CREDIT_SUCCESS,
    CHANGE_STATUS_FILE,
    CHANGE_STATUS_FILE_FAILURE,
    CHANGE_STATUS_FILE_SUCCESS,
    CLOSE_CREDIT,
    CLOSE_CREDIT_FAILURE,
    CLOSE_CREDIT_SUCCESS,
    DELETE_DOCUMENT,
    DELETE_DOCUMENT_FAILURE,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_LOCATION,
    DELETE_LOCATION_FAILURE,
    DELETE_LOCATION_SUCCESS,
    FETCH_CONSUMPTION_PDFS,
    FETCH_CONSUMPTION_PDFS_FAILURE,
    FETCH_CONSUMPTION_PDFS_SUCCESS,
    FETCH_CONTRACT_DOCUMENTS,
    FETCH_CONTRACT_DOCUMENTS_FAILURE,
    FETCH_CONTRACT_DOCUMENTS_SUCCESS,
    FETCH_CREDIT_APPLICATION,
    FETCH_CREDIT_APPLICATION_FAILURE,
    FETCH_CREDIT_APPLICATION_SUCCESS,
    FETCH_CREDIT_BUREAU,
    FETCH_CREDIT_BUREAU_FAILURE,
    FETCH_CREDIT_BUREAU_SUCCESS,
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_FAILURE,
    FETCH_DOCUMENTS_SUCCESS,
    FETCH_FINANCIAL_DATA,
    FETCH_FINANCIAL_DATA_FAILURE,
    FETCH_FINANCIAL_DATA_SUCCESS,
    FETCH_GEOCODE,
    FETCH_GEOCODE_FAILURE,
    FETCH_GEOCODE_POINT,
    FETCH_GEOCODE_POINT_FAILURE,
    FETCH_GEOCODE_POINT_SUCCESS,
    FETCH_GEOCODE_SUCCESS,
    FETCH_INSURANCE_DOCUMENTS,
    FETCH_INSURANCE_DOCUMENTS_FAILURE,
    FETCH_INSURANCE_DOCUMENTS_SUCCESS,
    FETCH_PROPOSAL_BACKUPS,
    FETCH_PROPOSAL_BACKUPS_FAILURE,
    FETCH_PROPOSAL_BACKUPS_SUCCESS,
    FETCH_REQUEST_FORM_DOCUMENTS,
    FETCH_REQUEST_FORM_DOCUMENTS_FAILURE,
    FETCH_REQUEST_FORM_DOCUMENTS_SUCCESS,
    FETCH_REQUIRED_DOCUMENTS_LIST,
    FETCH_REQUIRED_DOCUMENTS_LIST_FAILURE,
    FETCH_REQUIRED_DOCUMENTS_LIST_SUCCESS,
    INITIALIZE_ASSIGN_USER_FORM,
    INITIALIZE_FORM,
    INITIALIZE_FORM_EDIT_CREDIT,
    INITIALIZE_LOCATION_FORM,
    OPEN_MODAL_CONTACT,
    PRE_APPROVE_CREDIT,
    PRE_APPROVE_CREDIT_FAILURE,
    PRE_APPROVE_CREDIT_SUCCESS,
    REJECT_FILE,
    REJECT_FILE_FAILURE,
    REJECT_FILE_SUCCESS,
    RESET_ASSIGN_USER_FORM,
    RESET_FORM,
    RESET_LOCATION_FORM,
    RESET_UPLOAD_FORM,
    SAVE_ASSIGNED_USER,
    SAVE_ASSIGNED_USER_FAILURE,
    SAVE_ASSIGNED_USER_SUCCESS,
    SAVE_DOCUMENT,
    SAVE_DOCUMENT_FAILURE,
    SAVE_DOCUMENT_SUCCESS,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SAVE_LOCATION,
    SAVE_LOCATION_FAILURE,
    SAVE_LOCATION_SUCCESS,
    SET_IS_DOWNLOADING,
    SET_SHOW_GEOCODE_RESULTS,
    UPDATE_CREDIT,
    UPDATE_CREDIT_FAILURE,
    UPDATE_CREDIT_SUCCESS,
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    approveCredit: { errors: [], isFetching: false },
    cancelCredit: { errors: [], isFetching: false },
    changeStatusCredit: { errors: [], isFetching: false },
    changeStatusFile: { errors: [], isFetching: false },
    closeCredit: { errors: [], isFetching: false },
    contractDocuments: { data: [], errors: [], isFetching: false },
    creditApplication: { data: {}, errors: [], isFetching: false },
    creditBureauData: { data: null, errors: null, isLoading: false },
    deleteDocument: {
        errors: [],
        isDeleting: false,
    },
    deleteLocation: { errors: [], isDeleting: false },
    documents: { data: [], errors: [], isFetching: false },
    fetchConsumptionPdfs: { data: [], errors: [], isFetching: false },
    fetchGeocode: { data: [], errors: null, isLoading: false },
    fetchGeocodePoint: { data: null, errors: null, isFetching: false },
    fileUploaded: { data: {}, errors: [], isUploading: false },
    financial: { data: [], errors: [], isFetching: false },
    initialValues: {
        emails: [{ email: '' }],
        first_lastname: '',
        id: null,
        name: '',
        second_lastname: '',
        telephones: [{ telephone: '' }],
    },
    initialValuesAssignUserForm: { user: null },
    initialValuesFormEditCredit: {
        amount: 0,
        hitch: 0,
        id: null,
        rate: 0,
        term: 0,
    },
    initialValuesUploadForm: { file: null, name: '' },
    insuranceDocuments: { data: [], errors: [], isFetching: false },
    isDownloading: false,
    isOpenModalContact: false,
    locationInitialValues: {
        description: '',
        id: null,
        latitude: '',
        longitude: '',
        position: {},
    },
    preApproveCredit: { errors: [], isFetching: false },
    proposalBackups: { data: [], errors: [], isFetching: false },
    rejectFile: { errors: [], isFetching: false },
    requestFormDocuments: { data: [], errors: [], isFetching: false },
    requiredDocumentsList: { data: [], errors: [], isFetching: false },
    saveAssignedUser: { errors: [], isFetching: false },
    saveDocument: { errors: [], isSaving: false },
    saveItem: { errors: null, isSaving: false },
    saveLocation: { errors: [], isSaving: false },
    showGeocodeResults: false,
    updateCredit: { errors: [], isSaving: false },
};

const creditFileSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [APPROVE_CREDIT]: (state) => {
            state.approveCredit.isFetching = true;
        },
        [APPROVE_CREDIT_FAILURE]: (state, action) => {
            state.approveCredit = {
                ...state.approveCredit,
                errors: action.payload,
                isFetching: false,
            };
        },
        [APPROVE_CREDIT_SUCCESS]: (state) => {
            state.approveCredit.isFetching = false;
        },
        [CANCEL_CREDIT]: (state) => {
            state.cancelCredit.isFetching = true;
        },
        [CANCEL_CREDIT_FAILURE]: (state, action) => {
            state.cancelCredit = {
                ...state.cancelCredit,
                errors: action.payload,
                isFetching: false,
            };
        },
        [CANCEL_CREDIT_SUCCESS]: (state) => {
            state.cancelCredit.isFetching = false;
        },
        [CHANGE_STATUS_FILE]: (state) => {
            state.changeStatusFile.isFetching = true;
        },
        [CHANGE_STATUS_FILE_FAILURE]: (state, action) => {
            state.changeStatusFile = {
                ...state.changeStatusFile,
                errors: action.payload,
                isFetching: false,
            };
        },
        [CHANGE_STATUS_FILE_SUCCESS]: (state) => {
            state.changeStatusFile.isFetching = false;
        },
        [CHANGE_STATUS_CREDIT]: (state) => {
            state.changeStatusCredit.isFetching = true;
        },
        [CHANGE_STATUS_CREDIT_FAILURE]: (state, action) => {
            state.changeStatusCredit = {
                ...state.changeStatusCredit,
                errors: action.payload,
                isFetching: false,
            };
        },
        [CHANGE_STATUS_CREDIT_SUCCESS]: (state) => {
            state.changeStatusCredit.isFetching = false;
        },
        [CLOSE_CREDIT]: (state) => {
            state.closeCredit.isFetching = true;
        },
        [CLOSE_CREDIT_FAILURE]: (state, action) => {
            state.closeCredit = {
                ...state.closeCredit,
                errors: action.payload,
                isFetching: false,
            };
        },
        [CLOSE_CREDIT_SUCCESS]: (state) => {
            state.closeCredit.isFetching = false;
        },
        [DELETE_DOCUMENT]: (state) => {
            state.deleteDocument.isDeleting = true;
        },
        [DELETE_DOCUMENT_FAILURE]: (state, action) => {
            state.deleteDocument = {
                ...state.deleteDocument,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_DOCUMENT_SUCCESS]: (state) => {
            state.deleteDocument.isDeleting = false;
        },
        [DELETE_LOCATION]: (state) => {
            state.deleteLocation.isDeleting = true;
        },
        [DELETE_LOCATION_FAILURE]: (state, action) => {
            state.deleteLocation = {
                ...state.deleteLocation,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_LOCATION_SUCCESS]: (state) => {
            state.deleteLocation.isDeleting = false;
        },
        [FETCH_CONSUMPTION_PDFS]: (state) => {
            state.fetchConsumptionPdfs.isFetching = true;
        },
        [FETCH_CONSUMPTION_PDFS_FAILURE]: (state, action) => {
            state.fetchConsumptionPdfs = {
                ...state.fetchConsumptionPdfs,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONSUMPTION_PDFS_SUCCESS]: (state, action) => {
            state.fetchConsumptionPdfs = {
                ...state.fetchConsumptionPdfs,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONTRACT_DOCUMENTS]: (state) => {
            state.contractDocuments.isFetching = true;
        },
        [FETCH_CONTRACT_DOCUMENTS_FAILURE]: (state, action) => {
            state.contractDocuments = {
                ...state.contractDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CONTRACT_DOCUMENTS_SUCCESS]: (state, action) => {
            state.contractDocuments = {
                ...state.contractDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CREDIT_APPLICATION]: (state) => {
            state.creditApplication.isFetching = true;
        },
        [FETCH_CREDIT_APPLICATION_FAILURE]: (state, action) => {
            state.creditApplication = {
                ...state.creditApplication,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CREDIT_APPLICATION_SUCCESS]: (state, action) => {
            state.creditApplication = {
                ...state.creditApplication,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CREDIT_BUREAU]: (state) => {
            state.creditBureauData.isLoading = true;
        },
        [FETCH_CREDIT_BUREAU_FAILURE]: (state, action) => {
            state.creditBureauData = {
                ...state.creditBureauData,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_CREDIT_BUREAU_SUCCESS]: (state, action) => {
            state.creditBureauData = {
                ...state.creditBureauData,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_DOCUMENTS]: (state) => {
            state.documents.isFetching = true;
        },
        [FETCH_DOCUMENTS_FAILURE]: (state, action) => {
            state.documents = {
                ...state.documents,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOCUMENTS_SUCCESS]: (state, action) => {
            state.documents = {
                ...state.documents,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIAL_DATA]: (state) => {
            state.financial.isFetching = true;
        },
        [FETCH_FINANCIAL_DATA_FAILURE]: (state, action) => {
            state.financial = {
                ...state.financial,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_FINANCIAL_DATA_SUCCESS]: (state, action) => {
            state.financial = {
                ...state.financial,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_GEOCODE]: (state) => {
            state.fetchGeocode.isLoading = true;
        },
        [FETCH_GEOCODE_FAILURE]: (state, action) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                errors: action.payload,
                isLoading: false,
            };
        },
        [FETCH_GEOCODE_SUCCESS]: (state, action) => {
            state.fetchGeocode = {
                ...state.fetchGeocode,
                data: action.payload,
                isLoading: false,
            };
        },
        [FETCH_GEOCODE_POINT]: (state) => {
            state.fetchGeocodePoint.isFetching = true;
        },
        [FETCH_GEOCODE_POINT_FAILURE]: (state, action) => {
            state.fetchGeocodePoint = {
                ...state.fetchGeocodePoint,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_GEOCODE_POINT_SUCCESS]: (state, action) => {
            state.fetchGeocodePoint = {
                ...state.fetchGeocodePoint,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INSURANCE_DOCUMENTS]: (state) => {
            state.insuranceDocuments.isFetching = true;
        },
        [FETCH_INSURANCE_DOCUMENTS_FAILURE]: (state, action) => {
            state.insuranceDocuments = {
                ...state.insuranceDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INSURANCE_DOCUMENTS_SUCCESS]: (state, action) => {
            state.insuranceDocuments = {
                ...state.insuranceDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSAL_BACKUPS]: (state) => {
            state.proposalBackups.isFetching = true;
        },
        [FETCH_PROPOSAL_BACKUPS_FAILURE]: (state, action) => {
            state.proposalBackups = {
                ...state.proposalBackups,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PROPOSAL_BACKUPS_SUCCESS]: (state, action) => {
            state.proposalBackups = {
                ...state.proposalBackups,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUEST_FORM_DOCUMENTS]: (state) => {
            state.requestFormDocuments.isFetching = true;
        },
        [FETCH_REQUEST_FORM_DOCUMENTS_FAILURE]: (state, action) => {
            state.requestFormDocuments = {
                ...state.requestFormDocuments,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUEST_FORM_DOCUMENTS_SUCCESS]: (state, action) => {
            state.requestFormDocuments = {
                ...state.requestFormDocuments,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUIRED_DOCUMENTS_LIST]: (state) => {
            state.requiredDocumentsList.isFetching = true;
        },
        [FETCH_REQUIRED_DOCUMENTS_LIST_FAILURE]: (state, action) => {
            state.requiredDocumentsList = {
                ...state.requiredDocumentsList,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_REQUIRED_DOCUMENTS_LIST_SUCCESS]: (state, action) => {
            state.requiredDocumentsList = {
                ...state.requiredDocumentsList,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_ASSIGN_USER_FORM]: (state, action) => {
            state.initialValuesAssignUserForm = {
                ...state.initialValuesAssignUserForm,
                ...action.payload,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INITIALIZE_FORM_EDIT_CREDIT]: (state, action) => {
            state.initialValuesFormEditCredit = {
                ...state.initialValuesFormEditCredit,
                ...action.payload,
            };
        },
        [INITIALIZE_LOCATION_FORM]: (state, action) => {
            state.locationInitialValues = {
                ...state.locationInitialValues,
                ...action.payload,
            };
        },
        [OPEN_MODAL_CONTACT]: (state, action) => {
            state.isOpenModalContact = action.payload;
        },
        [PRE_APPROVE_CREDIT]: (state) => {
            state.preApproveCredit.isFetching = true;
        },
        [PRE_APPROVE_CREDIT_FAILURE]: (state, action) => {
            state.preApproveCredit = {
                ...state.preApproveCredit,
                errors: action.payload,
                isFetching: false,
            };
        },
        [PRE_APPROVE_CREDIT_SUCCESS]: (state) => {
            state.preApproveCredit.isFetching = false;
        },
        [REJECT_FILE]: (state) => {
            state.rejectFile.isFetching = true;
        },
        [REJECT_FILE_FAILURE]: (state, action) => {
            state.rejectFile = {
                ...state.rejectFile,
                errors: action.payload,
                isFetching: false,
            };
        },
        [REJECT_FILE_SUCCESS]: (state) => {
            state.rejectFile.isFetching = false;
        },
        [RESET_ASSIGN_USER_FORM]: (state) => {
            state.initialValuesAssignUserForm =
                INITIAL_STATE.initialValuesAssignUserForm;
            state.saveAssignedUser = INITIAL_STATE.saveAssignedUser;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveItem = INITIAL_STATE.saveItem;
        },
        [RESET_UPLOAD_FORM]: (state) => {
            state.initialValuesUploadForm =
                INITIAL_STATE.initialValuesUploadForm;
            state.fileUploaded = INITIAL_STATE.fileUploaded;
        },
        [RESET_LOCATION_FORM]: (state) => {
            state.locationInitialValues = INITIAL_STATE.locationInitialValues;
            state.saveLocation = INITIAL_STATE.saveLocation;
        },
        [SAVE_ASSIGNED_USER]: (state) => {
            state.saveAssignedUser.isSaving = true;
        },
        [SAVE_ASSIGNED_USER_FAILURE]: (state, action) => {
            state.saveAssignedUser = {
                ...state.saveAssignedUser,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ASSIGNED_USER_SUCCESS]: (state) => {
            state.saveAssignedUser.isSaving = false;
        },
        [SAVE_DOCUMENT]: (state) => {
            state.saveDocument.isSaving = true;
        },
        [SAVE_DOCUMENT_FAILURE]: (state, action) => {
            state.saveDocument = {
                ...state.saveDocument,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_DOCUMENT_SUCCESS]: (state) => {
            state.saveDocument.isSaving = false;
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem.isSaving = true;
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state) => {
            state.saveItem.isSaving = false;
        },
        [SAVE_LOCATION]: (state) => {
            state.saveLocation.isSaving = true;
        },
        [SAVE_LOCATION_FAILURE]: (state, action) => {
            state.saveLocation = {
                ...state.saveLocation,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_LOCATION_SUCCESS]: (state) => {
            state.saveLocation.isSaving = false;
        },
        [SET_IS_DOWNLOADING]: (state, action) => {
            state.isDownloading = action.payload;
        },
        [SET_SHOW_GEOCODE_RESULTS]: (state, action) => {
            state.showGeocodeResults = action.payload;
        },
        [UPDATE_CREDIT]: (state) => {
            state.updateCredit.isSaving = true;
        },
        [UPDATE_CREDIT_FAILURE]: (state, action) => {
            state.updateCredit = {
                ...state.updateCredit,
                errors: action.payload,
                isSaving: false,
            };
        },
        [UPDATE_CREDIT_SUCCESS]: (state) => {
            state.updateCredit.isSaving = false;
        },
        [UPLOAD_DOCUMENT]: (state) => {
            state.fileUploaded.isUploading = true;
        },
        [UPLOAD_DOCUMENT_FAILURE]: (state, action) => {
            state.fileUploaded = {
                ...state.fileUploaded,
                errors: action.payload,
                isUploading: false,
            };
        },
        [UPLOAD_DOCUMENT_SUCCESS]: (state, action) => {
            const { data, index } = action.payload;
            const requiredDocuments = state.requiredDocumentsList.data;
            const document = requiredDocuments[index];
            state.requiredDocumentsList.data[index] = {
                ...document,
                file: data.file,
                status: data.status,
            };
            state.fileUploaded = {
                ...state.fileUploaded,
                data: data,
                isUploading: false,
            };
        },
    },
});

export const creditFileActions = creditFileSlice.actions;

export default creditFileSlice.reducer;
