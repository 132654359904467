import i18next from 'i18next';

import { duplicateTemplate } from 'common/api/templates';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DUPLICATE_TEMPLATE,
    DUPLICATE_TEMPLATE_FAILURE,
    DUPLICATE_TEMPLATE_SUCCESS,
} from '../actionTypes';
import { templatesActions } from '../reducer';

import fetchTemplates from './fetchTemplates';

export default (product) => (dispatch) => {
    dispatch(templatesActions[DUPLICATE_TEMPLATE]());

    duplicateTemplate(product.id)
        .then(() => {
            dispatch(templatesActions[DUPLICATE_TEMPLATE_SUCCESS]());
            dispatch(fetchTemplates());
            showToast({ body: i18next.t('Successfully duplicated') });
        })
        .catch((error) => {
            dispatch(
                templatesActions[DUPLICATE_TEMPLATE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
