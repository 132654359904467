import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, DataGrid, IconButton, Typography } from 'sunwise-ui';

const ListItems = ({
    canModify,
    financierGroupType,
    items,
    isFetching,
    handleClickUpdate,
}) => {
    const { t } = useTranslation();

    const ActionsFormatter = (_, row) => {
        const roleLevel = row.financier_group_type;
        const canEdit =
            roleLevel.level > financierGroupType.level && roleLevel.level > 0;
        return (
            <div>
                {canEdit ? (
                    <IconButton
                        disabled={!canModify}
                        onClick={() => handleClickUpdate(row.id)}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                ) : (
                    <Button
                        color="secondary"
                        disabled={!canModify}
                        onClick={() => handleClickUpdate(row.id)}
                        startIcon={<VisibilityIcon fontSize="small" />}
                        variant="text"
                    >
                        {t('View permissions')}
                    </Button>
                )}
            </div>
        );
    };

    const columns = [
        {
            field: 'name',
            flex: 1,
            headerName: t('Role name'),
            sortable: false,
        },
        {
            field: 'financier_group_type',
            flex: 1,
            headerName: t('Role level'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.financier_group_type?.name}
                    </Typography>
                );
            },
            sortable: false,
        },
        {
            field: '',
            headerName: '',
            hide: !canModify,
            sortable: false,
            renderCell: (params) => {
                const { row } = params;
                return ActionsFormatter(row.name, row);
            },
            width: 300,
        },
    ];

    return (
        <DataGrid
            autoHeight
            columns={columns}
            components={{ LoadingOverlay: LinearProgress }}
            disableColumnMenu
            disableSelectionOnClick
            getRowHeight={() => 'auto'}
            hideFooterPagination
            loading={isFetching}
            rows={items}
        />
    );
};

ListItems.propTypes = {
    canModify: PropTypes.bool,
    financierGroupType: PropTypes.object,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
};

export default ListItems;
