import isEmpty from 'lodash/isEmpty';

import fetchTemplates from './fetchTemplates';
import resetForm from './resetForm';
import setIsOpenModal from './setIsOpenModal';

export default (filterData = {}) =>
    (dispatch) => {
        dispatch(resetForm());
        if (!isEmpty(filterData)) dispatch(fetchTemplates(filterData));
        dispatch(setIsOpenModal(false));
    };
