import React from 'react';

import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import {
    editionLevels,
    PageToolbar,
    TemplateView,
} from 'sunwise-template-core';
import { Box } from 'sunwise-ui';

import { custom1 } from 'common/utils/customScrolls';

import EmptyTemplate from './EmptyTemplate';

const PageWrapper = styled(Box)`
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-y: scroll;
    padding-top: 16px;

    ${custom1}
`;

const PageContainer = ({ isEmptyPages, onAddPage, selectedPageId }) => {
    if (isEmptyPages) return <EmptyTemplate onClick={() => onAddPage()} />;
    if (isNil(selectedPageId))
        return <TemplateView editionLevel={editionLevels.FULL_EDITION_MODE} />;
    return (
        <>
            <Box display="flex" flexDirection="column" justifyContent="center">
                <PageToolbar />
            </Box>
            <PageWrapper>
                <TemplateView editionLevel={editionLevels.FULL_EDITION_MODE} />
            </PageWrapper>
        </>
    );
};

PageContainer.propTypes = {
    isEmptyPages: PropTypes.bool,
    onAddPage: PropTypes.func,
    selectedPageId: PropTypes.string,
};

export default PageContainer;
