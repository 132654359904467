import React from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemText,
    Drawer,
    Typography,
} from 'sunwise-ui';

import NavNotificationsList from '../../../modules/notifications/components/NavNotificationsList';

const StyledButtonText = styled(ListItemText)`
    & .MuiListItemText-primary {
        font-size: 13px;
        text-align: center;
        color: #ff9a00;
    }
`;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
}));

const NotificationsDrawer = ({
    handleToggleDrawer,
    isOpenDrawer,
    redirect,
    setIsOpenDrawer,
}) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Drawer
                anchor="right"
                open={isOpenDrawer}
                onClose={handleToggleDrawer(false)}
                onOpen={handleToggleDrawer(true)}
            >
                <DrawerHeader>
                    <IconButton onClick={handleToggleDrawer(false)}>
                        <ChevronRightIcon />
                    </IconButton>

                    <Typography
                        variant="body2"
                        sx={{
                            fontWeight: 'bold',
                            padding: '1rem',
                            textAlign: 'center',
                            width: '85%',
                        }}
                    >
                        {t('Notifications')}
                    </Typography>
                </DrawerHeader>

                <Divider sx={{ mx: '1rem' }} />

                <Box
                    sx={{
                        height: 'calc(100% - 46px)',
                        overflowY: 'scroll',
                        padding: '0 15px',
                        width: { xs: '100vw', md: 480 },
                        py: 2,
                    }}
                    role="presentation"
                >
                    <NavNotificationsList setIsOpenDrawer={setIsOpenDrawer} />
                </Box>

                <ListItem disablePadding>
                    <ListItemButton
                        sx={{ padding: '1rem' }}
                        onClick={() => {
                            setIsOpenDrawer(false);
                            redirect('/notifications');
                        }}
                    >
                        <StyledButtonText
                            primary={t('View all notifications')}
                        />
                    </ListItemButton>
                </ListItem>
            </Drawer>
        </React.Fragment>
    );
};

NotificationsDrawer.propTypes = {
    handleToggleDrawer: PropTypes.func,
    isOpenDrawer: PropTypes.bool,
    redirect: PropTypes.func,
    setIsOpenDrawer: PropTypes.func,
};

export default NotificationsDrawer;
