import React, { useEffect, useState } from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Button, Card, Container as MaterialContainer, Grid } from 'sunwise-ui';

import { Modal, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissions';
import withPermissions from 'common/hocs/withPermissions';
import { getSession, getFinancierGroupType } from 'common/utils/session';

import resetPasswordUser from '../resetPasswordUser';

import * as actions from './actions';
import { ListItems, UserForm } from './components';
import * as selectors from './selectors';

const Container = ({
    activateItem,
    canModify,
    filterItems,
    getGroupsUser,
    groups,
    handleChangePassword,
    handleChangePasswordConfirmation,
    handleClickCloseModalProduct,
    handleClickCreate,
    handleClickResetPassword,
    handleClickUpdate,
    isFetching,
    isNewRecord,
    isOpenModalProduct,
    items,
    pagination,
    validPassword,
    validPasswordConfirmation,
    validPasswordConfirmationLength,
}) => {
    const { t } = useTranslation();
    const session = getSession();
    const [filterData, setFilterData] = useState({
        orderBy: null,
        page: 0,
        pageSize: 25,
        searchText: '',
        sortBy: null,
    });

    useEffect(() => {
        filterItems(filterData);
    }, []);

    useEffect(() => {
        getGroupsUser(session.financier);
    }, [session.financier]);

    const handleChangeSwitch = (item) => activateItem(filterData, item);

    const onChangePassword = (event) => handleChangePassword(event);

    const onChangePasswordConfirmation = (event, password) =>
        handleChangePasswordConfirmation(event, password);

    const financier_group = get(session, 'financier_group', null);

    return (
        <MaterialContainer maxWidth={false}>
            <Card>
                <Card.Header>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs>
                            <TitleIcon title={t('User', { count: 2 })} />
                        </Grid>

                        <Grid hidden={!canModify} item>
                            <Button onClick={handleClickCreate}>
                                Agregar Usuario
                            </Button>
                        </Grid>
                    </Grid>
                </Card.Header>

                <Card.Body>
                    <ListItems
                        canModify={canModify}
                        filterData={filterData}
                        filterItems={filterItems}
                        handleChangeSwitch={handleChangeSwitch}
                        handleClickUpdate={handleClickUpdate}
                        isFetching={isFetching}
                        items={items}
                        paginationData={pagination}
                        session={session}
                        setFilterData={setFilterData}
                    />
                </Card.Body>
            </Card>

            <Modal
                onHide={handleClickCloseModalProduct}
                show={isOpenModalProduct}
                size="lg"
                title={`${isNewRecord ? t('Add') : t('Update')} ${t('User')}`}
            >
                <UserForm
                    companyId={session.financier}
                    company_group={financier_group}
                    financierGroupType={getFinancierGroupType()}
                    filterData={filterData}
                    groups={groups}
                    handleClickCloseForm={handleClickCloseModalProduct}
                    handleClickResetPassword={handleClickResetPassword}
                    onChangePassword={onChangePassword}
                    onChangePasswordConfirmation={onChangePasswordConfirmation}
                    validPassword={validPassword}
                    validPasswordConfirmation={validPasswordConfirmation}
                    validPasswordConfirmationLength={
                        validPasswordConfirmationLength
                    }
                />
            </Modal>
            <resetPasswordUser.Container />
        </MaterialContainer>
    );
};

const mapStateToProps = createStructuredSelector({
    company: selectors.getDataFetchCompany,
    groups: selectors.getDataFetchGroups,
    isFetching: selectors.getIsFetchingItems,
    isNewRecord: selectors.getIsNewRecord,
    isOpenModalProduct: selectors.getIsOpenModalProduct,
    items: selectors.getDataFetchItems,
    pagination: selectors.getFetchItemsPagination,
    validPassword: selectors.getValidPassword,
    validPasswordConfirmation: selectors.getValidPasswordConfirmation,
    validPasswordConfirmationLength:
        selectors.getValidPasswordConfirmationLength,
});

const mapDispatchToProps = (dispatch) => ({
    activateItem: (filterData, item) =>
        dispatch(actions.activateItem(filterData, item)),
    filterItems: (filterData) => dispatch(actions.filterItems(filterData)),
    getGroupsUser: (id) => dispatch(actions.getGroups(id)),
    handleChangePassword: (event) =>
        dispatch(actions.handleChangePassword(event)),
    handleChangePasswordConfirmation: (event, password) =>
        dispatch(actions.handleChangePasswordConfirmation(event, password)),
    handleClickCloseModalProduct: () =>
        dispatch(actions.toggleModalProduct(false)),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    handleClickResetPassword: (id) =>
        dispatch(resetPasswordUser.actions.prepareReset(id)),
    handleClickUpdate: (item) => dispatch(actions.prepareUpdate(item)),
});

Container.propTypes = {
    activateItem: PropTypes.func,
    canModify: PropTypes.bool,
    filterItems: PropTypes.func,
    getGroupsUser: PropTypes.func,
    groups: PropTypes.array,
    handleChangePassword: PropTypes.func,
    handleChangePasswordConfirmation: PropTypes.func,
    handleClickCloseModalProduct: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickResetPassword: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isOpenModalProduct: PropTypes.bool,
    items: PropTypes.array,
    pagination: PropTypes.object,
    validPassword: PropTypes.bool,
    validPasswordConfirmation: PropTypes.bool,
    validPasswordConfirmationLength: PropTypes.bool,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.USERS_PERMISSION)
)(Container);
