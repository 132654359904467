import { activateItem } from 'common/api/users';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    ACTIVATE_ITEM,
    ACTIVATE_ITEM_FAILURE,
    ACTIVATE_ITEM_SUCCESS,
} from '../actionTypes';
import { companyUsersActions } from '../reducer';

import filterItems from './filterItems';

export default (filterData, currentItem) => (dispatch) => {
    const newValues = { is_enabled: !currentItem.is_enabled };

    dispatch(companyUsersActions[ACTIVATE_ITEM]());

    activateItem(newValues, currentItem.id)
        .then(() => {
            dispatch(companyUsersActions[ACTIVATE_ITEM_SUCCESS]());
            dispatch(filterItems(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch(
                companyUsersActions[ACTIVATE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
