import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Card, Divider, Grid, List, ListItem } from 'sunwise-ui';

import { StyledFigure, StyledH2, Modal } from 'common/components';

import ProductsForm from './ProductsForm';
import ProductStatus from './ProductStatus';

const RequestedProductsCard = ({
    allianceId,
    canModify,
    fetchProductsList,
    fetchSelectedProducts,
    financier,
    handleChangeProductStatus,
    productsList,
    selectedProducts,
}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    return (
        <Card>
            <Card.Header>
                <Grid alignItems="center" container>
                    <Grid item>
                        <StyledFigure
                            sizeFigure={56}
                            src={financier ? financier.image : ''}
                        />
                    </Grid>

                    <Grid item xs>
                        <StyledH2>{t('Requested Products')}</StyledH2>
                    </Grid>
                </Grid>
            </Card.Header>

            <List>
                {selectedProducts.map((product) => (
                    <ListItem key={`requested-product-${product.id}`}>
                        <ProductStatus
                            allianceId={allianceId}
                            callback={() => fetchSelectedProducts()}
                            canModify={canModify}
                            handleChangeProductStatus={
                                handleChangeProductStatus
                            }
                            product={product}
                        />
                    </ListItem>
                ))}
            </List>

            <Divider />

            <Card.Body hidden={!canModify} sx={{ textAlign: 'right' }}>
                <Button
                    color="secondary"
                    onClick={() => setShowModal(true)}
                    variant="outlined"
                >
                    {t('Update products')}
                </Button>
            </Card.Body>

            <Modal
                onEnter={() => fetchProductsList()}
                onHide={() => setShowModal(false)}
                show={showModal}
                size="lg"
                title={t('What product do you want to enable?')}
            >
                <ProductsForm
                    allianceId={allianceId}
                    callback={() => {
                        fetchSelectedProducts();
                        setShowModal(false);
                    }}
                    products={productsList}
                />
            </Modal>
        </Card>
    );
};

RequestedProductsCard.propTypes = {
    allianceId: PropTypes.number,
    canModify: PropTypes.bool,
    fetchProductsList: PropTypes.func,
    fetchSelectedProducts: PropTypes.func,
    financier: PropTypes.object,
    handleChangeProductStatus: PropTypes.func,
    productsList: PropTypes.array,
    selectedProducts: PropTypes.array,
};

export default RequestedProductsCard;
