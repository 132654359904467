import React from 'react';

import PropTypes from 'prop-types';

import alerts from 'common/modules/alerts';

import Footer from './defaultLayout/Footer';

const LoginLayout = ({ children }) => {
    return (
        <>
            {children}
            <Footer showPlaylist={false} />
            <alerts.Container />
        </>
    );
};

LoginLayout.propTypes = {
    children: PropTypes.element,
};

export default LoginLayout;
