import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { bulkProducts } from 'common/api/financierProduct';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
} from '../actionTypes';
import { financialActions } from '../reducer';

import fetchProducts from './fetchProducts';

export default (ids, value, filterData, callback) => (dispatch) => {
    const products = ids.map((id) => ({ id, status_flag: value }));

    dispatch(financialActions[BULK_UPDATE_ITEMS]());

    bulkProducts({ products })
        .then(() => {
            dispatch(financialActions[BULK_UPDATE_ITEMS_SUCCESS]());
            if (!isEmpty(filterData)) dispatch(fetchProducts(filterData));
            if (value === 'deleted')
                showToast({ body: i18next.t('It was successfully removed') });
            if (value === 'archived')
                showToast({ body: i18next.t('Archived successfully') });
            if (value === 'active')
                showToast({ body: i18next.t('Unarchived successfully') });
            dispatch(alerts.actions.close());
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                financialActions[BULK_UPDATE_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
