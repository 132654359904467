import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
    FETCH_CATEGORIES_SETTINGS,
    FETCH_CATEGORIES_SETTINGS_FAILURE,
    FETCH_CATEGORIES_SETTINGS_SUCCESS,
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
    FETCH_ROLE_LEVELS,
    FETCH_ROLE_LEVELS_FAILURE,
    FETCH_ROLE_LEVELS_SUCCESS,
    FETCH_ROLE_LEVEL_DETAILS,
    FETCH_ROLE_LEVEL_DETAILS_FAILURE,
    FETCH_ROLE_LEVEL_DETAILS_SUCCESS,
    FETCH_ROLE_DETAILS,
    FETCH_ROLE_DETAILS_FAILURE,
    FETCH_ROLE_DETAILS_SUCCESS,
    INITIALIZE_FORM,
    SET_IS_OPEN_MODAL,
    RESET_FORM,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_IS_NEW_RECORD,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    categoriesSettings: { data: [], errors: [], isFetching: false },
    deleteItem: { errors: [], isDeleting: false },
    fetchItems: { data: [], errors: [], isFetching: false },
    fetchRoleLevels: { data: [], errors: [], isFetching: false },
    fetchRoleDetails: { data: [], errors: [], isFetching: false },
    fetchRoleLevelDetails: { data: [], errors: [], isFetching: false },
    initialValues: {
        alliances_visualization_level: 0,
        categories: [],
        category: null,
        financier_group_type: '',
        groups: [],
        id: null,
        name: '',
    },
    isNewRecord: true,
    isOpenModal: false,
    saveItem: { errors: [], isSaving: false },
};

const companyRolesSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_ITEM]: (state) => {
            state.deleteItem.isDeleting = true;
        },
        [DELETE_ITEM_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_ITEM_SUCCESS]: (state) => {
            state.deleteItem.isDeleting = false;
        },
        [FETCH_CATEGORIES_SETTINGS]: (state) => {
            state.categoriesSettings.isFetching = true;
        },
        [FETCH_CATEGORIES_SETTINGS_FAILURE]: (state, action) => {
            state.categoriesSettings = {
                ...state.categoriesSettings,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_CATEGORIES_SETTINGS_SUCCESS]: (state, action) => {
            state.categoriesSettings = {
                ...state.categoriesSettings,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS]: (state) => {
            state.fetchItems.isFetching = true;
        },
        [FETCH_ITEMS_FAILURE]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ROLE_LEVELS]: (state) => {
            state.fetchRoleLevels.isFetching = true;
        },
        [FETCH_ROLE_LEVELS_FAILURE]: (state, action) => {
            state.fetchRoleLevels = {
                ...state.fetchRoleLevels,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ROLE_LEVELS_SUCCESS]: (state, action) => {
            state.fetchRoleLevels = {
                ...state.fetchRoleLevels,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ROLE_LEVEL_DETAILS]: (state) => {
            state.fetchRoleLevelDetails.isFetching = true;
        },
        [FETCH_ROLE_LEVEL_DETAILS_FAILURE]: (state, action) => {
            state.fetchRoleLevelDetails = {
                ...state.fetchRoleLevelDetails,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ROLE_LEVEL_DETAILS_SUCCESS]: (state, action) => {
            state.fetchRoleLevelDetails = {
                ...state.fetchRoleLevelDetails,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ROLE_DETAILS]: (state) => {
            state.fetchRoleDetails.isFetching = true;
        },
        [FETCH_ROLE_DETAILS_FAILURE]: (state, action) => {
            state.fetchRoleDetails = {
                ...state.fetchRoleDetails,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ROLE_DETAILS_SUCCESS]: (state, action) => {
            state.fetchRoleDetails = {
                ...state.fetchRoleDetails,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [SET_IS_OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveItem.errors = [];
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem.isSaving = true;
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state) => {
            state.saveItem.isSaving = false;
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
    },
});

export const companyRolesActions = companyRolesSlice.actions;

export default companyRolesSlice.reducer;
