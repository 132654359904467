import {
    FILE_COMPLEX_TYPE,
    FILE_SIMPLE_TYPE,
    INFONAVIT_TYPE,
    INSURANCE_TYPE,
} from 'common/constants';
import { getIsCiBanco, getIsSerfimex } from 'common/utils/session';

import prepareUpdateBright from '../../financialBright/actions/prepareUpdate';
import prepareUpdateBrove from '../../financialBrove/actions/prepareUpdate';
import prepareUpdateCeide from '../../financialCeide/actions/prepareUpdate';
import prepareUpdateCiBanco from '../../financialCiBanco/actions/prepareUpdate';
import prepareUpdateGalt from '../../financialGalt/actions/prepareUpdate';
import prepareUpdateGirasol from '../../financialRedGirasol/actions/prepareUpdate';
import prepareUpdateSerfimex from '../../financialSerfimex/actions/prepareUpdate';

export default (type, product, disabled) => (dispatch) => {
    const isCibanco = getIsCiBanco();
    const isSerfimex = getIsSerfimex();
    switch (type) {
        case FILE_COMPLEX_TYPE:
            dispatch(prepareUpdateGirasol(product, disabled));
            break;
        case FILE_SIMPLE_TYPE:
            dispatch(prepareUpdateGalt(product, disabled));
            break;
        case INFONAVIT_TYPE:
            dispatch(prepareUpdateCeide(product, disabled));
            break;
        case INSURANCE_TYPE:
            dispatch(prepareUpdateBrove(product, disabled));
            break;
        default: {
            if (isCibanco) dispatch(prepareUpdateCiBanco(product, disabled));
            else if (isSerfimex)
                dispatch(prepareUpdateSerfimex(product, disabled));
            else dispatch(prepareUpdateBright(product, disabled));
            break;
        }
    }
};
