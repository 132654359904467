import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from 'common/constants/multiregion';

import TRANSLATIONS_EN from './i18n/translations/en.json';
import TRANSLATIONS_ES from './i18n/translations/es.json';
import TRANSLATIONS_PT from './i18n/translations/pt.json';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: ['dev', 'staging'].includes(process.env.REACT_APP_ENV),
        fallbackLng: DEFAULT_LANGUAGE,
        interpolation: { escapeValue: false },
        keySeparator: false,
        lng: 'es',
        react: {
            transSupportBasicHtmlNodes: true,
            useSuspense: true,
        },
        resources: {
            en: { translation: TRANSLATIONS_EN },
            es: { translation: TRANSLATIONS_ES },
            pt: { translation: TRANSLATIONS_PT },
        },
    });

export default i18n;
