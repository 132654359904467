import React from 'react';

import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    TextField,
} from 'sunwise-ui';

const PasswordInput = ({
    control,
    formValues,
    handleClickShowPassoword,
    label,
    name,
    showPassword,
    type,
}) => (
    <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
            <FormControl error={Boolean(error)} fullWidth>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '0.75rem',
                    }}
                >
                    <Box>
                        <LockIcon fontSize="small" />
                    </Box>

                    <TextField
                        fullWidth
                        label={label}
                        name={name}
                        type={type}
                        variant="standard"
                        {...field}
                    />

                    {!isEmpty(formValues?.password) && (
                        <Box sx={{ mb: 1, position: 'absolute', right: 1 }}>
                            <IconButton
                                onClick={() => handleClickShowPassoword()}
                            >
                                {showPassword ? (
                                    <VisibilityOffIcon fontSize="small" />
                                ) : (
                                    <VisibilityIcon fontSize="small" />
                                )}
                            </IconButton>
                        </Box>
                    )}
                </Box>

                {Boolean(error) && (
                    <FormHelperText>{error?.message}</FormHelperText>
                )}
            </FormControl>
        )}
    />
);

PasswordInput.propTypes = {
    control: PropTypes.object,
    formValues: PropTypes.object,
    handleClickShowPassoword: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string,
    showPassword: PropTypes.bool,
    type: PropTypes.string,
};

export default PasswordInput;
