import initializeAssignProductForm from './initializeAssignProductForm';
import openAssignProductModal from './openAssignProductModal';

export default (ids, callback) => (dispatch) => {
    const values = {
        alliances: ids,
        callback,
    };
    dispatch(initializeAssignProductForm(values));
    dispatch(openAssignProductModal(true));
};
