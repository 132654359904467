import i18next from 'i18next';
import is from 'is_js';

export default (values = {}) => {
    const errors = {};
    if (is.empty(values.name)) errors.name = i18next.t('Name cannot be empty');
    if (is.empty(values.first_lastname))
        errors.first_lastname = i18next.t('Last name cannot be empty');
    return errors;
};
