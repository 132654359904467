import { createSlice } from '@reduxjs/toolkit';

import {
    ACTIVATE_ITEM,
    ACTIVATE_ITEM_FAILURE,
    ACTIVATE_ITEM_SUCCESS,
    DELETE_ITEM,
    DELETE_ITEM_FAILURE,
    DELETE_ITEM_SUCCESS,
    FETCH_COMPANY,
    FETCH_COMPANY_FAILURE,
    FETCH_COMPANY_SUCCESS,
    FETCH_GROUPS,
    FETCH_GROUPS_FAILURE,
    FETCH_GROUPS_SUCCESS,
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_LIST,
    FETCH_ITEMS_LIST_FAILURE,
    FETCH_ITEMS_LIST_SUCCESS,
    FETCH_ITEMS_SUCCESS,
    INITIALIZE_FORM,
    INVALID_PASSWORD,
    INVALID_PASSWORD_CONFIRMATION,
    INVALID_PASSWORD_CONFIRMATION_LENGTH,
    OPEN_MODAL_PRODUCT,
    RESET_FORM,
    RESTART_PASSWORD,
    RESTART_PASSWORD_FAILURE,
    RESTART_PASSWORD_SUCCESS,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_IS_NEW_RECORD,
    VALID_PASSWORD,
    VALID_PASSWORD_CONFIRMATION,
    VALID_PASSWORD_CONFIRMATION_LENGTH,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    activateItem: { errors: [], isFetching: false },
    deleteItem: { errors: [], isDeleting: false },
    fetchCompany: { data: [], errors: [], isFetching: false },
    fetchGroups: { data: [], errors: [], isFetching: false },
    fetchItems: {
        data: { data: [], pagination: {} },
        errors: [],
        isFetching: true,
    },
    fetchItemsList: { data: [], errors: [], isFetching: false },
    initialValues: {
        confirm_password: '',
        email: '',
        financier: '',
        financier_group: '',
        first_name: '',
        id: null,
        last_name: '',
        password: '',
        phone: '',
        second_surname: '',
    },
    isNewRecord: true,
    isOpenModalProduct: false,
    saveItem: { errors: [], isSaving: false },
    validPassword: false,
    validPasswordConfirmation: false,
    validPasswordConfirmationLength: false,
};

const companyUsersSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ACTIVATE_ITEM]: (state) => {
            state.activateItem.isFetching = true;
        },
        [ACTIVATE_ITEM_FAILURE]: (state, action) => {
            state.activateItem = {
                ...state.activateItem,
                errors: action.payload,
                isFetching: false,
            };
        },
        [ACTIVATE_ITEM_SUCCESS]: (state) => {
            state.activateItem.isFetching = false;
        },
        [DELETE_ITEM]: (state) => {
            state.deleteItem.isDeleting = true;
        },
        [DELETE_ITEM_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                errors: action.payload,
                isDeleting: false,
            };
        },
        [DELETE_ITEM_SUCCESS]: (state) => {
            state.deleteItem.isDeleting = false;
        },
        [FETCH_COMPANY]: (state) => {
            state.fetchCompany.isFetching = true;
        },
        [FETCH_COMPANY_FAILURE]: (state, action) => {
            state.fetchCompany = {
                ...state.fetchCompany,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPANY_SUCCESS]: (state, action) => {
            state.fetchCompany = {
                ...state.fetchCompany,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_GROUPS]: (state) => {
            state.fetchGroups.isFetching = true;
        },
        [FETCH_GROUPS_FAILURE]: (state, action) => {
            state.fetchGroups = {
                ...state.fetchGroups,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_GROUPS_SUCCESS]: (state, action) => {
            state.fetchGroups = {
                ...state.fetchGroups,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS]: (state) => {
            state.fetchItems.isFetching = true;
        },
        [FETCH_ITEMS_FAILURE]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_SUCCESS]: (state, action) => {
            state.fetchItems = {
                ...state.fetchItems,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_LIST]: (state) => {
            state.fetchItemsList.isFetching = true;
        },
        [FETCH_ITEMS_LIST_FAILURE]: (state, action) => {
            state.fetchItemsList = {
                ...state.fetchItemsList,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ITEMS_LIST_SUCCESS]: (state, action) => {
            state.fetchItemsList = {
                ...state.fetchItemsList,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [OPEN_MODAL_PRODUCT]: (state, action) => {
            state.isOpenModalProduct = action.payload;
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [RESTART_PASSWORD]: (state) => {
            state.saveItem.isSaving = true;
        },
        [RESTART_PASSWORD_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [RESTART_PASSWORD_SUCCESS]: (state) => {
            state.saveItem.isSaving = true;
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem.isSaving = true;
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state) => {
            state.saveItem.isSaving = false;
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [VALID_PASSWORD]: (state) => {
            state.validPassword = true;
        },
        [INVALID_PASSWORD]: (state) => {
            state.validPassword = false;
        },
        [VALID_PASSWORD_CONFIRMATION]: (state) => {
            state.validPasswordConfirmation = true;
        },
        [INVALID_PASSWORD_CONFIRMATION]: (state) => {
            state.validPasswordConfirmation = false;
        },
        [VALID_PASSWORD_CONFIRMATION_LENGTH]: (state) => {
            state.validPasswordConfirmationLength = true;
        },
        [INVALID_PASSWORD_CONFIRMATION_LENGTH]: (state) => {
            state.validPasswordConfirmationLength = false;
        },
    },
});

export const companyUsersActions = companyUsersSlice.actions;

export default companyUsersSlice.reducer;
