import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'common/components/Modal';

import Form from './Form';

const _Modal = ({
    documentsType,
    documentsTypeToSelect,
    fetchDocumentsType,
    handleClickClose,
    isUploading,
    show,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            onHide={handleClickClose}
            show={show}
            size="lg"
            title={t('Attach file')}
        >
            <Form
                documentsType={documentsType}
                documentsTypeToSelect={documentsTypeToSelect}
                fetchDocumentsType={fetchDocumentsType}
                handleClickCancel={handleClickClose}
                isUploading={isUploading}
            />
        </Modal>
    );
};

_Modal.propTypes = {
    documentsType: PropTypes.array,
    documentsTypeToSelect: PropTypes.array,
    fetchDocumentsType: PropTypes.func,
    handleClickClose: PropTypes.func,
    isUploading: PropTypes.bool,
    show: PropTypes.bool,
};

export default _Modal;
