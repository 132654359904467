import isEmpty from 'lodash/isEmpty';

import { unlinkAlliance } from 'common/api/financierAllianceInstaller';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UNLINK_ALLIANCE,
    UNLINK_ALLIANCE_FAILURE,
    UNLINK_ALLIANCE_SUCCESS,
} from '../actionTypes';
import { installersActions } from '../reducer';

import fetchInstallers from './fetchInstallers';

export default (filterData, id) => (dispatch) => {
    dispatch(installersActions[UNLINK_ALLIANCE]());

    unlinkAlliance(id, { is_enabled: false })
        .then(() => {
            dispatch(installersActions[UNLINK_ALLIANCE_SUCCESS]());
            if (!isEmpty(filterData)) dispatch(fetchInstallers(filterData));
            showToast({ body: 'Se desvinculó exitosamente' });
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch(
                installersActions[UNLINK_ALLIANCE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
