import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Tooltip } from 'sunwise-ui';

const DrawerContent = ({ children, drawerSize, title }) => {
    const [openTooltip, setOpenTooltip] = React.useState(false);
    const theme = useTheme();
    const handleCloseTooltip = () => setOpenTooltip(false);
    const handleOpenTooltip = (drawerSize) => {
        if (drawerSize === 'normal') {
            setOpenTooltip(false);
            return;
        }
        setOpenTooltip(true);
    };
    const isDakMode = theme.palette.mode === 'dark';
    const color = isDakMode ? null : 'secondary.main';
    return (
        <Tooltip
            componentsProps={{
                tooltip: {
                    sx: {
                        '& .MuiTooltip-arrow': { color },
                        bgcolor: color,
                        color: isDakMode ? null : 'secondary.contrastText',
                    },
                },
            }}
            onClose={handleCloseTooltip}
            onOpen={() => handleOpenTooltip(drawerSize)}
            open={openTooltip}
            placement="right"
            title={title}
        >
            {children}
        </Tooltip>
    );
};

DrawerContent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    drawerSize: PropTypes.string,
    title: PropTypes.string,
};

export default DrawerContent;
