import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, CustomPagination, DataGrid } from 'sunwise-ui';

import BulkOperationsCard from 'common/components/BulkOperationsCard';

import ProductColumns from './ProductColumns';

const DEBOUNCE_TIME = 1500;

const ListItems = ({
    canDelete,
    canUpdate,
    filterData,
    filterItems,
    handleClickBulkItems,
    handleClickDuplicate,
    handleClickUpdate,
    handleSaveRow,
    isFetching,
    items,
    paginationData,
    setFilterData,
}) => {
    const { t } = useTranslation();
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedBulkOption, setSelectedBulkOption] = useState('');
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        handleOnFilter(filterData);
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnSelectStatus = (value) => {
        const filter = { ...filterData, page: 0, status: value };
        setFilterData(filter);
    };

    const columns = ProductColumns({
        canDelete,
        canUpdate,
        filterData,
        handleClickBulkItems,
        handleClickDuplicate,
        handleClickUpdate,
        rowModesModel,
        selectedRowId,
        setRowModesModel,
        setSelectedRowId,
    });

    const components = {
        LoadingOverlay: LinearProgress,
        Pagination: CustomPagination,
    };

    const componentsProps = {
        pagination: {
            rowsPerPageOptions: [25, 50, 75, 100],
        },
    };

    const getRowHeight = () => 'auto';

    const handleRowEditStart = (params, event) => {
        const id = params.row.id;
        if (isNull(selectedRowId)) setSelectedRowId(id);
        else event.defaultMuiPrevented = true;
        return false;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const initialState = {
        pagination: { pageSize: filterData?.pageSize },
    };

    const onPageChange = (page) => setFilterData({ ...filterData, page: page });

    const onPageSizeChange = (newPage) =>
        setFilterData({ ...filterData, pageSize: newPage });

    const onRowModesModelChange = (newModel) => setRowModesModel(newModel);

    const onSelectionModelChange = (newModel) => setSelectionModel(newModel);

    const onSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    const prefix = t('{{count}} selected template', {
        count: selectionModel.length,
    });

    const processRowUpdate = (newRow) => {
        handleSaveRow(filterData, newRow);
        return newRow;
    };

    const sx = {
        '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
            backgroundColor: 'rgba(255, 154, 0, 0.16)',
        },
        '& .MuiDataGrid-row--editing:hover': {
            backgroundColor: 'rgba(255, 154, 0, 0.08)',
        },
        '& .MuiInputBase-formControl input': {
            border: 0,
            boxShadow: 'none',
            outline: 'none',
        },
    };

    return (
        <>
            <BulkOperationsCard
                canDelete={canDelete}
                canUpdate={canUpdate}
                filterData={filterData}
                handleClickBulkItems={handleClickBulkItems}
                handleOnChangeSearch={handleOnChangeSearch}
                handleOnSelectStatus={handleOnSelectStatus}
                prefix={prefix}
                selectedBulkOption={selectedBulkOption}
                selectionModel={selectionModel}
                setSelectedBulkOption={setSelectedBulkOption}
                setSelectionModel={setSelectionModel}
            />

            <Card>
                <Card.Body>
                    <DataGrid
                        autoHeight
                        checkboxSelection={true}
                        columns={columns}
                        components={components}
                        componentsProps={componentsProps}
                        disableColumnMenu
                        disableSelectionOnClick
                        editMode="row"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowHeight={getRowHeight}
                        initialState={initialState}
                        loading={isFetching}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        onRowModesModelChange={onRowModesModelChange}
                        onSelectionModelChange={onSelectionModelChange}
                        onSortModelChange={onSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        processRowUpdate={processRowUpdate}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="server"
                        sx={sx}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    canDelete: PropTypes.bool,
    canUpdate: PropTypes.bool,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickBulkItems: PropTypes.func,
    handleClickDuplicate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default ListItems;
