import React from 'react';

import PropTypes from 'prop-types';

import { DEFAULT_LAT, DEFAULT_LNG } from 'common/constants';

import LabelError from '../LabelError';

import MapContainer from './MapContainer';

const ControlledMap = ({
    center,
    disableDefaultUI,
    error,
    heightMap,
    onChange,
    value,
    readOnly,
    title,
    zoom,
}) => {
    const onMarkerDragend = (_, marker) => {
        onChange({
            latitude: marker.position.lat(),
            longitude: marker.position.lng(),
        });
        return;
    };

    const handleOnPositionChanged = (mapProps, map) => {
        onChange({
            latitude: map.center.lat(),
            longitude: map.center.lng(),
        });
    };

    const latitude =
        value?.latitude &&
        -90 < parseFloat(value?.latitude) &&
        parseFloat(value?.latitude) < 90
            ? value?.latitude
            : center.lat;

    const longitude =
        value?.longitude &&
        -180 < parseFloat(value?.longitude) &&
        parseFloat(value?.longitude) < 180
            ? value?.longitude
            : center.lng;

    const markers = [
        {
            draggable: false,
            lat: latitude,
            lng: longitude,
            name: title,
            onDragend: onMarkerDragend,
            title: title,
        },
    ];

    return (
        <>
            <MapContainer
                center={{ lat: latitude, lng: longitude }}
                disableDefaultUI={disableDefaultUI}
                handleOnPositionChanged={handleOnPositionChanged}
                heightMap={heightMap}
                markers={markers}
                readOnly={readOnly}
                zoom={zoom}
            />

            {error?.message && (
                <LabelError type="error" fontSize="13px">
                    {error?.message}
                </LabelError>
            )}
        </>
    );
};

ControlledMap.defaultProps = {
    center: { lat: DEFAULT_LAT, lng: DEFAULT_LNG },
    disableDefaultUI: false,
    readOnly: false,
};

ControlledMap.propTypes = {
    center: PropTypes.object,
    disableDefaultUI: PropTypes.bool,
    error: PropTypes.object,
    heightMap: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.object,
    zoom: PropTypes.number,
};

export default ControlledMap;
