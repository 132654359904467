import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/* send message */
export const getSendModel = createSelector(getModel, (model) => model.send);

export const getSendData = createSelector(
    getSendModel,
    (model) => model.data || []
);

export const getSendErrors = createSelector(
    getSendModel,
    (model) => model.errors
);

export const getSendIsFetching = createSelector(
    getSendModel,
    (model) => model.isFetching
);
