import { createSlice } from '@reduxjs/toolkit';

import {
    DELETE_COMPANIES_CATALOGS,
    DELETE_COMPANIES_CATALOGS_FAILURE,
    DELETE_COMPANIES_CATALOGS_SUCCESS,
    FETCH_COMPANIES_CATALOGS,
    FETCH_COMPANIES_CATALOGS_FAILURE,
    FETCH_COMPANIES_CATALOGS_SUCCESS,
    INITIALIZE_FORM,
    INITIALIZE_FORM_TERMS,
    OPEN_MODAL,
    RESET,
    RESET_FORM,
    RESET_FORM_TERMS,
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
    SET_IS_NEW_RECORD,
    SET_TYPE,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    companiesCatalogs: { data: [], errors: [], isFetching: false },
    deleteItem: { errors: [], isDeleting: false },
    initialValues: {
        category: null,
        description: '',
        id: null,
        name: '',
        required: false,
        type: null,
    },
    initialValuesTermsForm: { content: '', id: null },
    isNewRecord: true,
    isOpenModal: false,
    saveItem: { errors: [], isSaving: false },
    type: {},
};

const configurationDocumentsSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [DELETE_COMPANIES_CATALOGS]: (state) => {
            state.deleteItem.isDeleting = true;
        },
        [DELETE_COMPANIES_CATALOGS_FAILURE]: (state, action) => {
            state.deleteItem = {
                ...state.deleteItem,
                isDeleting: false,
                errors: action.payload,
            };
        },
        [DELETE_COMPANIES_CATALOGS_SUCCESS]: (state) => {
            state.deleteItem.isDeleting = false;
        },
        [FETCH_COMPANIES_CATALOGS]: (state) => {
            state.companiesCatalogs.isFetching = true;
        },
        [FETCH_COMPANIES_CATALOGS_FAILURE]: (state, action) => {
            state.companiesCatalogs = {
                ...state.companiesCatalogs,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_COMPANIES_CATALOGS_SUCCESS]: (state, action) => {
            state.companiesCatalogs = {
                ...state.companiesCatalogs,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [INITIALIZE_FORM_TERMS]: (state, action) => {
            state.initialValuesTermsForm = {
                ...state.initialValuesTermsForm,
                ...action.payload,
            };
        },
        [OPEN_MODAL]: (state, action) => {
            state.isOpenModal = action.payload;
        },
        [RESET]: () => INITIAL_STATE,
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
            state.saveItem.errors = [];
        },
        [RESET_FORM_TERMS]: (state) => {
            state.initialValuesTermsForm = INITIAL_STATE.initialValuesTermsForm;
            state.saveItem.errors = [];
        },
        [SAVE_ITEM]: (state) => {
            state.saveItem.isSaving = true;
        },
        [SAVE_ITEM_FAILURE]: (state, action) => {
            state.saveItem = {
                ...state.saveItem,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_ITEM_SUCCESS]: (state) => {
            state.saveItem.isSaving = false;
        },
        [SET_IS_NEW_RECORD]: (state, action) => {
            state.isNewRecord = action.payload;
        },
        [SET_TYPE]: (state, action) => {
            state.type = action.payload;
        },
    },
});

export const configurationDocumentsActions =
    configurationDocumentsSlice.actions;

export default configurationDocumentsSlice.reducer;
