import { NAME } from './constants';

export const DELETE_COVERAGES = `${NAME}/DELETE_COVERAGES`;
export const DELETE_COVERAGES_FAILURE = `${NAME}/DELETE_COVERAGES_FAILURE`;
export const DELETE_COVERAGES_SUCCESS = `${NAME}/DELETE_COVERAGES_SUCCESS`;

export const FETCH_COVERAGES = `${NAME}/FETCH_COVERAGES`;
export const FETCH_COVERAGES_FAILURE = `${NAME}/FETCH_COVERAGES_FAILURE`;
export const FETCH_COVERAGES_SUCCESS = `${NAME}/FETCH_COVERAGES_SUCCESS`;

export const INITIALIZE_FORM_COVERAGE = `${NAME}/INITIALIZE_FORM_COVERAGE`;
export const INITIALIZE_FORM_COVERAGE_CONFIG = `${NAME}/INITIALIZE_FORM_COVERAGE_CONFIG`;

export const OPEN_COVERAGE_FORM_MODAL = `${NAME}/OPEN_COVERAGE_FORM_MODAL`;
export const OPEN_COVERAGE_CONFING_FORM_MODAL = `${NAME}/OPEN_COVERAGE_CONFING_FORM_MODAL`;

export const RESET_COVERAGE_FORM = `${NAME}/RESET_COVERAGE_FORM`;
export const RESET_COVERAGE_CONFIG_FORM = `${NAME}/RESET_COVERAGE_CONFIG_FORM`;

export const SAVE_COVERAGE = `${NAME}/SAVE_COVERAGE`;
export const SAVE_COVERAGE_FAILURE = `${NAME}/SAVE_COVERAGE_FAILURE`;
export const SAVE_COVERAGE_SUCCESS = `${NAME}/SAVE_COVERAGE_SUCCESS`;

export const SAVE_COVERAGE_CONFIG = `${NAME}/SAVE_COVERAGE_CONFIG`;
export const SAVE_COVERAGE_CONFIG_FAILURE = `${NAME}/SAVE_COVERAGE_CONFIG_FAILURE`;
export const SAVE_COVERAGE_CONFIG_SUCCESS = `${NAME}/SAVE_COVERAGE_CONFIG_SUCCESS`;

export const SET_IS_NEW_COVERAGE_RECORD = `${NAME}/SET_IS_NEW_COVERAGE_RECORD`;
export const SET_IS_NEW_COVERAGE_CONFIG_RECORD = `${NAME}/SET_IS_NEW_COVERAGE_CONFIG_RECORD`;
