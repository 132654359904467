import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button } from 'sunwise-ui';

import {
    ReactHookFormInput,
    ReactHookFormSelect,
} from 'common/components/form';
import TextVariant from 'common/components/TextVariant';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import {
    SMART_DOCUMENT_FORM_CREATE_MODE,
    SMART_DOCUMENT_FORM_DUPLICATE_MODE,
} from '../constants';
import { getDocumentTemplatesToSelect } from '../helpers';
import * as selectors from '../selectors';
import smartDocumentValidate from '../smartDocumentValidate';

const FormContainer = ({
    documentTemplates,
    fetchTemplates,
    handleClickDocumentItem,
    initialValues,
    initTemplate,
    isDuplicatingSmartDocument,
    isFetchingDocumentTemplates,
    isSavingSmartDocument,
    proposalId,
    resetTemplate,
    save,
    smartDocumentFormMode,
}) => {
    const { t } = useTranslation();
    const isCreateMode =
        smartDocumentFormMode === SMART_DOCUMENT_FORM_CREATE_MODE;
    const isDuplicateMode =
        smartDocumentFormMode === SMART_DOCUMENT_FORM_DUPLICATE_MODE;

    const { control, handleSubmit, reset } = useForm({
        context: isCreateMode,
        defaultValues: initialValues,
        resolver: yupResolver(smartDocumentValidate),
    });

    useEffect(() => {
        fetchTemplates();
    }, []);

    useEffect(() => reset(initialValues), [initialValues]);

    const isDisabled =
        isDuplicatingSmartDocument ||
        isFetchingDocumentTemplates ||
        isSavingSmartDocument;

    return (
        <form
            onSubmit={handleSubmit((values) =>
                save(
                    handleClickDocumentItem,
                    initTemplate,
                    proposalId,
                    resetTemplate,
                    smartDocumentFormMode,
                    values
                )
            )}
        >
            {isDuplicateMode && (
                <Box mb={2}>
                    <TextVariant
                        text={t(
                            'Remember that when you duplicate a document, the tags and changes made in this editor will be preserved.'
                        )}
                    />
                </Box>
            )}

            {!isCreateMode && (
                <ReactHookFormInput
                    control={control}
                    disabled={isDisabled}
                    label={`${t('Nombre')} *`}
                    maxLength="254"
                    name="name"
                />
            )}

            {isCreateMode && (
                <ReactHookFormSelect
                    control={control}
                    disabled={isDisabled}
                    label={`${t('Template')} *`}
                    name="template"
                    options={getDocumentTemplatesToSelect(documentTemplates)}
                />
            )}

            <Box textAlign="right">
                <Button disabled={isDisabled} type="submit">
                    {isDuplicatingSmartDocument || isSavingSmartDocument
                        ? t('Saving')
                        : t('Save')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    documentTemplates: selectors.getTemplatesData,
    initialValues: selectors.getSmartDocumentInitialValues,
    isDuplicatingSmartDocument: selectors.getIsDuplicatingSmartDocument,
    isFetchingDocumentTemplates: selectors.getIsFetchingTemplates,
    isSavingSmartDocument: selectors.getIsSavingSmartDocument,
});

const mapDispatchToProps = (dispatch) => ({
    fetchTemplates: () => dispatch(actions.fetchTemplates()),
    save: (
        handleClickDocumentItem,
        initTemplate,
        proposalId,
        resetTemplate,
        smartDocumentFormMode,
        values
    ) =>
        dispatch(
            actions.saveDocument(
                handleClickDocumentItem,
                initTemplate,
                proposalId,
                resetTemplate,
                smartDocumentFormMode,
                values
            )
        ),
});

FormContainer.propTypes = {
    documentTemplates: PropTypes.array,
    fetchTemplates: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    initialValues: PropTypes.object,
    initTemplate: PropTypes.func,
    isDuplicatingSmartDocument: PropTypes.bool,
    isFetchingDocumentTemplates: PropTypes.bool,
    isSavingSmartDocument: PropTypes.bool,
    proposalId: PropTypes.string,
    resetTemplate: PropTypes.func,
    save: PropTypes.func,
    smartDocumentFormMode: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
