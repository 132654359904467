import { getItems } from 'common/api/roles';

import {
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
} from '../actionTypes';
import { companyRolesActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(companyRolesActions[FETCH_ITEMS]());

    getItems()
        .then((response) =>
            dispatch(
                companyRolesActions[FETCH_ITEMS_SUCCESS](response.data.data)
            )
        )
        .catch((error) =>
            dispatch(
                companyRolesActions[FETCH_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
