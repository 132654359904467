import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, DataGrid, Grid } from 'sunwise-ui';

import { CustomPagination, InputSearch } from 'common/components';

import Columns from './Columns';
const DEBOUNCE_TIME = 1500;

const ListItems = ({
    canModify,
    filterData,
    filterItems,
    handleChangeSwitch,
    handleClickUpdate,
    isFetching,
    items,
    paginationData,
    session,
    setFilterData,
}) => {
    const { t } = useTranslation();
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    return (
        <>
            <Card>
                <Card.Body>
                    <Grid container alignItems="end" justifyContent="flex-end">
                        <Grid item xs={18}>
                            <InputSearch
                                onChange={handleOnChangeSearch}
                                placeholder={t('Search')}
                                value={filterData?.searchText}
                            />
                        </Grid>
                    </Grid>
                </Card.Body>
            </Card>

            <Card>
                <Card.Body>
                    <DataGrid
                        autoHeight
                        checkboxSelection={false}
                        columns={Columns({
                            canModify,
                            handleChangeSwitch,
                            handleClickUpdate,
                            session,
                        })}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                pageSize: filterData?.pageSize,
                            },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={rowCount}
                        rows={items}
                        sortingMode="server"
                        sx={{
                            '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
                                backgroundColor: 'rgba(255, 154, 0, 0.16)',
                            },
                            '& .MuiDataGrid-row--editing:hover': {
                                backgroundColor: 'rgba(255, 154, 0, 0.08)',
                            },
                            '& .MuiInputBase-formControl input': {
                                border: 0,
                                boxShadow: 'none',
                                outline: 'none',
                            },
                        }}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    canModify: PropTypes.bool,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleChangeSwitch: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    session: PropTypes.object,
    setFilterData: PropTypes.func,
};

export default ListItems;
