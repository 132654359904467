import { createSlice } from '@reduxjs/toolkit';

import {
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: { email: '', password: '' },
    session: { isLoading: false, errors: null },
    validPassword: false,
};

const loginSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [LOGIN_USER]: (state) => {
            state.session.isLoading = true;
        },
        [LOGIN_USER_FAILURE]: (state, action) => {
            state.session = {
                ...state.session,
                isLoading: false,
                errors: action.payload,
            };
        },
        [LOGIN_USER_SUCCESS]: (state) => {
            state.session.isLoading = false;
        },
    },
});

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;
