import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isEqual, isString } from 'lodash';
import PropTypes from 'prop-types';
import { Card, CustomPagination, DataGrid } from 'sunwise-ui';

import BulkOperationsCard from './BulkOperationsCard';
import ProductColumns from './ProductColumns';

const DEBOUNCE_TIME = 1500;

const ListPanelItems = ({
    brands,
    changeInput,
    fieldName,
    filterData,
    filterItems,
    isFetching,
    isFetchingBrands,
    items,
    paginationData,
    setFilterData,
    value,
}) => {
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [selectionModel, setSelectionModel] = useState([]);
    const [currentFilterData, setCurrentFilterData] = useState({});

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    useEffect(() => {
        if (value && isString(value)) setSelectionModel(value?.split(','));
    }, [value]);

    useEffect(() => {
        changeInput(fieldName, selectionModel);
    }, [selectionModel]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnChangeBrand = (value) => {
        const filter = { ...filterData, page: 0, brandName: value };
        setFilterData(filter);
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    return (
        <>
            <BulkOperationsCard
                brands={brands}
                filterData={filterData}
                handleOnChangeBrand={handleOnChangeBrand}
                handleOnChangeSearch={handleOnChangeSearch}
                isFetchingBrands={isFetchingBrands}
            />

            <Card>
                <Card.Body>
                    <DataGrid
                        autoHeight
                        checkboxSelection={true}
                        columns={ProductColumns()}
                        components={{
                            LoadingOverlay: LinearProgress,
                            Pagination: CustomPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                rowsPerPageOptions: [25, 50, 75, 100],
                            },
                        }}
                        disableColumnMenu
                        disableSelectionOnClick
                        editMode="row"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowHeight={() => 'auto'}
                        initialState={{
                            pagination: {
                                pageSize: filterData?.pageSize,
                            },
                        }}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onSelectionModelChange={(newModel) =>
                            setSelectionModel(newModel)
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={paginationData?.current_page - 1 || 0}
                        pageSize={filterData.pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={rowCount}
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="server"
                        sx={{
                            '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
                                backgroundColor: 'rgba(255, 154, 0, 0.16)',
                            },
                            '& .MuiDataGrid-row--editing:hover': {
                                backgroundColor: 'rgba(255, 154, 0, 0.08)',
                            },
                            '& .MuiInputBase-formControl input': {
                                border: 0,
                                boxShadow: 'none',
                                outline: 'none',
                            },
                        }}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListPanelItems.propTypes = {
    brands: PropTypes.array,
    changeInput: PropTypes.func,
    fieldName: PropTypes.string,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingBrands: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    value: PropTypes.string,
};

export default ListPanelItems;
