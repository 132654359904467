import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { parseDate } from 'common/utils/dates';

import { NAME } from './constants';

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

/* send message */
export const getSendModel = createSelector(getModel, (model) => model.send);

export const getSendData = createSelector(
    getSendModel,
    (model) => model.data || []
);

export const getSendErrors = createSelector(
    getSendModel,
    (model) => model.errors
);

export const getSendIsFetching = createSelector(
    getSendModel,
    (model) => model.isFetching
);

/* fetch messages */
export const getMessagesModel = createSelector(
    getModel,
    (model) => model.messages
);

export const getMessagesData = createSelector(
    getMessagesModel,
    (model) => model.data || {}
);

export const getMessages = createSelector(getMessagesModel, (model) => {
    const sorted = model.data.messages
        .map((item) => ({
            created_at: item.created_at,
            id: item.id,
            image: item.logo,
            is_comment: item.is_comment,
            message: item.message,
            name: item.name,
            role: item.sent_from,
            updated_at: item.updated_at,
            username: item.username,
            maxCharacters: item?.maxCharacters || 250,
        }))
        .sort(
            (a, b) =>
                parseDate(a.created_at, 'dd/MM/yyyy HH:mm:ss') -
                parseDate(b.created_at, 'dd/MM/yyyy HH:mm:ss')
        );
    return sorted;
});

export const getMessagesErrros = createSelector(
    getMessagesModel,
    (model) => model.errors
);

export const getMessagesIsFetching = createSelector(
    getMessagesModel,
    (model) => model.isFetching
);

export const getChatId = createSelector(
    getInitialValues,
    (model) => model.chatId
);

export const getSocketConnected = createSelector(
    getModel,
    (model) => model.socketConnected
);

export const getSocketError = createSelector(
    getModel,
    (model) => model.socketError
);
