import { push } from 'connected-react-router';

import { login } from 'common/api/auth';
import { initSessionActions } from 'common/utils/session';

import setCurrentValues from '../../profile/actions/setCurrentValues';
import {
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
} from '../actionTypes';
import { loginActions } from '../reducer';

export default (data) => (dispatch) => {
    dispatch(loginActions[LOGIN_USER]());

    login(data)
        .then((response) => {
            const { groups, token, ...rest } = response.data;

            localStorage.setItem('token', token);
            localStorage.setItem('session', JSON.stringify(rest));
            localStorage.setItem('groups', JSON.stringify(groups));

            dispatch(loginActions[LOGIN_USER_SUCCESS]());
            dispatch(setCurrentValues(response.data));
            initSessionActions();
            dispatch(push('/credits'));
        })
        .catch((error) =>
            dispatch(
                loginActions[LOGIN_USER_FAILURE](error?.response?.data?.errors)
            )
        );
};
