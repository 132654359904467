import { filterTemplates } from 'common/api/templates';

import {
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { templatesActions } from '../reducer';

export default ({
        orderBy,
        page = 0,
        pageSize,
        searchText,
        sortBy,
        status,
    } = {}) =>
    (dispatch) => {
        dispatch(templatesActions[FETCH_TEMPLATES]());

        filterTemplates({
            orderBy,
            page: page + 1,
            perPage: pageSize,
            searchText,
            sortBy,
            status,
        })
            .then((response) =>
                dispatch(
                    templatesActions[FETCH_TEMPLATES_SUCCESS](response.data)
                )
            )
            .catch((error) =>
                dispatch(
                    templatesActions[FETCH_TEMPLATES_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
