import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Box, Grid, Card } from 'sunwise-ui';

import Modal from 'common/components/Modal';
import { TERMS_AND_CONDITIONS_TYPE } from 'common/constants';
import { PERMISSION_LIST } from 'common/constants/permissions';
import withPermissions from 'common/hocs/withPermissions';
import { getFinancierType } from 'common/utils/session';

import * as actions from './actions';
import {
    CardList,
    CardListDetail,
    CompanyCatalogForm,
    TermsAndConditionsForm,
} from './components';
import * as selectors from './selectors';

const Container = ({
    companiesCatalogs,
    getPermissionsByCode,
    handleToggleModal,
    isFetchingcompaniesCatalogs,
    isNewRecord,
    isOpenModal,
    reset,
    resetType,
    type,
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('alliance');

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    const financierType = getFinancierType();

    const { canView: canViewAllianceDocs, canModify: canModifyAllianceDocs } =
        getPermissionsByCode(PERMISSION_LIST.ALLIANCE_DOCUMENTS_PERMISSION);
    const {
        canView: canViewApplicationDocs,
        canModify: canModifyApplicationDocs,
    } = getPermissionsByCode(PERMISSION_LIST.APPLICATION_DOCUMENTS_PERMISSION);

    return (
        <Box>
            <Grid container>
                <Grid item md={9}>
                    <Card>
                        <CardList
                            canViewAllianceDocs={canViewAllianceDocs}
                            canViewApplicationDocs={canViewApplicationDocs}
                            financierType={financierType}
                            resetType={resetType}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                        />
                    </Card>
                </Grid>

                <Grid item md={9}>
                    <CardListDetail
                        canModify={
                            selectedTab === 'alliance'
                                ? canModifyAllianceDocs
                                : canModifyApplicationDocs
                        }
                        companiesCatalogs={companiesCatalogs}
                        isFetching={isFetchingcompaniesCatalogs}
                        type={type}
                    />
                </Grid>
            </Grid>

            <Modal
                keyboard={false}
                onHide={() => handleToggleModal(false)}
                show={isOpenModal}
                size={type?.type === TERMS_AND_CONDITIONS_TYPE ? 'lg' : 'md'}
                title={
                    type?.type === TERMS_AND_CONDITIONS_TYPE
                        ? t('Terms and conditions')
                        : isNewRecord
                        ? t('Create document')
                        : t('Update document')
                }
            >
                {type?.type === TERMS_AND_CONDITIONS_TYPE ? (
                    <TermsAndConditionsForm />
                ) : (
                    <CompanyCatalogForm />
                )}
            </Modal>
        </Box>
    );
};

const mapStateToProps = createStructuredSelector({
    companiesCatalogs: selectors.getDataCompaniesCatalogs,
    isFetchingcompaniesCatalogs: selectors.getIsFetchingCompaniesCatalogs,
    isNewRecord: selectors.getIsNewRecord,
    isOpenModal: selectors.getIsOpenModal,
    type: selectors.getType,
});

const mapDispatchToProps = (dispatch) => ({
    handleToggleModal: (value) => dispatch(actions.toggleModal(value)),
    reset: () => dispatch(actions.reset()),
    resetType: () => dispatch(actions.resetType()),
});

Container.propTypes = {
    companiesCatalogs: PropTypes.array,
    getPermissionsByCode: PropTypes.func,
    handleToggleModal: PropTypes.func,
    isFetchingcompaniesCatalogs: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    reset: PropTypes.func,
    resetType: PropTypes.func,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions([
        PERMISSION_LIST.ALLIANCE_DOCUMENTS_PERMISSION,
        PERMISSION_LIST.APPLICATION_DOCUMENTS_PERMISSION,
    ])
)(Container);
