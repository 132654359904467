import isEmpty from 'lodash/isEmpty';

import { sendMessage as sendAllianceMessage } from 'common/api/chatAlliance';
import { sendMessage as sendCreditMessage } from 'common/api/chatCredit';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SEND_MESSAGE,
    SEND_MESSAGE_FAILURE,
    SEND_MESSAGE_SUCCESS,
} from '../actionTypes';
import { ALLIANCE_DOCUMENT_TYPE } from '../constants';
import { chatActions } from '../reducer';

import addMessage from './addMessage';
import initializeForm from './initializeForm';

export default (values, callback) => (dispatch) => {
    const { is_comment, message, note_message } = values;

    if (is_comment === 1 && isEmpty(note_message)) return false;
    if (is_comment !== 1 && isEmpty(message)) return false;

    let newMessage = is_comment === 1 ? note_message : message;
    let other = is_comment === 1 ? message : note_message;

    const newValues = {
        is_comment: values.is_comment,
        message: newMessage.trim(),
        message_from: values.user.id,
    };

    const action =
        values.type === ALLIANCE_DOCUMENT_TYPE
            ? sendAllianceMessage
            : sendCreditMessage;

    dispatch(chatActions[SEND_MESSAGE]());

    action(values.chatId, newValues)
        .then((response) => {
            dispatch(chatActions[SEND_MESSAGE_SUCCESS](response.data));

            if (is_comment === 1) {
                dispatch(
                    initializeForm({
                        message: other,
                        note_message: '',
                        timestamp: new Date(),
                    })
                );
            } else {
                dispatch(
                    initializeForm({
                        message: '',
                        note_message: other,
                        timestamp: new Date(),
                    })
                );
            }

            const message = {
                created_at: response.data.created_at,
                id: response.data.id,
                is_comment: response.data.is_comment,
                logo: response.data.logo,
                message: response.data.message,
                name: response.data.name,
                sent_from: response.data.sent_from,
                updated_at: response.data.updated_at,
                username: response.data.username,
            };

            dispatch(addMessage(message));

            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                chatActions[SEND_MESSAGE_FAILURE](error?.response?.data?.errors)
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
