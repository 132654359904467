import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import ToastNotification from 'common/components/ToastNotification';
import { getIsGuest } from 'common/utils/session';

import { onMessageListener } from '../../firebaseInit';
import * as notificationsActions from '../../modules/notifications/actions';

const NotificationsListener = ({ fetchNotifications }) => {
    const isGuest = getIsGuest();
    const [notifications, setNotifications] = useState([]);

    onMessageListener()
        .then((payload) => {
            if (isGuest) return;
            setNotifications((prev) => prev.concat(payload));
            toast(
                <ToastNotification
                    title={payload.notification.title}
                    body={payload.notification.body}
                    type={payload.data?.type || 'info'}
                />
            );
        })
        .catch();

    useEffect(() => {
        if (notifications.length === 0) return;
        fetchNotifications();
    }, [notifications]);

    return null;
};

NotificationsListener.propTypes = {
    fetchNotifications: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
    fetchNotifications: () => {
        dispatch(notificationsActions.fetchNavNotifications());
    },
});

export default connect(null, mapDispatchToProps)(NotificationsListener);
