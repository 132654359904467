import { styled } from '@mui/material/styles';
import { Box } from 'sunwise-ui';

import { custom1 } from 'common/utils/customScrolls';

export default styled(Box)(
    ({ theme }) => `
    background:  ${
        theme.palette.mode === 'dark' ? '#000' : 'rgb(248, 252, 255)'
    };
    overflow-y: scroll;
    padding-top: 24px;
    position: relative;
    width: 100%;
    
    ${custom1}

    .apexcharts-theme-light {
        color: black;
    }
`
);
