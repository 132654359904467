import connectSocket from '../connectSocket';
import disconnectSocket from '../disconnectSocket';
import sendMessageSocket from '../sendMessageSocket';

const ENTITY = '/sunpay/chat/chat_credit/';

export const connect = (chatId) => connectSocket(`${ENTITY}${chatId}`);
export const disconnect = () => disconnectSocket();
export const sendMessage = (
    chatId,
    toUserId,
    message,
    note_message,
    token,
    userId,
    isComment
) => {
    let newMessage = isComment === 1 ? note_message : message;
    let other = isComment === 1 ? message : note_message;
    return sendMessageSocket({
        type: 'send_message',
        message: {
            data: {
                id: chatId,
                is_comment: isComment,
                message: newMessage,
                other,
                message_from: toUserId,
                model: 'chats/credit/',
            },
            device: '2',
            token,
            type: '0',
            user_id: userId,
            'user-agent': navigator.userAgent,
        },
    });
};
