import React from 'react';

import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from 'sunwise-ui';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
`;

const ItemContainer = styled(Box)`
    align-items: center;
    display: flex;
    gap: 8px;
    padding: 4px;
`;

const DotColor = styled(Box)`
    background-color: ${({ color }) => color};
    border-radius: 50%;
    height: 12px;
    width: 12px;
`;

const InfoList = () => {
    const { t } = useTranslation();
    return (
        <Container>
            <ItemContainer>
                <Typography fontSize="small" fontWeight="bold" variant="body2">
                    {t('Days elapsed (since starting the request)')}
                </Typography>
            </ItemContainer>
            <ItemContainer>
                <Box>
                    <DotColor color="#00ff00" />
                </Box>
                <Typography fontSize="small" variant="body2">
                    0-9 {t('day', { count: 2 })}
                </Typography>
            </ItemContainer>
            <ItemContainer>
                <Box>
                    <DotColor color="#ffa500" />
                </Box>
                <Typography fontSize="small" variant="body2">
                    10-14 {t('day', { count: 2 })}
                </Typography>
            </ItemContainer>
            <ItemContainer>
                <Box>
                    <DotColor color="#ff0000" />
                </Box>
                <Typography fontSize="small" variant="body2">
                    {t('More than 15 days')}
                </Typography>
            </ItemContainer>
            <ItemContainer>
                <Box>
                    <DotColor color="#ccc" />
                </Box>
                <Typography fontSize="small" variant="body2">
                    {t('Ended process')}
                </Typography>
            </ItemContainer>
        </Container>
    );
};

export default InfoList;
