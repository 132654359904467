import { createSlice } from '@reduxjs/toolkit';

import {
    BULK_UPDATE_ITEMS,
    BULK_UPDATE_ITEMS_FAILURE,
    BULK_UPDATE_ITEMS_SUCCESS,
    DUPLICATE_PRODUCT,
    DUPLICATE_PRODUCT_FAILURE,
    DUPLICATE_PRODUCT_SUCCESS,
    FETCH_ACCESSORIES,
    FETCH_ACCESSORIES_FAILURE,
    FETCH_ACCESSORIES_SUCCESS,
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_FAILURE,
    FETCH_DOCUMENTS_SUCCESS,
    FETCH_INVERTER_BRANDS,
    FETCH_INVERTER_BRANDS_FAILURE,
    FETCH_INVERTER_BRANDS_SUCCESS,
    FETCH_INVERTERS,
    FETCH_INVERTERS_FAILURE,
    FETCH_INVERTERS_SUCCESS,
    FETCH_PANEL_BRANDS,
    FETCH_PANEL_BRANDS_FAILURE,
    FETCH_PANEL_BRANDS_SUCCESS,
    FETCH_PANELS,
    FETCH_PANELS_FAILURE,
    FETCH_PANELS_SUCCESS,
    FETCH_PRODUCTS,
    FETCH_PRODUCTS_FAILURE,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_TEMPLATE_CATALOGS,
    FETCH_TEMPLATE_CATALOGS_FAILURE,
    FETCH_TEMPLATE_CATALOGS_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    accessories: {
        data: { data: [], pagination: {} },
        errors: [],
        isFetching: true,
    },
    bulkUpdateItems: { errors: [], isFetching: false },
    documents: { data: [], errors: [], isFetching: true },
    duplicateProduct: { errors: [], isFetching: true },
    fetchTemplateCatalogs: { data: [], errors: [], isFetching: true },
    inverterBrands: { data: [], errors: [], isFetching: true },
    inverters: {
        data: { data: [], pagination: {} },
        errors: [],
        isFetching: true,
    },
    panelBrands: { data: [], errors: [], isFetching: true },
    panels: {
        data: { data: [], pagination: {} },
        errors: [],
        isFetching: true,
    },
    products: {
        data: { data: [], paginaton: {} },
        errors: [],
        isFetching: false,
    },
};

const financialSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [BULK_UPDATE_ITEMS]: (state) => {
            state.bulkUpdateItems.isFetching = true;
        },
        [BULK_UPDATE_ITEMS_FAILURE]: (state, action) => {
            state.bulkUpdateItems = {
                ...state.bulkUpdateItems,
                isFetching: false,
                errors: action.payload,
            };
        },
        [BULK_UPDATE_ITEMS_SUCCESS]: (state) => {
            state.bulkUpdateItems.isFetching = false;
        },
        [DUPLICATE_PRODUCT]: (state) => {
            state.duplicateProduct.isFetching = true;
        },
        [DUPLICATE_PRODUCT_FAILURE]: (state, action) => {
            state.duplicateProduct = {
                ...state.duplicateProduct,
                isFetching: false,
                errors: action.payload,
            };
        },
        [DUPLICATE_PRODUCT_SUCCESS]: (state) => {
            state.duplicateProduct.isFetching = false;
        },
        [FETCH_ACCESSORIES]: (state) => {
            state.accessories.isFetching = true;
        },
        [FETCH_ACCESSORIES_FAILURE]: (state, action) => {
            state.accessories = {
                ...state.accessories,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_ACCESSORIES_SUCCESS]: (state, action) => {
            state.accessories = {
                ...state.accessories,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOCUMENTS]: (state) => {
            state.documents.isFetching = true;
        },
        [FETCH_DOCUMENTS_FAILURE]: (state, action) => {
            state.documents = {
                ...state.documents,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_DOCUMENTS_SUCCESS]: (state, action) => {
            state.documents = {
                ...state.documents,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTER_BRANDS]: (state) => {
            state.inverterBrands.isFetching = true;
        },
        [FETCH_INVERTER_BRANDS_FAILURE]: (state, action) => {
            state.inverterBrands = {
                ...state.inverterBrands,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTER_BRANDS_SUCCESS]: (state, action) => {
            state.inverterBrands = {
                ...state.inverterBrands,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTERS]: (state) => {
            state.inverters.isFetching = true;
        },
        [FETCH_INVERTERS_FAILURE]: (state, action) => {
            state.inverters = {
                ...state.inverters,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_INVERTERS_SUCCESS]: (state, action) => {
            state.inverters = {
                ...state.inverters,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANEL_BRANDS]: (state) => {
            state.panelBrands.isFetching = true;
        },
        [FETCH_PANEL_BRANDS_FAILURE]: (state, action) => {
            state.panelBrands = {
                ...state.panelBrands,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANEL_BRANDS_SUCCESS]: (state, action) => {
            state.panelBrands = {
                ...state.panelBrands,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANELS]: (state) => {
            state.panels.isFetching = true;
        },
        [FETCH_PANELS_FAILURE]: (state, action) => {
            state.panels = {
                ...state.panels,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PANELS_SUCCESS]: (state, action) => {
            state.panels = {
                ...state.panels,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PRODUCTS]: (state) => {
            state.products.isFetching = true;
        },
        [FETCH_PRODUCTS_FAILURE]: (state, action) => {
            state.products = {
                ...state.products,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_PRODUCTS_SUCCESS]: (state, action) => {
            state.products = {
                ...state.products,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_CATALOGS]: (state) => {
            state.fetchTemplateCatalogs.isFetching = true;
        },
        [FETCH_TEMPLATE_CATALOGS_FAILURE]: (state, action) => {
            state.fetchTemplateCatalogs = {
                ...state.fetchTemplateCatalogs,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TEMPLATE_CATALOGS_SUCCESS]: (state, action) => {
            state.fetchTemplateCatalogs = {
                ...state.fetchTemplateCatalogs,
                data: action.payload,
                isFetching: false,
            };
        },
    },
});

export const financialActions = financialSlice.actions;

export default financialSlice.reducer;
