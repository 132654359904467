import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH_LANGUAGES,
    FETCH_LANGUAGES_FAILURE,
    FETCH_LANGUAGES_SUCCESS,
    FETCH_TIMEZONES,
    FETCH_TIMEZONES_FAILURE,
    FETCH_TIMEZONES_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    UPDATE_CONFIGURATION,
    UPDATE_CONFIGURATION_FAILURE,
    UPDATE_CONFIGURATION_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: { auto_timezone: false, language: '', timezone: '' },
    languages: { data: [], errors: [], isFetching: false },
    save: { data: {}, errors: [], isSaving: false },
    timezones: { data: [], errors: [], isFetching: false },
};

const profileConfigurationSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH_LANGUAGES]: (state) => {
            state.languages = {
                ...state.languages,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_LANGUAGES_FAILURE]: (state, action) => {
            state.languages = {
                ...state.languages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_LANGUAGES_SUCCESS]: (state, action) => {
            state.languages = {
                ...state.languages,
                data: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TIMEZONES]: (state) => {
            state.timezones = {
                ...state.timezones,
                errors: [],
                isFetching: true,
            };
        },
        [FETCH_TIMEZONES_FAILURE]: (state, action) => {
            state.timezones = {
                ...state.timezones,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_TIMEZONES_SUCCESS]: (state, action) => {
            state.timezones = {
                ...state.timezones,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SAVE]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
        [UPDATE_CONFIGURATION]: (state) => {
            state.save = { ...state.save, errors: [], isSaving: true };
        },
        [UPDATE_CONFIGURATION_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [UPDATE_CONFIGURATION_SUCCESS]: (state, action) => {
            state.save = {
                ...state.save,
                data: action.payload,
                isSaving: false,
            };
        },
    },
});

export const profileConfigurationActions = profileConfigurationSlice.actions;

export default profileConfigurationSlice.reducer;
