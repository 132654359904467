import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

export default (values = {}) => {
    const errors = {};
    if (isEmpty(values.name)) errors.name = i18next.t('Name cannot be empty');
    if (
        values.administratives_expenses &&
        values.administratives_expenses.unit === 0 &&
        (values.administratives_expenses.value < 0 ||
            values.administratives_expenses.value > 100)
    )
        errors.administratives_expenses = {
            value: i18next.t('The value must be between {{min}} and {{max}}', {
                max: 100,
                min: 0,
            }),
        };
    if (
        values.retention &&
        values.retention.unit === 0 &&
        (values.retention.value < 0 || values.retention.value > 100)
    )
        errors.retention = {
            value: i18next.t('The value must be between {{min}} and {{max}}', {
                max: 100,
                min: 0,
            }),
        };
    return errors;
};
