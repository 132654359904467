import { styled } from '@mui/material/styles';

export default styled('div')`
    background: #fff;
    line-break: auto;
    max-width: 100%;
    padding: 0.375rem 0.75rem;
    white-space: normal;

    &.active,
    &:active,
    &:hover {
        background-color: rgba(239, 241, 251, 0.41);
        color: #848bab;
    }
`;
