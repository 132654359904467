import { markAllAsRead } from 'common/api/notifications';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    MARK_ALL_NOTIFICATIONS_AS_READ,
    MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE,
    MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
} from '../actionTypes';
import { notificationsActions } from '../reducer';

import fetchNavNotifications from './fetchNavNotifications';
import fetchNotifications from './fetchNotifications';

export default (filterData) => (dispatch) => {
    dispatch(notificationsActions[MARK_ALL_NOTIFICATIONS_AS_READ]());

    markAllAsRead()
        .then((response) => {
            dispatch(
                notificationsActions[MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS](
                    response.data
                )
            );
            dispatch(fetchNavNotifications());
            dispatch(fetchNotifications(filterData));
        })
        .catch((error) => {
            dispatch(
                notificationsActions[MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
