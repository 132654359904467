import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Box, Typography } from 'sunwise-ui';

const NameFormatter = ({ row }) => (
    <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontWeight="bold" variant="body2">
            {get(row, 'first_name', '')} {get(row, 'last_name', '')}{' '}
            {get(row, 'second_surname', '')}
        </Typography>

        <Typography variant="caption">{get(row, 'email', '- - -')}</Typography>
    </Box>
);

NameFormatter.propTypes = { row: PropTypes.object };

export default NameFormatter;
