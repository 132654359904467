import React from 'react';

import { Typography } from 'sunwise-ui';

import { numberFormat } from 'common/utils/helpers';

const CostFormatter = (cell, row) => (
    <Typography variant="caption">
        {numberFormat(cell || 0, {
            currency: row.actual_currency === 1 ? 'USD' : 'MXN',
            locale: row.actual_currency === 1 ? 'en-US' : 'es-MX',
            style: 'currency',
        })}
    </Typography>
);

export default CostFormatter;
