import { getCompany } from 'common/api/companies';

import {
    FETCH_COMPANY,
    FETCH_COMPANY_FAILURE,
    FETCH_COMPANY_SUCCESS,
} from '../actionTypes';
import { companyUsersActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(companyUsersActions[FETCH_COMPANY]());

    getCompany()
        .then((response) => {
            dispatch(companyUsersActions[FETCH_COMPANY_SUCCESS](response.data));
        })
        .catch((error) =>
            dispatch(
                companyUsersActions[FETCH_COMPANY_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
