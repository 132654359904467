import React from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Grid } from 'sunwise-ui';

import { AutocompleteField, InputSearch } from 'common/components';

import { getBrandOptions } from '../../helpers';

const BulkOperationsCard = ({
    brands,
    filterData,
    handleOnChangeBrand,
    handleOnChangeSearch,
    isFetchingBrands,
}) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Body>
                <Grid alignItems="end" container justifyContent="flex-end">
                    <Grid item xs={18} md={12} lg={14}>
                        <InputSearch
                            onChange={handleOnChangeSearch}
                            placeholder={t('Search')}
                            value={filterData?.searchText}
                        />
                    </Grid>

                    <Grid item xs={18} md={6} lg={4}>
                        <AutocompleteField
                            disabled={isFetchingBrands}
                            label={
                                isEmpty(filterData?.brand)
                                    ? t('All brands')
                                    : t('Brand')
                            }
                            onChange={handleOnChangeBrand}
                            options={getBrandOptions(brands)}
                            value={filterData?.brand}
                        />
                    </Grid>
                </Grid>
            </Card.Body>
        </Card>
    );
};

BulkOperationsCard.propTypes = {
    brands: PropTypes.array,
    filterData: PropTypes.object,
    handleOnChangeBrand: PropTypes.func,
    handleOnChangeSearch: PropTypes.func,
    isFetchingBrands: PropTypes.bool,
};

export default BulkOperationsCard;
