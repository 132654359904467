import { cancelCreditApplicaton } from 'common/api/creditApplications';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    CANCEL_CREDIT,
    CANCEL_CREDIT_FAILURE,
    CANCEL_CREDIT_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (id, callback) => (dispatch) => {
    const newValues = {
        status: 6,
    };

    dispatch(creditFileActions[CANCEL_CREDIT]());

    cancelCreditApplicaton(id, newValues)
        .then(() => {
            dispatch(creditFileActions[CANCEL_CREDIT_SUCCESS]());
            if (callback) callback();
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch(
                creditFileActions[CANCEL_CREDIT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
