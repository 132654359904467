import { arrayMoveMutable } from 'array-move';

import { updateOrderItems } from 'common/api/documentCatalog';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import { FETCH_COMPANIES_CATALOGS_SUCCESS } from '../actionTypes';
import { configurationDocumentsActions } from '../reducer';
import * as selectors from '../selectors';

import fetchCatalogs from './fetchCatalogs';

export default (oldIndex, newIndex) => (dispatch, getState) => {
    const state = getState();
    const companiesCatalogs = selectors.getCompaniesCatalogs(state);
    const type = selectors.getType(state);
    const items = arrayMoveMutable(companiesCatalogs.data, oldIndex, newIndex);

    dispatch(
        configurationDocumentsActions[FETCH_COMPANIES_CATALOGS_SUCCESS](items)
    );

    let catalogs = [];

    items.forEach((item, index) => {
        catalogs.push({
            id: item.id,
            order: index + 1,
        });
    });

    const values = {
        items: JSON.stringify(catalogs),
    };

    updateOrderItems(values)
        .then(() => {
            showToast();
            dispatch(fetchCatalogs(type));
        })
        .catch((error) => showReponseErrorsAsAlert(dispatch, error?.response));
};
