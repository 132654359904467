import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import ListItems from './ListItems';

const Panels = ({
    changeInput,
    fetchPanelBrands,
    fetchPanels,
    formValues,
    initialValues,
    isFetching,
    isFetchingPanelBrands,
    paginationData,
    panelBrands,
    panels,
}) => {
    const [filterData, setFilterData] = useState({
        brandName: '',
        financierProductId: formValues?.id,
        page: 0,
        pageSize: 25,
        searchText: '',
    });

    useEffect(() => {
        fetchPanelBrands();
        fetchPanels(filterData);
    }, []);

    return (
        <ListItems
            brands={panelBrands}
            changeInput={changeInput}
            fieldName="panels_list"
            filterData={filterData}
            filterItems={fetchPanels}
            isFetching={isFetching}
            isFetchingBrands={isFetchingPanelBrands}
            items={panels}
            paginationData={paginationData}
            setFilterData={setFilterData}
            value={initialValues?.panels_list}
        />
    );
};

Panels.propTypes = {
    changeInput: PropTypes.func,
    fetchPanelBrands: PropTypes.func,
    fetchPanels: PropTypes.func,
    formValues: PropTypes.object,
    initialValues: PropTypes.object,
    isFetching: PropTypes.bool,
    isFetchingPanelBrands: PropTypes.bool,
    paginationData: PropTypes.object,
    panelBrands: PropTypes.array,
    panels: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getPanelsIsFetching,
    isFetchingPanelBrands: selectors.getIsFetchingPanelBrands,
    paginationData: selectors.getPanelsPagination,
    panelBrands: selectors.getPanelBrandsData,
    panels: selectors.getPanelsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPanelBrands: () => dispatch(actions.fetchPanelBrands()),
    fetchPanels: (filterData) => dispatch(actions.fetchPanels(filterData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Panels);
