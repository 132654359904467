import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider } from 'sunwise-ui';

import { ReduxFieldAutocomplete } from 'common/components/form/redux';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';

const SelectUserForm = ({ callback, creditId, handleSubmit, save, users }) => {
    const { t } = useTranslation();

    const handleOnClickSave = (values) =>
        save({ ...values, creditId }, callback);

    const usersToSelect = users.map((item) => ({
        label: `${item.first_name} ${item.last_name}`,
        value: item.id,
    }));

    usersToSelect.unshift({
        disabled: true,
        label: t('Select a user'),
        value: '',
    });

    return (
        <form>
            <Field
                component={ReduxFieldAutocomplete}
                label=""
                name="user"
                options={usersToSelect}
            />

            <Divider sx={{ mb: 2, mx: -2 }} />

            <Box sx={{ textAlign: 'right' }}>
                <Button onClick={handleSubmit(handleOnClickSave)}>
                    {t('Save')}
                </Button>
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValuesAssignUserForm,
});

const mapDispatchToProps = (dispatch) => ({
    save: (values, callback) =>
        dispatch(actions.saveAssignedUser(values, callback)),
});

SelectUserForm.propTypes = {
    callback: PropTypes.func,
    creditId: PropTypes.number,
    handleSubmit: PropTypes.func,
    save: PropTypes.func,
    users: PropTypes.array,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/card-assign-user-form`,
})(SelectUserForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
