import { getCompany } from 'common/api/companies';

import {
    FETCH_COMPANY,
    FETCH_COMPANY_SUCCESS,
    FETCH_COMPANY_FAILURE,
} from '../actionTypes';
import { companyGeneralActions } from '../reducer';

import initializeForm from './initializeForm';
import resetForm from './resetForm';

export default () => (dispatch) => {
    dispatch(companyGeneralActions[FETCH_COMPANY]());
    dispatch(resetForm());
    getCompany()
        .then((response) => {
            dispatch(
                companyGeneralActions[FETCH_COMPANY_SUCCESS](response.data)
            );
            const values = response.data;
            if (response.data.image) values.image = response.data.image;
            dispatch(initializeForm(values));
        })
        .catch((error) =>
            dispatch(
                companyGeneralActions[FETCH_COMPANY_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
