import React, { useCallback, useState } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { styled } from '@mui/material/styles';
import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTemplatePages } from 'sunwise-template-core';
import { IconButton, Typography } from 'sunwise-ui';

import RoundedIcon from 'common/components/RoundedIcon';

import * as actions from '../../actions';
import { SIDE_WIDTH } from '../../constants';

import AsideCol from './AsideCol';

const Container = styled('div')(
    ({ theme }) => `
        background: ${theme.palette.mode === 'dark' ? '#3F444C' : '#011e2e'};
        display: flex;
        position: relative;

        &:hover .resize-button {
            opacity: 1;
        }
`
);

const ShowContainer = styled('div')`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    transition: width 0.2s ease-in, background-color 0.2s ease-in;
    width: 0;

    &:hover {
        background-color: #012233;
    }

    &.hide-mode {
        transition-delay: 0.25s;
        width: 26px;
    }
`;

const StyledIcon = styled('i')`
    color: #fff;
    font-size: 20px;
`;

const AsideColContainer = styled('div')`
    max-width: ${SIDE_WIDTH}px;
    overflow: hidden;
    position: relative;
    transition: max-width 0.2s ease-out;
    width: ${SIDE_WIDTH}px;

    &.hide {
        max-width: 0;
    }
`;

const ResizeButton = styled(IconButton)(
    ({ theme }) => `
    background-color: #fff;
    color: ${theme.palette.primary.main};
    cursor: pointer;
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    opacity: 0;
    position: absolute;
    right: -10px;
    text-align: center;
    top: 30px;
    transition: background-color 100ms, color 100ms, visibility 0s linear 0.5s, opacity 0.2s;
    visibility: visible;
    width: 24px;
    z-index: 55;

    i {
        margin-right: 1px;
    }

    &:hover {
        background-color: #ff9a00;
        color: #fff;
    }

    &.hide {
        visibility: hidden;
    }
`
);

const AddPageButton = styled('div')`
    align-items: center;
    background-color: #ff9a00;
    border-radius: 0;
    border: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0) !important;
    cursor: pointer;
    display: flex;
    height: 41px;
    justify-content: center;
    outline: 0;
    width: ${SIDE_WIDTH}px;
`;

const AddPageButtonText = styled(Typography)`
    color: #eff1fb;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.06px;
    line-height: 15px;
    margin-left: 6px;
    min-height: 15px;
`;

const LeftSideMenu = ({
    canUpdate,
    deletePagesInTemplate,
    duplicatePagesInTemplate,
    key = '',
    orderPagesInTemplate,
    prepareDelete,
    selectPage,
    selectedPageId,
    showNewPageModal,
    templatePages,
}) => {
    const { t } = useTranslation();
    const [isShowing, setIsShowing] = useState(
        isNil(localStorage.getItem(key + ':leftSideShowed')) ||
            localStorage.getItem(key + ':leftSideShowed') === 'true'
    );

    const handlePrepareDelete = useCallback(
        (id, isPage) => {
            prepareDelete(isPage, () => deletePagesInTemplate(id));
        },
        [deletePagesInTemplate]
    );
    const handleSetIsShowing = (value) => {
        localStorage.setItem(key + ':leftSideShowed', value);
        setIsShowing(value);
    };

    return (
        <Container>
            {isShowing && (
                <ResizeButton
                    className={`resize-button ${!isShowing && 'hide'}`}
                    onClick={() => handleSetIsShowing(false)}
                >
                    <ChevronLeftIcon />
                </ResizeButton>
            )}
            <AsideColContainer className={!isShowing && 'hide'}>
                {canUpdate && (
                    <AddPageButton id="dropdown-add" onClick={showNewPageModal}>
                        <RoundedIcon
                            backgroundColor="#F8FCFF"
                            borderColor="#ECEDF0"
                            customIconSize="10"
                            customSize="16"
                            icon="fas fa-plus"
                            iconColor="#FF9A00"
                            size="custom"
                        />
                        <AddPageButtonText>{t('New page')}</AddPageButtonText>
                    </AddPageButton>
                )}
                <AsideCol
                    canUpdate
                    onDeletePage={handlePrepareDelete}
                    onDuplicatePage={duplicatePagesInTemplate}
                    pages={templatePages}
                    selectPage={selectPage}
                    selectedPageId={selectedPageId}
                    updateOrder={orderPagesInTemplate}
                />
            </AsideColContainer>

            <ShowContainer
                className={!isShowing && 'hide-mode'}
                onClick={() => handleSetIsShowing(true)}
            >
                <StyledIcon className="fa fa-chevron-right" />
            </ShowContainer>
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => ({
    prepareDelete: (isPage, callback) =>
        dispatch(actions.prepareDelete(isPage, callback)),
});

LeftSideMenu.propTypes = {
    canUpdate: PropTypes.bool,
    deletePagesInTemplate: PropTypes.func,
    duplicatePagesInTemplate: PropTypes.func,
    key: PropTypes.string,
    orderPagesInTemplate: PropTypes.func,
    prepareDelete: PropTypes.func,
    selectedPageId: PropTypes.string,
    selectPage: PropTypes.func,
    showNewPageModal: PropTypes.func,
    templatePages: PropTypes.array,
};

export default compose(
    connect(null, mapDispatchToProps),
    withTemplatePages
)(LeftSideMenu);
