import arrayMove from 'array-move';

export const getActivedColumns = (columnList) => {
    return columnList.reduce((acc, current) => {
        if (current.is_active) return acc + 1;
        return acc;
    }, 0);
};

export const handleOnDragEnd = (items, result, setColumnList) => {
    const { destination, source } = result;

    if (
        !destination ||
        (destination?.droppableId === source?.droppableId &&
            destination?.index === source?.index)
    )
        return;

    handleUpdateOrderItems(
        items,
        source.index,
        destination.index,
        setColumnList
    );
};

const handleUpdateOrderItems = (items, oldIndex, newIndex, setColumnList) => {
    const copiedItems = [...items];
    const updatedItems = arrayMove(copiedItems, oldIndex, newIndex);

    let newItems = [];

    updatedItems.forEach((item, index) => {
        newItems.push({ ...item, order: index });
    });

    setColumnList(newItems);
};

export const handleOnSelectItemBuild = (
    columnList,
    name,
    setColumnList,
    value
) => {
    const newItems = columnList.map((column) => {
        if (column.name === name) return { ...column, is_active: value };
        return column;
    });
    setColumnList(newItems);
};

export const handleBuildColumns = (
    columnKeys,
    requiredColumnsKeys,
    settings
) => {
    // Filter out the settings columns if they exist in the columns defined in the constants file
    let intersectionColumns = settings.filter((col) =>
        Object.keys(columnKeys).includes(col.name)
    );
    let intersectionkeys = intersectionColumns.map((column) => column.name);
    // The new columns that are not in setting are filtered out
    let differenceColumns = Object.keys(columnKeys).filter(
        (col) => !intersectionkeys.includes(col)
    );
    // New columns are created
    let newColumns = differenceColumns.reduce((acc, column, index) => {
        return [
            ...acc,
            {
                is_active: false,
                name: column,
                order: index,
                required: requiredColumnsKeys.includes(column),
            },
        ];
    }, []);
    // Existing columns are combined with new columns
    return [...intersectionColumns, ...newColumns];
};

export const handleFormatterColumns = (columnKeys, requiredColumnsKeys) => {
    if (!columnKeys) return [];
    if (!requiredColumnsKeys) return [];
    return Object.keys(columnKeys).reduce((acc, column, index) => {
        return [
            ...acc,
            {
                is_active: requiredColumnsKeys.includes(column),
                name: column,
                order: index,
                required: requiredColumnsKeys.includes(column),
            },
        ];
    }, []);
};

export const handleRequiredColumns = (requiredColumnsKeys, settings) => {
    if (!requiredColumnsKeys) return [];
    return settings.map((setting) => ({
        ...setting,
        is_active: requiredColumnsKeys.includes(setting.name)
            ? true
            : setting.is_active,
        required: requiredColumnsKeys.includes(setting.name),
    }));
};
