import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, List, ListItem } from 'sunwise-ui';

import { KeyValue } from 'common/components';
import { numberFormat } from 'common/utils/helpers';

const InsuranceRequestDetail = ({ primaTotal, subTotal, term }) => {
    const { t } = useTranslation();

    return (
        <List>
            <ListItem>
                <Grid container>
                    <Grid item xs>
                        <KeyValue
                            append="MXN/USD/EURO"
                            title={t('Total to be insured')}
                            value={numberFormat(subTotal, {
                                style: 'currency',
                            })}
                        />
                    </Grid>
                    <Grid item xs>
                        <KeyValue
                            append={t('Month', { count: term }).toLowerCase()}
                            title={t('Term')}
                            value={term}
                        />
                    </Grid>
                </Grid>
            </ListItem>

            <ListItem>
                <Grid container>
                    <Grid item xs={9}>
                        <KeyValue
                            append="MXN/USD/EURO"
                            title={t('Total premium')}
                            value={numberFormat(primaTotal, {
                                style: 'currency',
                            })}
                        />
                    </Grid>
                </Grid>
            </ListItem>
        </List>
    );
};

InsuranceRequestDetail.propTypes = {
    primaTotal: PropTypes.number,
    subTotal: PropTypes.number,
    term: PropTypes.number,
};

export default InsuranceRequestDetail;
