import React from 'react';

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { isValidAccess } from 'common/utils/permissions';

import LayoutComponent from './LayoutComponent';
import LayoutPublicComponent from './LayoutPublicComponent';

const LayoutRoute = ({
    component,
    isHidden,
    isPublic,
    layout,
    redirect = { pathname: '/login' },
    requiredPermissions,
    ...rest
}) => {
    if (isHidden) return <Redirect to={redirect} />;
    if (!isValidAccess(isPublic, requiredPermissions))
        return <Redirect to={redirect} />;

    return (
        <Route
            {...rest}
            render={(matchProps) =>
                isPublic ? (
                    <LayoutPublicComponent
                        component={component}
                        layout={layout}
                        matchProps={matchProps}
                        rest={rest}
                    />
                ) : (
                    <LayoutComponent
                        component={component}
                        layout={layout}
                        matchProps={matchProps}
                        rest={rest}
                    />
                )
            }
        />
    );
};

LayoutRoute.defaultProps = {
    isHidden: false,
    redirect: { pathname: '/login' },
};

LayoutRoute.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    isHidden: PropTypes.bool,
    isPublic: PropTypes.bool,
    layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    redirect: PropTypes.object,
    requiredPermissions: PropTypes.array,
};

export default LayoutRoute;
