import { NAME } from './constants';

export const ADD_MESSAGE = `${NAME}/ADD_MESSAGE`;

export const FETCH_MESSAGES = `${NAME}/FETCH_MESSAGES`;
export const FETCH_MESSAGES_FAILURE = `${NAME}/FETCH_MESSAGES_FAILURE`;
export const FETCH_MESSAGES_SUCCESS = `${NAME}/FETCH_MESSAGES_SUCCESS`;

export const INIT_CHAT = `${NAME}/INIT_CHAT`;
export const INIT_CHAT_FAILURE = `${NAME}/INIT_CHAT_FAILURE`;
export const INIT_CHAT_SUCCESS = `${NAME}/INIT_CHAT_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SEND_MESSAGE = `${NAME}/SEND_MESSAGE`;
export const SEND_MESSAGE_FAILURE = `${NAME}/SEND_MESSAGE_FAILURE`;
export const SEND_MESSAGE_SUCCESS = `${NAME}/SEND_MESSAGE_SUCCESS`;
