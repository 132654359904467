import { ContentTypeJSON } from 'common/utils/api';

import API from '..';

const ENTITY = 'financiers/financier-alliance-installer';

export const deleteFinancierInstallersDocuments = (documentId) =>
    API.delete(`/api/v1/${ENTITY}/alliance/documents/${documentId}`);
export const getFinancierInstallersDocuments = (installerId) =>
    API.get(`/api/v1/${ENTITY}/alliance/documents/${installerId}`);
export const updateFinancierInstallersDocuments = (installerId, data) =>
    API.put(`/api/v1/${ENTITY}/alliance/documents/${installerId}`, { ...data });
export const updateFinancierInstallersDocumentStatus = (documentId, data) =>
    API.put(`/api/v1/${ENTITY}/status/documents/${documentId}`, { ...data });
export const uploadContract = (data) =>
    API.post(`/api/v1/${ENTITY}/documents/`, { ...data });
export const changeStatusFile = (fileId, data) =>
    API.put(`/api/v1/${ENTITY}/status/documents/${fileId}`, { ...data });
export const fetchRequirements = () =>
    API.get(`/api/v1/${ENTITY}/catalog/requirements/`);
export const saveRequirements = (data) =>
    API.post(`/api/v1/${ENTITY}/catalog/requirements/`, { ...data });
export const unlinkAlliance = (id, data) =>
    API.put(
        `/api/v1/${ENTITY}/alliance/unassign/${id}`,
        { ...data },
        ContentTypeJSON
    );
export const updateRequirements = (data) =>
    API.put(`/api/v1/${ENTITY}/catalog/requirements/${data.id}`, { ...data });
