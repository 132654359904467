import { NAME } from './constants';

export const FETCH_APPROVED_CREDITS = `${NAME}/FETCH_APPROVED_CREDITS`;
export const FETCH_APPROVED_CREDITS_FAILURE = `${NAME}/FETCH_APPROVED_CREDITS_FAILURE`;
export const FETCH_APPROVED_CREDITS_SUCCESS = `${NAME}/FETCH_APPROVED_CREDITS_SUCCESS`;

export const FETCH_CREDIT_APPLICATIONS = `${NAME}/FETCH_CREDIT_APPLICATIONS`;
export const FETCH_CREDIT_APPLICATIONS_FAILURE = `${NAME}/FETCH_CREDIT_APPLICATIONS_FAILURE`;
export const FETCH_CREDIT_APPLICATIONS_SUCCESS = `${NAME}/FETCH_CREDIT_APPLICATIONS_SUCCESS`;

export const FETCH_CREDITS = `${NAME}/FETCH_CREDITS`;
export const FETCH_CREDITS_FAILURE = `${NAME}/FETCH_CREDITS_FAILURE`;
export const FETCH_CREDITS_SUCCESS = `${NAME}/FETCH_CREDITS_SUCCESS`;

export const FETCH_ALLIANCES = `${NAME}/FETCH_ALLIANCES`;
export const FETCH_ALLIANCES_FAILURE = `${NAME}/FETCH_ALLIANCES_FAILURE`;
export const FETCH_ALLIANCES_SUCCESS = `${NAME}/FETCH_ALLIANCES_SUCCESS`;

export const INITIALIZE_CHANGE_STATUS_FORM = `${NAME}/INITIALIZE_CHANGE_STATUS_FORM`;
export const INITIALIZE_REASSIGN_ADVISOR_FORM = `${NAME}/INITIALIZE_REASSIGN_ADVISOR_FORM`;

export const OPEN_MODAL_CHANGE_STATUS = `${NAME}/OPEN_MODAL_CHANGE_STATUS`;
export const OPEN_MODAL_REASSIGN_ADVISOR = `${NAME}/OPEN_MODAL_REASSIGN_ADVISOR`;

export const RESET_CHANGE_STATUS_FORM = `${NAME}/RESET_CHANGE_STATUS_FORM`;
export const RESET_REASSIGN_ADVISOR_FORM = `${NAME}/RESET_REASSIGN_ADVISOR_FORM`;

export const SAVE_BULK_CHANGE_STATUS = `${NAME}/SAVE_BULK_CHANGE_STATUS`;
export const SAVE_BULK_CHANGE_STATUS_FAILURE = `${NAME}/SAVE_BULK_CHANGE_STATUS_FAILURE`;
export const SAVE_BULK_CHANGE_STATUS_SUCCESS = `${NAME}/SAVE_BULK_CHANGE_STATUS_SUCCESS`;

export const SAVE_BULK_REASSING_ADVISOR = `${NAME}/SAVE_BULK_REASSING_ADVISOR`;
export const SAVE_BULK_REASSING_ADVISOR_FAILURE = `${NAME}/SAVE_BULK_REASSING_ADVISOR_FAILURE`;
export const SAVE_BULK_REASSING_ADVISOR_SUCCESS = `${NAME}/SAVE_BULK_REASSING_ADVISOR_SUCCESS`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SET_IS_DOWNLOADING = `${NAME}/SET_IS_DOWNLOADING`;
