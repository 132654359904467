import { updateRowEdition } from 'common/api/financierProduct';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { financialActions } from '../reducer';

import fetchProducts from './fetchProducts';

export default (filterData, values) => (dispatch) => {
    if (!values.id) return;

    const newValues = {
        name: values.name,
    };

    dispatch(financialActions[SAVE_ITEM]());

    updateRowEdition(values.id, newValues)
        .then(() => {
            dispatch(financialActions[SAVE_ITEM_SUCCESS]());
            dispatch(fetchProducts(filterData));
            showToast();
        })
        .catch((error) => {
            dispatch(
                financialActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
