import React, { useState } from 'react';

import ListIcon from '@mui/icons-material/List';
import { get, isEmpty, isNull, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Menu, MenuItem, Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';
import { parseDate, formatDate } from 'common/utils/dates';
import { getStatusText } from 'common/utils/helpers';

import ActionsFormatter from './ActionsFormatter';
import InstallerNameFormatter from './InstallerNameFormatter';
import InstallerOwnerFormatter from './InstallerOwnerFormatter';
import UserTrackingFormatter from './UserTrackingFormatter';

const Columns = ({
    canModify,
    handleClickAssignProduct,
    handleClickUnlink,
    hiddenColumns,
    orders,
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(0);
    const breakpoint = useBreakpoint();
    const open = Boolean(anchorEl);
    const handleClick = (event, rowId) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(rowId);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };
    const nameColumn = [
        {
            align: 'left',
            field: 'business_name',
            flex: 1,
            headerName: t('Business name'),
            hide: hiddenColumns.includes('business_name'),
            minWidth: 300,
            order: orders?.business_name,
            renderCell: (params) => {
                const { row } = params;
                return <InstallerNameFormatter row={row} />;
            },
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            field: 'owner',
            headerName: t('Legal representative'),
            hide: hiddenColumns.includes('owner'),
            order: orders?.owner,
            renderCell: (params) => {
                const { row } = params;
                return <InstallerOwnerFormatter row={row} />;
            },
            sortable: false,
            width: 300,
        },
        {
            align: 'left',
            field: 'user_tracking',
            headerName: t('Assigned executive'),
            hide: hiddenColumns.includes('user_tracking'),
            order: orders?.user_tracking,
            renderCell: (params) => {
                const { row } = params;
                return <UserTrackingFormatter row={row} />;
            },
            sortable: false,
            width: 300,
        },
        {
            align: 'left',
            field: 'products',
            headerName: t('Product', { count: 2 }),
            hide: hiddenColumns.includes('products'),
            order: orders?.products,
            renderCell: (params) => {
                const { row } = params;
                const products = get(row, 'installer.products', []);
                if (isEmpty(products) || isNull(products))
                    return <Typography variant="caption">- - -</Typography>;
                return (
                    <Box>
                        <Button
                            onClick={(e) => handleClick(e, row?.id)}
                            startIcon={<ListIcon fontSize="small" />}
                            sx={{ fontSize: '0.75rem' }}
                            variant="outlined"
                        >
                            {t('Product', { count: 2 })}
                        </Button>

                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                horizontal: 'right',
                                vertical: 'top',
                            }}
                            key={row.id}
                            onClose={handleClose}
                            open={open && selectedRow === row.id}
                            sx={{
                                '& .MuiMenu-paper': {
                                    border: '1px solid rgba(244,244,244)',
                                    boxShadow: 'none',
                                },
                            }}
                            transformOrigin={{
                                horizontal: 'right',
                                vertical: 'top',
                            }}
                        >
                            {products.map((product) => (
                                <MenuItem
                                    key={product.id}
                                    onClick={handleClose}
                                    sx={{
                                        '&.MuiMenuItem-root': {
                                            fontSize: '0.75rem',
                                        },
                                    }}
                                >
                                    {product.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                );
            },
            width: 180,
        },
        {
            align: 'left',
            field: 'phone',
            headerName: t('Phone'),
            hide: hiddenColumns.includes('phone'),
            order: orders?.phone,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.installer?.phone || '- - -'}
                    </Typography>
                );
            },
            width: 180,
        },
        {
            align: 'left',
            field: 'city',
            headerName: t('City'),
            hide: hiddenColumns.includes('city'),
            order: orders?.city,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.installer?.city || '- - -'}
                    </Typography>
                );
            },
            width: 180,
        },
        {
            align: 'left',
            field: 'status',
            headerName: t('Status'),
            hide: hiddenColumns.includes('status'),
            order: orders?.status,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {getStatusText(row.status, true)}
                    </Typography>
                );
            },
            width: 180,
        },

        {
            align: 'left',
            field: 'created_at',
            headerName: t('Application date'),
            hide: hiddenColumns.includes('created_at'),
            order: orders?.created_at,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {formatDate(
                            parseDate(row.created_at, 'dd/MM/yyyy HH:mm:ss'),
                            'dd/MM/yyyy'
                        )}
                    </Typography>
                );
            },
            width: 180,
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: 'actions',
            flex: 1,
            headerName: '',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        canModify={canModify}
                        handleClickAssignProduct={handleClickAssignProduct}
                        handleClickUnlink={handleClickUnlink}
                        row={row}
                    />
                );
            },
            sortable: false,
        },
    ];

    return orderBy(columns, ['order'], ['asc']);
};

Columns.propTypes = {
    canModify: PropTypes.bool,
    handleClickAssignProduct: PropTypes.func,
    handleClickUnlink: PropTypes.func,
    hiddenColumns: PropTypes.array,
    orders: PropTypes.object,
};

export default Columns;
