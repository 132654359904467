import i18next from 'i18next';
import is from 'is_js';

export default (values = {}) => {
    const errors = {};

    if (is.empty(values.financier_group)) {
        errors.financier_group = i18next.t('Select a role');
    }

    if (is.empty(values.first_name)) {
        errors.first_name = i18next.t('Name cannot be empty');
    }

    if (is.empty(values.last_name)) {
        errors.last_name = i18next.t('Last name cannot be empty');
    }

    if (is.empty(values.email)) {
        errors.email = i18next.t('Email cannot be empty');
    }

    if (is.empty(values.password)) {
        errors.password = i18next.t('Password cannot be empty');
    }

    if (is.empty(values.confirm_password)) {
        errors.confirm_password = i18next.t('Confirm password cannot be empty');
    }

    if (!is.empty(values.password) && !is.empty(values.confirm_password)) {
        if (values.password != values.confirm_password) {
            errors.confirm_password = i18next.t(
                'Confirm password does not match'
            );
        }
    }

    if (is.empty(values.branch_office)) {
        errors.branch_office = i18next.t('Branch cannot be empty');
    }

    return errors;
};
