import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider } from 'sunwise-ui';

import { ShowErrors } from 'common/components';
import { ReactHookFormInput } from 'common/components/form/rhf';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const CoverageForm = ({
    errors,
    filterData,
    initialValues,
    isSaving,
    save,
}) => {
    const { t } = useTranslation();

    const { control, handleSubmit, reset } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    useEffect(() => reset(initialValues), [initialValues]);

    const handleClickSave = (values) => save(filterData, values);

    return (
        <form>
            <ReactHookFormInput
                control={control}
                disabled={isSaving}
                label={`${t('Name')} *`}
                name="name"
            />

            <ReactHookFormInput
                control={control}
                disabled={isSaving}
                label={t('Description')}
                name="description"
            />

            <ShowErrors errors={errors} />

            <Divider sx={{ mx: -2 }} />

            <Box sx={{ textAlign: 'right', pt: 2 }}>
                <Button
                    disabled={isSaving}
                    onClick={handleSubmit(handleClickSave)}
                >
                    {isSaving ? t('Saving') : t('Save')}
                </Button>
            </Box>
        </form>
    );
};

CoverageForm.propTypes = {
    errors: PropTypes.array,
    filterData: PropTypes.object,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    save: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSaveCoverage,
    initialValues: selectors.getInitialValuesCoverageForm,
    isSaving: selectors.getIsSavingCoverage,
});

const mapDispatchToProps = (dispatch) => ({
    save: (filterData, values) =>
        dispatch(actions.saveCoverage(filterData, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoverageForm);
