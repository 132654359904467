import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

import {
    APPROVED_STATUS,
    CANCELLED_STATUS,
    CLOSED_STATUS,
    CONDITIONED_APPROVED_STATUS,
    GRANTED_STATUS,
    INCOMPLETE_STATUS,
    PREAPPROVED_STATUS,
    PREVIABILITY_STATUS,
    PROCESS_STATUS,
    REQUESTED_STATUS,
} from 'common/constants';

export const creditsFormatted = (credits) => {
    return credits.map((item) => ({
        ...item,
        client_name: item.client.name,
        client_first_lastname: item.client.first_lastname,
        client_second_lastname: item.client.second_lastname,
    }));
};

export const getSizeLgStatus = (selectionModel) => {
    if (isEmpty(selectionModel)) return 12; // Si no selecciona
    return 8; // Si no filtra y no selecciona
};

export const getAlliancesOptions = (alliances) => {
    const options = alliances.map((item) => ({
        label: item.name,
        value: item.id,
    }));
    return options;
};

export const getStatusOptions = () => [
    {
        label: REQUESTED_STATUS,
        value: REQUESTED_STATUS,
    },
    {
        label: INCOMPLETE_STATUS,
        value: INCOMPLETE_STATUS,
    },
    {
        label: PROCESS_STATUS,
        value: PROCESS_STATUS,
    },
    {
        label: PREVIABILITY_STATUS,
        value: PREAPPROVED_STATUS,
    },
    {
        label: CONDITIONED_APPROVED_STATUS,
        value: CONDITIONED_APPROVED_STATUS,
    },
    {
        label: APPROVED_STATUS,
        value: APPROVED_STATUS,
    },
    {
        label: GRANTED_STATUS,
        value: CLOSED_STATUS,
    },
    {
        label: CANCELLED_STATUS,
        value: CANCELLED_STATUS,
    },
];

export const getUsersOptions2 = (users) => {
    const options = users.map((item) => ({
        label: `${item.first_name} ${item.last_name}`,
        value: item.id,
    }));
    return options;
};

export const getUsersOptions = (users) => {
    const options = users.map((item) => ({
        label: `${item.first_name} ${item.last_name}`,
        value: item.id,
    }));
    return [
        {
            disabled: true,
            label: i18next.t('Select a user'),
            value: '',
        },
        ...options,
    ];
};
