import React from 'react';

import BusinessIcon from '@mui/icons-material/Business';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Card, Grid, Link } from 'sunwise-ui';

import { MapContainer, StyledH2, TextVariant } from 'common/components';

import iconGoogleMaps from 'resources/iconGoogleMaps.svg';

const LocationCard = ({ location, address = '' }) => {
    const { t } = useTranslation();
    if (!location || !location.position) return null;

    const center = {
        lat: get(location, 'position.latitude'),
        lng: get(location, 'position.longitude'),
    };

    const markers = [
        {
            lat: center.lat,
            lng: center.lng,
            name: location.description,
            title: location.title,
        },
    ];

    return (
        <Card>
            <Card.Header>
                <StyledH2>{t('Location')}</StyledH2>
            </Card.Header>

            <Card.Body>
                {center.lat && center.lng ? (
                    <Grid container>
                        <Grid item>
                            <BusinessIcon />
                        </Grid>

                        <Grid item xs>
                            <TextVariant
                                text={location.title || 'Sin ubicación'}
                                variant="bold"
                            />
                            <br />
                            <TextVariant text={address} />
                            &nbsp;&nbsp;
                            <Link
                                href={`https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <img alt="" src={iconGoogleMaps} width="22px" />
                            </Link>
                        </Grid>
                    </Grid>
                ) : (
                    <Alert severity="warning">
                        {t('No location assigned')}
                    </Alert>
                )}
            </Card.Body>

            {center.lat && center.lng && (
                <MapContainer center={center} markers={markers} />
            )}
        </Card>
    );
};

LocationCard.propTypes = {
    location: PropTypes.object,
    address: PropTypes.string,
};

export default LocationCard;
