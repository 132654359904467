import { NAME } from './constants';

export const SAVE = `${NAME}/SAVE`;
export const SAVE_SUCCESS = `${NAME}/SAVE_SUCCESS`;
export const SAVE_FAILURE = `${NAME}/SAVE_FAILURE`;

export const VALID_PASSWORD = `${NAME}/VALID_PASSWORD`;
export const INVALID_PASSWORD = `${NAME}/INVALID_PASSWORD`;

export const VALID_PASSWORD_CONFIRMATION_LENGTH = `${NAME}/VALID_PASSWORD_CONFIRMATION_LENGTH`;
export const INVALID_PASSWORD_CONFIRMATION_LENGTH = `${NAME}/INVALID_PASSWORD_CONFIRMATION_LENGTH`;

export const VALID_PASSWORD_CONFIRMATION = `${NAME}/VALID_PASSWORD_CONFIRMATION`;
export const INVALID_PASSWORD_CONFIRMATION = `${NAME}/INVALID_PASSWORD_CONFIRMATION`;
