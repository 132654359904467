import isNil from 'lodash/isNil';

import { addLocation, updateLocation } from 'common/api/creditApplications';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_LOCATION,
    SAVE_LOCATION_FAILURE,
    SAVE_LOCATION_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

import fetchCreditApplication from './fetchCreditApplication';
import resetLocationForm from './resetLocationForm';

export default (values, callback) => (dispatch) => {
    let newValues = {};

    if (!isNil(values.latitude) && !isNil(values.longitude)) {
        newValues = {
            address: values.description,
            credit: values.creditId,
            latitude: values.latitude,
            longitude: values.longitude,
            name: values.description,
        };
    }

    dispatch(creditFileActions[SAVE_LOCATION]());

    const save = () =>
        values.id
            ? updateLocation(newValues, values.id)
            : addLocation(newValues);

    save()
        .then(() => {
            dispatch(creditFileActions[SAVE_LOCATION_SUCCESS]());
            dispatch(fetchCreditApplication(values.creditId));
            dispatch(resetLocationForm());
            if (callback) callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                creditFileActions[SAVE_LOCATION_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
