import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteOption from './deleteOption';

const getStatus = (type) => {
    const message = {
        0: 'Follow',
        1: 'Contacts',
        2: 'Contact documents',
        3: 'Project documents',
        4: 'Project',
        5: 'Contacts',
    };
    return i18next.t(message[type], { count: 2 }).toLowerCase();
};

export default (id, type) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        haveTextCaptcha: true,
        messages: [
            i18next.t(
                'Removing this element will affect all {{type}} that have this element assigned. Are you really sure about removing this item?',
                { type: getStatus(type) }
            ),
        ],
        onSuccess: () => dispatch(deleteOption(id, type)),
        textToValidate: i18next.t('Delete').toUpperCase(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
