import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => {
    return state[NAME];
};

export const getState = (state) => {
    return state;
};

export const getfetchTemplate = createSelector(
    getModel,
    (model) => model.fetchTemplate
);

export const getIsFetchingCustomTemplate = createSelector(
    getfetchTemplate,
    (model) => model.isFetching
);

export const getDatafetchTemplate = createSelector(
    getfetchTemplate,
    (fetchTemplate) => fetchTemplate.data || null
);

export const getIsInitialize = createSelector(getModel, (data) => {
    return data.isInitialize;
});

export const getupdateTemplate = createSelector(
    getModel,
    (model) => model.updateTemplate
);

export const getIsUpdatingCustomTemplate = createSelector(
    getupdateTemplate,
    (model) => model.isUpdating
);

export const getupdateTemplateType = createSelector(
    getupdateTemplate,
    (model) => model.type
);

export const getfetchTemplateCatalogs = createSelector(
    getModel,
    (model) => model.fetchTemplateCatalogs
);

export const getIsFetchingClonePages = createSelector(
    getfetchTemplateCatalogs,
    (fetchTemplateCatalogs) => fetchTemplateCatalogs.isFetching
);

export const getfetchTemplateCatalogsData = createSelector(
    getfetchTemplateCatalogs,
    (fetchTemplateCatalogs) => fetchTemplateCatalogs.data || []
);

export const getfetchTemplateCatalogsErrors = createSelector(
    getfetchTemplateCatalogs,
    (fetchTemplateCatalogs) => fetchTemplateCatalogs.errors || []
);
