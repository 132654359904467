export const NAME = 'multi-template-review';

export const DATASHEET_PDF_TYPE = 3;

export const PDF_LOW_QUALITY = '1';
export const PDF_MEDIUM_QUALITY = '2';
export const PDF_HIGHT_QUALITY = '3';
export const CSV_FILE = '4';
export const CSV_HOURLY_FILE = '5';
export const XLS_FILE = '6';
export const XLS_FILE_FORMATTED = '7';

export const SMART_DOCUMENT_FORM_CREATE_MODE = 1;
export const SMART_DOCUMENT_FORM_DUPLICATE_MODE = 2;
export const SMART_DOCUMENT_FORM_RENAME_MODE = 3;
