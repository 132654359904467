import { ContentTypeJSON } from 'common/utils/api';

import API from '../.';
const ENTITY = 'users/financier-group';

export const createItem = (data) => API.post(`/api/v1/${ENTITY}/`, { ...data });
export const getItems = () => API.get(`/api/v1/${ENTITY}/`);
export const updateItem = (data, id) =>
    API.put(`/api/v1/${ENTITY}/${id}`, { ...data });
export const deleteItem = (id) => API.delete(`/api/v1/${ENTITY}/${id}`);
export const getCategoriesSettings = () =>
    API.get(`/api/v1/users/categories-settings/`);

export const getRoleLevels = () =>
    API.get(`/api/v1/${ENTITY}/financier-group-type/`);

export const getRoleDetails = (roleId) =>
    API.get(`/api/v1/users/roles/${roleId}/`);

export const createRole = (data) =>
    API.post(`/api/v1/users/roles/`, { ...data }, ContentTypeJSON);

export const updateRole = (data, id) =>
    API.put(`/api/v1/users/roles/${id}/`, { ...data }, ContentTypeJSON);

export const getRoleLevelDetails = (levelId) =>
    API.get(`/api/v1/users/role-level/${levelId}/`);
