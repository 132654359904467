import React from 'react';

import ImageIcon from '@mui/icons-material/Image';
import { useTheme } from '@mui/material/styles';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
} from 'sunwise-ui';

import IconFinancial from 'common/components/icons/IconFinancial';
import { handleFileURL } from 'common/utils/helpers';

import { FINANCING_TYPE } from '../constants';

import SideMenuCard from './SideMenuCard';

const FinancialCard = ({
    financing,
    handleClickDatasheetItem,
    handleClickDocumentItem,
    isDisabled,
    selectedDocumentId,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (isEmpty(financing)) return null;

    const content = get(financing, 'content', {});
    const currentId = isEmpty(content) ? financing.name : content?.id;
    const extFinancingFile = get(financing, 'proposal_financing_file', null);

    return (
        <SideMenuCard icon={<IconFinancial />} title={t('Financing')}>
            <List disablePadding>
                <ListItem
                    disabled={isDisabled || currentId === selectedDocumentId}
                    disablePadding
                    selected={currentId === selectedDocumentId}
                    sx={{
                        borderRadius: '8px',
                        mb: '5px',
                        '&.Mui-selected': {
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? '#000'
                                    : 'rgba(31, 60, 83, 0.13)',
                        },
                    }}
                >
                    <ListItemButton
                        disabled={
                            isDisabled || currentId === selectedDocumentId
                        }
                        onClick={() => {
                            if (extFinancingFile)
                                handleClickDatasheetItem({
                                    id: financing?.name,
                                    name: financing?.name,
                                    archive: extFinancingFile,
                                    type: FINANCING_TYPE,
                                });
                            else if (!isEmpty(content))
                                handleClickDocumentItem({
                                    id: content?.id,
                                    name: content?.name,
                                    type: FINANCING_TYPE,
                                });
                            return false;
                        }}
                        sx={{ borderRadius: '8px' }}
                    >
                        <ListItemAvatar>
                            <Avatar
                                src={handleFileURL(
                                    get(financing, 'image', ''),
                                    process.env.REACT_APP_S3_MEDIA_PATH
                                )}
                                sx={{
                                    height: 32,
                                    width: 32,
                                }}
                            >
                                <ImageIcon />
                            </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                            primary={`${financing?.name} (Propuesta)`}
                            sx={{
                                '.MuiListItemText-primary': {
                                    fontSize: '14px',
                                },
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            </List>
        </SideMenuCard>
    );
};

FinancialCard.propTypes = {
    financing: PropTypes.object,
    handleClickDatasheetItem: PropTypes.func,
    handleClickDocumentItem: PropTypes.func,
    isDisabled: PropTypes.bool,
    selectedDocumentId: PropTypes.string,
};

export default FinancialCard;
