import API from '..';
const ENTITY = 'products-sunwise';

export const getAccessories = (str = '', page = 1, financierProductId) =>
    API.get(
        `/api/v1/${ENTITY}/accessories/paginate/filter/${str}?page=${page}${
            financierProductId !== ''
                ? `&financier_product=${financierProductId}`
                : ''
        }`
    );

export const getInverterBrands = () =>
    API.get(`/api/v1/${ENTITY}/inverters-brands/`);

export const getInverters = ({
    brandName,
    financierProductId,
    page = 1,
    pageSize,
    searchText = '',
}) =>
    API.get(`/api/v1/${ENTITY}/inverters/paginate/filter/${searchText}`, {
        params: {
            ...(brandName ? { brand: brandName } : {}),
            financier_product: financierProductId,
            page,
            per_page: pageSize,
        },
    });

export const getPanelBrands = () => API.get(`/api/v1/${ENTITY}/panels-brands/`);

export const getPanels = ({
    brandName,
    financierProductId,
    page = 1,
    pageSize,
    searchText = '',
}) =>
    API.get(`/api/v1/${ENTITY}/panels/paginate/filter/${searchText}`, {
        params: {
            ...(brandName ? { brand: brandName } : {}),
            financier_product: financierProductId,
            page,
            per_page: pageSize,
        },
    });
