import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, change } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider } from 'sunwise-ui';

import { ReduxFieldInput } from 'common/components/form/redux';
import ShowErrors from 'common/components/ShowErrors';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

import EmailFieldList from './EmailFieldList';
import PhoneFieldList from './PhoneFieldList';

const ContactForm = ({
    creditId,
    contactId,
    errors,
    handleClickCloseForm,
    handleSubmit,
    isSaving,
    save,
}) => {
    const { t } = useTranslation();
    const disabled = false;

    const handleOnClickSubmit = (values) =>
        save({ ...values, id: contactId, creditId: creditId });

    return (
        <form onSubmit={handleSubmit(handleOnClickSubmit)}>
            <Field
                name="name"
                component={ReduxFieldInput}
                label={`${t('Name')} *`}
                disabled={disabled}
                placeholder={t('Enter name')}
            />

            <Field
                name="first_lastname"
                component={ReduxFieldInput}
                label={`${t('Last name')} *`}
                disabled={disabled}
                placeholder={t('Enter last name')}
            />

            <Field
                name="second_lastname"
                component={ReduxFieldInput}
                label={t('Second last name')}
                disabled={disabled}
                placeholder={t('Enter last name')}
            />

            <Field
                name="business_name"
                component={ReduxFieldInput}
                label={t('Business name')}
                disabled={disabled}
                placeholder={t('Business name')}
            />

            <FieldArray
                component={PhoneFieldList}
                name="telephones"
                options={[]}
                placeholder={t('Phones')}
            />

            <FieldArray
                component={EmailFieldList}
                name="emails"
                options={[]}
                placeholder={t('Emails')}
            />

            <ShowErrors errors={errors} />

            <Divider sx={{ mb: 2, mx: -2 }} />

            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'end',
                }}
            >
                <Button
                    color="secondary"
                    onClick={handleClickCloseForm}
                    variant="text"
                >
                    {t('Cancel')}
                </Button>
                {!disabled && (
                    <Button disabled={isSaving} type="submit">
                        {t('Save')}
                    </Button>
                )}
            </Box>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    formValues: selectors.getValues,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSavingItem,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/form`, field, value)),
    save: (values) => dispatch(actions.save(values)),
});

ContactForm.propTypes = {
    contactId: PropTypes.number,
    creditId: PropTypes.number,
    errors: PropTypes.array,
    handleClickCloseForm: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSaving: PropTypes.bool,
    save: PropTypes.func,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
    validate,
})(ContactForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
