import { saveSelectedProducts } from 'common/api/products';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SAVE_SELECTED_PRODUCTS,
    SAVE_SELECTED_PRODUCTS_FAILURE,
    SAVE_SELECTED_PRODUCTS_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

import resetProductsForm from './resetProductsForm';

const handleCheckboxField = (values) => {
    if (!values) return '';

    const object_keys = Object.keys(values);
    let temp = [];

    if (object_keys.length) {
        object_keys.forEach((item) => {
            temp.push({
                id: item,
                added: values[item],
            });
        });
    }

    return JSON.stringify(temp);
};

export default (values, callback) => (dispatch) => {
    const newValues = {
        financiers_products: handleCheckboxField(values.financiers_products),
    };

    dispatch(installerProfileActions[SAVE_SELECTED_PRODUCTS]());

    saveSelectedProducts(values.allianceId, newValues)
        .then(() => {
            dispatch(installerProfileActions[SAVE_SELECTED_PRODUCTS_SUCCESS]());
            dispatch(resetProductsForm());
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                installerProfileActions[SAVE_SELECTED_PRODUCTS_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
