import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, Grid, Typography, Tooltip } from 'sunwise-ui';

import { Modal } from 'common/components';

import companyUsers from '../../companyUsers';
import * as actions from '../actions';

import SelectUserForm from './SelectUserForm';

const CardAssignUser = ({
    canModify,
    creditId,
    fetchCreditApplication,
    fetchUsers,
    financialAdvisor,
    users,
    userTracking,
}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const handleOnClickAssignUser = () => setShowModal(true);
    const handleOnEnter = () => fetchUsers();
    const handleOnHide = () => setShowModal(false);
    const handleOnCallback = () => {
        fetchCreditApplication(creditId);
        setShowModal(false);
    };
    return (
        <Card>
            <Card.Body>
                <Grid container alignItems="start">
                    <Grid item xs={18} lg={14}>
                        <Box display="flex" gap={1}>
                            <Typography fontWeight="bold" variant="caption">
                                Ejecutivo de solicitud:
                            </Typography>
                            <Typography variant="caption">
                                {financialAdvisor !== null
                                    ? `${financialAdvisor?.first_name} ${financialAdvisor?.last_name} ${financialAdvisor?.second_surname}`
                                    : t('No user assigned')}
                            </Typography>
                        </Box>
                        <Box display="flex" gap={1}>
                            <Typography fontWeight="bold" variant="caption">
                                {t('Company executive')}
                            </Typography>
                            <Typography variant="caption">
                                {userTracking !== null
                                    ? `${userTracking?.first_name} ${userTracking?.last_name} ${userTracking?.second_surname}`
                                    : t('No user assigned')}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        hidden={!canModify}
                        item
                        xs={18}
                        md={4}
                        textAlign="right"
                    >
                        <Tooltip title={t('Request executive')}>
                            <Button
                                color="secondary"
                                onClick={handleOnClickAssignUser}
                                size="small"
                                variant="text"
                            >
                                {financialAdvisor === null
                                    ? t('Assign')
                                    : t('Change')}{' '}
                                {t('Executive')}
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Card.Body>

            <Modal
                onEnter={handleOnEnter}
                onHide={handleOnHide}
                show={showModal}
                size="sm"
                title={t('Assign request executive')}
            >
                <SelectUserForm
                    creditId={creditId}
                    callback={handleOnCallback}
                    users={users}
                />
            </Modal>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    users: companyUsers.selectors.getFetchItemsListData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCreditApplication: (id) =>
        dispatch(actions.fetchCreditApplication(id)),
    fetchUsers: () => dispatch(companyUsers.actions.fetchItems()),
});

CardAssignUser.propTypes = {
    canModify: PropTypes.bool,
    creditId: PropTypes.number,
    fetchCreditApplication: PropTypes.func,
    fetchUsers: PropTypes.func,
    financialAdvisor: PropTypes.object,
    users: PropTypes.array,
    userTracking: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardAssignUser);
