import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Card, Grid, Typography } from 'sunwise-ui';

import PreviewLocation from 'common/components/PreviewLocation';

import * as actions from '../actions';
import * as selectors from '../selectors';

import LocationForm from './LocationForm';

const LocationCard = ({
    creditId,
    isDeletingLocation,
    location,
    prepareDeleteLocation,
    prepareEditLocation,
    resetLocationForm,
}) => {
    const { t } = useTranslation();
    const [isEditionMode, setIsEditionMode] = useState(false);

    useEffect(() => {
        return () => {
            resetLocationForm();
        };
    }, []);

    const handleClickEdit = () => {
        if (isEmpty(location)) return;
        const { id, address, latitude, longitude } = location;
        prepareEditLocation({ description: address, id, latitude, longitude });
        setIsEditionMode(true);
    };

    const renderContent = () => {
        if (isEditionMode)
            return (
                <LocationForm
                    canUpdate={true}
                    creditId={creditId}
                    handleClose={() => {
                        setIsEditionMode(false);
                        resetLocationForm();
                    }}
                />
            );

        if (isEmpty(location))
            return (
                <Button
                    color="secondary"
                    endIcon={<AddIcon />}
                    fullWidth
                    onClick={() => setIsEditionMode(true)}
                    variant="outlined"
                >
                    {t('Add location')}
                </Button>
            );

        const { address, latitude, longitude, name } = location;

        return (
            <Box mb={2}>
                <PreviewLocation
                    location={{
                        description: address,
                        position: { latitude, longitude },
                        title: name,
                    }}
                />
                <Grid container>
                    <Grid
                        alignItems="center"
                        display="flex"
                        gap={1}
                        item
                        justifyContent="end"
                        xs
                    >
                        <Button
                            color="error"
                            disabled={isDeletingLocation}
                            onClick={() =>
                                prepareDeleteLocation(location?.id, creditId)
                            }
                            variant="text"
                        >
                            {t('Delete')}
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => handleClickEdit()}
                            variant="outlined"
                        >
                            {t('Edit')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return (
        <Card>
            <Card.Header>
                <Grid container spacing={0}>
                    <Grid item xs>
                        <Typography fontWeight="bold" variant="body2">
                            {t('Location')}
                        </Typography>
                    </Grid>
                </Grid>
            </Card.Header>

            <Card.Body>{renderContent()}</Card.Body>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    isDeletingLocation: selectors.getIsDeletingLocation,
});

const mapDispatchToProps = (dispatch) => ({
    prepareDeleteLocation: (id, creditId) =>
        dispatch(actions.prepareDeleteLocation(id, creditId)),
    prepareEditLocation: (values) =>
        dispatch(actions.initializeLocationForm(values)),
    resetLocationForm: () => dispatch(actions.resetLocationForm()),
});

LocationCard.propTypes = {
    creditId: PropTypes.number,
    isDeletingLocation: PropTypes.bool,
    location: PropTypes.object,
    prepareDeleteLocation: PropTypes.func,
    prepareEditLocation: PropTypes.func,
    resetLocationForm: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationCard);
