import { OPEN_COVERAGE_CONFING_FORM_MODAL } from '../actionTypes';
import { configurationCoveragesActions } from '../reducer';

import resetCoverageConfigForm from './resetCoverageConfigForm';

export default (value) => (dispatch) => {
    dispatch(
        configurationCoveragesActions[OPEN_COVERAGE_CONFING_FORM_MODAL](value)
    );
    if (!value) dispatch(resetCoverageConfigForm());
};
