import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const CardFolderContentWrapper = styled('div')`
    cursor: pointer;
    font-size: 11px;
    line-height: 14px;
    padding: 40px 10px;
    text-align: center;
`;

const ImageContainer = styled('div')`
    height: 87px;
    margin-bottom: 11px;
    position: relative;
    width: 100%;
`;

const ImageFolder = styled('img')`
    height: 87px;
    margin: 0 auto;
    position: relative;
    width: 96px;
`;

const TitleText = styled('span')`
    color: #003b4b;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.34px;
    line-height: 22px;
    min-height: 22px;
    text-align: center;
`;

const RateLabel = styled('span')`
    font-size: 20px;
    font-weight: bold;
    left: 0;
    line-height: 29px;
    min-height: 29px;
    position: absolute;
    text-align: center;
    top: 35px;
    width: 100%;
`;

const CardFolderContent = ({ image, title, children, onClick, rate }) => (
    <CardFolderContentWrapper onClick={onClick}>
        <ImageContainer>
            <ImageFolder src={image} />
            <RateLabel>{rate}</RateLabel>
        </ImageContainer>

        <TitleText>{title}</TitleText>
        {children}
    </CardFolderContentWrapper>
);

CardFolderContent.propTypes = {
    children: PropTypes.node,
    image: PropTypes.string,
    onClick: PropTypes.func,
    rate: PropTypes.string,
    title: PropTypes.string,
};

export default CardFolderContent;
