import React from 'react';

import PropTypes from 'prop-types';
import { Box, Card, Typography } from 'sunwise-ui';

const SideMenuCard = ({ children, icon, sxCard, sxCardBody, title }) => (
    <Card sx={{ ...sxCard, mb: '16px !important' }}>
        <Card.Header>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
                {icon}
                <Typography fontWeight="bold" ml={1} variant="body2">
                    {title}
                </Typography>
            </Box>
        </Card.Header>

        <Card.Body sx={sxCardBody}>{children}</Card.Body>
    </Card>
);

SideMenuCard.defaultProps = {
    sxCard: {},
    sxCardBody: {},
};

SideMenuCard.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    icon: PropTypes.object,
    sxCard: PropTypes.object,
    sxCardBody: PropTypes.object,
    title: PropTypes.string,
};

export default SideMenuCard;
