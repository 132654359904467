import { filterFinancierInstallers } from 'common/api/financierInstallers';

import {
    FETCH_INSTALLERS,
    FETCH_INSTALLERS_FAILURE,
    FETCH_INSTALLERS_SUCCESS,
} from '../actionTypes';
import { installersActions } from '../reducer';

export default (filterData) => (dispatch) => {
    dispatch(installersActions[FETCH_INSTALLERS]());

    const {
        executiveName,
        orderBy,
        page = 1,
        pageSize,
        product,
        searchText,
        sortBy,
        status,
    } = filterData;

    filterFinancierInstallers({
        executiveName,
        orderBy,
        page: page + 1,
        perPage: pageSize,
        product: product === 'all' ? '' : product,
        searchText,
        sortBy,
        status: status === 'all' ? '' : status,
    })
        .then((response) =>
            dispatch(installersActions[FETCH_INSTALLERS_SUCCESS](response.data))
        )
        .catch((error) =>
            dispatch(
                installersActions[FETCH_INSTALLERS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
