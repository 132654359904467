import { styled } from '@mui/material/styles';

export default styled('div')`
    background: #fff;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    width: 100%;
`;
