import renderAPI from '../../renderAPI';
const ENTITY = 'render';

export const getPdfFile = (data) =>
    renderAPI.post(
        `/api/v1/${ENTITY}/template`,
        { ...data },
        {
            responseType: 'application/json',
        }
    );
