import React from 'react';

import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Switch } from 'sunwise-ui';

import DropdownCardMenu from 'common/components/DropdownCardMenu';

const ActionsFormatter = ({
    handleChangeSwitch,
    handleClickUpdate,
    row,
    session,
}) => {
    const { t } = useTranslation();
    const sessionId = get(session, 'id', null);
    const { id, is_enabled } = row;

    const renderSwitch = () => {
        if (sessionId === id) return <LockIcon />;

        return (
            <Switch
                onChange={() => handleChangeSwitch(row)}
                checked={is_enabled}
            />
        );
    };

    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'flex',
                gap: '4px',
                justifyContent: 'end',
                width: '100%',
            }}
        >
            {renderSwitch()}
            <DropdownCardMenu
                items={[
                    {
                        handleClick: () => handleClickUpdate(row),
                        icon: <EditIcon fontSize="small" />,
                        label: t('Edit'),
                    },
                ]}
            />
        </Box>
    );
};

ActionsFormatter.propTypes = {
    handleChangeSwitch: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    row: PropTypes.object,
    session: PropTypes.object,
};

export default ActionsFormatter;
