import React from 'react';

import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { BottomActions, Box, Button } from 'sunwise-ui';

import ReactHookFormInput from 'common/components/form/rhf/ReactHookFormInput';
import ShowErrors from 'common/components/ShowErrors';
import yupResolver from 'common/utils/yupResolver';

import * as actions from '../actions';
import * as selectors from '../selectors';
import validate from '../validate';

const DataForm = ({ errors, handleClickSave, initialValues, isSaving }) => {
    const { t } = useTranslation();
    const { control, handleSubmit } = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(validate),
    });

    return (
        <form onSubmit={handleSubmit(handleClickSave)}>
            <ReactHookFormInput
                autoComplete="off"
                control={control}
                label={t('Current password')}
                name="old_password"
                type="password"
            />

            <ReactHookFormInput
                autoComplete="off"
                control={control}
                label={t('Password')}
                name="new_password"
                type="password"
            />

            <ReactHookFormInput
                autoComplete="off"
                control={control}
                label={t('Confirm Password')}
                name="confirm_password"
                type="password"
            />

            <ShowErrors errors={errors} />

            <BottomActions>
                <Box sx={{ pt: 2, textAlign: 'right' }}>
                    <Button disabled={isSaving} type="submit">
                        {t('Save')}
                    </Button>
                </Box>
            </BottomActions>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickSave: (values) => dispatch(actions.save(values)),
});

DataForm.propTypes = {
    errors: PropTypes.object,
    handleClickSave: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataForm);
