import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getFetchCompanyModel = createSelector(
    getModel,
    (model) => model.fetchCompany
);

export const getIsFetchingCompanyData = createSelector(
    getFetchCompanyModel,
    (fetch) => fetch.isFetching
);

export const getFetchCompanyData = createSelector(
    getFetchCompanyModel,
    (fetch) => fetch.data || []
);

export const getFetchCompanyDataErrors = createSelector(
    getFetchCompanyModel,
    (fetch) => fetch.errors
);

export const getSaveCompanyModel = createSelector(
    getModel,
    (model) => model.saveCompany
);

export const getIsSavingCompany = createSelector(
    getSaveCompanyModel,
    (save) => save.isSaving
);

export const getSaveCompanyErrors = createSelector(
    getSaveCompanyModel,
    (save) => save.errors
);
