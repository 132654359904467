import alerts from 'common/modules/alerts';

import unlinkAlliance from './unlinkAlliance';

export default (filterData, item) => (dispatch) => {
    const dataAlert = {
        confirmText: 'Aceptar',
        messages: [
            `¿Está seguro de desvincular alianza ${item?.installer?.business_name}?`,
            'El instalador desaparecerá del listado de Alianzas y no podrá usar los productos financieros en sus propuestas.',
        ],
        onSuccess: () =>
            dispatch(unlinkAlliance(filterData, item?.installer?.id)),
        title: 'Confirmación',
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'warning',
    };

    dispatch(alerts.actions.show(dataAlert));
};
