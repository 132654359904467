import get from 'lodash/get';

import { getInteractiveProposalContent } from 'common/api/sunpal/interactiveProposal';

import {
    FETCH_INTERACTIVE_PROPOSAL_CONTENT,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE,
    FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS,
} from '../actionTypes';
import { interactiveProposalPreviewActions } from '../reducer';

export default (id, initTemplate) => (dispatch) =>
    new Promise((resolve) => {
        dispatch(
            interactiveProposalPreviewActions[
                FETCH_INTERACTIVE_PROPOSAL_CONTENT
            ]()
        );

        getInteractiveProposalContent(id)
            .then((response) => {
                const data = get(response, 'data', {});
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_INTERACTIVE_PROPOSAL_CONTENT_SUCCESS
                    ](data)
                );
                const documentContent = get(data, 'content_str', '{}');
                initTemplate(JSON.parse(documentContent));
            })
            .catch((error) =>
                dispatch(
                    interactiveProposalPreviewActions[
                        FETCH_INTERACTIVE_PROPOSAL_CONTENT_FAILURE
                    ](error?.response?.data?.errors)
                )
            )
            .finally(resolve);
    });
