import i18next from 'i18next';
import is from 'is_js';
import isNull from 'lodash/isNull';

export default (values = {}) => {
    const errors = {};
    if (isNull(values.name) || is.empty(values.name))
        errors.name = i18next.t('Name cannot be empty');
    return errors;
};
