import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

/* Solicitudes */
export const getCreditsModel = createSelector(
    getModel,
    (model) => model.credits
);

export const getCreditsData = createSelector(
    getCreditsModel,
    (credits) => credits?.data?.data
);

export const getCreditsErrors = createSelector(
    getCreditsModel,
    (model) => model.errors
);

export const getCreditsIsFetching = createSelector(
    getCreditsModel,
    (model) => model.isFetching
);

export const getCreditsPagination = createSelector(
    getCreditsModel,
    (model) => model.data.pagination || {}
);

/* Alliances */
export const getAlliancesModel = createSelector(
    getModel,
    (model) => model.alliances
);

export const getAlliancesErrors = createSelector(
    getAlliancesModel,
    (model) => model.errors
);

export const getIsFetchingAlliances = createSelector(
    getAlliancesModel,
    (model) => model.isFetching
);

export const getDataAlliances = createSelector(
    getAlliancesModel,
    (model) => model.data || []
);

export const getInitialChangeStatusValues = createSelector(
    getModel,
    (model) => model.initialChangeStatusValues
);

export const getInitialReassignAdvisorValues = createSelector(
    getModel,
    (model) => model.initialReassignAdvisorValues
);

export const getIsOpenModalChangeStatus = createSelector(
    getModel,
    (model) => model.isOpenModalChangeStatus
);

export const getIsopenModalReassignAdvisor = createSelector(
    getModel,
    (model) => model.isOpenModalReassingAdvisor
);

export const getSaveStatusChange = createSelector(
    getModel,
    (model) => model.saveBulkStatus
);
export const getIsSavingStatusChange = createSelector(
    getSaveStatusChange,
    (value) => value.isSaving
);
export const getSaveStatusChangeErrors = createSelector(
    getSaveStatusChange,
    (model) => model.errors
);

export const getsaveReassignAdvisor = createSelector(
    getModel,
    (model) => model.saveReassignAdvisor
);
export const getIsSavingMargin = createSelector(
    getsaveReassignAdvisor,
    (value) => value.isSaving
);
export const getsaveReassignAdvisorErrors = createSelector(
    getsaveReassignAdvisor,
    (model) => model.errors
);

export const getIsDownloading = createSelector(
    getModel,
    (model) => model.isDownloading
);
