import initializeForm from './initializeForm';
import toggleModalContact from './toggleModalContact';

export default (item) => (dispatch) => {
    const values = {
        business_name: item.business_name,
        emails: item.emails,
        first_lastname: item.first_lastname,
        name: item.name,
        second_lastname: item.second_lastname,
        telephones: item.telephones,
    };

    dispatch(initializeForm(values));
    dispatch(toggleModalContact(true));
};
