// Categories
export const ALLIANCE_DOCUMENTS_CATEGORY = 0;
export const PRODUCT_DOCUMENTS_CATEGORY = 1;

// Document Types
export const REQUEST_FORM_TYPE = 0;
export const DOCUMENTS_TYPE = 1;
export const CONTRACT_TYPE = 2;
export const PRIVATE_DOCUMENTS_TYPE = 3;
export const INSURANCE_DOCUMENTS_TYPE = 4;
export const TERMS_AND_CONDITIONS_TYPE = -1;
export const FILE_FOR_DOCUMENT_TYPES = [
    CONTRACT_TYPE,
    INSURANCE_DOCUMENTS_TYPE,
    REQUEST_FORM_TYPE,
];

// Product Types
export const SIMPLE_TYPE = 0;
export const FILE_COMPLEX_TYPE = 1;
export const FILE_SIMPLE_TYPE = 2;
export const INFONAVIT_TYPE = 3;
export const INSURANCE_TYPE = 4;

export const INFINITY = 100000000;
export const MAX_FILE_SIZE = 10485760;

export const CAN_CREATE = 4;
export const CAN_UPDATE = 2;
export const CAN_DELETE = 1;

export const DEFAULT_ALGORITHM_TYPES = [
    {
        label: 'Crédito Simple',
        value: 'static_payment',
    },
    {
        label: 'Arrendamiento financiero',
        value: 'static_payment_tax',
    },
];

export const DEFAULT_PRODUCT_TYPES = [
    { label: 'Público', value: 'public' },
    { label: 'Especial', value: 'special' },
];

export const DEFAULT_RATES = [
    { label: '1', value: '1' },
    { label: '1A', value: '1A' },
    { label: '1B', value: '1B' },
    { label: '1C', value: '1C' },
    { label: '1D', value: '1D' },
    { label: '1E', value: '1E' },
    { label: '1F', value: '1F' },
    { label: 'DAC', value: 'DAC' },
    { label: 'PDBT', value: 'PDBT' },
    { label: 'GDBT', value: 'GDBT' },
    { label: 'GDMTO', value: 'GDMTO' },
    { label: 'GDMTH', value: 'GDMTH' },
];

export const DEFAULT_TERMS = [
    { label: '6', value: '6' },
    { label: '12', value: '12' },
    { label: '18', value: '18' },
    { label: '24', value: '24' },
    { label: '36', value: '36' },
    { label: '48', value: '48' },
    { label: '60', value: '60' },
    { label: '72', value: '72' },
    { label: '84', value: '84' },
    { label: '96', value: '96' },
    { label: '108', value: '108' },
    { label: '120', value: '120' },
    { label: '132', value: '132' },
    { label: '144', value: '144' },
    { label: '180', value: '180' },
    { label: '240', value: '240' },
];

export const DEFAULT_TYPES_BUSINESS = [
    { label: 'Persona física', value: 'physical_person' },
    {
        label: 'Persona física con actividad',
        value: 'person_company',
    },
    { label: 'Persona moral', value: 'company' },
];

// Status
export const APPROVED_STATUS = 'Aprobado';
export const CANCELLED_STATUS = 'Cancelado';
export const CLOSED_STATUS = 'Cerrado';
export const CONDITIONED_APPROVED_STATUS = 'Aprobado condicionado';
export const GRANTED_STATUS = 'Otorgado';
export const INCOMPLETE_STATUS = 'Incompleto';
export const NO_DOCUMENT_STATUS = 'Sin documento';
export const PREAPPROVED_STATUS = 'Preaprobado';
export const PROCESS_STATUS = 'Proceso';
export const QUOTED_STATUS = 'Cotizado';
export const REJECTED_STATUS = 'Rechazado';
export const REQUESTED_STATUS = 'Solicitado';
export const DOWNLOAD_STATUS = 'Descargar';

// Status text
export const ENABLED_STATUS = 'Habilitado';
export const PREVIABILITY_STATUS = 'Pre-viabilidad';
export const PRE_APPROVED_STATUS = 'Pre-aprobado';

export const DOCUMENT_STATUS = {
    APPROVED_STATUS: { key: 'Approved', value: 'Aprobado', color: '#54c461' },
    DOWNLOAD_STATUS: { key: 'Download', value: 'Download' },
    INPROCESS_STATUS: { key: 'Process', value: 'Proceso', color: '#1a5459' },
    NO_DOCUMENT_STATUS: {
        key: 'No document',
        value: 'Adjuntar',
    },
    REJECTED_STATUS: { key: 'Rejected', value: 'Rechazado', color: '#d24649' },
    REQUESTED_STATUS: {
        key: 'Requested',
        value: 'Solicitado',
        color: '#fc983a',
    },
};

export const BUREAU_STATUS = {
    NOT_AUTHORIZED: { key: 'not_authorized', value: 'Not authorized' },
    NOT_FOUND: { key: 'not_found', value: 'Not found' },
    PASSED: { key: 'passed', value: 'Passed' },
    PENDING_CLARIFICATION: {
        key: 'pending_clarification',
        value: 'Pending clarification',
    },
    REFUSED: { key: 'refused', value: 'Rejected' },
    REQUESTED: { key: 'requested', value: 'Requested' },
};

export const DEFAULT_LAT = 19.43258226220417;
export const DEFAULT_LNG = -99.13319580978921;
export const DEFAULT_MAP_PREVIEW_ZOOM = 12;

export const FILE_ALLOWED_EXTENSIONS = {
    CSV: '.csv,text/csv,application/vnd.ms-excel',
    IMAGE: 'image/png,image/jpeg,image/jpg',
    PDF: '.pdf,application/pdf',
};

export const MAX_SMALL_FILE_SIZE = 2097152;

export const HIIDDEN_FOOTER_ROUTES = [
    '/credits/:creditId/preview/:uid',
    '/funnel',
    '/templates/edition',
    '/templates/interactive',
];

export const URL_TYPES = {
    LISA: 1,
    SUNWISE: 0,
};

export const CI_BANCO_UID = '366508fb-e833-4b04-b569-744bd6589dcd';
export const SERFIMEX_UID = '1c17991f-e30e-4d7e-ae5b-e01bcccfd83e';
