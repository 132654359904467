import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from 'sunwise-ui';

import * as actions from './actions';
import { Buttons, TextCaptcha, TextResponse } from './components';
import * as selectors from './selectors';

const Container = ({
    cancelText = '',
    confirmText = '',
    defaultTextResponse = '',
    disabled,
    handleClickCancel,
    handleClickSuccess,
    handleReset,
    haveTextCaptcha = false,
    haveTextResponse = false,
    hideButtons,
    isOpen = false,
    messages,
    onEnter,
    onExited,
    size = 'xs',
    textToValidate = '',
    title,
    type,
    variant,
}) => {
    const { t } = useTranslation();
    const [isValidCaptcha, setIsValidCaptcha] = useState(false);
    const [textResponse, setTextResponse] = useState('');

    const renderTextCaptcha = () => {
        if (!haveTextCaptcha) return null;
        return (
            <TextCaptcha
                textToValidate={textToValidate}
                isValid={isValidCaptcha}
                onValidate={setIsValidCaptcha}
            />
        );
    };

    const renderTextResponse = () => {
        if (!haveTextResponse) return null;
        return (
            <TextResponse
                defaultTextResponse={defaultTextResponse}
                textResponse={textResponse}
                onSetTextResponse={setTextResponse}
            />
        );
    };

    const isDisabledConfirmButton = () => {
        if (haveTextCaptcha && !isValidCaptcha) return true;
        if (
            haveTextResponse &&
            (!textResponse || textResponse.trim().length === 0)
        )
            return true;
        return false;
    };

    const handleOnEnter = () => {
        if (onEnter) onEnter();
    };

    const handleOnExited = () => {
        if (onExited) onExited();
        if (handleReset) handleReset();
    };

    return (
        <Dialog
            onClose={handleClickCancel}
            open={isOpen === null ? false : isOpen}
            size={size}
            title={title || t('Alert')}
            TransitionProps={{
                onEnter: handleOnEnter,
                onExited: handleOnExited,
            }}
        >
            <DialogTitle backgroundColor={`${variant}.main`}>
                {title}
            </DialogTitle>

            <DialogContent dividers sx={{ minHeight: 'auto' }}>
                <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                    sx={{ fontSize: '14px', fontWeight: 500 }}
                >
                    {Array.isArray(messages) &&
                        messages.map((message, i) => (
                            <div key={`message-alert-${i}`}>{message}</div>
                        ))}
                </Box>
                {renderTextCaptcha()}
                {renderTextResponse()}
            </DialogContent>

            {!hideButtons && (
                <DialogActions>
                    <Buttons
                        cancelText={cancelText}
                        confirmText={confirmText}
                        disabledBackButton={disabled}
                        disabledConfirmButton={
                            isDisabledConfirmButton() || disabled
                        }
                        handleClickCancel={() =>
                            handleClickCancel(haveTextResponse && textResponse)
                        }
                        handleClickSuccess={() =>
                            handleClickSuccess(haveTextResponse && textResponse)
                        }
                        type={type}
                    />
                </DialogActions>
            )}
        </Dialog>
    );
};

const mapStateToProps = createStructuredSelector({
    cancelText: selectors.getCancelText,
    classNameMessages: selectors.getClassNameMessages,
    confirmText: selectors.getConfirmText,
    defaultTextResponse: selectors.getDefaultTextResponse,
    disabled: selectors.getDisabled,
    haveTextCaptcha: selectors.getHaveTextCaptcha,
    haveTextResponse: selectors.getHaveTextResponse,
    hideButtons: selectors.getHideButtons,
    isOpen: selectors.getIsOpen,
    messages: selectors.getMessages,
    textToValidate: selectors.getTextToValidate,
    title: selectors.getTitle,
    type: selectors.getType,
    variant: selectors.getVariant,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickCancel: (response) => dispatch(actions.onCancel(response)),
    handleClickSuccess: (response) => dispatch(actions.onSuccess(response)),
    handleReset: () => dispatch(actions.reset()),
});

Container.propTypes = {
    cancelText: PropTypes.string,
    confirmText: PropTypes.string,
    defaultTextResponse: PropTypes.string,
    disabled: PropTypes.bool,
    handleClickCancel: PropTypes.func,
    handleClickSuccess: PropTypes.func,
    handleReset: PropTypes.func,
    haveTextCaptcha: PropTypes.bool,
    haveTextResponse: PropTypes.bool,
    hideButtons: PropTypes.bool,
    isOpen: PropTypes.bool,
    messages: PropTypes.array,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    size: PropTypes.string,
    textToValidate: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
