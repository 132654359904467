import { NAME } from './constants';

export const ACTIVATE_ITEM = `${NAME}/ACTIVATE_ITEM`;
export const ACTIVATE_ITEM_FAILURE = `${NAME}/ACTIVATE_ITEM_FAILURE`;
export const ACTIVATE_ITEM_SUCCESS = `${NAME}/ACTIVATE_ITEM_SUCCESS`;

export const DELETE_ITEM = `${NAME}/DELETE_ITEM`;
export const DELETE_ITEM_FAILURE = `${NAME}/DELETE_ITEM_FAILURE`;
export const DELETE_ITEM_SUCCESS = `${NAME}/DELETE_ITEM_SUCCESS`;

export const FETCH_COMPANY = `${NAME}/FETCH_COMPANY`;
export const FETCH_COMPANY_FAILURE = `${NAME}/FETCH_COMPANY_FAILURE`;
export const FETCH_COMPANY_SUCCESS = `${NAME}/FETCH_COMPANY_SUCCESS`;

export const FETCH_GROUPS = `${NAME}/FETCH_GROUPS`;
export const FETCH_GROUPS_FAILURE = `${NAME}/FETCH_GROUPS_FAILURE`;
export const FETCH_GROUPS_SUCCESS = `${NAME}/FETCH_GROUPS_SUCCESS`;

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS`;
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`;
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`;

export const FETCH_ITEMS_LIST = `${NAME}/FETCH_ITEMS_LIST`;
export const FETCH_ITEMS_LIST_FAILURE = `${NAME}/FETCH_ITEMS_LIST_FAILURE`;
export const FETCH_ITEMS_LIST_SUCCESS = `${NAME}/FETCH_ITEMS_LIST_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const INVALID_PASSWORD = `${NAME}/INVALID_PASSWORD`;
export const INVALID_PASSWORD_CONFIRMATION = `${NAME}/INVALID_PASSWORD_CONFIRMATION`;
export const INVALID_PASSWORD_CONFIRMATION_LENGTH = `${NAME}/INVALID_PASSWORD_CONFIRMATION_LENGTH`;

export const OPEN_MODAL_PRODUCT = `${NAME}/OPEN_MODAL_PRODUCT`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const RESTART_PASSWORD = `${NAME}/RESTART_PASSWORD`;
export const RESTART_PASSWORD_FAILURE = `${NAME}/RESTART_PASSWORD_FAILURE`;
export const RESTART_PASSWORD_SUCCESS = `${NAME}/RESTART_PASSWORD_SUCCESS`;

export const SAVE_ITEM = `${NAME}/SAVE_ITEM`;
export const SAVE_ITEM_FAILURE = `${NAME}/SAVE_ITEM_FAILURE`;
export const SAVE_ITEM_SUCCESS = `${NAME}/SAVE_ITEM_SUCCESS`;

export const SET_IS_NEW_RECORD = `${NAME}/SET_IS_NEW_RECORD`;

export const VALID_PASSWORD = `${NAME}/VALID_PASSWORD`;
export const VALID_PASSWORD_CONFIRMATION = `${NAME}/VALID_PASSWORD_CONFIRMATION`;
export const VALID_PASSWORD_CONFIRMATION_LENGTH = `${NAME}/VALID_PASSWORD_CONFIRMATION_LENGTH`;
