import React from 'react';

import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Card, FormControlLabel, Checkbox } from 'sunwise-ui';

import { CardFolderContent } from 'common/components/cards';

import folderImage from 'resources/folder.svg';

const ProductCardRedux = ({
    disabled,
    input: { onChange, value: selectedValue },
    product,
}) => {
    const theme = useTheme();
    const handleOnChange = (e) => {
        const value = Object.assign({}, selectedValue, {
            [e.target.value]: e.target.checked,
        });
        if (onChange) onChange(value);
    };
    const { id, name } = product;
    const sx = {
        color:
            theme.palette.mode === 'dark'
                ? '#fff'
                : theme.palette.primary.contrastText,
    };
    const title = (
        <FormControlLabel
            control={
                <Checkbox
                    checked={selectedValue[id] == true}
                    disabled={disabled}
                    onChange={handleOnChange}
                    value={id}
                />
            }
            label={name}
            sx={sx}
        />
    );

    return (
        <label>
            <Card>
                <CardFolderContent image={folderImage} title={title} />
            </Card>
        </label>
    );
};

ProductCardRedux.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object,
    product: PropTypes.object,
};

export default ProductCardRedux;
