import React, { useEffect, useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Avatar, Box, Button, Divider, Grid, Typography } from 'sunwise-ui';

import { StyledH2 } from 'common/components';
import { ReduxFieldInput } from 'common/components/form/redux';
import { parseDate, formatDate } from 'common/utils/dates';

import * as actions from './actions';
import { NAME } from './constants';
import * as selectors from './selectors';

const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Section = styled('div')`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;
    padding: 0 15px;
`;
const ScrollableContent = styled('div')`
    flex-grow: 1;
    overflow: auto;
    min-height: 0;
`;

const Comment = styled('div')`
    display: flex;
    margin-bottom: 15px;

    picture {
        align-items: center;
        border-radius: 100%;
        display: flex;
        height: 35px;
        overflow: hidden;
        width: 35px;

        img {
            width: 100%;
        }
    }

    ${({ variant }) =>
        variant === 'self' &&
        `flex-direction: row-reverse;

        .role-name {
            text-align: right;
        }`}
`;

const Content = styled('div')`
    padding: 0 16px;
    flex-grow: 1;
`;

const CommentSubmit = styled('form')`
    padding-bottom: 30px;
    position: relative;

    .form-control {
        border-radius: 20px;
        padding-right: 110px;
    }

    button {
        height: 36px;
        position: absolute;
        right: 1px;
        top: 1px;
        width: 106px;
        z-index: 100;
    }
`;

const Container = ({
    callback,
    description,
    documentId,
    documentType,
    fetchMessages,
    handleOnClickSend,
    handleSubmit,
    isFetching,
    messages,
    title,
    user,
    userType,
}) => {
    const { t } = useTranslation();
    const handleOnClickSubmit = (values) => handleOnClickSend(values, callback);

    const messagesEndRef = useRef(null);

    const scrollToBottom = () =>
        messagesEndRef.current.scrollTo(0, messagesEndRef.current.scrollHeight);

    useEffect(() => {
        fetchMessages({ documentId, documentType, user, userType });
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <Wrapper>
            <Section>
                <StyledH2>{title}</StyledH2>
                <Typography>{description}</Typography>

                <Divider />

                <ScrollableContent ref={messagesEndRef}>
                    {messages.map((comment) => (
                        <Comment key={`comment-${comment.id}`}>
                            <Avatar src={comment.image} />

                            <Content>
                                <div className="role-name">
                                    <Grid container>
                                        <Grid item xs>
                                            <StyledH2 size="sm">
                                                {comment.username}&nbsp;-&nbsp;
                                                <small>
                                                    ({comment.name}&nbsp;-&nbsp;
                                                    {comment.role})
                                                </small>
                                            </StyledH2>
                                        </Grid>
                                        <Grid item>
                                            <small>
                                                {formatDate(
                                                    parseDate(
                                                        comment.created_at,
                                                        'dd/MM/yyyy HH:mm:ss'
                                                    ),
                                                    'dd/MM/yyyy hh:mm:ss'
                                                )}
                                            </small>
                                        </Grid>
                                    </Grid>
                                </div>

                                <Box>
                                    {comment.isReject && (
                                        <React.Fragment>
                                            <div className="title">
                                                <Button
                                                    as="span"
                                                    color="secondary"
                                                >
                                                    <CloseIcon />
                                                </Button>{' '}
                                                {t('Reason for rejection')}
                                            </div>

                                            {comment.reasons.map(
                                                (item, index) => (
                                                    <Button
                                                        as="div"
                                                        key={`reject-reason-${index}`}
                                                    >
                                                        {item}
                                                    </Button>
                                                )
                                            )}
                                        </React.Fragment>
                                    )}

                                    <div>{comment.message}</div>
                                </Box>
                            </Content>
                        </Comment>
                    ))}
                </ScrollableContent>

                <CommentSubmit onSubmit={handleSubmit(handleOnClickSubmit)}>
                    <Field
                        component={ReduxFieldInput}
                        name="message"
                        label={t('Add comment')}
                    />

                    <Button disabled={isFetching} type="submit" variant="text">
                        <SendIcon />
                    </Button>
                </CommentSubmit>
            </Section>
        </Wrapper>
    );
};

Container.propTypes = {
    callback: PropTypes.func,
    description: PropTypes.string,
    documentId: PropTypes.string,
    documentType: PropTypes.string,
    fetchMessages: PropTypes.func,
    handleOnClickSend: PropTypes.func,
    handleSubmit: PropTypes.func,
    isFetching: PropTypes.bool,
    messages: PropTypes.array,
    title: PropTypes.string,
    user: PropTypes.object,
    userType: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
    isFetching: selectors.getSendIsFetching,
    messages: selectors.getMessages,
});

const mapDispatchToProps = (dispatch) => ({
    fetchMessages: (values) => dispatch(actions.fetchMessages(values)),
    handleOnClickSend: (values, callback) =>
        dispatch(actions.sendMessage(values, callback)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form`,
})(Container);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
