import React, { useState } from 'react';

import PhoneIcon from '@mui/icons-material/Phone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import { StyledFigure } from 'common/components';

const CollapseContainer = styled('div')`
    display: flex;
    flex-direction: column;
    max-height: ${({ isVisible }) => (isVisible ? '88px' : '0')};
    overflow: hidden;
    transition: max-height 0.25s linear;
`;

const CompanyInformationCard = ({
    alliance,
    business_name,
    canModify,
    canModifyInstaller,
    canViewInstaller,
    image = 'https://via.placeholder.com/66x66.png',
    legal_representative,
    name,
    phone,
    rfc,
    rightColumnComponent,
    web_page,
}) => {
    const { t } = useTranslation();
    const [showData, setShowData] = useState(false);

    return (
        <Card>
            <Card.Header>
                <Grid container alignItems="center">
                    <Grid item>
                        <StyledFigure src={image} />
                    </Grid>

                    <Grid item xs>
                        <Typography fontWeight="bold" variant="body2">
                            {name}
                        </Typography>

                        <Typography variant="body2">{business_name}</Typography>
                    </Grid>

                    {rightColumnComponent && (
                        <Grid hidden={!canModify} item>
                            {rightColumnComponent}
                        </Grid>
                    )}

                    {alliance && (canModifyInstaller || canViewInstaller) && (
                        <Grid sx={{ textAlign: 'right' }}>
                            <Tooltip title={t('View installer')}>
                                <IconButton
                                    component={Link}
                                    to={`/installer-profile/${alliance.id}`}
                                >
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Card.Header>

            <List>
                <ListItem>
                    <Grid container>
                        <Grid
                            alignItems="center"
                            display="flex"
                            gap={1}
                            item
                            xs
                        >
                            <PhoneIcon fontSize="small" />

                            <Typography variant="body2">
                                {phone || t('No registered phone')}
                            </Typography>
                        </Grid>

                        <Grid item xs>
                            <Typography variant="body2">{web_page}</Typography>
                        </Grid>
                    </Grid>
                </ListItem>

                <CollapseContainer isVisible={showData}>
                    <ListItem>
                        <Box alignItems="center" display="flex" gap={1}>
                            <Typography fontWeight="bold" variant="body2">
                                {t('Tax ID')}:
                            </Typography>

                            <Typography variant="body2">
                                {rfc || '- - -'}
                            </Typography>
                        </Box>
                    </ListItem>

                    <ListItem>
                        <Box alignItems="center" display="flex" gap={1}>
                            <Typography fontWeight="bold" variant="body2">
                                {t('Legal representative')}:
                            </Typography>

                            <Typography variant="body2">
                                {legal_representative || '- - -'}
                            </Typography>
                        </Box>
                    </ListItem>
                </CollapseContainer>
            </List>

            <Divider />

            <Card.Body sx={{ textAlign: 'center' }}>
                <Button
                    color="secondary"
                    onClick={() => setShowData(!showData)}
                    variant="text"
                >
                    {showData ? t('Hide Information') : t('Show Information')}
                </Button>
            </Card.Body>
        </Card>
    );
};

CompanyInformationCard.propTypes = {
    alliance: PropTypes.object,
    business_name: PropTypes.string,
    canModify: PropTypes.bool,
    canModifyInstaller: PropTypes.bool,
    canViewInstaller: PropTypes.bool,
    image: PropTypes.string,
    legal_representative: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    rfc: PropTypes.string,
    rightColumnComponent: PropTypes.node,
    web_page: PropTypes.string,
};

export default CompanyInformationCard;
