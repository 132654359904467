import { TERMS_AND_CONDITIONS_TYPE } from 'common/constants';

import initializeForm from './initializeForm';
import initializeFormTerms from './initializeFormTerms';
import setIsNewRecord from './setIsNewRecord';
import toggleModal from './toggleModal';

export default (item, type) => (dispatch) => {
    const init =
        type?.type === TERMS_AND_CONDITIONS_TYPE
            ? initializeFormTerms
            : initializeForm;

    dispatch(init(item));
    dispatch(setIsNewRecord(false));
    dispatch(toggleModal(true));
};
