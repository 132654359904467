import { NAME } from './constants';

export const BRANCH_OFFICES_DICTIONARY_BACKUP = `${NAME}/BRANCH_OFFICES_DICTIONARY_BACKUP`;

export const FETCH_ITEMS = `${NAME}/FETCH_ITEMS`;
export const FETCH_ITEMS_FAILURE = `${NAME}/FETCH_ITEMS_FAILURE`;
export const FETCH_ITEMS_SUCCESS = `${NAME}/FETCH_ITEMS_SUCCESS`;

export const FETCH_NEXT_PAGE = `${NAME}/FETCH_NEXT_PAGE`;
export const FETCH_NEXT_PAGE_FAILURE = `${NAME}/FETCH_NEXT_PAGE_FAILURE`;
export const FETCH_NEXT_PAGE_SUCCESS = `${NAME}/FETCH_NEXT_PAGE_SUCCESS`;

export const UPDATE_COLUMNS = `${NAME}/UPDATE_COLUMNS`;
export const UPDATE_COLUMNS_FAILURE = `${NAME}/UPDATE_COLUMNS_FAILURE`;
export const UPDATE_COLUMNS_SUCCESS = `${NAME}/UPDATE_COLUMNS_SUCCESS`;

export const FETCH_EXECUTIVES = `${NAME}/FETCH_EXECUTIVES`;
export const FETCH_EXECUTIVES_FAILURE = `${NAME}/FETCH_EXECUTIVES_FAILURE`;
export const FETCH_EXECUTIVES_SUCCESS = `${NAME}/FETCH_EXECUTIVES_SUCCESS`;

export const FETCH_COMPANIES = `${NAME}/FETCH_COMPANIES`;
export const FETCH_COMPANIES_FAILURE = `${NAME}/FETCH_COMPANIES_FAILURE`;
export const FETCH_COMPANIES_SUCCESS = `${NAME}/FETCH_COMPANIES_SUCCESS`;

export const RESET_VALUES = `${NAME}/RESET_VALUES`;

export const SELECT_FILTER_ITEM = `${NAME}/SELECT_FILTER_ITEM`;
