import { changeStatusAlliance } from 'common/api/financierInstallers';
import { CANCELLED_STATUS } from 'common/constants';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    REJECT_ALLIANCE,
    REJECT_ALLIANCE_FAILURE,
    REJECT_ALLIANCE_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (id, callback) => (dispatch) => {
    const newValues = { status: CANCELLED_STATUS };

    dispatch(installerProfileActions[REJECT_ALLIANCE]());

    changeStatusAlliance(id, newValues)
        .then(() => {
            dispatch(installerProfileActions[REJECT_ALLIANCE_SUCCESS]());
            if (callback) callback();
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch(
                installerProfileActions[REJECT_ALLIANCE_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
