import {
    saveInsuranceConfig,
    updateInsuranceConfig,
} from 'common/api/products';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_COVERAGE_CONFIG,
    SAVE_COVERAGE_CONFIG_FAILURE,
    SAVE_COVERAGE_CONFIG_SUCCESS,
} from '../actionTypes';
import { configurationCoveragesActions } from '../reducer';

import fetchCoverages from './fetchCoverages';
import resetCoverageConfigForm from './resetCoverageConfigForm';
import toggleCoverageConfigModal from './toggleCoverageConfigModal';

export default (filterData, values, setSelectedCoverage) => (dispatch) => {
    let newValues = {
        amount: values.amount,
        annual_increase: values.annual_increase,
        description: values.description,
        insurance_product: values.insurance_product,
        tax_included: values.tax_included,
        tax_type: values.tax_type,
        tax_value: values.tax_value,
        type_cost: values.type_cost,
        value_type: values.value_type,
        visible: true,
    };

    if (values.id) newValues.id = values.id;

    const action =
        values.id == null ? saveInsuranceConfig : updateInsuranceConfig;

    dispatch(configurationCoveragesActions[SAVE_COVERAGE_CONFIG]());

    action(newValues)
        .then((response) => {
            dispatch(
                configurationCoveragesActions[SAVE_COVERAGE_CONFIG_SUCCESS](
                    response.data
                )
            );
            dispatch(
                fetchCoverages(filterData, (coverages) => {
                    const filtered = coverages.filter(
                        (item) => item.id === values.insurance_product
                    );
                    setSelectedCoverage(filtered[0]);
                })
            );
            dispatch(toggleCoverageConfigModal(false));
            dispatch(resetCoverageConfigForm());
            showToast();
        })
        .catch((error) => {
            dispatch(
                configurationCoveragesActions[SAVE_COVERAGE_CONFIG_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
