import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import deleteLocation from './deleteLocation';

export default (id, creditId) => (dispatch) => {
    const dataAlert = {
        confirmText: i18next.t('Delete'),
        messages: [
            i18next.t('Are you sure to remove location from the project?'),
        ],
        onSuccess: () => dispatch(deleteLocation(id, creditId)),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'error',
    };

    dispatch(alerts.actions.show(dataAlert));
};
