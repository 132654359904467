import { getInstaller } from 'common/api/installers';

import {
    FETCH_INSTALLER,
    FETCH_INSTALLER_FAILURE,
    FETCH_INSTALLER_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(installerProfileActions[FETCH_INSTALLER]());

    getInstaller(id)
        .then((response) =>
            dispatch(
                installerProfileActions[FETCH_INSTALLER_SUCCESS](response.data)
            )
        )
        .catch((error) =>
            dispatch(
                installerProfileActions[FETCH_INSTALLER_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
