import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Button, Grid } from 'sunwise-ui';

import ReduxFieldInput from 'common/components/form/redux/ReduxFieldInput';
import ShowErrors from 'common/components/ShowErrors';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const DataForm = ({ errors, handleClickSave, handleSubmit, isSaving }) => {
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit(handleClickSave)}>
            <Grid container>
                <Grid item xs>
                    <Field
                        autoComplete="off"
                        component={ReduxFieldInput}
                        label={t('Password')}
                        name="new_password"
                        placeholder={t('Password')}
                        type="password"
                    />

                    <Field
                        autoComplete="off"
                        component={ReduxFieldInput}
                        label={t('Confirm Password')}
                        name="confirm_password"
                        placeholder={t('Confirm Password')}
                        type="password"
                    />
                </Grid>
            </Grid>

            <ShowErrors errors={errors} />

            <Grid container>
                <Grid item xs textAlign="right">
                    <Button disabled={isSaving} type="submit">
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSave,
    initialValues: selectors.getInitialValues,
    isSaving: selectors.getIsSaving,
});

const mapDispatchToProps = (dispatch) => ({
    handleClickSave: (values) => dispatch(actions.save(values)),
});

DataForm.propTypes = {
    errors: PropTypes.array,
    handleClickSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    isSaving: PropTypes.bool,
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/reset-password-form`,
    validate,
})(DataForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
