import { createSlice } from '@reduxjs/toolkit';

import {
    ADD_MESSAGE,
    FETCH_MESSAGES,
    FETCH_MESSAGES_FAILURE,
    FETCH_MESSAGES_SUCCESS,
    INITIALIZE_FORM,
    RESET_FORM,
    SEND_MESSAGE,
    SEND_MESSAGE_FAILURE,
    SEND_MESSAGE_SUCCESS,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    initialValues: {
        chatId: null,
        message: '',
        message_from: null,
        user: null,
    },
    messages: { data: { messages: [] }, errors: null, isFetching: false },
    send: { data: {}, errors: null, isFetching: false },
};

const commentAreaSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [ADD_MESSAGE]: (state, action) => {
            state.messages.data.messages.push(action.payload);
        },
        [FETCH_MESSAGES]: (state) => {
            state.messages.isFetching = true;
        },
        [FETCH_MESSAGES_FAILURE]: (state, action) => {
            state.messages = {
                ...state.messages,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_MESSAGES_SUCCESS]: (state, action) => {
            state.messages = {
                ...state.messages,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [RESET_FORM]: (state) => {
            state.initialValues = INITIAL_STATE.initialValues;
        },
        [SEND_MESSAGE]: (state) => {
            state.send.isFetching = true;
        },
        [SEND_MESSAGE_FAILURE]: (state, action) => {
            state.send = {
                ...state.send,
                errors: action.payload,
                isFetching: false,
            };
        },
        [SEND_MESSAGE_SUCCESS]: (state, action) => {
            state.send = {
                ...state.send,
                data: action.payload,
                isFetching: false,
            };
        },
    },
});

export const commentAreaActions = commentAreaSlice.actions;

export default commentAreaSlice.reducer;
