import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialAssignProductValues = createSelector(
    getModel,
    (model) => model.initialAssignProductValues
);

/* Executives */
export const getExecutivesModel = createSelector(
    getModel,
    (model) => model.executives
);

export const getIsFetchingExecutives = createSelector(
    getExecutivesModel,
    (model) => model.isFetching
);

export const getExecutivesData = createSelector(
    getExecutivesModel,
    (model) => model.data || []
);

/* Instalers */
export const getInstallersModel = createSelector(
    getModel,
    (model) => model.installers
);

export const getFetchInstallersData = createSelector(
    getInstallersModel,
    (model) => model.data.data
);

export const getInstallersErrors = createSelector(
    getInstallersModel,
    (model) => model.errors
);

export const getIsFetchingInstallers = createSelector(
    getInstallersModel,
    (model) => model.isFetching
);

export const getInstallersPagination = createSelector(
    getInstallersModel,
    (model) => model.data.pagination || {}
);

/* Financial products */
export const getFinancialProducts = createSelector(
    getModel,
    (model) => model.financialProducts
);

export const getFinancialProductsData = createSelector(
    getFinancialProducts,
    (model) => model.data.data
);

export const getFinancialProductsErrors = createSelector(
    getFinancialProducts,
    (model) => model.errors
);

export const getIsFetchingFinancialProducts = createSelector(
    getFinancialProducts,
    (model) => model.isFetching
);

export const getFinancialProductsPagination = createSelector(
    getFinancialProducts,
    (model) => model.data.pagination || {}
);

export const getIsDownloading = createSelector(
    getModel,
    (model) => model.isDownloading
);

/* Unlink Alliance */
export const getUnlinkAlliance = createSelector(
    getModel,
    (model) => model.unlinkAlliance
);

export const getIsFetchingUnlinkAlliance = createSelector(
    getUnlinkAlliance,
    (model) => model.isFetching
);

export const getUnlinkAllianceErrors = createSelector(
    getUnlinkAlliance,
    (model) => model.errors
);

export const getIsOpenAssignProductModal = createSelector(
    getModel,
    (model) => model.isOpenAssignProductModal
);

export const getSaveAssignProduct = createSelector(
    getModel,
    (model) => model.saveAssignProduct
);

export const getIsSavingAssignProduct = createSelector(
    getSaveAssignProduct,
    (value) => value.isSaving
);

export const getAssignProductErrors = createSelector(
    getSaveAssignProduct,
    (model) => model.errors
);

export const getAllFinancierProductsModel = createSelector(
    getModel,
    (model) => model.allFinancierProducts || {}
);

export const getAllFinancierProductsData = createSelector(
    getAllFinancierProductsModel,
    (model) => model.data || []
);
