import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isEqual, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, DataGrid } from 'sunwise-ui';

import CustomPagination from 'common/components/CustomPagination';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMN_KEYS, REQUIRED_COLUMNS_KEYS } from '../constants';

import BulkOperationsCard from './BulkOperationsCard';
import Columns from './Columns';

const DEBOUNCE_TIME = 1500;

const ListItems = ({
    alliances,
    canModify,
    downloadFile,
    filterData,
    filterItems,
    handleClickChangeStatus,
    handleClickReassignAdvisor,
    handleSaveRow,
    isFetching,
    isFetchingAlliances,
    isOpenModalChangeStatus,
    isOpenModalReassingAdvisor,
    items,
    paginationData,
    setFilterData,
    statusList,
    tableSettings,
    users,
}) => {
    const { t } = useTranslation();
    const [rowCount, setRowCount] = useState(paginationData?.rowCount || 0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [selectedBulkOption, setSelectedBulkOption] = useState('');
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    useEffect(() => {
        if (!isOpenModalReassingAdvisor) setSelectedBulkOption('');
        if (!isOpenModalChangeStatus) setSelectedBulkOption('');
    }, [isOpenModalChangeStatus, isOpenModalReassingAdvisor]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleClickAlliance = (value) => {
        setFilterData({
            ...filterData,
            allianceId: value,
        });
    };

    const handleClickStatus = (value) => {
        setFilterData({
            ...filterData,
            status: value,
        });
    };

    const handleClickUser = (value) => {
        setFilterData({
            ...filterData,
            financialAdvisor: value,
        });
    };

    const handleOnChangeSearch = (event) => {
        setFilterData({
            ...filterData,
            page: 0,
            searchText: event.target.value,
        });
    };

    const handleOnPageChange = (page) => {
        setFilterData({ ...filterData, page: page });
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData({
            ...filterData,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        });
    };

    const handleRowEditStart = (params, event) => {
        const id = params.row.id;
        if (isNull(selectedRowId)) setSelectedRowId(id);
        else event.defaultMuiPrevented = true;
        return false;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const processRowUpdate = (newRow) => {
        handleSaveRow(filterData, newRow);
        return newRow;
    };

    const components = {
        LoadingOverlay: LinearProgress,
        Pagination: CustomPagination,
    };
    const componentsProps = {
        pagination: {
            rowsPerPageOptions: [25, 50, 75, 100],
        },
    };
    const initialState = {
        pagination: { pageSize: filterData?.pageSize },
    };
    const columns = Columns({
        canModify,
        hiddenColumns: getHiddenColumns(tableSettings),
        orders: getOrderedColumns(tableSettings),
        rowModesModel,
        selectedRowId,
        setRowModesModel,
        setSelectedRowId,
        tableSettings,
    });
    const page = paginationData?.current_page - 1 || 0;
    const pageSize = filterData.pageSize;

    const prefix = t('{{count}} selected request', {
        count: selectionModel.length,
    });

    const sxDatagrid = {
        '& .MuiDataGrid-row--editing .MuiDataGrid-cell': {
            backgroundColor: 'rgba(255, 154, 0, 0.16)',
        },
        '& .MuiDataGrid-row--editing:hover': {
            backgroundColor: 'rgba(255, 154, 0, 0.08)',
        },
        '& .MuiInputBase-formControl input': {
            border: 0,
            boxShadow: 'none',
            outline: 'none',
        },
    };

    return (
        <>
            <BulkOperationsCard
                alliances={alliances}
                canModify={canModify}
                downloadFile={downloadFile}
                filterData={filterData}
                handleClickAlliance={handleClickAlliance}
                handleClickChangeStatus={handleClickChangeStatus}
                handleClickReassignAdvisor={handleClickReassignAdvisor}
                handleClickStatus={handleClickStatus}
                handleClickUser={handleClickUser}
                handleOnChangeSearch={handleOnChangeSearch}
                isFetchingAlliances={isFetchingAlliances}
                prefix={prefix}
                selectedBulkOption={selectedBulkOption}
                selectionModel={selectionModel}
                setSelectedBulkOption={setSelectedBulkOption}
                setSelectionModel={setSelectionModel}
                statusList={statusList}
                users={users}
            />

            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnKeys={COLUMN_KEYS}
                        tableKey="applications"
                        requiredColumnsKeys={REQUIRED_COLUMNS_KEYS}
                    />

                    <DataGrid
                        autoHeight
                        checkboxSelection={canModify}
                        columns={columns}
                        components={components}
                        componentsProps={componentsProps}
                        disableColumnMenu
                        disableSelectionOnClick
                        editMode="row"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowHeight={() => 'auto'}
                        initialState={initialState}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={(newPage) =>
                            setFilterData({ ...filterData, pageSize: newPage })
                        }
                        onRowEditStart={handleRowEditStart}
                        onRowEditStop={handleRowEditStop}
                        onRowModesModelChange={(newModel) =>
                            setRowModesModel(newModel)
                        }
                        onSelectionModelChange={(newModel) =>
                            setSelectionModel(newModel)
                        }
                        onSortModelChange={handleOnSortModelChange}
                        page={page}
                        pageSize={pageSize}
                        pagination
                        paginationMode="server"
                        processRowUpdate={processRowUpdate}
                        rowCount={rowCount}
                        rowModesModel={rowModesModel}
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="server"
                        sx={sxDatagrid}
                    />
                </Card.Body>
            </Card>
        </>
    );
};

ListItems.propTypes = {
    alliances: PropTypes.array,
    canModify: PropTypes.bool,
    downloadFile: PropTypes.func,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    handleClickChangeStatus: PropTypes.func,
    handleClickReassignAdvisor: PropTypes.func,
    handleSaveRow: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingAlliances: PropTypes.bool,
    isOpenModalChangeStatus: PropTypes.bool,
    isOpenModalReassingAdvisor: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    statusList: PropTypes.array,
    tableSettings: PropTypes.array,
    users: PropTypes.array,
};

export default ListItems;
