import { preApproveCreditApplicaton } from 'common/api/creditApplications';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    PRE_APPROVE_CREDIT,
    PRE_APPROVE_CREDIT_FAILURE,
    PRE_APPROVE_CREDIT_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (id, callback) => (dispatch) => {
    dispatch(creditFileActions[PRE_APPROVE_CREDIT]());

    preApproveCreditApplicaton(id)
        .then((response) => {
            dispatch(
                creditFileActions[PRE_APPROVE_CREDIT_SUCCESS](response.data)
            );
            if (callback) callback();
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch(
                creditFileActions[PRE_APPROVE_CREDIT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
