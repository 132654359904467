import { ContentTypeJSON } from 'common/utils/api';

import API from '..';

const ENTITY = 'products';

export const bulkAssignProducts = (data) =>
    API.post(
        `/api/v1/${ENTITY}/bulk-products-alliance/`,
        { ...data },
        ContentTypeJSON
    );
export const changeProductStatus = (productId, data) =>
    API.put(`/api/v1/${ENTITY}/financier-product/${productId}/change-status/`, {
        ...data,
    });
export const getFinancialProducts = () =>
    API.get(`/api/v1/${ENTITY}/financier-products/`);
export const getProductsList = (financierId, allianceId) =>
    API.get(
        `/api/v1/${ENTITY}/financiers-products/${financierId}/alliance/${allianceId}`
    );
export const getSelectedProducts = (allianceId) =>
    API.get(`/api/v1/${ENTITY}/financier-product-alliance/${allianceId}`);
export const saveSelectedProducts = (allianceId, data) =>
    API.put(`/api/v1/${ENTITY}/financier-product/alliance/${allianceId}`, {
        ...data,
    });

/** insurence */
export const filterInsurances = ({ status = 'active' } = {}) =>
    API.get(`/api/v1/${ENTITY}/insurance-product/`, {
        params: {
            status_flag: status,
        },
    });
export const deleteInsurance = (id) =>
    API.delete(`/api/v1/${ENTITY}/insurance-product/${id}`);
export const getInsurance = (id) =>
    API.get(`/api/v1/${ENTITY}/insurance-product/${id}`);
export const getInsurances = () =>
    API.get(`/api/v1/${ENTITY}/insurance-product/`);
export const saveInsurance = (data) =>
    API.post(`/api/v1/${ENTITY}/insurance-product/`, { ...data });
export const updateInsurance = (data) =>
    API.put(`/api/v1/${ENTITY}/insurance-product/${data.id}`, { ...data });

/** insurence config*/
export const deleteInsuranceConfig = (id) =>
    API.delete(`/api/v1/${ENTITY}/insurance-params/${id}`);
export const getInsuranceConfig = (id) =>
    API.get(`/api/v1/${ENTITY}/insurance-params/${id}`);
export const getInsuranceConfigs = () =>
    API.get(`/api/v1/${ENTITY}/insurance-params/`);
export const saveInsuranceConfig = (data) =>
    API.post(`/api/v1/${ENTITY}/insurance-params/`, { ...data });
export const updateInsuranceConfig = (data) =>
    API.put(`/api/v1/${ENTITY}/insurance-params/${data.id}`, { ...data });
