import React from 'react';

import PropTypes from 'prop-types';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
} from 'sunwise-ui';

const CheckboxButtonField = ({
    disabled,
    input: { name, onChange, value: selectedValue },
    items,
    meta: { error, touched },
}) => {
    const handleOnChange = (e) => {
        const value = Object.assign({}, selectedValue, {
            [e.target.value]: e.target.checked,
        });

        if (onChange) onChange(value);
    };

    return (
        <FormControl disabled={disabled} sx={{ mb: 2 }}>
            <FormGroup row>
                {items.map((item) => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedValue[item.value] == true}
                                disabled={disabled}
                                onChange={handleOnChange}
                                value={item.value}
                            />
                        }
                        key={`checkbox-${name}-${item.value}`}
                        label={item.label}
                    />
                ))}
            </FormGroup>
            {error && touched && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

CheckboxButtonField.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object,
    items: PropTypes.array,
    meta: PropTypes.object,
};

export default CheckboxButtonField;
