export const custom1 = `
    &::-webkit-scrollbar {
        height: 8px;
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background: #004167;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #004167;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: #004167;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track:hover,
    &::-webkit-scrollbar-track:active {
        background: transparent;
    }
`;
