import { number, object, string } from 'yup';

import getValidationTexts from 'common/utils/getValidationTexts';

export default () => {
    const { REQUIRED_TEXT } = getValidationTexts();

    return object().shape({
        description: string().required(REQUIRED_TEXT).nullable(),
        amount: number().typeError(REQUIRED_TEXT).required(REQUIRED_TEXT),
        value_type: string().required(REQUIRED_TEXT).nullable(),
    });
};
