import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, Link, Typography } from 'sunwise-ui';

const InstallerNameFormatter = ({ row }) => (
    <Box display="flex" gap={1} alignItems="flex-start">
        <Avatar
            src={get(row, 'installer.image', '')}
            sx={{ height: 36, width: 36 }}
        />

        <Box display="flex" flexDirection="column" gap={1}>
            <Link
                color="secondary"
                component={RouterLink}
                to={`/installer-profile/${row.id}`}
            >
                <Typography fontWeight="bold" variant="caption">
                    {get(row, 'installer.name', '')}
                </Typography>
            </Link>

            <Typography fontWeight="bold" variant="caption">
                {get(row, 'installer.business_name', '')}
            </Typography>
        </Box>
    </Box>
);

InstallerNameFormatter.propTypes = {
    row: PropTypes.object,
};

export default InstallerNameFormatter;
