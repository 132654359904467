import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import {
    Button,
    Card,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
} from 'sunwise-ui';

import { StyledH2 } from 'common/components';
import InputDatePicker from 'common/components/form/InputDatePicker';
import { formatDate, subDate } from 'common/utils/dates';
import { numberFormat } from 'common/utils/helpers';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';

const fixMarginBottom = {
    '& .MuiFormControl-root, & .MuiButton-root': { marginBottom: 0 },
};

const CardTotalProposalIssued = ({
    fetchProposalsTotals,
    handleSubmit,
    installerId,
    proposalsTotals,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [chartType, setChartType] = useState('total');

    useEffect(() => {
        const initialDate = formatDate(
            subDate(new Date(), { years: 1 }),
            'dd/MM/yyyy'
        );
        const finalDate = formatDate(new Date(), 'dd/MM/yyyy');
        const values = {
            final_date: finalDate,
            initial_date: initialDate,
        };
        fetchProposalsTotals(values, installerId);
    }, [installerId]);

    const handleChangeChartOptions = (type) => setChartType(type);

    const getSeriesData = (data) => {
        return [
            {
                data: data.map((item) => get(item, `${chartType}_emitida`, 0)),
                name: t('Proposals issued without your products'),
                type: 'column',
            },
            {
                data: data.map((item) => get(item, `${chartType}_cotizada`, 0)),
                name: t('Proposals issued with your products'),
                type: 'column',
            },
            {
                data: data.map((item) =>
                    get(item, `${chartType}_solicitada`, 0)
                ),
                name: t('Proposals issued and requested'),
                type: 'column',
            },
        ];
    };

    const chartConfig = {
        options: {
            chart: { background: '#ffffff00', toolbar: { show: false } },
            colors: ['#03e396', '#0073d0', '#346eeb'],
            dataLabels: { enabled: false },
            legend: { show: true },
            xaxis: { categories: proposalsTotals.categories },
            yaxis: [
                {
                    axisBorder: { show: true },
                    decimalsInFloat: 0,
                    labels: {
                        formatter: (val) =>
                            chartType === 'total'
                                ? val
                                : chartType === 'kw_propuesta'
                                ? numberFormat(val, {
                                      style: 'decimal',
                                      unit: 'kW',
                                  })
                                : numberFormat(val, {
                                      style: 'currency',
                                  }),
                    },
                },
            ],
        },
        series: getSeriesData(
            Object.keys(proposalsTotals.series).map(
                (serieKey) => proposalsTotals.series[serieKey]
            )
        ),
    };

    const handleClickFilter = (values) =>
        fetchProposalsTotals(values, installerId);

    return (
        <Card>
            <form onSubmit={handleSubmit(handleClickFilter)}>
                <Card.Header>
                    <Grid alignItems="center" container sx={fixMarginBottom}>
                        <Grid item md={9} xs={18}>
                            <StyledH2>{t('Total proposals')}</StyledH2>
                        </Grid>

                        <Grid item md={9} xs={18}>
                            <Grid container>
                                <Grid item xs sx={{ ml: 'auto' }}>
                                    <Field
                                        component={InputDatePicker}
                                        fullWidth
                                        label={t('Start date')}
                                        maxDate={new Date()}
                                        name="initial_date"
                                    />
                                </Grid>

                                <Grid item xs>
                                    <Field
                                        component={InputDatePicker}
                                        fullWidth
                                        label={t('Final date')}
                                        maxDate={new Date()}
                                        name="final_date"
                                    />
                                </Grid>

                                <Grid item>
                                    <Button type="submit">{t('Filter')}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card.Header>
            </form>

            <Card.Body>
                <ToggleButtonGroup
                    color="primary"
                    exclusive
                    fullWidth
                    onChange={(e) => handleChangeChartOptions(e.target.value)}
                    size="small"
                    sx={{ mb: 2 }}
                    value={chartType}
                >
                    <ToggleButton value="total">{t('Total')}</ToggleButton>
                    <ToggleButton value="kw_propuesta">
                        {t('Power')}
                    </ToggleButton>
                    <ToggleButton value="total_propuesta">
                        {t('Worth')}
                    </ToggleButton>
                </ToggleButtonGroup>

                <Chart
                    height="250"
                    id={`chart-${chartType}`}
                    options={{
                        ...chartConfig.options,
                        theme: { mode: theme.palette.mode },
                    }}
                    series={chartConfig.series}
                    type="bar"
                />
            </Card.Body>
        </Card>
    );
};

CardTotalProposalIssued.propTypes = {
    fetchProposalsTotals: PropTypes.func,
    handleSubmit: PropTypes.func,
    installerId: PropTypes.string,
    proposalsTotals: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    initialValues: selectors.getInitialValues,
});

const mapDispatchToProps = (dispatch) => ({
    fetchProjectsSalesStage: () => dispatch(actions.fetchProjectsSalesStage()),
    fetchProposalsTotals: (values, installerId) =>
        dispatch(actions.fetchProposalsTotals(values, installerId)),
});

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/form-card`,
})(CardTotalProposalIssued);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
