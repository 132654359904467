export const DEFAULT_NO_PERMISSIONS = {
    canDelete: false,
    canModify: false,
    canView: false,
};

export const PERMISSION_LEVELS = {
    NONE: 'none',
    VIEW: 'view',
    MODIFY: 'modify',
    ALL: 'all',
};

export const PERMISSION_LIST = {
    GENERAL_INFORMATION_PERMISSION: 1,
    ROLES_PERMISSION: 2,
    USERS_PERMISSION: 3,
    INSURANCE_DOCUMENTS_PERMISSION: 4, // DOCUMENTOS DE SEGURO
    ALLIANCE_PRODUCTS_PERMISSION: 5, // PRODUCTOS DE ALIANZA
    ALLIANCE_REQUESTS_PERMISSION: 6, // SOLICITUDES DE ALIANZA
    ALLIANCE_DOCUMENTS_PERMISSION: 7, // DOCUMENTOS DE ALIANZA
    ALLIANCE_PRODUCT_APPLICATIONS_PERMISSION: 8, // SOLICITUDES DE PRODUCTO DE ALIANZA
    APPLICATION_DOCUMENTS_PERMISSION: 9, // DOCUMENTOS DE SOLICITUD
};
