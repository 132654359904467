import i18next from 'i18next';
import is from 'is_js';

export default (values = {}) => {
    const errors = {};
    if (is.empty(values.new_password))
        errors.new_password = i18next.t('Password cannot be empty');
    if (is.empty(values.confirm_password))
        errors.confirm_password = i18next.t('Confirm password cannot be empty');
    if (!is.empty(values.new_password) && !is.empty(values.confirm_password))
        if (values.new_password !== values.confirm_password)
            errors.confirm_password = i18next.t(
                'Confirm password does not match'
            );
    return errors;
};
