export const PREFIX = 'SUNPAY_SOCKETS';

export const BEGIN_RECONNECT_SOCKET = `${PREFIX}::BEGIN_RECONNECT`;
export const BROKEN_SOCKET = `${PREFIX}::BROKEN`;
export const CLOSED_SOCKET = `${PREFIX}::CLOSED`;
export const ERROR_SOCKET = `${PREFIX}::ERROR`;
export const MESSAGE_SOCKET = `${PREFIX}::MESSAGE`;
export const OPEN_SOCKET = `${PREFIX}::OPEN`;
export const RECONNECTED = `${PREFIX}::RECONNECTED`;
export const RECONNECT_ATTEMPT_SOCKET = `${PREFIX}::RECONNECT_ATTEMPT`;
