import { updateCreditApplication } from 'common/api/creditApplications';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import { getStatusKey } from 'common/utils/helpers';

import {
    CHANGE_STATUS_CREDIT,
    CHANGE_STATUS_CREDIT_FAILURE,
    CHANGE_STATUS_CREDIT_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (item, callback, loadingContext) => (dispatch) => {
    if (!item.creditId) return;

    const newValues = {
        status: getStatusKey(item.status, false),
    };

    loadingContext.openLoading('Guardando cambios...');
    dispatch(creditFileActions[CHANGE_STATUS_CREDIT]());

    updateCreditApplication(item.creditId, newValues)
        .then(() => {
            dispatch(creditFileActions[CHANGE_STATUS_CREDIT_SUCCESS]());
            loadingContext.closeLoading();
            if (callback) callback();
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            loadingContext.closeLoading();
            dispatch(
                creditFileActions[CHANGE_STATUS_CREDIT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
