import axios from 'axios';
import i18next from 'i18next';

import { URL_TYPES } from 'common/constants';
import { downloadFileFromUrl, getFile } from 'common/modules/lisaFiles/actions';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import downloadFile from './downloadFile';

export default (archive, fileName, loadingContext, name, urlType) =>
    async (dispatch) => {
        if (urlType === URL_TYPES.LISA) {
            loadingContext.openLoading(i18next.t('Downloading'));
            dispatch(
                getFile(fileName, (data) => {
                    loadingContext.closeLoading();
                    dispatch(downloadFileFromUrl(data.url));
                })
            );
            return false;
        }
        await axios({
            method: 'get',
            responseType: 'blob',
            url: archive,
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                dispatch(downloadFile(name, url, 'pdf'));
            })
            .catch((error) =>
                showReponseErrorsAsAlert(dispatch, error?.response)
            );
    };
