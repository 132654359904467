import { FETCH_MESSAGES_SUCCESS } from '../actionTypes';
import { chatActions } from '../reducer';
import * as selectors from '../selectors';

export default ({ commentId, maxCharacters }) =>
    (dispatch, getState) => {
        const messageData = selectors.getMessagesData(getState());
        const { messages, ...rest } = messageData;
        const newMessages = messages.map((message) => {
            if (message.id === commentId) {
                const newMessage = { ...message };
                newMessage.maxCharacters = maxCharacters;
                return newMessage;
            }
            return message;
        });
        dispatch(
            chatActions[FETCH_MESSAGES_SUCCESS]({
                ...rest,
                messages: newMessages,
            })
        );
    };
