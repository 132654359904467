import i18next from 'i18next';
import { types } from 'sunwise-template-core';

import { getPdfFile } from 'common/api/sunpal/renderTemplate';
import showToast from 'common/utils/showToast';

import downloadFile from './downloadFile';

export default (
        templateFinishedId,
        renderName,
        openLoading,
        closeLoading,
        templateType = types.ONE_PROPOSAL_TYPE
    ) =>
    (dispatch) => {
        openLoading(i18next.t('Downloading'));

        return getPdfFile({
            quality: '2',
            renderName,
            templateFinishedId,
            templateType,
        })
            .then((response) => {
                closeLoading();
                dispatch(downloadFile(renderName, response.data, 'pdf'));
                showToast({
                    body: i18next.t('It has been downloaded successfully'),
                });
            })
            .catch(() => {
                closeLoading();
                showToast({
                    body: i18next.t('An error has occurred'),
                    type: 'danger',
                });
            });
    };
