import { bulkAssignProducts } from 'common/api/products';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_BULK_ASSIGN_PRODUCT,
    SAVE_BULK_ASSIGN_PRODUCT_FAILURE,
    SAVE_BULK_ASSIGN_PRODUCT_SUCCESS,
} from '../actionTypes';
import { installersActions } from '../reducer';

import closeAssingProductModal from './closeAssingProductModal';

export default (filterData, values) => (dispatch) => {
    dispatch(installersActions[SAVE_BULK_ASSIGN_PRODUCT]());

    bulkAssignProducts(values)
        .then(() => {
            dispatch(installersActions[SAVE_BULK_ASSIGN_PRODUCT_SUCCESS]());
            dispatch(closeAssingProductModal(filterData));
            if (values.callback) values.callback();
            showToast();
        })
        .catch((error) => {
            dispatch(
                installersActions[SAVE_BULK_ASSIGN_PRODUCT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
