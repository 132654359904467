import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, Typography, List, ListItem } from 'sunwise-ui';

import ContactPreview from './ContactPreview';

const SupportContactCard = ({ contacts = [] }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <Card.Header>
                <Typography fontWeight="bold" variant="body2">
                    {t('Support Contacts')}
                </Typography>
            </Card.Header>

            <List>
                {contacts.length > 0 ? (
                    contacts.map((contact) => (
                        <ListItem key={`support-contact-${contact.id}`}>
                            <ContactPreview contact={contact} />
                        </ListItem>
                    ))
                ) : (
                    <ListItem>
                        <Typography variant="caption">
                            {t('There are no items to display')}
                        </Typography>
                    </ListItem>
                )}
            </List>
        </Card>
    );
};

SupportContactCard.propTypes = { contacts: PropTypes.array };

export default SupportContactCard;
