import React from 'react';

import PropTypes from 'prop-types';
import { Card, Divider, Grid, Skeleton } from 'sunwise-ui';

const Placeholder = (
    <Card>
        <Card.Body>
            <Skeleton variant="rounded" />

            <Divider sx={{ my: 1 }} />

            <Grid container>
                <Grid item xs={2}>
                    <Skeleton height={52} variant="rounded" />
                </Grid>

                <Grid item xs={16}>
                    <Skeleton variant="rounded" />
                </Grid>
            </Grid>
        </Card.Body>
    </Card>
);

const PlaceholderCardDocuments = ({ children, ready }) => (
    <React.Fragment>{ready ? Placeholder : children}</React.Fragment>
);

PlaceholderCardDocuments.propTypes = {
    children: PropTypes.node,
    ready: PropTypes.bool,
};

export default PlaceholderCardDocuments;
