import React from 'react';

import PropTypes from 'prop-types';
import { UiDialog as Dialog } from 'sunwise-ui';

const Modal = ({
    children,
    footerComponent: FooterComponent = null,
    onEnter,
    onExited,
    onHide,
    show,
    size = 'lg',
    title,
}) => (
    <Dialog
        footerComponent={FooterComponent}
        onClose={() => onHide()}
        onEnter={onEnter}
        onExited={onExited}
        open={show}
        size={size}
        title={title}
    >
        {children}
    </Dialog>
);

Modal.propTypes = {
    children: PropTypes.node,
    footerComponent: PropTypes.node,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    onHide: PropTypes.func,
    show: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.string,
};

export default Modal;
