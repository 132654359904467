import { NAME } from './constants';

export const FETCH_DOCUMENTS = `${NAME}/FETCH_DOCUMENTS`;
export const FETCH_DOCUMENTS_FAILURE = `${NAME}/FETCH_DOCUMENTS_FAILURE`;
export const FETCH_DOCUMENTS_SUCCESS = `${NAME}/FETCH_DOCUMENTS_SUCCESS`;

export const FETCH_DOCUMENTS_TYPE = `${NAME}/FETCH_DOCUMENTS_TYPE`;
export const FETCH_DOCUMENTS_TYPE_FAILURE = `${NAME}/FETCH_DOCUMENTS_TYPE_FAILURE`;
export const FETCH_DOCUMENTS_TYPE_SUCCESS = `${NAME}/FETCH_DOCUMENTS_TYPE_SUCCESS`;

export const INITIALIZE_FORM = `${NAME}/INITIALIZE_FORM`;

export const RESET_FORM = `${NAME}/RESET_FORM`;

export const SHOW_MODAL = `${NAME}/SHOW_MODAL`;

export const UPLOAD_DOCUMENT = `${NAME}/UPLOAD_DOCUMENT`;
export const UPLOAD_DOCUMENT_FAILURE = `${NAME}/UPLOAD_DOCUMENT_FAILURE`;
export const UPLOAD_DOCUMENT_SUCCESS = `${NAME}/UPLOAD_DOCUMENT_SUCCESS`;
