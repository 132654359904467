import React from 'react';

import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const UserTrackingFormatter = ({ row }) => {
    return row?.user_tracking?.user ? (
        <Typography variant="caption">
            {`${row?.user_tracking?.user?.first_name} ${row?.user_tracking?.user?.last_name} ${row?.user_tracking?.user?.second_surname}` ||
                '- - -'}
        </Typography>
    ) : (
        '- - -'
    );
};

UserTrackingFormatter.propTypes = { row: PropTypes.object };

export default UserTrackingFormatter;
