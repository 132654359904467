import { BUREAU_STATUS } from 'common/constants';

export const getStatusButtonProps = (status) => {
    switch (status?.key) {
        case BUREAU_STATUS.REQUESTED.key:
            return { background: '#CED4DC', color: '#011e2e' };
        case BUREAU_STATUS.REFUSED.key:
            return { background: '#E81412', color: '#011e2e' };
        case BUREAU_STATUS.NOT_FOUND.key:
            return { background: '#979EA8', color: '#011e2e' };
        case BUREAU_STATUS.NOT_AUTHORIZED.key:
            return { background: '#282C34', color: '#ffffff' };
        case BUREAU_STATUS.PASSED.key:
            return { background: '#54C45E', color: '#011e2e' };
        case BUREAU_STATUS.PENDING_CLARIFICATION.key:
            return { background: '#F6E203', color: '#011e2e' };
        default:
            return {};
    }
};
