import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from 'sunwise-ui';

import { useBreakpoint } from 'common/hooks';

import ActionsFormatter from './ActionsFormatter';
import NameFormatter from './NameFormatter';

const Columns = ({
    canModify,
    handleChangeSwitch,
    handleClickUpdate,
    session,
}) => {
    const { t } = useTranslation();
    const breakpoint = useBreakpoint();

    const nameColumn = [
        {
            align: 'left',
            field: 'full_name',
            headerName: t('Name'),
            minWidth: 360,
            renderCell: (params) => {
                const { row } = params;
                return <NameFormatter row={row} />;
            },
            sortable: false,
        },
    ];

    let columns = [
        ...nameColumn,
        {
            align: 'left',
            field: 'role_name',
            flex: 1,
            headerName: t('Role'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.role_name || '- - -'}{' '}
                    </Typography>
                );
            },
        },
        {
            align: 'left',
            field: 'phone',
            flex: 1,
            headerName: t('Phone'),
            renderCell: (params) => {
                const { row } = params;
                return (
                    <Typography variant="caption">
                        {row?.phone || '- - -'}{' '}
                    </Typography>
                );
            },
        },
    ];

    if (['xs', 'sm'].includes(breakpoint)) {
        columns = nameColumn;
    }

    columns = [
        ...columns,
        {
            align: 'left',
            field: '',
            flex: 1,
            hide: !canModify,
            renderCell: (params) => {
                const { row } = params;
                return (
                    <ActionsFormatter
                        handleChangeSwitch={handleChangeSwitch}
                        handleClickUpdate={handleClickUpdate}
                        row={row}
                        session={session}
                    />
                );
            },
            sortable: false,
        },
    ];

    return columns;
};

Columns.propTypes = {
    canUpdate: PropTypes.bool,
    handleChangeSwitch: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    session: PropTypes.object,
};

export default Columns;
