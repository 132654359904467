import i18next from 'i18next';

import {
    APPROVED_STATUS,
    CLOSED_STATUS,
    ENABLED_STATUS,
    INCOMPLETE_STATUS,
    PRE_APPROVED_STATUS,
    PROCESS_STATUS,
    REJECTED_STATUS,
    REQUESTED_STATUS,
} from 'common/constants';

export const getExecutivesOptions = (executives) => {
    const options = executives.map((item) => ({
        label: item.name,
        value: item.name,
    }));
    return options;
};

export const getStatusOptions = () => [
    {
        label: i18next.t('All statuses'),
        value: 'all',
    },
    {
        label: REQUESTED_STATUS,
        value: REQUESTED_STATUS,
    },
    {
        label: INCOMPLETE_STATUS,
        value: INCOMPLETE_STATUS,
    },
    {
        label: PROCESS_STATUS,
        value: PROCESS_STATUS,
    },
    {
        label: PRE_APPROVED_STATUS,
        value: APPROVED_STATUS,
    },
    {
        label: ENABLED_STATUS,
        value: CLOSED_STATUS,
    },
    {
        label: REJECTED_STATUS,
        value: REJECTED_STATUS,
    },
];
