import { get, isEmpty } from 'lodash';

import { getTableSettings } from 'common/api/core';

import {
    FETCH_TABLE_SETTINGS,
    FETCH_TABLE_SETTINGS_FAILURE,
    FETCH_TABLE_SETTINGS_SUCCESS,
} from '../actionTypes';
import {
    handleBuildColumns,
    handleFormatterColumns,
    handleRequiredColumns,
} from '../helpers';
import { tableSettingsActions } from '../reducer';

export default (tableKey, columnKeys = {}, requiredColumnsKeys = []) =>
    (dispatch) => {
        dispatch(tableSettingsActions[FETCH_TABLE_SETTINGS]());

        getTableSettings(tableKey)
            .then((response) => {
                const data = get(response, 'data', {});
                let columns = get(data, 'settings', []);
                if (isEmpty(columns)) {
                    columns = handleFormatterColumns(
                        columnKeys,
                        requiredColumnsKeys
                    );
                } else {
                    columns = handleRequiredColumns(
                        requiredColumnsKeys,
                        columns
                    );
                    columns = handleBuildColumns(
                        columnKeys,
                        requiredColumnsKeys,
                        columns
                    );
                }
                dispatch(
                    tableSettingsActions[FETCH_TABLE_SETTINGS_SUCCESS](columns)
                );
            })
            .catch((error) =>
                dispatch(
                    tableSettingsActions[FETCH_TABLE_SETTINGS_FAILURE](error)
                )
            );
    };
