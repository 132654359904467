import is from 'is_js';

import { fetchItem } from 'common/api/financierProduct';

import {
    FETCH_PRODUCT,
    FETCH_PRODUCT_FAILURE,
    FETCH_PRODUCT_SUCCESS,
} from '../actionTypes';
import { financialCiBancoActions } from '../reducer';

import initializeForm from './initializeForm';
import toggleModalProduct from './toggleModalProduct';

const handleLists = (list) => {
    const temp = {};
    if (list) list.split(',').forEach((item) => (temp[item] = true));
    return temp;
};

const handleMaxMin = (limits) => (limits ? limits.split(',') : [0, 0]);

export default (value, disabled) => (dispatch) => {
    dispatch(financialCiBancoActions[FETCH_PRODUCT]());

    fetchItem(value.id)
        .then((response) => {
            const values = response.data;
            dispatch(financialCiBancoActions[FETCH_PRODUCT_SUCCESS](values));

            const rules = {};
            values.financier_product_rules.forEach((rule) => {
                rules[rule.key] = rule.value;
            });

            const amount_values = handleMaxMin(rules.amount_values);
            const power_values = handleMaxMin(rules.power_values);
            const rate_list = handleLists(rules.rate_list);
            const accesories_list_optional = handleLists(
                rules.accesories_list_optional
            );
            const catalog_documents = handleLists(values.catalog_documents);
            const parameters_terms = handleLists(
                is.array(values.parameters) ? values.parameters[0].terms : ''
            );

            const newValues = {
                accesories_list_must_have: rules.accesories_list_must_have,
                accesories_list_optional: accesories_list_optional,
                amount_values: {
                    isChecked: values.parameters.length
                        ? values.parameters[0].blind_objects.includes(
                              'amount_values'
                          )
                        : false,
                    max: amount_values[1],
                    min: amount_values[0],
                },
                catalog_documents: catalog_documents,
                description: values.description,
                disabled: disabled,
                id: values.id,
                inverters_list: rules.inverters_list,
                name: values.name,
                panels_list: rules.panels_list,
                parameters: values.parameters.length
                    ? {
                          ...values.parameters[0],
                          terms: parameters_terms,
                      }
                    : {},
                parameters_commission_credit: {
                    isChecked: values.parameters.length
                        ? values.parameters[0].blind_objects.includes(
                              'commission_credit'
                          )
                        : false,
                    opening_commission_vat: values.parameters.length
                        ? values.parameters[0].opening_commission_vat
                        : 0,
                    unit: values.parameters.length
                        ? values.parameters[0].is_commission_percentage
                            ? 0
                            : 1
                        : 1,
                    value: values.parameters.length
                        ? values.parameters[0].commission_credit
                        : 0,
                },
                parameters_hitch: {
                    isChecked: values.parameters.length
                        ? values.parameters[0].blind_objects.includes('hitch')
                        : false,
                    unit: values.parameters.length
                        ? values.parameters[0].is_percentage
                            ? 0
                            : 1
                        : 1,
                    value: values.parameters.length
                        ? values.parameters[0].hitch
                        : 0,
                },
                parameters_rate: {
                    isChecked: values.parameters.length
                        ? values.parameters[0].blind_objects.includes('rate')
                        : false,
                    value: values.parameters.length
                        ? values.parameters[0].rate
                        : 0,
                },
                power_values: {
                    isChecked: values.parameters.length
                        ? values.parameters[0].blind_objects.includes(
                              'power_values'
                          )
                        : false,
                    max: power_values[1],
                    min: power_values[0],
                },
                rate_list: rate_list,
                status_flag: values?.status_flag,
                storage_equipment_required: values.storage_equipment_required,
                template: values?.template?.id,
                type: values.type,
                type_algorithm: values.type_algorithm,
                types_business_company: values.types_business_company,
            };

            dispatch(initializeForm(newValues));
            dispatch(toggleModalProduct(true));
        })
        .catch((error) =>
            dispatch(
                financialCiBancoActions[FETCH_PRODUCT_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
