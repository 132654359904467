import React from 'react';

import PropTypes from 'prop-types';
import { ListItemButton, ListItemText } from 'sunwise-ui';

const ControlledListItem = ({
    category,
    getIsSelected,
    handleOnClickCatalogCard,
    title,
    type,
    visible = true,
}) => {
    if (!visible) return null;
    return (
        <ListItemButton
            onClick={() => handleOnClickCatalogCard(category, type)}
            selected={getIsSelected(category, type)}
        >
            <ListItemText primary={title} />
        </ListItemButton>
    );
};

ControlledListItem.propTypes = {
    category: PropTypes.number,
    getIsSelected: PropTypes.func,
    handleOnClickCatalogCard: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.number,
    visible: PropTypes.bool,
};

export default ControlledListItem;
