import axios from 'axios';

const API = axios.create({
    baseURL: process.env.REACT_APP_RENDER_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

API.interceptors.request.use((req) => {
    return req;
});

API.interceptors.response.use(
    (res) => res,
    (error) => Promise.reject(error)
);

export default API;
