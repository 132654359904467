import i18next from 'i18next';

import { uploadRequiredDocument } from 'common/api/creditApplications';
import { PROCESS_STATUS } from 'common/constants';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPLOAD_DOCUMENT,
    UPLOAD_DOCUMENT_FAILURE,
    UPLOAD_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';
import * as selectors from '../selectors';

export default (document, file, onSuccess) => (dispatch, getState) => {
    const creditDocuments = selectors.getRequiredDocumentsListData(getState());
    const index = creditDocuments
        .map((creditDocument) => creditDocument.id)
        .indexOf(document.id);

    dispatch(creditFileActions[UPLOAD_DOCUMENT]());

    uploadRequiredDocument(document.id, { file, status: PROCESS_STATUS })
        .then((response) => {
            dispatch(
                creditFileActions[UPLOAD_DOCUMENT_SUCCESS]({
                    data: response.data,
                    index,
                })
            );
            onSuccess();
            showToast({
                body: i18next.t('The file was uploaded successfully'),
            });
        })
        .catch((error) => {
            dispatch(
                creditFileActions[UPLOAD_DOCUMENT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
