import { useEffect } from 'react';

export default (value, name, event) => {
    useEffect(() => {
        if (value) window.addEventListener(name, event);
        return () => {
            window.removeEventListener(name, event);
        };
    }, [value]);
};
