import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Container as MaterialContainer } from 'sunwise-ui';

import { HeaderContainer, TitleIcon } from 'common/components';
import { PERMISSION_LIST } from 'common/constants/permissions';
import withPermissions from 'common/hocs/withPermissions';

import InstallerProfileContent from './components/InstallerProfileContent';

const Container = ({ canModify, match }) => {
    const { t } = useTranslation();

    return (
        <MaterialContainer maxWidth={false}>
            <HeaderContainer>
                <TitleIcon title={t('Installer File')} />
            </HeaderContainer>

            <InstallerProfileContent
                allianceId={match.params.id}
                canModify={canModify}
            />
        </MaterialContainer>
    );
};

Container.propTypes = {
    canModify: PropTypes.bool,
    match: PropTypes.object,
};

export default compose(
    withPermissions(PERMISSION_LIST.ALLIANCE_REQUESTS_PERMISSION)
)(withRouter(Container));
