import isEmpty from 'lodash/isEmpty';

import filterCredits from './filterCredits';
import openModalChangeStatus from './openModalChangeStatus';
import resetChangeStatusForm from './resetChangeStatusForm';

export default (filterData = {}) =>
    (dispatch) => {
        dispatch(resetChangeStatusForm());
        if (!isEmpty(filterData)) dispatch(filterCredits(filterData));
        dispatch(openModalChangeStatus(false));
    };
