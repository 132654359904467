import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import {
    gridPageCountSelector,
    gridPageSelector,
    gridPageSizeSelector,
    gridPaginationSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CustomPagination = ({ rowsPerPageOptions }) => {
    const { t } = useTranslation();
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const { rowCount = 0 } = useGridSelector(apiRef, gridPaginationSelector);
    const sx = {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        m: 1,
    };
    const sxWrapper = {
        alignItems: 'center',
        display: 'flex',
        flexDirection: { gap: 1, md: 'row', xs: 'column' },
        width: { md: 'auto', xs: '100%' },
    };

    return (
        <Box sx={sxWrapper}>
            <Box sx={sx}>
                <Typography fontWeight="bold" noWrap variant="body2">
                    {t('Total items')}:
                </Typography>
                {rowCount}
            </Box>

            {rowsPerPageOptions?.length > 0 && (
                <Box sx={sx}>
                    <Typography fontWeight="bold" noWrap variant="body2">
                        {t('Items per page')}:
                    </Typography>

                    <FormControl variant="standard" sx={{ minWidth: 60 }}>
                        <Select
                            value={pageSize}
                            onChange={(event) =>
                                apiRef.current.setPageSize(event?.target?.value)
                            }
                            sx={{ textAlign: 'center' }}
                        >
                            {rowsPerPageOptions.map((option) => (
                                <MenuItem
                                    key={`option-${option}`}
                                    value={option}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            )}

            <FormControl sx={{ m: 1 }}>
                <Pagination
                    color="primary"
                    count={pageCount}
                    onChange={(_, value) => apiRef.current.setPage(value - 1)}
                    page={page + 1}
                    size="small"
                />
            </FormControl>
        </Box>
    );
};

CustomPagination.defaultProps = {
    rowsPerPageOptions: [],
};

CustomPagination.propTypes = {
    rowsPerPageOptions: PropTypes.array,
};

export default CustomPagination;
