import { getFinancierInstaller } from 'common/api/financierInstallers';

import {
    FETCH_FINANCIER_INSTALLER,
    FETCH_FINANCIER_INSTALLER_FAILURE,
    FETCH_FINANCIER_INSTALLER_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(installerProfileActions[FETCH_FINANCIER_INSTALLER]());

    getFinancierInstaller(id)
        .then((response) =>
            dispatch(
                installerProfileActions[FETCH_FINANCIER_INSTALLER_SUCCESS](
                    response.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                installerProfileActions[FETCH_FINANCIER_INSTALLER_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
