import React from 'react';

import PropTypes from 'prop-types';
import { Typography } from 'sunwise-ui';

const UserTrackingFormatter = ({ row }) => {
    return row.financial_advisor ? (
        <Typography variant="caption">
            {row?.financial_advisor?.first_name}{' '}
            {row?.financial_advisor?.last_name}
        </Typography>
    ) : (
        '- - -'
    );
};

UserTrackingFormatter.propTypes = {
    row: PropTypes.object,
};

export default UserTrackingFormatter;
