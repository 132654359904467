export default {
    control: {
        backgroundColor: 'transparent',
        padding: '8.5px 14px',
    },
    input: {
        padding: '8.5px 14px',
        margin: 0,
        borderRadius: '8px',
        boxShadow: 'none',
        outline: 'none',
        border: 0,
    },
};
