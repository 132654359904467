import { createSlice } from '@reduxjs/toolkit';

import { NAME } from './constants';

const INITIAL_STATE = {};

const dashboardSlice = createSlice({
    initialState: INITIAL_STATE,
    name: NAME,
    reducers: {},
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice.reducer;
