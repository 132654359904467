import React, { useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import {
    Button,
    Card,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import { Modal } from 'common/components';
import { NO_DOCUMENT_STATUS } from 'common/constants';
import parseQueryString from 'common/utils/parseQueryString';

import CreditDocuments from './CreditDocuments';
import FileReview from './FileReview';
import SelectDocumentsForm from './SelectDocumentsForm';

const DocumentCard = ({
    canModify,
    documents,
    documentType,
    fetchDocuments,
    fetchRequiredDocumentsList,
    handleOnChangeStatusFile,
    handleOnClickDeleteFile,
    handleOnRejectFile,
    isFetching,
    location,
    moduleName = '',
    onChangeFileStatus,
    onClickDownload,
    onSave,
    requiredDocuments,
    selectedFile,
    setSelectedFile,
    title = i18next.t('Document', { count: 2 }),
    uploadDocumentFile,
    user,
    userType,
    visible = true,
}) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [showAddDocumentsModal, setShowAddDocumentsModal] = useState(false);
    const { search: locationSearch = '' } = location;
    const { documentId = null } = parseQueryString(locationSearch);
    const [documentOpen, setDocumentOpen] = useState(false);

    const handleOnClickAddDocument = () => setShowAddDocumentsModal(true);

    const handleOnClickDownload = () => onClickDownload();

    const handleOnClickStatus = (file) => {
        setSelectedFile(file);
        setShowModal(true);
    };

    const filterFiles = requiredDocuments.filter(
        (item) => item.file && item.status != NO_DOCUMENT_STATUS
    );
    const hasDownloableFile = filterFiles.length > 0;

    useEffect(() => {
        if (documentOpen) return;
        if (!documentId || filterFiles.length === 0) return;
        const file = requiredDocuments.find((item) => item.id === documentId);
        if (!file) return;
        handleOnClickStatus(file);
    }, [filterFiles, documentId]);

    if (!visible) return null;

    return (
        <Card>
            <Card.Header>
                <Grid alignItems="center" container>
                    <Grid item xs>
                        <Typography fontWeight="bold" variant="body2">
                            {title}
                        </Typography>
                    </Grid>

                    {onClickDownload && hasDownloableFile && (
                        <Grid item>
                            <Tooltip title={t('Download all')}>
                                <IconButton
                                    color="secondary"
                                    onClick={handleOnClickDownload}
                                    variant="outlined"
                                >
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Card.Header>

            <CreditDocuments
                canModify={canModify}
                documents={requiredDocuments}
                handleOnClickStatus={handleOnClickStatus}
                handleOnClickDelete={handleOnClickDeleteFile}
            />

            <Divider />

            <Card.Body hidden={!canModify} sx={{ textAlign: 'right' }}>
                <Button
                    color="secondary"
                    onClick={handleOnClickAddDocument}
                    variant="text"
                >
                    <strong>Solicitar otro</strong>
                </Button>
            </Card.Body>

            <Modal
                onEnter={() => fetchDocuments()}
                onHide={() => setShowAddDocumentsModal(false)}
                show={showAddDocumentsModal}
                size="md"
                title={t('Select the document')}
            >
                <SelectDocumentsForm
                    callback={() => {
                        fetchRequiredDocumentsList();
                        setShowAddDocumentsModal(false);
                    }}
                    documents={documents}
                    isFetching={isFetching}
                    moduleName={moduleName}
                    onSave={onSave}
                />
            </Modal>

            <FileReview
                documentType={documentType}
                handleOnChangeStatusFile={handleOnChangeStatusFile}
                handleOnRejectFile={handleOnRejectFile}
                mode="review"
                onChangeFileStatus={onChangeFileStatus}
                onHide={() => {
                    setShowModal(false);
                    setDocumentOpen(true);
                }}
                selectedFile={selectedFile}
                show={showModal}
                uploadDocumentFile={uploadDocumentFile}
                user={user}
                userType={userType}
            />
        </Card>
    );
};

DocumentCard.propTypes = {
    canModify: PropTypes.bool,
    documents: PropTypes.array,
    documentType: PropTypes.string,
    fetchDocuments: PropTypes.func,
    fetchRequiredDocumentsList: PropTypes.func,
    handleOnChangeStatusFile: PropTypes.func,
    handleOnClickDeleteFile: PropTypes.func,
    handleOnRejectFile: PropTypes.func,
    isFetching: PropTypes.bool,
    location: PropTypes.object,
    moduleName: PropTypes.string,
    onChangeFileStatus: PropTypes.func,
    onClickDownload: PropTypes.func,
    onSave: PropTypes.func,
    requiredDocuments: PropTypes.array,
    selectedFile: PropTypes.object,
    setSelectedFile: PropTypes.func,
    title: PropTypes.string,
    uploadDocumentFile: PropTypes.func,
    user: PropTypes.object,
    userType: PropTypes.number,
    visible: PropTypes.bool,
};

export default withRouter(DocumentCard);
