import React, { useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Tab, TabPanel, Tabs, Typography } from 'sunwise-ui';

import { EmptyMessage } from 'common/components';
import {
    DOCUMENTS_TYPE,
    INSURANCE_DOCUMENTS_TYPE,
    INSURANCE_TYPE,
    REQUEST_FORM_TYPE,
} from 'common/constants';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import CatalogDocuments from './CatalogDocuments';

const Page3 = ({
    disabled,
    documents,
    fetchDocuments,
    financierType,
    isFetching,
}) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState('request_form');
    const handleChange = (_, newValue) => setSelectedTab(newValue);
    const getRequestFormDocs = () => {
        if (financierType === INSURANCE_TYPE)
            return documents.filter(
                (doc) => doc.type === INSURANCE_DOCUMENTS_TYPE
            );
        return documents.filter((doc) => doc.type === REQUEST_FORM_TYPE);
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    const docs = useMemo(
        () => documents.filter((doc) => doc.type === DOCUMENTS_TYPE),
        [documents]
    );

    if (isFetching)
        return (
            <EmptyMessage>
                <Typography>{t('Loading')}</Typography>
            </EmptyMessage>
        );

    return (
        <React.Fragment>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onChange={handleChange}
                    scrollButtons="auto"
                    value={selectedTab}
                    variant="scrollable"
                >
                    <Tab
                        id="tab-request_form"
                        key="tab-request_form"
                        label={
                            financierType === INSURANCE_TYPE
                                ? t('Insurance application form')
                                : t('Request form')
                        }
                        value="request_form"
                    />
                    <Tab
                        id="tab-documents"
                        key="tab-documents"
                        label="Documentos"
                        value="documents"
                    />
                </Tabs>
            </Box>

            <TabPanel
                key="tab-request_form"
                selectedTab={selectedTab}
                value="request_form"
            >
                <CatalogDocuments
                    disabled={disabled}
                    documents={getRequestFormDocs()}
                />
            </TabPanel>

            <TabPanel
                key="tab-documents"
                selectedTab={selectedTab}
                value="documents"
            >
                <CatalogDocuments disabled={disabled} documents={docs} />
            </TabPanel>
        </React.Fragment>
    );
};

const mapStateToProps = createStructuredSelector({
    documents: selectors.getDocumentsData,
    isFetching: selectors.getDocumentsIsFetching,
});

const mapDispatchToProps = (dispatch) => ({
    fetchDocuments: () => dispatch(actions.fetchDocuments()),
});

Page3.propTypes = {
    disabled: PropTypes.bool,
    documents: PropTypes.array,
    fetchDocuments: PropTypes.func,
    financierType: PropTypes.string,
    isFetching: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page3);
