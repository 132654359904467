import React from 'react';

import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Box } from 'sunwise-ui';

import EmptyTemplateList from 'resources/EmptyTemplateList.svg';

import CardWrapper from './CardWrapper';

const StyledText = styled(Box)`
    color: #202253;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: -0.36px;
    line-height: 19px;
    text-align: center;
`;

const StyledImage = styled('img')`
    ${({ clickable }) => clickable && 'cursor: pointer;'}
    margin-bottom: 25px;
    max-width: 300px;
    width: 100%;
`;

const StyledContainer = styled(Box)`
    width: 60%;
`;

const StyledEmptyModule = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    width: 100%;
`;

const EmptyTemplate = ({
    onClick,
    text = i18next.t('Hey! Select or add a page to get started!'),
}) => {
    return (
        <StyledEmptyModule>
            <StyledContainer>
                <CardWrapper>
                    <StyledImage
                        clickable={onClick}
                        onClick={onClick}
                        src={EmptyTemplateList}
                    />

                    <StyledText>{text}</StyledText>
                </CardWrapper>
            </StyledContainer>
        </StyledEmptyModule>
    );
};

EmptyTemplate.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
};

export default EmptyTemplate;
