import * as React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    alignItems: 'center',
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#fff',
    border: '1.8px solid #d3d7eb',
    borderRadius: '50%',
    boxShadow: 'inset 0 2px 3px 0 rgba(0, 0, 0, 0.02)',
    display: 'flex',
    height: 35,
    justifyContent: 'center',
    width: 35,
    zIndex: 1,

    'svg path': {
        fill: '#202253',
    },

    ...(ownerState.active && {
        backgroundColor: '#2bf2ab',
        'svg path': {
            fill: '#202253',
        },
    }),

    ...(ownerState.completed && {
        'svg path': {
            fill: '#2bf2ab',
        },
    }),

    ...(ownerState.disabled && {
        border: '1px solid #d3d7eb',
        'svg path': {
            fill: '#d3d7eb',
        },
    }),
}));

const StepperTimelineIcon = (props) => {
    const { active, completed, className, disabled, icons } = props;

    return (
        <ColorlibStepIconRoot
            ownerState={{ active, completed, disabled }}
            className={className}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
};

StepperTimelineIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    icons: PropTypes.object,
};

export default StepperTimelineIcon;
