import React, { useEffect, useState, useRef } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card, DataGrid } from 'sunwise-ui';

import CustomPagination from 'common/components/CustomPagination';
import CustomColumns from 'common/modules/customColumns';
import { getOrderedColumns, getHiddenColumns } from 'common/utils/helpers';

import { COLUMN_KEYS, REQUIRED_COLUMNS_KEYS } from '../constants';

import BulkOperationsCard from './BulkOperationsCard';
import Columns from './Columns';
import ProductsModal from './ProductsModal';

const DEBOUNCE_TIME = 1500;

const ListItems = ({
    assignProduct,
    canModify,
    downloadFile,
    executives,
    filterData,
    filterItems,
    financierProducts,
    handleClickUnlink,
    isFetching,
    isFetchingExecutives,
    items,
    paginationData,
    setFilterData,
    statusList,
    tableSettings,
}) => {
    const { t } = useTranslation();
    const [rowCount, setRowCount] = useState(0);
    const [selectionModel, setSelectionModel] = useState([]);
    const [allianceId, setAllianceId] = useState(null);
    const [currentFilterData, setCurrentFilterData] = useState(filterData);

    useEffect(() => {
        if (paginationData)
            setRowCount((prevRowCount) =>
                paginationData?.total_rows !== undefined
                    ? paginationData?.total_rows
                    : prevRowCount
            );
    }, [paginationData?.total_rows, setRowCount]);

    useEffect(() => {
        if (!isEqual(currentFilterData, filterData)) {
            handleOnFilter(filterData);
            setCurrentFilterData(filterData);
        }
    }, [filterData]);

    const handleOnFilter = useRef(
        debounce((filter) => filterItems(filter), DEBOUNCE_TIME)
    ).current;

    const handleOnChangeFilter = (value, key) =>
        setFilterData((prev) => ({ ...prev, [key]: value }));

    const handleOnChangeSearch = (event) => {
        setFilterData((prev) => ({
            ...prev,
            page: 0,
            searchText: event.target.value,
        }));
    };

    const handleOnSortModelChange = (sortModel) => {
        if (!sortModel[0]) return;

        setFilterData((prev) => ({
            ...prev,
            orderBy: sortModel[0].field.toLocaleLowerCase(),
            sortBy: sortModel[0].sort,
        }));
    };

    const callback = () => {
        setAllianceId(null);
        setSelectionModel([]);
    };

    const handleClickAssignProduct = (alliancesIds) => {
        setAllianceId(alliancesIds[0]);
        assignProduct(alliancesIds, callback);
    };

    const handleOnPageChange = (page) => handleOnChangeFilter(page, 'page');
    const handleOnPageSizeChange = (newPage) =>
        handleOnChangeFilter(newPage, 'pageSize');
    const handleOnSelectionModelChange = (newModel) =>
        setSelectionModel(newModel);

    const components = {
        LoadingOverlay: LinearProgress,
        Pagination: CustomPagination,
    };
    const componentsProps = {
        pagination: {
            rowsPerPageOptions: [25, 50, 75, 100],
        },
    };
    const initialState = {
        pagination: { pageSize: filterData?.pageSize },
    };
    const columns = Columns({
        canModify,
        handleClickAssignProduct,
        handleClickUnlink,
        hiddenColumns: getHiddenColumns(tableSettings),
        orders: getOrderedColumns(tableSettings),
        selectionModel,
    });
    const page = paginationData?.current_page - 1 || 0;
    const pageSize = filterData.pageSize;

    const prefix = t('{{count}} selected alliance', {
        count: selectionModel.length,
    });

    return (
        <>
            <BulkOperationsCard
                canModify={canModify}
                downloadFile={downloadFile}
                executives={executives}
                filterData={filterData}
                financierProducts={financierProducts}
                handleClickAssignProduct={assignProduct}
                handleOnChangeSearch={handleOnChangeSearch}
                isFetchingExecutives={isFetchingExecutives}
                prefix={prefix}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                statusList={statusList}
                handleOnChangeFilter={handleOnChangeFilter}
            />

            <Card>
                <Card.Body>
                    <CustomColumns.Container
                        columnKeys={COLUMN_KEYS}
                        tableKey="installers"
                        requiredColumnsKeys={REQUIRED_COLUMNS_KEYS}
                    />

                    <DataGrid
                        autoHeight
                        checkboxSelection={true}
                        columns={columns}
                        components={components}
                        componentsProps={componentsProps}
                        disableColumnMenu
                        disableSelectionOnClick
                        editMode="row"
                        experimentalFeatures={{ newEditingApi: true }}
                        getRowHeight={() => 'auto'}
                        initialState={initialState}
                        loading={isFetching}
                        onPageChange={handleOnPageChange}
                        onPageSizeChange={handleOnPageSizeChange}
                        onSelectionModelChange={handleOnSelectionModelChange}
                        onSortModelChange={handleOnSortModelChange}
                        page={page}
                        pageSize={pageSize}
                        pagination
                        paginationMode="server"
                        rowCount={rowCount}
                        rows={items}
                        selectionModel={selectionModel}
                        sortingMode="server"
                    />
                </Card.Body>
            </Card>

            <ProductsModal
                allianceId={allianceId}
                alliancesFilterData={filterData}
            />
        </>
    );
};

ListItems.propTypes = {
    assignProduct: PropTypes.func,
    canModify: PropTypes.bool,
    downloadFile: PropTypes.func,
    executives: PropTypes.array,
    filterData: PropTypes.object,
    filterItems: PropTypes.func,
    financierProducts: PropTypes.array,
    handleClickUnlink: PropTypes.func,
    isFetching: PropTypes.bool,
    isFetchingExecutives: PropTypes.bool,
    items: PropTypes.array,
    paginationData: PropTypes.object,
    setFilterData: PropTypes.func,
    statusList: PropTypes.array,
    tableSettings: PropTypes.array,
};

export default ListItems;
