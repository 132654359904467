import { filterUsers } from 'common/api/users';

import {
    FETCH_ITEMS,
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_SUCCESS,
} from '../actionTypes';
import { companyUsersActions } from '../reducer';

export default (filterData) => (dispatch) => {
    const { orderBy, page = 1, pageSize, searchText, sortBy } = filterData;

    dispatch(companyUsersActions[FETCH_ITEMS]());

    filterUsers({
        orderBy,
        page: page + 1,
        perPage: pageSize,
        searchText,
        sortBy,
    })
        .then((response) => {
            dispatch(companyUsersActions[FETCH_ITEMS_SUCCESS](response.data));
        })
        .catch((error) =>
            dispatch(
                companyUsersActions[FETCH_ITEMS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
