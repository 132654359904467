import i18next from 'i18next';
import { types } from 'sunwise-template-core';

import { duplicateSmartDocument } from 'common/api/smartDocuments';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    DUPLICATE_SMART_DOCUMENT,
    DUPLICATE_SMART_DOCUMENT_FAILURE,
    DUPLICATE_SMART_DOCUMENT_SUCCESS,
} from '../actionTypes';
import { templateDocumentsActions } from '../reducer';

import fetchDocuments from './fetchDocuments';
import openDocumentModal from './openDocumentModal';

export default (
        handleClickDocumentItem,
        initTemplate,
        proposalId,
        resetTemplate,
        values
    ) =>
    (dispatch) => {
        dispatch(templateDocumentsActions[DUPLICATE_SMART_DOCUMENT]());

        const newValues = {
            name: values.name,
            smart_document_id: values.id,
        };

        duplicateSmartDocument(newValues)
            .then((response) => {
                dispatch(
                    templateDocumentsActions[DUPLICATE_SMART_DOCUMENT_SUCCESS](
                        response.data
                    )
                );
                const { content, id, name } = response.data;
                dispatch(fetchDocuments(proposalId));
                handleClickDocumentItem({
                    document_reference_id: id,
                    hasContent: true,
                    id,
                    name,
                    type: types.SMART_DOCUMENTS_TYPE,
                });
                resetTemplate();
                initTemplate(JSON.parse(content));
                dispatch(openDocumentModal(false));
                showToast({ body: i18next.t('Successfully duplicated') });
            })
            .catch((error) => {
                dispatch(
                    templateDocumentsActions[DUPLICATE_SMART_DOCUMENT_FAILURE](
                        error?.response?.data?.errors
                    )
                );
                showReponseErrorsAsAlert(dispatch, error?.response);
            });
    };
