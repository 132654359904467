import React from 'react';

import PropTypes from 'prop-types';

import { validateAccessWithPermissions } from 'common/utils/permissions';

const CheckAccess = ({
    children,
    isHidden = false,
    requiredPermissions = [],
}) => {
    if (isHidden) return null;

    const hasAccess = validateAccessWithPermissions(requiredPermissions);

    return <>{hasAccess && <>{children}</>}</>;
};

CheckAccess.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    isHidden: PropTypes.bool,
    requiredPermissions: PropTypes.array,
};

export default CheckAccess;
