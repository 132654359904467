import { saveItem } from 'common/api/financierAllianceTracking';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    SAVE_ASSIGNED_USER,
    SAVE_ASSIGNED_USER_FAILURE,
    SAVE_ASSIGNED_USER_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

import resetAssignUserForm from './resetAssignUserForm';

export default (values, callback) => (dispatch) => {
    const newValues = {
        financier_installer: values.allianceId,
        user: values.user,
    };

    dispatch(installerProfileActions[SAVE_ASSIGNED_USER]());

    saveItem(newValues)
        .then(() => {
            dispatch(installerProfileActions[SAVE_ASSIGNED_USER_SUCCESS]());
            dispatch(resetAssignUserForm());
            if (callback) callback();
        })
        .catch((error) => {
            dispatch(
                installerProfileActions[SAVE_ASSIGNED_USER_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
