import initializeForm from './initializeForm';
import setIsNewRecord from './setIsNewRecord';
import toggleModalProduct from './toggleModalProduct';

export default (item) => (dispatch) => {
    const values = {
        email: item.email,
        financier_group: item.financier_group,
        first_name: item.first_name,
        id: item.id,
        last_name: item.last_name,
        phone: item.phone,
        second_surname: item.second_surname,
    };

    dispatch(setIsNewRecord(false));
    dispatch(initializeForm(values));
    dispatch(toggleModalProduct(true));
};
