import React from 'react';

import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, FormGroup } from 'sunwise-ui';

const ReduxFieldCheck = ({
    disabled,
    input: { onBlur, onChange, value },
    label = '',
    sx,
}) => {
    const checkbox = (
        <Checkbox
            checked={value}
            disabled={disabled}
            onBlur={onBlur}
            onChange={onChange}
        />
    );

    return (
        <FormGroup disabled={disabled} sx={{ ...sx, alignItems: 'center' }}>
            {label !== '' ? (
                <FormControlLabel
                    disabled={disabled}
                    control={checkbox}
                    label={label}
                />
            ) : (
                <FormControlLabel
                    disabled={disabled}
                    control={checkbox}
                    sx={{ m: 0 }}
                />
            )}
        </FormGroup>
    );
};

ReduxFieldCheck.propTypes = {
    disabled: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.string,
    sx: PropTypes.object,
};

export default ReduxFieldCheck;
