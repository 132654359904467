import React from 'react';

import PropTypes from 'prop-types';

import PdfViewer from 'common/components/PdfViewer';

import {
    DATASHEET_PDF_TYPE,
    ELECTRIC_BILL_TYPE,
    FINANCING_TYPE,
} from '../constants';

import TemplateSection from './TemplateSection';

const PreviewContent = ({
    editionDisabled,
    isFetchingCustomTemplate,
    isFetchingFinancingTemplate,
    isFetchingInteractiveProposalContent,
    isFetchingSmartDocumentContent,
    selectedDocument,
}) => {
    switch (selectedDocument?.type) {
        case DATASHEET_PDF_TYPE:
        case ELECTRIC_BILL_TYPE:
            return (
                <PdfViewer
                    document={{
                        url: selectedDocument.archive,
                    }}
                    format={selectedDocument?.format}
                />
            );
        case FINANCING_TYPE:
            if (selectedDocument?.archive)
                return (
                    <PdfViewer
                        document={{
                            url: selectedDocument.archive,
                        }}
                        format={selectedDocument?.format}
                    />
                );
            else
                return (
                    <TemplateSection
                        editionDisabled={editionDisabled}
                        isLoading={isFetchingFinancingTemplate}
                    />
                );
        default:
            return (
                <TemplateSection
                    editionDisabled={editionDisabled}
                    isLoading={
                        isFetchingCustomTemplate ||
                        isFetchingInteractiveProposalContent ||
                        isFetchingSmartDocumentContent
                    }
                />
            );
    }
};

PreviewContent.propTypes = {
    editionDisabled: PropTypes.bool,
    isFetchingCustomTemplate: PropTypes.bool,
    isFetchingFinancingTemplate: PropTypes.bool,
    isFetchingInteractiveProposalContent: PropTypes.bool,
    isFetchingSmartDocumentContent: PropTypes.bool,
    selectedDocument: PropTypes.object,
};

export default PreviewContent;
