import { filterInsurances } from 'common/api/products';

import {
    FETCH_COVERAGES,
    FETCH_COVERAGES_FAILURE,
    FETCH_COVERAGES_SUCCESS,
} from '../actionTypes';
import { configurationCoveragesActions } from '../reducer';

export default ({ status } = {}, callback) =>
    (dispatch) => {
        dispatch(configurationCoveragesActions[FETCH_COVERAGES]());

        filterInsurances({
            status,
        })
            .then((response) => {
                dispatch(
                    configurationCoveragesActions[FETCH_COVERAGES_SUCCESS](
                        response?.data?.data
                    )
                );
                if (callback) callback(response?.data?.data);
            })
            .catch((error) =>
                dispatch(
                    configurationCoveragesActions[FETCH_COVERAGES_FAILURE](
                        error?.response?.data?.errors
                    )
                )
            );
    };
