import { closeCreditApplicaton } from 'common/api/creditApplications';
import alerts from 'common/modules/alerts';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';

import {
    CLOSE_CREDIT,
    CLOSE_CREDIT_FAILURE,
    CLOSE_CREDIT_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (id, callback) => (dispatch) => {
    dispatch(creditFileActions[CLOSE_CREDIT]());

    closeCreditApplicaton(id)
        .then(() => {
            dispatch(creditFileActions[CLOSE_CREDIT_SUCCESS]());
            if (callback) callback();
            dispatch(alerts.actions.close());
        })
        .catch((error) => {
            dispatch(
                creditFileActions[CLOSE_CREDIT_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
