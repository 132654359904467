import React from 'react';

import { styled } from '@mui/material/styles';
import { Grid } from 'sunwise-ui';

import { socialMedia } from './constants';

import { SocialMediaItem } from './index';

const StyledTitle = styled('h1')`
    font-size: 26px !important;
    line-height: 32px;
    margin-top: 32px;
    text-align: left;
`;

const SocialMediaFooterContainer = () => {
    if (!socialMedia || !socialMedia.length > 0) return null;
    return (
        <>
            <StyledTitle>¡Síguenos!</StyledTitle>

            <Grid container>
                {socialMedia.map((social, index) => (
                    <Grid item xs key={index}>
                        <SocialMediaItem
                            fontSize="15.6px"
                            href={social.href}
                            imgUrl={social.imgUrl}
                            lineHeight="29px"
                            marginBottom="12px"
                            marginTop="13px"
                            target="_blank"
                            title={social.name}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default SocialMediaFooterContainer;
