import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getNotifications = createSelector(
    getModel,
    (model) => model.fetchNotifications
);

export const getNotificationsData = createSelector(
    getNotifications,
    (model) => model.data.data || []
);

export const getIsFetchingNotifications = createSelector(
    getNotifications,
    (model) => model.isFetching || false
);

export const getNotificationsPagination = createSelector(
    getNotifications,
    (model) => model.data.pagination || null
);

export const getNavNotifications = createSelector(
    getModel,
    (model) => model.fetchNavNotifications
);

export const getNavNotificationsData = createSelector(
    getNavNotifications,
    (model) => model.data.data || []
);

export const getNavNotificationsNoReaded = createSelector(
    getNavNotifications,
    (model) => model.data.unread_notifications || 0
);

export const getIsFetchingNavNotifications = createSelector(
    getNavNotifications,
    (model) => model.isFetching || false
);

export const getDeleteNotification = createSelector(
    getModel,
    (model) => model.deleteNotification
);

export const getIsDeletingNotification = createSelector(
    getDeleteNotification,
    (model) => model.isDeleting
);

export const getMarkAllAsRead = createSelector(
    getModel,
    (model) => model.markAllAsRead
);

export const getIsUpdatingNotifications = createSelector(
    getMarkAllAsRead,
    (model) => model.isUpdating
);
