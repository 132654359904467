import { getStatus } from 'common/api/burocredito';

import {
    FETCH_CREDIT_BUREAU,
    FETCH_CREDIT_BUREAU_FAILURE,
    FETCH_CREDIT_BUREAU_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(creditFileActions[FETCH_CREDIT_BUREAU]());

    getStatus(id)
        .then((response) =>
            dispatch(
                creditFileActions[FETCH_CREDIT_BUREAU_SUCCESS](response.data)
            )
        )
        .catch((error) =>
            dispatch(
                creditFileActions[FETCH_CREDIT_BUREAU_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
