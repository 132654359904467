import { createSlice } from '@reduxjs/toolkit';

import {
    FETCH,
    FETCH_FAILURE,
    FETCH_SUCCESS,
    INITIALIZE_FORM,
    SAVE,
    SAVE_FAILURE,
    SAVE_SUCCESS,
    SET_CURRENT_VALUES,
    SET_THEME,
} from './actionTypes';
import { NAME } from './constants';

const INITIAL_STATE = {
    currentValues: {
        first_name: null,
        last_name: null,
        phone: null,
        profile_image: null,
    },
    fetch: { data: [], errors: [], isFetching: false },
    initialValues: {
        confirm_password: null,
        email: null,
        first_name: null,
        id: null,
        last_name: null,
        new_password: null,
        old_password: null,
        phone: null,
        profile_image: null,
    },
    save: { errors: [], isSaving: false },
    theme: 'light',
};

const profileSlice = createSlice({
    name: NAME,
    initialState: INITIAL_STATE,
    reducers: {
        [FETCH]: (state) => {
            state.fetch.isFetching = true;
        },
        [FETCH_FAILURE]: (state, action) => {
            state.fetch = {
                ...state.fetch,
                errors: action.payload,
                isFetching: false,
            };
        },
        [FETCH_SUCCESS]: (state, action) => {
            state.fetch = {
                ...state.fetch,
                data: action.payload,
                isFetching: false,
            };
        },
        [INITIALIZE_FORM]: (state, action) => {
            state.initialValues = { ...state.initialValues, ...action.payload };
        },
        [SAVE]: (state) => {
            state.save.isSaving = true;
        },
        [SAVE_FAILURE]: (state, action) => {
            state.save = {
                ...state.save,
                errors: action.payload,
                isSaving: false,
            };
        },
        [SAVE_SUCCESS]: (state) => {
            state.save.isSaving = false;
        },
        [SET_CURRENT_VALUES]: (state, action) => {
            state.currentValues = { ...state.currentValues, ...action.payload };
        },
        [SET_THEME]: (state, action) => {
            state.theme = action.payload;
        },
    },
});

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
