import React from 'react';

import PropTypes from 'prop-types';
import { Box, Card, Typography } from 'sunwise-ui';

const CardKeyValue = ({ append, prepend, title, value, visible = true }) => {
    if (!visible) return null;
    return (
        <Card>
            <Card.Header>
                <Typography fontWeight="bold" variant="body2">
                    {title}
                </Typography>
            </Card.Header>

            <Card.Body>
                <Box
                    alignItems="center"
                    display="flex"
                    gap={1}
                    justifyContent="center"
                >
                    {prepend !== null && (
                        <Typography variant="body2">{prepend}</Typography>
                    )}
                    {value !== null && (
                        <Typography fontWeight="bold" variant="h3">
                            {value}
                        </Typography>
                    )}
                    {append !== null && (
                        <Typography variant="body2">{append}</Typography>
                    )}
                </Box>
            </Card.Body>
        </Card>
    );
};

CardKeyValue.propTypes = {
    append: PropTypes.string,
    prepend: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
    visible: PropTypes.bool,
};

export default CardKeyValue;
