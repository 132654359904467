import get from 'lodash/get';

import { getRoleLevelDetails } from 'common/api/roles';

import {
    FETCH_ROLE_LEVEL_DETAILS,
    FETCH_ROLE_LEVEL_DETAILS_FAILURE,
    FETCH_ROLE_LEVEL_DETAILS_SUCCESS,
} from '../actionTypes';
import { getPermissionGroups, getRoleCategories } from '../helpers';
import { companyRolesActions } from '../reducer';
import * as selectors from '../selectors';

import initializeForm from './initializeForm';

export default (levelId) => (dispatch, getState) => {
    const formValues = selectors.getValues(getState());

    if (!levelId) return false;

    dispatch(companyRolesActions[FETCH_ROLE_LEVEL_DETAILS]());

    getRoleLevelDetails(levelId)
        .then((response) => {
            const data = get(response, 'data.data', {});
            dispatch(
                companyRolesActions[FETCH_ROLE_LEVEL_DETAILS_SUCCESS](data)
            );
            const categoriesSettings = get(data, 'category_settings', []);
            dispatch(
                initializeForm({
                    ...formValues,
                    categories: getRoleCategories(categoriesSettings),
                    category: categoriesSettings[0]?.id,
                    financier_group_type: levelId,
                    groups: getPermissionGroups(categoriesSettings),
                })
            );
        })
        .catch((error) =>
            dispatch(
                companyRolesActions[FETCH_ROLE_LEVEL_DETAILS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
