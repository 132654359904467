import isEmpty from 'lodash/isEmpty';

import filterCredits from './filterCredits';
import openModalReassignAdvisor from './openModalReassignAdvisor';
import resetReassignAdvisorForm from './resetReassignAdvisorForm';

export default (filterData = {}) =>
    (dispatch) => {
        dispatch(resetReassignAdvisorForm());
        if (!isEmpty(filterData)) dispatch(filterCredits(filterData));
        dispatch(openModalReassignAdvisor(false));
    };
