import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

import ListItems from './ListItems';

const Inverters = ({
    changeInput,
    fetchInverterBrands,
    fetchInverters,
    formValues,
    initialValues,
    inverterBrands,
    inverters,
    isFetching,
    isFetchingInverterBrands,
    paginationData,
}) => {
    const [filterData, setFilterData] = useState({
        brandName: '',
        financierProductId: formValues?.id,
        page: 0,
        pageSize: 25,
        searchText: '',
    });

    useEffect(() => {
        fetchInverterBrands();
        fetchInverters(filterData);
    }, []);

    return (
        <ListItems
            brands={inverterBrands}
            changeInput={changeInput}
            fieldName="inverters_list"
            filterData={filterData}
            filterItems={fetchInverters}
            isFetching={isFetching}
            isFetchingBrands={isFetchingInverterBrands}
            items={inverters}
            paginationData={paginationData}
            setFilterData={setFilterData}
            value={initialValues?.inverters_list}
        />
    );
};

Inverters.propTypes = {
    changeInput: PropTypes.func,
    fetchInverterBrands: PropTypes.func,
    fetchInverters: PropTypes.func,
    formValues: PropTypes.object,
    initialValues: PropTypes.object,
    inverterBrands: PropTypes.array,
    inverters: PropTypes.array,
    isFetching: PropTypes.bool,
    isFetchingInverterBrands: PropTypes.bool,
    paginationData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
    inverterBrands: selectors.getInverterBrandsData,
    inverters: selectors.getInvertersData,
    isFetching: selectors.getPanelsIsFetching,
    isFetchingInverterBrands: selectors.getIsFetchingInverterBrands,
    paginationData: selectors.getInvertersPagination,
});

const mapDispatchToProps = (dispatch) => ({
    fetchInverterBrands: () => dispatch(actions.fetchInverterBrands()),
    fetchInverters: (filterData) =>
        dispatch(actions.fetchInverters(filterData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inverters);
