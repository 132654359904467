import React, { useCallback, useState } from 'react';

import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box, Button, FormHelperText, FormLabel } from 'sunwise-ui';

import LabelError from 'common/components/LabelError';
import { MAX_FILE_SIZE } from 'common/constants';
import { isImageBase64, isImageUrl } from 'common/utils/helpers';

const StyledDiv = styled(Box)`
    border-radius: 8px;
    border: 1px solid;
    color: #848bab;
    font-size: 14px;
    width: 100%;
`;

const ReduxFieldFile = ({
    buttonText = i18next.t('Upload'),
    disabled,
    id,
    input,
    label,
    maxSize = MAX_FILE_SIZE,
    maxSizeErrorMessage = i18next.t(
        'The file exceeds the maximum size allowed'
    ),
    meta: { touched, error },
    placeholder = i18next.t('File upload'),
    sxButton,
    text = i18next.t('Change image'),
    variant,
    variantButton,
}) => {
    const [errorSize, setErrorSize] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0 && acceptedFiles[0].size >= maxSize) {
            setErrorSize(maxSizeErrorMessage);
            return;
        }

        setErrorSize(null);

        input.onChange(
            acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
        );
    }, []);

    const propId = id ? id : 'inputGroup' + Math.random();
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div {...getRootProps} disabled={disabled}>
            <label htmlFor={`contained-button-file-${propId}`}>
                <input
                    {...getInputProps()}
                    disabled={disabled}
                    id={`contained-button-file-${propId}`}
                    type="file"
                />
                {label && (
                    <FormLabel className="custom-file-label">{label}</FormLabel>
                )}
                <Box
                    display="flex"
                    gap="16px"
                    justifyContent="center"
                    sx={{ flexGrow: 0 }}
                >
                    {variant !== 'profile' && (
                        <StyledDiv>
                            {variant !== 'profile'
                                ? `${
                                      isString(input.value) &&
                                      !isImageBase64(input.value) &&
                                      !isImageUrl(input.value)
                                          ? input.value
                                          : isArray(input.value) &&
                                            input.value.length > 0
                                          ? input.value[0].path
                                          : placeholder
                                  }`
                                : `${text}`}
                        </StyledDiv>
                    )}
                    <Button
                        color="secondary"
                        component="span"
                        fullWidth
                        size="small"
                        sx={sxButton}
                        variant={variantButton}
                    >
                        {buttonText}
                    </Button>
                </Box>
                {Boolean(error) && (
                    <LabelError type="error">{error?.message}</LabelError>
                )}
                {((touched && error) || errorSize !== null) && (
                    <FormHelperText type="error">
                        {error} {errorSize}
                    </FormHelperText>
                )}
            </label>
        </div>
    );
};

ReduxFieldFile.propTypes = {
    buttonText: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.string,
    maxSize: PropTypes.number,
    maxSizeErrorMessage: PropTypes.number,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    sxButton: PropTypes.object,
    text: PropTypes.string,
    variant: PropTypes.string,
    variantButton: PropTypes.string,
};

export default ReduxFieldFile;
