import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';

import { NAME } from './constants';

export const getModel = (state) => state[NAME];

export const getInitialValues = createSelector(
    getModel,
    (model) => model.initialValues
);

export const getSaveItem = createSelector(getModel, (model) => model.saveItem);

export const getIsSavingItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.isSaving
);

export const getDataSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.data
);

export const getErrorsSaveItem = createSelector(
    getSaveItem,
    (saveItem) => saveItem.errors
);

export const getDeleteItem = createSelector(
    getModel,
    (model) => model.deleteItem
);

export const getIsDeletingItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.isDeleting
);

export const getDataDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.data
);

export const getErrorsDeleteItem = createSelector(
    getDeleteItem,
    (deleteItem) => deleteItem.errors
);

export const getFetchItems = createSelector(
    getModel,
    (model) => model.fetchItems
);

export const getIsFetchingItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.isFetching
);

export const getDataFetchItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.data.data || []
);

export const getFetchItemsPagination = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.data.pagination
);

export const getErrorsFetchItems = createSelector(
    getFetchItems,
    (fetchItems) => fetchItems.errors
);

export const getFetchItemsList = createSelector(
    getModel,
    (model) => model.fetchItemsList
);

export const getIsFetchingItemsList = createSelector(
    getFetchItemsList,
    (fetchItems) => fetchItems.isFetching
);

export const getFetchItemsListData = createSelector(
    getFetchItemsList,
    (fetchItems) => fetchItems.data || []
);

export const getFetchItemsListErrors = createSelector(
    getFetchItemsList,
    (fetchItems) => fetchItems.errors
);

export const getFetchGroups = createSelector(
    getModel,
    (model) => model.fetchGroups
);

export const getIsFetchingGroups = createSelector(
    getFetchGroups,
    (fetchGroups) => fetchGroups.isFetching
);

export const getDataFetchGroups = createSelector(
    getFetchGroups,
    (fetchGroups) => fetchGroups.data || []
);

export const getErrorsFetchGroups = createSelector(
    getFetchGroups,
    (fetchGroups) => fetchGroups.errors
);

export const getIsOpenModalProduct = createSelector(
    getModel,
    (model) => model.isOpenModalProduct
);

export const getIsNewRecord = createSelector(
    getModel,
    (model) => model.isNewRecord
);

export const getValues = createSelector(
    (state) => getFormValues(`${NAME}/form`)(state),
    (values) => values || {}
);

export const getFetchCompany = createSelector(
    getModel,
    (model) => model.fetchCompany
);

export const getIsFetchingCompany = createSelector(
    getFetchCompany,
    (fetchCompany) => fetchCompany.isFetching
);

export const getDataFetchCompany = createSelector(
    getFetchCompany,
    (fetchCompany) => fetchCompany.data || null
);

export const getErrorsFetchCompany = createSelector(
    getFetchCompany,
    (fetchCompany) => fetchCompany.errors
);

export const getValidPasswordConfirmationLength = createSelector(
    getModel,
    (model) => model.validPasswordConfirmationLength
);

export const getValidPassword = createSelector(
    getModel,
    (model) => model.validPassword
);

export const getValidPasswordConfirmation = createSelector(
    getModel,
    (model) => model.validPasswordConfirmation
);

export const getActivateItem = createSelector(
    getModel,
    (model) => model.activateItem
);

export const getIsFetchingActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.isFetching
);
export const getDataActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.data
);
export const getErrorsActivateItem = createSelector(
    getActivateItem,
    (activateItem) => activateItem.erros
);
