import API from '..';
const ENTITY = 'products/financier-product';

export const bulkProducts = (data) =>
    API.put(
        `/api/v1/${ENTITY}/`,
        { ...data },
        { headers: { 'Content-Type': 'application/json' } }
    );
export const updateRowEdition = (id, data) =>
    API.put(`/api/v1/products/row-edition/${id}/`, { ...data });
export const fetchItem = (uuid) => API.get(`/api/v1/${ENTITY}/${uuid}`);
export const fetchItems = ({
    orderBy = '',
    page = 1,
    perPage = 25,
    sortBy = '',
}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: { order_by: orderBy, page, per_page: perPage, sort: sortBy },
    });

export const filterProducts = ({
    orderBy,
    page = 1,
    perPage = 25,
    searchText,
    sortBy,
    status = 'active',
} = {}) =>
    API.get(`/api/v1/${ENTITY}/`, {
        params: {
            page: page,
            per_page: perPage,
            status_flag: status,
            ...(orderBy ? { order_by: orderBy } : {}),
            ...(searchText ? { search: searchText } : {}),
            ...(sortBy ? { sort: sortBy } : {}),
        },
    });

export const create = (values) => API.post(`/api/v1/${ENTITY}/`, { ...values });
export const update = (values) =>
    API.put(`/api/v1/${ENTITY}/${values.id}`, { ...values });
export const duplicateProduct = (id) =>
    API.put(`/api/v1/${ENTITY}/${id}/duplicate/`);
