import React, { useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { Box, Divider, Grid, Link, Typography } from 'sunwise-ui';

import {
    ReduxFieldCheck,
    ReduxFieldFile,
    ReduxFieldInput,
} from 'common/components/form/redux';
import TextVariant from 'common/components/TextVariant';
import {
    DEFAULT_ALGORITHM_TYPES,
    DEFAULT_PRODUCT_TYPES,
    DEFAULT_RATES,
    DEFAULT_TERMS,
    DEFAULT_TYPES_BUSINESS,
} from 'common/constants';
import { handleTermsOnCSVFile, mergeTermsData } from 'common/utils/helpers';

import CheckboxButtonField from '../../financial/components/productForm/CheckboxButtonField';
import RadioButtonField from '../../financial/components/productForm/RadioButtonField';

const FieldContainer = styled(Box)`
    margin-bottom: 45px;
`;

const TextInputContainer = styled(FieldContainer)`
    .form-label {
        color: #202253;
        font-size: 15px;
        margin-bottom: 5px;
    }

    .form-control {
        border-radius: 3px;
        border: 1px solid #eff1fb;
        box-shadow: 0 2px 12px 0 rgba(129, 158, 200, 0.06);
        font-size: 13px;
        height: 50px;
        padding: 17px 0 17px 18px;
    }
`;

const TextAreaContainer = styled(TextInputContainer)`
    .form-control {
        height: 121px;
    }
`;

const Parameters = ({ changeInput, disabled, formValues }) => {
    const { t } = useTranslation();
    const [defaultTerms, setTerms] = useState(DEFAULT_TERMS);

    const terms = get(formValues, 'terms', []);

    useEffect(() => {
        if (terms.length > 0) {
            const object_keys = Object.keys(formValues.terms);
            let selectedTerms = [];

            object_keys.forEach((item) => {
                selectedTerms.push({
                    label: item,
                    value: item,
                });
            });

            const nonDuplidatedAndSortData = mergeTermsData(
                defaultTerms,
                selectedTerms
            );

            setTerms(nonDuplidatedAndSortData);
        }
    }, [terms]);

    const handleData = (data) => {
        const newTerms = handleTermsOnCSVFile(data, defaultTerms);
        setTerms(newTerms.termsList);
        changeInput('terms', newTerms.selectedTerms);
    };

    const handleOnChangeFilePercentage = (e) => {
        const file = e[0];
        if (!file) return;
        Papa.parse(file, {
            complete: handleData,
        });
    };

    const downloadLink =
        formValues?.file_rate && formValues.id
            ? formValues?.file_rate
            : 'https://sunwise-production.s3.amazonaws.com/media/PPA example file.csv';

    return (
        <React.Fragment>
            <TextInputContainer>
                <Field
                    component={ReduxFieldInput}
                    disabled={disabled}
                    label={`${t('Product name')} *`}
                    name="name"
                    placeholder={t('Product name')}
                />
            </TextInputContainer>

            <TextAreaContainer>
                <Field
                    as="textarea"
                    component={ReduxFieldInput}
                    defaultBehevior={true}
                    disabled={disabled}
                    label={t('General information about the product')}
                    name="description"
                    placeholder=""
                />
            </TextAreaContainer>

            <FieldContainer>
                <TextVariant text={t('Product type')} variant="bold" />
                <Divider />
                <Field
                    component={RadioButtonField}
                    disabled={disabled}
                    items={DEFAULT_PRODUCT_TYPES}
                    name="type"
                    prefixId="type"
                />
            </FieldContainer>

            <FieldContainer>
                <TextVariant text={t('Type of tax regime')} variant="bold" />
                <Divider />
                <Field
                    component={RadioButtonField}
                    disabled={disabled}
                    items={DEFAULT_TYPES_BUSINESS}
                    name="types_business_company"
                    prefixId="fiscal2"
                />
            </FieldContainer>

            <FieldContainer>
                <Typography fontWeight="bold" variant="body2">
                    {t('Algorithm for run')}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Field
                    component={RadioButtonField}
                    disabled={disabled}
                    items={DEFAULT_ALGORITHM_TYPES}
                    name="type_algorithm"
                    prefixId="type_algorithm"
                />
            </FieldContainer>

            <FieldContainer>
                <TextVariant
                    text={t('Select rates to consider')}
                    variant="bold"
                />
                <Divider />
                <Field
                    component={CheckboxButtonField}
                    disabled={disabled}
                    items={DEFAULT_RATES}
                    name="rate_list"
                    prefixId="rates"
                />
            </FieldContainer>

            <FieldContainer
                hidden={['static_payment', 'static_payment_tax'].includes(
                    formValues.type_algorithm
                )}
            >
                <TextVariant
                    text={t('Interest rate configuration file')}
                    variant="bold"
                />
                <Divider />
                <Field
                    component={ReduxFieldFile}
                    name="file_rate"
                    onChange={handleOnChangeFilePercentage}
                />

                <Box p={2}>
                    <Link href={downloadLink} target="_blank">
                        {t('View attached file')}
                    </Link>
                </Box>
            </FieldContainer>

            <TextInputContainer>
                <TextVariant text={t('Amount limit')} variant="bold" />
                <Divider />
                <Grid container>
                    <Grid item xs={2} lg={1}>
                        <Field
                            component={ReduxFieldCheck}
                            disabled={disabled}
                            label=""
                            name="amount_values.isChecked"
                            onChange={(e) => {
                                if (!e.target.checked) {
                                    changeInput('amount_values.min', 0);
                                    changeInput('amount_values.max', '');
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs>
                        <Field
                            append="MXN"
                            component={ReduxFieldInput}
                            disabled={
                                !formValues['amount_values'] ||
                                !formValues['amount_values'].isChecked ||
                                disabled
                            }
                            label={t('Minimum amount')}
                            name="amount_values.min"
                            prepend="$"
                            type="number"
                        />
                    </Grid>

                    <Grid item xs>
                        <Field
                            append="MXN"
                            component={ReduxFieldInput}
                            disabled={
                                !formValues['amount_values'] ||
                                !formValues['amount_values'].isChecked ||
                                disabled
                            }
                            label={t('Maximum amount')}
                            name="amount_values.max"
                            prepend="$"
                            type="number"
                        />
                    </Grid>
                </Grid>
            </TextInputContainer>

            <TextInputContainer>
                <TextVariant text={t('Power limit')} variant="bold" />
                <Divider />
                <Grid container>
                    <Grid item xs={2} lg={1}>
                        <Field
                            component={ReduxFieldCheck}
                            disabled={disabled}
                            label=""
                            name="power_values.isChecked"
                            onChange={(e) => {
                                if (!e.target.checked) {
                                    changeInput('power_values.min', 0);
                                    changeInput('power_values.max', '');
                                }
                            }}
                        />
                    </Grid>

                    <Grid item xs>
                        <Field
                            append="kW"
                            component={ReduxFieldInput}
                            disabled={
                                !formValues['power_values'] ||
                                !formValues['power_values'].isChecked ||
                                disabled
                            }
                            label={t('Minimum power')}
                            name="power_values.min"
                            type="number"
                        />
                    </Grid>

                    <Grid item xs>
                        <Field
                            append="kW"
                            component={ReduxFieldInput}
                            disabled={
                                !formValues['power_values'] ||
                                !formValues['power_values'].isChecked ||
                                disabled
                            }
                            label={t('Maximum power')}
                            name="power_values.max"
                            type="number"
                        />
                    </Grid>
                </Grid>
            </TextInputContainer>
        </React.Fragment>
    );
};

Parameters.propTypes = {
    changeInput: PropTypes.func,
    disabled: PropTypes.bool,
    formValues: PropTypes.object,
};

export default Parameters;
