import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Box, Button, Divider } from 'sunwise-ui';

import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/languages/es.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/table.min.js';

import { ShowErrors } from 'common/components';

import * as actions from '../actions';
import { NAME } from '../constants';
import * as selectors from '../selectors';
import validate from '../validate';

const FroalaContainer = styled('div')`
    .fr-box {
        height: 350px;
    }

    .fr-wrapper {
        background: #fff !important;
        border-bottom-color: #cccccc !important;
        height: calc(100% - 50px);

        .fr-element {
            height: 100%;
            min-height: 0 !important;

            &.fr-view {
                line-height: normal;
                overflow-y: auto;
            }
        }
    }
    .second-toolbar {
        display: none;
    }
`;

const TermsAndConditionsForm = ({
    changeInput,
    errors,
    handleSubmit,
    initialValues,
    isSaving,
    save,
    type,
}) => {
    const { t } = useTranslation();
    const [textValue, setTextValue] = useState(initialValues.content);

    const config = {
        attribution: false,
        autoStart: true,
        charCounterCount: false,
        defaultFontFamily: 'Montserrat',
        fontSize: [
            '8',
            '9',
            '10',
            '11',
            '12',
            '14',
            '16',
            '18',
            '20',
            '24',
            '30',
            '36',
            '48',
            '60',
            '72',
            '96',
        ],
        fontSizeDefaultSelection: '14',
        key: process.env.REACT_APP_FROALA_LICENSE,
        language: 'es',
        placeholderText: 'Agrega el texto aquí',
        pluginsEnabled: [
            'align',
            'fontSize',
            'colors',
            'lineBreaker',
            'lineHeight',
            'link',
            'lists',
            'table',
            'paragraphFormat',
            'paragraphStyle',
            'tagPlugin',
        ],
        toolbarButtons: {
            moreParagraph: {
                buttons: [
                    'alignLeft',
                    'alignCenter',
                    'alignRight',
                    'alignJustify',
                    'formatOL',
                    'formatUL',
                    'paragraphFormat',
                    'paragraphStyle',
                    'lineHeight',
                    'outdent',
                    'indent',
                ],
            },
            moreRich: {
                buttons: ['insertLink', 'insertTable'],
            },
            moreText: {
                buttons: [
                    'bold',
                    'italic',
                    'underline',
                    'strikeThrough',
                    'subscript',
                    'superscript',
                    'fontFamily',
                    'fontSize',
                    'textColor',
                    'backgroundColor',
                    'inlineClass',
                    'inlineStyle',
                    'clearFormatting',
                ],
            },
        },
    };

    const handleModelChange = (model) => {
        setTextValue(model);
        changeInput('content', model);
    };

    const canUpdate = true;

    const onClickSave = (values) => save(values, type);

    return (
        <form onSubmit={handleSubmit(onClickSave)}>
            <FroalaContainer>
                <FroalaEditorComponent
                    config={config}
                    model={textValue}
                    onModelChange={handleModelChange}
                    tag="textarea"
                />
            </FroalaContainer>

            <ShowErrors errors={errors} />

            {canUpdate ? (
                <React.Fragment>
                    <Divider sx={{ my: 2, mx: -2 }} />

                    <Box sx={{ textAlign: 'right' }}>
                        <Button disabled={isSaving} type="submit">
                            {t('Save')}
                        </Button>
                    </Box>
                </React.Fragment>
            ) : null}
        </form>
    );
};

const mapStateToProps = createStructuredSelector({
    errors: selectors.getErrorsSaveItem,
    initialValues: selectors.getInitialValuesTermsForm,
    isNewRecord: selectors.getIsNewRecord,
    isSaving: selectors.getIsSavingItem,
    type: selectors.getType,
});

const mapDispatchToProps = (dispatch) => ({
    changeInput: (field, value) =>
        dispatch(change(`${NAME}/terms-form`, field, value)),
    save: (values, type) =>
        dispatch(actions.saveTermsAndConditions(values, type)),
});

TermsAndConditionsForm.propTypes = {
    changeInput: PropTypes.func,
    errors: PropTypes.array,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isSaving: PropTypes.bool,
    save: PropTypes.func,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

const FormContainer = reduxForm({
    enableReinitialize: true,
    form: `${NAME}/terms-form`,
    validate,
})(TermsAndConditionsForm);

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
