import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Box, Button, List, Typography } from 'sunwise-ui';

import * as actions from '../actions';
import { handleClickNotificationBuild } from '../helpers';
import * as selectors from '../selectors';

import NotificationItem from './NotificationItem';

const NavNotificationsList = ({
    deleteNotification,
    filterData,
    isDeletingNotification,
    isFetching,
    isUpdatingNotifications,
    markAllAsRead,
    notifications,
    redirect,
    setIsOpenDrawer,
    updateNotification,
}) => {
    const { t } = useTranslation();
    const handleClickDelete = (id) => deleteNotification(filterData, id);

    const handleClickMarkAsReaded = (notification) =>
        updateNotification(filterData, notification);

    const isLoading =
        isDeletingNotification || isFetching || isUpdatingNotifications;

    if (isLoading)
        return (
            <Box>
                <LinearProgress />
            </Box>
        );

    if (notifications.length === 0)
        return (
            <Box p={2} sx={{ textAlign: 'center' }}>
                <Typography variant="caption">
                    {t('No notifications at the moment')}
                </Typography>
            </Box>
        );

    return (
        <Box>
            <Box mb={1}>
                <Button
                    color="secondary"
                    fullWidth
                    onClick={() => markAllAsRead()}
                    variant="outlined"
                >
                    {t('Mark all as read')}
                </Button>
            </Box>

            <List component="nav">
                {notifications.map(
                    (notification) =>
                        notification.id && (
                            <NotificationItem
                                handleClickDelete={handleClickDelete}
                                handleClickMarkAsReaded={
                                    handleClickMarkAsReaded
                                }
                                handleClickNotification={
                                    handleClickNotificationBuild
                                }
                                key={`notification-item-${notification.id}`}
                                notification={notification}
                                redirect={redirect}
                                setIsOpenDrawer={setIsOpenDrawer}
                            />
                        )
                )}
            </List>
        </Box>
    );
};

NavNotificationsList.propTypes = {
    deleteNotification: PropTypes.func,
    filterData: PropTypes.object,
    isDeletingNotification: PropTypes.bool,
    isFetching: PropTypes.bool,
    isUpdatingNotifications: PropTypes.bool,
    markAllAsRead: PropTypes.func,
    notifications: PropTypes.array,
    redirect: PropTypes.func,
    setIsOpenDrawer: PropTypes.func,
    updateNotification: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
    isDeletingNotification: selectors.getIsDeletingNotification,
    isFetching: selectors.getIsFetchingNavNotifications,
    isUpdatingNotifications: selectors.getIsUpdatingNotifications,
    notifications: selectors.getNavNotificationsData,
});

const mapDispatchToProps = (dispatch) => ({
    deleteNotification: (filterData, id) =>
        dispatch(actions.deleteNotification(filterData, id)),
    markAllAsRead: () => dispatch(actions.markAllAsRead()),
    redirect: (link) => dispatch(push(link)),
    updateNotification: (filterData, notification) =>
        dispatch(actions.updateNotification(filterData, notification)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavNotificationsList);
