import {
    saveRequirements,
    updateRequirements,
} from 'common/api/financierAllianceInstaller';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    SAVE_ITEM,
    SAVE_ITEM_FAILURE,
    SAVE_ITEM_SUCCESS,
} from '../actionTypes';
import { configurationDocumentsActions } from '../reducer';

import fetchTermsAndConditions from './fetchTermsAndConditions';
import resetFormTerms from './resetFormTerms';
import toggleModal from './toggleModal';

export default (values, type) => (dispatch) => {
    const newValues = {
        content: values.content,
        required: true,
    };

    let action = saveRequirements;

    if (values.id) {
        action = updateRequirements;
        newValues.id = values.id;
    }

    dispatch(configurationDocumentsActions[SAVE_ITEM]());

    action(newValues)
        .then(() => {
            dispatch(configurationDocumentsActions[SAVE_ITEM_SUCCESS]());

            dispatch(fetchTermsAndConditions(type));
            dispatch(toggleModal(false));
            dispatch(resetFormTerms());

            showToast();
        })
        .catch((error) => {
            dispatch(
                configurationDocumentsActions[SAVE_ITEM_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
