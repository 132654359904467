import { updateInstaller } from 'common/api/installers';
import { showReponseErrorsAsAlert } from 'common/utils/helpers';
import showToast from 'common/utils/showToast';

import {
    UPDATE_INSTALLER_DATA,
    UPDATE_INSTALLER_DATA_FAILURE,
    UPDATE_INSTALLER_DATA_SUCCESS,
} from '../actionTypes';
import { installerProfileActions } from '../reducer';

export default (values, onSuccess) => (dispatch) => {
    const newValues = {
        city: values.city,
        email: values.email,
        legal_representative: values.legal_representative,
        phone: values.phone,
        rfc: values.rfc,
        web_page: values.web_page,
    };

    dispatch(installerProfileActions[UPDATE_INSTALLER_DATA]());

    updateInstaller(values.installerId, newValues)
        .then(() => {
            dispatch(installerProfileActions[UPDATE_INSTALLER_DATA_SUCCESS]());
            if (onSuccess) onSuccess();
            showToast();
        })
        .catch((error) => {
            dispatch(
                installerProfileActions[UPDATE_INSTALLER_DATA_FAILURE](
                    error?.response?.data?.errors
                )
            );
            showReponseErrorsAsAlert(dispatch, error?.response);
        });
};
