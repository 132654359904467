import { fetchListCatalogs } from 'common/api/documentCatalog';
import { DOCUMENTS_TYPE, PRODUCT_DOCUMENTS_CATEGORY } from 'common/constants';

import {
    FETCH_DOCUMENTS,
    FETCH_DOCUMENTS_FAILURE,
    FETCH_DOCUMENTS_SUCCESS,
} from '../../actionTypes';
import { creditFileActions } from '../../reducer';

export default () => (dispatch) => {
    dispatch(creditFileActions[FETCH_DOCUMENTS]());

    fetchListCatalogs()
        .then((response) => {
            const dataFiltered = response.data.data.filter(
                (item) =>
                    item.category === PRODUCT_DOCUMENTS_CATEGORY &&
                    item.type === DOCUMENTS_TYPE
            );
            dispatch(creditFileActions[FETCH_DOCUMENTS_SUCCESS](dataFiltered));
        })
        .catch((error) =>
            dispatch(
                creditFileActions[FETCH_DOCUMENTS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
