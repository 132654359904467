import i18next from 'i18next';
import isEmpty from 'lodash/isEmpty';

export default (values = {}) => {
    const errors = {};
    if (isEmpty(values.name)) errors.name = i18next.t('Name cannot be empty');
    if (
        values.parameters_commission_credit &&
        values.parameters_commission_credit.unit === 0 &&
        (values.parameters_commission_credit.value < 0 ||
            values.parameters_commission_credit.value > 100)
    )
        errors.parameters_commission_credit = {
            value: i18next.t('The value must be between {{min}} and {{max}}', {
                max: 100,
                min: 0,
            }),
        };
    return errors;
};
