import React from 'react';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import { ReduxFieldInput } from 'common/components/form/redux';

const FieldContainer = styled('div')`
    margin-bottom: 45px;
`;

const TextInputContainer = styled(FieldContainer)`
    .form-label {
        color: #202253;
        font-size: 15px;
        margin-bottom: 5px;
    }
    .form-control {
        border-radius: 3px;
        border: 1px solid #eff1fb;
        box-shadow: 0 2px 12px 0 rgba(129, 158, 200, 0.06);
        font-size: 13px;
        height: 50px;
        padding: 17px 0 17px 18px;
    }
`;

const TextAreaContainer = styled(TextInputContainer)`
    .form-control {
        height: 121px;
    }
`;

const Parameters = ({ disabled }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <TextInputContainer>
                <Field
                    component={ReduxFieldInput}
                    disabled={disabled}
                    label={`${t('Product name')} *`}
                    name="name"
                    placeholder={t('Product name')}
                />
            </TextInputContainer>

            <TextAreaContainer>
                <Field
                    as="textarea"
                    component={ReduxFieldInput}
                    defaultBehevior={true}
                    disabled={disabled}
                    label={t('General information about the product')}
                    name="description"
                    placeholder=""
                />
            </TextAreaContainer>
        </React.Fragment>
    );
};

Parameters.propTypes = {
    disabled: PropTypes.bool,
};

export default Parameters;
