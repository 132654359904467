import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Button, Card, Grid, UiDialog as Dialog } from 'sunwise-ui';

import TitleIcon from 'common/components/TitleIcon';
import { PERMISSION_LIST } from 'common/constants/permissions';
import withPermissions from 'common/hocs/withPermissions';
import { getFinancierGroupType } from 'common/utils/session';

import * as actions from './actions';
import { ListItems, RoleForm } from './components';
import * as selectors from './selectors';

const Container = ({
    canModify,
    fetchItems,
    handleClickCloseModal,
    handleClickCreate,
    handleClickUpdate,
    isFetching,
    isNewRecord,
    isOpenModal,
    items,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        fetchItems();
    }, []);

    const financierGroupType = getFinancierGroupType();

    return (
        <React.Fragment>
            <Card>
                <Card.Header>
                    <Grid container sx={{ alignItems: 'center' }}>
                        <Grid item xs>
                            <TitleIcon title="Roles" />
                        </Grid>

                        <Grid hidden={!canModify} item>
                            <Button
                                endIcon={<AddIcon fontSize="small" />}
                                onClick={handleClickCreate}
                            >
                                {t('Add role')}
                            </Button>
                        </Grid>
                    </Grid>
                </Card.Header>

                <Card.Body>
                    <ListItems
                        canModify={canModify}
                        financierGroupType={financierGroupType}
                        handleClickUpdate={handleClickUpdate}
                        isFetching={isFetching}
                        items={items}
                    />
                </Card.Body>
            </Card>

            <Dialog
                onClose={() => handleClickCloseModal()}
                open={isOpenModal}
                size="lg"
                title={`${isNewRecord ? t('Add') : t('Update')} ${t('Role')}`}
            >
                <RoleForm
                    financierGroupType={financierGroupType}
                    handleClickCloseModal={handleClickCloseModal}
                />
            </Dialog>
        </React.Fragment>
    );
};

Container.propTypes = {
    canModify: PropTypes.bool,
    fetchItems: PropTypes.func,
    handleClickCloseModal: PropTypes.func,
    handleClickCreate: PropTypes.func,
    handleClickUpdate: PropTypes.func,
    isFetching: PropTypes.bool,
    isNewRecord: PropTypes.bool,
    isOpenModal: PropTypes.bool,
    items: PropTypes.array,
};

const mapStateToProps = createStructuredSelector({
    isFetching: selectors.getIsFetchingItems,
    isNewRecord: selectors.getIsNewRecord,
    isOpenModal: selectors.getisOpenModal,
    items: selectors.getFetchItemsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchItems: () => dispatch(actions.fetchItems()),
    handleClickCloseModal: () => dispatch(actions.openModal(false)),
    handleClickCreate: () => dispatch(actions.prepareCreate()),
    handleClickUpdate: (id) => dispatch(actions.fetchRoleDetails(id)),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withPermissions(PERMISSION_LIST.ROLES_PERMISSION)
)(Container);
