import { filterTemplates } from 'common/api/templates';

import {
    FETCH_TEMPLATES,
    FETCH_TEMPLATES_FAILURE,
    FETCH_TEMPLATES_SUCCESS,
} from '../actionTypes';
import { templateDocumentsActions } from '../reducer';

export default () => (dispatch) => {
    dispatch(templateDocumentsActions[FETCH_TEMPLATES]());

    filterTemplates({ perPage: 1000 })
        .then((response) =>
            dispatch(
                templateDocumentsActions[FETCH_TEMPLATES_SUCCESS](
                    response.data.data
                )
            )
        )
        .catch((error) =>
            dispatch(templateDocumentsActions[FETCH_TEMPLATES_FAILURE](error))
        );
};
