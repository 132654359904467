import get from 'lodash/get';

import { restartPassword } from 'common/api/users';
import alerts from 'common/modules/alerts';
import showToast from 'common/utils/showToast';

import {
    RESTART_PASSWORD,
    RESTART_PASSWORD_FAILURE,
    RESTART_PASSWORD_SUCCESS,
} from '../actionTypes';
import { companyUsersActions } from '../reducer';

import filterItems from './filterItems';
import resetForm from './resetForm';
import toggleModalProduct from './toggleModalProduct';

export default (filterData, values) => (dispatch) => {
    dispatch(alerts.actions.close());

    dispatch(companyUsersActions[RESTART_PASSWORD]());

    restartPassword(values.id)
        .then((response) => {
            dispatch(companyUsersActions[RESTART_PASSWORD_SUCCESS]());

            dispatch(toggleModalProduct(false));
            dispatch(resetForm());
            dispatch(filterItems(filterData));

            const message = get(response, ['data']);

            showToast({ body: message, autoClose: 5000 });
        })
        .catch((error) =>
            dispatch(
                companyUsersActions[RESTART_PASSWORD_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
