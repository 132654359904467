import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Divider,
    Grid,
    Stack,
    StackItem,
    Tooltip,
    Typography,
} from 'sunwise-ui';

import DropdownCardMenu from 'common/components/DropdownCardMenu';
import { NO_DOCUMENT_STATUS } from 'common/constants';

import icoPreapproved from 'resources/icoPreapproved.svg';

import DocumentStatus from './DocumentStatus';

const CreditDocuments = ({
    canModify,
    documents,
    handleOnClickDelete,
    handleOnClickStatus,
}) => {
    const { t } = useTranslation();

    if (isEmpty(documents)) {
        return (
            <Box display="flex" alignItems="center" p={2}>
                <Typography
                    variant="body2"
                    fontWeight="bold"
                    sx={{ color: '#848bab' }}
                >
                    {t('No assigned documents')}
                </Typography>
            </Box>
        );
    }

    const handleOnClickDownload = (href) => {
        const link = document.createElement('a');
        link.href = href;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
    };

    return (
        <Stack
            divider={<Divider />}
            sx={{ '.MuiDivider-fullWidth': { mt: 0 } }}
        >
            {documents.map((document) => (
                <StackItem key={document.id}>
                    <Grid container display="flex" alignItems="center">
                        <Grid item xs={2} textAlign="center">
                            {document.required && (
                                <Tooltip
                                    title={t(
                                        'This symbol specifies what documents are necessary to pre-validate the product'
                                    )}
                                >
                                    <img
                                        alt="icoPreapproved"
                                        src={icoPreapproved}
                                        width={20}
                                    />
                                </Tooltip>
                            )}
                        </Grid>

                        <Grid item xs={8}>
                            <Typography
                                variant="body2"
                                fontWeight="bold"
                                sx={{ fontSize: '0.875rem' }}
                            >
                                {document.name}
                            </Typography>
                        </Grid>

                        <Grid item textAlign="center" xs={6}>
                            <DocumentStatus
                                disabled={!canModify}
                                onClick={() => handleOnClickStatus(document)}
                                status={document?.status}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <DropdownCardMenu
                                items={[
                                    {
                                        handleClick: () =>
                                            handleOnClickDownload(
                                                document?.file
                                            ),
                                        icon: <DownloadIcon fontSize="small" />,
                                        label: t('Download'),
                                        visible:
                                            document.status !==
                                            NO_DOCUMENT_STATUS,
                                    },
                                    {
                                        handleClick: () =>
                                            handleOnClickDelete(document),
                                        icon: <DeleteIcon fontSize="small" />,
                                        label: t('Delete'),
                                    },
                                ]}
                                visible={canModify}
                            />
                        </Grid>
                    </Grid>
                </StackItem>
            ))}
        </Stack>
    );
};

CreditDocuments.propTypes = {
    canModify: PropTypes.bool,
    documents: PropTypes.array,
    handleOnClickDelete: PropTypes.func,
    handleOnClickStatus: PropTypes.func,
};

export default CreditDocuments;
