import { getProposalBackups } from 'common/api/proposals';

import {
    FETCH_PROPOSAL_BACKUPS,
    FETCH_PROPOSAL_BACKUPS_FAILURE,
    FETCH_PROPOSAL_BACKUPS_SUCCESS,
} from '../actionTypes';
import { creditFileActions } from '../reducer';

export default (id) => (dispatch) => {
    dispatch(creditFileActions[FETCH_PROPOSAL_BACKUPS]());

    getProposalBackups(id)
        .then((response) =>
            dispatch(
                creditFileActions[FETCH_PROPOSAL_BACKUPS_SUCCESS](
                    response?.data?.data
                )
            )
        )
        .catch((error) =>
            dispatch(
                creditFileActions[FETCH_PROPOSAL_BACKUPS_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
