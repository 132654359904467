import i18next from 'i18next';

import alerts from 'common/modules/alerts';

import cancelCredit from './cancelCredit';

export default (id, callback) => (dispatch) => {
    if (!id) return;

    const dataAlert = {
        haveTextCaptcha: true,
        messages: [
            i18next.t(
                'Canceling the request will no longer be able to reverse the changes'
            ),
        ],
        onSuccess: () => dispatch(cancelCredit(id, callback)),
        textToValidate: i18next.t('Cancel').toUpperCase(),
        title: i18next.t('Confirmation'),
        type: alerts.ALERT_TYPE_CONFIRM,
        variant: 'error',
    };

    dispatch(alerts.actions.show(dataAlert));
};
