import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, MenuItem, Select } from 'sunwise-ui';

const StatusFilterSelect = ({ filterBy, handleSelected, variant }) => {
    const { t } = useTranslation();
    return (
        <FormControl fullWidth size="small">
            <InputLabel>{t('Filter by')}</InputLabel>

            <Select
                label={t('Filter by')}
                onChange={(e) => handleSelected(e.target.value)}
                value={filterBy}
                variant={variant}
            >
                <MenuItem value="active">{t('Active')}</MenuItem>
                <MenuItem value="archived">{t('Archived')}</MenuItem>
            </Select>
        </FormControl>
    );
};

StatusFilterSelect.propTypes = {
    filterBy: PropTypes.string,
    handleSelected: PropTypes.func,
    variant: PropTypes.string,
};

export default StatusFilterSelect;
