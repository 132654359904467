import React, { useEffect } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Button, Card, Grid, List, ListItem } from 'sunwise-ui';

import ContactPreview from 'common/components/ContactPreview';
import StyledH2 from 'common/components/StyledH2';

import * as actions from '../actions';
import * as selectors from '../selectors';

const CompanyUsers = ({
    companyUsers = [],
    fetchCompanyUsers,
    match,
    updateInstallerUsers,
    installerId,
    isFetchingCompanyUsers,
}) => {
    const { t } = useTranslation();
    const {
        params: { id },
    } = match;

    useEffect(() => {
        fetchCompanyUsers(id);
    }, [id]);

    const handleClickUpdateInstallerUsers = () =>
        updateInstallerUsers(installerId, () => fetchCompanyUsers(id));

    const renderContent = ({ companyUsers, isFetchingCompanyUsers }) => {
        if (isFetchingCompanyUsers) return <LinearProgress />;
        if (companyUsers.length === 0)
            return <ListItem>{t('There are no items to display')}</ListItem>;
        return companyUsers.map((contact) => (
            <ListItem key={`support-contact-${contact.id}`}>
                <ContactPreview contact={contact} />
            </ListItem>
        ));
    };

    const sxList = { maxHeight: '400px', overflow: 'auto' };

    return (
        <Card>
            <Card.Header>
                <Grid container alignItems="center">
                    <Grid item xs>
                        <StyledH2>{t('Users')}</StyledH2>
                    </Grid>

                    <Grid item>
                        <Button
                            color="secondary"
                            onClick={handleClickUpdateInstallerUsers}
                            variant="text"
                        >
                            {t('Update')}
                        </Button>
                    </Grid>
                </Grid>
            </Card.Header>

            <List sx={sxList}>
                {renderContent({ companyUsers, isFetchingCompanyUsers })}
            </List>
        </Card>
    );
};

const mapStateToProps = createStructuredSelector({
    companyUsers: selectors.getCompanyUsersData,
    isFetchingCompanyUsers: selectors.getCompanyUsersIsFetching,
});

const mapDispatchToProps = (dispatch) => ({
    fetchCompanyUsers: (id) => dispatch(actions.fetchCompanyUsers(id)),
    updateInstallerUsers: (id, callback) =>
        dispatch(actions.updateInstallerUsers(id, callback)),
});

CompanyUsers.propTypes = {
    companyUsers: PropTypes.array,
    fetchCompanyUsers: PropTypes.func,
    installerId: PropTypes.string,
    isFetchingCompanyUsers: PropTypes.bool,
    match: PropTypes.object,
    updateInstallerUsers: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompanyUsers));
