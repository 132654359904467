import React from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, List, Typography } from 'sunwise-ui';

import { handleClickNotificationBuild } from '../helpers';

import NotificationItem from './NotificationItem';

const NotificationsList = ({
    deleteNotification,
    filterData,
    notifications,
    redirect,
    updateNotification,
}) => {
    const { t } = useTranslation();
    const handleClickDelete = (id) => deleteNotification(filterData, id);

    const handleClickMarkAsReaded = (notification) =>
        updateNotification(filterData, notification);

    if (notifications.length === 0) {
        return (
            <Box p={2} sx={{ textAlign: 'center' }}>
                <Typography variant="caption">
                    {t('No notifications at the moment')}
                </Typography>
            </Box>
        );
    }

    return (
        <List component="nav">
            {notifications.map(
                (notification) =>
                    notification.id && (
                        <NotificationItem
                            handleClickDelete={handleClickDelete}
                            handleClickMarkAsReaded={handleClickMarkAsReaded}
                            handleClickNotification={
                                handleClickNotificationBuild
                            }
                            key={`notification-item-${notification.id}`}
                            notification={notification}
                            redirect={redirect}
                        />
                    )
            )}
        </List>
    );
};

NotificationsList.propTypes = {
    deleteNotification: PropTypes.func,
    filterData: PropTypes.object,
    notifications: PropTypes.array,
    redirect: PropTypes.func,
    updateNotification: PropTypes.func,
};

export default NotificationsList;
