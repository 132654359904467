import {
    ALLIANCE_DOCUMENT_TYPE,
    CREDIT_DOCUMENT_TYPE,
    FINANCIER_USER_TYPE,
    INSTALLER_USER_TYPE,
    NAME,
} from './constants';
import Container from './Container';
import reducer from './reducer';
import * as selectors from './selectors';

export default {
    ALLIANCE_DOCUMENT_TYPE,
    Container,
    CREDIT_DOCUMENT_TYPE,
    FINANCIER_USER_TYPE,
    INSTALLER_USER_TYPE,
    NAME,
    reducer,
    selectors,
};
