import { getAccessories } from 'common/api/productsSunwise';

import {
    FETCH_ACCESSORIES,
    FETCH_ACCESSORIES_FAILURE,
    FETCH_ACCESSORIES_SUCCESS,
} from '../actionTypes';
import { financialActions } from '../reducer';

export default (filter, page, financierProductId) => (dispatch) => {
    dispatch(financialActions[FETCH_ACCESSORIES]());

    getAccessories(filter, page, financierProductId)
        .then((response) =>
            dispatch(financialActions[FETCH_ACCESSORIES_SUCCESS](response.data))
        )
        .catch((error) =>
            dispatch(
                financialActions[FETCH_ACCESSORIES_FAILURE](
                    error?.response?.data?.errors
                )
            )
        );
};
