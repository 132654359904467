import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';

import { useBreakpoint } from 'common/hooks';

import isotypeSunpay from 'resources/isotypeSunpay.svg';

const items = [{ src: isotypeSunpay, width_sm: '35px', width_md: '45px' }];

const Logo = () => {
    const breakpoint = useBreakpoint();
    const [logo, setLogo] = useState({
        src: isotypeSunpay,
        width_sm: '35px',
        width_md: '45px',
    });
    const theme = useTheme();

    useEffect(() => {
        let itemsToSelect = [...items];
        if (theme.palette.mode !== 'dark' || itemsToSelect.length === 1)
            itemsToSelect = [items[0]];
        setLogo(
            itemsToSelect[Math.floor(Math.random() * itemsToSelect.length)]
        );
    }, [theme.palette.mode]);

    return (
        <img
            src={logo.src}
            alt="Sunpay"
            style={{
                width: ['xs', 'sm'].includes(breakpoint)
                    ? logo.width_sm
                    : logo.width_md,
                maxWidth: 'initial',
            }}
        />
    );
};

Logo.propTypes = {};

export default Logo;
