import { NAME } from './constants';

export const ADD_TEMPLATE_PAGES = `${NAME}/ADD_TEMPLATE_PAGES`;
export const ADD_TEMPLATE_PAGES_FAILURE = `${NAME}/ADD_TEMPLATE_PAGES_FAILURE`;
export const ADD_TEMPLATE_PAGES_SUCCESS = `${NAME}/ADD_TEMPLATE_PAGES_SUCCESS`;

export const FETCH_TEMPLATE_CATALOGS = `${NAME}/FETCH_TEMPLATE_CATALOGS`;
export const FETCH_TEMPLATE_CATALOGS_FAILURE = `${NAME}/FETCH_TEMPLATE_CATALOGS_FAILURE`;
export const FETCH_TEMPLATE_CATALOGS_SUCCESS = `${NAME}/FETCH_TEMPLATE_CATALOGS_SUCCESS`;

export const FETCH_TEMPLATE = `${NAME}/FETCH_TEMPLATE`;
export const FETCH_TEMPLATE_FAILURE = `${NAME}/FETCH_TEMPLATE_FAILURE`;
export const FETCH_TEMPLATE_SUCCESS = `${NAME}/FETCH_TEMPLATE_SUCCESS`;

export const RESET = `${NAME}/RESET`;

export const SET_INITIALIZE = `${NAME}/SET_INITIALIZE`;

export const UPDATE_TEMPLATE = `${NAME}/UPDATE_TEMPLATE`;
export const UPDATE_TEMPLATE_FAILURE = `${NAME}/UPDATE_TEMPLATE_FAILURE`;
export const UPDATE_TEMPLATE_SUCCESS = `${NAME}/UPDATE_TEMPLATE_SUCCESS`;
